import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs";
import { environment } from "src/app/environment";
import { map } from "rxjs/operators";
import { AuthService } from "src/app/services/auth.service";

@Injectable({
  providedIn: "root",
})

export class DepartmentService {
  constructor(private http: HttpClient, private authService: AuthService) {}

  getDepartmentList(parms): Observable<any> {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
  
    const URL = environment.apiUrl + "/Admin/GetAllDepartments";
    return this.http
      .post(URL, parms,{headers:headers})
      // .pipe(map((response: Response) => response));
  }

  addDeparetment(parms): Observable<any> {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/Admin/CreateDepartment";
    return this.http
      .post(URL, parms,{headers:headers})
      // .pipe(map((response: Response) => response));
  }

  updateDeparment(parms): Observable<any> {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/Admin/UpdateDepartmentStatus";
    return this.http
      .post(URL, parms,{headers:headers})
      // .pipe(map((response: Response) => response));
  }


  getDepartmentDetails(depId,CmpCode): Observable<any> {
    
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/Admin/GetDepartmentById?Id=" + depId+`&CmpCode=`+CmpCode;
    return this.http.get(URL,  { headers: headers })
    // .pipe(map((response: Response) => response));
  }

  deleteDepartment(depId): Observable<any>{
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/Admin/DeleteDepartment?Id="+depId;
    return this.http
      .post(URL,{headers:headers})
      // .pipe(map((response: Response) => response));
  }
}
