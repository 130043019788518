import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient, private authService: AuthService) {

  }

  /**
   * @name getUserList
   * @description
   * Fetch Users List
   */
  getUserList(params) {
    const headers = new HttpHeaders();
  let token = this.authService.getToken();
  headers.append('Authorization', `bearer ${token}`)
  headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + '/Admin/GetAllEmployees';
    return this.http.post(URL, params,  { headers: headers })
    // .pipe(map((response: Response) => response));
  }

  getTotalCount(CmpCode) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + '/Admin/GetEmployeeCount?CmpCode='+CmpCode;
    return this.http.get(URL,  { headers: headers })
    // .pipe(map((response: Response) => response));
  }

  createEmployee(dataParams) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + '/Admin/CreateEmployee';
    return this.http.post(URL, dataParams,  { headers: headers })
    // .pipe(map((response: Response) => response));
  }

  getDepartmentList(CmpCode) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + '/Admin/GetAllDepartmentsForSelect?CmpCode='+CmpCode;
    return this.http.get(URL,  { headers: headers })
    // .pipe(map((response: Response) => response));
  }

  getDesignationList(CmpCode) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + '/Admin/GetAllDesignationsForSelect?CmpCode='+CmpCode;
    return this.http.get(URL,  { headers: headers })
    // .pipe(map((response: Response) => response));
  }

  getPayrollList() {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + '/Admin/GetAllPayrollState',
      params = {
        PageNo: 1,
        NoOfRecords: 10,
        SortType: 'asc',
        FieldName: 'PayrollState'
      };
    return this.http.post(URL, params,  { headers: headers })
    // .pipe(map((response: Response) => response));
  }

  getManagerList(CmpCode) {
    
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + '/Admin/GetAllEmployeesForSelect?CmpCode='+CmpCode;
    return this.http.get(URL,  { headers: headers })
    // .pipe(map((response: Response) => response));
  }

  getRateList(CmpCode) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + '/Admin/GetAllRateList/'+CmpCode,
      params = {
        PageNo: 1,
        NoOfRecords: 10,
        SortType: 'asc',
        FieldName: 'RateList'
      };
    return this.http.post(URL, params,  { headers: headers })
    // .pipe(map((response: Response) => response));
  }

  getPhysicalOfficeList(CmpCode) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + '/Admin/GetAllPhysicalList/'+CmpCode,
      params = {
        PageNo: 1,
        NoOfRecords: 10,
        SortType: 'asc',
        FieldName: 'PhysicalList'
      };
    return this.http.post(URL, params,  { headers: headers })
    // .pipe(map((response: Response) => response));
  }

  getCountryList(CmpCode) {

    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + '/Admin/GetAllCountryForSelect?CmpCode='+CmpCode;
    return this.http.get(URL,  { headers: headers })
    // .pipe(map((response: Response) => response));
  }

  getStateList(CountryId,CmpCode) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + '/Admin/GetStateByCountry?id=' + CountryId +"&Cmpcode="+CmpCode;
    return this.http.get(URL,  { headers: headers })
    // .pipe(map((response: Response) => response));
  }

  getCityList(StateId,CmpCode) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + '/Admin/GetCitiesByState/' + StateId +"/"+CmpCode;
    return this.http.get(URL,  { headers: headers })
    // .pipe(map((response: Response) => response));
  }

  getZipCodeList(CityId,CmpCode) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + '/Admin/GetZipcodeByCity/' + CityId +"/"+CmpCode;
    return this.http.get(URL,  { headers: headers })
    // .pipe(map((response: Response) => response));
  }

  deleteUser(data) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    
    const URL = environment.apiUrl + '/Admin/DeleteEmployee';
    return this.http.post(URL, data,  { headers: headers })
    // .pipe(map((response: Response) => response));
  }

  updateUserData(userData) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + '/Admin/UpdateEmployee';
    return this.http.post(URL, userData,  { headers: headers })
    // .pipe(map((response: Response) => response));
  }

  saveUdfData(udfData,CmpCode) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + '/Admin/CreateAdHocAttribute/'+CmpCode;
    return this.http.post(URL, udfData,  { headers: headers })
    // .pipe(map((response: Response) => response));
  }

  fetchManagerList(CmpCode) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + '/Admin/GetAllEmployeesForSelect?CmpCode='+CmpCode;
    return this.http.get(URL,  { headers: headers })
    // .pipe(map((response: Response) => response));
  }
  fetchModuleList(CmpCode) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + '/Admin/GetAllModulesForSelect/'+CmpCode;
    return this.http.get(URL,  { headers: headers })
    // .pipe(map((response: Response) => response));
  }

  fetchOrgStructure(CmpCode) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + '/Admin/GetAllOrgStructure',
      params = {
        PageNo: 1,
        NoOfRecords: 10,
        SortType: 'asc',
        FieldName: 'orgSOrder',
        CmpCode:CmpCode
      };
    return this.http.post(URL, params,  { headers: headers })
    // .pipe(map((response: Response) => response));
  }

  fetchOrgStructureElem(CmpCode) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + '/Admin/GetAllOrgStructureLevel',
      params = {
        PageNo: 1,
        NoOfRecords: 10,
        SortType: 'asc',
        FieldName: 'orgSOrder',
        CmpCode:CmpCode
      };
    return this.http.post(URL, params,  { headers: headers })
    // .pipe(map((response: Response) => response));
  }

  addOrgStructure(data) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + '/Admin/CreateOrgStructureMapping';
    return this.http.post(URL, data,  { headers: headers })
    // .pipe(map((response: Response) => response));
  }
  updateUserMapping(data,CmpCode) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + '/Admin/UpdateOrgStructureMapping/'+CmpCode;
    return this.http.post(URL, data,  { headers: headers })
    // .pipe(map((response: Response) => response));
  }
  getAllOrgStructure() {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + '/Admin/GetAllOrgStructureMapping',
      params = {
        PageNo: 1,
        NoOfRecords: 10,
        SortType: 'asc',
        FieldName: 'ModuleId'
      };
    return this.http.post(URL, params,  { headers: headers })
    // .pipe(map((response: Response) => response));
  }
  getOrgStructureMappingByUserId(UserId,CmpCode) {
    
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + '/Admin/GetOrgStructureMappingByUserId/' + UserId +"/"+CmpCode;
    return this.http.get(URL,  { headers: headers })
    // .pipe(map((response: Response) => response));
  }
  deleteUserMapping(mapId,CmpCode) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + '/Admin/DeleteUserMapping/' + mapId +"/"+CmpCode;
    return this.http.post(URL,  { headers: headers })
    // .pipe(map((response: Response) => response));
  }
  getViewUserList(id,CmpCode) {
    
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + '/Admin/GetUserViewList/'+CmpCode+"/" + id;
    return this.http.get(URL,  { headers: headers })
    // .pipe(map((response: Response) => response));
  }
  getAllRolesForSelect(CmpCode) {

    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + '/Admin/GetAllRolesForSelect?CmpCode='+CmpCode;
    return this.http.get(URL,  { headers: headers })
    // .pipe(map((response: Response) => response));
  }
  assignRolesToUser(data) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + '/Accounts/AssignRolesToUser';
    return this.http.post(URL, data,  { headers: headers })
    // .pipe(map((response: Response) => response));
  }
  getAllRolesByUserId(UserId,CmpCode) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + '/Accounts/GetAllRolesByUserId?Id=' + UserId +"&CmpCode="+CmpCode;
    return this.http.get(URL,  { headers: headers })
    // .pipe(map((response: Response) => response));
  }
  unAssignRolesToUser(data) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + '/Accounts/UnAssignRolesToUser';
    return this.http.post(URL, data,  { headers: headers })
    // .pipe(map((response: Response) => response));
  }

  getAllJobBandsForSelect(CmpCode) {
    try {
      const headers = new HttpHeaders();
      let token = this.authService.getToken();
      headers.append('Authorization', `bearer ${token}`)
      headers.append('Content-Type', 'application/json');
      const URL = environment.apiUrl + '/Admin/GetAllJobBandsForSelect?CmpCode='+CmpCode;
      return this.http.get(URL,  { headers: headers })
      // .pipe(map((response: Response) => response));
    } catch (error) {
      throw error;
    }
  }

  getAllLocationsForSelect(CmpCode) {
    try {
      const headers = new HttpHeaders();
      let token = this.authService.getToken();
      headers.append('Authorization', `bearer ${token}`)
      headers.append('Content-Type', 'application/json');
      const URL = environment.apiUrl + '/Admin/GetAllLocationsForSelect?CmpCode='+CmpCode;
      return this.http.get(URL,  { headers: headers })
      // .pipe(map((response: Response) => response));
    } catch (error) {
      throw error;
    }
  }

  createUserEmployee(dataParams) {
    try {
      const headers = new HttpHeaders();
      let token = this.authService.getToken();
      headers.append('Authorization', `bearer ${token}`)
      headers.append('Content-Type', 'application/json');
      const URL = environment.apiUrl + "/Admin/CreateUserEmployee";
      return this.http
      .post(URL, dataParams,  { headers: headers })
      // .pipe(map((response: Response) => response));
    } catch (error) {
      throw error;
    }
  }

  getEmployeeDetailsByUserId(userId,CmpCode) {
    try {
      const headers = new HttpHeaders();
      let token = this.authService.getToken();
      headers.append('Authorization', `bearer ${token}`)
      headers.append('Content-Type', 'application/json');
      const URL = environment.apiUrl + "/Admin/GetEmployeeDetailsByUserId?UserId=" + userId +"CmpCode="+CmpCode;
      return this.http.get(URL,  { headers: headers })
      // .pipe(map((response: Response) => response));
    } catch (error) {
      throw error;
    }
  }

  // Work Experience
  getAllEmployeeWorkExperience(employeeId,CmpCode) {
    try {
      const headers = new HttpHeaders();
      let token = this.authService.getToken();
      headers.append('Authorization', `bearer ${token}`)
      headers.append('Content-Type', 'application/json');
      const URL = environment.apiUrl + "/Admin/GetAllEmployeeWorkExperience?EmployeeId=" + employeeId +"CmpCode="+CmpCode;
      return this.http.get(URL,  { headers: headers })
      // .pipe(map((response: Response) => response));
    } catch (error) {
      throw error;
    }
  }

  saveEmployeeWorkExperience(dataParams) {
    try {
      const headers = new HttpHeaders();
      let token = this.authService.getToken();
      headers.append('Authorization', `bearer ${token}`)
      headers.append('Content-Type', 'application/json');
      const URL = environment.apiUrl + "/Admin/SaveEmployeeWorkExperience";
      return this.http
      .post(URL, dataParams,  { headers: headers })
      // .pipe(map((response: Response) => response));
    } catch (error) {
      throw error;
    }
  }

  deleteEmployeeWorkExperience(id,CmpCode) {
    try {
      const headers = new HttpHeaders();
      let token = this.authService.getToken();
      headers.append('Authorization', `bearer ${token}`)
      headers.append('Content-Type', 'application/json');
      const URL = environment.apiUrl + "/Admin/DeleteEmployeeWorkExperience/" + id +"/"+CmpCode;
      return this.http
      .post(URL,  { headers: headers })
      // .pipe(map((response: Response) => response));
    } catch (error) {
      throw error;
    }
  }

  // Ecucation
  getAllEmployeeEducation(employeeId,CmpCode) {
    try {
      const headers = new HttpHeaders();
      let token = this.authService.getToken();
      headers.append('Authorization', `bearer ${token}`)
      headers.append('Content-Type', 'application/json');
      const URL = environment.apiUrl + "/Admin/GetAllEmployeeEducation?EmployeeId=" + employeeId+"CmpCode="+CmpCode;
      return this.http.get(URL,  { headers: headers })
      // .pipe(map((response: Response) => response));
    } catch (error) {
      throw error;
    }
  }

  saveEmployeeEducation(dataParams,CmpCode) {
    try {
      const headers = new HttpHeaders();
      let token = this.authService.getToken();
      headers.append('Authorization', `bearer ${token}`)
      headers.append('Content-Type', 'application/json');
      const URL = environment.apiUrl + "/Admin/SaveEmployeeEducation/"+CmpCode;
      return this.http
      .post(URL, dataParams,  { headers: headers })
      // .pipe(map((response: Response) => response));
    } catch (error) {
      throw error;
    }
  }

  deleteEmployeeEducation(id,CmpCode) {
    try {
      const headers = new HttpHeaders();
      let token = this.authService.getToken();
      headers.append('Authorization', `bearer ${token}`)
      headers.append('Content-Type', 'application/json');
      const URL = environment.apiUrl + "/Admin/DeleteEmployeeEducation/" + id +"/"+CmpCode;
      return this.http
      .post(URL,  { headers: headers })
      // .pipe(map((response: Response) => response));
    } catch (error) {
      throw error;
    }
  }

  // Dependents
  getAllEmployeeDependents(employeeId,CmpCode) {
    try {
      const headers = new HttpHeaders();
      let token = this.authService.getToken();
      headers.append('Authorization', `bearer ${token}`)
      headers.append('Content-Type', 'application/json');
      const URL = environment.apiUrl + "/Admin/GetAllEmployeeDependents?EmployeeId=" + employeeId +"CmpCode="+CmpCode;
      return this.http.get(URL,  { headers: headers })
      // .pipe(map((response: Response) => response));
    } catch (error) {
      throw error;
    }
  }

  saveEmployeeDependents(dataParams,CmpCode) {
    try {
      const headers = new HttpHeaders();
      let token = this.authService.getToken();
      headers.append('Authorization', `bearer ${token}`)
      headers.append('Content-Type', 'application/json');
      const URL = environment.apiUrl + "/Admin/SaveEmployeeDependents/"+CmpCode;
      return this.http
      .post(URL, dataParams,  { headers: headers })
      // .pipe(map((response: Response) => response));
    } catch (error) {
      throw error;
    }
  }

  deleteEmployeeDependent(id,CmpCode) {
    try {
      const headers = new HttpHeaders();
      let token = this.authService.getToken();
      headers.append('Authorization', `bearer ${token}`)
      headers.append('Content-Type', 'application/json');
      const URL = environment.apiUrl + "/Admin/DeleteEmployeeDependent/" + id +"/"+CmpCode;
      return this.http
      .post(URL,  { headers: headers })
      // .pipe(map((response: Response) => response));
    } catch (error) {
      throw error;
    }
  }

  // Department
  createDepartment(dataParams,CmpCode) {
    try {
      const headers = new HttpHeaders();
      let token = this.authService.getToken();
      headers.append('Authorization', `bearer ${token}`)
      headers.append('Content-Type', 'application/json');
      const URL = environment.apiUrl + "/Admin/CreateDepartment/"+CmpCode;
      return this.http
      .post(URL, dataParams,  { headers: headers })
      // .pipe(map((response: Response) => response));
    } catch (error) {
      throw error;
    }
  }

  // Designation
  createDesignation(dataParams,CmpCode) {
    try {
      const headers = new HttpHeaders();
      let token = this.authService.getToken();
      headers.append('Authorization', `bearer ${token}`)
      headers.append('Content-Type', 'application/json');
      const URL = environment.apiUrl + "/Admin/CreateDesignation/"+CmpCode;
      return this.http
      .post(URL, dataParams,  { headers: headers })
      // .pipe(map((response: Response) => response));
    } catch (error) {
      throw error;
    }
  }

  //Location
  saveLocation(dataParams,CmpCode) {
    try {
      const headers = new HttpHeaders();
      let token = this.authService.getToken();
      headers.append('Authorization', `bearer ${token}`)
      headers.append('Content-Type', 'application/json');
      const URL = environment.apiUrl + "/Admin/SaveLocation/"+CmpCode;
      return this.http
      .post(URL, dataParams,  { headers: headers })
      // .pipe(map((response: Response) => response));
    } catch (error) {
      throw error;
    }
  }

  // Job Band
  saveJobBand(dataParams,CmpCode) {
    try {
      const headers = new HttpHeaders();
      let token = this.authService.getToken();
      headers.append('Authorization', `bearer ${token}`)
      headers.append('Content-Type', 'application/json');
      const URL = environment.apiUrl + "/Admin/SaveJobBand/"+CmpCode;
      return this.http
      .post(URL, dataParams,  { headers: headers })
      // .pipe(map((response: Response) => response));
    } catch (error) {
      throw error;
    }
  }

  validateEmployeeCode(empCode,CmpCode) {
    try {
      
      const headers = new HttpHeaders();
      let token = this.authService.getToken();
      headers.append('Authorization', `bearer ${token}`)
      headers.append('Content-Type', 'application/json');
      const URL = environment.apiUrl + "/Admin/ValidateEmployeeCode?empCode=" + empCode +"/"+CmpCode;
      return this.http.get(URL,  { headers: headers })
      // .pipe(map((response: Response) => response));
    } catch (error) {
      throw error;
    }
  }
}
