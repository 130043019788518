import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs";
import { environment } from "src/app/environment";
import { map } from "rxjs/operators";
import { AuthService } from "src/app/services/auth.service";

@Injectable({
  providedIn: 'root'
})

export class DesignationService {
  constructor(private http: HttpClient, private authService: AuthService) { }

  getDesignationList(parms): Observable<any> {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
  
    const URL = environment.apiUrl + "/Admin/GetAllDesignations";
    return this.http
      .post(URL, parms,{headers:headers})
      // .pipe(map((response: Response) => response));
  }

  addDesignation(parms): Observable<any> {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
  
    const URL = environment.apiUrl + "/Admin/CreateDesignation";
    return this.http
      .post(URL, parms,{headers:headers})
      // .pipe(map((response: Response) => response));
  }

  updateDesignation(parms): Observable<any> {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
  

    const URL = environment.apiUrl + "/Admin/UpdateDesignation";
    return this.http
      .post(URL, parms,{headers:headers})
      // .pipe(map((response: Response) => response));
  }
  getDesignationDetails(desId,Cmpcode): Observable<any> {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
  
    const URL = environment.apiUrl + "/Admin/GetDesignationById?Id=" + desId+`&Cmpcode=`+Cmpcode;
    return this.http.get(URL,{headers:headers})
    // .pipe(map((response: Response) => response));
  }



  deleteDesignation(desId): Observable<any>{ 
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
  

    const URL = environment.apiUrl + "/Admin/DeleteDesignation?Id="+desId;
    return this.http
      .post(URL, {headers:headers})
      // .pipe(map((response: Response) => response));
  }

}
