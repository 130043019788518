<div class="bg-overlady" *ngIf="userLoader">
  <div class="loader-position">
    <div class="loader"></div>
    <h2>Loading....</h2>
  </div>
</div> 

<mk-box header="" [isCollapsable]="false" [isRemovable]="false" contentStyleClasses="table-responsive">
  <div class="row" style="    margin: 0;">

    <div class="col-sm-7 no-padding">
      <h4 class="title-new">Customize Leave Policy</h4>
    </div>

    <div class="col-md-4 col-sm-4"> 
      <ejs-dropdownlist  [allowFiltering]="true" (change)="ddlSelection($event, 'Employee', 'i', 'i')" [value]="+EmpValue"
        [fields]='ActiveEmployeefields' [dataSource]='EmployeeList'> </ejs-dropdownlist>
      
      <!-- <ng-select [allowClear]="true" [(active)]="ActiveEmployee" [(items)]="EmployeeList"
        (selected)="ddlSelection($event, 'Employee', 'i', 'i')" placeholder="Select Employee">
      </ng-select> -->
    </div>
   
    <div class="col-md-1 col-sm-4 btnset text-right" style="padding-top: 46px;">
      <!-- <button class="AddLeaveType mx-auto  pull-right" type="button"   (click)="filterShow()">
        <i class="fa fa-filter" aria-hidden="true"></i>
      </button> -->

      <!-- <div class="btn-group">
        <button type="button" class="AddLeaveType mx-auto btn btn-primary btn-brand" data-toggle="modal" data-target="#exampleModalfilter2" title="Filters" (click)="filterShow()">
          <img src="../assets/img/Logo/23.png">
        </button>
      </div> -->

      <!-- <div class="btn-group">
        <ul class="paggingUl">
          <ngb-pagination [collectionSize]="myRequestsListLength" [(page)]="myRequestsPage" [pageSize]="myRequestsPageSize"></ngb-pagination>
          <li style="margin-top: -4px;">
            <span class="recordsMsg">No. of Records</span>
            <select class="NoOfpage" style="width: auto;" [(ngModel)]="myRequestsPageSize">
              <option [ngValue]="10">10</option>
              <option [ngValue]="20">20</option>
              <option [ngValue]="50">50</option>
            </select>
          </li>
        </ul>
        </div> -->

  
    </div>

    <!-- Filter Popup -->
    <!-- <div class="btn-group">
      <div class="container datashow rt-filters" *ngIf="flterShowData">
        <div class="card">
          <div class="card card-body">
            <div>
              <div class="row row-bor-bot">                   
                <div class="col-xs-9">
                  <h4>Filter</h4>
                </div>
                <div class="col-xs-3 text-right">
                  <h4 class="icon-close" (click)="closeFilter();">x</h4>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label for="status">Status</label>
                    <select [(ngModel)]="statusSelectValue" class="form-control statsuClass" style="margin-top: 10px;">
                      <option value=true selected="selected">Active</option>
                      <option value=false>InActive</option>
                    </select>
                  </div>
                </div>
              </div>
              
              
              <div class="row">               
                <div class="col-sm-12">
                  <button type="button" (click)="SideFilterFunc()" class="btn btn-primary">Search</button>
                  <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  <button type="button" (click)="ResetFilter()" class="btn btn-default">Reset</button>
                </div>                    
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

  </div>
  <div class="row">
    <div class="col-sm-12 no-padding">
      <ejs-grid #overviewgrid id='overviewgrid' [dataSource]='userAvalLeave' rowHeight='38' [allowSelection]='true'
        [allowSorting]='true' height='600' [enableHover]='false' [selectionSettings]='selectionSettings'
        [filterSettings]='filterSettings' [allowSorting]='true' [allowFiltering]='true' [enableVirtualization]='false'
        (load)='load()' [allowPaging]='true' [pageSettings]='pageSettings'>
        <e-columns>
          <e-column field='leaveTypeName' headerText='Leave Type' [filter]='filter'>
            <ng-template #template let-listData>
              <a href="" (click)="CustomizePermission ? selectLeaveType(listData.leaveTypeId) : return" 
              data-toggle="modal" data-backdrop="static"  data-target="#customizeleave" style="color: inherit;">
                <span><i class="fa fa-circle" [ngStyle]="{ color:listData.colorCode }"></i></span> 
                <span style="color: #3496db;">{{ listData.leaveTypeName }}</span>
            </a>
            </ng-template>
          </e-column>
          <e-column field='typeText' headerText='Type' [filter]='filter'>
            <ng-template #template let-listData>
              <span 
                data-backdrop="static" data-keyboard="false" data-target="#customizeleave">
                {{ listData.typeText }}
              </span>
            </ng-template>
          </e-column>
  
          <e-column field='unitText' headerText='Unit' [filter]='filter'>
            <ng-template #template let-listData>
              <span 
                data-backdrop="static" data-keyboard="false" data-target="#customizeleave">
                {{ listData.unitText }}
              </span>
            </ng-template>
          </e-column>

          <e-column field="balance" headerText='Balance' [filter]='filter'>
            <ng-template #template let-listData>
              <span 
              data-backdrop="static" data-keyboard="false" data-target="#customizeleave">
              {{ listData.balance }}
              </span>
            </ng-template>
          </e-column>

          <e-column field='Actions' [allowFiltering]='false' headerText='Action' [allowSorting]='false'>
            <ng-template #template let-listData>
              <a href="javascript:void(0)" class="view" title="View History" (click)="ViewHistoryPermission ? selectLeaveTypeViewHistory(listData.leaveTypeId) : return" data-toggle="modal" data-backdrop="static" 
                data-keyboard="false" data-target="#customizeleave">
                <i class="fa fa-history "></i>
              </a>
            </ng-template>
          </e-column>
        </e-columns>
      </ejs-grid>


      <!-- <table class="table table-hover">
        <thead>
          <tr class="table-primary">
            <th scope="col" style="text-align: left; width: 50%;">Leave Type</th>
            <th scope="col" style="text-align: left; width: 15%;">Type</th>
            <th scope="col" style="text-align: left; width: 15%;">Unit</th>
            <th scope="col" style="text-align: left; width: 10%;">Balance</th>
            <th scope="col" style="text-align: left; width: 10%;">Action</th>
          </tr>
        </thead>
        <tbody style="text-align: left;">
          <tr *ngFor="let listData of userAvalLeave; let i = index;">
            <td scope="row" (click)="CustomizePermission ? selectLeaveType(listData.leaveTypeId) : return" data-backdrop="static" data-keyboard="false" data-target="#customizeleave">
              <span><i class="fa fa-circle" [ngStyle]="{ color:listData.colorCode }"></i></span> 
              <span>{{ listData.leaveTypeName }}</span>
            </td>
            <td (click)="CustomizePermission ? selectLeaveType(listData.leaveTypeId) : return" data-backdrop="static" data-keyboard="false" data-target="#customizeleave">{{ listData.typeText }}</td>       
            <td (click)="CustomizePermission ? selectLeaveType(listData.leaveTypeId) : return" data-backdrop="static" data-keyboard="false" data-target="#customizeleave">{{ listData.unitText }}</td>     
            <td (click)="CustomizePermission ? selectLeaveType(listData.leaveTypeId) : return" data-backdrop="static" data-keyboard="false" data-target="#customizeleave">{{ listData.balance }}</td>
            <td>
              <a href="javascript:void(0)" class="view" title="View History" (click)="ViewHistoryPermission ? selectLeaveTypeViewHistory(listData.leaveTypeId) : return" data-toggle="modal" data-backdrop="static" 
                data-keyboard="false" data-target="#customizeleave">
                <i class="fa fa-history "></i>
              </a>
            </td>
          </tr>
        </tbody>
      </table> -->

      <!-- Pagination -->
      <div class="row">
      <div class="col-sm-12">
        <div class="d-flex justify-content-between p-2" class="col-sm-12 text-right">
          <!-- <ul class="paggingUl">
            <ngb-pagination [collectionSize]="myRequestsListLength" [(page)]="myRequestsPage" [pageSize]="myRequestsPageSize"></ngb-pagination>
            <li>
              <span class="recordsMsg">No. of Records</span>
              <select class="NoOfpage" style="width: auto;" [(ngModel)]="myRequestsPageSize">
                <option [ngValue]="10">10</option>
                <option [ngValue]="20">20</option>
                <option [ngValue]="50">50</option>
              </select>
            </li>
          </ul> -->
        </div>
      </div>
    </div>
    </div>
  </div> 
</mk-box>

<div id="customizeleave" class="modal fade" role="dialog">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <div class="row">
          <div class="col-sm-3">
            <h4 class="modal-title" *ngIf="!showHistory">Customize Leave Policy</h4>
            <h4 class="modal-title" *ngIf="showHistory">Leave History</h4>
          </div> 
          <div class="col-sm-2">
            <div class="form-group" *ngIf="showHistory" style="margin-bottom: 0;">
              <div class="input-group date">
                <!-- <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div> -->
                <!-- <input type="text"  class="e-input"  [(ngModel)]="vh_StartDate"
                  placeholder="From" [bsConfig]="{ containerClass: 'theme-dark-blue' }" autocomplete="off"
                  bsDatepicker [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', customTodayClass:'custom-today-class', showWeekNumbers: false }"
                  id="startDateHistory" (bsValueChange)="datepickerStartDate($event)"
                  required /> -->

                  <ejs-datepicker [value]="vh_StartDate" (change)="datepickerStartDate($event)"
                   [allowEdit]="false"   ></ejs-datepicker>
              </div>
            </div>
          </div>
          <div class="col-sm-2">
            <div class="form-group" *ngIf="showHistory" style="margin-bottom: 0;">
              <div class="input-group date">
                <!-- <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div> -->
                <!-- <input type="text"  class="e-input"  [(ngModel)]="vh_EndDate"
                  placeholder="To" [bsConfig]="{ containerClass: 'theme-dark-blue' }" autocomplete="off"
                  bsDatepicker [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', customTodayClass:'custom-today-class', showWeekNumbers: false }"
                  id="endDateHistory" [minDate]="minDate" (bsValueChange)="datepickerEndDate($event)"
                  required /> -->

                  <ejs-datepicker [value]="vh_EndDate" (change)="datepickerEndDate($event)"
                  [allowEdit]="false"   ></ejs-datepicker>
              </div>
            </div>
          </div>
          <div class="col-sm-5 text-right">
            <button type="button" (click)="onShowHistory()" class="btn btn-primary btn-brand" *ngIf="!showHistory && ViewHistoryPermission">
              <i class="fa fa-history" aria-hidden="true"></i> View History
            </button>
            <button type="button" (click)="backHistory()" class="btn btn-primary btn-brand" *ngIf="showHistory">
              <i class="fa fa-back" aria-hidden="true"></i> Back
            </button>
            <button type="button" (click)="ExportSelectedEmployeeHistory()" title="Export" class="btn btn-primary btn-brand" *ngIf="showHistory">
              <i class="fa fa-upload" aria-hidden="true"></i>
            </button>
           
            
          </div>
          <button _ngcontent-c11="" class="close" data-dismiss="modal" type="button">×</button>
        </div>
      </div>
      <div class="modal-body model-bg user-modal-height">
        <div class="box-body" style="overflow: hidden;" *ngIf="!showHistory">
          <form [formGroup]="customizePolicyForm" (ngSubmit)="saveCustomizeLeave()">
            <div class="form-group">
              <div class="row">
                <div class="col-sm-2">
                  <label for="EffectiveFromDate" class="col-form-label">Effective From</label>
                </div>
                <div class="col-sm-2">
                  <div class="input-group date">
                    <!-- <input type="text" class="e-input"  autocomplete="off" bsDatepicker
                      [bsConfig]="{dateInputFormat: 'MM-DD-YYYY',containerClass: 'theme-dark-blue', customTodayClass:'custom-today-class', showWeekNumbers: false }"
                      id="EffectiveFromDate" name="EffectiveFromDate" formControlName="EffectiveFrom" bsDatepicker  required />
                    <div class="input-group-addon">
                      <i class="fa fa-calendar"></i>
                    </div>             -->
                    <ejs-datepicker formControlName="EffectiveFrom"
                    [allowEdit]="false"   ></ejs-datepicker>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="row">
                <div class="col-sm-1" style="padding-right: 0;">
                  <div class="custom-control custom-checkbox custom-control-inline">
                    <input type="checkbox" id="isAccural" class="custom-control-input" formControlName="IsAccural"
                      [checked]="tempLeaveType.IsAccural" (change)="onChangeIsAccural($event);" name="IsAccural" />
                    <label class="col-form-label mx-auto" style="margin-left: 10px;" for="isAccural">Accrual</label>
                  </div>
                </div>
                <div class="col-sm-2" *ngIf="tempLeaveType.IsAccural == true">
                  <!-- <select class="form-control" [attr.disabled]="viewMode?'true':null && editMode? 'true':null" name="AccuralPeriod" 
                    formControlName="AccuralPeriod" (change)="onChangeAccuralPeriod($event);">
                    <option value=1>One Time</option>
                    <option value=2>Yearly</option>
                    <option value=3>Monthly</option>
                    <option value=4>Half Yearly</option>
                    <option value=5>Triannually</option>
                    <option value=6>Quarterly</option>
                    <option value=7>Bi-Monthly</option>
                    <option value=8>Semi Monthly</option>
                    <option value=9>Bi-Weekly</option>
                    <option value=10>Weekly</option>
                  </select> -->
                  <ejs-dropdownlist [allowFiltering]="true" [enabled]="!(viewMode?'true':null) && !(editMode? 'true':null)" (change)="onChangeAccuralPeriod($event)" formControlName="AccuralPeriod"
                  [fields]='AccuralPeriodfield' [dataSource]='AccuralPerioddata'> </ejs-dropdownlist>
                </div>
                <div class="col-sm-1" *ngIf="tempLeaveType.IsAccural == true">
                  <label class="col-form-label"> on </label>
                </div>
                <div class="col-sm-2 no-padding" *ngIf="tempLeaveType.IsAccural == true">
                  <!-- <select class="form-control" [attr.disabled]="viewMode?'true':null && editMode? 'true':null" formControlName="AccuralDay">
                    <option [selected]=" isEditMode? AccuralDay:'' || IsAccuralChange? days==1:''" 
                      *ngFor="let days of AccuralDayList; let i= index" [ngValue]="i+1">{{days}}</option> 
                    <option *ngFor="let item of ddlAccuralDaysList;" [ngValue]="item.id">{{ item.text }}</option>
                  </select> -->
                  <ejs-dropdownlist [allowFiltering]="true" [enabled]="!(viewMode?'true':null) && !(editMode? 'true':null)"
                  formControlName="AccuralDay" [fields]='ddlAccuralDaysListfield' [dataSource]='ddlAccuralDaysList'>
                </ejs-dropdownlist>
                </div>
                <div class="col-sm-2 no-padding" *ngIf="tempLeaveType.IsAccural == true && (tempLeaveType.AccuralPeriod != 3 && tempLeaveType.AccuralPeriod != 8 && tempLeaveType.AccuralPeriod != 9 && tempLeaveType.AccuralPeriod != 10)">
                  <!-- <select class="form-control"  [attr.disabled]="viewMode?'true':null && editMode? 'true':null" formControlName="AccuralMonth">
                    <option *ngFor="let item of ddlAccuralMonthsList" [selected]="isEditMode ? AccuralMonth : ''" [ngValue]="item.value">{{ item.name }}</option>
                  </select> -->
                  <ejs-dropdownlist [allowFiltering]="true" [enabled]="!(viewMode?'true':null) && !(editMode? 'true':null)"
                  formControlName="AccuralMonth" [fields]='ddlAccuralMonthsListfield' [dataSource]='ddlAccuralMonthsList'>
                </ejs-dropdownlist>
                </div>
                <div class="col-sm-1" style="white-space: nowrap" *ngIf="tempLeaveType.IsAccural == true">
                  <label for="noofdays" class="col-form-label" *ngIf="tempLeaveType.Unit == 1"> No. of Days </label>
                  <label for="noofdays" class="col-form-label" *ngIf="tempLeaveType.Unit == 2"> No. of Hours </label>
                </div>
                <div class="col-sm-1" *ngIf="tempLeaveType.IsAccural == true">
                  <input type="number" name="noofdays" [attr.disabled]="viewMode?'true':null && editMode? 'true':null" formControlName="AccuralNoOfDays" value="0"
                  class="e-input" (change)="onchangeAccuralNoOfDays($event);" />
                </div>
                <div class="col-sm-1" *ngIf="tempLeaveType.IsAccural == true && (tempLeaveType.AccuralPeriod == 3 || tempLeaveType.AccuralPeriod == 8 || tempLeaveType.AccuralPeriod == 9 || tempLeaveType.AccuralPeriod == 10)">
                  <label class="col-form-label" style="cursor: pointer;" (click)="openEntitlementCalendarPopup();"><i class="fa fa-calendar"></i></label>
                </div>
                <div class="col-sm-1" *ngIf="tempLeaveType.IsAccural == true && tempLeaveType.AccuralPeriod != 3" style="width: 30px;">
                  <label for="AccuralDaysInPeriod" class="col-form-label"> in </label>
                </div>
                <div class="col-sm-2" style="margin-top: -3px; width: 140px;" *ngIf="tempLeaveType.IsAccural == true && tempLeaveType.AccuralPeriod != 1">
                  <!-- <select class="form-control" name="AccuralDaysInPeriod" [attr.disabled]=" viewMode?'true':null && editMode? 'true':null" 
                    formControlName="AccuralDaysInPeriod">
                    <option [ngValue]="1">Current Accrual</option>
                    <option [ngValue]="2">Next Accrual</option>
                  </select> -->
                  <ejs-dropdownlist [allowFiltering]="true" name="AccuralDaysInPeriod" [enabled]="!(viewMode?'true':null) && !(editMode? 'true':null)"
                  formControlName="AccuralDaysInPeriod" [fields]='AccuralDaysInPeriodfield' [dataSource]='AccuralDaysInPeriod'>
                </ejs-dropdownlist>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="row">
                <div class="col-sm-1" style="padding-right: 0;">
                  <div class="custom-control custom-checkbox custom-control-inline">
                    <input type="checkbox" id="IsReset" class="custom-control-input" formControlName="IsReset" [attr.disabled]="viewMode?'true':null && editMode? 'true':null" 
                      [checked]="tempLeaveType.IsReset" (change)="onChangeIsReset($event);" name="IsReset" />
                    <label class="col-form-label mx-auto" style="margin-left: 10px;" for="IsReset">Reset</label>
                  </div>
                </div>
                <div class="col-sm-2" *ngIf="tempLeaveType.IsReset == true">
                  <!-- <select class="form-control"  [attr.disabled]="viewMode?'true':null && editMode? 'true':null"  
                    formControlName="ResetPeriod" (change)="onChangeResetPeriod($event);">
                    <option value=1>One Time</option>
                    <option value=2>Yearly</option>
                    <option value=3>Monthly</option>
                    <option value=4>Half Yearly</option>
                    <option value=5>Triannually</option>
                    <option value=6>Quarterly</option>
                    <option value=7>Bi-Monthly</option>
                    <option value=8>Semi Monthly</option>
                    <option value=9>Bi-Weekly</option>
                    <option value=10>Weekly</option>
                  </select> -->
                  <ejs-dropdownlist [allowFiltering]="true" id='ResetPeriod' [enabled]="!(viewMode?'true':null) && !(editMode? 'true':null)"  (change)="onChangeResetPeriod($event)"
                  formControlName="ResetPeriod" [fields]='ResetPeriodfield' [dataSource]='ResetPeriod' >
                </ejs-dropdownlist>
                </div>
                <div class="col-sm-1" *ngIf="tempLeaveType.IsReset == true">
                  <label class="col-form-label"> on </label>
                </div>
                <div class="col-sm-2 no-padding" *ngIf="tempLeaveType.IsReset == true">
                  <!-- <select class="form-control" [attr.disabled]="viewMode?'true':null && editMode? 'true':null" formControlName="ResetDay">
                    <option *ngFor="let item of ddlResetDaysList;" [ngValue]="item.id">{{ item.text }}</option>
                  </select> -->
                  <ejs-dropdownlist [allowFiltering]="true" [enabled]="!(viewMode?'true':null) && !(editMode? 'true':null)"
                  formControlName="ResetDay" [fields]='ddlResetDaysListfield' [dataSource]='ddlResetDaysList'>
                </ejs-dropdownlist>
                </div>
                <div class="col-sm-2 no-padding" *ngIf="tempLeaveType.IsReset == true && (tempLeaveType.ResetPeriod != 3 && tempLeaveType.ResetPeriod != 8 && tempLeaveType.ResetPeriod != 9 && tempLeaveType.ResetPeriod != 10)">
                  <!-- <select class="form-control" [attr.disabled]="viewMode?'true':null && editMode? 'true':null" formControlName="ResetMonth">
                     <option *ngFor="let mon of MonthList" [ngValue]="isEditMode? ResetMonth:'' || mon.value" [selected]="isResetChange ? mon.value==1 : ''">{{mon.name}}</option>
                    <option *ngFor="let item of ddlResetMonthsList" [selected]="isEditMode ? ResetMonth : ''" [ngValue]="item.value">{{ item.name }}</option>
                  </select> -->
                  <ejs-dropdownlist [allowFiltering]="true" [enabled]="!(viewMode?'true':null) && !(editMode? 'true':null)" 
                  formControlName="ResetMonth" [fields]='ddlResetMonthsListfield' [dataSource]='ddlResetMonthsList'>
                </ejs-dropdownlist>
                </div>
              </div>
            </div>
        
            <div class="form-group" *ngIf="tempLeaveType.IsReset == true">
              <div class="row">
                <div class="col-sm-1 col-form-label">
                  &nbsp;
                </div>
                <div class="col-sm-2 no-padding" style="margin-left: 15px;">
                  <!-- <select class="form-control" [attr.disabled]="viewMode?'true':null && editMode? 'true':null" formControlName="CarryForwardType"
                    (change)="onChangeCarryForwardType($event);">
                    <option value="1">Carry Forward</option>
                    <option value="2">Carry Forward With Expiry</option>
                    <option value="3">Carry Forward With Overall Limit</option>
                  </select> -->
                  <ejs-dropdownlist [allowFiltering]="true" [enabled]="!(viewMode?'true':null) && !(editMode? 'true':null)" (change)="onChangeCarryForwardType($event)"
                  formControlName="CarryForwardType" [fields]='CarryForwardTypefield' [dataSource]='CarryForwardType'>
                </ejs-dropdownlist>
                </div>
                <div class="col-sm-1" style="width: 100px;">
                  <input type="number" name="crValue" [attr.disabled]="viewMode?'true':null && editMode? 'true':null" formControlName="CarryForwardValue"
                    class="form-control" />
                </div>
                <div class="col-sm-2">
                  <!-- <select class="form-control" [attr.disabled]="viewMode?'true':null && editMode? 'true':null" formControlName="CarryForwardPercentageUnit"
                    (change)="onChangeCarryForwardPercentageUnit($event);">
                    <option value=1>Percentage</option>
                    <option value=2>Unit(s)</option>
                  </select> -->
                  <ejs-dropdownlist [allowFiltering]="true" [enabled]="!(viewMode?'true':null) && !(editMode? 'true':null)" (change)="onChangeCarryForwardPercentageUnit($event)"
                  formControlName="CarryForwardPercentageUnit" [fields]='CarryForwardPercentagefield' [dataSource]='CarryForwardPercentage'>
                </ejs-dropdownlist>
                </div>
                <div class="col-sm-1 no-padding" *ngIf="tempLeaveType.CarryForwardPercentageUnit != 2">
                  <label class="col-form-label"> Max. Limit </label>
                </div>
                <div class="col-sm-2" *ngIf="tempLeaveType.CarryForwardPercentageUnit != 2" style="width: 100px;">
                  <input type="number" name="CarrForLimit" [attr.disabled]=" viewMode?'true':null && editMode? 'true':null" formControlName="CarryForwardMaxLimit"
                  class="e-input" >
                </div>

                <ng-container *ngIf="tempLeaveType.CarryForwardType == 2">
                  <div class="col-sm-1 no-padding">
                    <label class="col-form-label">Expires in</label>
                  </div>
                  <div class="col-sm-1 no-padding" style="width: 80px;">
                    <input type="text" name="CarryForwardExpiresIn" (keypress)="numberOnly($event)" [attr.disabled]=" viewMode?'true':null && editMode? 'true':null" formControlName="CarryForwardExpiresIn"
                    class="e-input"  />
                  </div>
                  <div class="col-sm-1 no-padding" style="margin: -3px 0 0 10px;">
                    <!-- <select class="form-control" [attr.disabled]="viewMode?'true':null && editMode? 'true':null" formControlName="CarryForwardExpiresInType">
                      <option value=1>Month(s)</option>
                      <option value=2>Day(s)</option>
                    </select> -->
                    <ejs-dropdownlist [allowFiltering]="true" [enabled]="!(viewMode?'true':null) && !(editMode? 'true':null)"
                    formControlName="CarryForwardExpiresInType" [fields]='CarryForwardExpiresinfield' [dataSource]='CarryForwardExpiresin'>
                  </ejs-dropdownlist>
                  </div>
                </ng-container>
                
                <ng-container *ngIf="tempLeaveType.CarryForwardType == 3">
                  <div class="col-sm-1 no-padding">
                    <label class="col-form-label">Overall Limit</label>
                  </div>
                  <div class="col-sm-1 no-padding"style="width: 80px;">
                    <input type="text" name="CarryForwardOverallLimit" (keypress)="numberOnlyWithDecimal($event)" [attr.disabled]=" viewMode?'true':null && editMode? 'true':null" formControlName="CarryForwardOverallLimit"
                    class="e-input"  />
                  </div>
                </ng-container>

              </div>
            </div>

            <div class="form-group" *ngIf="tempLeaveType.IsReset == true">
              <div class="row">
                <div class="col-sm-1 col-form-label">
                  &nbsp;
                </div>
                <div class="col-sm-1" style="width: 100px;">
                  <label for="encash" class="col-form-label">Encashment</label>
                </div>
                <div class="col-sm-1" style="width: 100px;">
                  <input type="number" name="encash" [attr.disabled]="viewMode?'true':null && editMode? 'true':null" formControlName="EncashmentValue"
                  class="e-input" >
                </div>
                <div class="col-sm-2">
                  <!-- <select class="form-control" [attr.disabled]="viewMode?'true':null && editMode? 'true':null" formControlName="EncashmentPercentageUnit"
                    (change)="onChangeEncashmentPercentageUnit($event);">
                    <option value=1>Percentage</option>
                    <option value=2>Unit(s)</option>
                  </select> -->
                  <ejs-dropdownlist [allowFiltering]="true" [enabled]="!(viewMode?'true':null) && !(editMode? 'true':null)" (change)="onChangeEncashmentPercentageUnit($event);"
                  formControlName="EncashmentPercentageUnit" [fields]='EncashmentPercentageUnitfield' [dataSource]='EncashmentPercentageUnitdata'>
                </ejs-dropdownlist>
                </div>
                <div class="col-sm-1 no-padding" *ngIf="tempLeaveType.EncashmentPercentageUnit != 2">
                  <label class="col-form-label"> Max. Limit </label>
                </div>
                <div class="col-sm-2" *ngIf="tempLeaveType.EncashmentPercentageUnit != 2">
                  <input type="number" name="encash" [attr.disabled]="viewMode?'true':null && editMode? 'true':null" formControlName="EncashmentMaxLimit"
                  class="e-input" >
                </div>
              </div>
            </div>

            <!-- Opening Balance -->
            <ng-container *ngIf="IsOpeningBalanceEnable == true">
              <div class="form-group" style="margin-top: 30px;">
                <div class="row">
                  <div class="col-sm-3">
                    <label class="col-form-label ico-remove" (click)="setMoreOptionData('OpeningBalance', false)"><i class="fa fa-minus-square"></i></label>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <label class="col-form-label">Opening Balance</label>
                  </div>
                  <div class="col-sm-2">
                    <input type="number" id="OpeningBalance" name="OpeningBalance" [attr.disabled]="viewMode?'true':null && editMode? 'true':null" formControlName="OpeningBalance"
                    class="e-input"  />
                  </div>
                </div>
              </div>
            </ng-container>

            <!-- Maximum Balance -->
            <ng-container *ngIf="IsMaximumBalanceEnable == true">
              <div class="form-group" style="margin-top: 30px;">
                <div class="row">
                  <div class="col-sm-3">
                    <label class="col-form-label ico-remove" (click)="setMoreOptionData('MaximumBalance', false)"><i class="fa fa-minus-square"></i></label>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <label class="col-form-label">Maximum Balance</label>
                  </div>
                  <div class="col-sm-2">
                    <input type="number" id="MaximumBalance" name="MaximumBalance" [attr.disabled]="viewMode?'true':null && editMode? 'true':null" formControlName="MaximumBalance"
                    class="e-input"  />
                  </div>
                </div>
              </div>
            </ng-container>

            <!-- Prorate Accural -->
            <ng-container *ngIf="IsProrateAccuralEnable == true">
              <div class="form-group" style="margin-top: 30px;">
                <div class="row">
                  <div class="col-sm-3">
                    <label class="col-form-label ico-remove" (click)="setMoreOptionData('ProrateAccural', false)"><i class="fa fa-minus-square"></i></label>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <label class="col-form-label">Prorate Accrual</label>
                  </div>
                  <div class="col-sm-3">
                    <!-- <select class="form-control" [attr.disabled]="viewMode?'true':null && editMode? 'true':null" formControlName="ProrateAccuralType"
                      (change)="setprorateAccuralTypeValue($event);">
                      <option [ngValue]="1">Start of Policy</option>
                      <option [ngValue]="2">Start and End of Policy</option>
                      <option [ngValue]="3">Do not Prorate</option>
                    </select> -->
                    <ejs-dropdownlist [allowFiltering]="true" [enabled]="!(viewMode?'true':null) && !(editMode? 'true':null)" (change)="setprorateAccuralTypeValue($event)"
                    formControlName="ProrateAccuralType" [fields]='ProrateAccuralTypefield' [dataSource]='ProrateAccuralTypedata'>
                  </ejs-dropdownlist>
                  </div>
                  <div class="col-sm-1">
                    <label class="col-form-label" *ngIf="prorateAccuralType != '3'" style="color: rgb(51, 153, 148); cursor: pointer;" (click)="openProrateAccuralModal();">Advanced </label>
                  </div>
                </div>
              </div>
            </ng-container>

            <!-- Deductible Holidays -->
            <ng-container *ngIf="IsDeductibleHolidaysEnable == true">
              <div class="form-group" style="margin-top: 30px;">
                <div class="row">
                  <div class="col-sm-3">
                    <label class="col-form-label ico-remove" (click)="setMoreOptionData('DeductibleHolidays', false)"><i class="fa fa-minus-square"></i></label>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <label class="col-form-label">Deductible Holidays</label>
                  </div>
                  <div class="col-sm-3">
                    <!-- <select class="form-control" [attr.disabled]="viewMode?'true':null && editMode? 'true':null" formControlName="DeductibleHolidays">
                      <option [ngValue]="1">All Holidays</option>
                      <option [ngValue]="2">Holidays on Workdays</option>
                    </select> -->
                    <ejs-dropdownlist [allowFiltering]="true" [enabled]="!(viewMode?'true':null) && !(editMode? 'true':null)" 
                    formControlName="DeductibleHolidays" [fields]='DeductibleHolidaysfield' [dataSource]='DeductibleHolidaysdata'>
                  </ejs-dropdownlist>
                  </div>
                  <div class="col-sm-1">
                  </div>
                </div>
              </div>
            </ng-container>

            <!-- <div class="form-group row" style="margin-top: 30px;"> -->
            <div class="form-group row" style="margin-top: 10px; margin-bottom: 70px;"
              *ngIf="IsOpeningBalanceEnable != true || 
                IsMaximumBalanceEnable != true || 
                IsProrateAccuralEnable != true || 
                IsDeductibleHolidaysEnable != true">
              <div class="dropdown rt-dropdown">
                <label class="col-form-label" data-toggle="dropdown" style="color: rgb(51, 153, 148); cursor: pointer;">More Options <i class="fa fa-plus" aria-hidden="true"></i></label>
                <ul class="dropdown-menu">
                  <li *ngIf="IsOpeningBalanceEnable != true" (click)="setMoreOptionData('OpeningBalance', true)">Add Opening Balance</li>
                  <li *ngIf="IsMaximumBalanceEnable != true" (click)="setMoreOptionData('MaximumBalance', true)">Set Maximum Balance</li>
                  <li *ngIf="IsProrateAccuralEnable != true" (click)="setMoreOptionData('ProrateAccural', true)">Prorate Accrual</li>
                  <li *ngIf="IsDeductibleHolidaysEnable != true" (click)="setMoreOptionData('DeductibleHolidays', true)">Deductible Holidays</li>
                </ul>
              </div>
            </div>

            <div class="form-group row" style="margin-top: 10px; margin-left: 10px; border-top: 1px solid #ddd; padding-top: 20px;">
              <button type="submit" class="btn btn-primary btn-brand" style="width: 100px;">Save</button>
              <!-- <button type="button" class="btn btn-light" data-dismiss="modal" style="width: 100px;">Cancel</button> -->
            </div>
          </form>
        </div>

        <div *ngIf="showHistory">
          <div class="row no-padding">
            <div class="col-sm-12 no-padding">
              <ejs-grid #overviewgrid id='overviewgrid' [dataSource]='selectLeaveHistory' rowHeight='38' [allowSelection]='true'
                [allowSorting]='true' height='600' [enableHover]='false' [selectionSettings]='selectionSettings'
                [filterSettings]='filterSettings' [allowSorting]='true' [allowFiltering]='true' [enableVirtualization]='false'
                (load)='load()' [allowPaging]='true' [pageSettings]='pageSettings'>
                <e-columns>
                  <e-column field='createdDate' headerText='Date' [filter]='filter'>
                    <ng-template #template let-historyData>
                      {{ dateformat(historyData.createdDate) }}
                    </ng-template>
                  </e-column>
                  <e-column field='type' headerText='Type' [filter]='filter'>
                    <ng-template #template let-historyData>
                      <ng-container *ngIf="historyData.createdBy == null">
                        {{ historyData.type }}
                      </ng-container>
                      <ng-container *ngIf="historyData.createdBy != null">
                        <div>
                          <span>Manual Correction</span>
                          <span>
                            &nbsp;&nbsp;
                            <span title="Updated By : {{ historyData.createdBy }} | Updated On : {{ dateformat(historyData.createdDate) }}">
                              <i class="fa fa-info-circle"></i>
                            </span>
                          </span>
                        </div>
                        <small>{{ historyData.type }}</small>
                      </ng-container>
                    </ng-template>
                  </e-column>
          
                  <e-column field='added' headerText='Added' [filter]='filter'>
                  </e-column>

                  <e-column field='used' headerText='used' [filter]='filter'>
                  </e-column>
                  
                  <e-column field="balance" headerText='Balance' [filter]='filter'>
                  </e-column>

                </e-columns>
              </ejs-grid>


              <!-- <table class="table table-hover">
                <thead>
                  <tr class="table-primary" >
                    <th scope="col" style="text-align: left;">Date</th>
                    <th scope="col" style="text-align: left;">Type</th>
                    <th scope="col" style="text-align: left;">Added</th>
                    <th scope="col" style="text-align: left;">Used</th>
                    <th scope="col" style="text-align: left;">Balance</th>        
                  </tr>
                </thead>
                <tbody style="text-align: left;">
                  <ng-container *ngIf="selectLeaveHistory.length > 0">
                    <tr *ngFor="let historyData of selectLeaveHistory | slice: (myHistoryRequestsPage-1) * myHistoryRequestsPageSize : (myHistoryRequestsPage-1) * myHistoryRequestsPageSize + myHistoryRequestsPageSize">
                      <td>{{ historyData.createdDate | date }} </td>
                      <td>
                        <ng-container *ngIf="historyData.createdBy == null">
                          {{ historyData.type }}
                        </ng-container>
                        <ng-container *ngIf="historyData.createdBy != null">
                          <div>
                            <span>Manual Correction</span>
                            <span>
                              &nbsp;&nbsp;
                              <span title="Updated By : {{ historyData.createdBy }} | Updated On : {{ historyData.createdDate | date }}">
                                <i class="fa fa-info-circle"></i>
                              </span>
                            </span>
                          </div>
                          <small>{{ historyData.type }}</small>
                        </ng-container>
                      </td>
                      <td>{{ historyData.added }}</td>
                      <td>{{ historyData.used }}</td>
                      <td>{{ historyData.balance }}</td>      
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="selectLeaveHistory.length == 0">
                    <tr>
                      <td colspan="5" class="text-center">
                        <span class="noData mx-auto">No Data Available</span>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table> -->
              <!-- Pagination -->
              <!-- <div class="row">
                <div class="col-sm-12">
                  <div class="d-flex justify-content-between p-2" class="col-sm-12 text-right">
                    <ul class="paggingUl">
                      <ngb-pagination [collectionSize]="myRequestsListHistoryLength" [(page)]="myHistoryRequestsPage" [pageSize]="myHistoryRequestsPageSize"></ngb-pagination>
                      <li>
                        <span class="recordsMsg">No. of Records</span>
                        <select class="NoOfpage" style="width: auto;" [(ngModel)]="myHistoryRequestsPageSize">
                          <option [ngValue]="10">10</option>
                          <option [ngValue]="20">20</option>
                          <option [ngValue]="50">50</option>
                        </select>
                      </li>
                    </ul>
                  </div>
                </div>
              </div> -->
            </div>
          </div> 
        </div>
      </div>
    </div>
  </div>
</div>

<div id="manageEntitlementCalendar" class="modal fade" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
        <h4 class="modal-title">Entitlement Calendar</h4>
      </div>
      <div class="modal-body model-bg">
        <div class="row">
          <ng-container *ngFor="let item of tempLeaveType.EntitlementCalendarList; let i = index">
            <div class="clearfix" *ngIf="i % 3 == 0"></div>
            <div class="col-sm-4">
              <div class="box text-center">
                <div class="box-header" style="padding: 5px;">
                  <input type="hidden" [(ngModel)]="tempLeaveType.EntitlementCalendarList[i].LeavePolicyEntitlementCalendarId"
                  class="e-input"  name="leavePolicyEntitlementCalendarId-{{i}}" #leavePolicyEntitlementCalendarId="ngModel" />
                  <input type="hidden" [(ngModel)]="tempLeaveType.EntitlementCalendarList[i].Month"
                  class="e-input"   name="month-{{i}}" #month="ngModel" />
                  <label class="col-form-label">{{ item.MonthText }}</label>
                </div>
                <div class="box-body" style="padding: 5px;">
                  <input type="number" id="count-{{i}}" autocomplete="off" style="width: 60px;"
                    [(ngModel)]="tempLeaveType.EntitlementCalendarList[i].Count" name="count-{{i}}" #count="ngModel" />
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="modal-footer text-right">
        <button type="button" class="btn btn-primary btn-brand" data-dismiss="modal">Ok</button>
      </div>
    </div>
  </div>
</div>

<div id="manageProrateAccuralModal" class="modal fade" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
        <h4 class="modal-title">Prorate Accrual</h4>
      </div>
      <div class="modal-body model-bg">
        <div class="col-sm-12 col-xs-12 model-body" *ngIf="prorateAccural !== null && prorateAccural !== undefined">
          <form name="form" (ngSubmit)="u.form.valid && saveProrateAccural()" autocomplete="off" #u="ngForm" novalidate>
            <div class="m-portlet__body">
              <input type="hidden" class="e-input"  name="leaveTypeId" [(ngModel)]="prorateAccural.leaveTypeId">
              <input type="hidden" class="e-input"  name="employeeId" [(ngModel)]="prorateAccural.employeeId">
              <div class="form-group row">
                <div class="col-sm-12">
                  <label for="chckProrateByMonths">Prorate By</label>
                </div>
                <div class="col-sm-12">
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" class="custom-control-input" id="chckProrateByMonths" [attr.disabled]="viewMode?'true':null && editMode? 'true':null" 
                      name="prorateBy" [(ngModel)]="prorateAccural.prorateBy" #prorateBy="ngModel" [value]="true" checked />
                    <label class="col-form-label mx-auto" style="margin-left: 10px;" for="chckProrateByMonths">Number of effective months</label>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

                    <input type="radio" class="custom-control-input" id="chckProrateByDays" [attr.disabled]="viewMode?'true':null && editMode? 'true':null"
                      name="prorateBy" [(ngModel)]="prorateAccural.prorateBy" #prorateBy="ngModel" [value]="false" />
                    <label class="col-form-label mx-auto" style="margin-left: 10px;" for="chckProrateByDays">Number of effective days</label>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-12">
                  <label for="chckProrateByMonths">Round Off</label>
                </div>
                <div class="col-sm-4">
                  <div class="custom-control custom-checkbox custom-control-inline">
                    <input type="checkbox" class="custom-control-input" id="chckRoundOff" [attr.disabled]="viewMode?'true':null && editMode? 'true':null" 
                      name="isRoundOff" [(ngModel)]="prorateAccural.isRoundOff" #isRoundOff="ngModel" />
                    <label class="col-form-label mx-auto" style="margin-left: 10px;" for="chckRoundOff">Round Off to</label>
                  </div>
                </div>
                <div class="col-sm-4">
                  <select class="form-control" name="roundOffToValue" [(ngModel)]="prorateAccural.roundOffToValue" style="margin-top: 0;"
                    #roundOffToValue="ngModel" [attr.disabled]="(viewMode?'true':null && editMode? 'true':null) || !prorateAccural.isRoundOff ? 'true' : null">
                    <option *ngFor="let roundOff of roundOffValueSelectList" [ngValue]="roundOff.value">{{ roundOff.name }}</option>
                  </select>
                </div>
                <div class="col-sm-4">
                  <select class="form-control" name="roundOffToType" [(ngModel)]="prorateAccural.roundOffToType" style="margin-top: 0;"
                    #roundOffToType="ngModel" [attr.disabled]="(viewMode?'true':null && editMode? 'true':null) || !prorateAccural.isRoundOff ? 'true' : null">
                    <option *ngFor="let roundOff of roundOffTypeSelectList" [ngValue]="roundOff.value">{{ roundOff.name }}</option>
                  </select>
                </div>
              </div>
              
              <div class="form-group row" *ngIf="prorateAccural.prorateBy == true">
                <div class="col-sm-12">
                  <label>First Month Rule</label>
                </div>
                <div class="col-sm-12" *ngIf="prorateAccural.prorateAccuralFirstMonthList.length === 0">
                  <label (click)="addFirstMonthRule()"><a href="javascript: void(0);">Define rule for the first monthof the policy</a></label>
                </div>
                <div class="col-sm-12" *ngIf="prorateAccural.prorateAccuralFirstMonthList.length > 0">
                  <table class="table table-bordered user-table text-left">
                    <thead>
                      <tr>
                        <th style="width: 10%;"></th>
                        <th style="width: 30%;">From</th>
                        <th style="width: 30%;">To</th>
                        <th style="width: 30%;">Count</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let rule of prorateAccural.prorateAccuralFirstMonthList; let i=index">
                        <td class="text-center">
                          {{i + 1}}
                          <input type="hidden" [(ngModel)]="prorateAccural.prorateAccuralFirstMonthList[i].leavePolicyPAFMId"
                          class="e-input"  name="leavePolicyPAFMId-{{i}}" #leavePolicyPAFMId="ngModel" />
                        </td>
                        <td>
                          <select class="form-control" [(ngModel)]="prorateAccural.prorateAccuralFirstMonthList[i].from" 
                            name="fm-from-{{i}}" #from="ngModel" style="margin-top: 0;" (change)='onFirstRuleFromSelected($event, i, prorateAccural.prorateAccuralFirstMonthList[i].from)'
                            [disabled]="viewMode?'true':null && editMode? 'true':null" 
                            [ngClass]="{ 'is-invalid': u.submitted && from.invalid }" required>
                            <option *ngFor="let item of prorateAccuralDayList" [ngValue]="item.id">{{ item.text }}</option>
                          </select>
                          <span *ngIf="duplicateFirstMonthRuleFrom[i]=='false'" class="error">Invalid selection.</span>
                          <div *ngIf="u.submitted && from.invalid" class="invalid-feedback error">
                            <div *ngIf="from.errors.required">From is required</div>
                          </div>
                        </td>
                        <td>
                          <select class="form-control" [(ngModel)]="prorateAccural.prorateAccuralFirstMonthList[i].to" 
                            name="fm-to-{{i}}" #to="ngModel" style="margin-top: 0;" (change)='onFirstRuleToSelected($event, i, prorateAccural.prorateAccuralFirstMonthList[i].to)'
                            [disabled]="viewMode?'true':null && editMode? 'true':null" 
                            [ngClass]="{ 'is-invalid': u.submitted && to.invalid }" required>
                            <option *ngFor="let item of prorateAccuralDayList" [ngValue]="item.id">{{ item.text }}</option>
                          </select>
                          <span *ngIf="duplicateFirstMonthRuleTo[i]=='false'" class="error">Invalid selection.</span>
                          <div *ngIf="u.submitted && to.invalid" class="invalid-feedback error">
                            <div *ngIf="to.errors.required">To is required</div>
                          </div>
                        </td>
                        <td>
                          <input type="number" class="e-input"  [(ngModel)]="prorateAccural.prorateAccuralFirstMonthList[i].count" 
                            name="fm-count-{{i}}" #count="ngModel" style="margin-top: 0;"
                            [attr.disabled]="viewMode?'true':null && editMode? 'true':null" 
                            [ngClass]="{ 'is-invalid': u.submitted && to.invalid }" required />
                          <div *ngIf="u.submitted && count.invalid" class="invalid-feedback error">
                            <div *ngIf="count.errors.required">Count is required</div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colspan="4" class="text-center">
                          <label (click)="addFirstMonthRule()"><a href="javascript: void(0);"><i class="fa fa-plus"></i> Add Rule</a></label>        
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>

              <div class="form-group row" *ngIf="prorateAccural.prorateBy == true && prorateAccuralType == '2'">
                <div class="col-sm-12">
                  <label>Last Month Rule</label>
                </div>
                <div class="col-sm-12" *ngIf="prorateAccural.prorateAccuralLastMonthList.length === 0">
                  <label (click)="addLastMonthRule()"><a href="javascript: void(0);">Define rule for the last month of the policy</a></label>
                </div>
                <div class="col-sm-12" *ngIf="prorateAccural.prorateAccuralLastMonthList.length > 0">
                  <table class="table table-bordered user-table text-left">
                    <thead>
                      <tr>
                        <th style="width: 10%;"></th>
                        <th style="width: 30%;">From</th>
                        <th style="width: 30%;">To</th>
                        <th style="width: 30%;">Count</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let rule of prorateAccural.prorateAccuralLastMonthList; let i=index">
                        <td class="text-center">
                          {{i + 1}}
                          <input type="hidden" class="e-input"  [(ngModel)]="prorateAccural.prorateAccuralLastMonthList[i].leavePolicyPALMId"
                            name="leavePolicyPALMId-{{i}}" #leavePolicyPALMId="ngModel" />
                        </td>
                        <td>
                          <select class="form-control" [(ngModel)]="prorateAccural.prorateAccuralLastMonthList[i].from" 
                            name="lm-from-{{i}}" #from="ngModel" style="margin-top: 0;" (change)='onLastRuleFromSelected($event, i, prorateAccural.prorateAccuralLastMonthList[i].from)'
                            [disabled]="viewMode?'true':null && editMode? 'true':null" 
                            [ngClass]="{ 'is-invalid': u.submitted && from.invalid }" required>
                            <option *ngFor="let item of prorateAccuralDayList" [ngValue]="item.id">{{ item.text }}</option>
                          </select>
                          <span *ngIf="duplicateLastMonthRuleFrom[i]=='false'" class="error">Invalid selection.</span>
                          <div *ngIf="u.submitted && from.invalid" class="invalid-feedback error">
                            <div *ngIf="from.errors.required">From is required</div>
                          </div>
                        </td>
                        <td>
                          <select class="form-control" [(ngModel)]="prorateAccural.prorateAccuralLastMonthList[i].to" 
                            name="lm-to-{{i}}" #to="ngModel" style="margin-top: 0;" (change)='onLastRuleFromSelected($event, i, prorateAccural.prorateAccuralLastMonthList[i].to)'
                            [disabled]="viewMode?'true':null && editMode? 'true':null" 
                            [ngClass]="{ 'is-invalid': u.submitted && to.invalid }" required>
                            <option *ngFor="let item of prorateAccuralDayList" [ngValue]="item.id">{{ item.text }}</option>
                          </select>
                          <span *ngIf="duplicateLastMonthRuleTo[i]=='false'" class="error">Invalid selection.</span>
                          <div *ngIf="u.submitted && to.invalid" class="invalid-feedback error">
                            <div *ngIf="to.errors.required">To is required</div>
                          </div>
                        </td>
                        <td>
                          <input type="text" class="e-input"   (keypress)="numberOnlyWithDecimal($event)" [(ngModel)]="prorateAccural.prorateAccuralLastMonthList[i].count" 
                            name="lm-count-{{i}}" #count="ngModel" style="margin-top: 0;"
                            [attr.disabled]="viewMode?'true':null && editMode? 'true':null" 
                            [ngClass]="{ 'is-invalid': u.submitted && to.invalid }" required />
                          <div *ngIf="u.submitted && count.invalid" class="invalid-feedback error">
                            <div *ngIf="count.errors.required">Count is required</div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colspan="4" class="text-center">
                          <label (click)="addLastMonthRule()"><a href="javascript: void(0);"><i class="fa fa-plus"></i> Add Rule</a></label>        
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>

            </div>
            <div class=" form-group pull-left" *ngIf="!viewMode">
              <div class="m-form__actions">
                <button type="submit" class="btn btn-primary btn-brand" (click)="u.submitted = true">Save</button>
                <!-- <button type="button" class="btn btn-light" data-dismiss="modal">Cancel</button> -->
              </div>
            </div>
          </form>
        </div>
      </div>

    </div>
  </div>
</div>
 
<!-- pop up model  -->
<div id="leaveTypeMessageModel" class="modal fade" role="dialog">
  <div class="modal-dialog modal-confirm modal-sm">
    <div class="modal-content" style="border-radius: 10px; top: 150px; left: 350px;">
      <div class="modal-header success-header">
        <div class="icon-box" *ngIf="showSuccess">
          <i class="fa fa-check"></i>
        </div>
        <div class="icon-box-remove" *ngIf="!showSuccess">
          <i class="fa fa-remove"></i>
        </div>
      </div>
      <div class="modal-body">
        <p class="text-center">{{showMessage}}</p>
      </div>
      <div class="modal-footer">
        <button class="btn btn-success btn-block" data-dismiss="modal">
          OK
        </button>
      </div>
    </div>
  </div>
</div>



<!-- Modal -->
<div class="modal fade small-filter" id="exampleModalfilter2" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Filters</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="width: 100%; display: inline-block;">
        <div class="container datashow rt-filters no-padding" *ngIf="flterShowData">
          <div class="card">
            <div class="card card-body">
              <div>
                
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="status">Status</label>
                      <select [(ngModel)]="statusSelectValue" class="form-control statsuClass" style="margin-top: 10px;">
                        <option value=true selected="selected">Active</option>
                        <option value=false>InActive</option>
                      </select>
                    </div>
                  </div>
                </div>
                
                
                <div class="row">               
                  <div class="col-sm-12">
                    <button type="button" (click)="SideFilterFunc()" class="btn btn-primary">Search</button>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <button type="button" (click)="ResetFilter()" class="btn btn-default">Reset</button>
                  </div>                    
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</div>