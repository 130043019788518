<div class="bg-overlady" *ngIf="userLoader">
  <div class="loader-position">
    <div class="loader"></div>
    <h2>Loading....</h2>
  </div>
</div>

<mk-box header="" [isCollapsable]="false" [isRemovable]="false" contentStyleClasses="table-responsive">
  <div class="row" style="margin: 0;">
    <div class="col-sm-4 no-padding">
      <h4 class="title-new">Daily Report</h4>
    </div>
    <div class="col-sm-4">
      <div class="navigation-buttons">
        <button type="button" class="btn" title="Previous" (click)="navigatePrevious($event)">
          <svg id="i-chevron-left" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="20" height="20" fill="none"
            stroke="#204d74" stroke-linecap="round" stroke-linejoin="round" stroke-width="3">
            <path d="M20 30 L8 16 20 2" />
          </svg>
        </button>
        <h4 style="display: inline;">{{ navigationText }}</h4>
        <button type="button" class="btn" title="Next" (click)="navigateNext($event)">
          <svg id="i-chevron-right" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="20" height="20"
            fill="none" stroke="#204d74" stroke-linecap="round" stroke-linejoin="round" stroke-width="3">
            <path d="M12 30 L24 16 12 2" />
          </svg>
        </button>
      </div>
    </div>
    <div class="col-sm-4 text-right btnset" style="padding-top: 50px;">
      <button class="btn btn-primary btn-brand sm mx-auto" (click)="getChart()">
        <i class="fa fa-bar-chart" aria-hidden="true"></i>
      </button>
      <span>&nbsp;&nbsp;&nbsp;</span>
      <button class="btn btn-primary btn-brand sm mx-auto" (click)="getDailyRepo()">
        <i class="fa fa-list-ul" aria-hidden="true"></i>
      </button>
      <span>&nbsp;&nbsp;&nbsp;</span>
      <!-- <button class="mx-auto" type="button" id="navbarDropdown" (click)="filterShow()"  role="button" data-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false"><i class="fa fa-filter" aria-hidden="true"></i>
      </button> -->

      <!-- <div class="btn-group">
        <button type="button" class="AddLeaveType mx-auto btn btn-primary btn-brand" data-toggle="modal" data-target="#exampleModalfilter2" title="Filters" (click)="filterShow()">
          <img src="../assets/img/Logo/23.png">
        </button>
      </div> -->

      <!-- Filter and Export -->
      <div class="btn-group">
        <!-- <div class="container datashow rt-filters" *ngIf="flterShowData">
          <div class="card">
            <div class="card card-body">
              <div>
                <div class="row row-bor-bot">                   
                  <div class="col-xs-9">
                    <h4>Filter</h4>
                  </div>
                  <div class="col-xs-3 text-right">
                    <h4 class="icon-close" (click)="closeFilter();">x</h4>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="depart">From</label>
                      <div class="input-group date">
                        <div class="input-group-addon">
                          <i class="fa fa-calendar"></i>
                        </div>
                        <input type="text" class="form-control" name="filterDate" [(ngModel)]="filterDate"
                          placeholder="From" autocomplete="off" bsDatepicker style="margin-top: 0; margin-bottom: 0;"
                          [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', containerClass: 'theme-dark-blue', customTodayClass: 'custom-today-class', showWeekNumbers: false }"
                          autocomplete="off" bsDatepicker id="dpDate" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="leaveType">Leave Type</label>
                      <ng-select [allowClear]="true" [(active)]="ActiveLeaveTypes" [(items)]="ddlLeaveTypeList"
                        (selected)="ddlSelection($event, 'LeaveType')" placeholder="All Leave Types"
                        #ddlFilterLeaveType (opened)="closeDropdownList(ddlFilterLeaveType)"
                        (removed)="removeFilterDropdownValue($event, 'LeaveType')">
                      </ng-select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="depart">Department</label>
                      <ng-select [allowClear]="true" [(active)]="ActiveDepartments" [(items)]="ddlDepartmentList"
                        (selected)="ddlSelection($event, 'Department')" placeholder="All Department"
                        #ddlFilterDepartments (opened)="closeDropdownList(ddlFilterDepartments)"
                        (removed)="removeFilterDropdownValue($event, 'Department')">
                      </ng-select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="location">Location</label>
                      <ng-select [allowClear]="true" [(active)]="ActiveLocations" [(items)]="ddlLocationList"
                        (selected)="ddlSelection($event, 'Location')" placeholder="All Location"
                        #ddlFilterLocations (opened)="closeDropdownList(ddlFilterLocations)"
                        (removed)="removeFilterDropdownValue($event, 'Location')">
                      </ng-select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="desigation" >Desigation</label>
                      <ng-select [allowClear]="true" [(active)]="ActiveDesignations" [(items)]="ddlDesignationList"
                        (selected)="ddlSelection($event, 'Desigation')" placeholder="All Desigation"
                        #ddlFilterDesigations (opened)="closeDropdownList(ddlFilterDesigations)"
                        (removed)="removeFilterDropdownValue($event, 'Desigation')">
                      </ng-select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="depart">User Status</label>
                      <select class="form-control" name="filterUserStatus" [(ngModel)]="filterUserStatus">
                        <option value=null>All</option>
                        <option value="0">Active</option>
                        <option value="1">Inactive</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">               
                  <div class="col-sm-12">
                    <button type="button" (click)="processFilters()" class="btn btn-primary btn-brand">Search</button>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <button type="button" (click)="resetFilters()" class="btn btn-default">Reset</button>
                  </div>                    
                </div>
              </div>
            </div>
          </div>
        </div> -->

        <div class="dropdown rt-dropdown">
          <label class="col-form-label btn btn-primary btn-brand" data-toggle="dropdown"><img src="../assets/img/Logo/22.png" aria-hidden="true" class="fa fa-ellipsis-h"></label>
          <ul class="dropdown-menu pull-right">
            <!-- <li (click)="ExportExcel()"> Export</li> -->
            <li>
              <a (click)="ExportExcel()">Excel</a>
            </li>
            <li>
              <a (click)="printList('tableData')">Print</a>
            </li>
            <li>
              <a (click)="exportTableToCSV()">CSV</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="row" id="tableData">
    <div class="col-sm-12 no-padding" *ngIf="showlist">
      <!-- <table class="table table-hover">
        <thead>
          <tr class="table-primary">
            <th scope="col" style="text-align: left; width: 25%;">Employee Name</th>
            <th scope="col" style="text-align: left; width: 15%;">Leave Type Name</th>
            <th scope="col" style="text-align: left; width: 10%;">Type</th>
            <th scope="col" style="text-align: left; width: 30%;">Reason</th>
            <th scope="col" style="text-align: left; width: 20%;">Approval Status</th>
          </tr>
        </thead>
        <tbody style="text-align: left;">
          <ng-container *ngIf="reportList.length > 0">
            <tr *ngFor="let item of reportList">
              <td scope="row">
                <span>
                  <img class="img-circle img-sm" src="./../../../assets/img/avatar5.png" alt="User Image">
                </span>
                <span>&nbsp;</span>
                <span>{{ item.empCode }} &nbsp;</span>
                <label>{{ item.employeeName }}</label>
              </td>
              <td>{{ item.leaveTypeName }}</td>       
              <td>{{ item.type }}</td>       
              <td>
                {{ item.leaveReason }}
              </td>
              <td>
                {{ item.status }}
              </td>
            </tr>
          </ng-container>
          <ng-container *ngIf="reportList.length == 0">
            <tr>
              <td colspan="5" class="text-center">
                <span>No data available</span>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table> -->
<!-- {{reportList |json}} -->
      <ejs-grid #overviewgrid id='overviewgrid' [dataSource]='reportList' rowHeight='38' [allowSelection]='true'
      [allowSorting]='true' height='600' [enableHover]='false' [selectionSettings]='selectionSettings'
      [filterSettings]='filterSettings' [allowSorting]='true' [allowFiltering]='true' [enableVirtualization]='false'
      (load)='load()' [allowPaging]='true' [pageSettings]='pageSettings'  [allowExcelExport]='true' [allowPdfExport]="true">
      <e-columns>
        <e-column [visible]= "false" field='employeeId' headerText='Employee ID' [filter]='filter' clipMode='EllipsisWithTooltip'> </e-column>
        <e-column field='employeeName' headerText='Employee Name' [filter]='filter' clipMode='EllipsisWithTooltip'>
          <ng-template #template let-data>
                <span>
                  <!-- <img src="../assets/img/Logo/male.png" style="width: 28px;margin-right: 5px;"> {{data.userName}} -->
                  <img src="{{userImage(data)}}" style="width: 28px;margin-right: 5px; border-radius: 50%;">
                  {{ data.empCode }}- 
                  {{ data.employeeName }}
                </span>
          </ng-template>

        </e-column>
        <e-column  field='email' headerText='Email' [filter]='filter' clipMode='EllipsisWithTooltip'> </e-column>
        <e-column field='leaveTypeName' headerText='Leave Type Name' [filter]='filter' clipMode='EllipsisWithTooltip'> </e-column>

        <e-column field='type' headerText='Type' [filter]='filter' clipMode='EllipsisWithTooltip'> </e-column>

        <e-column field='leaveReason' headerText='Reason' [filter]='filter' clipMode='EllipsisWithTooltip'> </e-column>

        <e-column field='status' headerText='Approval Status' [filter]='filter' clipMode='EllipsisWithTooltip'>
          <ng-template #template let-data>
                  
            <span *ngIf="data.status=='Rejected'" class="status-icon">
              <!-- <i aria-hidden="true" class="fa fa-thumbs-down rejected" style="color: red;"></i>  -->
              <img src="../assets/img/Logo/Reject1.png" style="width: 20px;margin-right: 4px;" title="Rejected">
            </span>

            <span *ngIf="data.status=='Approved'" class="status-icon">
              <img src="../assets/img/Logo/Approve1.png" style="width: 20px;margin-right: 4px;"
                title="Approved">
              <!-- <i aria-hidden="true" class="fa fa-thumbs-up approved" style="color: #07b676;"></i>  -->
            </span>

            <span *ngIf="data.status=='Pending' || data.expenseStatus==''" class="status-icon">
              <img src="../assets/img/Logo/Pending.gif" style="width: 22px;margin-right: 4px;" title="Pending">
              <!-- <i class="fa fa-hourglass-start" aria-hidden="true" style="color: #fb9c3f;"></i>  -->
            </span>
            <span *ngIf="data.status=='Cancelled' || data.expenseStatus==''" class="status-icon">
              <img src="../assets/img/Logo/cross-icon.png" style="width: 22px;margin-right: 4px;" title="Cancelled">
              <!-- <i class="fa fa-hourglass-start" aria-hidden="true" style="color: #fb9c3f;"></i>  -->
            </span>
        
          </ng-template>
        </e-column>

      </e-columns>
    </ejs-grid>


    </div>
    <div class="col-sm-12 no-padding" *ngIf="showchart">
      
      <div class="card-box" *ngIf="chartData.length > 0">
        <div class="card-box-body">
          <div class="row">
            <div id="dvChart" class="col-sm-8">
              <highcharts-chart  [Highcharts]="highcharts" [options]="chartOptions" 
                style="width: 97%; height: 400px; display: block;">
              </highcharts-chart>
            </div>
            <div class="col-sm-4">
              
              <div class="form-group row">
                <div class="dropdown rt-dropdown">
                  <label class="col-form-label" data-toggle="dropdown" style="cursor: pointer;"><i class="fa fa-align-justify" aria-hidden="true"></i></label>
                  <ul class="dropdown-menu">
                    <li (click)="downloadChart(1)">Download PNG image</li>
                    <li (click)="downloadChart(2)">Download JPEG image</li>
                    <li (click)="downloadChart(3)">Download PDF document</li>
                  </ul>
                </div>
              </div>

              <div class="report-summary">
                <ul>
                  <li *ngFor="let item of chartData">
                    <div class="report-summary-item">
                      <div class="row">
                        <div class="col-xs-10">
                          <span>{{ item[0] }}</span>
                        </div>
                        <div class="col-xs-2 text-right">
                          <label>{{ item[1] }}</label>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

            </div>
          </div>
        </div>
      </div>

      <ng-container *ngIf="chartData.length == 0">
        <table class="table table-hover">
          <tr>
            <td class="text-center">
              <span>No data available</span>
            </td>
          </tr>
        </table>
      </ng-container>

    </div>
    

  </div>
</mk-box>




<!-- Modal -->
<div class="modal fade small-filter" id="exampleModalfilter2" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Filters</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="width: 100%; display: inline-block;">
        <div class="container datashow rt-filters" *ngIf="flterShowData">
          <div class="card">
            <div class="card card-body">
              <div>
              
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="depart">From</label>
                      <div class="input-group date">
                        <div class="input-group-addon">
                          <i class="fa fa-calendar"></i>
                        </div>
                        <input type="text" class="e-input" name="filterDate" [(ngModel)]="filterDate"
                          placeholder="From" autocomplete="off" bsDatepicker style="margin-top: 0; margin-bottom: 0;"
                          [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', containerClass: 'theme-dark-blue', customTodayClass: 'custom-today-class', showWeekNumbers: false }"
                          autocomplete="off" bsDatepicker id="dpDate" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="leaveType">Leave Type</label>
                      <ng-select [allowClear]="true" [(active)]="ActiveLeaveTypes" [(items)]="ddlLeaveTypeList"
                        (selected)="ddlSelection($event, 'LeaveType')" placeholder="All Leave Types"
                        #ddlFilterLeaveType (opened)="closeDropdownList(ddlFilterLeaveType)"
                        (removed)="removeFilterDropdownValue($event, 'LeaveType')">
                      </ng-select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="depart">Department</label>
                      <ng-select [allowClear]="true" [(active)]="ActiveDepartments" [(items)]="ddlDepartmentList"
                        (selected)="ddlSelection($event, 'Department')" placeholder="All Department"
                        #ddlFilterDepartments (opened)="closeDropdownList(ddlFilterDepartments)"
                        (removed)="removeFilterDropdownValue($event, 'Department')">
                      </ng-select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="location">Location</label>
                      <ng-select [allowClear]="true" [(active)]="ActiveLocations" [(items)]="ddlLocationList"
                        (selected)="ddlSelection($event, 'Location')" placeholder="All Location"
                        #ddlFilterLocations (opened)="closeDropdownList(ddlFilterLocations)"
                        (removed)="removeFilterDropdownValue($event, 'Location')">
                      </ng-select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="desigation" >Designation</label>
                      <ng-select [allowClear]="true" [(active)]="ActiveDesignations" [(items)]="ddlDesignationList"
                        (selected)="ddlSelection($event, 'Designation')" placeholder="All Designation"
                        #ddlFilterDesignations (opened)="closeDropdownList(ddlFilterDesignations)"
                        (removed)="removeFilterDropdownValue($event, 'Designation')">
                      </ng-select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="depart">User Status</label>
                      <select class="form-control" name="filterUserStatus" [(ngModel)]="filterUserStatus">
                        <option value=null>All</option>
                        <option value="0">Active</option>
                        <option value="1">Inactive</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">               
                  <div class="col-sm-12">
                    <button type="button" (click)="processFilters()" class="btn btn-primary btn-brand">Search</button>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <button type="button" (click)="resetFilters()" class="btn btn-default">Reset</button>
                  </div>                    
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</div>