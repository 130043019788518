import { Injectable } from "@angular/core";
import { Http, Response, Headers, RequestOptions } from "@angular/http";
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from "src/app/environment";
import { Observable, BehaviorSubject, Subject, of } from "rxjs";
import { AuthService } from "../auth.service";
declare var $: any;
@Injectable({
  providedIn: "root",
})
export class ExpenseHttpService {
  constructor(private _http: Http, private httpClient: HttpClient,
    private authService: AuthService) { }

  Getaddresscode(CmpCode: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Admin/Getaddresscode?Cmpcode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }


  Getprecustomercode(CmpCode: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Admin/Getprecustomercode?Cmpcode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  Getpreemployeecode(CmpCode: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Admin/Getpreemployeecode?Cmpcode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetResourceAllocationWithTask(CmpCode: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Expanse/GetResourceAllocationWithTask?employeeid=0&departmentid=0&designationid=0&overallstatus=0&CmpCode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetAssignHistory(params: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Project/Getassignhistory?userid=` + params.userid + `&projectid=` + params.projectid + `&Cmpcode=` + params.CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  getInHandRequests(params: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Project/GetRequestpendinginhand?projectid=`+params.projetcId+"&userid="+params.userId+"&cmpcode="+params.cmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  getTransferemployee(params: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Project/GetTransferemployee?employeeid=`+params.userId+ "&cmpcode="+params.cmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  saveTransferemployee(params) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Project/Resourcefortransfer`, params, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  GetNotifications(params: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Message/getNotification?userId=` + params.UserId + `&roleId=` + params.roleId + `&notiDate=` + params.date + `&CmpCode=` + params.CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetNotificationLogin(params: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Message/getNotificationLogin?userId=` + params.UserId + `&roleId=` + params.roleId + `&notiDate=` + params.date + `&CmpCode=` + params.CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  setRedNotification(notificationId, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Message/setRedNotification?notificationId=` + notificationId + `&CmpCode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  setRedAllNotification(userId, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Message/setRedAllNotification?userid=` + userId + `&CmpCode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetAllCountryForSelect(CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Admin/GetAllCountryForSelect?CmpCode=`+CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  GetStateByCountry(countryId, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Admin/GetStateByCountry?id=` + countryId + `&CmpCode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  GetCityByState(StateId, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Admin/GetCitiesByState?Id=` + StateId + `&CmpCode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  GetzipcodeBycity(CityId, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Admin/GetZipcodeByCity?id=` + CityId + `&CmpCode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  GetZoneByCountry(countryName, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Accounts/GetContryTimeZone?countryName=` + countryName + `&CmpCode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  Getformassignment(UserId, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Expanse/Getformassignment?userid=` + UserId + `&CmpCode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetLoginusers(UserId, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Admin/GetLoginusers?userid=` + UserId + `&CmpCode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  GetFininacialYear(CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}/Admin/GetCurrentFinancialYear?cmpCode=${CmpCode}`, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetChangeRequest(requestid, userId, CmpCode, projectid, type) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(
        `${environment.apiUrl}Analytics/GetExchangerequest?requestid=` +
        requestid + `&userId=` + userId + `&CmpCode=` + CmpCode + `&projectid=` + projectid + `&type=` + type, { headers: headers }

      )
      .map((response: Response) => response.json())
      .toPromise();
  }
  GetChangePhaseProject(projectId, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(
        `${environment.apiUrl}Project/GetPhasebyProject?projectId=` +
        projectId + `&CmpCode=` + CmpCode, { headers: headers }

      )
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetUserCommercial(projectId, userid, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(
        `${environment.apiUrl}Project/Getusercommercial?projectid=` + projectId + `&userid=` + userid + `&CmpCode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetcompanyDetail(companyId: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Admin/GetcompanyDetail?companyId=` + companyId, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetFormpermission(roleId: any, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Advancepayment/GetFormpermission?roleId=` + roleId + `&CmpCode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetAllEmployeeForSelect(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Timesheet/GetAllEmployeeForSelect`, formdata,{headers:headers}
      )
      .map((response: Response) => response.json())
      .toPromise();
  }

  // GetAllEmployeeForSelectWithLoginId(userid,cmpcode){
  //   return this._http
  //     .post(`${environment.apiUrl}project/assignmyteam?employeeid=`+userid+'&Cmpcode'+cmpcode)
  //     .map((response: Response) => response.json())
  //     .toPromise();
  // }
  GetAllEmployeeForSelectWithLoginId(userid, cmpcode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}project/assignmyteam?employeeid=` + userid + `&CmpCode=` + cmpcode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  GetOwnerByTaskOrSubtask(projectId,id, cmpcode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}project/GetOwnerByTaskOrSubtask?ProjectId=`+projectId+`&TaskOrSubTaskId=`+id+`&CmpCode=`+cmpcode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  GetAllEmployeeForSelectWithLoginIdforProject(userid, cmpcode, projectid) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}project/assignmyteamforproject?employeeid=` + userid + `&CmpCode=` + cmpcode + `&projectid=` + projectid, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetAllEmployeeForSelectWithLoginIdforProjectWithDate(userid, date, cmpcode, projectid) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}project/assignmyteamforproject?employeeid=` + userid + `&date=`+date+`&CmpCode=` + cmpcode + `&projectid=` + projectid, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  getDateBelongsToUser(userid, cmpcode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Expanse/Getreportdate?userid=`+userid+`&cmpcode=`+cmpcode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  Getprojectuploaddocument(params: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}project/Getprojectuploaddocument?projectid=` + params.projectid + `&pdocumentId=` + params.pdocumentId + `&cmpcode=` + params.cmpcode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetAllProjectByEmployeeForSelect(employeeId: any, formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Timesheet/GetAllProjectByEmployeeForSelect/` + employeeId, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }
  GetAllProjectByEmployeeForSelectbyDate(employeeId: any, cmpcode: any, dateon: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Timesheet/GetAllprojectforassign?employeeid=` + employeeId + `&cmpcode=` + cmpcode + `&dateon=` + dateon, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  GetAllRecentProjectByEmployeeId(projecid:any,employeeId: any, cmpcode: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Timesheet/Getrecenttimesheet?projectid=` + projecid + '&employeeid=' + employeeId + `&cmpcode=` + cmpcode , { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetAllProjectPhaseForSelect(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Timesheet/GetAllProjectPhaseForSelect`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetAllProjectSubPhaseForSelect(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Timesheet/GetAllProjectSubPhaseForSelect`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetAllProjectTaskForSelect(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Timesheet/GetAllProjectTaskForSelect`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetAllProjectSubTaskForSelect(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Timesheet/GetAllProjectSubTaskForSelect`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetTimesheetMeta(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Timesheet/GetTimesheetMeta`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }

  UpdateMileStone(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Project/insMilestone`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }

  createUploadprojectdocument(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)

    return this._http
      .post(`${environment.apiUrl}Project/createUploadprojectdocument`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }

  UpdateRights(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Admin/InsFormassignment`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }

  OnSubscription(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Admin/Updatesubcription`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }
  OnPayDone(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Admin/InsertPaypalResult`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetExchangeRequest(value: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(
        `${environment.apiUrl}Approval/Exchangerequeststatus?requestid=` +
        value.id + `&clientstatus=` + value.clientstatus + `&servicestatus=` + value.changerequestcompletestatus + `&taskId=` + value.taskId + `&Cmpcode=` + value.CmpCode, { headers: headers }
      )
      .map((response: Response) => response.json())
      .toPromise();
  }
  GetAllProjectToShow(projectid, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(
        `${environment.apiUrl}Project/GetAllProjectForSelectoneproject?projectid=0` + `&CmpCode=` + CmpCode, { headers: headers }
      )
      .map((response: Response) => response.json())
      .toPromise();
  }

  UpdateOrgSetting(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Admin/OrgUpdate`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }
  UpdateGeneralSetttings(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)

    return this._http
      .post(`${environment.apiUrl}Admin/Modifycompanydetail`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }

  AddChangeRequest(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Expanse/SaveExchangerequest`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }

  AddReimbursment(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Reimbursement/SaveReimbursement`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();

  }

  AddNewCategory(formdata: any) {
    // Api calling without Headers/ Without token
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Category/CreateCategory`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }
  EditCategory(formdata: any) {
    // Api calling without Headers/ Without token
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Category/EditCategory`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }
  EditClientAmount(formdata: any) {
    // Api calling without Headers/ Without token
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Expanse/Editclientamount`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }
  AddSubNewCategory(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    // Api calling without Headers/ Without token
    return this._http
      .post(`${environment.apiUrl}Category/CreateSubCategory`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetCategory(categoryId, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(
        `${environment.apiUrl}Category/GetCategory?CategoryId=` +
        categoryId +
        `&CategoryName=category` + `&CmpCode=` + CmpCode, { headers: headers }
      )
      .map((response: Response) => response.json())
      .toPromise();
  }
  GetexpenseCategory(categoryId, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(
        `${environment.apiUrl}Category/GetexpenseCategory?CategoryId=` +
        categoryId +
        `&CategoryName=category` + `&CmpCode=` + CmpCode, { headers: headers }
      )
      .map((response: Response) => response.json())
      .toPromise();
  }
  GetCategoryCode(CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(
        `${environment.apiUrl}/Admin/Getcategorycode?cmpcode=` + CmpCode, { headers: headers }
      )
      .map((response: Response) => response.json())
      .toPromise();
  }
  GetSubcategory(CategoryId, subCategoryId, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(
        `${environment.apiUrl}Category/GetSubcategory?CategoryId=` +
        CategoryId + `&subCategoryId=` + subCategoryId + `&CmpCode=` + CmpCode, { headers: headers }
      )
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetCurrency(categoryId, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(
        `${environment.apiUrl}Category/GetCategory?CategoryId=` +
        categoryId +
        `&CategoryName=category` + `&CmpCode=` + CmpCode, { headers: headers }
      )
      .map((response: Response) => response.json())
      .toPromise();
  }
//not done bearer
  importCatgory(formdata) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)

    return this._http
      .post(`${environment.apiUrl}Category/ImportExcelCategory`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }
//not done bearer
  ImportExcelFiles(formdata) {
    const headers = new Headers();
   let token = this.authService.getToken();
   headers.append('Authorization', `bearer ${token}`)
    // headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Excel/ImportExcelFiles`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }

  // Policy API's

  CreatePolicy(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Policy/createPolicy`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }

  createPolicyCategory(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Policy/createPolicyCategory`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }

  createPolicyMileage(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Policy/createPolicyMileage`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }

  createPolicyDiem(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)

    return this._http
      .post(`${environment.apiUrl}Policy/createPolicyDiem`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }
  DiemSetting(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Policy/DiemSetting`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }
  // Rules Api's
  createRules(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Policy/createRules`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }

  createRoleType(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)

    return this._http
      .post(`${environment.apiUrl}Policy/createRoleType`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }
  GetRole(roleid, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Policy/GetRole?roleid=0` + `&CmpCode=` + CmpCode,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }
  GetRuleType(RuleTypeId, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Policy/GetRoleType?roleTypeid=` + RuleTypeId + `&CmpCode=` + CmpCode,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }
  createInviteUser(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Policy/createInviteUser`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }
  AddContactSupport(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)

    return this._http
      .post(`${environment.apiUrl}Admin/Addcontactsupport`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }
  // CRGContactSupport(formdata: any) {
  //   const headers = new HttpHeaders();
  //   headers.append('Content-Type', 'application/json');
  //   return this._http
  //     .post(`https://crg-support.com/api/ticket`, formdata, { headers: headers} )
  //     .map((response: Response) => response.json())
  //     .toPromise();
  // }
  CRGContactSupport(formdata: any): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.httpClient.post(`https://crg-support.com/api/ticket`, formdata,{headers:headers});
  }

  CRGContacttest(user_id: string): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let userData = { 'user_id': user_id };
    return this.httpClient.post(`https://crg-support.com/api/testing`, userData,{headers:headers});
  }

  sendEmailUser(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Policy/sendForEmail`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }

  registerWithSuperTick(path,formdata){
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.httpClient.post(path, formdata,{headers:headers});
  }

  substituteUser(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Advancepayment/InsSubstitutedetail`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }

  SaveScheduleService(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Project/Createschduleservice`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }

  empSubstituteDetail(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Advancepayment/empsubsitutueDetail`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }
  getEmpProjectList(projectId, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Project/GetEmployeeProject?EmployeeId=` + projectId + `&CmpCode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  getScheduleServiceList(Id, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Project/Getschduleservicelist?id=`+ Id+`&CmpCode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  ProjectEditpermission(Id, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Project/ProjectEditpermission?ProjectId=`+ Id+`&CmpCode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  getInUser(Id, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Accounts/Getinviteuser?id=` + Id + `&CmpCode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  getProjectbyUserId(Id, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}project/ProjectAssignTeam?ProjectId=` + Id + `&CmpCode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  getUserDetail(userid, CmpCode, typeId) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Accounts/UsersDetail?userid=` + userid + `&CmpCode=` + CmpCode + `&userType=` + typeId, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  getUserHistory(userid, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Category/Getrejoiningdetail?userid=` + userid + `&cmpcode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  getUserDetailExport(CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Admin/GetUsersDetailexportexcel?cmpcode=`+CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  RegisterUser(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Accounts/UserRegister`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }

  ChangePassword(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Accounts/changepassword`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetDepartment(DepartmentId, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Policy/GetDepartment?DepartmentId=0` + `&CmpCode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetEmployeByEmail(formdata, CmpCode: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http

      .get(`${environment.apiUrl}Policy/GetEmployeByEmail?Email=` + formdata + `&Cmpcode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetPolicy(policyId, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Policy/GetPolicy?policyId=0` + `&CmpCode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  getExpenseReport(CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Analytics/Getexpansedetail/` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  getRoadBlockReport(CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Analytics/getroadblock/` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  GetPhasereport(projectId: any, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Project/GetPhasereport/${projectId}/${CmpCode}`, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetAllProjectForSelect(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Project/GetAllProjectForSelect`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }

  // All reports Api Start
  getResourceAllocationReport(CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Analytics/getexrptresourceallocation/` + CmpCode, { headers: headers })

      .map((response: Response) => response.json())
      .toPromise();
  }

  getResourceUtilizationReport(isDate: any, UserId, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Analytics/getresourceutilization?utDate=` + isDate + `&UserId=` + UserId + `&CmpCode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  getResourceCapacityReport(from: any,to, UserId, CmpCode, Emp?, project?,  dept? ) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Analytics/Getresourcepoolreport?fromDate=`+from+`&toDate=`+to+`&EmployeeId=`+Emp+`&userid=`
      +UserId+`&CmpCode=`+CmpCode+`&projectId=`+project+`&deptId=`+dept, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }


  getProjectLeavePlanReport(CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Analytics/getprojectleaveplannerreport/` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  getResourceEfficiencyReport() {

  }

  getOverDueReport(CmpCode) {
    //not used this api
     const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');

    return this._http
      .get(`${environment.apiUrl}Analytics/getoverduetask`, CmpCode)
      .map((response: Response) => response.json())
      .toPromise();
  }

  getPlannedVsActualReport(CmpCode) {
    //not used this api
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Analytics/getplannedvsactual`, CmpCode)
      .map((response: Response) => response.json())
      .toPromise();
  }

  getResourceWorkDetailReport(CmpCode) {
    //not used this api
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Analytics/getexrptresourceworkdetail`, CmpCode)
      .map((response: Response) => response.json())
      .toPromise();
  }

  getProjectProfitDetailReport(CmpCode) {
    //not used this api
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Analytics/getprojectprofitablity`, CmpCode)
      .map((response: Response) => response.json())
      .toPromise();
  }

  getMomDetailReport(CmpCode) {
    //not used this api
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Analytics/getmomreport`, CmpCode)
      .map((response: Response) => response.json())
      .toPromise();
  }
  // All reports Api End
  getUnsubmittedExpanse(CmpCode) {
    //not used this api
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Analytics/Getunsubmittedexpanse`, CmpCode)
      .map((response: Response) => response.json())
      .toPromise();
  }

  getCategoryExpanse(CmpCode) {
    //not used this api
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Analytics/Getcategoryexpanse`, CmpCode)
      .map((response: Response) => response.json())
      .toPromise();
  }
  getUserLocations(user, dates, CmpCode) {
      const headers = new Headers();
      let token = this.authService.getToken();
      headers.append('Authorization', `bearer ${token}`)
      headers.append('Content-Type', 'application/json');
      return this._http
        .get(`${environment.apiUrl}Admin/Getcheckincheckoutlocation?userid=`+user+`&startdate=`+dates.start+`&enddate=`+dates.end+`&cmpcode=`+CmpCode ,{headers:headers})
        .map((response: Response) => response.json())
        .toPromise();
  }

  getUserwiseExpanse(CmpCode) {
    //not used this api
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Analytics/getuserwiseexpanse`, CmpCode)
      .map((response: Response) => response.json())
      .toPromise();
  }

  getMilegeExpanse(CmpCode) {
      //not used this api
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Analytics/getmilegeexpanse`, CmpCode)
      .map((response: Response) => response.json())
      .toPromise();
  }

  getPolicyViolation(CmpCode) {
     //not used this api
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Analytics/getpolicyviolation`, CmpCode)
      .map((response: Response) => response.json())
      .toPromise();
  }

  getCustomerExpanse(CmpCode) {
    //not used this api
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Analytics/getcustomerexpanse`, CmpCode)
      .map((response: Response) => response.json())
      .toPromise();
  }

  getProjectExpanse(CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Analytics/getprojectexpanse/`+ CmpCode ,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }

  AddExpense(formdata: any) {
    debugger;
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)


    return this._http
      .post(`${environment.apiUrl}Expanse/createExpanse`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetCustomer(CustomerId, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Expanse/GetCustomer?CustomerId=0` + `&CmpCode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  GetCustomerfornewreport(CmpCode,data) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Expanse/GetCustomer?CustomerId=0` + `&CmpCode=` + CmpCode+ '&projectid=' +data, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  GetCustomerexp(CustomerId, CmpCode, projectId) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Expanse/GetCustomer?CustomerId=` + CustomerId + `&CmpCode=` + CmpCode
        + `&projectid=` + projectId, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  GetCustomerFilter(CmpCode, userid) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Expanse/GetCustomerdashboard?&CmpCode=` + CmpCode + `&userid=` + userid, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  GetProjectFilter(userid, CmpCode, CustomerId) {

    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Expanse/GetExProjectsdashboard?&userid=` + userid + `&CmpCode=` + CmpCode
        + `&customerid=` + CustomerId, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetPaymentmode(paymentModeId, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Expanse/GetPaymentmode?paymentModeId=0` + `&CmpCode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  GetProjectAdmin(CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Project/Getallprojectadmin?cmpcode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  GetProjectManagers(CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Admin/GetAllMangerandadmin?CmpCode=`+CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  AddExpenseMileage(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)

    return this._http
      .post(`${environment.apiUrl}Expanse/createExpanseMileage`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetPurticularPolicy(policyId, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Policy/GetPolicy?policyId=` + policyId + `&CmpCode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetPurticularCategories(policyId, CmpCode, locationId) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Policy/GetPolicyCategory?policyId=` + policyId + `&CmpCode=` + CmpCode
        + `&locationId=` + locationId, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetPurticularMileage(policyId, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Policy/GetPolocyMileage?policyId=` + policyId + `&CmpCode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetPurticularDiem(policyId, CmpCode) {

    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Policy/GetPolicyDiem?policyId=` + policyId + `&CmpCode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  GetDiemSetting(policyId, CmpCode) {

    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Policy/GetDiemSetting?policyId=` + policyId + `&CmpCode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  GetisIncludedTax(CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(
        `${environment.apiUrl}Expanse/isincludedtax?CmpCode=` + CmpCode, { headers: headers }
      )
      .map((response: Response) => response.json())
      .toPromise();
  }
  GetAllExpense(expenseId, categoryId, userid, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(
        `${environment.apiUrl}Expanse/GetAllexpense?expenseId=` +
        expenseId +
        `&categoryId=` +
        categoryId + `&userid=` + userid + `&CmpCode=` + CmpCode, { headers: headers }
      )
      .map((response: Response) => response.json())
      .toPromise();
  }
  GetAllExpenseNew(expenseId, categoryId, userid, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(
        `${environment.apiUrl}Expanse/GetFilterexpense?expenseId=` +
        expenseId +
        `&categoryId=` +
        categoryId + `&userid=` + userid + `&CmpCode=` + CmpCode, { headers: headers }
      )
      .map((response: Response) => response.json())
      .toPromise();
  }
  getlastexpenseUser(userid, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(
        `${environment.apiUrl}Expanse/getlastexpenseUser?userid=` + userid + `&CmpCode=` + CmpCode, { headers: headers }
      )
      .map((response: Response) => response.json())
      .toPromise();
  }
  createbulkexpense(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)

    return this._http
      .post(`${environment.apiUrl}Expanse/createExpenseAddBulk`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }

  CreateScheduleEmail(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Project/savescheduleemail`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }
  CreateScheduleEmailwsr(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Project/savescheduleemailwsr`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }

  StatusChangeReport(params: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http.get(`${environment.apiUrl}Project/Statuschangereport?projectid=` + params.projectid +
      `&reportid=` + params.reportid + `&cmpcode=` + params.cmpcode + `&type=` + params.type, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetProject(userid, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Expanse/GetProjectstatuswise?userid=` + userid + `&CmpCode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetcustomerProject(CustomerId, CmpCode, userid,date) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Expanse/Getcustomerproject/GetAllexpense?CustomerId=` + CustomerId
        + `&CmpCode=` + CmpCode + `&userid=` + userid + `&dateon=` + date, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetChangeReqProject(projectid, CmpCode) {

    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Expanse/GetRequestProjects?projectid=` + projectid + `&CmpCode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  Getchangerequestcode(CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Expanse/Getchangerequestcode?Cmpcode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  GetAllReportuserby(userid, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Expanse/GetReportUserBy?userId=` + userid + `&CmpCode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  GetUnsubmitedReports(userid, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Expanse/GetUnsubmitedReports?userId=` + userid + `&CmpCode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  GetAdvanceDetailbyId(repid, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Advancepayment/Getadvanceinfo?reportid=` + repid + `&cmpcode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  Getreimbinfo(repid, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Reimbursement/Getreimbinfo?reportid=` + repid + `&CmpCode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  AddReportExpense(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Expanse/createExpReport`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }
  AddExpenseApproval(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
  //  headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Expanse/expapprovereject `, formdata,{headers:headers} )
      .map((response: Response) => response.json())
      .toPromise();
  }
  GetReport(reportId, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Expanse/getExReport?reportId=0` + `&CmpCode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  GetReportuserby(userid, CmpCode) {

    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Expanse/getExReportuserby?userid=` + userid + `&CmpCode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  // Start Approval Api's

  GetReportsByID(reportId, Status, CmpCode) {

    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(
        `${environment.apiUrl}Approval/GetGetApproval?reportId=` + reportId + `&status=` + Status + `&CmpCode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  DeleteReportById(reportId, CmpCode, userid) {

    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(
        `${environment.apiUrl}Expanse/Deletebulkreport?reportid=` + reportId + `&cmpcode=` + CmpCode + `&userid=` + userid, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();

  }
  GetApprovalByID(reportId, Status, userid, CmpCode) {

    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(
        `${environment.apiUrl}Approval/GetsubmitApproval?reportId=` + reportId + `&status=` + Status + `&userId=` + userid + `&CmpCode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetReportsDetailByID(createdBy, CmpCode) {

    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(
        `${environment.apiUrl}Approval/GetExpenseDetail?createdBy=` + createdBy + `&CmpCode=` + CmpCode, { headers: headers }
      )
      .map((response: Response) => response.json())
      .toPromise();
  }

  getIPAddress() {
    return this._http
      .get(`https://jsonip.com`)
      .map((response: Response) => response.json())
      .toPromise();
  }
  GetExpenseDetailByID(createdBy, CmpCode, userid) {

    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(
        `${environment.apiUrl}Approval/GetExpenseprojectdetail?reportid=` + createdBy + `&CmpCode=` + CmpCode + `&userid=` + userid, { headers: headers }
      )
      .map((response: Response) => response.json())
      .toPromise();
  }
  GetLeaveDetailByID(createdBy, CmpCode, userid, expeseid) {

    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(
        `${environment.apiUrl}Advancepayment/Getlevelapproval?reportid=` + createdBy + `&CmpCode=` + CmpCode + `&userId=` + userid + `&expenseId=` + expeseid, { headers: headers }

      )
      .map((response: Response) => response.json())
      .toPromise();
  }
  Getlabelapproved(advanceid, CmpCode, userid) {

    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(
        `${environment.apiUrl}Advancepayment/getlabelapproved?advanceid=` + advanceid + `&cmpcode=` + CmpCode +
        `&userid=` + userid, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  Applyadvanceamount(reportid, applyAmount, CmpCode) {

    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(
        `${environment.apiUrl}Advancepayment/Applyadvanceamount?reportid=` + reportid +
        `&applyAmount=` + applyAmount + `&cmpcode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetAllReportsByidbyall(reportId, status, createdBy, CmpCode) {

    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(
        `${environment.apiUrl}Approval/ExGetAllsubmitedl?reportId=` +
        reportId +
        `&status=` +
        status +
        `&createdBy=` +
        createdBy +
        `&CmpCode=` +
        CmpCode, { headers: headers }

      )
      .map((response: Response) => response.json())
      .toPromise();
  }


  Getuseradvancedetail(userid, CmpCode) {

    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(
        `${environment.apiUrl}Advancepayment/Getuseradvancedetail?userid=` + userid + `&cmpcode=` + CmpCode, { headers: headers }
      )
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetAllReports(reportId, status, createdBy, CmpCode) {

    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(
        `${environment.apiUrl}Approval/ExGetAllApproval?reportId=` +
        reportId +
        `&status=` +
        status +
        `&createdBy=` +
        createdBy +
        `&CmpCode=` +
        CmpCode, { headers: headers }

      )
      .map((response: Response) => response.json())
      .toPromise();
  }
  GetAllReportsumited(reportId, status, createdBy, CmpCode, type) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(
        `${environment.apiUrl}Approval/GetBeforapprovlreport?reportId=` +
        reportId +
        `&status=` +
        status +
        `&createdBy=` +
        createdBy +
        `&CmpCode=` +
        CmpCode +
        `&type=` +
        type, { headers: headers }

      )
      .map((response: Response) => response.json())
      .toPromise();
  }


  GetApproveAllReports(reportId, userId, CmpCode,from, to) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(
        `${environment.apiUrl}Expanse/myApprovel?reportId=` +
        reportId +
        `&userId=` +
        userId
        + `&CmpCode=` + CmpCode+`&fromdate=`+from+`&todate=`+to, { headers: headers }

      )
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetResourceAllocation(CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(
        `${environment.apiUrl}Expanse/GetResourceAllocation?employeeid=0&departmentid=0&designationid=0&overallstatus=0` + `&CmpCode=` + CmpCode + `&startDate=&endDate=`, { headers: headers }
      )
      .map((response: Response) => response.json())
      .toPromise();
  }

  resourceAllocation(parms: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(
        `${environment.apiUrl}Expanse/GetResourceAllocation?employeeid=` +
        parms.employeeId +
        `&departmentid=` +
        parms.departmentId +
        `&designationid=` +
        parms.designationId +
        `&overallstatus=` +
        parms.overallStatus +
        `&cmpCode=` +
        parms.CmpCode +
        `&startDate=` +
        parms.startDate +
        `&endDate=` +
        parms.endDate
        + `&usertype=` +
        parms.usertype, { headers: headers }
      )
      .map((response: Response) => response.json())
      .toPromise();
  }
  allexpense(parms: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(
        `${environment.apiUrl}Expanse/GetAllexpense?expenseId=` + parms.expenseId + `&categoryId=` +
        parms.categoryId +
        `&merchantName=` +
        parms.merchantName +
        `&status=` +
        parms.status +
        `&amount=` +
        parms.amount, { headers: headers }
      )
      .map((response: Response) => response.json())
      .toPromise();
  }

  reportListingFilter(parms: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(
        `${environment.apiUrl}Expanse/reportfilterClick?reportTitle=` +
        parms.reportTitle +
        `&amount=` +
        parms.amount, { headers: headers }
      )
      .map((response: Response) => response.json())
      .toPromise();
  }
  Getadvancerefernce(CmpCode: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(
        `${environment.apiUrl}Advancepayment/Getadvancerefernce?CmpCode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  GetadvanceAvailabelamount(userId, CmpCode: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(
        `${environment.apiUrl}Advancepayment/GetadvanceAvailabelamount?userid=` + userId + `&cmpcode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  advancePaymentFilterList(parms: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(
        `${environment.apiUrl}Expanse/GetAllAdvancePayment?empName=` +
        parms.empName +
        `&amount=` +
        parms.amount, { headers: headers }
      )
      .map((response: Response) => response.json())
      .toPromise();
  }
  reportfilter(parms: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(
        `${environment.apiUrl}Expanse/GetAllReport?reportTitle=` +
        parms.reportTitle +
        `&amount=` +
        parms.amount, { headers: headers }
      )
      .map((response: Response) => response.json())
      .toPromise();
  }
  allBulkFilterCategory(parms: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(
        `${environment.apiUrl}Category/GetCategoryfilter?CategoryName=` +
        parms.CategoryName, { headers: headers }

      )
      .map((response: Response) => response.json())
      .toPromise();
  }
  InviteUserFilter(parms: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Accounts/UsersDetailFilter?userid=` + parms.userid +`&Name=` + parms.Name + `&Phone=` + parms.Phone + `&Email=` + parms.Email + `&Cmpcode=` + parms.CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  ChangeRequestFilter(parms: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Analytics/GetExchangerequest?requestid=` + parms.requestid +`&requestedby=` + parms.requestedby + `&projectid=` + parms.projectid + `&clientstatus=` + parms.clientstatus, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  approvalfilterReport(parms: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(
        `${environment.apiUrl}Expanse/myApproval?reportTitle=` +
        parms.reportTitle +
        `&amount=` +
        parms.amount, { headers: headers }
      )
      .map((response: Response) => response.json())
      .toPromise();
  }

  ExGetAllSubmited(reportId, status, createdBy, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(
        `${environment.apiUrl}Approval/ExGetAllSubmited?reportId=` + reportId + `&status=` + status + `&createdBy=` + createdBy + `&CmpCode=` + CmpCode, { headers: headers }
      )
      .map((response: Response) => response.json())
      .toPromise();
  }

  createInsApprovalbulk(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Approval/createInsApprovalbulk`, formdata,{headers:headers} )
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetUsers(createdBy, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Approval/spGetUsers?userId=0` + `&CmpCode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }


  GetEmployees(createdBy, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Policy/GetEmployee?EmployeeId=0&CmpCode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetVendors(CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Admin/Getvendorformapping?cmpcode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  GetEmployeesByReportId(repid, userid, usertype, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Expanse/Getreportbyuser?rpeortid=` + repid + `&userid=` + userid + `&usertype=` + usertype + `&CmpCode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  GetTaskData(params: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Project/GetProjectTask/` + params.projectId + `/` + params.CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  GetCommercial(params: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Project/Getcommercial?projectid=` + params.projectId + `&CmpCode=` + params.CmpCode + `&userid=` + params.userid + `&roleid=` + params.roleid, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  GetEmptaskdetail(params: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}project/Emptaskdetail?fromdate=` + params.fromdate + `&todate=` + params.todate + `&CmpCode=` + params.CmpCode + `&empId=` + params.empId
        + `&taskId=` + params.taskId + `&subTaskId=` + params.subTaskId, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  Pushnewemployee(params: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}project/Pushnewemployee?projectid=` + params.projectid + `&fromDate=` + params.fromdate + `&todate=` + params.todate +
        `&taskid=` + params.taskId + `&subtaskid=` + params.subTaskId + `&CmpCode=` + params.CmpCode + `&plannedEfforts=` + params.plannedEfforts, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetEmployeetimesheethours(params: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}project/GetEmployeetimesheethours?EmployeeId=` + params.EmployeeId + `&projectId=` + params.projectId +
        `&taskId=` + params.taskId + `&subtaskId=` + params.subtaskId + `&Cmpcode=` + params.Cmpcode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  Getpendinghours(projectid, cmpcode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Project/Getpendingtimesheethours?projectid=` + projectid + `&cmpcode=` +cmpcode , { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  createQuestionaire(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Project/ProjectCloserFeedback`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }
  saveProjectCloserForm(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}ProjectInformation/InsertProjectInformation`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }
  projectCloserFormFileUpload(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    // headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}ProjectInformation/UploadFileProjectInformation`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }

  SendEmailReminder(projectid, cmpcode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Timesheet/sendforapproval?projectid=`+projectid+`&cmpcode=`+cmpcode , { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  SendEmailReminderProjectAssign(projectid, cmpcode, user) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Timesheet/sendforapprovalbyuser?projectid=`+projectid+`&cmpcode=`+cmpcode+`&userid=`+user , { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  GetpendinTimesheets(projectid, cmpcode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Timesheet/Gettimeforprojectclosed?projectid=` + projectid + `&cmpcode=` +cmpcode , { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  GetpendinDataUserWise( userId, projectid, cmpcode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Project/Getuserpendingtask?projectid=`+projectid+`&cmpcode=`+cmpcode+`&userid=`+userId, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  GetProjectPhaseEmployee(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Project/GetProjectPhaseEmployee`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }
  SaveRemarks(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Project/saveremarks`, formdata,{headers:headers} )
      .map((response: Response) => response.json())
      .toPromise();
  }

  getProjectAllData(projectID: any, CmpCode: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Project/GetPhaseTask/` + projectID + `/` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  getLoggedHours(id,projectID: any, CmpCode: any, taskType) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Project/GetLoggedHours?id=${id}&ProjectId=${projectID}&CmpCode=${CmpCode}&name=${taskType}`, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetAllEmployeesForSelect(CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Admin/GetAllEmployeesForSelect?CmpCode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetMerchants(createdBy, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Approval/GetAllMerchent?expenseId=0` + `&CmpCode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  createOutofOffice(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Approval/createOutofOffice`, formdata,{headers:headers} )
      .map((response: Response) => response.json())
      .toPromise();
  }
  createPhaseTask(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Project/createPhaseTask`, formdata,{headers:headers} )
      .map((response: Response) => response.json())
      .toPromise();
  }

  requestcorrection(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Timesheet/Timecorrection`, formdata,{headers:headers} )
      .map((response: Response) => response.json())
      .toPromise();
  }

  createComment(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Project/saveCommit`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }

  getcorrectionlist(params: any) {

    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http

      .get(`${environment.apiUrl}Timesheet/Gettimesheetcorrection?userid=` + params.userid + `&cmpcode=` + params.cmpcode +
         `&usertype=` + params.usertype +  `&filterEnddate=` + params.filterEnddate + `&filterStartdate=`+ params.filterStartdate, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  GettimecorrectionExcel(params: any) {

    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Timesheet/GettimecorrectionExcel?userid=` + params.userid + `&cmpcode=` + params.cmpcode + `&usertype=1`, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }


  Getemployeewiseexcel(params: any) {

    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Timesheet/Getemployeewiseexcel?userid=` + params.userid + `&cmpcode=` + params.cmpcode + `&dateson=` + params.dates, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  GettimecorrectionuserExcel(params: any) {

    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Timesheet/GettimecorrectionuserExcel?userid=` + params.userid + `&cmpcode=` + params.cmpcode , { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }


  projectOptEmail(params: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http

      .get(`${environment.apiUrl}project/projectoptemail?projectid=` + params.projectid + `&isemail=` + params.isemail
        + `&CmpCode=` + params.CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetcommentandRoadblock(params: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Project/GetcommentandRoadblock?phaseid=` + params.phaseId + `&subphasid=` + params.subPhaseId
        + `&TaskId=` + params.taskId + `&SubTaskId=` + params.subTaskId + `&Type=Roadblock` + `&CmpCode=` + params.CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetcommentData(params: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Project/GetcommentandRoadblock?phaseid=` + params.phaseId + `&subphasid=` + params.subPhaseId
        + `&TaskId=` + params.taskId + `&SubTaskId=` + params.subTaskId + `&Type=comment` + `&CmpCode=` + params.CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetTimesheetDataForSales(projectID: any, Cmpcode: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Timesheet/GettimesheetsalepersionforEamil?projectid=`+projectID+`&Cmpcode=`+Cmpcode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetTimesheetDataForTeams(projectID: any, Cmpcode: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Timesheet/GettimesheetteamforEamil?projectid=`+projectID+`&Cmpcode=`+Cmpcode , { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetTimesheetDataForCustomersContacts(projectID: any, Cmpcode: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Timesheet/GettimesheetCustomerContactsforEamil?projectid=`+projectID+`&Cmpcode=`+Cmpcode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetManagerandCustomer(params: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Project/GetManagerandCustomer?&projectid=` + params.projectid + `&cmpcode=` + params.CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  Getschduledefaulttime(params: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
    .get(`${environment.apiUrl}Project/Getschduledefaulttime?projectId=`+params.projectId+`&Cmpcode=`+params.cmpCode+`&reportid=`+params.reportId+`&type=`+params.type, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetEmailSchedule(params: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Project/Getemailschedule?Cmpcode=` + params.CmpCode + `&projectid=` + params.projectid, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetProjectWiseSummary(params: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Project/Getprojectwisesummary?projectid=` + params.projectid + `&cmpcode=` + params.CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  Gettimesheetfortask(params: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Project/Gettimesheetfortask?taskid=` + params.taskid + `&Cmpcode=` + params.CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetIncludetaxAmount(CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Expanse/Getincludetaxamount?Cmpcode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  projecttaskcopy(params: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Project/projecttaskcopy?taskid=` + params.taskid + `&Cmpcode=` + params.CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  Getuploadprojectdocument(userId: any, projectid: any, CmpCode: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Project/Getuploadprojectdocument?userid=` + userId + `&projectid=` + projectid + `&cmpcode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetProjectlist(params: any) {
    let opt: RequestOptions
    let myHeaders: Headers = new Headers
    let token = this.authService.getToken();
    myHeaders.set('Authorization', `bearer ${token}`)
    opt = new RequestOptions({
      headers: myHeaders
    });

    return this._http
      .get(`${environment.apiUrl}project/Getengedproject?emodelId=` + params.emodelId + `&cmpcode=` + params.CmpCode, opt)
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetDocumentList(params: any) {

    let opt: RequestOptions
    let myHeaders: Headers = new Headers
    let token = this.authService.getToken();
    myHeaders.set('Authorization', `bearer ${token}`)
    opt = new RequestOptions({
      headers: myHeaders
    });

    return this._http
      .get(`${environment.apiUrl}project/GetengedDocument?emodelId=` + params.emodelId + `&cmpcode=` + params.CmpCode, opt)
      .map((response: Response) => response.json())
      .toPromise();
  }


  GetEngagementmode(params: any) {
    let opt: RequestOptions
    let myHeaders: Headers = new Headers
    let token = this.authService.getToken();
    myHeaders.set('Authorization', `bearer ${token}`)
    opt = new RequestOptions({
      headers: myHeaders
    });

    return this._http
      .get(`${environment.apiUrl}Project/GetEngagementmode?emodelId=` + params.emodelId + `&cmpcode=` + params.CmpCode, opt)
      .map((response: Response) => response.json())
      .toPromise();
  }
  GetResourcedepatment(params: any, cmp) {
    let opt: RequestOptions
    let myHeaders: Headers = new Headers
    let token = this.authService.getToken();
    myHeaders.set('Authorization', `bearer ${token}`)
    opt = new RequestOptions({
      headers: myHeaders
    });

    return this._http
      .get(`${environment.apiUrl}Project/GetResourcedepatment?userid=` + params + `&cmpcode=` + cmp, opt)
      .map((response: Response) => response.json())
      .toPromise();
  }

  Getallroadblocktype(params: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Roadblock/Getallroadblocktype?id=` + params.Id + `&cmpcode=` + params.CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetprojectDocument(params: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Project/GetprojectDocument?pdocumentId=` + params.pdocumentId + `&cmpcode=` + params.CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }


  GetProjectWorkorderwise(params: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Project/GetProjectWorkorderwise?projectid=` + params.pdocumentId + `&cmpcode=` + params.CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetProjectResource(params: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Project/GetProjectResource?projectid=` + params.pdocumentId + `&cmpcode=` + params.CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  UpdateJobbandStatus(params: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Admin/Changejobbandstatus?jovbandid=` + params.jobBandId + `&status=` + params.isActive
        + `&cmpcode=` + params.CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }


  UpdateEngagementModel(params: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Project/UpdateEngagementModel?emodelId=` + params.emodelId + `&isActive=` + params.isActive
        + `&cmpcode=` + params.CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  Updatectegorystatus(params: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Category/Updatectegorystatus?CategoryId=` + params.CategoryId +
        `&isactive=` + params.isActive + `&cmpcode=` + params.CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  UpdateDocumentStatus(params: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    //  debugger;
    return this._http
      .get(`${environment.apiUrl}Project/Changedocstatus?engageid=` + params.emodelId + `&documentid=` + params.PDocumentId
        + `&status=` + params.status + `&cmpcode=` + params.cmpcode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  Updateroadblockstatus(params: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Roadblock/Updateroadblockstatus?id=` + params.RoadblockMasterId + `&cmpcode=` + params.CmpCode
        + `&Inactive=` + params.isActive, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  createEngagement(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Project/createEngagement`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }

  CreateRoadblockMaster(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Roadblock/CreateRoadblockMaster`, formdata ,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }

  CreateprojectDocument(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
  //  headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Project/CreateprojectDocument`, formdata ,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }

  CreateInvoice(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
  //  headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Project/CreateProjectInvoiceDetail`, formdata ,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }

  Updateinvoicecollection(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
  //  headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Project/Updateinvoicecollection`, formdata ,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }

  CreateProjectInvoiceDetail(param: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
  //  headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl} Project/CreateProjectInvoiceDetail`, param,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }


  DeleteFormID(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Expanse/DeleteFormID`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }

  Deleteinvoiceorder(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Project/Deleteinvoiceorder`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }


  GetScheduleReportHistory(params: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Admin/Getschedulereporthistory?reportname=` + params.reportname + `&Cmpcode=` + params.CmpCode+ `&projectid=`+params.projectid, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  ScheduleReportRunNow(params: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Schedulereport/schedulereportrunNow?projectid=` + params.projectId + `&reportid=` + params.reportId
        + `&CmpCode=` + params.CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetProjectCustomerContactsWsr(params: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Project/GetProjectCustomerContactsWsr?projectId=` + params.projectId + `&Cmpcode=` + params.CmpCode +
        `&reportid=` + params.reportid + `&type=` + params.type, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetProjectProjectTeamWsr(params: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Project/ScheduleprojectteamWsr?projectId=` + params.projectId + `&Cmpcode=` + params.CmpCode +
        `&reportid=` + params.reportid + `&type=` + params.type, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetProjectSalesPersonWsr(params: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Project/GetProjectSalesPersonWsr?projectId=` + params.projectId + `&Cmpcode=` + params.CmpCode +
        `&reportid=` + params.reportid + `&type=` + params.type, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }



  GetProjectCustomerContacts(params: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Project/GetProjectCustomerContacts?projectId=` + params.projectId + `&Cmpcode=` + params.CmpCode +
        `&reportid=` + params.reportid + `&type=` + params.type, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetProjectProjectTeam(params: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Project/Scheduleprojectteam?projectId=` + params.projectId + `&Cmpcode=` + params.CmpCode +
        `&reportid=` + params.reportid + `&type=` + params.type, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetProjectSalesPerson(params: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Project/GetProjectSalesPerson?projectId=` + params.projectId + `&Cmpcode=` + params.CmpCode +
        `&reportid=` + params.reportid + `&type=` + params.type, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }


  ViewDocument(params: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Project/Getdocument?id=` + params.id + `&CmpCode=` + params.CmpCode
        + `&phasTaskType=` + params.phasTaskType, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  UpdateExpense(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Approval/EditApprovalExpense`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }
  GetAmountVald(categoryId, Amount, userId, CmpCode, locationId) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(
        `${environment.apiUrl}Expanse/GetAmountVald?categoryId=` +
        categoryId +
        `&Amount=` +
        Amount +
        `&userId=` +
        userId
        + `&CmpCode=` + CmpCode + `&locationId=` + locationId, { headers: headers }
      )
      .map((response: Response) => response.json())
      .toPromise();
  }
  Getmilagesrate(startDate, categoryId, CmpCode, milragelocation) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(
        `${environment.apiUrl}Expanse/GetAllMilagerate?startDate=` +
        startDate +
        `&categoryId=` +
        categoryId
        + `&CmpCode=` + CmpCode + `&milragelocation=` + milragelocation, { headers: headers }
      )
      .map((response: Response) => response.json())
      .toPromise();
  }
  createItemizebulk(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Expanse/createItemize`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }
  Getdefaultperdeim(CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Policy/Getdefaultperdeim?CmpCode=`+ CmpCode ,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }

  getCurrencyInINR(currencyName, currency, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(
        `${environment.apiUrl}Expanse/GetCurrencyRS?fromCurrency=` +
        currencyName +
        `&currecy=` +
        currency +
        `&CmpCode=` +
        CmpCode, { headers: headers }
      )
      .map((response: Response) => response.json())
      .toPromise();
  }

  ForwardForEmp(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Approval/ForwardForEmp`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }

  createAdvncepayment(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Advancepayment/createAdvncepayment`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }

  Tagadvanceinreport(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Advancepayment/Tagadvanceinreport`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }

  Advanceapprove(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Advancepayment/Advanceapprove`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetPaidThrough(formdata: any, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Advancepayment/GetPaidThrough?paidThroughId=0` + `&CmpCode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  recallreport(reportid, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Reimbursement/recallreport?reportid=` + reportid + `&CmpCode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  GetRemersedreports(userId: any, CmpCode) {
     const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Approval/GetRemersedreports?userid=` + userId + `&CmpCode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetAllAdvancePayment(advancePaymentId, CmpCode, userId) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Advancepayment/GetAllAdvancePayment?advancePaymentId=` +
        advancePaymentId + `&CmpCode=` + CmpCode + `&userId=` + userId, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  // End Approval Api's
  EditReport(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Expanse/EditExpenseReport`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }
  UpdateAllReportName(status, id, cmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Expanse/Getlastmappedexpense?status=`+status+`&rpeortid=`+id+`&CmpCode=`+cmpCode,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }
  DeleteExpense(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Expanse/DeleteFormID`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }
  DeleteCategory(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Expanse/DeleteFormID`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }
  GetAllReport(reportId, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(
        `${environment.apiUrl}Expanse/GetAllReport?reportId=` + reportId + `&CmpCode=` + CmpCode, { headers: headers }
      )
      .map((response: Response) => response.json())
      .toPromise();
  }
  GetAllReportbyCustomer(userid, customid, CmpCode, usertype) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(
        `${environment.apiUrl}Expanse/Getcustomerbyreport?userid=` + userid + `&customerid=` + customid + `&CmpCode=` + CmpCode + `&usertype=` + usertype, { headers: headers }
      )
      .map((response: Response) => response.json())
      .toPromise();
  }
  UpdateEmployeeStatus(params) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(
        `${environment.apiUrl}Admin/Updateemployeestatus?employeeid=` + params.employeeid + `&InActive=` + params.InActive, { headers: headers }
      )
      .map((response: Response) => response.json())
      .toPromise();
  }
  setDefaultPolicy(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Policy/setDefaultPolicy`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }
  GetExpenseHistory(expHistoryId, CmpCode, userid, reportid) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(
        `${environment.apiUrl}Expanse/GetExpenseHistory?expHistoryId=` +
        expHistoryId +
        `&CmpCode=` + CmpCode + `&userid=` + userid + `&reportid=` + reportid, { headers: headers }
      )
      .map((response: Response) => response.json())
      .toPromise();
  }

  // him code
  createPolicyEmployee(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Expanse/ExpenseEmployee`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetPurticularWarmExpenseEmp(policyId, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(
        `${environment.apiUrl}/Expanse/GetExpenseEmployee?policyId=` + policyId + `&CmpCode=` + CmpCode, { headers: headers }
      )
      .map((response: Response) => response.json())
      .toPromise();
  }

  createWarmPolicy(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Policy/createWarms`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }

  CreateTrainingRequest(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Category/Createtraning`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }
  UpdateTrainingRequest(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Category/UpdateTrainingRequest`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }


  createRejoingRequest(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Category/Createrejoining`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetDimeAmount(noofdays, categoryIdDime, subCategoryId, location, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(
        `${environment.apiUrl}Expanse/GetDimeAmount?noofdays=` +
        noofdays + `&categoryId=` + categoryIdDime + `&subcategoryid=` + subCategoryId +
        `&location=` + location + `&CmpCode=` + CmpCode, { headers: headers }
      )
      .map((response: Response) => response.json())
      .toPromise();
  }
  GetAmountLimit(employeeid, date, CmpCode, reportId) {

    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(
        `${environment.apiUrl}Expanse/GetAmountLimit?employeeid=` +
        employeeid + `&date=` + date + `&CmpCode=` + CmpCode + `&reportId=` + reportId, { headers: headers }
      )
      .map((response: Response) => response.json())
      .toPromise();
  }

  projectDashboard(CmpCode) {
    //not used

    return this._http
      .get(
        `${environment.apiUrl}/Expanse/projectdashboard`, CmpCode
      )
      .map((response: Response) => response.json())
      .toPromise();
  }
  getprojectdashboard(projectId, CmpCode, datepr) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(
        `${environment.apiUrl}/Schedulereport/getprojectdashboard/` + projectId + `/` + CmpCode + `/` + datepr, { headers: headers }
      )
      .map((response: Response) => response.json())
      .toPromise();
  }

  getprojectDashboardbyPid(projectId, CmpCode, datepr) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(
        `${environment.apiUrl}/Admin/getprojectdashboard/` + projectId + `/` + CmpCode + `/` + datepr, { headers: headers }
      )
      .map((response: Response) => response.json())
      .toPromise();
  }


  getCmpCodebyProjectid(projectId) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(
        `${environment.apiUrl}/Schedulereport/Getcmpcodeproject?projectid=` + projectId, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  GetSubmitFilter(params) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(
        `${environment.apiUrl}Approval/GetSubmitFilter?fromDate=` +
        params.fromDate + `&toDate=` + params.toDate + `&projectId=` + params.projectId
        + `&reportId=` + params.reportId + `&userId=` + params.userId, { headers: headers }

      )
      .map((response: Response) => response.json())
      .toPromise();
  }

  myApprovel(reportId, userId, statusType, CmpCode, from, to) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(
        `${environment.apiUrl}Expanse/myApprovel?reportId=` +
        reportId + `&userId=` + userId + `&statusType=` + statusType + `&CmpCode=` + CmpCode+`&fromdate=`+from+`&todate=`+to, { headers: headers }

      )
      .map((response: Response) => response.json())
      .toPromise();
  }

  myApprovelsfilter( userId, statusType, CmpCode, from, to) {

    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(
        `${environment.apiUrl}Expanse/myApprovels?userId=`  + userId + `&statusType=` + statusType + `&CmpCode=` + CmpCode +`&fromdate=`+from+`&todate=`+to, { headers: headers }

      )
      .map((response: Response) => response.json())
      .toPromise();
  }
  advanceApprovalFilter( userId, statusType, CmpCode) {

    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(
        `${environment.apiUrl}Advancepayment/GetadvanceApprovel?userId=`  + userId + `&statusType=` + statusType + `&CmpCode=` + CmpCode, { headers: headers }

      )
      .map((response: Response) => response.json())
      .toPromise();
  }
  GetMyadvanceApprovel(advanceid, userId, statusType, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Advancepayment/GetMyadvanceApprovel?advanceid=` +
        advanceid + `&userId=` + userId + `&statusType=` + statusType + `&CmpCode=` + CmpCode, { headers: headers }
      )
      .map((response: Response) => response.json())
      .toPromise();
  }
  GetSalesPersonsAll(CustomerId, userid, CmpCode) {

    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Admin/GetSalesPersonsAll?CustomerId=` + CustomerId + `&userid=` + userid + `&CmpCode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  AddSalePerson(formdata: any) {

    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Admin/InsSalePersonCustomer`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }
  GetUserData(userid, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Admin/GetEmployeeDetailsByUserId?CmpCode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  UpdateUserData(userdatalist) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Admin/UpdateuserEmpDetail`, userdatalist,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }
  uploadempImage(userdatalist) {
   const headers = new Headers();
   let token = this.authService.getToken();
   headers.append('Authorization', `bearer ${token}`)

    return this._http
      .post(`${environment.apiUrl}Admin/uploadempImage`, userdatalist,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }

  homedashboard() {
    //not used
    return this._http
      .get(
        `/assets/projectdashboard22.json`
      )

      .map((response: Response) => response.json())
      .toPromise();
  }

  getprojectUserID(userId, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Expanse/GetExProjects?userid=`+ userId + `&CmpCode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();

  }
  genrateDalireport(projectId, CmpCode, date) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Dailyreport/GenrateDalireport?projectid=`+projectId+`&CmpCode=`+ CmpCode+`&dateon=`+date, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();

  }

  getDateValidation(projectId, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Project/GetemployeeUnassignDate?ProjectId=`+projectId+`&CmpCode=`+ CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();

  }

  genrateWeeklyreport(projectId, CmpCode, date) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}WeeklyReport/Genrateweeklyeport?projectid=`+projectId+`&CmpCode=`+ CmpCode+`&dateon=`+date, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();

  }

  getContactAndBugsHistory(email,cmpcode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    // headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`https://crg-support.com/api/getalltickets?user=`+email+`&cmpCode=`+cmpcode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();

  }
  getPendingTimeHours(Id, projectId, CmpCode, type) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}project/Getpendingtimehours?id=`+Id+`&projectid=`+projectId+`&cmpcode=`+CmpCode+`&tpye=`+type, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();

  }
  getmaxapprovaldate(projectId, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}project/Getmaxapprovaldate?projectid=`+projectId+`&cmpcode=`+CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();

  }
  Getmaxapprovaldateuserwise(projectId, CmpCode, userid) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}project/Getmaxapprovaldateuserwise?projectid=`+projectId+`&cmpcode=`+CmpCode+`&userid=`+userid, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();

  }

  deleteContactSupport(obj) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Category/Deletecontactsupport?id=`+obj.id +`&type=`+obj.type+`&deletedby=`+obj.deletedby+`&CmpCode=`+obj.cmpcode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();

  }

  deleteVendor(obj) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Admin/deletevendor?deletedby=`+obj.user+`&id=`+obj.delId+`&cmpcode=`+obj.cmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();

  }
  getTrainingListbyUserID(userId, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Category/Gettraining?userid=`+ userId + `&CmpCode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();

  }
  getProjectInvoiceDetail(projectId, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Project/ProjectInvoiceDetail?projectid=`+ projectId + `&cmpCode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();

  }
  getProjectInvoiceFiltered(Customer,from, to, projectid,CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}project/ProjectInvoicereport?customerid=`+Customer+`&projectid=`+projectid+`&fromdate=`+from+`&todate=`+to+`&cmpcode=`+ CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();

  }
  getinvoiceheader(projectId, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Project/Getinvoiceheader?projectid=`+ projectId + `&cmpCode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();

  }
  homedashboardsummary(userId, projectId, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Analytics/getprojectsummary/` + projectId + `/` + CmpCode, { headers: headers })
      //.get(`${environment.apiUrl}Analytics/getexpense/`+userId)
      .map((response: Response) => response.json())
      .toPromise();
  }
  getExpenseDeshboard(userId, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Analytics/getexpense/` + userId + `/` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();

  }
  getExpenseFilterDeshboard(userId, CmpCode, fromdate, todate, projectId, customerID, employeeId, UserType) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Analytics/getexpense/` + userId + `/` + CmpCode + `/` + fromdate + `/` + todate + `/` + projectId + `/` + customerID + `/` + employeeId + `/` + UserType, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();

  }
  // report filter api
  getExpenseDetFilter(params) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Analytics/Getexpansedetail/${params.Userid}/${params.customerid}/${params.reportid}/${params.fromdate}/${params.todate}/${params.CmpCode}/${params.useridselect}`, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();

  }
  getMomDetailReportFilter(params) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Analytics/getmomreport/${params.Userid}/${params.projectid}/${params.fromdate}/${params.todate}/${params.CmpCode}` , { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  getProjectProfitDetailFilter(params) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Analytics/getprojectprofitablity/${params.Userid}/${params.projectid}/${params.fromdate}/${params.todate}/${params.CmpCode}`, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  getPlannedVsActualFilter(params) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Analytics/getplannedvsactual/${params.Userid}/${params.projectid}/${params.fromdate}/${params.todate}/${params.status}/${params.CmpCode}`, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  getOverDueFilter(params) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Analytics/getoverduetask/${params.Userid}/${params.projectid}/${params.fromdate}/${params.todate}/${params.CmpCode}`, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  getResourceAllocationFilter(params) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Analytics/getexrptresourceallocation/${params.Userid}/${params.customerid}/${params.projectid}/${params.fromdate}/${params.todate}/${params.CmpCode}`, { headers: headers })

      .map((response: Response) => response.json())
      .toPromise();
  }

  getCustomerExpanseFilter(params) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Analytics/getcustomerexpanse/${params.Userid}/${params.customerid}/${params.reportid}/${params.fromdate}/${params.todate}/${params.CmpCode}`, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  getMilegeExpanseFilter(params) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Analytics/getmilegeexpanse/${params.Userid}/${params.customerid}/${params.reportid}/${params.fromdate}/${params.todate}/${params.CmpCode}`, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  getProjectLeavePlanFilter(params) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Analytics/getprojectleaveplannerreport/${params.Userid}/${params.projectid}/${params.customerid}/${params.fromdate}/${params.todate}/${params.CmpCode}`, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  getPolicyViolationFilter(params) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Analytics/getpolicyviolation/${params.Userid}/${params.customerid}/${params.reportid}/${params.fromdate}/${params.todate}/${params.CmpCode}`, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  getGetmontlyleave(params) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}OrganizationReport/Getmontlyleave?startdate=${params.fromdate}&departmentId=${params.departmentId}&designationId=${params.designationId}&enddate=${params.todate}&leavetype=0&userid=${params.Userid}&cmpcode=${params.CmpCode}`, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  getExitEmployeesList(params) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}OrganizationReport/GetExitEmployeesList?startdate=${params.fromdate}&departmentId=${params.departmentId}&designationId=${params.designationId}&enddate=${params.todate}&userid=${params.Userid}&cmpcode=${params.CmpCode}`, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  getTimeSheetDetailList(params) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}OrganizationReport/EmployeetimesheetdetailMonthlywise?projetid=${params.projectid}&cmpcode=${params.cmpcode}&fromdate=${params.fromdate}&todate=${params.todate}&userid=${params.userid}&customerid=${params.customerid}&recordTimeStatus=${params.recordTimeStatus}`, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }


  getUsersonBoarded(params) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}OrganizationReport/GetUsersonBoarded?startdate=${params.fromdate}&departmentId=${params.departmentId}&designationId=${params.designationId}&enddate=${params.todate}&userid=${params.Userid}&cmpcode=${params.CmpCode}`, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  getUsersoffBoarded(params) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}OrganizationReport/GetUsersoffBoarded?startdate=${params.fromdate}&departmentId=${params.departmentId}&designationId=${params.designationId}&enddate=${params.todate}&userid=${params.Userid}&cmpcode=${params.CmpCode}`, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  getProjectoffBoarded(params) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}OrganizationReport/ProjectoffBoarded?startdate=${params.fromdate}&departmentId=${params.departmentId}&designationId=${params.designationId}&enddate=${params.todate}&userid=${params.Userid}&cmpcode=${params.CmpCode}`, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  getProjectonBoarded(params) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}OrganizationReport/ProjectonBoarded?startdate=${params.fromdate}&departmentId=${params.departmentId}&designationId=${params.designationId}&enddate=${params.todate}&userid=${params.Userid}&cmpcode=${params.CmpCode}`, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  getUserwiseExpanseFilter(params) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Analytics/getuserwiseexpanse/${params.Userid}/${params.customerid}/${params.reportid}/${params.fromdate}/${params.todate}/${params.CmpCode}/${params.status}`, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  ExpenseRepHomepage(userId, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Analytics/getGetApprovelReport?CmpCode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();

  }
  getRoadBlockReportFilter(params) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Analytics/getroadblock/${params.projectid}/${params.fromdate}/${params.todate}/${params.CmpCode}/${params.UserType}/${params.userId}`, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  getResourceWorkFilter(params) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Analytics/getexrptresourceworkdetail/${params.Userid}/${params.projectid}/${params.employeeid}/${params.fromdate}/${params.todate}/${params.CmpCode}`, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
    getClientVsPOFilter(params) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Analytics/GetClientVsPo?EmployeeId=${params?.Userid}&fromDate=${params?.fromdate}&toDate=${params?.todate}&CmpCode=${params?.CmpCode}`, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  getResourceVsPoFilter(params) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Analytics/GetResourceVsPo?EmployeeId=${params?.Userid}&fromDate=${params?.fromdate}&toDate=${params?.todate}&CmpCode=${params?.CmpCode}&projectid=${params?.projectid}`, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  getCategoryExpanseFilter(params) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Analytics/Getcategoryexpanse/${params.Userid}/${params.customerid}/${params.reportid}/${params.fromdate}/${params.todate}/${params.CmpCode}/${params.useridselect}`, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  approvalExpense(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Approval/createInsApprovalbulk`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();

  }

  GetChangeRequestFilter(params) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Analytics/GetExchangerequest?projectid=` + params.projectid + `&status=` + params.status + `&employeeId=` + params.employeeid + `&fromdate=` + params.fromdate + `&todate=` + params.todate + `&Cmpcode=` + params.cmpcode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  getUnsubmittedFilter(params) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Analytics/Getunsubmittedexpanse/${params.Userid}/${params.customerid}/${params.reportid}/${params.fromdate}/${params.todate}/${params.CmpCode}/${params.useridselect}`, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  GetAllemployees(employeeid, CmpCode) {

    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Approval/GetAllemployees?employeeid=` + employeeid + `&CmpCode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  GetAllemployeesfor(employeeid, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Approval/GetAllemployee?employeeid=` + employeeid + `&CmpCode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  GetMileagecategory(CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Policy/GetMileagecategory?CmpCode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  Getdimecategory(CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Policy/Getdimecategory?CmpCode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  GetcurrencyOt(base, symbols, end_at, CmpCode) {
    return this._http
      .get(`https://api.exchangeratesapi.io/latest?access_key=d65076a7a3f032587fb6b08d413e817f&base=` + base + `&symbols=` + symbols + `&end_at=` + end_at)
      // .get(`http://apilayer.net/api/live?access_key=44cba030eb58d4746260a9e8756e4da6&currencies=`+base+`&source=`+symbols+
      // `&format=1&dateformate=`+end_at)
      .map((response: Response) => response.json())
      .toPromise();
  }
  GetChangerequestRPT(model) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Analytics/GetChangerequestRPT?requestedby=` + model.requestedby +
        `&projectid=` + model.projectid + `&clientstatus=` + model.clientstatus + `&fromDate=` + model.fromDate + `&toDate=` + model.toDate + `&Cmpcode=` + model.CmpCode + `&UserType=` + model.UserType + `&userId=` + model.userId, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  GetReportuser(userid, CmpCode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Expanse/ExReportuserbyApp?userid=` + userid + `&CmpCode=` + CmpCode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  leaveCompOffBulkApproval(parms: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Leave/LeaveCompOffBulkApproval`, parms,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();

  }

  EventDecline(parms: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Timesheet/EventDecline`, parms,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();

  }

  getKanbanData(value, cmpcode, userid) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Project/GetPhaseTask/` + value + '/' + cmpcode + '/' + userid, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();

  }
  //
  PostKanbanData(parms) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Project/createPhaseTask`, parms,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();

  }
  PostUpdateStringPredeseccor(parms) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Project/editPredecessorproject`, parms,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();

  }
  PostMomData(parms) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}mom/UpdateMomactionstatus`, parms,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();

  }
  getShedularTimeSheetData(fromdate, todate, projectId, empId, cmpcode, type) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Timesheet/Gettimesheetnew?EmployeeId=` + empId + '&fromDate=' + fromdate + '&toDate=' + todate + '&CmpCode=' + cmpcode + '&projectId=' + projectId + '&type=' + type, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();


  }

  getShedularResoruceDate(fromdate, todate, projectId, empId, cmpcode, userid, type) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Timesheet/Gettimesheetresource?EmployeeId=` + empId + '&fromDate=' + fromdate + '&toDate=' + todate + '&CmpCode=' + cmpcode + '&projectId=' + projectId + '&userid=' + userid + '&type=' + type, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();


  }
  getShedularTimeSheetHoliday(fromdate, todate, empId, cmpcode, projectid) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Timesheet/GetProjectHolidaystimesheet?EmployeeId=` + empId + '&projectId=' + projectid + '&CmpCode=' + cmpcode + '&fromDate=' + fromdate + '&toDate=' + todate, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();


  }
  getShedularTimeSheetMultiData(userid, projectId, fromdate, todate, empId, cmpcode, type) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Timesheet/Gettimesheetmulti?EmployeeId=` + empId + '&fromDate=' + fromdate + '&toDate=' + todate + '&CmpCode=' + cmpcode + '&projectId=' + projectId + '&userid=' + userid + '&type=' + type, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();


  }
  GettimesheetdatewiseforDetail(userid, projectId, fromdate, todate, empId, cmpcode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Timesheet/Gettimesheetdatewise?EmployeeId=` + empId + '&fromDate=' + fromdate + '&toDate=' + todate + '&CmpCode=' + cmpcode + '&projectId=' + projectId + '&userid=' + userid, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();


  }
  forDetailForResource(userid, projectId, fromdate, todate, empId, cmpcode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');

    return this._http
      .get(`${environment.apiUrl}Timesheet/Getpoolresource?EmployeeId=` + empId + '&fromDate=' + fromdate + '&toDate=' + todate + '&CmpCode=' + cmpcode + '&projectId=' + projectId + '&userid=' + userid, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  Getorginzationchartlist(userid, cmpcode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}project/Getorgchart?projectid=` + userid + '&cmpcode=' + cmpcode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();


  }
  Getorginzationteamlist(userid, cmpcode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}project/getorgcharprojectteam?projectid=` + userid + '&cmpcode=' + cmpcode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();


  }

  postApprovalData(parms: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Timesheet/TimesheetApprovemulti`, parms,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }

  getResourceAllocationData(projectId, fromdate, todate, userid, cmpcode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}analytics/gettaskownersheet/` + fromdate + `/` + todate + '/' + projectId + '/' + userid + '/' + cmpcode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();

  }
  getPdfViewerData(projectId, cmpcode, ondate, type) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Advancepayment/Getpdfviwer?projectId=` + projectId + '&Cmpcode=' + cmpcode + '&onDate=' + ondate + '&type=' + type, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();

  }

  postTaskpercentageKanban(parms: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Timesheet/TimesheetPercentage/`, parms,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }
  postReportDetail(parms: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}project/savereportdetail`, parms,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }

  projectBudgetDetail(userId, projectId, cmpcode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}project/projectbudget?`+'projectid=' + projectId + '&cmpcode=' + cmpcode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();

  }
  apigetReportDetail(cmpcode, type) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Project/Getpmsreport?Cmpcode=` + cmpcode + '&type=' + type, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();

  }

  // mom/Getmomkanban?userid=10419&CmpCode=1
  getMomList(userid, cmpcode, projectId) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    // .pipe(map((response: Response) => response));
    return this._http
      .get(`${environment.apiUrl}mom/Getmomkanban?userid=` + userid + '&CmpCode=' + cmpcode+'&projectId='+projectId, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();

  }
  getHolidayList(cmpcode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    // .pipe(map((response: Response) => response));
    return this._http
      .get(`${environment.apiUrl}Holiday/GetClientHolidayList?Cmpcode=` + cmpcode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  saveClientHoliday(parms: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Holiday/SaveclientHoliday`, parms,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }
  multiApproveTimecorrection(parms: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Timesheet/TimecorrectionApprovemulti`, parms,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }
  deleteClientHoliday(parms: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Expanse/DeleteFormID`, parms,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }
  getHolidayListMapping(Custid, cmpcode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    // .pipe(map((response: Response) => response));
    return this._http
      .get(`${environment.apiUrl}Holiday/Getclientholidaymapping?customerid=` + Custid + '&Cmpcode=' + cmpcode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();

  }
  AddHoliday(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Holiday/Customerholidaymapping`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }

  ConfiguringZohoPeople(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}ConfigureKeys/ConfiguringZohoPeople`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }

  getGoogleToken(cmpCode, user) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}ConfigureKeys/GetTokens?Cmpcode=`+cmpCode+'&UserId='+user+'&IsActive=true', { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  getMicrosoftToken(cmpCode, user) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}ConfigureKeys/MicrosoftUserTokens?Cmpcode=`+cmpCode+'&UserId='+user+'&IsActive=true', { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }


  getGoogleTokenHeader(params) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}ConfigureKeys/GoogleUserTokens?Cmpcode=`+params.Cmpcode+`&UserId=`+params.UserId+`&IsActive=`+params.IsActive, params, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  getGoogleTokenSync(params) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}ConfigureKeys/GetTokens?Cmpcode=`+params.Cmpcode+`&UserId=`+params.UserId+`&IsActive=`+params.IsActive, params, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  getMicrosoftTokenHeader(params) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}ConfigureKeys/GetMicroSoftTokens?Cmpcode=`+params.Cmpcode+`&UserId=`+params.UserId+`&IsActive=`+params.IsActive, params,{ headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  getMicrosoftTokenSync(params) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}ConfigureKeys/MicrosoftUserTokens?Cmpcode=`+params.Cmpcode+`&UserId=`+params.UserId+`&IsActive=`+params.IsActive, params,{ headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  ConfiguringMicroSoft(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}ConfigureKeys/ConfiguringMicroSoft`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }
  ConfiguringGoogle(formdata: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}ConfigureKeys/ConfiguringGoogle`, formdata,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }

  getCategoryListbyReportid(reportid, cmpcode) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    //http://localhost:63000/api/Advancepayment/Getreportcustomer?reportid=92&cmpcode=1
    // .pipe(map((response: Response) => response));
    return this._http
      .get(`${environment.apiUrl}Advancepayment/Getreportcustomer?reportid=` + reportid + '&Cmpcode=' + cmpcode, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  getFaqCategory(categoryId) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');

    return this._http
      .get(`${environment.apiUrl}Admin/Getfaqcategory?categoryid=` + categoryId, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  AddFaqDetail(parms: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Admin/addfaqdetail`, parms, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  getFaqDetail(id, search) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Admin/Getfaqdetail?id=` + id + '&search=' + search, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  getAccounts() {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .get(`${environment.apiUrl}Accounts/Getservice`, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }


  getHeaders() {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
   //  debugger;
    return this._http
      .get('https://geolocation-db.com/json', { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }


  Employeecheckinreport(params: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    // debugger;
    return this._http
      .get(`${environment.apiUrl}Accounts/checkemployeereport?userid=` + params.employee + '&cmpcode=' + params.cmpcode + '&checkinDate=' + params.fromdate
        + '&checkoutDate=' + params.todate, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }
  getUserAttendance(userid,cmpcode,checkIn,checkout) {
    //Admin/Getuserattendeance?userid=&cmpcode=&checkintime=&checkouttime=
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    // debugger;
    return this._http
      .get(`${environment.apiUrl}Admin/Getuserattendeance?userid=` + userid + '&cmpcode=' + cmpcode + '&checkintime=' + checkIn
        + '&checkouttime=' + checkout, { headers: headers })
      .map((response: Response) => response.json())
      .toPromise();
  }

  ModifyTimeCorrection(parms: any) {
    const headers = new Headers();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    return this._http
      .post(`${environment.apiUrl}Admin/timecorrectionapprovalreject`, parms,{headers:headers})
      .map((response: Response) => response.json())
      .toPromise();
  }

getProjectForCustomer(userid,cmpcode)
{
  const headers = new Headers();
  let token = this.authService.getToken();
  headers.append('Authorization', `bearer ${token}`)
  headers.append('Content-Type', 'application/json');
  // debugger;
  return this._http
    .get(`${environment.apiUrl}Project/Getcontactuserbyproject?contacttid=` + userid + '&cmpcode=' + cmpcode ,
       { headers: headers })
    .map((response: Response) => response.json())
    .toPromise();
}
getProjectForSale(userid,cmpcode)
{
  const headers = new Headers();
  let token = this.authService.getToken();
  headers.append('Authorization', `bearer ${token}`)
  headers.append('Content-Type', 'application/json');
  // debugger;
  return this._http
    .get(`${environment.apiUrl}Project/GetSalespersonbyproject?salepersonid=` + userid + '&cmpcode=' + cmpcode ,
       { headers: headers })
    .map((response: Response) => response.json())
    .toPromise();
}
getProjectForInviteUserList(userid,cmpcode)
{
  const headers = new Headers();
  let token = this.authService.getToken();
  headers.append('Authorization', `bearer ${token}`)
  headers.append('Content-Type', 'application/json');
  // debugger;
  return this._http
    .get(`${environment.apiUrl}Project/Getuserbyproject?userid=` + userid + '&cmpcode=' + cmpcode ,
       { headers: headers })
    .map((response: Response) => response.json())
    .toPromise();
}

getCustomerForInviteUserList(userid,cmpcode)
{
  const headers = new Headers();
  let token = this.authService.getToken();
  headers.append('Authorization', `bearer ${token}`)
  headers.append('Content-Type', 'application/json');
  // debugger;
  return this._http
    .get(`${environment.apiUrl}Project/Getuserbycustomer?userid=` + userid + '&cmpcode=' + cmpcode ,
       { headers: headers })
    .map((response: Response) => response.json())
    .toPromise();
}
getContactSupportList(params)
{
  const headers = new Headers();
  let token = this.authService.getToken();
  headers.append('Authorization', `bearer ${token}`)
  headers.append('Content-Type', 'application/json');
  return this._http
    .post(`${environment.apiUrl}Project/CrreateFeedBackProduct`, params,{headers:headers})
    .map((response: Response) => response.json())
    .toPromise();
}
getScheduleServices(): any{
  const headers = new Headers()
  let token = this.authService.getToken();
  headers.append('Authorization', `bearer ${token}`)
  headers.append('Content-Type', 'application/json')
  return this._http
    .get(`${environment.apiUrl}Accounts/Getschdeulereport`,{headers:headers})
    .map((response: Response) => response.json())
    .toPromise();
}
getContactSupportListconcern(params)
{
  const headers = new Headers();
  let token = this.authService.getToken();
  headers.append('Authorization', `bearer ${token}`)
  headers.append('Content-Type', 'application/json');
  return this._http
    .post(`${environment.apiUrl}Project/CreateFeedBackProject`, params,{headers:headers})
    .map((response: Response) => response.json())
    .toPromise();
}

AdminApproval(parms)
{
  const headers = new Headers();
  let token = this.authService.getToken();
  headers.append('Authorization', `bearer ${token}`)
  headers.append('Content-Type', 'application/json');
  return this._http
    .post(`${environment.apiUrl}Leave/AllLevelApproval`, parms,{headers:headers})
    .map((response: Response) => response.json())
    .toPromise();
}

AdminApprovalForCompoff(parms)
{
  const headers = new Headers();
  let token = this.authService.getToken();
  headers.append('Authorization', `bearer ${token}`)
  headers.append('Content-Type', 'application/json');
  return this._http
    .post(`${environment.apiUrl}CompensatoryOff/AlllevelCompOffApproval`, parms,{headers:headers})
    .map((response: Response) => response.json())
    .toPromise();
}

CompoffApproval(parms)
{
  const headers = new Headers();
  let token = this.authService.getToken();
  headers.append('Authorization', `bearer ${token}`)
  headers.append('Content-Type', 'application/json');
  return this._http
    .post(`${environment.apiUrl}CompensatoryOff/CompOffApproval`, parms,{headers:headers})
    .map((response: Response) => response.json())
    .toPromise();
}

getApproveList(parms)
{
  const headers = new Headers();
  let token = this.authService.getToken();
  headers.append('Authorization', `bearer ${token}`)
  headers.append('Content-Type', 'application/json');
  return this._http
    .post(`${environment.apiUrl}Leave/LeaveApproval`, parms,{headers:headers})
    .map((response: Response) => response.json())
    .toPromise();
}

getConfiguringZohoPeople(parms){
  const headers = new Headers();
  let token = this.authService.getToken();
  headers.append('Authorization', `bearer ${token}`)
  headers.append('Content-Type', 'application/json');
  return this._http
    .get(`${environment.apiUrl}ConfigureKeys/getConfiguringZohoPeople?Cmpcode=`+parms, {body:parms,headers:headers})
    .map((response: Response) => response.json())
    .toPromise();
}

getConfiguringMicroSoft(parms){
  const headers = new Headers();
  let token = this.authService.getToken();
  headers.append('Authorization', `bearer ${token}`)
  headers.append('Content-Type', 'application/json');
  return this._http
    .get(`${environment.apiUrl}ConfigureKeys/getConfiguringMicroSoft?Cmpcode=`+parms,{body:parms,headers:headers})
    .map((response: Response) => response.json())
    .toPromise();
}

getConfiguringMicroSoftHeader(cmp, user){
  const headers = new Headers();
  let token = this.authService.getToken();
  headers.append('Authorization', `bearer ${token}`)
  headers.append('Content-Type', 'application/json');
  return this._http
  .get(`${environment.apiUrl}ConfigureKeys/getConfiguringMicroSoftEmployees?Cmpcode=`+cmp+'&UserId='+user+'&IsActive=true',{headers:headers})
    .map((response: Response) => response.json())
    .toPromise();
}

SaveZohoIntegrationSettings(parms){
  const headers = new Headers();
  let token = this.authService.getToken();
  headers.append('Authorization', `bearer ${token}`)
  headers.append('Content-Type', 'application/json');
  return this._http
    .post(`${environment.apiUrl}ConfigureKeys/ZohoIntegrationSettings`,parms,{headers:headers})
    .map((response: Response) => response.json())
    .toPromise();
}

getZohoSettings(parms){
  const headers = new Headers();
  let token = this.authService.getToken();
  headers.append('Authorization', `bearer ${token}`)
  headers.append('Content-Type', 'application/json');
  return this._http
    .get(`${environment.apiUrl}ConfigureKeys/getZohoIntegrationSettings?Cmpcode=`+parms,{body:parms,headers:headers})
    .map((response: Response) => response.json())
    .toPromise();
}

getConfiguringGoogle(parms){
  const headers = new Headers();
  let token = this.authService.getToken();
  headers.append('Authorization', `bearer ${token}`)
  headers.append('Content-Type', 'application/json');
  return this._http
    .get(`${environment.apiUrl}ConfigureKeys/getConfiguringGoogle?Cmpcode=`+parms,{body:parms,headers:headers})
    .map((response: Response) => response.json())
    .toPromise();
}

getConfiguringGoogleHeader(cmp, user){
  const headers = new Headers();
  let token = this.authService.getToken();
  headers.append('Authorization', `bearer ${token}`)
  headers.append('Content-Type', 'application/json');
  return this._http
    .get(`${environment.apiUrl}ConfigureKeys/getConfiguringGoogleEmployees?Cmpcode=`+cmp+'&UserId='+user+'&IsActive=true',{headers:headers})
    .map((response: Response) => response.json())
    .toPromise();
}

PauseSync(data){
  const headers = new Headers();
  let token = this.authService.getToken();
  headers.append('Authorization', `bearer ${token}`)
  headers.append('Content-Type', 'application/json');
  return this._http
    .post(`${environment.apiUrl}ConfigureKeys/Pause?IsActive=`+data.IsActive+`&Cmpcode=`+data.Cmpcode+`&UserId=`+data.UserId+`&serviceType=`+data.serviceType,{body:data,headers:headers})
    .map((response: Response) => response.json())
    .toPromise();
}

IsConfigurationsExist(cmp){
  const headers = new Headers();
  let token = this.authService.getToken();
  headers.append('Authorization', `bearer ${token}`)
  headers.append('Content-Type', 'application/json');
  return this._http
    .get(`${environment.apiUrl}ConfigureKeys/IsConfigurationsExist?Cmpcode=`+cmp,{headers:headers})
    .map((response: Response) => response.json())
    .toPromise();
}

IsConfigurationsEmployeeExist(cmp, user){
  const headers = new Headers();
  let token = this.authService.getToken();
  headers.append('Authorization', `bearer ${token}`)
  headers.append('Content-Type', 'application/json');
  return this._http
    .get(`${environment.apiUrl}ConfigureKeys/IsConfigurationsExistForEmployee?Cmpcode=`+cmp+`&UserId=`+user,{headers:headers})
    .map((response: Response) => response.json())
    .toPromise();
}

getconfigurationHistory(parms){
  const headers = new Headers();
  let token = this.authService.getToken();
  headers.append('Authorization', `bearer ${token}`)
  headers.append('Content-Type', 'application/json');
  return this._http
    .get(`${environment.apiUrl}Admin/GetconfigurationHistory?cmpcode=`+parms+'&type=MicroSoft',{body:parms,headers:headers})
    .map((response: Response) => response.json())
    .toPromise();
}
getIntegrationStatus(parms){
  const headers = new Headers();
  let token = this.authService.getToken();
  headers.append('Authorization', `bearer ${token}`)
  headers.append('Content-Type', 'application/json');
  return this._http
    .get(`${environment.apiUrl}Admin/isexistsconfiguration?cmpcode=`+parms,{body:parms,headers:headers})
    .map((response: Response) => response.json())
    .toPromise();
}

getReportingTo(cmpcode)
{
  const headers = new Headers();
  let token = this.authService.getToken();
  headers.append('Authorization', `bearer ${token}`)
  headers.append('Content-Type', 'application/json');
  // debugger;
  return this._http
    .get(`${environment.apiUrl}Admin/Getreportingtousers?Cmpcode=` + cmpcode ,
       { headers: headers })
    .map((response: Response) => response.json())
    .toPromise();
}

getProjectFeedbackFilter(params) {
  debugger
  const headers = new Headers();
  let token = this.authService.getToken();
  headers.append('Authorization', `bearer ${token}`)
  headers.append('Content-Type', 'application/json');
  return this._http
    .get(`${environment.apiUrl}Project/GetProductFeedbackrpt?fromdate=`+params.fromdate+'&todate=' + params.todate+'&userid=' + params.userid+'&cmpcode=' + params.cmpcode, { headers: headers })

    .map((response: Response) => response.json())
    .toPromise();
}

getresourcefreeondate(params) {
  debugger
  const headers = new Headers();
  let token = this.authService.getToken();
  headers.append('Authorization', `bearer ${token}`)
  headers.append('Content-Type', 'application/json');
  return this._http
    .get(`${environment.apiUrl}Analytics/Getresourcefreeondate?dateon=`+params.dateon+'&projectid=' + params.projectid+'&CmpCode=' + params.cmpcode+'&userid='+params.userid, { headers: headers })

    .map((response: Response) => response.json())
    .toPromise();
}

getProductFeedbackrpt(params) {
  debugger
  const headers = new Headers();
  let token = this.authService.getToken();
  headers.append('Authorization', `bearer ${token}`)
  headers.append('Content-Type', 'application/json');
  return this._http
    .get(`${environment.apiUrl}Analytics/Getresourcetimeprojectwise?formdate=`+params.fromdate+'&todate=' + params.todate+'&dateon=' + params.dateon+'&CmpCode=' + params.cmpcode+'&userid=' + params.userid, { headers: headers })

    .map((response: Response) => response.json())
    .toPromise();
}
getProjectConcernFilter(params) {
  debugger
  const headers = new Headers();
  let token = this.authService.getToken();
  headers.append('Authorization', `bearer ${token}`)
  headers.append('Content-Type', 'application/json');
  return this._http
    .get(`${environment.apiUrl}Project/GetProjectFeedbackrpt?fromdate=`+params.fromdate+'&todate=' + params.todate+'&userid=' + params.userid+'&cmpcode=' + params.cmpcode+'&projectid=' + params.projectid, { headers: headers })

    .map((response: Response) => response.json())
    .toPromise();
}
postCommentData(parms: any) {
  const headers = new Headers();
  let token = this.authService.getToken();
  headers.append('Authorization', `bearer ${token}`)
  headers.append('Content-Type', 'application/json');
  return this._http
    .post(`${environment.apiUrl}Timesheet/Savecomments`, parms,{headers:headers})
    .map((response: Response) => response.json())
    .toPromise();
}
getReportingDetail(userid, CmpCode) {
  const headers = new Headers();
  let token = this.authService.getToken();
  headers.append('Authorization', `bearer ${token}`)
  headers.append('Content-Type', 'application/json');
  return this._http
    .get(`${environment.apiUrl}Project/Getmyreportingteam?userid=` + userid + `&CmpCode=` + CmpCode, { headers: headers })
    .map((response: Response) => response.json())
    .toPromise();
}
postReportingDetail(formdata: any) {
  const headers = new Headers();
  let token = this.authService.getToken();
  headers.append('Authorization', `bearer ${token}`)
  headers.append('Content-Type', 'application/json');
  return this._http
    .post(`${environment.apiUrl}Project/Reportingmanagertransfer`, formdata,{headers:headers})
    .map((response: Response) => response.json())
    .toPromise();
}
getTabSelection(userid, CmpCode) {
  const headers = new Headers();
  let token = this.authService.getToken();
  headers.append('Authorization', `bearer ${token}`)
  headers.append('Content-Type', 'application/json');
  return this._http
    .get(`${environment.apiUrl}Advancepayment/Getsubsitudestep?userid=` + userid + `&cmpcode=` + CmpCode, { headers: headers })
    .map((response: Response) => response.json())
    .toPromise();
}
LeaveGetPolicy(CmpCode) {
  const headers = new Headers();
  let token = this.authService.getToken();
  headers.append('Authorization', `bearer ${token}`)
  headers.append('Content-Type', 'application/json');
  return this._http
    .get(`${environment.apiUrl}Policy/GetLeavePolicy?CmpCode=`+ CmpCode, { headers: headers })
    .map((response: Response) => response.json())
    .toPromise();
}
postWorkOrder(parms: any) {
  const headers = new Headers();
  let token = this.authService.getToken();
  headers.append('Authorization', `bearer ${token}`)
  headers.append('Content-Type', 'application/json');
  return this._http
    //.post(`${environment.apiUrl}ProjectOrderDetail/CreateWorkOrderDetail`, parms,{headers:headers})
    .post(`${environment.apiUrl}Project/CreateWorkOrderDetail`, parms,{headers:headers})

    .map((response: Response) => response.json())
    .toPromise();
}

postWorkOrderImages(formdata: any) {
  debugger;
  const headers = new Headers();
  let token = this.authService.getToken();
  headers.append('Authorization', `bearer ${token}`)

  return this._http
  .post(`${environment.apiUrl}Project/uploadWorKOrder`, formdata,{headers:headers})
    .map((response: Response) => response.json())
    .toPromise();
}

postInvoiceImages(parms: any) {
  debugger;
  const headers = new Headers();
  let token = this.authService.getToken();
  headers.append('Authorization', `bearer ${token}`)
  return this._http
    //.post(`${environment.apiUrl}ProjectOrderDetail/CreateWorkOrderDetail`, parms,{headers:headers})
    .post(`${environment.apiUrl}project/uploadinvoiceOrder`, parms,{headers:headers})

    .map((response: Response) => response.json())
    .toPromise();
}

getWorkOrderDetail(projectid,CmpCode) {
  const headers = new Headers();
  let token = this.authService.getToken();
  headers.append('Authorization', `bearer ${token}`)
  headers.append('Content-Type', 'application/json');
  return this._http
    .get(`${environment.apiUrl}Project/GetProjectWorkorderDetail?projectid=`+ projectid+`&cmpcode=` + CmpCode, { headers: headers })
    .map((response: Response) => response.json())
    .toPromise();
}
GetmonthwisetimesheetRPT(Customer,from, to, projectid,CmpCode) {
  const headers = new Headers();
  let token = this.authService.getToken();
  headers.append('Authorization', `bearer ${token}`)
  headers.append('Content-Type', 'application/json');
  return this._http
    .get(`${environment.apiUrl}Timesheet/GetmonthwisetimesheetRPT?fromdate=`+from+`&todate=`+to+`&cmpcode=`+CmpCode, { headers: headers })
    .map((response: Response) => response.json())
    .toPromise();
}

gettimesheetNonWorkingRPT(Customer,from, to, projectid,CmpCode) {
  const headers = new Headers();
  let token = this.authService.getToken();
  headers.append('Authorization', `bearer ${token}`)
  headers.append('Content-Type', 'application/json');
  return this._http
    .get(`${environment.apiUrl}Timesheet/GettimesheetNonWorkingRPT?fromdate=`+from+`&todate=`+to+`&cmpcode=`+CmpCode+`&projectid=`+projectid, { headers: headers })
    .map((response: Response) => response.json())
    .toPromise();
}

getWorkOrderFiltered(Customer,from, to, projectid,CmpCode) {
  const headers = new Headers();
  let token = this.authService.getToken();
  headers.append('Authorization', `bearer ${token}`)
  headers.append('Content-Type', 'application/json');
  return this._http
    .get(`${environment.apiUrl}project/GetProjectWorkorderreport?customerid=`+Customer+`&projectid=`+projectid+`&fromdate=`+from+`&todate=`+to+`&cmpcode=`+CmpCode, { headers: headers })
    .map((response: Response) => response.json())
    .toPromise();
}
getCustomerForWorkOrderDetail(projectid,CmpCode) {
  const headers = new Headers();
  let token = this.authService.getToken();
  headers.append('Authorization', `bearer ${token}`)
  headers.append('Content-Type', 'application/json');
  return this._http
    .get(`${environment.apiUrl}Project/Getprojectcustomerwise?projectid=`+projectid+`&cmpcode=` + CmpCode, { headers: headers })
    .map((response: Response) => response.json())
    .toPromise();
}

getExtendworkorderdetail(pId, wId, CmpCode) {
  const headers = new Headers();
  let token = this.authService.getToken();
  headers.append('Authorization', `bearer ${token}`)
  headers.append('Content-Type', 'application/json');
  return this._http
    .get(`${environment.apiUrl}Project/GetExtendworkorderdetail?workorderid=`+wId+`&projectid=`+pId+`&cmpcode=` + CmpCode, { headers: headers })
    .map((response: Response) => response.json())
    .toPromise();
}

saveExtendworkorderdetail(data) {
  const headers = new Headers();
  let token = this.authService.getToken();
  headers.append('Authorization', `bearer ${token}`)
  headers.append('Content-Type', 'application/json');
  return this._http
    .post(`${environment.apiUrl}Project/ProjectWorkOrderExtend`,data, { headers: headers })
    .map((response: Response) => response.json())
    .toPromise();
}

getProjectsForWorkOrder(id,CmpCode) {
  const headers = new Headers();
  let token = this.authService.getToken();
  headers.append('Authorization', `bearer ${token}`)
  headers.append('Content-Type', 'application/json');
  return this._http
    .get(`${environment.apiUrl}project/Getworkorderbreakup?workOrderNumber=`+id+`&cmpcode=` + CmpCode, { headers: headers })
    .map((response: Response) => response.json())
    .toPromise();
}
getProjectsForInvoice(wid,Iid,CmpCode) {
  const headers = new Headers();
  let token = this.authService.getToken();
  headers.append('Authorization', `bearer ${token}`)
  headers.append('Content-Type', 'application/json');
  return this._http
    .get(`${environment.apiUrl}project/Getinvoicebreakup?workOrderNumber=`+wid+`&invoiceno=`+Iid+`&cmpcode=`+CmpCode, { headers: headers })
    .map((response: Response) => response.json())
    .toPromise();
}
getServiceListView(pid,Iid,CmpCode) {
  const headers = new Headers();
  let token = this.authService.getToken();
  headers.append('Authorization', `bearer ${token}`)
  headers.append('Content-Type', 'application/json');
  return this._http
    .get(`${environment.apiUrl}project/Invoiceservicedetail?projectid=`+pid+`&invoiceid=`+Iid+`&cmpcode=`+CmpCode, { headers: headers })
    .map((response: Response) => response.json())
    .toPromise();
}
getServiceListEdit(pid,Wid,Iid,CmpCode) {
  const headers = new Headers();
  let token = this.authService.getToken();
  headers.append('Authorization', `bearer ${token}`)
  headers.append('Content-Type', 'application/json');
  return this._http
    .get(`${environment.apiUrl}Project/Getservicedetail?projectid=`+pid+`&workordernumber=`+Wid+`&invoiceid=`+Iid+`&cmpcode=`+CmpCode, { headers: headers })
    .map((response: Response) => response.json())
    .toPromise();
}
// Project/GetProjectWorkorderDetail?projectid=&cmpcode=
getWorkOrderHeader(projectid,CmpCode) {
  const headers = new Headers();
  let token = this.authService.getToken();
  headers.append('Authorization', `bearer ${token}`)
  headers.append('Content-Type', 'application/json');
  return this._http
    .get(`${environment.apiUrl}Project/Getworkorderheader?projectid=`+ projectid+`&cmpcode=` + CmpCode, { headers: headers })
    .map((response: Response) => response.json())
    .toPromise();
}
getDeletWordOrderDetail(parms: any) {
  const headers = new Headers();
  let token = this.authService.getToken();
  headers.append('Authorization', `bearer ${token}`)
  headers.append('Content-Type', 'application/json');
  return this._http
    //.post(`${environment.apiUrl}ProjectOrderDetail/CreateWorkOrderDetail`, parms,{headers:headers})
    .post(`${environment.apiUrl}Project/DeleteProjectWorkorder`, parms,{headers:headers})

    .map((response: Response) => response.json())
    .toPromise();
}
getUserMapLocation(projectid,userid, stdate,enddate, CmpCode) {
  const headers = new Headers();
  let token = this.authService.getToken();
  headers.append('Authorization', `bearer ${token}`)
  headers.append('Content-Type', 'application/json');
  return this._http
    .get(`${environment.apiUrl}Admin/Getcheckincheckoutlocationteam?projectid=`+projectid+`&userid=`+userid+`&startdate=`+stdate+`&enddate=`+enddate+`&cmpcode=`+CmpCode ,{headers:headers})
    .map((response: Response) => response.json())
    .toPromise();
}


}
