import {
  AfterViewInit,
  Component,
  ContentChild,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  Renderer2,
  TemplateRef,
  ViewChild
} from '@angular/core';

import {LayoutStore} from '../layout.store';
import { AuthService } from 'src/app/services/auth.service';

import {HeaderService} from './header.service';
import { DatePipe } from '@angular/common'

import {removeListeners, removeSubscriptions} from '../../helpers';
import { ExpenseHttpService } from 'src/app/services/expense/expense-http.service';
import { ToasterService } from 'src/app/+layout/expense/toaster/toaster.service';

/**
 * Header Logo
 */
@Component({
  selector: 'mk-layout-header-logo',
  template: '<ng-template #templateRef><ng-content></ng-content></ng-template>'
})
export class HeaderLogoComponent {
  @ViewChild('templateRef') public templateRef: TemplateRef<any>;
}

/**
 * Header Logo Mini
 */
@Component({
  selector: 'mk-layout-header-logo-mini',
  template: '<ng-template #templateRef><ng-content></ng-content></ng-template>'
})
export class HeaderLogoMiniComponent {
  @ViewChild('templateRef') public templateRef: TemplateRef<any>;
}

/**
 * Header
 */
@Component({
  selector: 'mk-layout-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements AfterViewInit, OnDestroy {
  private isSidebarLeftCollapsed: boolean;
  private isSidebarRightCollapsed: boolean;
  private listeners = [];
  private subscriptions = [];

  @Input() isSidebarLeftToggle = true;
  @Input() isSidebarRightToggle = true;

  @ContentChild(HeaderLogoComponent) public headerLogoComponent: HeaderLogoComponent;
  @ContentChild(HeaderLogoMiniComponent) public headerLogoMiniComponent: HeaderLogoMiniComponent;

  @ViewChild('headerElement') private headerElement: ElementRef;
  @ViewChild('sidebarLeftToggleElement') private sidebarLeftToggleElement: ElementRef;
  @ViewChild('sidebarRightToggleElement') private sidebarRightToggleElement: ElementRef;

  /**
   * @method constructor
   * @param layoutStore [description]
   * @param ngZone      [description]
   * @param renderer2   [description]
   * @param elementRef   [description]
   * @param headerService   [description]
   */
  ipAddress
  constructor(
    public toaster: ToasterService,
    public date: DatePipe,
    private layoutStore: LayoutStore,
    private ngZone: NgZone,
    private renderer2: Renderer2,
    private elementRef: ElementRef,
    private headerService: HeaderService,
    public auth: AuthService,
    private expenseHttpService: ExpenseHttpService
    
  ) {
   
      this.NoOfattemps=0;
      this.isusercheckedin();
      this.NoOfattemps=this.auth.getNoOfAttepmts();
      //  this.ischeckedin='no';
      this.userid= this.auth.getUserId();
      this.checkintime=this.auth.getCheckinTime();
      this.diffrencetime();
      // debugger
  }

  /**
   * @method ngAfterViewInit
   */
  NoOfattemps: any;
  ischeckedin:any;
  userid:any;
  checkintime:any;
  checkintype:any;
  timeLeft: number = 1;
  mint: number = 0;
  hour: number = 0;
  interval;
  datecheckintime:any;
  currenttime:any;


isusercheckedin()
{
 let status= this.auth.getCheckintype();

 if(status=='0')
 {
   this.ischeckedin='no';
 }
 if(status=='1')
 {
  this.ischeckedin='yes';
 }
  if(status=='2')
  {
    this.ischeckedin='no';
  }

}

checkout(){
  this.getLat
  this.getLong 
  this.pauseTimer();
  this.ischeckedin='no';
}
lat:any
lng :any

get getLat(){
  return  navigator.geolocation.getCurrentPosition((position: any) => {
        if (position) {
          this.lat = position.coords.latitude;
        }
      },
      (error: any) => console.log(error));
}
get getLong(){
  return  navigator.geolocation.getCurrentPosition((position: any) => {
      if (position) {
        this.lng =  position.coords.longitude;
      }
    },
    (error: any) => console.log(error));
}

checkin(){
  debugger
  this.getLat
  this.getLong

    let date= new Date();
    let shortdate= this.date.transform(date, 'yyyy-MM-dd HH:mm:ss');
    let lat:any =  this.getLat
    let long:any =  this.getLong

    const params= {
      dateon: shortdate,
      userid: this.auth.getUserId(),
      cmpcode: this.auth.getcompanyCode(),
      ipaddress: this.ipAddress?.ip,
      status: '1',
      lati: this.lat == null?0:this.lat,
      longi: this.lng == null?0:this.lng,
      browser:navigator?.userAgent==null?'None':navigator?.userAgent
    }
    try {
      this.auth.setcheckstatus(params).subscribe(response => {
      if(response.success==true){
        this.auth.setNoOfAttepmts(response.data.table[0].totalattempt);
        this.NoOfattemps= this.auth.getNoOfAttepmts();
        if(response.data.table[0].data==4){   
        let currentdate:any= new Date();
        let mints=   response.data.table[0].mintues * 60000;
        let actuallogged= currentdate-mints;
        this.checkintime= new Date(actuallogged);
        this.auth.setCheckininTime(this.checkintime);
        this.auth.setCheckin('1');
        this.ischeckedin='yes';
        this.diffrencetime();
        this.toaster.show('success', response.data.table[0].message);
        }
        if(response.data.table[0].data==5){
          this.auth.setCheckin('1');
          this.ischeckedin='yes';

          let lastdate:any = new Date(response.data.table[0].lastchkin);
          // this.lastcheckedin = lastdate;
          this.currenttime = new Date();
          let differencefrommints = this.currenttime - lastdate;
          let totalmint = Math.floor(differencefrommints / 60000);

          let mints = response.data.table[0].lastchkmin;
          let totalmintts = totalmint + mints;
          let milisecs = totalmintts * 60000;
          let actualdatobesaved = this.currenttime - milisecs;
          let convertstringtodate = new Date(actualdatobesaved);
          this.auth.setCheckininTime(convertstringtodate);
          this.auth.setCheckin('1');
          this.diffrencetime();
          this.toaster.show('warning', response.data.table[0].message)
        }   
      }
      }, error => {
        this.toaster.show('warning', "Please Try Again")
    }); 
    } catch (error) {
      this.toaster.show('warning', "Please Try Again")
    }
} 

pad(d) {
  return (d < 10) ? '0' + d.toString() : d.toString();
}

newdate:any;
   diffrencetime(){
    
     if(this.checkintime=='')
     {
       this.datecheckintime= new Date();
     }
     else{
     this.datecheckintime= new Date(this.checkintime);
     }
     this.currenttime= new Date();

     let oldtime=this.currenttime-1260000;
   
      this.newdate=new Date(oldtime);
   let different= (this.currenttime-this.datecheckintime);
   let diffDays = Math.floor(different / 86400000); // days
   let diffHrs = Math.floor((different % 86400000) / 3600000); // hours
   let diffMins = Math.floor((different % 3.6e6) / 6e4)//Math.round(((different % 86400000) % 3600000) / 60000); // minutes 
   let diffMinsx = Math.floor((different % 6e4) / 1000);
  // let diffMinss = Math.round((((different % 86400000) % 3600000 ) % 3600000)/ 60000); // minutes 

   this.hour= this.pad(diffHrs);

   this.mint= this.pad(diffMins);
   this.timeLeft= diffMinsx;

   if(this.ischeckedin=='yes')
{
  this.startTimer();
}

  }

   
  startTimer() {
    clearInterval(this.interval);
    this.interval = setInterval(() => {
      if (this.timeLeft >= 59) {
        this.mint++;
        this.mint= this.pad(this.mint);
        if (this.mint == 60) {
          this.hour++;
          this.hour= this.pad(this.hour);
          this.mint = 0;
        }
        this.timeLeft = 0;
      } else {
        this.timeLeft++;
      }
      this.timeLeft= this.pad(this.timeLeft)
     
    }, 1000);

    

 
  }

 

  pauseTimer() {
  this.getLat
  this.getLong 
  clearInterval(this.interval);
  let date= new Date();
  let shortdate=    this.date.transform(date, 'yyyy-MM-dd HH:mm:ss');
  const params={
    dateon:shortdate,
    userid:this.auth.getUserId(),
    cmpcode:this.auth.getcompanyCode(),
    status:'2',
    header:'static',
    ipaddress: this.ipAddress?.ip,
    lati: this.lat == null?0:this.lat,
    longi: this.lng == null?0:this.lng,
    browser:navigator?.userAgent==null?'None':navigator?.userAgent
  }
try {
  this.auth.setcheckstatus(params).subscribe(response => {
    this.auth.setNoOfAttepmts(response.table);
    if(response.success==true)
    {
      this.auth.setNoOfAttepmts(response.data.table[0].totalattempt);
      this.NoOfattemps= this.auth.getNoOfAttepmts();
  
      if(response.data.table[0].data==5)
      {
        //not success
        this.toaster.show("warning", response.data.table[0].message);
        this.auth.setCheckin('2');
        this.auth.setCheckininTime('');
      }
      if(response.data.table[0].data==4)
      {
      this.hour=0;
      this.mint=0;
      this.timeLeft=0;
      this.auth.setCheckin('2');
      this.auth.setCheckininTime('');
      this.toaster.show("success", response.data.table[0].message);
      }
      
    }
  
  }, error => {
    this.toaster.show('warning', "Please Try Again")
});
} catch (error) {
  this.toaster.show('warning', "Please Try Again")
}

  
  }



  ngAfterViewInit() {
    this.isusercheckedin();
    this.headerService.elementRef = this.headerElement;

    if (this.sidebarLeftToggleElement) {
      this.subscriptions.push(this.layoutStore.isSidebarLeftCollapsed.subscribe((value: boolean) => {
        this.isSidebarLeftCollapsed = value;
      }));
      this.ngZone.runOutsideAngular(() => {
        this.listeners.push(this.renderer2.listen(this.sidebarLeftToggleElement.nativeElement, 'click', (event: Event) => {
          event.preventDefault();
          this.layoutStore.sidebarLeftCollapsed(!this.isSidebarLeftCollapsed);
        }));
      });
    }
    if (this.sidebarRightToggleElement) {
      this.subscriptions.push(this.layoutStore.isSidebarRightCollapsed.subscribe((value: boolean) => {
        this.isSidebarRightCollapsed = value;
      }));
      this.ngZone.runOutsideAngular(() => {
        this.listeners.push(this.renderer2.listen(this.sidebarRightToggleElement.nativeElement, 'click', (event: Event) => {
          event.preventDefault();
          this.layoutStore.sidebarRightCollapsed(!this.isSidebarRightCollapsed);
        }));
      });
    }
    this.expenseHttpService.getIPAddress().then(async res => {
      debugger
      this.ipAddress = await res;
    })
  }

  /**
   * @method ngOnDestroy
   */
  ngOnDestroy() {
    this.listeners = removeListeners(this.listeners);
    this.subscriptions = removeSubscriptions(this.subscriptions);
  }

  settoggle()
  {
    
    let NewsidebarToggle: HTMLElement = document.getElementsByClassName("sidebar-mini")[0] as HTMLElement;
    if(NewsidebarToggle.className == "skin-blue sidebar-mini sidebar-collapse"){
      NewsidebarToggle.className = "skin-blue sidebar-mini";
      return
    }
     if(localStorage.getItem("istoggle") != null)
      {
          if(localStorage.getItem("istoggle") === "1")
          {
            localStorage.setItem("istoggle", "0");
          }
          else
          {
            localStorage.setItem("istoggle", "1");
          }
      }
      else
      {
        localStorage.setItem("istoggle", "1");
      }
    }
}
