import { DatePipe } from "@angular/common";
import { map } from "rxjs/operators";
import { UserService } from "src/app/user/user.service";
import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { LeaveService } from "../leave.service";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import { AuthService } from "src/app/services/auth.service";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { CommonService } from "src/app/common.service";
import { Permission } from "src/app/permission-keyword";
import * as _ from "lodash";
import { ToasterService } from '../../expense/toaster/toaster.service';
import { HolidaysService } from "./holidays.service";
import { GridComponent,Column, ExcelExportProperties } from "@syncfusion/ej2-angular-grids";
import { ExpenseHttpService } from "src/app/services/expense/expense-http.service";
import { UploaderComponent } from "@syncfusion/ej2-angular-inputs";


class HolidayCalendarDates {
  HolidayId: number;
  HolidayDate: Date;
  DayType: string;
}

@Component({
  selector: "app-holidays",
  templateUrl: "./holidays.component.html",
  styleUrls: ["./holidays.component.css"],
})
export class HolidaysComponent implements OnInit {
  myRequestsListLength: any;
  myRequestsPage = 1;
  myRequestsPageSize = 10;
  holidays = [];
  locations: any;
  selectedLocation: any;
  currentUser: any;
  holidayForm: FormGroup;
  searchString;
  collectionSize = 0;
  startDate: any;
  endDate: any;
  DateFormat:any
  showSuccess: boolean;
  showMessage: any;
  submitted: any;
  confirmBox: boolean;
  CheckJson: any;
  HolidayTypesVal = [];
  HolidayCalendarDatesList: HolidayCalendarDates[];

  holidayList: any;
  tempholidayList: any;
  userLoader: Boolean = false;
  filterHoliday = "";
  page: 1;
  pageSize: 4;

  gapi: any;
  auth2: any;
  Name: any;
  show: any;
  EditPermission: boolean;
  DeletePermission: boolean;
  ViewPermission: boolean;
  EditAllPermission: boolean;
  AddPermission: boolean;
  flterShowData = false;
  CurrentUser:any
  UserId: any;
  CmpCode:any;
  public filter: Object;
  public filterSettings: Object;
  public selectionSettings: Object;
  public height: string = '240px';
  public fields: Object = { text: 'text', value: 'value' };
  public item: number[] = [1, 2, 3, 4, 5];
  @ViewChild('overviewgrid')
  public gridInstance: GridComponent;
  //GoogleAuth
  GoogleAuth: any; // Google Auth object.
  filterPeriod: number;
  navigationText: any;
  filterStartDate: Date;
  filterEndDate: Date;
  minDate: any;
  maxDate: any;
  filterLocationId: any;
  LocationList: any[];
  ddlLocationList: any[];
  ActiveLocations = [];
  monthNamesList = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Validation Message
  requiredHolidayName: String = null;
  requiredHolidayDate: String = null;

  st: number;
  LocalDataPermission: any;

  @ViewChild("loginRef") loginElement: ElementRef;
  // Http: any;

  constructor(
    private _holidaysServ: HolidaysService,
    private _authSer: AuthService,
    private _commonSer: CommonService,
    private _userService: UserService,
    private auth: AuthService,
    private commonService: CommonService,
    private datePipe: DatePipe,
    private Http: ExpenseHttpService,
    private toaster: ToasterService

  ) {
    debugger
    this.CmpCode = this.auth.getcompanyCode();
    this.auth.IsPermissions('Change Requests');
    this.LocalDataPermission = this.auth.LocalDataPermission;
    if (this.LocalDataPermission === '' || this.LocalDataPermission === null || this.LocalDataPermission === undefined) {
      this.LocalDataPermission[0].addPermission = 0;
      this.LocalDataPermission[0].editPermission = 0;
      this.LocalDataPermission[0].viewpermission = 0;
      this.LocalDataPermission[0].deletePermission = 0;
    }

    // this.auth.currentUser.subscribe(x => this.CurrentUser = x);
    this.ViewPermission = this.LocalDataPermission[0]?.viewpermission;
    this.AddPermission = this.LocalDataPermission[0]?.addPermission;
    this.EditPermission = this.LocalDataPermission[0]?.editPermission;
    this.DeletePermission = this.LocalDataPermission[0]?.deletePermission;

  }
  viwerrole:any;
  roleIds:any;
  btndisableimport: boolean = true;
  btndisableexport: boolean = true;
  currentDate = new Date();
  ngOnInit() {
    this.roleIds = this.auth.getRoleId();
    this.viwerrole=this.roleIds
    if (this.roleIds == "1") {

    }
    if (this.roleIds == "2") {
      this.btndisableimport = false;
    }
    if (this.roleIds == "3" || this.viwerrole=="11") {
      this.btndisableimport = false;
      this.btndisableexport = false;
    }
    this.filterSettings = { type: "Menu" };
    this.filter = { type: "CheckBox" };
    // this.stTime = performance.now();
    this.selectionSettings = { persistSelection: true, type: "Multiple", checkboxOnly: true };

    this.CmpCode = this.auth.getcompanyCode();
    // this.googleSDK();
    this.DateFormat= this._authSer.getdateFormat();
    // Set default filter
    this.filterPeriod = 8;
    let currentDate = new Date();
    this.filterStartDate = new Date(currentDate.getFullYear() + "-01-01 00:00:00");
    this.filterEndDate = new Date(currentDate.getFullYear() + "-12-31 00:00:00");

    this.holidayForm = new FormGroup({
      holidayName: new FormControl("", Validators.required),
      holidayDate: new FormControl("", Validators.required),
      isRestrictedHoliday: new FormControl(""),
      locations: new FormControl(""),
      locationsParm: new FormControl(""),
      Description: new FormControl(""),
      DaysReminder: new FormControl(""),
      notifyEmployees: new FormControl(""),
      reprocessLeave: new FormControl(""),
      createdBy: new FormControl(""),
      CmpCode: new FormControl(""),
      Id: new FormControl(""),
      holidayType: new FormControl(""),
      endDate: new FormControl(""),
      holidayDatesList: new FormControl(""),
    });

    this.auth.currentUser.subscribe((x) => {
      this.currentUser = x;
      this.UserId = this.currentUser.userId;
    });
    this.getAllLocations();
  }

  ImportFileExcel: any;
  ImportFileExcelName: any;
  onFileChangeImg(event){
    let IsCheckSize = event.target.files[0].size;
    if(IsCheckSize > 2097152){
      this.toaster.show("success", 'Please Select a file smaller than 2MB');
    }else {
      this.ImportFileExcel = event.target.files[0];
      this.ImportFileExcelName = event.target.files[0].name;
    };
  }
  ImportFileData: any;
  importError = false;
  ImportFile() {
    try {
      this.importError = false;
      this.ImportFileData = undefined;
   
    } catch (ex) {

    }
  }
  handleFileInput(event) {
    let IsCheckSize = event.target.files[0].size;
    if(IsCheckSize > 2097152){
      this.toaster.show("success", 'Please Select a file smaller than 2MB');
    }else {
      this.ImportFileData = event.target.files[0];
      $('#importFile').val('');
    };
  }
  UploadFileBtn() {
    $('#importFile').click();
  }
  errorData = []  ;
  @ViewChild('file') public uploadObj: UploaderComponent;
  SaveImportExcelData() {
    if (this.ImportFileExcel !== undefined) {
      let fd = new FormData();
      fd.append("file", this.ImportFileExcel);
      fd.append("Cmpcode", this.CmpCode);
      fd.append("userid", this.UserId);
      fd.append("excelFileName", "organizationholidays");
      this.Http.ImportExcelFiles(fd).then((res) => {
        if (res.success === true) {
          this.toaster.show("success", "Records Imported Successfully");
          this.errorData = []
          this.uploadObj.clearAll();
          this.ngOnInit();
          this.commonService.CloseModalHandler('exampleModal3'); 
        } else {
          this.errorData = res?.data?.split("*");
          this.errorData[0] = "Please Resolve the Below Errors"
          this.ImportFileExcel = undefined;
          this.uploadObj.clearAll();
          this.toaster.show("warning", "Incorrect Records");
          this.ngOnInit();
        }
      },(error) => {
        this.toaster.show("warning", "Uh oh, Something went wrong!");
      });
    } else {
      this.toaster.show("error", "Please Select a file");
    }
  }
  

  public pageSettings = { pageCount: 3 };
  @ViewChild('overviewgrid') public Grid: GridComponent;
  load() {
    const rowHeight: number = this.Grid.getRowHeight();  // height of the each row
    const gridHeight: any = this.Grid.height;  // grid height
    const pageSize: number = this.Grid.pageSettings.pageSize;   // initial page size
    const pageResize: any = (gridHeight - (pageSize * rowHeight)) / rowHeight; // new page size is obtained here
    this.Grid.pageSettings.pageSize = pageSize + Math.round(pageResize);
  }

  logText(i: number) {
    this.st = i;
  }

  //DateFormat='MM/dd/yyyy';
 
  
  
  dateformat(datas:string){
    return this._authSer.Dateformat(datas);
    // if (this.DateFormat=='1')
    // {
    //   return this.datePipe.transform(datas, 'MM/dd/yyyy')
   
    // }
    // if(this.DateFormat=='2')
    // {
    //   return this.datePipe.transform(datas, 'dd/MM/yyyy')
    // }
    // if(this.DateFormat== '3')
    // {
    //   return this.datePipe.transform(datas, 'yyyy/MM/dd')
    // }
  }

  prepareLoginButton() {
    this.auth2.attachClickHandler(
      this.loginElement.nativeElement,
      {},
      (googleUser) => {
        let profile = googleUser.getBasicProfile();
        console.log(googleUser);
        let a = googleUser.getAuthResponse().access_token;
        let params = {
          UserId: this.UserId,
          AccessToken: googleUser.getAuthResponse().access_token,
          SyncType: this.st,
        };
        this._commonSer
          .CRG_PostAPI("Admin/SyncWithGoogleCalendar", params)
          .subscribe((res) => {});
      },
      (error) => {
        alert(JSON.stringify(error, undefined, 2));
      }
    );
  }

  googleSDK() {
    window["googleSDKLoaded"] = () => {
      window["gapi"].load("auth2", () => {
        this.auth2 = window["gapi"].auth2.init({
          client_id:
            "467170952999-rae30kmckqs7a4j4u7mk1dduu6p9a97b.apps.googleusercontent.com",
          cookie_policy: "single_host_origin",
          scope:
            "https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events.readonly https://www.googleapis.com/auth/calendar.events",
        });

        this.prepareLoginButton();
      });
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://apis.google.com/js/platform.js?onload=googleSDKLoaded";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "google-jssdk");
  }

  getAllLocations() {
    this._userService.getAllLocationsForSelect(this.CmpCode).subscribe((res: any) => {
      if (res.success) {
        this.locations = [];
        this.locations = _.map(res.data.table, (tableObj) => {
          return { id: tableObj.locationId, text: tableObj.locationName };
        });
        this.getAllHolidays();
      }
    });
    this.getAllLocationList();
  }
  selectedHoliday = [];

  holidayTypes = ["Full Day", "First Half", "Second Half"];

  getAllHolidays() {
    let par = {
      PageNo: 1,
      NoOfRecords: 10000,
      SortType: "desc", //'asc',
      FieldName: "CreatedDate",
      CmpCode:this.CmpCode
    };
    this.commonService
      .CRG_PostAPI("Holiday/GetAllHoliday", par)
      .subscribe((res) => {
        const response = JSON.parse(JSON.parse(res["_body"]).data);
        for (var j = 0; j < response.length; j++) {
          if (response[j].LocationIds) {
            var locationId = response[j].LocationIds.split(",");
            let name = [];
            for (var i = 0; i < locationId.length; i++) {
              if (this.locations.some((c) => c.id == locationId[i])) {
                name.push(_.find(this.locations, (e) => e.id == locationId[i]));
              }
            }
            response[j].LocationName = name.map((c) => c.text).join();
          }
        }
        this.holidays = response;
        this.myRequestsListLength = this.holidays.length;
      });
  }

  /************************** SAVE Holiday *********************************/
  onHolidayNameChanged() {
    this.requiredHolidayName = null;
    if (this.holidayForm.controls.holidayName.value == null || 
      this.holidayForm.controls.holidayName.value == undefined ||
      this.holidayForm.controls.holidayName.value == "") {
        this.requiredHolidayName = "Name is required.";
    }
  }

  saveHoliday() {
    debugger;

    this.requiredHolidayName = null;
    this.requiredHolidayDate = null;

    console.clear();
    console.log(this.holidayForm);

    let isFormValid = true;
    if (this.holidayForm.controls.holidayName.value == null ||
      this.holidayForm.controls.holidayName.value == undefined ||
      this.holidayForm.controls.holidayName.value == "") {
        this.requiredHolidayName = "Name is required.";
        isFormValid = false;
    }

    if (this.holidayForm.controls.holidayDate.value == null ||
      this.holidayForm.controls.holidayDate.value == undefined ||
      this.holidayForm.controls.holidayDate.value == "") {
        this.requiredHolidayDate = "Date is required.";
        isFormValid = false;
    }

    if (this.holidayForm.controls.endDate.value == null ||
      this.holidayForm.controls.endDate.value == undefined ||
      this.holidayForm.controls.endDate.value == "") {
        this.requiredHolidayDate = "Date is required.";
        isFormValid = false;
    }

    //if (this.holidayForm.status === "VALID") {
    if (isFormValid === true) {
      if (!this.isEdit) {
        this.holidayForm.get("createdBy").setValue(this.currentUser.userId);
        // this.holidayForm.controls["CmpCode"].patchValue(this.CmpCode)
      }

      if (this.holidayForm.value.locationsParm != null && this.holidayForm.value.locationsParm != undefined) {
        this.holidayForm.value.locations = Array.prototype.map
          .call(this.holidayForm.value.locationsParm, (s) => s.id)
          .toString();
      }

      this.holidayForm.value.holidayType = this.HolidayCalendarDatesList[0].DayType;
      let vm = this;

      this.HolidayCalendarDatesList.forEach(function (part, index) {
        this[index].HolidayDate = vm
          .getFormattedDate(this[index].HolidayDate)
          .toDateString();
      }, this.HolidayCalendarDatesList); // use arr as this

      this.holidayForm.value.holidayDatesList = this.HolidayCalendarDatesList;

      let parms = {
        HolidayId:
          this.holidayForm.value.Id == null ? 0 : this.holidayForm.value.Id,
        HolidayTitle: this.holidayForm.value.holidayName,
        HolidayStartDate: this.getFormattedDate(
          this.holidayForm.value.holidayDate
        ).toDateString(),
        HolidayEndDate: this.getFormattedDate(
          this.holidayForm.value.endDate
        ).toDateString(),
        IsRestricted: this.holidayForm.value.isRestrictedHoliday,
        Locations: this.holidayForm.value.locations,
        DateJson: JSON.stringify(this.holidayForm.value.holidayDatesList),
        Description: this.holidayForm.value.Description,
        DaysReminder: this.holidayForm.value.DaysReminder,
        NotifyEmployees: this.holidayForm.value.notifyEmployees,
        ReprocessLeave: this.holidayForm.value.reprocessLeave,
        createdBy: this.holidayForm.value.createdBy,
        CmpCode: this.CmpCode,
        CreatedDate: this.holidayForm.value.CreatedDate,
        UpdatedDate: this.holidayForm.value.UpdatedDate,
      };

      this._holidaysServ.saveHoliday(parms).subscribe(
        (response) => {
          this.submitted = false;
          if (response.success == true) {
            this._commonSer.CloseModalHandler("MessageModel");
            this.holidayForm.reset();
            this.showSuccess = true;
            this.showMessage = response.data;
        //    this._commonSer.OpenModalHandler("MessageModel");
           this.commonService.CloseModalHandler("addHoliday");
            this.toaster.show("success",  this.showMessage);
          //  this.closeModel('addmaster');
          } else {
            this.showSuccess = false;
            this.showMessage = response.data;
            this._commonSer.OpenModalHandler("MessageModel");
          }

          this.getAllHolidays();
        },
        (error) => {
          this.showSuccess = false;
          this.showWarning = false;
          this.showMessage = "Uh oh, Something went wrong !";
          this._commonSer.OpenModalHandler("MessageModel");
        }
      );
    }
  }

  /************************** Edit Holiday *********************************/

  isEdit = false;
  editHoliday(holiday) {
    this.holidayForm.reset();
    this.holidayForm.enable();
    var vm = this;
    this.holidayForm.patchValue({
      Id: holiday.HolidayId,
      holidayName: holiday.HolidayTitle,
      holidayDate: vm.getFormattedDate(holiday.HolidayStartDate),
      endDate: vm.getFormattedDate(holiday.HolidayEndDate),
      isRestrictedHoliday: holiday.IsRestricted,
      locations: holiday.LocationIds,
      Description: holiday.Description,
      DaysReminder: holiday.DaysReminder,
      notifyEmployees: holiday.NotifyEmployee,
      reprocessLeave: holiday.IsReprocess,
    });
    this.selectedLocation = [];
    if (holiday.LocationIds) {
      var val = holiday.LocationIds.split(",");
      for (var x = 0; x < val.length; x++) {
        this.selectedLocation.push(
          _.find(this.locations, (location) => location.id == val[x])
        );
      }

      this.selectedLocation = this.selectedLocation.filter(function (element) {
        return element !== undefined;
      });
      this.holidayForm.value.locationsParm = this.selectedLocation;
    }

    this.HolidayTypesVal = [];
    this.CheckJson = JSON.parse(holiday.DateJson);
    for (var i in this.CheckJson) {
      this.HolidayTypesVal.push(this.CheckJson[i].DayType);
    }

    let leaveStartDate1 = this.getFormattedDate(holiday.HolidayStartDate);
    let leaveEndDate1 = this.getFormattedDate(holiday.HolidayEndDate);

    if (leaveStartDate1 != null && leaveEndDate1 != null) {
      this.HolidayCalendarDatesList = [];
      let arrayDatesList1 = this.getDates(leaveStartDate1, leaveEndDate1);
      for (let i = 0; i < arrayDatesList1.length; i++) {
        let newData = new HolidayCalendarDates();
        newData.HolidayDate = arrayDatesList1[i];
        newData.DayType = this.HolidayTypesVal[i];
        this.HolidayCalendarDatesList.push(newData);
      }
    }

    this.commonService.OpenModalHandler("addHoliday");
    this.holidayForm.enable();

    this.getAllHolidays();
    this.isView = false;
    this.isEdit = true;

    //this.holidayForm.get('locationsParm').markAsDirty();
    //this.holidayForm.markAsDirty();
    //this.holidayForm.valid = true;
    
    setTimeout(() => {
      //this.holidayForm.markAsTouched();
      //this.holidayForm.markAsDirty();

      //this.holidayForm.updateValueAndValidity();

      // this.holidayForm.get('locationsParm').setErrors(null);
      // this.holidayForm.get('locationsParm').markAsTouched();
      // this.holidayForm.get('locationsParm').markAsDirty();
      //console.log(this.holidayForm);
    }, 300);
  }
  /************************** View Holiday *********************************/

  isView = false;
  viewHoliday(holiday) {
    this.holidayForm.patchValue({
      Id: holiday.HolidayId,
      holidayName: holiday.HolidayTitle,
      holidayDate: new Date(holiday.HolidayStartDate),
      endDate: new Date(holiday.HolidayEndDate),
      isRestrictedHoliday: holiday.IsRestricted,
      locations: holiday.LocationIds,
      Description: holiday.Description,
      DaysReminder: holiday.DaysReminder,
      notifyEmployees: holiday.NotifyEmployee,
      reprocessLeave: holiday.IsReprocess,
      createdBy: holiday.CreatedBy,
    });

    this.selectedLocation = [];
    if (holiday.LocationIds) {
      var val = holiday.LocationIds.split(",");
      for (var x = 0; x < val.length; x++) {
        this.selectedLocation.push(
          _.find(this.locations, (location) => location.id == val[x])
        );
      }

      this.selectedLocation = this.selectedLocation.filter(function (element) {
        return element !== undefined;
      });
    }

    this.HolidayTypesVal = [];
    this.CheckJson = JSON.parse(holiday.DateJson);
    for (var i in this.CheckJson) {
      this.HolidayTypesVal.push(this.CheckJson[i].DayType);
    }

    let leaveStartDate1 = this.getFormattedDate(holiday.HolidayStartDate);
    let leaveEndDate1 = this.getFormattedDate(holiday.HolidayEndDate); // new Date(this.endDate);

    if (leaveStartDate1 != null && leaveEndDate1 != null) {
      this.HolidayCalendarDatesList = [];
      let arrayDatesList1 = this.getDates(leaveStartDate1, leaveEndDate1);
      for (let i = 0; i < arrayDatesList1.length; i++) {
        let newData = new HolidayCalendarDates();
        newData.HolidayDate = arrayDatesList1[i];
        newData.DayType = this.HolidayTypesVal[i];
        this.HolidayCalendarDatesList.push(newData);
      }
    }

    this.commonService.OpenModalHandler("addHoliday");
    this.holidayForm.disable();
    this.isView = true;
  }

  /************************** New Holiday *********************************/

  newHoliday() {
    debugger;
    this.holidayForm.enable();
    this.holidayForm.reset();
    this.isEdit = false;
    this.isView = false;
    this.HolidayCalendarDatesList = [];
    this._commonSer.OpenModalHandler("addHoliday");
  }


  /************************** Delete Holiday *********************************/

  showWarning: Boolean = false;
  deleteId = 0;
  IsDelete: Boolean = false;
  deleteHoliday(holiday) {
    this.deleteId = holiday.HolidayId;
    this.showSuccess = false;
    this.showWarning = false;
    this.IsDelete = true;
    this.confirmBox = true;
    this.isView = false;
    this.showMessage = "Are you sure, you want to delete this record?";
    this._commonSer.OpenModalHandler("MessageModel");
  }

  ConfirmDeleteAction() {
    try {
      this._commonSer.CloseModalHandler("MessageModel");
      this._holidaysServ.deleteHoliday(this.deleteId).subscribe((res) => {
        this.getAllHolidays();
        this.showMessage =  res.message;
        this.IsDelete = false;
        this.deleteId = 0;
        this.confirmBox = false;
        this.showWarning = false;

        if (res.success == true) {
          //this.showSuccess = true;
          this.toaster.show("success", "Record Deleted Successfully");
        } else {
          this.showSuccess = false;
        }

        //this._commonSer.OpenModalHandler("MessageModel");
      });
    } catch (ex) {
      console.log(ex);
      this.getAllHolidays();
    }
  }

  getCSharpFormattedDate(val: any) {
    if (val === null || val === "") {
      return null;
    }
    const date = new Date(val);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return month + "-" + day + "-" + year;
  }

  setNavigationText() {
    let vm = this;
    vm.navigationText = "";

    let startDateString =
      vm.filterStartDate.getDate() +
      " " +
      vm.monthNamesList[vm.filterStartDate.getMonth()] +
      " " +
      vm.filterStartDate.getFullYear() +
      "";
    let endDateString =
      vm.filterEndDate.getDate() +
      " " +
      vm.monthNamesList[vm.filterEndDate.getMonth()] +
      " " +
      vm.filterEndDate.getFullYear() +
      "";

    vm.navigationText = startDateString + " - " + endDateString;
  }
  getAllLocationList() {
    let parms = {
      Condition: "",
      FieldName: "CreatedDate",
      NoOfRecords: 10000,
      PageNo: 1,
      SortType: "desc",
      CmpCode:this.CmpCode,
    };
    this._holidaysServ.getlocationlist(parms).subscribe(
      (res) => {
        this.LocationList = res["data"].table;
        this.ddlLocationList = this._commonSer.SetDDlDropdown(
          res["data"].table,
          "locationId",
          "locationName"
        );
      },
      (error) => {
        this.userLoader = false;
        console.log(error);
      }
    );
  }
  getAllFilterHolidays() {
    this.userLoader = true;
    let parms = {
      //Period: this.filterPeriod,
      StartDate: this.getFormattedDate(this.filterStartDate).toDateString(),
      EndDate: this.getFormattedDate(this.filterEndDate).toDateString(),
      LocationIds:
        this.filterLocationId !== undefined ? this.filterLocationId : null,
      //FilterLocation: this.filterLocationId !== undefined ? this.filterLocationId : null,
      // UserId: this.currentUserId
    };

    this.commonService
      .CRG_PostAPI("Holiday/GetAllHoliday", parms)
      .subscribe((res) => {
        const response = JSON.parse(JSON.parse(res["_body"]).data);
        for (var j = 0; j < response.length; j++) {
          if (response[j].LocationIds) {
            var locationId = response[j].LocationIds.split(",");
            let name = [];
            for (var i = 0; i < locationId.length; i++) {
              if (this.locations.some((c) => c.id == locationId[i])) {
                name.push(_.find(this.locations, (e) => e.id == locationId[i]));
              }
            }
            response[j].LocationName = name.map((c) => c.text).join();
          }
        }
        this.holidays = response;
        this.myRequestsListLength = this.holidays.length;
      });
  }

  filterShow() {
    // this.getDepartmentList();
    // this.getAllDesigationList();
    //this.getAllLocationList();
    this.flterShowData = true;
  }
  closeFilter() {
    this.flterShowData = false;
  }
  processFilters() {
    this.flterShowData = false;
    this.setNavigationText();
    this.getAllFilterHolidays();
    //this.getUserAvailabilityReport();
  }
  ddlSelection(value: any, placeHolder: any) {
    try {
      //  
      switch (placeHolder) {
        case "Location":
          this.ActiveLocations = [];
          this.ActiveLocations.push(value);
          this.filterLocationId = value.id;
          break;
      }
    } catch (ex) {
      console.log(ex);
    }
  }
  removeFilterDropdownValue(value, _type) {
    try {
      //  
      switch (_type) {
        case "Location":
          this.ActiveLocations = [];
          this.filterLocationId = null;
          break;
      }
    } catch (ex) {
      console.log(ex);
    }
  }

  resetFilters() {
    this.ActiveLocations = [];
    this.filterLocationId = null;

    this.filterPeriod = 6;
    let currentDate = new Date();
    this.filterStartDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    this.filterEndDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );

    this.setNavigationText();
    this.getAllHolidays();
  }
  onChangeFilterPeriod(e) {
    let _period = e.srcElement.value;
    let currentDate = new Date();

    if (_period == 1) {
      // Yesterday
      currentDate.setDate(currentDate.getDate() - 1);
      this.filterStartDate = currentDate;
      this.filterEndDate = currentDate;
    } else if (_period == 2) {
      // Today
      this.filterStartDate = currentDate;
      this.filterEndDate = currentDate;
    } else if (_period == 3) {
      // Last Week
      let first = currentDate.getDate() - currentDate.getDay() - 7;
      let last = first + 6;

      this.filterStartDate = new Date(currentDate.setDate(first));
      this.filterEndDate = new Date(currentDate.setDate(last));
    } else if (_period == 4) {
      // This Week
      let first = currentDate.getDate() - currentDate.getDay();
      let last = first + 6;

      this.filterStartDate = new Date(currentDate.setDate(first));
      this.filterEndDate = new Date(currentDate.setDate(last));
    } else if (_period == 5) {
      // Last Month
      currentDate.setMonth(currentDate.getMonth() - 1);
      this.filterStartDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );
      this.filterEndDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      );
    } else if (_period == 6) {
      // This Month
      this.filterStartDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );
      this.filterEndDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      );
    } else if (_period == 7) {
      // Last Year
      this.filterStartDate = new Date(
        currentDate.getFullYear() - 1,
        currentDate.getMonth() - currentDate.getMonth(),
        1
      );
      this.filterEndDate = new Date(
        currentDate.getFullYear() - 1,
        currentDate.getMonth() - currentDate.getMonth() + 12,
        0
      );
    } else if (_period == 8) {
      // This Year
      this.filterStartDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - currentDate.getMonth(),
        1
      );
      this.filterEndDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - currentDate.getMonth() + 12,
        0
      );
    } else if (_period == 9) {
      // Custom
      this.filterStartDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );
      this.filterEndDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      );
    }

    this.setNavigationText();
  }

  Search() {
    //this.holidayList = this.tempholidayList;
    if (this.filterHoliday) {
      let filteredList = Object.assign([], this.holidays).filter(
        (hil) =>
          hil.HolidayTitle.toLowerCase().indexOf(
            this.filterHoliday.toLowerCase()
          ) > -1
      );
      this.holidays = filteredList;
    } else this.getAllHolidays();
  }

  endDateInvalid = false;
  endDateInvalidMessage = "";
  validateEndDate() {
    let res = this.datePipe.transform(
      this.holidayForm.value.holidayDate,
      "dd-MM-yyyy"
    );

    let endDate = this.datePipe.transform(
      this.holidayForm.value.endDate,
      "dd-MM-yyyy"
    );

    if (endDate < res) {
      // this.holidayForm.controls['endDate'].hasError()

      this.endDateInvalid = true;
      this.endDateInvalidMessage = "End date should be valid.";
      return false;
    } else {
      this.endDateInvalid = false;
      return true;
    }
  }

  datepickerStartDate(event) {
    debugger
    this.startDate = event.value;
    this.calculateDays();
    this.requiredHolidayDate = null;
    if (this.startDate == null ||
      this.startDate == undefined ||
      this.holidayForm.controls.holidayDate.value == "") {
        this.requiredHolidayDate = "Date is required.";
    }
  }
  NewHolidayCalendarDatesList = [ {text: "Full Day", id: 1},
                                  {text: "1st Half", id: 2},
                                  {text: "2nd Half", id: 3} ]

                
   NewHolidayCalendarDatesListfield = {value: "id", text: "text"}
  datepickerEndDate(event) {
    debugger
    this.endDate = event.value;
    this.calculateDays();
    this.requiredHolidayDate = null;
    if (this.endDate == null ||
      this.endDate == undefined ||
      this.endDate == "") {
        this.requiredHolidayDate = "Date is required.";
    }
  }
  getFormattedDate(val: any) {
    if (val === null) {
      return null;
    }
    const date = new Date(val);
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    return new Date(year, month, day);
  }
  calculateDays() {
    let vw = this;
    let leaveStartDate = this.getFormattedDate(this.startDate);
    let leaveEndDate = this.getFormattedDate(this.endDate); // new Date(this.endDate);

    if (leaveStartDate != null && leaveEndDate != null) {
      this.HolidayCalendarDatesList = [];
      let arrayDatesList = this.getDates(leaveStartDate, leaveEndDate);
      for (let i = 0; i < arrayDatesList.length; i++) {
        let newData = new HolidayCalendarDates();
        newData.HolidayDate = arrayDatesList[i];
        newData.DayType = "1";
        vw.HolidayCalendarDatesList.push(newData);
      }
    }
  }

  getDates(startDate, stopDate) {
    let dateArray = new Array();
    let currentDate = startDate;
    while (currentDate <= stopDate) {
      dateArray.push(this.getFormattedDate(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dateArray;
  }

  setDayTypeValue(e, i) {
    let _value = e.value;
    this.HolidayCalendarDatesList[i].DayType = _value;
    this.holidayForm.get("holidayType").setValue(_value);
  }

  resetForm() {
    const target = "#exampleModalfilter";
    $(target).hide("slow");
    
    this.filterEndDate= null;
    this.filterStartDate= null;
    this.filterPeriod=null;
  }
  export(): void {
    debugger
    const excelExportProperties: ExcelExportProperties = {
      fileName: 'Holiday_List.xlsx'
  };
    (this.gridInstance.columns[1] as Column).visible = false;
    this.gridInstance.excelExport(excelExportProperties);
  }
  printList(printData) {
    let printContents = document.getElementById(printData).innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    location.reload();
  }
  exportTableToCSV(filename) {
    var csv = [];
    var rows = document.getElementById("tableData").querySelectorAll("table tr");
    for (var i = 0; i < rows.length; i++) {
      var row = [], cols = rows[i].querySelectorAll("td, th");
      for (var j = 0; j < cols.length; j++)
        row.push(cols[j].textContent);
      csv.push(row.join(","));
    }

    // Download CSV file
    this.downloadCSV(csv.join("\n"), filename);
  }
  downloadCSV(csv, filename) {
    var csvFile;
    var downloadLink;

    // CSV file
    csvFile = new Blob([csv], { type: "text/csv" });

    // Download link
    downloadLink = document.createElement("a");

    // File name
    downloadLink.download = filename;

    // Create a link to the file
    downloadLink.href = window.URL.createObjectURL(csvFile);

    // Hide download link
    downloadLink.style.display = "none";

    // Add the link to DOM
    document.body.appendChild(downloadLink);

    // Click download link
    downloadLink.click();
  }
}