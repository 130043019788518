// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,

  //siteUrl: 'https://leaveapi.geterpnow.com/',
  //apiUrl: 'https://leaveapi.geterpnow.com/api',

  // siteUrl: 'http://103.10.234.199/api/',
  // apiUrl: 'http://103.10.234.199/api/api/',

  
  siteUrl: 'https://app.pm-studio.com/api/',
  apiUrl: 'https://app.pm-studio.com/api/api/',
  AnalyticKey: '9877769078@kunalMalhotra',
  // Success and error alert in duplicate case
  reOpenMsg: 'Project successfully re-opened.',
  SuccessAlert: 'alert alert-success',
  ErrorAlert: 'alert alert-danger',
  LoginEmailError: 'Invalid User Name',
  LoingPasswordError: 'Password is incorrect',

  //Dynamic Add validation messages
  DynamicCountry: "Please enter country",
  DynamicState: "Please select all required field  (country)",
  DynamicCity: "Please select all required field  (country, State)",
  DynamicZipCode: "Please select all required field  (country, State, ZipCode )",

  //Validation Message
  requiredMsg: 'Required',
  NumberMsg: 'Only numbers are allowed',
  MobileMsg: "Invalid mobile number",
  TelephoneMsg: "Invalid telephone number",
  EmailMsg: "Invalid Email",
  countryCode: "Invalid Code",
  ConfirmMsg: "Do you really want to add this value ?",
  websiteMsg: "Invalid URL",
  TimeOutInterval: 4000,
  ImportInterval: 15000,
  alertTimeoutInertval: 7000,
  NotDeleteHierarchyTimeout: 10000,


  //Success, udpate and delete message
  deleteMsg: 'Are you sure, you want to delete this record?',
  assignRoleAllPermissionsMsg: 'Do you really want to assign all the permissions to this role?',
  unRoleAssignAllPermissionsMsg: 'Do you really want to unassign all the permissions from this role?',
  successMsg: 'Record Added Successfully',
  updateMsg: 'Record Updated Successfully',
  deleteSuccessMsg: 'Record Deleted Successfully.',
  errorOccurredMsg: 'An error has occurred',

  //Model Messages
  AddModel_msg: 'Add',
  EdtiModel_msg: 'Edit',
  ViewModel_msg: 'View',

  //Button Text
  AddButton_text: "Save",
  UpdateButton_text: "Update",

  //minimum one item add
  MinOneAddress: 'Minimum one address is required',
  RequiredLocationHierarchy: 'Location hierarchy is required',
  PreSaveAddress: 'Please save edited address first',
  DuplicateRadio: 'To change the primary address, you need to edit the previous one',
  MinOnePrimary: 'Minimum one primary address is required',
  AlreadyPrimaryMsg: 'Do you really want to change the primary address ?',
  RevertChanges: 'Do you want to revert the changes ?',
  NotDeletePrimaryAddress: 'This is a primary record. Please mark another address as primary to delete this record.',
  Savepreviousvalue: 'You need to save the edited address before editing a new address. \n  Do you want to save the edited address?'

};

