import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Observable } from "rxjs";
import { AuthService } from './auth.service';
@Injectable({
  providedIn: 'root'
})
export class JwtInterceptorService implements HttpInterceptor {

  constructor(private authService: AuthService) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    let currentUser = {
      token: this.authService.getToken()
    }
    if (currentUser.token) {
      request = request.clone({
        setHeaders: {
          Authorization: `bearer ${currentUser.token}`
        }
      });
    }
    return next.handle(request);
  }
}
