import { Component, OnInit, ViewChildren, QueryList, ViewChild } from '@angular/core';
import { LeaveService } from "../../leave.service";
import { CommonService } from "src/app/common.service";
import { AuthService } from "src/app/services/auth.service";
import { BsDatepickerConfig } from "ngx-bootstrap";
import { environment } from "src/app/environment";
import { DepartmentService } from "src/app/+layout/department/department.service";
import { DesignationService } from "src/app/+layout/designation/designation.service";
import { LocationService } from "src/app/+layout/location/location.service";
import { SelectComponent } from "ng2-select/ng2-select";
import * as Highcharts from 'highcharts';
import { GridComponent,ExcelExportProperties, Column } from "@syncfusion/ej2-angular-grids";
declare let html2canvas: any;
//import jsPDF from 'jspdf';

@Component({
  selector: 'app-daily-report',
  templateUrl: './daily-report.component.html',
  styleUrls: ['./daily-report.component.css']
})
export class DailyReportComponent implements OnInit {
 CmpCode:any;
  datePickerConfiq: Partial<BsDatepickerConfig>;

  userLoader: boolean;
  currentUserId: any;

  navigationText: any;

  flterShowData = false;

  filterDate: Date;

  // For User Dropdownlist
  filterLeaveTypeId: any;
  LeaveTypeList: any[];
  ddlLeaveTypeList: any[];
  ActiveLeaveTypes = [];

  filterDepartmentId: any;
  DepartmentList: any[];
  ddlDepartmentList: any[];
  ActiveDepartments = [];

  filterDesignationId: any;
  DesignationList: any[];
  ddlDesignationList: any[];
  ActiveDesignations = [];

  filterLocationId: any;
  LocationList: any[];
  ddlLocationList: any[];
  ActiveLocations = [];

  catogries = [];
  reportList: any[];
  showchart = false;
  showlist = true;
  chartData = [];
  public filter: Object;
  public filterSettings: Object;
  public selectionSettings: Object;
  public height: string = '240px';
  public fields: Object = { text: 'text', value: 'value' };
  public item: number[] = [1, 2, 3, 4, 5];

  highcharts = Highcharts;
  chartOptions = {
    chart : {
       plotBorderWidth: null,
       plotShadow: false
    },
    title : {
       text: 'Daily Report'
    },
    tooltip : {
       pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
    },
    plotOptions : {
       pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
             enabled: true,
             format: '<b>{point.name}%</b>: {point.percentage:.1f} %',
          }
       }
    },
    series : [{
       type: 'pie',
       name: 'Daily Report',
       data: [[]]
    }]
  };

  IsShowMenu: Boolean = true;
  capturedImage: any;

  monthNamesList = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  filterUserStatus: number = null;

  @ViewChildren(SelectComponent) selectElements: QueryList<any>;
  recieptIMg: string;

  constructor(private _leaveService: LeaveService,
    private _commonSer: CommonService,
    private _authSer: AuthService,
    private _depratmentSer: DepartmentService,
    private _desigation: DesignationService,
    private _locationSer: LocationService) {this.CmpCode = this._authSer.getcompanyCode(); }

  ngOnInit() {
    this.filterSettings = { type: "Menu" };
    this.filter = { type: "CheckBox" };
    // this.stTime = performance.now();
    this.selectionSettings = { persistSelection: true, type: "Multiple", checkboxOnly: true };

    this.CmpCode = this._authSer.getcompanyCode();
    this._authSer.currentUser.subscribe((res) => {
      this.currentUserId = res.userId;
    });

    this.reportList = [];

    let currentDate = new Date();
    this.filterDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());

    this.setNavigationText();
    this.getDailyReport();
  }

  public pageSettings = { pageCount: 3 };
  @ViewChild('overviewgrid') public Grid: GridComponent;
  load() {
    const rowHeight: number = this.Grid.getRowHeight();  // height of the each row
    const gridHeight: any = this.Grid.height;  // grid height
    const pageSize: number = this.Grid.pageSettings.pageSize;   // initial page size
    const pageResize: any = (gridHeight - (pageSize * rowHeight)) / rowHeight; // new page size is obtained here
    this.Grid.pageSettings.pageSize = pageSize + Math.round(pageResize);
  }
  
  getCSharpFormattedDate(val: any) {
    if (val === null || val === "") {
      return null;
    }
    const date = new Date(val);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return month + "-" + day + "-" + year;
  }

  filterShow() {
    this.getLeaveTypeList();
    this.getDepartmentList();
    this.getAllDesigationList();
    this.getAllLocationList();
    this.flterShowData = !this.flterShowData;
  }
  closeFilter() {
    this.flterShowData = false;
  }

  ddlSelection(value: any, placeHolder: any) {
    try {
      switch (placeHolder) {
        case "LeaveType":
          this.ActiveLeaveTypes = [];
          this.ActiveLeaveTypes.push(value);
          this.filterLeaveTypeId = value.id;
          break;
        case "Department":
          this.ActiveDepartments = [];
          this.ActiveDepartments.push(value);
          this.filterDepartmentId = value.id;
          break;
        case "Desigation":
          this.ActiveDesignations = [];
          this.ActiveDesignations.push(value);
          this.filterDesignationId = value.id;
          break;
        case "Location":
          this.ActiveLocations = [];
          this.ActiveLocations.push(value);
          this.filterLocationId = value.id;
          break;
      }
    } catch (ex) {
      console.log(ex);
    }
  }
  removeFilterDropdownValue(value, _type) {
    try {
      switch (_type) {
        case "LeaveType":
          this.ActiveLeaveTypes = [];
          this.filterLeaveTypeId = null;
          break;
        case "Department":
          this.ActiveDepartments = [];
          this.filterDepartmentId = null;
          break;
        case "Desigation":
          this.ActiveDesignations = [];
          this.filterDesignationId = null;
          break;
        case "Location":
          this.ActiveLocations = [];
          this.filterLocationId = null;
          break;
      }
    } catch (ex) {
      console.log(ex);
    }
  }

  getLeaveTypeList() {
    this._leaveService.getAllLeaveTypeForSelect(this.CmpCode).subscribe((res) => {
      this.LeaveTypeList = res["data"].table;
      this.ddlLeaveTypeList = this._commonSer.SetDDlDropdown(res["data"].table, "leaveTypeId", "leaveTypeName");
    }, error => {
      this.userLoader = false;
      console.log(error);
    });
  }

  getDepartmentList() {
    let parms = {
      Condition: "",
      FieldName: "CreatedDate",
      NoOfRecords: 10000,
      PageNo: 1,
      SortType: "desc",
      CmpCode:this.CmpCode
    };
    this._depratmentSer.getDepartmentList(parms).subscribe((res) => {
      this.DepartmentList = res["data"].table;
      this.ddlDepartmentList = this._commonSer.SetDDlDropdown(res["data"].table, "departmentId", "departmentName");
    }, error => {
      this.userLoader = false;
      console.log(error);
    });
  }

  getAllDesigationList() {
    let parms = {
      Condition: "",
      FieldName: "CreatedDate",
      NoOfRecords: 10000,
      PageNo: 1,
      SortType: "desc",
      CmpCode:this.CmpCode
    };
    this._desigation.getDesignationList(parms).subscribe((res) => {
      this.DesignationList = res["data"].table;
      this.ddlDesignationList = this._commonSer.SetDDlDropdown(res["data"].table, "designationId", "designationName");
    }, error => {
      this.userLoader = false;
      console.log(error);
    });
  }

  getAllLocationList() {
    let parms = {
      Condition: "",
      FieldName: "CreatedDate",
      NoOfRecords: 10000,
      PageNo: 1,
      SortType: "desc",
      CmpCode:this.CmpCode
    };
    this._locationSer.getlocationlist(parms).subscribe((res) => {
      this.LocationList = res["data"].table;
      this.ddlLocationList = this._commonSer.SetDDlDropdown(res["data"].table, "locationId", "locationName");
    }, error => {
      this.userLoader = false;
      console.log(error);
    });
  }

  processFilters() {
    this.flterShowData = false;
    this.setNavigationText();
    this.getDailyReport();
  }

  resetFilters() {
    this.ActiveDepartments = [];
    this.filterDepartmentId = null;
    this.ActiveDesignations = [];
    this.filterDesignationId = null;
    this.ActiveLocations = [];
    this.filterLocationId = null;

    let currentDate = new Date();
    this.filterDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());

    this.setNavigationText();
  }

  // Close Opened Dropdowns when clicking on new dropdown
  closeDropdownList(element) {
    if (element.optionsOpened == true) {
      let elementsToclose = this.selectElements.filter(function (el: any) {
        return (el != element && el.optionsOpened == true);
      });
      elementsToclose.forEach(function (e: SelectComponent) {
        e.clickedOutside();
      });
    }
  }

  getChart() {
    this.showchart = true;
    this.showlist = false;
    this.chartOptions = {
      chart : {
        plotBorderWidth: null,
        plotShadow: false
      },
      title : {
        text: 'Daily Report'
      },
      tooltip : {
        pointFormat: '{series.name}: <b>{point.y}</b>'
      },
      plotOptions : {
        pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}: </b> {point.y}',
            }
        }
      },
      series : [{
        type: 'pie',
        name: 'Number of Employees on this Leave',
        data: this.chartData
      }]
  };
  }

  getDailyRepo() {
    this.showchart = false;
    this.showlist = true;
    this.getDailyReport();
  }

  compressArray(original: any[]) {
    let compressed = [];
    // make a copy of the input array
    let copy = original.slice(0);

    // first loop goes over every element
    for (let i = 0; i < original.length; i++) {
      let myCount = 0;
      // loop over every element in the copy and see if it's the same
      for (let w = 0; w < copy.length; w++) {
        if (original[i] == copy[w]) {
          // increase amount of times duplicate is found
          myCount++;
          // sets item to undefined
          delete copy[w];
        }
      }

      if (myCount > 0) {
        let a = [];
        a.push(original[i]);
        a.push(myCount);
        a.push("#eee");
        compressed.push(a);
      }
    }

    return compressed;
  }

  // Get Report
  getDailyReport() {
    if (this.showchart == true) {
      this.showlist = false;
      this.showchart = true;
    }

    this.userLoader = true;
    let parms = {
      Date: this.getCSharpFormattedDate(this.filterDate),
      FilterLeaveType: this.filterLeaveTypeId !== undefined ? this.filterLeaveTypeId : null,
      FilterDepartment: this.filterDepartmentId !== undefined ? this.filterDepartmentId : null,
      FilterDesignation: this.filterDesignationId !== undefined ? this.filterDesignationId : null,
      FilterLocation: this.filterLocationId !== undefined ? this.filterLocationId : null,
      FilterUserStatus: this.filterUserStatus !== undefined ? this.filterUserStatus : null,
      UserId: this.currentUserId,
      CmpCode:this.CmpCode
    };
    this._leaveService.getDailyReport(parms).subscribe((res) => {
      this.reportList = res["data"];
      this.catogries = [];
      for (let value of this.reportList) {
        this.catogries.push(value.leaveTypeName);
      }
      this.chartData = this.compressArray(this.catogries);
      console.clear();
      console.log(this.chartData);
      this.userLoader = false;
    }, error => {
      this.userLoader = false;
      console.log(error);
    });
  }

  // Navigation
  setNavigationText() {
    let vm = this;
    vm.navigationText = "";

    let dateString = vm.filterDate.getDate() + " " + vm.monthNamesList[vm.filterDate.getMonth()] + " " +  vm.filterDate.getFullYear() + "";
    vm.navigationText = dateString;
  }

  navigatePrevious(e) {
    let vm = this;
    let startDate = new Date(vm.filterDate);
    startDate.setDate(startDate.getDate() - 1);

    this.filterDate = startDate;
    vm.setNavigationText();
    vm.getDailyReport();
  }

  navigateNext(e) {
    let vm = this;
    let startDate = new Date(vm.filterDate);
    startDate.setDate(startDate.getDate() + 1);

    this.filterDate = startDate;
    vm.setNavigationText();
    vm.getDailyReport();
  }
  userImage(item) {
    // <img src="/assets/img/Logo/male.png" style="width: 190px;">
    if (item.userImage == '' || item.userImage == undefined || item.userImage == null) {
      // this.recieptIMg = `${environment.siteUrl}` + "Uploads/Icon/UploadIcons.png";
      if (item.genders == "Female") {
        this.recieptIMg = "/assets/img/Logo/female.png"
 
      }
      else {
        this.recieptIMg = "/assets/img/Logo/male.png"
 
      }
    }
    else {
      this.recieptIMg = `${environment.siteUrl}` + item.userImage;
    }
    return this.recieptIMg
 
  }
  export(): void {
    debugger
    const excelExportProperties: ExcelExportProperties = {
      fileName: 'daily-report.xlsx'
  };
  // this.UpdateForExport();
  //this.getLocationList();
  (this.Grid.columns[0] as Column).visible = true;
    this.Grid.excelExport(excelExportProperties);
  }

  printList(printData) {
    debugger
    let printContents = document.getElementById(printData).innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    location.reload();
  }

  exportTableToCSV() {
    debugger
    const excelExportProperties: ExcelExportProperties = {
      fileName: 'daily-report.csv'
  };
  (this.Grid.columns[0] as Column).visible = true;
  this.Grid.csvExport(excelExportProperties);
 setTimeout(() => {
  (this.Grid.columns[0] as Column).visible = false;
 }, 2000);

}

  // Export
  table0:any=[];
  table1:any=[];
  mergetable:any=[];
  ExportExcel() {
    debugger
    this.userLoader = true;
    let parms = {
      Date: this.getCSharpFormattedDate(this.filterDate),
      FilterLeaveType: this.filterLeaveTypeId !== undefined ? this.filterLeaveTypeId : null,
      FilterDepartment: this.filterDepartmentId !== undefined ? this.filterDepartmentId : null,
      FilterDesignation: this.filterDesignationId !== undefined ? this.filterDesignationId : null,
      FilterLocation: this.filterLocationId !== undefined ? this.filterLocationId : null,
      FilterUserStatus: this.filterUserStatus !== undefined ? this.filterUserStatus : null,
      UserId: this.currentUserId,
      CmpCode:this.CmpCode
    };
    this._leaveService.getDailyReport_Excel(parms).subscribe((res) => {
      let exportData = res["data"];
      this.table0=exportData.table1;
      this.table1=exportData.table2;
      this.mergetable= this.table1.concat(this.table0)
   
      this.userLoader = false;
      this._commonSer.exportAsExcelFile(this.mergetable, 'Daily Report');
    }, error => {
      this.userLoader = false;
      console.log(error);
    });
  }

  downloadChart(_type) {
    let vm = this;
    this.IsShowMenu = false;
    html2canvas(document.querySelector("#dvChart")).then(canvas => {
      let contentType = "image/png";
      let fileName = "Daily_Report";
      if (_type == 1) {
        contentType = "image/png";
      } else if (_type == 2) {
        contentType = "image/jpeg";
      } else if (_type == 3) {
        contentType = "application/pdf";
      }

      /// document.body.appendChild(canvas);
      this.capturedImage = canvas.toDataURL("image/jpeg", 1.0);
      //console.log("canvas.toDataURL() -->" + this.capturedImage);
      // this will contain something like (note the ellipses for brevity), console.log cuts it off 
      // "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAa0AAAB3CAYAAACwhB/KAAAXr0lEQVR4Xu2dCdiNZf7HP/ZQkpQtaUxDjYYoTSYlURMhGlmKa..."

      canvas.toBlob(function (blobData) {
        console.clear();
        console.log(blobData);
        //  just pass blob to something expecting a blob
        // somfunc(blob);

        // Same as canvas.toDataURL(), just longer way to do it.
        // let reader = new FileReader();
        // debugger;
        // reader.readAsDataURL(blob);
        // reader.onloadend = function () {
        //   let base64data = reader.result;
        //   //console.log("Base64--> " + base64data);

        //   const blobData = vm.convertBase64ToBlobData(base64data.toString(), 'image/png', 512);

        //   if (window.navigator && window.navigator.msSaveOrOpenBlob) { //IE
        //     window.navigator.msSaveOrOpenBlob(blobData, fileName);
        //   } else { // chrome
        //     const blob = new Blob([blobData], { type: contentType });
        //     const url = window.URL.createObjectURL(blob);
        //     // window.open(url);
        //     const link = document.createElement('a');
        //     link.href = url;
        //     link.download = fileName;
        //     link.click();
        //   }
        // };

        if (_type == 3) {
          vm.userLoader = true;
          // let pdf = new jsPDF();
          // pdf.addImage(vm.capturedImage, 'JPEG', 0, 0);
          // pdf.save("download.pdf");

          let reader = new FileReader();
          reader.readAsDataURL(blobData);
          reader.onloadend = function () {
            let base64data = reader.result;
            let params = {
              base64Data: base64data,
              fileName: 'Daily Report'
            };
            // vm._leaveService.getReportPDFFromBase64(params).subscribe((res) => {
            //   vm.userLoader = false;
            //   let url = URL.createObjectURL(res);
            //   let a = document.createElement("a");
            //   a.href = url;
            //   a.download = "Daily Report.pdf";
            //   a.click();
            // }, error => {
            //   vm.userLoader = false;
            //   console.log(error);
            // });

            try {
              vm._leaveService.getReportPDFFromBase64(params)
                .subscribe((res) => {
                  vm.userLoader = false;
                  let url = URL.createObjectURL(res);
                  let a = document.createElement("a");
                  a.href = url;
                  a.download = "Daily Report.pdf";
                  a.click();
                });
            } catch (ex) {
              vm.userLoader = false;
              console.log(ex);
            }
          };
        } else {
          if (window.navigator && window.navigator.msSaveOrOpenBlob) { //IE
            window.navigator.msSaveOrOpenBlob(blobData, fileName);
          } else { // chrome
            const blob = new Blob([blobData], { type: contentType });
            const url = window.URL.createObjectURL(blob);
            // window.open(url);
            const link = document.createElement('a');
            link.href = url;
            link.download = fileName;
            link.click();
          }
        }
      });
      this.IsShowMenu = true;
    });
  }

  // convertBase64ToBlobData(base64Data: string, contentType: string = 'image/png', sliceSize = 512) {
  //   const byteCharacters = atob(base64Data);
  //   const byteArrays = [];

  //   for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
  //     const slice = byteCharacters.slice(offset, offset + sliceSize);

  //     const byteNumbers = new Array(slice.length);
  //     for (let i = 0; i < slice.length; i++) {
  //       byteNumbers[i] = slice.charCodeAt(i);
  //     }

  //     const byteArray = new Uint8Array(byteNumbers);

  //     byteArrays.push(byteArray);
  //   }

  //   const blob = new Blob(byteArrays, { type: contentType });
  //   return blob;
  // }
}
