import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./auth.guard";

const routes: Routes = [
  {
    path: "",
    redirectTo: "Login",
    pathMatch: "full"
  },
  {
    path: "expense",
    data: {
     // title: "Expense"
    },
    children: [

{
        path: "projecthealthcard",
        // {
        //   path: "hometest",
        //   loadChildren:
        //     () => import('./+layout/expense/hometest/hometest.module').then(m => m.HometestModule),
        //   data: {
        //     title: "Project Health Card",
        //     module: "hometest"
        //   }
        // },
        loadChildren:
          () => import('./+layout/expense/projecthealthcard/projecthealthcard.module').then(m => m.ProjecthealthcardModule),
        data: {
         // title: "Project Health Card",
          module: "Projecthealthcard",
          customLayout:true
        }


      },
      {
        path: "showfaq",
        // {
        //   path: "hometest",
        //   loadChildren:
        //     () => import('./+layout/expense/hometest/hometest.module').then(m => m.HometestModule),
        //   data: {
        //     title: "Project Health Card",
        //     module: "hometest"
        //   }
        // },
        loadChildren:
          () => import('./+layout/expense/showfaq/showfaq.module').then(m => m.ShowFaqModule),
        data: {
          //title: "Show FAQ",
          module: "showfaq",
          customLayout:true
        }


      },
    ]
    },

  {
    path: "Login",
    loadChildren: () => import('./+login/login.module').then(m => m.LoginModule),
    data: {
      customLayout: true
    }
  },
  {
    path: "32568fire",
    loadChildren: () => import('./missfire/missfire.module').then(m => m.missfireModule),
    data: {
      customLayout: true
    }
  },
  {
    path: "inviteuserlogin/:responseId",
    loadChildren:
      () => import('./+layout/expense/inviteuserlogin/inviteuserlogin.module').then(m => m.InviteuserloginModule),
    data: {
      //title: "Inviteuser Login",
      module: "inviteuserlogin",
      customLayout: true
    }
  },
  {
    path: "paSystem",
    data: {
      //title: "PASystem"
    },
    canActivate: [AuthGuard],
    children: [
      {
        path: "Masters",
        data: {
         // title: "Masters"
        },
        // canActivate: [AuthGuard],
        // canActivateChild: [AuthGuard],
        children: [
          {
            path: "countrymaster",
            loadChildren: () => import('./+layout/country/country.module').then(m => m.CountryModule),
            data: {
              //title: "Country",
              module: "COUNTRY"
            }
          },
          // {
          //   path: 'currencymaster',
          //   loadChildren: './currency/currency.module#CurrencyModule',
          //   data: {
          //     title: 'Currency',
          //     module: 'CURRENCY'
          //   }
          // },
          {
            path: "contactAddress",
            loadChildren: () => import('./+layout/CAddress/CAddress.module').then(m => m.CAddressModule),
            data: {
              //title: "Customer Profiles",
              module: "CONTACTPERSON"
            }
          },
          {
            path: "AccountCustodianProfiles",
            loadChildren: () => import('./+layout/Sales/Sales.module').then(m => m.SalesModule),
            data: {
              //title: "Account Custodian Profiles",
              module: "SALES"
            }
          },
          {
            path: "zipcode",
            loadChildren: () => import('./+layout/zipcode/zipcode.module').then(m => m.zipModule),
            data: {
              //title: "Zipcode",
              module: "ZIPCODE"
            }
          },
          {
            path: "businessType",
            loadChildren:
              () => import('./+layout/bussinessType/bussinessType.module').then(m => m.bussinessTypeModule),
            data: {
              //title: "Business Type",
              module: "BUSINESS"
            }
          },
          {
            path: "roadblockMaster",
            loadChildren:
              () => import('./+layout/roadblockMaster/roadblockMaster.module').then(m => m.RoadbloackMasterModule),
            data: {
              //title: "RoadblockMaster",
              module: "RoadblockMaster"
            }
          },
          {
            path: "location",
            loadChildren: () => import('./+layout/location/location.module').then(m => m.locationModule),
            data: {
              //title: "Location",
              module: "LOCATION"
            }
          },
          {
            path: "department",
            loadChildren:
              () => import('./+layout/department/department.module').then(m => m.departmentModule),
            data: {
              //title: "Department",
              module: "DEPARTMENT",
              disableLayout: true
            }
          },
          {
            path: "designation",
            loadChildren:
              () => import('./+layout/designation/designation.module').then(m => m.designationModule),
            data: {
              //title: "Designation",
              module: "DESIGNATION",
              disableLayout: true
            }
          },
          {
            path: "vendor",
            loadChildren:
              () => import('./+layout/Vendor/Vendor.module').then(m => m.vendorModule),
            data: {
              //title: "Designation",
              module: "Vendor",
              disableLayout: true
            }
          },
          {
            path: "jobband",
            loadChildren: () => import('./+layout/jobband/jobband.module').then(m => m.jobbandModule),
            data: {
              //title: "Job Band",
              module: "JOBBAND"
            }
          },
          {
            path: "group",
            loadChildren: () => import('./+layout/group/group.module').then(m => m.groupModule),
            data: {
              //title: "Job Band",
              module: "GROUP"
            }
          },
          {
            path: "announcements",
            loadChildren:
              () => import('./+layout/announcements/announcements.module').then(m => m.AnnouncementsModule),
            data: {
              //title: "announcements",
              module: "announcements"
            }
          },
          {
            path: "quicklink",
            loadChildren:
              () => import('./+layout/quicklink/quicklink.module').then(m => m.QuickLinkModule),
            data: {
              //title: "quicklink",
              module: "quicklink"
            }
          },
          {
            path: "emailtemplate",
            loadChildren:
              () => import('./+layout/email-template/email-template.module').then(m => m.emailTemplateModule),
            data: {
              //title: "Email Templates",
              module: "emailtemplate"
            }
          },
          {
            path: "emailtemplate/:id",
            loadChildren:
              () => import('./+layout/email-template/email-template.module').then(m => m.emailTemplateModule),
            data: {
              //title: "Email Templates",
              module: "emailtemplate"
            }
          },
          {
            path: "statemaster",
            loadChildren: () => import('./+layout/state/state.module').then(m => m.StateModule),
            data: {
              //title: "State",
              module: "STATE"
              // disableLayout: true
            }
          },
          {
            path: "customer",
            loadChildren: () => import('./+layout/customer/customers.module').then(m => m.CustomersModule),
            data: {
              //title: "Customer",
              module: "CUSTOMER"
            }
          },
          {
            path: "city",
            loadChildren: () => import('./+layout/city/city.module').then(m => m.CityModule),
            data: {
              //title: "City",
              module: "CITY"
            }
          },
          // {
          //   path: 'location',
          //   loadChildren: './+layout/city/city.module#CityModule',
          //   data: {
          //     title: 'Location',
          //     module: 'LOCATION'
          //   }
          // },
          {
            path: "levels",
            loadChildren: () => import('./+layout/levels/levels.module').then(m => m.LevelsModule),
            data: {
              //title: "Organization Structure Level",
              module: "LEVEL"
            }
          },
          {
            path: "levelElements",
            loadChildren:
              () => import('./+layout/level-elements/level-elements.module').then(m => m.LevelElementsModule),
            data: {
              //title: "Organization Structure Level Element",
              module: "LEVELELEMENT"
            }
          },
          {
            path: "permission",
            loadChildren:
              () => import('./role-masters/permission/permission.module').then(m => m.PermissionModule),
            data: {
              //title: "Permission"
            }
          },
          {
            path: "role",
            loadChildren: () => import('./role-masters/role/role.module').then(m => m.RoleModule),
            data: {
              //title: "Role",
              module: "ROLE"
            }
          },
          {
            path: "group",
            loadChildren: () => import('./role-masters/group/group.module').then(m => m.GroupModule),
            data: {
              //title: "Group",
              module: "GROUP"
            }
          },

          {
            path: "projecttimeline",
            loadChildren: () => import('./+layout/projecttimeline/projecttimeline.module').then(m => m.ProjecttimelineModule),
            data: {
              //title: "Project",
              module: "projecttimeline"
            }
          },
          // {
          //   path: 'languagemaster',
          //   loadChildren: './languageMaster/languageMaster.module#LanguageMasterModule',
          //   data: {
          //     title: 'Language Master',
          //     module: 'LANGUAGE'
          //   }
          // },
          // {
          //   path: 'keywordmaster',
          //   loadChildren: './keywordMaster/keywordMaster.module#KeyWordMasterModule',
          //   data: {
          //     title: 'Keyword Master',
          //     module: 'KEYWORD'
          //   }
          // },
          {
            path: "projects/:id",
            loadChildren: () => import('./+layout/project/projects.module').then(m => m.ProjectsModule),
            data: {
              //title: "Project",
              module: "PROJECT"
            }
          },
          {
            path: "projects",
            loadChildren: () => import('./+layout/project/projects.module').then(m => m.ProjectsModule),
            data: {
              //title: "Project",
              module: "PROJECT"
            }
          },
          {
            path: "kanban",
            loadChildren:
              () => import('./+layout/kanban/kanban.module').then(m => m.KanbansModule),
            data: {
              //title: "Project Kanban",
              module: "KANBAN"
            }
          },
          {
            path: "timesheetnew",
            loadChildren:
              () => import('./+layout/timesheetnew/timesheetnew.module').then(m => m.TimesheetnewModule),
            data: {
              //title: "Record Time",
              module: "timesheetnew"
            }
          },
          //:employeeId


          {
            path: "timesheetapprovals",
            loadChildren:
              () => import('./+layout/timesheetapprovals/timesheetapprovals.module').then(m => m.TimesheetapprovalsModule),
            data: {
              //title: "Time Approval",
              module: "timesheetapprovals"
            }
          },
          {
            path: "timesheetapprovals/:userId/:from/:to",
            loadChildren:
              () => import('./+layout/timesheetapprovals/timesheetapprovals.module').then(m => m.TimesheetapprovalsModule),
            data: {
              //title: "Time Approval",
              module: "timesheetapprovals"
            }
          },
          {
            path: "ganttchart/:projectId",
            loadChildren:
              () => import('./+layout/ganttchart/ganttchart.module').then(m => m.GanttChartModule),
            data: {
              //title: "Ganttchart",
              module: "GANTTCHART"
            }
          },

          {
            path: "ganttchart",
            loadChildren:
              () => import('./+layout/ganttchart/ganttchart.module').then(m => m.GanttChartModule),
            data: {
              //title: "Gantt Chart",
              module: "GANTTCHART"
            }
          },
          {
            path: "projectPhases",
            loadChildren:
              () => import('./+layout/projectPhase/projectPhases.module').then(m => m.ProjectPhasesModule),
            data: {
              //title: "Project Phase",
              module: "PROJECTPHASE"
            }
          },
          {
            path: "projectAssign",
            loadChildren:
              () => import('./+layout/projectAssign/project-assign.module').then(m => m.ProjectAssignModule),
            data: {
              //title: "Resource Assignment",
              module: "PROJECTASSIGN"
            }
          },
          {
            path: "projectAssignUpdated",
            loadChildren:
              () => import('./+layout/projectAssignUpdated/projectAssignUpdated.module').then(m => m.projectAssignUpdatedModule),
            data: {
              //title: "Resource Assignment",
              module: "PROJECTASSIGN"
            }
          },
          // {
          //   path: "Gantt",
          //   loadChildren:
          //     "./+layout/project-gantt/project-gantt.module#ProjectGanttModule",
          //   data: {
          //     title: "Project Gantt",
          //     module: "PROJECT"
          //   }
          // },
          // {
          //   path: 'projectGantt',
          //   loadChildren: () => import('./+layout/project-gantt-chart/project-gantt-chart.module').then(m => m.ProjectGanttChartModule),
          //   data: {
          //     title: 'Project Gantt',
          //     module: 'PROJECT'
          //   }
          // },
          {
            path: "timesheets",
            loadChildren:
              () => import('./+layout/timesheet/timesheets.module').then(m => m.TimesheetsModule),
            data: {
              //title: "Timesheet",
              module: "TIMESHEET"
            }
          },
          {
            path: "checkin-report",
            loadChildren:
              () => import('./+layout/checkin-report/checkin-report.module').then(m => m.CheckinReportModule),
            data: {
              //title: "Timesheet",
              module: "TIMESHEET"
            }
          },
          {
            path: "timesheetApproval",
            loadChildren:
              () => import('./+layout/timesheetApprove/timesheetApprove.module').then(m => m.TimesheetApproveModule),
            data: {
              //title: "Timesheet Approval",
              module: "APPROVETIMESHEET"
            }
          },



          {//:startDate/:endDate/:employeeId/:createdBy",
            path: "timesheetApproval/:startDate/:endDate/:employeeId",
            loadChildren:
              () => import('./+layout/timesheetApprove/timesheetApprove.module').then(m => m.TimesheetApproveModule),
            data: {
              //title: "Timesheet Approval",
              module: "APPROVETIMESHEET"
            }
          },
          {
            path: "timesheetReport",
            loadChildren:
              () => import('./+layout/timesheet-report/timesheet-report.module').then(m => m.TimesheetReportModule),
            data: {
              //title: "Timesheet Reports",
              module: "TIMESHEETREPORT"
            }
          }
        ]
      }
    ]
  },
  {
    path: "user",
    pathMatch: "full",
    loadChildren: () => import('./user/user.module').then(m => m.UserModule),
    data: {
      //title: "User",
      module: "USER"
    },
    canActivate: [AuthGuard]
  },
  {
    path: "paSystem/Masters/mom",
    pathMatch: "full",
    loadChildren: () => import('./+layout/mom/mom.module').then(m => m.MomModule),
    data: {
     // title: "Minutes of Meeting",
      module: "MEETINGMINUTES"
    }
  },
  {
    path: "paSystem/Masters/roadblock",
    pathMatch: "full",
    loadChildren: () => import('./+layout/roadblock/roadblock.module').then(m => m.RoadblockModule),
    data: {
     // title: "Roadblocks",
      module: "ROADBLOCK"
    }
    //canActivate: [AuthGuard]
  },
  {
    path: "udf",
    pathMatch: "full",
    loadChildren: () => import('./udf/udf.module').then(m => m.UdfModule),
    data: {
      //title: "UDF",
      module: "UDF"
    },
    canActivate: [AuthGuard]
  },
  {
    path: "home",
    pathMatch: "full",
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    data: {
      //title: "Home"
    },
    canActivate: [AuthGuard]
  },
  {
    path: "UserLocations/:data",
    pathMatch: "full",
    loadChildren: () => import('./WorldMap/userLocation.module').then(m => m.WorldMapModule ),
    data: {
      //title: "Home"
    },
    canActivate: [AuthGuard]
  },
  {
    path: "detail",
    pathMatch: "full",
    loadChildren: () => import('./frequestlyaskquestion/detail.module').then(m => m.DetailModule),
    data: {
     // title: "detail"
    },
    canActivate: [AuthGuard]
  },
  {
    path: "leave",
    pathMatch: "full",
    loadChildren: () => import('./+layout/leave/leave.module').then(m => m.LeaveModule),
    data: {
     // title: "Leave Dashboard ",
      module: "LEAVE"
    }
  },
  // {
  //   path: 'approval',
  //   pathMatch: 'full',
  //   loadChildren: './+layout/leave/approval/approval.module#LeaveApprovalModule',
  //   data: {
  //     title: 'Requests/Approvals',
  //     module: 'REQUEST'
  //   }, canActivate: [AuthGuard]
  // },
  // {
  //   path: 'leave-approval',
  //   pathMatch: 'full',
  //   loadChildren: './+layout/leave/leave-approval/leave-approval.module#LeaveApprovalModule',
  //   data: {
  //     title: 'Leave Approval ',
  //     module: 'LEAVEAPPROVAL'
  //   }, canActivate: [AuthGuard]
  // },
  // {
  //   path: 'approve',
  //   pathMatch: 'full',
  //   loadChildren: './+layout/leave/comp-off-approve/comp-off-approve.module#CompOffApproveModule',
  //   data: {
  //     title: 'Leave Dashboard COMPENSATORY OFF APPROVAL',
  //     module: 'COMP-OFF-APPROVE'
  //   }, canActivate: [AuthGuard]
  // },
  // {
  //   path: 'setting',
  //   pathMatch: 'full',
  //   loadChildren: './+layout/leave/setting/setting.module#SettingModule',
  //   data: {
  //     title: 'Leave Type',
  //     module: 'SETTING'
  //   }, canActivate: [AuthGuard]
  // },
  {
    path: "reset-password",
    loadChildren: () => import('./reset-password/reset-password.module').then(m => m.ResetPasswordModule),
    data: {
      customLayout: true,
      //title: "Reset Password"
    },
   // canActivate: [AuthGuard]
  },
  {
    path: "expense",
    data: {
     // title: "Expense"
    },
    canActivate: [AuthGuard],
    children: [
      {
        path: "category",
        loadChildren:
          () => import('./+layout/expense/category/category.module').then(m => m.CategoryModule),
        data: {
         // title: "category",
          module: "CATEGORY"
        }
      },
      {
        path: "subcategory",
        loadChildren:
          () => import('./+layout/expense/sub-category/sub-category.module').then(m => m.SubCategoryModule),
        data: {
          //title: "Sub Category",
          module: "SUBCATEGORY"
        }
      },
      {
        path: "pdfreport",
        loadChildren:
          () => import('./+layout/expense/pdfreport/pdfreport.module').then(m => m.pdfreportModule),
        data: {
         // title: "pdfreport",
          module: "pdfreport"
        }
      },

      {
        path: "progressreport",
        loadChildren:
          () => import('./+layout/expense/progressreport/progressreport.module').then(m => m.progressreportModule),
        data: {
          //title: "Expense Report",
          module: "progressreport"
        }
      },
      {
        path: "shedule",
        loadChildren:
          () => import('./+layout/expense/shedule/sheduleapp.module').then(m => m.SheduleAppModule),
        data: {
         // title: "shedule",
          module: "Shedule"
        }
      },
      {
        path: "policyList",
        loadChildren:
          () => import('./+layout/expense/policy-list/policy-list.module').then(m => m.PolicyListModule),
        data: {
          //title: "Policy Configuration",
          module: "policyList"
        }
      },
      {
        path: "policy/:policyId",
        loadChildren: () => import('./+layout/expense/policy/policy.module').then(m => m.PolicyModule),
        data: {
         // title: "policy",
          module: "POLICY"
        }
      },
      {
        path: "rule",
        loadChildren: () => import('./+layout/expense/addRule/rule.module').then(m => m.RuleModule),
        data: {
        //  title: "rule",
          module: "Rule"
        }
      },

      {
        path: "Inviteuser",
        loadChildren:
          () => import('./+layout/expense/inviteuser/inviteuser.module').then(m => m.InviteuserModule),
        data: {
          //title: "Invite User",
          module: "Inviteuser"
        }
      },
      {
        path: "Inviteuseredit/:userId/:typeId",
        loadChildren:
          () => import('./+layout/expense/inviteuseredit/inviteuseredit.module').then(m => m.InviteusereditModule),
        data: {
          //title: "Invite User",
          module: "Inviteuseredit"
        }
      },
      {

        path: "InviteUserList",
        loadChildren:
          () => import('./+layout/expense/inviteUserList/inviteUserList.module').then(m => m.InviteUserListModule),
        data: {
          //title: "User List",
          module: "InviteUserList"
        }
      },
      {

        path: "VenderRegisteration",
        loadChildren:
          () => import('./+layout/expense/venderregistration/venderregistration.module').then(m => m.VenderRegistrationModule),
        data: {
          //title: "User List",
          module: "VenderRegistration"
        }
      },
      {
        path: "Addexpense",
        loadChildren:
          () => import('./+layout/expense/addexpense/addexpense.module').then(m => m.AddexpenseModule),
        data: {
          //title: "Add Expense",
          module: "Addexpense"
        }
      },
      {
        path: "Addexpense/:expenseId",
        loadChildren:
          () => import('./+layout/expense/addexpense/addexpense.module').then(m => m.AddexpenseModule),
        data: {
          //title: "Add Expense",
          module: "Addexpense"
        }
      },
      {
        path: "Allexpense",
        loadChildren:
          () => import('./+layout/expense/allexpense/allexpense.module').then(m => m.AllexpenseModule),
        data: {
          //title: "Expense Details",
          module: "Allexpense"
        }
      },
      {
        path: "resourceallocation",
        loadChildren:
          () => import('./+layout/expense/resourceallocation/resourceallocation.module').then(m => m.ResourceallocationModule),
        data: {
          //title: "Resource Allocation",
          module: "Resourceallocation"
        }
      },
      {
        path: "employeePolicy",
        loadChildren:
          () => import('./+layout/expense/employeePolicy/employeePolicy.module').then(m => m.EmployeePolicyModule),
        data: {
          //title: "Employee Policy",
          module: "EmployeePolicy"
        }
      },
      {
        path: "Viewexpense/:categoryId",
        loadChildren:
          () => import('./+layout/expense/viewexpense/viewexpense.module').then(m => m.ViewexpenseModule),
        data: {
          //title: "Expense Details",
          module: "Viewexpense"
        }
      },
      // {
      //   path: "Editexpense/:expenseId",
      //   loadChildren:
      //     () => import('./+layout/expense/editexpense/editexpense.module').then(m => m.EditexpenseModule),
      //   data: {
      //     title: "editexpense",
      //     module: "Editexpense"
      //   }
      // },
      {
        path: "approvalsetting",
        loadChildren:
          () => import('./+layout/expense/approvalsetting/approvalsetting.module').then(m => m.ApprovalsettingModule),
        data: {
          //title: "Approval Setting",
          module: "approvalsetting"
        }
      },

      {
        path: "Advancepayments",
        loadChildren:
          () => import('./+layout/expense/advancepayments/advancepayments.module').then(m => m.AdvancepaymentsModule),
        data: {
         // title: "Submit Expense",
          module: "Advancepayments"
        }
      },
      {
        path: "Advancepayments/:reportId",
        loadChildren:
          () => import('./+layout/expense/advancepayments/advancepayments.module').then(m => m.AdvancepaymentsModule),
        data: {
          //title: "Submit Expense",
          module: "Advancepayments"
        }
      },
      {
        path: "AddAdvancePayment",
        loadChildren:
          () => import('./+layout/expense/all-advance-payment-listing/all-advance-payment-listing.module').then(m => m.AllAdvancePaymentListingModule),
        data: {
          //title: "Add Advance Payment",
          module: "AddAdvancePayment"
        }
      }
      ,
      {
        path: "BulkCategory",
        loadChildren:
          () => import('./+layout/expense/import-category/import-category.module').then(m => m.ImportCategoryModule),
        data: {
         // title: "Category / SubCategory",
          module: "BULKCATEGORY"
        }
      },
      {
        path: "AdvancePaymentListig",
        loadChildren:
          () => import('./+layout/expense/all-advance-payment-listing/all-advance-payment-listing.module').then(m => m.AllAdvancePaymentListingModule),
        data: {
          //title: "AdvancePaymentListig",
          module: "AdvancePaymentListig"
        }
      },
      {
        path: "dropdownpayment",
        loadChildren:
          () => import('./+layout/expense/add-advance-payment/add-advance-payment.module').then(m => m.AddAdvancePaymentModule),
        data: {
          //title: "DropdownComponent",
          module: "dropdownpayment"
        }
      },

      {
        path: "approval",
        loadChildren:
          () => import('./+layout/expense/approval/approval.module').then(m => m.ApprovalModule),
        data: {
          //title: "Report Details",
          module: "approval"
        }
      },
      {
        path: "approval/:reportId",
        loadChildren:
          () => import('./+layout/expense/approval/approval.module').then(m => m.ApprovalModule),
        data: {
          //title: "approval",
          module: "approval"
        }
      },

      {
        path: "BulkApproval",
        loadChildren:
          () => import('./+layout/expense/bulk-report/bulk-report.module').then(m => m.BulkReportModule),
        data: {
          //title: "Bulk Approval",
          module: "BulkApproval"
        }
      },

      {
        path: "OutOfOfficer",
        loadChildren:
          () => import('./+layout/expense/out-of-officer/out-of-officer.module').then(m => m.OutOfOfficerModule),
        data: {
          //title: "Out Of Office",
          module: "outofofficer"
        }
      },

      {
        path: "ReportListing",
        loadChildren:
          () => import('./+layout/expense/report-listing/report-listing.module').then(m => m.ReportListingModule),
        data: {
          //title: "ReportListing",
          module: "ReportListing"
        }
      },
      {
        path: "Reports",
        loadChildren: () => import('./+layout/expense/reports/reports.module').then(m => m.ReportsModule),
        data: {
          //title: "Reports",
          module: "Reports"
        }
      },
      {
        path: "Reimbursement",
        loadChildren: () => import('./+layout/expense/reimbursement/reimbursement.module').then(m => m.ReimbursementModule),
        data: {
          //title: "Reimbursement",
          module: "Reimbursement"
        }
      },
      {
        path: "newreport",
        loadChildren:
          () => import('./+layout/expense/newreport/newreport.module').then(m => m.NewreportModule),
        data: {
          //title: "New Report",
          module: "newreport"
        }
      },
      {
        path: "reporttitle",
        loadChildren:
          () => import('./+layout/expense/reporttitle/reporttitle.module').then(m => m.ReporttitleModule),
        data: {
          //title: "reporttitle",
          module: "reporttitle"
        }
      },
      {
        path: "mailalert",
        loadChildren:
          () => import('./+layout/expense/mailalert/mailalert.module').then(m => m.MailalertModule),
        data: {
          //title: "mailalert",
          module: "mailalert"
        }
      },

      {
        path: "empsubstitutedetails",
        loadChildren:
          () => import('./+layout/expense/empsubstitutedetails/empsubstitutedetails.module').then(m => m.EmpsubstitutedetailsModule),
        data: {
          //title: "empsubstitutedetails",
          module: "empsubstitutedetails"
        }
      },

      // {
      //   path: "inviteuserlogin/:responseId",
      //   loadChildren:
      //     () => import('./+layout/expense/inviteuserlogin/inviteuserlogin.module').then(m => m.InviteuserloginModule),
      //   data: {
      //     title: "inviteuserlogin",
      //     module: "inviteuserlogin"
      //   }
      // },
      {
        path: "myapprovals",
        loadChildren:
          () => import('./+layout/expense/myapprovals/myapprovals.module').then(m => m.MyapprovalsModule),
        data: {
          //title: "Expense Approval",
          module: "myapprovals"
        }
      },
      {
        path: "newcustomapproval",
        loadChildren:
          () => import('./+layout/expense/newcustomapproval/newcustomapproval.module').then(m => m.NewcustomapprovalModule),
        data: {
          //title: "newcustomapproval",
          module: "newcustomapproval"
        }
      },
      {
        path: "expensereport",
        loadChildren:
          () => import('./+layout/expense/expensereport/expensereport.module').then(m => m.ExpensereportModule),
        data: {
          //title: "Expense Report",
          module: "expensereport"
        }
      },
      {
        path: "resourceallocation",
        loadChildren:
          () => import('./+layout/expense/resourceallocation/resourceallocation.module').then(m => m.ResourceallocationModule),
        data: {
          //title: "Resource Allocation",
          module: "resourceallocation"
        }
      },
      {
        path: "unsubmitted",
        loadChildren:
          () => import('./+layout/expense/unsubmitted/unsubmitted.module').then(m => m.UnsubmittedModule),
        data: {
          //title: "Unsubmitted Expenses Report",
          module: "unsubmitted"
        }
      },
      {
        path: "excategory",
        loadChildren:
          () => import('./+layout/expense/excategory/excategory.module').then(m => m.ExcategoryModule),
        data: {
          //title: "Ex-Category",
          module: "excategory"
        }
      },
      {
        path: "exuser",
        loadChildren: () => import('./+layout/expense/exuser/exuser.module').then(m => m.ExuserModule),
        data: {
          //title: "Expenses by User",
          module: "exuser"
        }
      },
      {
        path: "exmileage",
        loadChildren:
          () => import('./+layout/expense/exmileage/exmileage.module').then(m => m.ExmileageModule),
        data: {
          //title: "Ex-Mileage",
          module: "exmileage"
        }
      },
      {
        path: "policyviolations",
        loadChildren:
          () => import('./+layout/expense/policyviolations/policyviolations.module').then(m => m.PolicyviolationsModule),
        data: {
          //title: "Policy Violations",
          module: "policyviolations"
        }
      },
      {
        path: "excustomer",
        loadChildren:
          () => import('./+layout/expense/excustomer/excustomer.module').then(m => m.ExcustomerModule),
        data: {
          //title: "Ex-Customer",
          module: "excustomer"
        }
      },
      {
        path: "exproject",
        loadChildren:
          () => import('./+layout/expense/exproject/exproject.module').then(m => m.ExprojectModule),
        data: {
          //title: "Ex-Project",
          module: "exproject"
        }
      },
      {
        path: "changerequest",
        loadChildren:
          () => import('./+layout/expense/changerequest/changerequest.module').then(m => m.ChangerequestModule),
        data: {
          //title: "Change Request",
          module: "changerequest"
        }
      },

      {
        path: "resourcereport",
        loadChildren:
          () => import('./+layout/expense/resourcereport/resourcereport.module').then(m => m.ResourcereportModule),
        data: {
          //title: "Resource Efficiency Report",
          module: "resourcereport"
        }
      },
      {
        path: "bugreport",
        loadChildren:
          () => import('./+layout/expense/bugreport/bugreport.module').then(m => m.BugreportModule),
        data: {
          //title: "Resource Efficiency Report",
          module: "bugreport"
        }
      },
      {
        path: "contactsupportutilization",
        loadChildren:
          () => import('./+layout/expense/contactsupportutilization/contactsupportutilization.module').then(m => m.ContactsupportutilizationModule),
        data: {
          //title: "Resource Efficiency Report",
          module: "contactsupportutilization"
        }
      },
      {
        path: "projectfeedback",
        loadChildren:
          () => import('./+layout/expense/projectfeedback/projectfeedback.module').then(m => m.ProjectfeedbackModule),
        data: {
          //title: "Resource Efficiency Report",
          module: "projectfeedback"
        }
      },
      {
        path: "resourceReleaseReport",
        loadChildren:
          () => import('./+layout/expense/resourceReleaseReport/resourceReleaseReport.module').then(m => m.resourceReleaseReportModule),
        data: {
          //title: "Resource Efficiency Report",
          module: "resourceReport1"
        }
      },
      {
        path: "TimeRecordStatus",
        loadChildren:
          () => import('./+layout/expense/resourceReport2/resourceReport2.module').then(m => m.resourceReport2Module),
        data: {
          //title: "Resource Efficiency Report",
          module: "TimeRecordStatus"
        }
      },
      {
        path: "projectconcern",
        loadChildren:
          () => import('./+layout/expense/projectconcern/projectconcern.module').then(m => m.ProjectconcernModule),
        data: {
          //title: "Resource Efficiency Report",
          module: "projectconcern"
        }
      },
      {
        path: "usercompliancereport",
        loadChildren:
          () => import('./+layout/expense/usercompliancereport/usercompliancereport.module').then(m => m.UsercompliancereportModule),
        data: {

          module: "usercompliancereport"
        }
      },
      {
        path: "resourcereport/:projectId",
        loadChildren:
          () => import('./+layout/expense/resourcereport/resourcereport.module').then(m => m.ResourcereportModule),
        data: {
          //title: "Resource Efficiency Report",
          module: "resourcereport"
        }
      },

      {
        path: "overduetaskreport",
        loadChildren:
          () => import('./+layout/expense/overduetaskreport/overduetaskreport.module').then(m => m.OverduetaskreportModule),
        data: {
          //title: "Over Due Task Report",
          module: "overduetaskreport"
        }
      },

      {
        path: "plannedactualreport",
        loadChildren:
          () => import('./+layout/expense/plannedactualreport/plannedactualreport.module').then(m => m.PlannedactualreportModule),
        data: {
          //title: "Planned Vs Actual Report",
          module: "plannedactualreport"
        }
      },
      {
        path: "plannedactualreport/:projectId",
        loadChildren:
          () => import('./+layout/expense/plannedactualreport/plannedactualreport.module').then(m => m.PlannedactualreportModule),
        data: {
          //title: "Planned Vs Actual Report",
          module: "plannedactualreport"
        }
      },

      {
        path: "resourceworkreport",
        loadChildren:
          () => import('./+layout/expense/resourceworkreport/resourceworkreport.module').then(m => m.ResourceworkreportModule),
        data: {
          //title: "Resource Work Report",
          module: "resourceworkreport"
        }
      },
      {
        path: "resourceworkreport/:projectId",
        loadChildren:
          () => import('./+layout/expense/resourceworkreport/resourceworkreport.module').then(m => m.ResourceworkreportModule),
        data: {
          //title: "Resource Work Report",
          module: "resourceworkreport"
        }
      },
      {
        path: "clientvspo",
        loadChildren:
          () => import('./+layout/expense/clientvspo/clientvspo.module').then(m => m.ClientvspoModule),
        data: {
          //title: "Resource Work Report",
          module: "clientvspo"
        }
      },
      {
        path: "resourcesvspo",
        loadChildren:
          () => import('./+layout/expense/resourcesvspo/resourcesvspo.module').then(m => m.ResourcesvspoModule),
        data: {
          //title: "Resource Work Report",
          module: "resourcesvspo"
        }
      },
      {
        path: "saleorderdetails",
        loadChildren:
          () => import('./+layout/expense/saleorderdetails/saleorderdetails.module').then(m => m.SaleorderdetailsModule),
        data: {
          //title: "Resource Work Report",
          module: "saleorderdetails"
        }
      },
      {
        path: "resourceutilizationreport",
        loadChildren:
          () => import('./+layout/expense/resourceutilizationreport/resourceutilizationreport.module').then(m => m.ResourceutilizationreportModule),
        data: {
          //title: "Resource Utilization Report",
          module: "resourceutilizationreport"
        }
      },
      {
        path: "resourceCapacityPlanner",
        loadChildren:
          () => import('./+layout/expense/resourceCapacityPlanner/resourceCapacityPlanner.module').then(m => m.ResourceCapacityPlannerModule),
        data: {
          //title: "Resource Capacity Planner",
          module: "resourceCapacityPlanner"
        }
      },

      {
        path: "resourceallocationreport",
        loadChildren:
          () => import('./+layout/expense/resourceallocationreport/resourceallocationreport.module').then(m => m.ResourceallocationreportModule),
        data: {
          //title: "Resource Allocation Report",
          module: "resourceallocationreport"
        }
      },
      {
        path: "projectprofitabilityreport",
        loadChildren:
          () => import('./+layout/expense/projectprofitabilityreport/projectprofitabilityreport.module').then(m => m.ProjectprofitabilityreportModule),
        data: {
          //title: "Project Profitability Report",
          module: "projectprofitabilityreport"
        }
      },

      {
        path: "momactionreport",
        loadChildren:
          () => import('./+layout/expense/momactionreport/momactionreport.module').then(m => m.MomactionreportModule),
        data: {
          //title: "MOM Action Report",
          module: "momactionreport"
        }
      },

      {
        path: "changerequestreport",
        loadChildren:
          () => import('./+layout/expense/changerequestreport/changerequestreport.module').then(m => m.ChangerequestreportModule),
        data: {
          //title: "Change Request Report",
          module: "changerequestreport"
        }
      },
      {
        path: "projectleaveplannerreport",
        loadChildren:
          () => import('./+layout/expense/projectleaveplannerreport/projectleaveplannerreport.module').then(m => m.ProjectleaveplannerreportModule),
        data: {
          //title: "Project Leave Planner Report",
          module: "projectleaveplannerreport"
        }
      },
      {
        path: "MonthlyLeavePlan",
        loadChildren:
          () => import('./+layout/expense/MonthlyLeavePlan/MonthlyLeavePlan.module').then(m => m.MonthlyLeavePlanModule),
        data: {
          //title: "Monthly Leave Plan Report",
          module: "MonthlyLeavePlan"
        }
      },
      {
        path: "roadblockchallengereport",
        loadChildren:
          () => import('./+layout/expense/roadblockchallengesreport/roadblockchallengereport.module').then(m => m.RoadblockchallengereportModule),
        data: {
          //title: "Roadblock Challenges Report",
          module: "roadblockchallengereport"
        }
      },
      // {
      //   path: "hometest",
      //   loadChildren:
          // () => import('./+layout/expense/hometest/hometest.module').then(m => m.HometestModule),
      //   data: {
      //     //title: "Project Health Card",
      //     module: "hometest"
      //   }
      // },
      {
        path: "ProjectHealthCard",
        loadChildren:
          () => import('./+layout/expense/ProjectHealth/ProjectHealth.module').then(m => m.ProjectHealthModule),
        data: {
          //title: "Project Health Card",
          module: "ProjectHealthCard"
        }
      },
      {
        path: "phaseproject",
        loadChildren:
          () => import('./+layout/expense/phaseproject/phaseproject.module').then(m => m.PhaseprojectModule),
        data: {
          //title: "phaseproject",
          module: "phaseproject"
        }
      },
      {
        path: "expensedasboard",
        loadChildren:
          () => import('./+layout/expense/expensedasboard/expensedasboard.module').then(m => m.ExpensedasboardModule),
        data: {
          //title: "Expense Dashboard",
          module: "expensedasboard"
        }
      },
      {
        path: "rights",
        loadChildren: () => import('./+layout/expense/rights/rights.module').then(m => m.RightsModule),
        data: {
          //title: "Rights",
          module: "rights"
        }
      },
      {
        path: "projectphasedetail",
        loadChildren:
          () => import('./+layout/expense/projectphasedetail/projectphasedetail.module').then(m => m.ProjectphasedetailModule),
        data: {
          //title: "phase Detail",
          module: "projectphasedetail"
        }
      },
      {
        path: "AnalyticsReports",
        loadChildren:
          () => import('./+layout/expense/AnalyticsReports/AnalyticsReports.module').then(m => m.AnalyticsReportsModule),
        data: {
          //title: "AnalyticsReports",
          module: "AnalyticsReports"
        }
      },
      {
        path: "globalSettings",
        loadChildren:
          () => import('./+layout/expense/orgsetting/orgsetting.module').then(m => m.OrgsettingModule),
        data: {
          //title: "Orgsetting",
          module: "orgsetting"
        }
      },
      {
        path: "organisationchart",
        loadChildren:
          () => import('./+layout/expense/organisationchart/organisationchart.module').then(m => m.organisationchartModule),
        data: {
          //title: "Organisation Chart",
          module: "organisationchart"
        }
      },
      {
        path: "projectorganisationchart",
        loadChildren:
          () => import('./+layout/expense/projectorganisationchart/projectorganisationchart.module').then(m => m.projectorganisationchartModule),
        data: {
          //title: "Project Organisation Chart",
          module: "projectorganisationchart"
        }
      },
      {
        path: "expensereports",
        loadChildren:
          () => import('./+layout/expense/expensereports/expensereports.module').then(m => m.ExpensereportsModule),
        data: {
          //title: "Expense Reports",
          module: "expensereports"
        }
      },
      {
        path: "leavereports",
        loadChildren:
          () => import('./+layout/expense/leavereports/leavereports.module').then(m => m.LeavereportsModule),
        data: {
          //title: "Leave Reports",
          module: "leavereports"
        }
      },
      {
        path: "projectreports",
        loadChildren:
          () => import('./+layout/expense/projectreports/projectreports.module').then(m => m.ProjectreportsModule),
        data: {
          //title: "Project Reports",
          module: "projectreports"
        }
      },
      {
        path: "managementreports",
        loadChildren:
          () => import('./+layout/expense/managementreports/managementreports.module').then(m => m.ManagementreportsModule),
        data: {
          //title: "Management Reports",
          module: "managementreports"
        }
      },
      {
        path: "businessreports",
        loadChildren:
          () => import('./+layout/expense/businessreports/businessreports.module').then(m => m.BusinessreportsModule),
        data: {
          //title: "Business Reports",
          module: "businessreports"
        }
      },
      {
        path: "organizationReports",
        loadChildren:
          () => import('./+layout/expense/organizationReports/organizationReports.module').then(m => m.organizationReports),
        data: {
          //title: "Business Reports",
          module: "businessreports"
        }
      },
      {
        path: "exitEmployeesList",
        loadChildren:
          () => import('./+layout/expense/organizationReports/exitEmployeesList/exitEmployeesList.module').then(m => m.ExitEmployeesListReports),
        data: {
          //title: "Business Reports",
          module: "exitEmployeesList"
        }
      },
      {
        path: "leavesDetails",
        loadChildren:
          () => import('./+layout/expense/organizationReports/LeavesDetails/leavesDetails.module').then(m => m.LeavesDetailsReports),
        data: {
          //title: "Business Reports",
          module: "leavesDetails"
        }
      },
      {
        path: "timeDetails",
        loadChildren:
          () => import('./+layout/expense/organizationReports/timeDetails/timeDetails.module').then(m => m.TimeDetailsReports),
        data: {
          //title: "Business Reports",
          module: "timeDetails"
        }
      },
      {
        path: "projectOff-Boarded",
        loadChildren:
          () => import('./+layout/expense/organizationReports/projectOff-Boarded/projectOff-Boarded.module').then(m => m.ProjectOffBoardedReports),
        data: {
          //title: "Business Reports",
          module: "projectOff-Boarded"
        }
      },
      {
        path: "projectsOn-Boarded",
        loadChildren:
          () => import('./+layout/expense/organizationReports/projectsOn-Boarded/projectsOn-Boarded.module').then(m => m.ProjectsOnBoardedReports),
        data: {
          //title: "Business Reports",
          module: "projectsOn-Boarded"
        }
      },
      {
        path: "usersOff-Boarded",
        loadChildren:
          () => import('./+layout/expense/organizationReports/usersOff-Boarded/usersOff-Boarded.module').then(m => m.UsersOffBoardedReports),
        data: {
          //title: "Business Reports",
          module: "usersOff-Boarded"
        }
      },
      {
        path: "usersOn-Boarded",
        loadChildren:
          () => import('./+layout/expense/organizationReports/usersOn-Boarded/usersOn-Boarded.module').then(m => m.usersOnBoardedReports),
        data: {
          //title: "Business Reports",
          module: "usersOn-Boarded"
        }
      },
      {
        path: "contactsupport",
        loadChildren:
          () => import('./+layout/expense/contactsupport/contactsupport.module').then(m => m.ContactsupportModule),
        data: {
          //title: "Contact Support",
          module: "contactsupport"
        }
      },
      {
        path: "integration",
        loadChildren:
          () => import('./+layout/expense/integration/integration.module').then(m => m.IntegrationModule),
        data: {
          //title: "Integration",
          module: "integration"
        }
      },
      {
        path: "settings",
        loadChildren:
          () => import('./+layout/expense/settings/settings.module').then(m => m.SettingsModule),
        data: {
          //title: "Settings",
          module: "settings"
        }
      },
      {
        path: "engagementmodel",
        loadChildren:
          () => import('./+layout/engagementmodel/engagementmodel.module').then(m => m.EngagementmodelModule),
        data: {
          //title: "Engagement Model",
          module: "engagementmodel"
        }
      },
      {
        path: "projectdocument",
        loadChildren:
          () => import('./+layout/expense/projectdocument/projectdocument.module').then(m => m.ProjectdocumentModule),
        data: {
          //title: "Project Document",
          module: "projectdocument"
        }
      }
      ,
      {
        path: "workorderdetails",
        loadChildren:
          () => import('./+layout/expense/workorderdetails/workorderdetails.module').then(m => m.WorkOrderDetailsModule),
        data: {
          //title: "Project Document",
          module: "workorderdetails"
        }
      }
      ,
      {
        path: "workorderReport",
        loadChildren:
          () => import('./+layout/expense/workorderReport/workorderReport.module').then(m => m.WorkOrderReportModule),
        data: {
          //title: "workorderReport",
          module: "workorderReport"
        }
      }
      ,
      {
        path: "customreport",
        loadChildren:
          () => import('./+layout/expense/customreport/customreport.module').then(m => m.CustomReportModule),
        data: {
          //title: "workorderReport",
          module: "customreport"
        }
      }
      ,
      {
        path: "pmssupport",
        loadChildren:
          () => import('./+layout/expense/pmssupport/pmssupport.module').then(m => m.PmssupportModule),
        data: {
          //title: "workorderReport",
          module: "pmssupport"
        }
      },
      {
        path: "monthlyreport",
        loadChildren:
          () => import('./+layout/expense/monthlyreport/monthlyreport.module').then(m => m.MonthlyreportModule),
        data: {
          //title: "monthlyreport",
          module: "monthlyreport"
        }
      },
      {
        path: "MonthlyNonWorkingReport",
        loadChildren:
          () => import('./+layout/expense/monthlyNonWorkingreport/monthlyNonWorkingreport.module').then(m => m.MonthlyNonWorkingreportModule),
        data: {
          //title: "MonthlyNonWorkingreport",
          module: "MonthlyNonWorkingreport"
        }
      },
      {
        path: "invoice/:id",
        loadChildren:
          () => import('./+layout/expense/invoice/invoice.module').then(m => m.InvoiceModule),
        data: {
          //title: "invoice",
          module: "invoice"
        }
      }
      ,
      {
        path: "invoiceReport/:id",
        loadChildren:
          () => import('./+layout/expense/invoiceReport/invoiceReport.module').then(m => m.InvoiceReportModule),
        data: {
          //title: "invoiceReport",
          module: "invoiceReport"
        }
      },
      {
        path: "TrainingRequirement",
        loadChildren:
          () => import('./+layout/expense/TrainingRerquirement/TrainingRerquirement.module').then(m => m.TrainingRerquirementModule),
        data: {
          //title: "TrainingRequirement",
          module: "TrainingRequirement"
        }
      },
      {
        path: "clientholiday",
        loadChildren:
          () => import('./+layout/leave/clientholiday/clientholiday.module').then(m => m.ClientholidayModule),
        data: {
          //title: "Client Holiday",
          module: "clientholiday"
        }
      }
      ,
      {
        path: "projectsummary",
        loadChildren:
          () => import('./+layout/expense/projectsummary/projectsummary.module').then(m => m.ProjectsummaryModule),
        data: {
          //title: "Project Summary",
          module: "projectsummary"
        }
      }
      ,
      {
        path: "timecorrection",
        loadChildren:
          () => import('./+layout/expense/timecorrection/timecorrection.module').then(m => m.TimecorrectionModule),
        data: {
          //title: "Project Summary",
          module: "timecorrection"
        }
      }
      ,
      {
        path: "timecorrectionapproval",
        loadChildren:
          () => import('./+layout/expense/timecorrectionapproval/timecorrectionapproval.module').then(m => m.TimecorrectionapprovalModule),
        data: {
          //title: "Project Summary",
          module: "timecorrection"
        }
      } ,
      {
        path: "entrygrid",
        loadChildren:
          () => import('./+layout/expense/entrygrid/entrygrid.module').then(m => m.EntrygridModule),
        data: {
          //title: "Project Summary",
          module: "entrygrid"
        }
      }
      ,


    ]
  },
  { path: "**", redirectTo: "errors/404", pathMatch: "full" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
