import { Component, OnInit, ViewChildren, ViewChild, QueryList, } from '@angular/core';
import { LeaveService } from "../../leave.service";
import { CommonService } from "src/app/common.service";
import { AuthService } from "src/app/services/auth.service";
import { UserService } from "src/app/user/user.service";
import { BsDatepickerConfig } from "ngx-bootstrap";
import { DepartmentService } from "src/app/+layout/department/department.service";
import { DesignationService } from "src/app/+layout/designation/designation.service";
import { LocationService } from "src/app/+layout/location/location.service";
import { SelectComponent } from "ng2-select/ng2-select";
import { GridComponent, SaveEventArgs, Column, ExcelExportProperties } from '@syncfusion/ej2-angular-grids';
import { ColumnModel, ResizeService, QueryCellInfoEventArgs } from '@syncfusion/ej2-angular-grids';
import { environment } from 'src/app/environment';
import { data } from 'jquery';
import { Tooltip } from '@syncfusion/ej2-popups';


@Component({
  selector: 'app-user-availability-report',
  templateUrl: './user-availability-report.component.html',
  styleUrls: ['./user-availability-report.component.css']
})
export class UserAvailabilityReportComponent implements OnInit {

  datePickerConfiq: Partial<BsDatepickerConfig>;
  CmpCode: any;
  userLoader: boolean;
  currentUserId: any;

  navigationText: any;

  flterShowData = false;

  filterPeriod: number;

  filterStartDate: Date;
  filterEndDate: Date;
  minDate: any;
  maxDate: any;

  // For User Dropdownlist
  filterDepartmentId: any;
  DepartmentList: any[];
  ddlDepartmentList: any[];
  ActiveDepartments = [];

  filterDesignationId: any;
  DesignationList: any[];
  ddlDesignationList: any[];
  ActiveDesignations = [];

  filterLocationId: any;
  LocationList: any[];
  ddlLocationList: any[];
  ActiveLocations = [];

  datesList: any[];
  employeeDataList: any[];
  public columns: ColumnModel[];

  LeaveTypeList: any[];

  monthNamesList = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  filterUserStatus: number = null;

  @ViewChildren(SelectComponent) selectElements: QueryList<any>;

  constructor(private _leaveService: LeaveService,
    private _commonSer: CommonService,
    private _authSer: AuthService,
    private _depratmentSer: DepartmentService,
    private _desigation: DesignationService,
    private _locationSer: LocationService) {
    this.CmpCode = this._authSer.getcompanyCode();
  }

  @ViewChild('overviewgrid') public Grid: GridComponent;

  load() {
    const rowHeight: number = this.Grid.getRowHeight();  // height of the each row
    const gridHeight: any = this.Grid.height;  // grid height
    const pageSize: number = this.Grid.pageSettings.pageSize;   // initial page size
    const pageResize: any = (gridHeight - (pageSize * rowHeight)) / rowHeight; // new page size is obtained here
    this.Grid.pageSettings.pageSize = pageSize + Math.round(pageResize);
  }
  ngOnInit() {
    this.CmpCode = this._authSer.getcompanyCode();
    this._authSer.currentUser.subscribe((res) => {
      this.currentUserId = res.userId;
    });
    this.filterSettings = { type: "Menu" };
    this.filter = { type: "CheckBox" };

    this.datesList = [];
    this.employeeDataList = [];

    this.filterPeriod = 6;
    let currentDate = new Date();
    this.filterStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    this.filterEndDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

    this.minDate = this.filterStartDate;
    this.minDate.setDate(this.minDate.getDate());

    this.maxDate = this.filterEndDate;
    this.maxDate.setDate(this.maxDate.getDate());

    this.setNavigationText();

    this.getUserAvailabilityReport();
    this.getAllLeaveTypes();
  }

  getCSharpFormattedDate(val: any) {
    if (val === null || val === "") {
      return null;
    }
    const date = new Date(val);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return month + "-" + day + "-" + year;
  }

  filterShow() {
    this.getDepartmentList();
    this.getAllDesigationList();
    this.getAllLocationList();
    this.flterShowData = !this.flterShowData;
  }
  closeFilter() {
    this.flterShowData = false;
  }

  ddlSelection(value: any, placeHolder: any) {
    try {
      switch (placeHolder) {
        case "Department":
          this.ActiveDepartments = [];
          this.ActiveDepartments.push(value);
          this.filterDepartmentId = value.id;
          break;
        case "Desigation":
          this.ActiveDesignations = [];
          this.ActiveDesignations.push(value);
          this.filterDesignationId = value.id;
          break;
        case "Location":
          this.ActiveLocations = [];
          this.ActiveLocations.push(value);
          this.filterLocationId = value.id;
          break;
      }
    } catch (ex) {
      console.log(ex);
    }
  }
  removeFilterDropdownValue(value, _type) {
    try {

      switch (_type) {
        case "Department":
          this.ActiveDepartments = [];
          this.filterDepartmentId = null;
          break;
        case "Desigation":
          this.ActiveDesignations = [];
          this.filterDesignationId = null;
          break;
        case "Location":
          this.ActiveLocations = [];
          this.filterLocationId = null;
          break;
      }
    } catch (ex) {
      console.log(ex);
    }
  }

  getDepartmentList() {
    let parms = {
      Condition: "",
      FieldName: "CreatedDate",
      NoOfRecords: 10000,
      PageNo: 1,
      SortType: "desc",
      CmpCode: this.CmpCode
    };
    this._depratmentSer.getDepartmentList(parms).subscribe((res) => {
      this.DepartmentList = res["data"].table;
      this.ddlDepartmentList = this._commonSer.SetDDlDropdown(res["data"].table, "departmentId", "departmentName");
    }, error => {
      this.userLoader = false;
      console.log(error);
    });
  }

  getAllDesigationList() {
    let parms = {
      Condition: "",
      FieldName: "CreatedDate",
      NoOfRecords: 10000,
      PageNo: 1,
      SortType: "desc",
      CmpCode: this.CmpCode
    };
    this._desigation.getDesignationList(parms).subscribe((res) => {
      this.DesignationList = res["data"].table;
      this.ddlDesignationList = this._commonSer.SetDDlDropdown(res["data"].table, "designationId", "designationName");
    }, error => {
      this.userLoader = false;
      console.log(error);
    });
  }
  tooltip(args: QueryCellInfoEventArgs) {
    debugger
    const tooltip: Tooltip = new Tooltip({
        content: args.data[args.column.field]
    }, args.cell as HTMLTableCellElement);
  }

  getAllLocationList() {
    let parms = {
      Condition: "",
      FieldName: "CreatedDate",
      NoOfRecords: 10000,
      PageNo: 1,
      SortType: "desc",
      CmpCode: this.CmpCode
    };
    this._locationSer.getlocationlist(parms).subscribe((res) => {
      this.LocationList = res["data"].table;
      this.ddlLocationList = this._commonSer.SetDDlDropdown(res["data"].table, "locationId", "locationName");
    }, error => {
      this.userLoader = false;
      console.log(error);
    });
  }

  processFilters() {
    this.flterShowData = false;
    this.setNavigationText();
    this.getUserAvailabilityReport();
  }

  resetFilters() {
    this.ActiveDepartments = [];
    this.filterDepartmentId = null;
    this.ActiveDesignations = [];
    this.filterDesignationId = null;
    this.ActiveLocations = [];
    this.filterLocationId = null;

    this.filterPeriod = 6;
    let currentDate = new Date();
    this.filterStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    this.filterEndDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

    this.setNavigationText();
  }

  onChangeFilterPeriod(e) {
    let _period = e.srcElement.value;
    let currentDate = new Date();

    if (_period == 1) { // Yesterday
      currentDate.setDate(currentDate.getDate() - 1);
      this.filterStartDate = currentDate;
      this.filterEndDate = currentDate;
    } else if (_period == 2) { // Today
      this.filterStartDate = currentDate;
      this.filterEndDate = currentDate;
    } else if (_period == 3) { // Last Week
      let first = (currentDate.getDate() - currentDate.getDay()) - 7;
      let last = first + 6;

      this.filterStartDate = new Date(currentDate.setDate(first));
      this.filterEndDate = new Date(currentDate.setDate(last));
    } else if (_period == 4) { // This Week
      let first = currentDate.getDate() - currentDate.getDay();
      let last = first + 6;

      this.filterStartDate = new Date(currentDate.setDate(first));
      this.filterEndDate = new Date(currentDate.setDate(last));
    } else if (_period == 5) { // Last Month
      currentDate.setMonth(currentDate.getMonth() - 1);
      this.filterStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      this.filterEndDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    } else if (_period == 6) { // This Month
      this.filterStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      this.filterEndDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    }

    this.setNavigationText();
  }

  actionComplete(args: SaveEventArgs) {
    debugger;
    //   if (args.type == "actionComplete") {
    //     var date = new Date(this.filterStartDate)
    //     var aa = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    //     var d = aa.getDate()
    //     var monthNumber = aa.getDate() + 1 //29
    //     var monthNumbers = aa.getDate() + 2 //30
    //     var monthNumbert = aa.getDate() + 3 //31

    //     if (d == 28) {
    //       var number = monthNumber.toString()
    //       var number1 = monthNumbers.toString()
    //       var number2 = monthNumbert.toString()
    //       this.Grid.getColumnByField(number).visible = false;
    //       this.Grid.getColumnByField(number1).visible = false;
    //       this.Grid.getColumnByField(number2).visible = false;
    //       this.Grid.refreshColumns();

    //     }

    //     if (d == 29) {
    //       var number = monthNumber.toString()
    //       var number1 = monthNumbers.toString()
    //       this.Grid.getColumnByField(number).visible = false;
    //       this.Grid.getColumnByField(number1).visible = false;
    //       this.Grid.refreshColumns();

    //     }

    //     if (d == 30) {
    //       var number = monthNumber.toString()
    //       this.Grid.getColumnByField(number).visible = false;
    //       this.Grid.refreshColumns();


    //    }
    //    if(d == 31)
    //    {
    //      this.Grid.getColumnByField('29').visible = true;
    //      this.Grid.getColumnByField('30').visible = true;
    //      this.Grid.getColumnByField('31').visible = true;
    //      this.Grid.refreshColumns();

    //    }
    //  }

  }

  // Close Opened Dropdowns when clicking on new dropdown
  closeDropdownList(element) {
    if (element.optionsOpened == true) {
      let elementsToclose = this.selectElements.filter(function (el: any) {
        return (el != element && el.optionsOpened == true);
      });
      elementsToclose.forEach(function (e: SelectComponent) {
        e.clickedOutside();
      });
    }
  }

  // Get Report
  columnArray: any = []
  employeeName: any
  newTotalArray: any = []
  ejsGridData: any = []
  newArrayGrid: any = []
  public pageSettings = { pageCount: 3 };
  public filter: Object;
  public filterSettings: Object;
  public selectionSettings: Object;
  userImag: any
  genders: any
  employeeId: any
  getUserAvailabilityReport() {
    this.userLoader = true;
    let parms = {
      Period: this.filterPeriod,
      StartDate: this.getCSharpFormattedDate(this.filterStartDate),
      EndDate: this.getCSharpFormattedDate(this.filterEndDate),
      FilterDepartment: this.filterDepartmentId !== undefined ? this.filterDepartmentId : null,
      FilterDesignation: this.filterDesignationId !== undefined ? this.filterDesignationId : null,
      FilterLocation: this.filterLocationId !== undefined ? this.filterLocationId : null,
      FilterUserStatus: this.filterUserStatus !== undefined ? this.filterUserStatus : null,
      UserId: this.currentUserId,
      CmpCode: this.CmpCode
    };
    this._leaveService.getUserAvailabilityReport(parms).subscribe((res) => {
      debugger
      document.getElementById('saveClick').click()
      this.employeeDataList = res["data"];
      this.datesList = res["datesList"];
      console.log(this.employeeDataList, 'this.employee data list')
      console.log(this.datesList, 'this.employee data list')
      this.userLoader = false;
      this.ejsGridData = []
      this.columnArray = []
      this.employeeName = 'employeeName'
      this.userImag = "userImage"
      this.genders = "genders"
      this.employeeId = "employeeId"
      this.columnArray.push(this.employeeName)
      this.columnArray.push(this.userImag)
      this.columnArray.push(this.employeeId)
      this.columnArray.push(this.genders)



      for (var i = 0; i < this.datesList.length; i++) {
        var dat = this.datesList[i].dateString
        var chk = dat.split(' ')
        this.columnArray.push(chk[0])
      }

      for (var i = 0; i < this.employeeDataList.length; i++) {
        for (var j = 0; j < this.columnArray.length; j++) {
          var key = this.columnArray[j]
          if (j == 0) {
            var data = this.employeeDataList[i].employeeName
            if (data == "Katy Watson ") {
              debugger
            }

          }
          else if (j == 1) {
            var data = this.employeeDataList[i].userImage
          }
          else if (j == 2) {
            var data = this.employeeDataList[i].empCode
          }
          else if (j == 3) {
            var data = this.employeeDataList[i].genders
          }
          else {
            var data = this.employeeDataList[i].dateList[j - 4].text
          }
          this.ejsGridData.push({ [key]: data })

        }

      }

      debugger;

      this.newArrayGrid = []
      for (var j = 0; j < this.ejsGridData.length; j++) {
        if (this.columnArray.length == 35) {
          var d = this.collect(this.ejsGridData[j + 0], this.ejsGridData[j + 1], this.ejsGridData[j + 2], this.ejsGridData[j + 3], this.ejsGridData[j + 4],
            this.ejsGridData[j + 5], this.ejsGridData[j + 6], this.ejsGridData[j + 7], this.ejsGridData[j + 8],
            this.ejsGridData[j + 9], this.ejsGridData[j + 10], this.ejsGridData[j + 11], this.ejsGridData[j + 12], this.ejsGridData[j + 13],
            this.ejsGridData[j + 14], this.ejsGridData[j + 15], this.ejsGridData[j + 16], this.ejsGridData[j + 17],
            this.ejsGridData[j + 18], this.ejsGridData[j + 19], this.ejsGridData[j + 20], this.ejsGridData[j + 21], this.ejsGridData[j + 22],
            this.ejsGridData[j + 23], this.ejsGridData[j + 24], this.ejsGridData[j + 25], this.ejsGridData[j + 26],
            this.ejsGridData[j + 28], this.ejsGridData[j + 29], this.ejsGridData[j + 30], this.ejsGridData[j + 31], this.ejsGridData[j + 32], this.ejsGridData[j + 33], this.ejsGridData[j + 34]
          )
          this.newArrayGrid.push(d)
          // break;
          j = j + 34
        }
        else if (this.columnArray.length == 34) {
          var d = this.collect(this.ejsGridData[j + 0], this.ejsGridData[j + 1], this.ejsGridData[j + 2], this.ejsGridData[j + 3], this.ejsGridData[j + 4],
            this.ejsGridData[j + 5], this.ejsGridData[j + 6], this.ejsGridData[j + 7], this.ejsGridData[j + 8],
            this.ejsGridData[j + 9], this.ejsGridData[j + 10], this.ejsGridData[j + 11], this.ejsGridData[j + 12], this.ejsGridData[j + 13],
            this.ejsGridData[j + 14], this.ejsGridData[j + 15], this.ejsGridData[j + 16], this.ejsGridData[j + 17],
            this.ejsGridData[j + 18], this.ejsGridData[j + 19], this.ejsGridData[j + 20], this.ejsGridData[j + 21], this.ejsGridData[j + 22],
            this.ejsGridData[j + 23], this.ejsGridData[j + 24], this.ejsGridData[j + 25], this.ejsGridData[j + 26],
            this.ejsGridData[j + 28], this.ejsGridData[j + 29], this.ejsGridData[j + 30], this.ejsGridData[j + 31], this.ejsGridData[j + 32], this.ejsGridData[j + 33]
          )
          this.newArrayGrid.push(d)
          // break;
          j = j + 33
        }
        else if (this.columnArray.length == 33) {
          var d = this.collect(this.ejsGridData[j + 0], this.ejsGridData[j + 1], this.ejsGridData[j + 2], this.ejsGridData[j + 3], this.ejsGridData[j + 4],
            this.ejsGridData[j + 5], this.ejsGridData[j + 6], this.ejsGridData[j + 7], this.ejsGridData[j + 8],
            this.ejsGridData[j + 9], this.ejsGridData[j + 10], this.ejsGridData[j + 11], this.ejsGridData[j + 12], this.ejsGridData[j + 13],
            this.ejsGridData[j + 14], this.ejsGridData[j + 15], this.ejsGridData[j + 16], this.ejsGridData[j + 17],
            this.ejsGridData[j + 18], this.ejsGridData[j + 19], this.ejsGridData[j + 20], this.ejsGridData[j + 21], this.ejsGridData[j + 22],
            this.ejsGridData[j + 23], this.ejsGridData[j + 24], this.ejsGridData[j + 25], this.ejsGridData[j + 26],
            this.ejsGridData[j + 28], this.ejsGridData[j + 29], this.ejsGridData[j + 30], this.ejsGridData[j + 31], this.ejsGridData[j + 32]
          )
          this.newArrayGrid.push(d)
          // break;
          j = j + 32
        }
        else if (this.columnArray.length == 32) {
          var d = this.collect(this.ejsGridData[j + 0], this.ejsGridData[j + 1], this.ejsGridData[j + 2], this.ejsGridData[j + 3], this.ejsGridData[j + 4],
            this.ejsGridData[j + 5], this.ejsGridData[j + 6], this.ejsGridData[j + 7], this.ejsGridData[j + 8],
            this.ejsGridData[j + 9], this.ejsGridData[j + 10], this.ejsGridData[j + 11], this.ejsGridData[j + 12], this.ejsGridData[j + 13],
            this.ejsGridData[j + 14], this.ejsGridData[j + 15], this.ejsGridData[j + 16], this.ejsGridData[j + 17],
            this.ejsGridData[j + 18], this.ejsGridData[j + 19], this.ejsGridData[j + 20], this.ejsGridData[j + 21], this.ejsGridData[j + 22],
            this.ejsGridData[j + 23], this.ejsGridData[j + 24], this.ejsGridData[j + 25], this.ejsGridData[j + 26],
            this.ejsGridData[j + 28], this.ejsGridData[j + 29], this.ejsGridData[j + 30], this.ejsGridData[j + 31]
          )
          this.newArrayGrid.push(d)
          // break;
          j = j + 31
        }
      }
      if (this.columnArray.length == 32) {
        let test = ['29', '30', '31']
        this.Grid.showColumns(test)
      }
      else if (this.columnArray.length == 31) {
        this.Grid.refreshColumns()
        let test = ['31']
        this.Grid.hideColumns(test);

      }
      else if (this.columnArray.length == 30) {
        this.Grid.refreshColumns()
        let test = ['30', '31']
        this.Grid.hideColumns(test);

      }
      else if (this.columnArray.length == 29) {
        this.Grid.refreshColumns()
        let test = ['29', '30', '31']
        this.Grid.hideColumns(test);

      }



      //   setTimeout(() => {

      //     this.newArrayGrid

      // }, 3000);

      // var obj = {};

      //     for(var j=0;j<this.ejsGridData.length;j++)
      // { 
      //    obj={}
      //   for(var k=0;k<=this.datesList.length;k++)
      //   {
      //     obj[k]=this.ejsGridData[j+k]
      //   }
      //   var d=this.collect(obj
      //     )

      //     this.newArray.push(d)

      //     j=j+this.datesList.length
      // }
      // this.Grid.refresh()
      console.log(this.newArrayGrid, 'new Array')
      console.log(this.employeeDataList);
    }, error => {
      this.userLoader = false;
      console.log(error);
    });
  }
  collect(...a) {
    var ret = {};
    var len = arguments.length;
    for (var i = 0; i < len; i++) {
      for (var p in arguments[i]) {
        if (arguments[i].hasOwnProperty(p)) {
          ret[p] = arguments[i][p];
        }
      }

    }
    return ret;
  }
  getAllLeaveTypes() {
    this.userLoader = true;
    let parms = {
      PageNo: 1,
      NoOfRecords: 100000,
      FilterStatus: null,
      FilterEmployees: null,
      FilterDepartments: null,
      FilterLocations: null,
      FilterDesignations: null,
      FilterRoles: null,
      FilterUnit: null,
      CmpCode: this.CmpCode
    };
    this._leaveService.getAllLeaveTypeData(parms).subscribe((res) => {
      this.LeaveTypeList = res["data"].table;
      this.userLoader = false;
    });
  }

  export(): void {
    debugger
    const excelExportProperties: ExcelExportProperties = {
      fileName: 'user-availability-report.xlsx'
    };
    // this.UpdateForExport();
    //this.getLocationList();
    this.Grid.excelExport(excelExportProperties);
  }

  printList(printData) {
    debugger
    let printContents = document.getElementById(printData).innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    location.reload();
  }

  exportTableToCSV() {
    debugger
    const excelExportProperties: ExcelExportProperties = {
      fileName: 'user-availability-report.csv'
    };
    this.Grid.csvExport(excelExportProperties);

  }

  onDPStartDateChange(e) {
    this.filterStartDate = e;
    this.minDate = new Date(this.filterStartDate);
    this.minDate.setDate(this.minDate.getDate());
  }
  onDPEndDateChange(e) {
    this.filterEndDate = e;
    this.maxDate = new Date(this.filterEndDate);
    this.maxDate.setDate(this.maxDate.getDate());

    this.validateDateRange();
  }

  validateDateRange() {
    if (this.filterStartDate != null && this.filterEndDate != null) {
      setTimeout(() => {
        let startDate = new Date(this.filterStartDate);
        let endDate = new Date(this.filterEndDate);
        let diffDays = this.datediff(startDate, endDate);

        if (diffDays > 30) {
          let currentDate = new Date();
          this.filterStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
          this.filterEndDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        }
      }, 300);
    }
  }

  // Navigation
  setNavigationText() {
    let vm = this;
    vm.navigationText = "";

    let startDateString = vm.filterStartDate.getDate() + " " + vm.monthNamesList[vm.filterStartDate.getMonth()] + " " + vm.filterStartDate.getFullYear() + "";
    let endDateString = vm.filterEndDate.getDate() + " " + vm.monthNamesList[vm.filterEndDate.getMonth()] + " " + vm.filterEndDate.getFullYear() + "";

    vm.navigationText = startDateString + " - " + endDateString;
  }

  datediff(first, second) {
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
  }

  navigatePrevious(e) {
    let vm = this;
    let _period = vm.filterPeriod;
    vm.filterPeriod = 7;

    let startDate = new Date(vm.filterStartDate);
    let endDate = new Date(vm.filterEndDate);
    // endDate.setDate(endDate.getDate() + 1);
    // endDate.setSeconds(endDate.getSeconds() - 1);
    let diffDays = this.datediff(startDate, endDate);

    let tempStartDate = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
    let tempEndDate = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0);
    let tempDiffDays = this.datediff(tempStartDate, tempEndDate);

    if (diffDays == tempDiffDays) {
      startDate.setMonth(startDate.getMonth() - 1);
      startDate = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
      endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
    } else {
      startDate.setDate(startDate.getDate() - (diffDays + 1));
      endDate.setDate(endDate.getDate() - (diffDays + 1));
    }

    this.filterStartDate = startDate;
    this.filterEndDate = endDate;

    vm.setNavigationText();
    vm.getUserAvailabilityReport();
  }

  navigateNext(e) {
    let vm = this;
    let _period = vm.filterPeriod;
    vm.filterPeriod = 7;

    let startDate = new Date(vm.filterStartDate);
    let endDate = new Date(vm.filterEndDate);
    // endDate.setDate(endDate.getDate() + 1);
    // endDate.setSeconds(endDate.getSeconds() - 1);
    let diffDays = this.datediff(startDate, endDate);

    let tempStartDate = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
    let tempEndDate = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0);
    let tempDiffDays = this.datediff(tempStartDate, tempEndDate);

    if (diffDays == tempDiffDays) {
      startDate.setMonth(startDate.getMonth() + 1);
      startDate = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
      endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
    } else {
      startDate.setDate(startDate.getDate() + (diffDays + 1));
      endDate.setDate(endDate.getDate() + (diffDays + 1));
    }

    this.filterStartDate = startDate;
    this.filterEndDate = endDate;

    vm.setNavigationText();
    vm.getUserAvailabilityReport();
  }

  // Export
  ExportExcel() {
    this.userLoader = true;
    let parms = {
      Period: this.filterPeriod,
      StartDate: this.getCSharpFormattedDate(this.filterStartDate),
      EndDate: this.getCSharpFormattedDate(this.filterEndDate),
      FilterDepartment: this.filterDepartmentId !== undefined ? this.filterDepartmentId : null,
      FilterDesignation: this.filterDesignationId !== undefined ? this.filterDesignationId : null,
      FilterLocation: this.filterLocationId !== undefined ? this.filterLocationId : null,
      FilterUserStatus: this.filterUserStatus !== undefined ? this.filterUserStatus : null,
      UserId: this.currentUserId,
      CmpCode: this.CmpCode
    };
    this._leaveService.getUserAvailabilityReport_Excel(parms).subscribe((res) => {
      let exportData = res["data"];
      this.userLoader = false;
      console.clear();
      console.log(exportData);
      this._commonSer.exportAsExcelFile(exportData, 'User Availability Report');
    }, error => {
      this.userLoader = false;
      console.log(error);
    });
  }

  ExportCSV() {
    this.userLoader = true;
    let parms = {
      Period: this.filterPeriod,
      StartDate: this.getCSharpFormattedDate(this.filterStartDate),
      EndDate: this.getCSharpFormattedDate(this.filterEndDate),
      FilterDepartment: this.filterDepartmentId !== undefined ? this.filterDepartmentId : null,
      FilterDesignation: this.filterDesignationId !== undefined ? this.filterDesignationId : null,
      FilterLocation: this.filterLocationId !== undefined ? this.filterLocationId : null,
      FilterUserStatus: this.filterUserStatus !== undefined ? this.filterUserStatus : null,
      UserId: this.currentUserId,
      CmpCode: this.CmpCode
    };
    this._leaveService.getUserAvailabilityReport_Excel(parms).subscribe((res) => {
      let exportData = res["data"];
      this.userLoader = false;
      console.clear();
      console.log(exportData);
      this._commonSer.exportAsExcelAsCsvFile(exportData, 'User Availability Report');
    }, error => {
      this.userLoader = false;
      console.log(error);
    });
  }
  recieptIMg: any
  userImage(item) {
    debugger;
    // <img src="/assets/img/Logo/male.png" style="width: 190px;">
    //if(data.e)
    if (item.userImage == '' || item.userImage == undefined || item.userImage == null) {
      // this.recieptIMg = `${environment.siteUrl}` + "Uploads/Icon/UploadIcons.png";
      if (item.genders == "Female") {
        this.recieptIMg = "/assets/img/Logo/female.png"
      }
      else {
        this.recieptIMg = "/assets/img/Logo/male.png"
      }
    }
    else {
      this.recieptIMg = `${environment.siteUrl}` + item.userImage;
    }
    return this.recieptIMg
  }

}
