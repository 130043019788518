import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';

import { BoxModule, TabsModule, DropdownModule } from 'angular-admin-lte';

import { HeaderInnerComponent } from './header-inner/header-inner.component';
import { SidebarLeftInnerComponent } from './sidebar-left-inner/sidebar-left-inner.component';
import { SidebarRightInnerComponent } from './sidebar-right-inner/sidebar-right-inner.component';
import { UploaderModule } from '@syncfusion/ej2-angular-inputs';
import { BarRatingModule } from 'ngx-bar-rating'
import { NgxPayPalModule } from 'ngx-paypal';
import {SelectModule} from 'ng2-select';
import { DropDownListAllModule,DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpModule,
    NgxPayPalModule,
    SelectModule,
    DropdownModule,
    BarRatingModule,
    TabsModule,
    BoxModule,
    UploaderModule,
    DropDownListModule
  ],
  declarations: [HeaderInnerComponent, SidebarLeftInnerComponent, SidebarRightInnerComponent],
  exports: [BoxModule, TabsModule, HeaderInnerComponent, SidebarLeftInnerComponent, SidebarRightInnerComponent]
})
export class CoreModule { }
