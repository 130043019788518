import {
    Component, OnInit, QueryList,
    ViewChildren,
    Directive,
    Input,
    Output,
    EventEmitter,
    ElementRef, ViewChild
} from '@angular/core';
import { LeaveService } from "../leave.service";
import { FormGroup, FormBuilder, Validators,FormControl } from "@angular/forms";
import { AuthService } from "src/app/services/auth.service";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { CommonService } from "src/app/common.service";
import { Permission } from 'src/app/permission-keyword';
import { environment } from "src/app/environment";
import { Router,ActivatedRoute } from '@angular/router';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { DatePipe } from '@angular/common';
import { ToasterService } from '../../expense/toaster/toaster.service';
import { ExpenseHttpService } from 'src/app/services/expense/expense-http.service';
import { TextSearchColorSettings } from '@syncfusion/ej2-angular-pdfviewer';
import { isBuffer } from 'lodash';
import { ElementSchemaRegistry } from '@angular/compiler';


interface RequestsApprovalsSorting {
    requestId: any;
    empCode: any;
    employeeName: any;
    status: any;
    createdDate: any;
    requestType: string;
}

interface RequestsApprovals {
    isSelected: boolean;
    requestId: any;
    empCode: any;
    employeeName: any;
    statusId: any;
    status: any;
    createdDate: any;
    requestType: string;
    isCurrentWorkflow: boolean;
}

interface LeavesDetails {
    leaveId: any;
    employeeId: any;
    empCode: string;
    empName: string;
    leaveTypeId: any;
    leaveTypeName: string;
    type: string;
    dayHoursTaken: string;
    leaveStartDate: any;
    leaveEndDate: any;
    totalDays: any;
    leaveReason: string;
    statusId: any;
    status: string;
    financialYearId: any;
    financialYear: string;
    createdBy: any;
    createdDate: any;
    updatedBy: any;
    updatedDate: any;
    filePaths: any;
}
interface LeavesApprovalDetails {
    leaveApprovalId: any;
    leaveId: any;
    employeeId: any;
    empCode: string;
    empName: string;
    statusId: any;
    status: string;
    comment: string;
    approvedBy: any;
    approvedDate: any;
    approverGroup: any;
    isCurrentWorkflow: boolean;
    approverEmpId: any;
    approverEmpCode: string;
    approverEmail: string;
    approverName: string;
    approverGroupName:string
    type:number
}
interface CompOffApprovalDetails {
    approvalId: any;
    compOffId: any;
    employeeId: any;
    empCode: string;
    empName: string;
    statusId: any;
    status: string;
    comment: string;
    approvedBy: any;
    approvedDate: any;
    approverGroup: any;
    isCurrentWorkflow: boolean;
    approverEmpId: any;
    approverEmpCode: string;
    approverEmail: string;
    approverName: string;
    approverGroupName:string
    type:number


}

export type SortColumn = keyof RequestsApprovalsSorting | "";
export type SortDirection = "asc" | "desc" | "";
const rotate: { [key: string]: SortDirection } = {
    asc: "desc",
    desc: "",
    "": "asc",
};
const compare = (v1: string, v2: string) => (v1 < v2 ? -1 : v1 > v2 ? 1 : 0);
export interface SortEvent {
    column: SortColumn;
    direction: SortDirection;
}

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: "th[sortable]",
    // tslint:disable-next-line: use-host-property-decorator
    host: {
        "[class.asc]": 'direction === "asc"',
        "[class.desc]": 'direction === "desc"',
        "(click)": "rotate()",
    },
})

// tslint:disable-next-line: directive-class-suffix
export class NgbdSortableHeader { 
    @Input() sortable: SortColumn = "";
    @Input() direction: SortDirection = "";
    @Output() sort = new EventEmitter<SortEvent>();

    rotate() {
        this.direction = rotate[this.direction];
        this.sort.emit({ column: this.sortable, direction: this.direction });
    }
}

@Component({
    selector: 'app-approval',
    templateUrl: './approval.component.html',
    styleUrls: ['./approval.component.css']
})
export class ApprovalComponent implements OnInit {
    CurrentUser: any;
    loggedUserId: any;
    isAdmin: Boolean;
    userLoader: Boolean = false;
    canPostApproveRequest: Boolean;

    myApprovalsList: RequestsApprovals[] = [];
    chkAllApprovals: Boolean = false;
    myApprovalsFilterRequestType: any = -1;
    myApprovalsFilterStatusId: any = -1;
    myApprovalsListLength: any;
    myApprovalsPage = 1;
    myApprovalsPageSize = 10;

    myRequestsList: RequestsApprovals[] = [];
    chkAllRequests: Boolean = false;
    myRequestsFilterRequestType: any = -1;
    myRequestsFilterStatusId: any = -1;
    myRequestsListLength: any;
    myRequestsPage = 1;
    myRequestsPageSize = 10;

    // For User Dropdownlist
    ddlEmployeeUserId: any;
    EmployeeList = [];
    ActiveEmployee = [];

    parms: any;

    // For Request Details & Approvals
    currentRequestType: string;
    leaveDetails: LeavesDetails;
    leaveApprovalList: LeavesApprovalDetails[] = [];
    compOffDetails: any;
    compOffApprovalList: CompOffApprovalDetails[] = [];

    leaveFull: any[];
    compOff: any[];


    requestApprovalComment: string;
    requestBulkApprovalComment: string;
    checkedLeaveRequestIds: number[] = [];
    checkedCompOffRequestIds: number[] = [];

    public showSuccess: Boolean;
    public showMessage: string;

    formImport: FormGroup;
    importType: any;
    fileToUpload: File = null;
    chckSendForApproval: Boolean = true;

    generalSettingsData: any;
    isCancelReasonMandatory: Boolean = false;
    isLeaveReasonTextboxEmpty: Boolean = false;

    apiURL: any;
    Queryuserid:any;
    CmpCode:any;

    @ViewChild('labelImport')labelImport: ElementRef;
    @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
   userId:any
    financialYearText: any;
    nextFinancialYearId: any;
    currentFinancialYearId: any;
    prevFinancialYearId: any;
    value = 0;
    constructor(private _leaveSer: LeaveService,
        private _fb: FormBuilder, private datePipe: DatePipe,
        private _authSer: AuthService,private http:ExpenseHttpService,
        private activatedRoute: ActivatedRoute, private toasterService: ToasterService,
        private _commonSer: CommonService) {
            this.CmpCode = this._authSer.getcompanyCode();
            this.Queryuserid = this.activatedRoute.snapshot.params.userId;
            this.userId=this._authSer.getUserId()
    }
    roleid:any
    name:any
    DateFormat: any;
 ngOnInit() {//Angular Lifecycle hook
    this.filterSettings = { type: "Menu" };
    this.filter = { type: "CheckBox" };
    this.CmpCode = this._authSer.getcompanyCode();
    this._authSer.currentUser.subscribe((res) => {
    this.CurrentUser = res;
    this.loggedUserId = res.userId;
    this.isAdmin = res.isAdmin;
    this.canPostApproveRequest = this.CurrentUser.permission.includes(Permission.PostApprovalRequest);
    this.roleid=this._authSer.getRoleId()
    this.name=this._authSer.getUserFullName()

    // Call after getting current userId
    this.getEmployeesListForSelect();
    this.getCurrentFinancialYear()
    this.bindRequestsList();
    this.getGeneralSettingsData();
    });

    this.apiURL = environment.siteUrl;
    this.filterSettings = { type: "Menu" };
    this.filter = { type: "CheckBox" };
    this.selectionSettings = { persistSelection: true, type: "Multiple", checkboxOnly: true };
    this.DateFormat= this._authSer.getdateFormat();
    }

    public filter: Object;
    public filterSettings: Object;
    public selectionSettings: Object;
    public height: string = '240px';
    public fields: Object = { text: 'text', value: 'value' };
    public item: number[] = [1, 2, 3, 4, 5];
    public pageSettings = { pageCount: 3 };
    @ViewChild('overviewgrid') public Grid: GridComponent;
    load() {// Created By S Initialize grid properties
        const rowHeight: number = this.Grid.getRowHeight();  // height of the each row
        const gridHeight: any = this.Grid.height;  // grid height
        const pageSize: number = this.Grid.pageSettings.pageSize;   // initial page size
        const pageResize: any = (gridHeight - (pageSize * rowHeight)) / rowHeight; // new page size is obtained here
        this.Grid.pageSettings.pageSize = pageSize + Math.round(pageResize);
    }

    dateformat(datas:string){// Created By K Global Date Format
        return this._authSer.Dateformat(datas);
    }

    getCurrentFinancialYear() {// Created By S Get financial year details
        debugger
        this._leaveSer.getCurrentFinancialYear(this.CmpCode).subscribe((res) => {
          this.financialYearText = res["data"].table[0].title;
          this.currentFinancialYearId = res["data"].table[0].financialYearId;
          this.prevFinancialYearId = res["data"].table1[0].prevId;
          this.nextFinancialYearId = res["data"].table1[0].nextId;
        });
      }

      getFinancialYearDetailsback(evnt, financialYearId) {// Created By S Get privious financial years details 
        debugger
          let cmp = this._authSer.getcompanyCode()
            this._leaveSer.getFinancialYearDetails(financialYearId, cmp).subscribe((res: any) => {     
              this._leaveSer.getFinancialYearDetails(res.data.table1[0].prevId, cmp).subscribe((res) => {
                this.financialYearText = res["data"].table[0].title;     
                this.currentFinancialYearId = res["data"].table[0].financialYearId;
                this.prevFinancialYearId = res["data"].table1[0].prevId;
                this.nextFinancialYearId = res["data"].table1[0].nextId;
                let today = new Date();
                let currentyear = today.getFullYear();
                let year =  res["data"].table[0].startYear;
                if(year != currentyear){
                  let elementAddLeave: HTMLElement = document.getElementsByClassName("LeaveTypeList")[0] as HTMLElement;
                  let elementTypeList: HTMLElement = document.getElementsByClassName("LeaveDisablesOnpre")[0] as HTMLElement;
                  // let elementHoliday: HTMLElement = document.getElementsByClassName("disableActionbutton11")[0] as HTMLElement;
                  let elementLeaves: HTMLElement = document.getElementsByClassName("disableActionbutton11")[0] as HTMLElement;
        
                //   elementAddLeave.style.pointerEvents = "none"
                //   elementTypeList.style.pointerEvents = "none"
                  // elementHoliday.style.pointerEvents = "none"
                //   elementLeaves.style.pointerEvents = "none"
                }else{
                  let elementAddLeave: HTMLElement = document.getElementsByClassName("LeaveTypeList")[0] as HTMLElement;
                  let elementTypeList: HTMLElement = document.getElementsByClassName("LeaveDisablesOnpre")[0] as HTMLElement;
                  // let elementHoliday: HTMLElement = document.getElementsByClassName("disableActionbutton11")[0] as HTMLElement;
                  let elementLeaves: HTMLElement = document.getElementsByClassName("disableActionbutton11")[0] as HTMLElement;
        
                //   elementAddLeave.style.pointerEvents = "auto"
                //   elementTypeList.style.pointerEvents = "auto"
                  // elementHoliday.style.pointerEvents = "auto"
                //   elementLeaves.style.pointerEvents = "auto"
                }
        
          
                this.bindRequestsList();
               
                
              });
            });
          }

        getFinancialYearDetailsForwrd(evnt, financialYearId) {// Created By S Get next financial year details
        debugger
      
        let cmp = this._authSer.getcompanyCode()
            this._leaveSer.getFinancialYearDetails(financialYearId, cmp).subscribe((res: any) => {
            this.financialYearText = res["data"].table[0].title;
            this.currentFinancialYearId = res["data"].table[0].financialYearId;
            this.prevFinancialYearId = res["data"].table1[0].prevId;
            this.nextFinancialYearId = res["data"].table1[0].nextId;
            let today = new Date();
            let currentyear = today.getFullYear();
            let year =  res["data"].table[0].startYear;
            if(year != currentyear){
                let elementAddLeave: HTMLElement = document.getElementsByClassName("LeaveTypeList")[0] as HTMLElement;
                let elementTypeList: HTMLElement = document.getElementsByClassName("LeaveDisablesOnpre")[0] as HTMLElement;
                // let elementHoliday: HTMLElement = document.getElementsByClassName("disableActionbutton11")[0] as HTMLElement;
                let elementLeaves: HTMLElement = document.getElementsByClassName("disableActionbutton11")[0] as HTMLElement;

                // elementAddLeave.style.pointerEvents = "none"
                // elementTypeList.style.pointerEvents = "none"
                // elementHoliday.style.pointerEvents = "none"
                // elementLeaves.style.pointerEvents = "none"
            }else{
                let elementAddLeave: HTMLElement = document.getElementsByClassName("LeaveTypeList")[0] as HTMLElement;
                let elementTypeList: HTMLElement = document.getElementsByClassName("LeaveDisablesOnpre")[0] as HTMLElement;
                // let elementHoliday: HTMLElement = document.getElementsByClassName("disableActionbutton11")[0] as HTMLElement;
                let elementLeaves: HTMLElement = document.getElementsByClassName("disableActionbutton11")[0] as HTMLElement;

                // elementAddLeave.style.pointerEvents = "auto"
                // elementTypeList.style.pointerEvents = "auto"
                // elementHoliday.style.pointerEvents = "auto"
                // elementLeaves.style.pointerEvents = "auto"
            }
         
            this.bindRequestsList();
                
            });
        }
    bindRequestsList() {// Created By S Get Data
        this.getMyRequestsList();
        this.getMyApprovalsList();
    }
    total:any=[]
    pending:any=[]
    pendingLength:any=0
    rejectLength:any=0
    CancelledLength: any = 0
    approvedLength:any=0
    rejected:any=[]
    approved:any=[]
    totalApprovalList:any=[]

    getMyApprovalsList() {// Created By S Get My approval list
        let loggedUserId = this.loggedUserId;
        let ddlUserId;
        // if(this.Queryuserid!=undefined){
            // ddlUserId = this.Queryuserid
        // }
         // else{
            ddlUserId = this.ddlEmployeeUserId;
        // }
       
        let _filterStatusId = this.myApprovalsFilterStatusId;
        let _requestType = this.myApprovalsFilterRequestType;

        this.parms = {
            PageNo: 1,
            NoOfRecords: 100000,
            UserId: loggedUserId,
            statusType: _filterStatusId,
            FinancialYearId: this.currentFinancialYearId,
            requestType: _requestType,
            searchedUserId: ddlUserId,
            CmpCode:this.CmpCode,
        };
        this.userLoader = true;
        this._leaveSer.getAllMyLeavesCompOffApprovals(this.parms).subscribe((res) => {
            this.chkAllApprovals = false;
            this.userLoader = false;
            this.myApprovalsList = res["data"].table;
            if(this.Queryuserid!=undefined){
                // this.Queryuserid
                this.myApprovalsList = res["data"].table.filter((x) => x.requestId == this.Queryuserid);
            }
            else{
                // ddlUserId = this.ddlEmployeeUserId;
                this.myApprovalsList = res["data"].table;
            }
            this.totalApprovalList=this.myApprovalsList
            console.log(this.myApprovalsList,'apporvallist')
        //     <div *ngIf="data.status == 'Pending' ">
        //     <img src="../assets/img/Logo/Pending.gif" style="width: 22px;" title="Pending">
        //   </div>
        //   <div *ngIf="data.status == 'Submit' ">
        //     <img src="../assets/img/Logo/Submitted.png" style="width: 12px;" title="Submitted">
        //   </div>
        //   <div *ngIf="data.status == 'Approved' ">
        //     <img src="../assets/img/Logo/Approve1.png" style="width: 18px;" title="Approved">
        //   </div>
        //   <div *ngIf="data.status == 'Rejected' ">
        //     <img src="../assets/img/Logo/Reject1.png" style="width: 18px;" title="Rejected">
        //   </div>
        
            this.total=this.myApprovalsList.length
            this.pending=this.myApprovalsList.filter(x=>x.status=="Pending")
            this.rejected=this.myApprovalsList.filter(x=>x.status=="Rejected")
            this.approved=this.myApprovalsList.filter(x=>x.status=="Approved")
            this.CancelledLength=this.myApprovalsList.filter(x=>x.status=="Cancelled")
            this.pendingLength=this.pending.length
            this.CancelledLength = this.CancelledLength.length
            this.rejectLength=this.rejected.length
            this.approvedLength=this.approved.length
            this.myApprovalsListLength = this.myApprovalsList.length;
        },
        error => {
            this.userLoader = false;
            this.myRequestsList = [];
            console.log(error);
        });
    }
active:any=1
    getFilterVaLue(val){// Created By S Filter Lists by legends for approvals
        debugger
        if(val===1)
        {
            this.active=1
            this.myApprovalsList=this.totalApprovalList
        }
        else if(val===2)
        {
            this.active=2

            this.pending=this.totalApprovalList.filter(x=>x.status=="Pending")
            this.myApprovalsList=[]
            this.myApprovalsList=this.pending
        }
        else if(val===3)
        {
            this.active=3

            this.myApprovalsList=[]
            this.myApprovalsList = this.totalApprovalList.filter(x=>x.status=="Approved")

        }
        else if(val===4)
        {
            this.active=4

            this.myApprovalsList=[]
            this.myApprovalsList=this.totalApprovalList.filter(x=>x.status=="Rejected")

        }
        else if(val===5)
        {
            this.active=5

            this.myApprovalsList=[]
            this.myApprovalsList=this.totalApprovalList.filter(x=>x.status=="Cancelled")

        }
        else{

        }


    }

    getFilterVaLueReq(val)// Created By S Filter Lists by legends for My Requests
    {
        if(val===1)
        {
            this.active=1
            this.myRequestsList=this.totalmyRequestsList
        }
        else if(val===2)
        {
            this.active=2
            this.myRequestsList=[]
            this.myRequestsList=this.totalmyRequestsList.filter(x=>x.status=="Pending")
        }
        else if(val===3)
        {
            this.active=3
            this.myRequestsList=[]
            this.myRequestsList= this.totalmyRequestsList.filter(x=>x.status=="Approved")

        }
        else if(val===4)
        {
            this.active=4
            this.myRequestsList=[]
            this.myRequestsList=this.totalmyRequestsList.filter(x=>x.status=="Rejected")

        }
        else if(val===5)
        {
            this.active=5
            this.myRequestsList=[]
            this.myRequestsList=this.totalmyRequestsList.filter(x=>x.status=="Cancelled")

        }
        else{

        }
    }

    pendingReqLength:any=0
    rejectReqLength:any=0
    mycancelledLeaves:any
    mycancelledLeaveslength:any = 0
    approvedReqLength:any=0
    totalmyRequestsList:any=[]
    getMyRequestsList() {// Created By S Get My Requests
        let loggedUserId = this.loggedUserId;
        let _filterStatusId = this.myRequestsFilterStatusId;
        let _requestType = this.myRequestsFilterRequestType;

        this.parms = {
            PageNo: 1,
            NoOfRecords: 100000,
            UserId: loggedUserId,
            statusType: _filterStatusId,
            requestType: _requestType,
            FinancialYearId: this.currentFinancialYearId,
            CmpCode:this.CmpCode,
        };
        this.userLoader = true;
        this._leaveSer.getAllMyLeavesCompOffRequests(this.parms).subscribe((res) => {
            this.chkAllRequests = false;
            this.userLoader = false;
            this.myRequestsList = res["data"].table;
            this.totalmyRequestsList=this.myRequestsList
            console.log(this.myRequestsList,'myrequestlist')
            this.myRequestsListLength = this.myRequestsList.length;

            this.pending=this.myRequestsList.filter(x=>x.status=="Pending")
            this.pendingReqLength=this.pending.length

            this.rejected=this.myRequestsList.filter(x=>x.status=="Rejected")
            this.rejectReqLength=this.rejected.length

            this.approved=this.myRequestsList.filter(x=>x.status=="Approved")
            this.approvedReqLength=this.approved.length

            this.mycancelledLeaves=this.myRequestsList.filter(x=>x.status=="Cancelled")
            this.mycancelledLeaveslength=this.mycancelledLeaves.length
            
        },
        error => {
            this.userLoader = false;
            this.myRequestsList = [];
            console.log(error);
        });
    }

    // Filters
    filterRequestTypeChange(_filterRequestType, _type) {// Created By S Update Request type
        if (_type === 1) {
            this.myApprovalsFilterRequestType = _filterRequestType;
            this.getMyApprovalsList();
        } else if (_type === 2) {
            this.myRequestsFilterRequestType = _filterRequestType;
            this.getMyRequestsList();
        }
    }
    filterStatusChange(_filterStatusId, _type) {// Created By S Update Status Type
        if (_type === 1) {
            this.myApprovalsFilterStatusId = _filterStatusId;
            this.getMyApprovalsList();
        } else if (_type === 2) {
            this.myRequestsFilterStatusId = _filterStatusId;
            this.getMyRequestsList();
        }
    }

    getEmployeesListForSelect () {// Created By S Get all employees list
        this._leaveSer.getEmployeesListForSelect(this.loggedUserId,this.CmpCode).subscribe((res) => {
            // For Employee Select List
            this.EmployeeList = this._commonSer.SetDDlDropdown(res["data"].table, 'userId', 'employeeCodeName');
            // const selectedEmp = this.EmployeeList.find(x => x.id.toString() === this.loggedUserId.toString());
            // if (selectedEmp !== undefined) {
            //     setTimeout(() => {
            //         this.ddlSelection(selectedEmp, 'Employee', undefined, undefined);
            //     }, 300);
            // }
        },
        error => {
            this.EmployeeList = [];
            console.log(error);
        });
    }
    ddlSelection(value: any, placeHolder: any) { // Created By S On Employee field update
        try {
            switch (placeHolder) {
                case 'Employee':
                    this.ActiveEmployee = [];
                    this.ActiveEmployee.push(value);
                    this.ddlEmployeeUserId = value.id;

                    this.getMyApprovalsList();
                    break;
            }
        } catch (error) {
            console.log(error);
        }
    }
    removed(placeHolder: any) {// Created By S On Remove Employee
        try {
            switch (placeHolder) {
                case 'Employee':
                    this.ActiveEmployee = [];
                    this.ddlEmployeeUserId = null;

                    this.getMyApprovalsList();
                    break;
            }
        } catch (error) {
            console.log(error);
        }
    }

    onSortMyApprovals({ column, direction }: SortEvent) {// Created By S Sorting Approval Requests
        // resetting other headers
        this.headers.forEach((header) => {
            if (header.sortable !== column) {
                header.direction = "";
            }
        });

        // sorting MyApprovalsList
        if (direction === "" || column === "") {
            this.myApprovalsList = this.myApprovalsList;
        } else {
            this.myApprovalsList = [...this.myApprovalsList].sort((a, b) => {
                const res = compare(`${a[column]}`, `${b[column]}`);
                return direction === "asc" ? res : -res;
            });
        }
    }
    getLeaveDetails(requid){// Created By S Get Leave Detauls by Id
        debugger;
        this._leaveSer.getLeaveDetails(requid,this.CmpCode).subscribe((res) => {
        this.userLoader = false;
        if (res["data"].table.length > 0) {
        this.leaveDetails = res["data"].table[0];
        console.log(this.leaveDetails,'leaveDetail'); 
        }  
        })
    }
    // get myApprovalsList(): RequestsApprovals[] {
    //     return this.myApprovalsListTemp.map((request, i) => ({
    //         id: i + 1,
    //         ...request,
    //     })).slice(
    //         (this.myApprovalsPage - 1) * this.myApprovalsPageSize,
    //         (this.myApprovalsPage - 1) * this.myApprovalsPageSize + this.myApprovalsPageSize
    //     );
    // }


   
    getRequestDetails(requestId, requestType) {// Created By S Get Request Details based on Request Type
        debugger
        this.leaveDetails = null;
        this.compOffDetails = null;
        this.leaveApprovalList = [];
        this.compOffApprovalList = [];
        this.isLeaveReasonTextboxEmpty = false;

        this.currentRequestType = "";
        this.requestApprovalComment = "";
        this.userLoader = true;
        if (requestType === "Leave") {
            this.currentRequestType = "Leave";
            this._leaveSer.getLeaveDetails(requestId,this.CmpCode).subscribe((res) => {
                this.userLoader = false;
                if (res["data"].table.length > 0) {
                    this.leaveDetails = res["data"].table[0];
                    console.log(this.leaveDetails);

                    this._commonSer.OpenModalHandler("requestDetailsModal");
                    this.getLeaveApprovalDetails(requestId);
                } else {
                    //Show not found message
                }
            },
            error => {
                this.userLoader = false;
                this.currentRequestType = "";
                this.leaveDetails = null;
                console.log(error);
            });
        } else if (requestType === "Compensatory Off") {
            this.currentRequestType = "Compensatory Off";
            this._leaveSer.getCompOffDetails(requestId,this.CmpCode).subscribe((res) => {
                this.userLoader = false;
                if (res["data"].table.length > 0) {
                    this.compOffDetails = res["data"].table[0];
                    console.clear();
                    console.log(this.compOffDetails);
                    this._commonSer.OpenModalHandler("requestDetailsModal");
                    this.getCompOffApprovalDetails(requestId);
                } else {
                    //Show not found message
                }
            },
            error => {
                this.userLoader = false;
                this.currentRequestType = "";
                this.compOffDetails = null;
                console.log(error);
            });
        }
    }
    onlyUnique(value, index, self) {// Created By S 
        return self.indexOf(value) === index;
      }
    
    disbutton:boolean=true
    loginUserList:any=[]
    withoutLoginUserList:any
    checkList:boolean=false
    listArray:any
    getLeaveApprovalDetails(leaveId) {// Created By S Get Leave Approval Details
        this.leaveApprovalList = [];
        this.disbutton=true
        this._leaveSer.getLeaveApprovalDetails(leaveId,this.CmpCode).subscribe((res) => {
            debugger;
            this.leaveApprovalList = res["data"].table;
            this.checkList=false
            this.loginUserList=[]
            this.withoutLoginUserList=[]
            this.listArray=[]
            this.compOffApprovalList = [];
            this.approvalgGrouplistSix  =[]
            this.approvalgGrouplistTen=[]
            for(var i=0;i<this.leaveApprovalList.length;i++)
            {
                
                if(Number(this.userId)==this.leaveApprovalList[i].approverEmpId)
                {

                    if(this.leaveApprovalList[i].approverGroup==6 && this.leaveApprovalList[i].type===0)
                    {
                        if(this.approvalgGrouplistSix.length==0)
                        {
                this.approvalgGrouplistSix.push(this.leaveApprovalList[i])
                        }
                    }
                    else  if(this.leaveApprovalList[i].approverGroup==10 && this.leaveApprovalList[i].type===0)
                    {
                        if(this.approvalgGrouplistTen.length==0)
                        {
                this.approvalgGrouplistTen.push(this.leaveApprovalList[i])
                        }
                    }
                    else
                    {
                        if(this.loginUserList.length==0)
                        {
                            this.loginUserList.push(this.leaveApprovalList[i])
    
                        }
                    }
                    if(this.checkList==false)
                    {
                        // approvedBy: "20586"
                        // approvedDate: null
                        // approverEmail: "ramesh@samsung.co.in"
                        // approverEmpCode: "SAMUID5001"
                        // approverEmpId: 20586
                        // approverGroup: 6
                        // approverName: "Ramesh "
                        // comment: null
                        // empCode: "SAMUID5004"
                        // employeeId: 20589
                        // employeeName: "Neha Verma "
                        // isCurrentWorkflow: false
                        // leaveApprovalId: 667
                        // leaveId: 198
                        // projectname: "aa1_ (informer),Duplcate projects_ (informer),aa1_ (informer),Duplcate projects_ (informer),aa1_ (informer),Duplcate projects_ (informer),aa1_ (informer),Duplcate projects_ (informer),aa1_ (informer),Duplcate projects_ (informer),aa1_ (informer),Duplcate projects_ (informer),aa1_ (informer),Duplcate projects_ (informer),RAJ TAS_ (informer),aa1_ (informer),Diet Drinks_ (informer),Demo2Phase_ (approver),RAJ TAS_ (informer),aa1_ (informer),Diet Drinks_ (informer),Demo2Phase_ (approver),RAJ TAS_ (informer),aa1_ (informer),Diet Drinks_ (informer),Demo2Phase_ (approver),aa1_ (informer),Duplcate projects_ (informer),aa1_ (informer),Duplcate projects_ (informer),aa1_ (informer),Duplcate projects_ (informer),aa1_ (informer),Duplcate projects_ (informer),aa1_ (informer),Duplcate projects_ (informer),aa1_ (informer),Duplcate projects_ (informer),aa1_ (informer),Duplcate projects_ (informer),aa1_ (informer),Duplcate projects_ (informer),aa1_ (informer),Duplcate projects_ (informer),aa1_ (informer),Duplcate projects_ (informer),aa1_ (informer),Duplcate projects_ (informer),aa1_ (informer),Duplcate projects_ (informer),aa1_ (informer),Duplcate projects_ (informer),aa1_ (informer),Duplcate projects_ (informer),aa1_ (informer),Duplcate projects_ (informer),aa1_ (informer),Duplcate projects_ (informer),aa1_ (informer),Duplcate projects_ (informer),aa1_ (informer),Duplcate projects_ (informer),aa1_ (informer),Duplcate projects_ (informer),aa1_ (informer),Duplcate projects_ (informer),aa1_ (informer),Duplcate projects_ (informer),aa1_ (informer),Duplcate projects_ (informer),aa1_ (informer),Duplcate projects_ (informer),aa1_ (informer),Duplcate projects_ (informer),aa1_ (informer),Duplcate projects_ (informer),aa1_ (informer),Duplcate projects_ (informer),aa1_ (informer),Duplcate projects_ (informer),aa1_ (informer),Duplcate projects_ (informer)"
                        // status: null
                        // statusId: -1
                        // type: 0
                        var approvedBy=this.leaveApprovalList[i].approvedBy
                        var approvedDate=this.leaveApprovalList[i].approvedDate
                     //   this.loginUserList.push({type:this.leaveApprovalList[i].type,status:this.leaveApprovalList[i].status,projectname:this.leaveApprovalList[i].projectname,leaveId:this.leaveApprovalList[i].leaveId,leaveApprovalId:this.leaveApprovalList[i].leaveApprovalId,isCurrentWorkflow:this.leaveApprovalList[i].isCurrentWorkflow,employeeName:this.leaveApprovalList[i].employeeId,employeeId:this.leaveApprovalList[i].employeeId,empCode:this.leaveApprovalList[i].empCode,comment:this.leaveApprovalList[i].comment,approverName:this.leaveApprovalList[i].approverName,approverGroup:this.leaveApprovalList[i].approverGroup,approverEmpId:this.leaveApprovalList[i].approverEmpId,approverEmpCode:this.leaveApprovalList[i].approverEmpCode,approverEmail:this.leaveApprovalList[i].approverEmail,approvedBy:this.leaveApprovalList[i].approvedBy,approvedDate:this.leaveApprovalList[i].approvedDate})
                   //  this.loginUserList.push(this.leaveApprovalList[i])
                     this.checkList=true
                     this.listArray.push(this.leaveApprovalList[i].approverGroupName)
                    }
                    else{
                        var aa=this.leaveApprovalList[i].approverGroupName
                        this.listArray.push(aa)
                    }
                   

                }
                else
                {
                    this.withoutLoginUserList.push(this.leaveApprovalList[i])
                }
            }
           
            this.leaveApprovalList=[]
            var unique = this.listArray.filter(this.onlyUnique);
            if(this.loginUserList.length !=0)
            {
                this.leaveApprovalList=this.withoutLoginUserList.concat(this.loginUserList)

            }
            else {
                 if(this.approvalgGrouplistSix.length!=0)
                 {
                    this.leaveApprovalList=this.withoutLoginUserList.concat(this.approvalgGrouplistSix)
                 }
                 else{
                    this.leaveApprovalList=this.withoutLoginUserList.concat(this.approvalgGrouplistTen)

                 }
            }
            for(var j=0;j<this.leaveApprovalList.length;j++)
            {
                if(Number(this.userId)==this.leaveApprovalList[j].approverEmpId)
                {
                    debugger;
                    this.leaveApprovalList[j].approverGroupName=unique.toLocaleString()
                }
            }
            if(this.roleid=='1')
            {
                var userid=this._authSer.getUserId()
                for(var i=0;i<this.leaveApprovalList.length;i++)
                {
                   if( this.leaveApprovalList[i].approvedBy==userid || this.leaveDetails.status=='Approved')
                   {
                    this.disbutton=false   
                }
                }
            }

        },
        error => {
            this.leaveApprovalList = [];
            console.log(error);
        });
    }
    projectList:any=[]
    projects:any=[]
    getproject(type,status){// Created By S On approval timeline project click
        debugger;
    // approvedBy: "20586"
    // approvedDate: null
    // approverEmail: "ramesh@samsung.co.in"
    // approverEmpCode: "SAMUID5001"
    // approverEmpId: 20586
    // approverGroup: 6
    // approverName: "Ramesh "
    // comment: null
    // empCode: "SAMUID5004"
    // employeeId: 20589
    // employeeName: "Neha Verma "
    // isCurrentWorkflow: false
    // leaveApprovalId: 487
    // leaveId: 168
    // status: "Pending"
    // statusId: 1
    // type: 1
    // getProjectDetails(type,typeid,approvalid,CmpCode,approvalgroup)
       if(type==1)
       {
        this._leaveSer.getProjectDetails(type,status.leaveId,status.approvedBy,this.CmpCode,status.approverGroup).subscribe((res) => {
            this.projectList = res["data"].table;
    
        },
        error => {
            this.leaveApprovalList = [];
            console.log(error);
        });
       }
       if(type==2){
        this._leaveSer.getProjectDetails(type,status.compOffId,status.approvedBy,this.CmpCode,status.approverGroup).subscribe((res) => {
            this.projectList = res["data"].table;
    
        },
        error => {
            this.leaveApprovalList = [];
            console.log(error);
        });
       }
   
    //   this.projectList= this.leaveApprovalList.filter(x=>x.approverEmpCode==status)
    //          this.projects=this.projectList[0].projectname
    //          this.projects.toString()
    }
    // Get Compensatory Off Approval Details
    showButtonApproval:boolean=false
    approvalgGrouplistSix:any=[]
    approvalgGrouplistTen:any=[]
    getCompOffApprovalDetails(compOffId) {// Created By S Get Comp-off details
        this.disbutton=true
        this.showButtonApproval=false
       
        this.compOffApprovalList = [];
        this.approvalgGrouplistSix  =[]
        this.approvalgGrouplistTen=[]
        this._leaveSer.getCompOffApprovalDetails(compOffId,this.CmpCode).subscribe((res) => {
            this.compOffApprovalList = res["data"].table;
            debugger;

            this.checkList=false
            this.loginUserList=[]
            this.withoutLoginUserList=[]
            this.listArray=[]
            for(var i=0;i<this.compOffApprovalList.length;i++)
            {
               
                if(Number(this.userId)==this.compOffApprovalList[i].approverEmpId)
                {

                    if(this.compOffApprovalList[i].approverGroup==6 && this.compOffApprovalList[i].type===0)
                    {
                        if(this.approvalgGrouplistSix.length==0)
                        {
                this.approvalgGrouplistSix.push(this.compOffApprovalList[i])
                        }
                    }
                    else  if(this.compOffApprovalList[i].approverGroup==10 && this.compOffApprovalList[i].type===0)
                    {
                        if(this.approvalgGrouplistTen.length==0)
                        {
                this.approvalgGrouplistTen.push(this.compOffApprovalList[i])
                        }
                    }
                    else
                    {
                        if(this.loginUserList.length==0)
                        {
                            this.loginUserList.push(this.compOffApprovalList[i])
    
                        }
                    }
                    if(this.checkList==false)
                    {
                     
                     //this.loginUserList.push(this.compOffApprovalList[i])
                     this.checkList=true
                     this.listArray.push(this.compOffApprovalList[i].approverGroupName)
                    }
                    else{
                        var aa=this.compOffApprovalList[i].approverGroupName
                        this.listArray.push(aa)
                    }
                   

                }
                else
                {
                    this.withoutLoginUserList.push(this.compOffApprovalList[i])
                }
            }
           
            this.compOffApprovalList=[]
            var unique = this.listArray.filter(this.onlyUnique);
            if(this.loginUserList.length !=0)
            {
                this.compOffApprovalList=this.withoutLoginUserList.concat(this.loginUserList)

            }
            else {
                 if(this.approvalgGrouplistSix.length!=0)
                 {
                    this.compOffApprovalList=this.withoutLoginUserList.concat(this.approvalgGrouplistSix)
                 }
                 else{
                    this.compOffApprovalList=this.withoutLoginUserList.concat(this.approvalgGrouplistTen)

                 }
            }
        
            for(var j=0;j<this.compOffApprovalList.length;j++)
            {
                if(Number(this.userId)==this.compOffApprovalList[j].approverEmpId)
                {
                    debugger;
                    this.compOffApprovalList[j].approverGroupName=unique.toLocaleString()
                }
            }
            if(this.roleid=='1')
            {
                var userid=this._authSer.getUserId()
                for(var i=0;i<this.compOffApprovalList.length;i++)
                {
                    if(userid==this.compOffApprovalList[i].approvedBy || this.compOffDetails.status=='Approved')
                    {
                      this.disbutton=  false
                    }
                }
            }
        },
        error => {
            this.compOffApprovalList = [];
            console.log(error);
        });
    }

    processComppoffApprovalAll(compOffId){// Created By S On Approval Com-Off Requests - All Level
        debugger
        let parms = {
            CompOffId: compOffId,
            StatusId: 2,
            Comment: this.requestApprovalComment,
            UserId: this._authSer.getUserId(),
            CmpCode:this.CmpCode,
        };
        this._leaveSer.compOffApprovalAll(parms).subscribe((res) =>{
            if (res.success === true) {
                debugger
                this.closeAllModals() 

                this.showMessage = res.data;
               
                    this.toasterService.show('success',this.showMessage)

            } else {
                this.showSuccess = false;
                this.showMessage = res.message;
               
                    this.toasterService.show('success',this.showMessage)

              //  this._commonSer.OpenModalHandler("userMessageModel");
            }
            this.getMyApprovalsList();
        })
      

    }
    processLeaveApprovalAll(leaveId){// Created By S On Approval All Leaves
        debugger
        let parms = {
            LeaveId: leaveId,
            StatusId: 2,
            RejectedReason: this.requestApprovalComment,
            ApprovedBy: this._authSer.getUserId(),
            CmpCode:this.CmpCode,
        };
        this._leaveSer.leaveApprovalAll(parms).subscribe((res) =>{
            if (res.success === true) {
                debugger
                this.closeAllModals() 

                this.showMessage = res.data;
               
                    this.toasterService.show('success',this.showMessage)

            } else {
                this.showSuccess = false;
                this.showMessage = res.message;
               
                    this.toasterService.show('success',this.showMessage)

              //  this._commonSer.OpenModalHandler("userMessageModel");
            }
        })
        this.getMyApprovalsList();

    }

    //Process Leave Request
    processLeaveApproval(leaveId, statusId) {// Created By S On Leave Approve
        this.isLeaveReasonTextboxEmpty = false;
        if (statusId == 3) {
            if (this.isCancelReasonMandatory && (this.requestApprovalComment == null || this.requestApprovalComment.trim().length == 0)) {
                this.isLeaveReasonTextboxEmpty = true;
                return;
            }
        }

        let currentUserID = this.loggedUserId;
        this.parms = {
            LeaveId: leaveId,
            StatusId: statusId,
            RejectedReason: this.requestApprovalComment,
            ApprovedBy: currentUserID,
            CmpCode:this.CmpCode,
        };
        this.userLoader = true;
        this.showSuccess = true;
        this._leaveSer.leaveApproval(this.parms).subscribe((response) => {
            this.userLoader = false;
            if (response.success === true) {
                debugger
                this.closeAllModals()

                this.showMessage = response.data;
               
                    this.toasterService.show('success',this.showMessage)

            } else {
                this.showSuccess = false;
                this.showMessage = response.message;
               
                    this.toasterService.show('success',this.showMessage)

              //  this._commonSer.OpenModalHandler("userMessageModel");
            }
            this.getMyApprovalsList();
        },
        error => {
            this.showSuccess = false;
            this.showMessage = "Something went's wrong, please try again.";
           
                this.toasterService.show('success',this.showMessage)
            
            //this._commonSer.OpenModalHandler("userMessageModel");
            console.log(error);
        });
    }
    //Process Compensatory Off Request
    processCompOffApproval(compOffId, statusId) {// Created By S On Comp-off Approval
        this.isLeaveReasonTextboxEmpty = false;
        if (statusId == 3) {
            if (this.isCancelReasonMandatory && (this.requestApprovalComment == null || this.requestApprovalComment.trim().length == 0)) {
                this.isLeaveReasonTextboxEmpty = true;
                return;
            }
        }

        let currentUserID = this.loggedUserId;
        this.parms = {
            CompOffId: compOffId,
            StatusId: statusId,
            Comment: this.requestApprovalComment,
            UserId: currentUserID,
            CmpCode:this.CmpCode,
        };
        this.userLoader = true;
        this.showSuccess = true;
        this._leaveSer.compOffApproval(this.parms).subscribe((response) => {
            this.userLoader = false;
            if (response.success === true) {
                this.showMessage = response.data;
              //  this._commonSer.OpenModalHandler("userMessageModel");
                this.toasterService.show('success',this.showMessage)
                this.closeAllModals()

            } else {
                this.showSuccess = false;
                this.showMessage = response.message;
              //  this._commonSer.OpenModalHandler("userMessageModel");
                this.toasterService.show('success',this.showMessage)
                this.closeAllModals()
            }
            this.getMyApprovalsList();
        },
        error => {
            this.showSuccess = false;
            this.showMessage = "Something went's wrong, please try again.";
            //this._commonSer.OpenModalHandler("userMessageModel");
            this.toasterService.show('success',this.showMessage)
            
            console.log(error);
        });
    }

  
    checkAllMyApprovals() {// Created By K Select All - Approvals
        if (this.chkAllApprovals) {
            this.myApprovalsList.forEach(request => { request.isSelected = true; });
        } else {
            this.myApprovalsList.forEach(request => { request.isSelected = false; });
        }
    }
    checkAllMyRequests() {// Created By K Select All - Requests
        if (this.chkAllRequests) {
            this.myRequestsList.forEach(request => { request.isSelected = true; });
        } else {
            this.myRequestsList.forEach(request => { request.isSelected = false; });
        }
    }

    
    showBulkApprovalModal() {// Created By S Process Bulk Approval
        this.requestBulkApprovalComment = "";
        let isAnySelected = false;
        this.myApprovalsList.forEach(request => {
            if (request.isCurrentWorkflow == false) {
                request.isSelected = false;
            }
        });

        this.myApprovalsList.forEach(request => {
            if (request.isSelected) {
                isAnySelected = true;
            }
        });
        if (isAnySelected) {
            this._commonSer.OpenModalHandler("bulkRequestsApprovalModal");
        }
    }
    processBulkRequestsApproval(statusId) {// Created By S Approve In bulk
        let currentUserID = this.loggedUserId;
        this.checkedLeaveRequestIds = [];
        this.checkedCompOffRequestIds = [];
        this.myApprovalsList.forEach(request => {
            if (request.isSelected && request.statusId === 1) {
                if (request.requestType === "Leave") {
                    this.checkedLeaveRequestIds.push(request.requestId);
                } else if (request.requestType === "Compensatory Off") {
                    this.checkedCompOffRequestIds.push(request.requestId);
                }
            }
        });

        if (this.checkedLeaveRequestIds.length === 0 && this.checkedCompOffRequestIds.length === 0) {
            this.showSuccess = false;
            this.showMessage = "Please select atleast one request to process approval";
            this._commonSer.OpenModalHandler("userMessageModel");
        }

        this.parms = {
            LeaveIdList: this.checkedLeaveRequestIds,
            CompOffIdList: this.checkedCompOffRequestIds,
            StatusId: statusId,
            Comment: this.requestBulkApprovalComment,
            ApprovedBy: currentUserID,
            CmpCode:this.CmpCode,
        };
        this.userLoader = true;
        this.showSuccess = true;
        this._leaveSer.leaveCompOffBulkApproval(this.parms).subscribe((response) => {
            this.userLoader = false;
            if (response.success === true) {
                this.showMessage = response.data;
                this._commonSer.OpenModalHandler("userMessageModel");
            } else {
                this.showSuccess = false;
                this.showMessage = response.message;
                this._commonSer.OpenModalHandler("userMessageModel");
            }
            this.getMyApprovalsList();
        },
        error => {
            this.showSuccess = false;
            this.showMessage = "Something went's wrong, please try again.";
            this._commonSer.OpenModalHandler("userMessageModel");
            console.log(error);
        });
    }

    closeAllModals() {// Created By S Close Modals
        this._commonSer.CloseModalHandler('requestDetailsModal');
        this._commonSer.CloseModalHandler('requestApprovalModal');
        this._commonSer.CloseModalHandler('bulkRequestsApprovalModal');
        this._commonSer.CloseModalHandler('userMessageModel');
    }

    // Import and Export
    ExportLeavesExcel() {// Created By S Export Leaves as excel
        let loggedUserId = this.loggedUserId;
        let ddlUserId = this.ddlEmployeeUserId;
        let _filterStatusId = this.myApprovalsFilterStatusId;
        let _requestType = this.myApprovalsFilterRequestType;

        let parms = {
            PageNo: 1,
            NoOfRecords: 100000,
            UserId: loggedUserId,
            statusType: _filterStatusId,
            requestType: _requestType,
            searchedUserId: ddlUserId,
            CmpCode:this.CmpCode,
        };
        this.userLoader = true;
        this._leaveSer.getLeavesReport_Excel(parms).subscribe((res) => {
            this.userLoader = false;
            let _exportData = res["data"];
            this._commonSer.exportAsExcelFile(_exportData, 'Leave Report');
        },
        error => {
            this.userLoader = false;
            console.log(error);
        });

        // this._leaveSer.getLeavesReport_Excel().subscribe((res) => {
        //     let exportData = res["data"];
        //     this._commonSer.exportAsExcelFile(exportData[0].table, 'Leave Report');
        // }, error => {
        //     console.log(error);
        // });
    }

    ExportCompOffExcel() {// Created By S Export Comp-off as excel
        let loggedUserId = this.loggedUserId;
        let ddlUserId = this.ddlEmployeeUserId;
        let _filterStatusId = this.myApprovalsFilterStatusId;
        let _requestType = this.myApprovalsFilterRequestType;

        let parms = {
            PageNo: 1,
            NoOfRecords: 100000,
            UserId: loggedUserId,
            statusType: _filterStatusId,
            requestType: _requestType,
            searchedUserId: ddlUserId,
            CmpCode:this.CmpCode,
        };
        this.userLoader = true;
        this._leaveSer.getCompOffReport_Excel(parms).subscribe((res) => {
            this.userLoader = false;
            let _exportData = res["data"];
            this._commonSer.exportAsExcelFile(_exportData, 'CompensatoryOff Report');
        },
        error => {
            this.userLoader = false;
            console.log(error);
        });

        // this._leaveSer.getCompOffReport_Excel().subscribe((res) => {
        //     let exportData = res["data"];
        //     this._commonSer.exportAsExcelFile(exportData.table, 'CompensatoryOff Report');
        // }, error => {
        //     console.log(error);
        // });
    }

    showtable = true;
    showimport = false;

    resetImportForm() {// Created By S Reset Form
        this.showtable = true;
        this.showimport = false;
        this.chckSendForApproval = true;
        this.importType = 0;
    }
    Import(_importType) {// Created By S Initialize form
        this.showtable = false;
        this.showimport = true;
        this.chckSendForApproval = true;
        this.importType = _importType;
    }

    showMapData = false;
    showNext = false;
    showname = false;
    showtext = true;

    onFileChange(files: FileList) {// Created By S On file recived
        this.showname = true;
        this.showtext = false;
        this.labelImport.nativeElement.innerText = Array.from(files)
          .map(f => { if (f.size <= 2242880) {
              this.showNext = true;
              return f.name + ' ' + f.size;
            } else {
                return 'file size exceeds 2MB';
            }
        }).join(', ');
        this.fileToUpload = files.item(0);
    }
    showUpload() {// Created By S Toggle
        this.showimport = true;
        this.showNext = false;
        this.showMapData = false;
    }
    showMap() {// Created By S Toggle
        this.showimport = false;
        this.showNext = false;
        this.showMapData = true;
    }

    uploadData() {// Created By S Upload import files
        const formData = new FormData();
        formData.append('importFile', this.fileToUpload);

        if (this.importType == 1) {
            this.userLoader = true;
            this._leaveSer.importLeaves(formData, this.chckSendForApproval).subscribe((response) => {
                this.userLoader = false;
                console.clear();
                console.log(response);
                if (response["success"] === true) {
                    this.showSuccess = true;
                    this.showMessage = response["data"];
                    this._commonSer.OpenModalHandler("userMessageModel");
                } else {
                    this.showSuccess = false;
                    this.showMessage = response["message"];
                    this._commonSer.OpenModalHandler("userMessageModel");
                }
                this.getMyApprovalsList();
                this.resetImportForm();
            },
            error => {
                this.showSuccess = false;
                this.showMessage = "Something went's wrong, please try again.";
                this._commonSer.OpenModalHandler("userMessageModel");
                this.resetImportForm();
                console.log(error);
            });
        } else if (this.importType == 2) {
            this.userLoader = true;
            this._leaveSer.importCompOffs(formData, this.chckSendForApproval).subscribe((response) => {
                this.userLoader = false;
                if (response["success"] === true) {
                    this.showSuccess = true;
                    this.showMessage = response["data"];
                    this._commonSer.OpenModalHandler("userMessageModel");
                } else {
                    this.showSuccess = false;
                    this.showMessage = response["message"];
                    this._commonSer.OpenModalHandler("userMessageModel");
                }
                this.getMyApprovalsList();
                this.resetImportForm();
            },
            error => {
                this.showSuccess = false;
                this.showMessage = "Something went's wrong, please try again.";
                this._commonSer.OpenModalHandler("userMessageModel");
                this.resetImportForm();
                console.log(error);
            });
        }
    }


    // General Settings Data
    getGeneralSettingsData() {// Created By S Getting Leave genral settings
        this._leaveSer.getGeneralSettings(this.CmpCode).subscribe((res) => {
            this.userLoader = false;
            this.generalSettingsData = res["data"];
            this.isCancelReasonMandatory = this.generalSettingsData.leaveCancellationReasonMandatory;
        },
        error => {
            console.log(error);
        });
    }

    recieptIMg: any;
  userImage(item) {// Created By S Update
    // <img src="/assets/img/Logo/male.png" style="width: 190px;">
    if (item.userImage == '' || item.userImage == undefined || item.userImage == null) {
      // this.recieptIMg = `${environment.siteUrl}` + "Uploads/Icon/UploadIcons.png";
      if (item.genders == "Female") {
        this.recieptIMg = "/assets/img/Logo/female.png"

      }
      else {
        this.recieptIMg = "/assets/img/Logo/male.png"

      }
    }
    else {
      this.recieptIMg = `${environment.siteUrl}` + item.userImage;
    }
    return this.recieptIMg

  }
   

    OnPagingHide( value:any) {// Created By S
        if(value==1){
     
         var reqt = document.getElementById("Reqt");
         var appr = document.getElementById("Appr");
        
          if (reqt.style.display === "none") {
           reqt.style.display = "";
           appr.style.display = "none";
         }
        }
       else{
     
         var reqt = document.getElementById("Reqt");
         var appr = document.getElementById("Appr");
        
          if (appr.style.display === "none") {
           reqt.style.display = "none";
           appr.style.display = "";
         }
       }
          
        
         
      }
}
