import {
  Component,
  OnInit,
  QueryList,
  ViewChildren,
  Directive,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,

} from "@angular/core";
import { LeaveService } from "./leave.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AuthService } from "src/app/services/auth.service";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { CommonService } from "src/app/common.service";
import { environment } from "src/app/environment";
import { interval, Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { GridComponent } from "@syncfusion/ej2-angular-grids";
import { ExpenseHttpService } from "src/app/services/expense/expense-http.service";
import { ToasterService } from "../expense/toaster/toaster.service";


interface Country {
  empCode?: any;
  leaveTypeName: string;
  leaveStartDate: string;
  leaveEndDate: string;
  totalDays: any;
  status: string;
  createdDate: string;
  type: string;
}
class LeaveTypeData {
  LeaveTypeId: number;
  LeaveTypeName: string;
  LType: number;
  TypeText: string;
  Unit: number;
  UnitText: string;
  IsDurationFullDay: boolean;
  IsDurationHalfDay: boolean;
  IsDurationQuarterDay: boolean;
  EnableFileUploadOption: boolean;
  OnAppliedLeavePeriodExceeds: number;
  OnweekendBetweenLeaveCountAfter: number;
  OnCanCountAsLeaveInWeekendBetweenLeave: number;
}
class LeaveCalendarDates {
  LeaveTypeId: number;
  LeaveDate: string;
  DayType: string;
  Type: string;
  Time: string;
  Hours: string;
  IsWeekEnd: Boolean;
}

export type SortColumn = keyof Country | "";
export type SortDirection = "asc" | "desc" | "";
const rotate: { [key: string]: SortDirection } = {
  asc: "desc",
  desc: "",
  "": "asc",
};
const compare = (v1: string, v2: string) => (v1 < v2 ? -1 : v1 > v2 ? 1 : 0);

export interface SortEvent {
  column: SortColumn;
  direction: SortDirection;
}

@Directive({
  selector: "th[sortable]",
  host: {
    "[class.asc]": 'direction === "asc"',
    "[class.desc]": 'direction === "desc"',
    "(click)": "rotate()",
  },
})
export class NgbdSortableHeaders {
  @Input() sortable: SortColumn = "";
  @Input() direction: SortDirection = "";
  @Output() sort = new EventEmitter<SortEvent>();

  rotate() {
    this.direction = rotate[this.direction];
    this.sort.emit({ column: this.sortable, direction: this.direction });
  }
}

@Component({
  selector: "app-leave",
  templateUrl: "./leave.component.html",
  styleUrls: ["./leave.component.css"],
})
export class LeaveComponent implements OnInit{

  datePickerConfiq: Partial<BsDatepickerConfig>;
  leaveApply: FormGroup;
  allLeaveTypeList: any;
  allHolidayList: any;
  currentUserId: any;
  submitted = false;
  submittedForFileUpload = false;
  leaveStartFrom: Date;
  userLoader: Boolean = false;
  collection: any[];
  public showSuccess: Boolean;
  public showMessage: string;
  page = 1;
  pageSize = 10;
  COUNTRIES: Country[] = [];
  parms;
  collectionSize: any;
  countries1 = this.COUNTRIES;
  startDateChange;
  minDate: Date;
  bsValue: Date = new Date();
  @ViewChildren(NgbdSortableHeaders) headers: QueryList<NgbdSortableHeaders>;
  myDateValue;
  startDate: any;
  endDate: any;
  applyingLeaveDays: Number = 0;
  userAvalLeave: any;
  currentDate: Date;
  preYearDate: any;
  nextYearDate: any;
  employeName: any;
  employeCode: any;
  financialYearText: any;
  showAvlLeave: any;
  showBalLeave: any;
  todatlEmployeeList: any;
  applyLeaveSelectedUserId: any;
  applyLeaveSelectedUserLeaveBalance: any;
  currentUserName: any;
  currentUserCode: any;
  DateFormat:any
  value:any
  // For User Dropdownlist
  ddlEmployeeUserId: any;
  EmployeeList = [];
  ActiveEmployee = [];

  // For Financial Year
  currentFinancialYearId: any;
  prevFinancialYearId: any;
  nextFinancialYearId: any;

  LeaveTypeDetails: LeaveTypeData;
  DayTypeSelectList = [];
  TypeSelectList = [];
  totalApplyingLeaves: any;

  LeaveCalendarDatesList: LeaveCalendarDates[];

  fileToUpload: File = null;
  isRequiredFileUpload: Boolean = false;
  isFileUploaded: Boolean = false;
  isUploadedFileInvalidFormat: Boolean = false;

  showWeekendHolidayCountNote: Boolean = false;

  syncCalendarType: any;
  gapi: any;
  auth2: any;

  @ViewChild('googleAuthorize') googleAuthorizeElement: ElementRef;
  @ViewChild('loginRef2') loginElement2: ElementRef;



  calendarSyncAuthorized: any;
  currentGmailAccountId: String = "";
  intervalFunGmailAccountSubscription: Subscription;
  intervalFunGmailAccount: any;

  generalSettingsData: any;

  editMode = false;

  cancelLeaveReason: any = "";
  isCancelReasonMandatory: Boolean = false;
  isCancelReasonTextboxEmpty: Boolean = true;
CmpCode:any;
  leaveId: any;
  setbypass: any;
  viewType;
  formUser: any;
  userExpireLeave: any;
  showLeaveTakenDay: any;
  globalArrayDatesList: any;
  ViewshowAvlLeave: any;
  ViewtotalApplyingLeaves: any;
  ViewshowBalLeave: any;

  constructor(
    private _leaveSer: LeaveService,private datePipe:
    DatePipe,
    private _fb: FormBuilder,
    private _authSer: AuthService,
    private toaster: ToasterService,
    private _commonSer: CommonService
  ) {
    this.CmpCode = this._authSer.getcompanyCode();
    this.datePickerConfiq = Object.assign(
      {},
      {
        showWeekNumbers: true,
      }
    );
  }


  ngOnInit() {//Angular Lifecycle Hook
    debugger
    this.CmpCode = this._authSer.getcompanyCode();
    this.DateFormat= this._authSer.getdateFormat();
    this.googleSDK();
    var dateCheck=new Date()

    this.currentDate = new Date();
    this.leaveApply = this._fb.group({
      leaveId: 0,
      UserId: [ , Validators.required,],
      LeaveTypeId: [, Validators.required],
      LeaveStartDate: ["", Validators.required],
      LeaveEndDate: ["", Validators.required],
      TotalDays: [""],
      LeaveReason: [""],
      CreatedBy: [""],
      LeaveDatesList: [[]],
      FileUpload: [],
      cmpCode: "",
      TeamEmailId: " "

    });

    this._authSer.currentUser.subscribe((res) => {
      this.currentUserId = res.userId;
      this.ddlEmployeeUserId = res.userId;
    });
    var roleid=this._authSer.getRoleId()
     this.todatlEmployeeList=[]

      this._authSer.currentUser.subscribe((res) => {
        this.currentUserId = res.userId;
        this.ddlEmployeeUserId = res.userId;
      });
    this.getCurrentUserDetail();
    this.getEmployeesListForSelect();
    this.getCurrentFinancialYear();

    this.getAllLeaveType1();
    this.bindAllDashboardData();
    this.resetLeaveTypeData();
    this.syncCalendarType = "2";
    this.editMode = false;
    this.getGeneralSettingsData();
    this.filterSettings = { type: "Menu" };
    this.filter = { type: "CheckBox" };
    this.selectionSettings = { persistSelection: true, type: "Multiple", checkboxOnly: true };
    this._leaveSer.GetEmployeeLocation(this.CmpCode, this._authSer.getUserId()).subscribe(res =>{
      this.locationId = res.data.table[0]?.locationId;
    })
  }

  public employeeFields: Object = { text: 'text', value: 'id' };
  // public todatlemployeeFields: Object = { text: 'employeeName', value: 'empCode' };
  public todatlemployeeFields: Object = { text: 'employeeName', value: 'userId' };
  public AvlLeaveFields: Object = { text: 'leaveTypeName', value: 'leaveTypeId' };
  public text: string = "Select an employee";
  AvlLeavetext: string = "Select leave type"
  public filter: Object;
  public filterSettings: Object;
  public selectionSettings: Object;
  public height: string = '240px';
  public item: number[] = [1, 2, 3, 4, 5];
  public pageSettings = { pageCount: 3 };

  //--------DatePicker-------
  public today: Date = new Date();
  public currentYear: number = this.today.getFullYear();
  public currentMonth: number = this.today.getMonth();
  public currentDay: number = this.today.getDate();
  public dateValue: Object = new Date(new Date().setDate(14));
  public minCalDate: Object = new Date(this.currentYear, this.currentMonth, 1);
  public maxCalDate: Object =  new Date(this.currentYear, this.currentMonth, 27);
  //-------------------------
  @ViewChild('overviewgrid') public Grid: GridComponent;
  load() {// Created By K Initialize grid properties
    const rowHeight: number = this.Grid.getRowHeight();  // height of the each row
    const gridHeight: any = this.Grid.height;  // grid height
    const pageSize: number = this.Grid.pageSettings.pageSize;   // initial page size
    const pageResize: any = (gridHeight - (pageSize * rowHeight)) / rowHeight; // new page size is obtained here
    this.Grid.pageSettings.pageSize = pageSize + Math.round(pageResize);
  }

  getCurrentUserDetail() {// Created By K Get logged in user details

    this._leaveSer.getEmployeeDetails(this.currentUserId,this.CmpCode).subscribe((res) => {
      //this.todatlEmployeeList = res['data'].table;
debugger
      if (res['data'].table != null && res['data'].table.length > 0) {
        this.currentUserName = res["data"].table[0].employeeName;
        this.currentUserCode = res["data"].table[0].empCode;
      }
    });
  }
  myTest: string;

toRoleid:any
  getEmployeesListForSelect() {// Created By K Get all employees list
    this.setbypass = 1708;

    this._leaveSer.getEmployeesListForSelect(this.currentUserId,this.CmpCode).subscribe((res) => {
    debugger
     var roleid=this._authSer.getRoleId()
     this.todatlEmployeeList=[]
     if(roleid=="1" || roleid=="2" ||roleid=="10")
     {
      // this.todatlEmployeeList.push({userId:0,employeeCodeName:"My Team"})
     this.toRoleid=0

     }
      this.todatlEmployeeList = res['data'].table;

      if (res['data'].table != null && res['data'].table.length > 0) {
        this.employeName = res["data"].table[0].employeeName;
        this.employeCode = res["data"].table[0].empCode;
      }

      // For Dashboard Emplyee Select List
      debugger;
      this.EmployeeList = this._commonSer.SetDDlDropdownfor(res["data"].table, 'userId', 'employeeCodeName',this.toRoleid);
      // this.ddlSelection($event,'Employee');
      const currentEmp = this.todatlEmployeeList.find(x => x.userId == this.currentUserId);

     if(this.toRoleid==0)
     {
     if( res["data"].table.length==1)
     {
      if (currentEmp !== undefined && currentEmp != null) {
        const empValue = { 'id': currentEmp.userId, 'text': currentEmp.employeeCodeName };
        this.value=currentEmp.userId
        setTimeout(() => {
          this.ddlSelection(empValue, 'Employee');
          this.leaveApply.patchValue({
            UserId: currentEmp.userId
          });
          this.setCurrentUserIdForApplyLeave(undefined);
          // this.bindAllDashboardData();
        }, 0);
      }
     }
     else{
      if (currentEmp !== undefined && currentEmp != null) {
        const empValue = { 'id': 0, 'text': "My Team" };
        this.value=0
        setTimeout(() => {
          this.ddlSelection(empValue, 'Employee');
          this.leaveApply.patchValue({
            UserId: currentEmp.userId
          });
          this.setCurrentUserIdForApplyLeave(undefined);
          // this.bindAllDashboardData();
        },0);
      }
     }

     }
     else{
      if (currentEmp !== undefined && currentEmp != null) {
        const empValue = { 'id': currentEmp.userId, 'text': currentEmp.employeeCodeName };
        this.value=currentEmp.userId
        setTimeout(() => {
          this.ddlSelection(empValue, 'Employee');
          this.leaveApply.patchValue({
            UserId: currentEmp.userId
          });
          this.setCurrentUserIdForApplyLeave(undefined);
          // this.bindAllDashboardData();
        }, 0);
      }

     }



    });
  }

 // DateFormat='MM/dd/yyyy';



  dateformat(datas:string){// Created By K Global Date format
    return this._authSer.Dateformat(datas);
    // if (this.DateFormat=='1')
    // {
    //   return this.datePipe.transform(datas, 'MM/dd/yyyy')

    // }
    // if(this.DateFormat=='2')
    // {
    //   return this.datePipe.transform(datas, 'dd/MM/yyyy')
    // }
    // if(this.DateFormat== '3')
    // {
    //   return this.datePipe.transform(datas, 'yyyy/MM/dd')
    // }
  }

  locationId = 0
  ddlSelection(value: any, placeHolder: any) {// Created By K Global Controller for fields update
    this.setbypass = undefined
    debugger
    try {
      switch (placeHolder) {
        case 'Employee':
          this.ActiveEmployee = [];
          this.ActiveEmployee.push(value);
          if(value.value != undefined ){
            this.ddlEmployeeUserId = value.value;
          }
          else{
            this.ddlEmployeeUserId = value.id;
          }
          this._leaveSer.GetEmployeeLocation(this.CmpCode, this.ddlEmployeeUserId).subscribe(res =>{
            this.locationId = res.data.table[0]?.locationId;
            this.bindAllDashboardData();
          })
          break;
      }
    } catch (ex) {

    }
  }

  // Created By K Bind All data as per the Financial Year in one call
  bindAllDashboardData() {
    this.getAllHolidayList();
    if(this.setbypass != 1708){
      this.getAllLeave();
    }
    this.userAvaliableLeave();
  }

  // LeaveId:
  // UserId:
  // LeaveTypeId:
  // LeaveStartDate:
  // LeaveEndDate:
  // TotalDays:
  // LeaveReason:
  // CreatedBy:
  // LeaveDatesList:
  // FileUpload:
  // cmpCode:
  // TeamEmailId:

 disableSubmit: boolean = false;

  editLeave(data){// Created By K On Edit Leave
    debugger
    this.popUPCloseReset();
    this.leaveType="Edit";
    // this.showLeaveTakenDay = new Date(data.leaveStartDate);
    //createdDate
    // this.getAllLeave();
    // this.Grid.refresh();
    this.getLeaveDetails(data.leaveId, data.statusId, this.CmpCode, this.leaveType);
    // debugger;
    // this.editMode = true;
    // this.leaveApply.enable();
    // this.viewID = data.leaveId
    // this.disableSubmit = false;
    // this.LeaveCalendarDatesList = data
    // let teamemail: HTMLInputElement = document.getElementsByClassName("emailtxt")[0] as HTMLInputElement;
    // let startdate: HTMLInputElement = document.getElementsByName("startdate")[0] as HTMLInputElement;
    // let enddate: HTMLInputElement = document.getElementsByName("enddate")[0] as HTMLInputElement;

    // let startdateformated = this.dateformat(data.leaveStartDate)
    // let enddateformated =  this.dateformat(data.leaveEndDate)
    // this.leaveApply.patchValue({
    //   LeaveReason: data.leaveReason,
    //   LeaveTypeId: data.leaveTypeId,
    //   TeamEmailId: data.teamemailId,
    //   leaveId: this.viewID,
    //   UserId: data.userId,
    //   LeaveStartDate: startdateformated,
    //   LeaveEndDate: enddateformated

    // });


    // teamemail.setAttribute("value", data.teamemailId)
    // startdate.setAttribute("value", startdateformated )
    // enddate.setAttribute("value", enddateformated)
  }

  viewdisable: boolean = false;
  viewID;
  leaveType:any
  viewLeave(data){// Created By K On View Leave
    debugger;
    this.popUPCloseReset();
    this.leaveType='View'
    this.showLeaveTakenDay = new Date(data.createdDate);
    // this.getLeaveDetails(data.leaveId, data.statusId, this.CmpCode, this.leaveType);

    this._leaveSer.getLeaveDetailsForEdit(data.leaveId, this.CmpCode)
        .subscribe((response) => {
          this.userLoader = false;
          console.clear();
          console.log(response);
          if (response.success == true) {
            this.setLeaveFormData(response.data, 'View');
          } else {
            this.showSuccess = false;
            this.showMessage = response.message;
            this.toaster.show("error", this.showMessage )
            // vm._commonSer.OpenModalHandler("userMessageModel");
          }
        }, (error) => {
          this.userLoader = false;
          this.showSuccess = false;
          this.showMessage = "Uh oh, Something went wrong !";
          this.toaster.show("error", this.showMessage )
          // vm._commonSer.OpenModalHandler("userMessageModel");
        });

        // let fordate = this.dateformat(this.showLeaveTakenDay)
        let fordate = this.datePipe.transform(this.showLeaveTakenDay, "yyyy-MM-dd")
        this._leaveSer.getviewtimebalance(data.userId ,data.leaveTypeId, data.totalDays, this.CmpCode, fordate)
        .subscribe((response) => {
          this.userLoader = false;
          console.log(response);
          if (response.success == true) {

            this.ViewshowAvlLeave = response.data.table[0].totalBalance
            this.ViewtotalApplyingLeaves = response.data.table[0].taken
            this.ViewshowBalLeave = response.data.table[0].leftbalence
          } else {

            this.showSuccess = false;
            this.showMessage = response.message;
            this.toaster.show("error", this.showMessage )
            // vm._commonSer.OpenModalHandler("userMessageModel");
          }
        });


    // this.getAllLeave();
    // this.Grid.refresh();
    // alert("helo")
    // this.viewType = false
    //this.disableSubmit = true;
    // this.viewID = data.LeaveId

    // let reasonleave: HTMLInputElement = document.getElementsByClassName("emailtxt")[0] as HTMLInputElement;
    // let startdate: HTMLInputElement = document.getElementsByName("startdate")[0] as HTMLInputElement;
    // let enddate: HTMLInputElement = document.getElementsByName("enddate")[0] as HTMLInputElement;
    // let dateconvert =new Date(data.leaveStartDate);
    // let enddate22 =  new Date(data.leaveEndDate);
    // this.leaveApply.patchValue({
    //   LeaveReason: data.leaveReason,
    //   LeaveTypeId: data.leaveTypeId,
    //   TeamEmailId: data.teamemailId,
    //   UserId: data.userId,
    //   LeaveStartDate: dateconvert,
    //   LeaveEndDate: enddate22


    // });
    // reasonleave.setAttribute("value", data.teamemailId)
    // startdate.setAttribute("value", dateconvert )
    // enddate.setAttribute("value", enddate22)


  }
  Deleteleave(data) {// Created By K Initialize delete
    debugger;
    this.leaveId = data.leaveId;
  }

  LeaveDelete(){// Created By K Delete Leave
      try {
        let userId = this._authSer.getUserId();
        let param = {
          leaveId: this.leaveId,
          createdBy: userId,
          CmpCode: this.CmpCode
        };
        this._leaveSer.DeleteLeave(param).subscribe((res) => {
          if (res.success === true) {
            this.toaster.show("success", res.message);
            this.getAllLeave();
            this.Grid.refresh();
          } else {
            this.toaster.show("warning", res.message);
          }
        });
      } catch (ex) { }

  }

  UpdateLeave(){// Created By K On Save Leave for edit
    debugger;
    if (this.editMode == true) {
      this.leaveApply.patchValue({
        LeaveDatesList: this.LeaveCalendarDatesList,
        LeaveStartDate: this.datePipe.transform(this.leaveApply.value.LeaveStartDate, "MM-dd-yyyy"),
        LeaveEndDate:this.datePipe.transform(this.leaveApply.value.LeaveEndDate, "MM-dd-yyyy"),
      })
    this._leaveSer.updateAppliedLeave(this.leaveApply.value).subscribe(
      (response) => {
        this.userLoader = false;
        this.submitted = false;
        this.submittedForFileUpload = false;
        this.showMessage = response.message;
        this.getAllLeave();
        this.Grid.refresh();
        console.log(response);
        if (response.success == true) {
          this.saveUploadedFiles(response.leaveId, this.leaveApply.value.UserId);

          this.showSuccess = true;
          this.showMessage = response.data;
          //this.getAllLeave();
          // this._commonSer.OpenModalHandler("userMessageModel");
          this.toaster.show("success",this.showMessage);
          this.leaveApply.reset();
          this._commonSer.CloseModalHandler("applyLeaveEditMode");
          this.userAvaliableLeave();
        } else {
          this.userLoader = false;
          this.showSuccess = false;
          this.showMessage = response.message;
          this.toaster.show("error",this.showMessage);

          this._commonSer.OpenModalHandler("applyLeaveEditMode");
        }
      },
      (error) => {
        this.userLoader = false;
        this.showSuccess = false;
        this.showMessage = "Uh oh, Something went wrong !";
        this.toaster.show("error",this.showMessage);
        this._commonSer.OpenModalHandler("applyLeaveEditMode");
      }
    );
    }

  }
  getCurrentFinancialYear() {// Created By K Get financial year details
    debugger
    this._leaveSer.getCurrentFinancialYear(this.CmpCode).subscribe((res) => {
      this.financialYearText = res["data"].table[0].title;
      this.currentFinancialYearId = res["data"].table[0].financialYearId;
      this.prevFinancialYearId = res["data"].table1[0].prevId;
      this.nextFinancialYearId = res["data"].table1[0].nextId;
    });
  }

getFinancialYearDetailsback(evnt, financialYearId) {// Created By K Previous year handler
debugger
this.setbypass = 1700
  if(this.value == 0){
    // this.ddlEmployeeUserId = this._authSer.getUserId()
  }else{
    this.ddlEmployeeUserId = this.value
  }

  let cmp = this._authSer.getcompanyCode()
    this._leaveSer.getFinancialYearDetails(financialYearId, cmp).subscribe((res: any) => {


      this._leaveSer.getFinancialYearDetails(res.data.table1[0].prevId, cmp).subscribe((res) => {
        this.financialYearText = res["data"].table[0].title;


        this.currentFinancialYearId = res["data"].table[0].financialYearId;
        this.prevFinancialYearId = res["data"].table1[0].prevId;
        this.nextFinancialYearId = res["data"].table1[0].nextId;
        let today = new Date();
        let currentyear = today.getFullYear();
        let year =  res["data"].table[0].startYear;
        if(year != currentyear){
          let elementAddLeave: HTMLElement = document.getElementsByClassName("LeaveTypeList")[0] as HTMLElement;
          let elementTypeList: HTMLElement = document.getElementsByClassName("LeaveDisablesOnpre")[0] as HTMLElement;
          // let elementHoliday: HTMLElement = document.getElementsByClassName("disableActionbutton11")[0] as HTMLElement;
          let elementLeaves: HTMLElement = document.getElementsByClassName("disableActionbutton11")[0] as HTMLElement;

          elementAddLeave.style.pointerEvents = "none"
          elementTypeList.style.pointerEvents = "none"
          // elementHoliday.style.pointerEvents = "none"
          // elementLeaves.style.pointerEvents = "none"
        }else{
          let elementAddLeave: HTMLElement = document.getElementsByClassName("LeaveTypeList")[0] as HTMLElement;
          let elementTypeList: HTMLElement = document.getElementsByClassName("LeaveDisablesOnpre")[0] as HTMLElement;
          // let elementHoliday: HTMLElement = document.getElementsByClassName("disableActionbutton11")[0] as HTMLElement;
          let elementLeaves: HTMLElement = document.getElementsByClassName("disableActionbutton11")[0] as HTMLElement;

          elementAddLeave.style.pointerEvents = "auto"
          elementTypeList.style.pointerEvents = "auto"
          // elementHoliday.style.pointerEvents = "auto"
          // elementLeaves.style.pointerEvents = "auto"
        }


        this.bindAllDashboardData();

      });
    });
  }

  getFinancialYearDetailsForwrd(evnt, financialYearId) {// Created By K Next Year handler
    debugger
    this.setbypass = 1700
    if(this.value == 0){
      // this.ddlEmployeeUserId = this._authSer.getUserId()
    }else{
      this.ddlEmployeeUserId = this.value
    }
    let cmp = this._authSer.getcompanyCode()
      this._leaveSer.getFinancialYearDetails(financialYearId, cmp).subscribe((res: any) => {
        this.financialYearText = res["data"].table[0].title;
        this.currentFinancialYearId = res["data"].table[0].financialYearId;
        this.prevFinancialYearId = res["data"].table1[0].prevId;
        this.nextFinancialYearId = res["data"].table1[0].nextId;
        let today = new Date();
        let currentyear = today.getFullYear();
        let year =  res["data"].table[0].startYear;
        if(year != currentyear){
          let elementAddLeave: HTMLElement = document.getElementsByClassName("LeaveTypeList")[0] as HTMLElement;
          let elementTypeList: HTMLElement = document.getElementsByClassName("LeaveDisablesOnpre")[0] as HTMLElement;
          // let elementHoliday: HTMLElement = document.getElementsByClassName("disableActionbutton11")[0] as HTMLElement;
          let elementLeaves: HTMLElement = document.getElementsByClassName("disableActionbutton11")[0] as HTMLElement;

          elementAddLeave.style.pointerEvents = "none"
          elementTypeList.style.pointerEvents = "none"
          // elementHoliday.style.pointerEvents = "none"
          // elementLeaves.style.pointerEvents = "none"
        }else{
          let elementAddLeave: HTMLElement = document.getElementsByClassName("LeaveTypeList")[0] as HTMLElement;
          let elementTypeList: HTMLElement = document.getElementsByClassName("LeaveDisablesOnpre")[0] as HTMLElement;
          // let elementHoliday: HTMLElement = document.getElementsByClassName("disableActionbutton11")[0] as HTMLElement;
          let elementLeaves: HTMLElement = document.getElementsByClassName("disableActionbutton11")[0] as HTMLElement;

          elementAddLeave.style.pointerEvents = "auto"
          elementTypeList.style.pointerEvents = "auto"
          // elementHoliday.style.pointerEvents = "auto"
          // elementLeaves.style.pointerEvents = "auto"
        }
          this.bindAllDashboardData();

      });
    }


  datepickerStartDate($event1) {// Created By K Calculate leave dates on start date click
    this.startDate = $event1;
    this.minDate = this.startDate;
    this.minDate.setDate(this.minDate.getDate());
    this.calculateDays();
  }
  datepickerEndDate($event1) {// Created By K Calculate leave dates on end date click
    this.endDate = $event1;
    this.minDate = this.startDate;
    this.calculateDays();
  }
  calculateDays() {// Created By K leave dates calculation logic
    let vw = this;
    debugger;
    if (this.startdate != null && this.enddate != null) {
      let leaveStartDate = new Date(this.startdate);
      let leaveEndDate = new Date(this.enddate);
      this.applyingLeaveDays = 0;
      let days = Math.floor((Date.UTC(leaveEndDate.getFullYear(), leaveEndDate.getMonth(), leaveEndDate.getDate()) - Date.UTC(leaveStartDate.getFullYear(), leaveStartDate.getMonth(), leaveStartDate.getDate())) / (1000 * 60 * 60 * 24));
      if (days != undefined && days != null) {
        days = days + 1;
        if (days < 0) {
          days = 0;
        }
        this.applyingLeaveDays = days;
        this.leaveApply.controls.TotalDays.setValue(days) ;

      }

      if (leaveStartDate != null && leaveEndDate != null) {

        let weekday = new Array(7);
        weekday[0] = "Sunday";
        weekday[1] = "Monday";
        weekday[2] = "Tuesday";
        weekday[3] = "Wednesday";
        weekday[4] = "Thursday";
        weekday[5] = "Friday";
        weekday[6] = "Saturday";

        if (this.submitted == false) {
          debugger
          this.LeaveCalendarDatesList = [];
          let arrayDatesList = this.getDates(leaveStartDate, leaveEndDate);
          this.globalArrayDatesList = this.getDates(leaveStartDate, leaveEndDate);
          for (let i = 0; i < arrayDatesList.length; i++) {
            let _newDate = arrayDatesList[i];
            let _day = weekday[_newDate.getDay()];

            let newData = new LeaveCalendarDates();
            newData.LeaveDate = _newDate ;
            newData.DayType = "1";
            newData.Type = "1";
            newData.Time = "09:30";
            newData.Hours = "9.00";
            newData.IsWeekEnd = false;

            if (_day == "Sunday" || _day == "Saturday") {
              newData.DayType = "4";
              newData.IsWeekEnd = true;
            }

            vw.LeaveCalendarDatesList.push(newData);
          }

          this.calculateTotalLeaves();

          this.getDatesListFromAPI();
        }
      }

    }
  }

  getDates(startDate, endDate) {// Created By K Get dates between two dates
    let dateArray = new Array();
    let tStartDate = new Date(startDate);
    let tEndDate = new Date(endDate);

    let sDate = new Date(tStartDate.getFullYear() + "-" + (tStartDate.getMonth() + 1) + "-" + tStartDate.getDate() + " 00:00:00");
    let eDate = new Date(tEndDate.getFullYear() + "-" + (tEndDate.getMonth() + 1) + "-" + tEndDate.getDate() + " 00:00:00");

    let currentDate = sDate;
    while (currentDate <= eDate) {
      dateArray.push(new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0)));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dateArray;
  }


  getDatesListFromAPI() {// Created By K Get Dates List From API
    let tempLeaveCalendarDatesList = [];
    for (let i = 0; i < this.LeaveCalendarDatesList.length; i++) {
      let _newDate = this.LeaveCalendarDatesList[i].LeaveDate;
      if (_newDate != null) {
        let newDateData = {
          LeaveTypeId: this.LeaveCalendarDatesList[i].LeaveTypeId,
          LeaveDate: this.getCSharpFormattedDate(_newDate),
          DayType: this.LeaveCalendarDatesList[i].DayType,
          Type: this.LeaveCalendarDatesList[i].Type,
          Time: this.LeaveCalendarDatesList[i].Time,
          Hours: this.LeaveCalendarDatesList[i].Hours,
          IsWeekEnd: this.LeaveCalendarDatesList[i].IsWeekEnd,
        };
        tempLeaveCalendarDatesList.push(newDateData);
      }
    }
    let _startDate = this.startDate;
    let _endDate = this.endDate;

    if (_startDate == null || _endDate == null) {
      return;
    }

    let modelData = {
      LeaveTypeId: this.leaveApply.value.LeaveTypeId,
      LeaveStartDate: this.getCSharpFormattedDate(_startDate),
      LeaveEndDate: this.getCSharpFormattedDate(_endDate),
      LeaveDatesList: tempLeaveCalendarDatesList,
      UserId: this.leaveApply.value.UserId,
      CmpCode:this.CmpCode
    };
    this.userLoader = true;

    this._leaveSer.getLeaveDates(modelData).subscribe(
      (response) => {
        this.userLoader = false;
        console.log(response);
        if (response.success == true) {
          let _tempDatesList = response.data;
          this.showWeekendHolidayCountNote = response.showMessage;

          for (let i = 0; i < _tempDatesList.length; i++) {
            this.LeaveCalendarDatesList[i].DayType = _tempDatesList[i].dayType;
          }
          this.calculateTotalLeaves();
        } else {
          this.userLoader = false;
          this.showSuccess = false;
          this.showMessage = response.message;
          this._commonSer.OpenModalHandler("userMessageModel");
        }
      },
      (error) => {
        this.userLoader = false;
        this.showSuccess = false;
        this.showMessage = "Uh oh, Something went wrong !";
        this._commonSer.OpenModalHandler("userMessageModel");
      }
    );
  }

  getAllLeave() {// Created By K Get All Leaves
    debugger;
    // if(this.setbypass == undefined){
      var currentID =  this.ddlEmployeeUserId;
    // }else{
    //   var currentID = this.value;
    // }
     //this.currentUserId;
    let _currentFinancialYearId = this.currentFinancialYearId;
    if (_currentFinancialYearId == undefined || _currentFinancialYearId == null) {
      _currentFinancialYearId = 0;
    }

    let parms = {
      UserId: currentID,
      PageNo: 1,
      NoOfRecords: 100000,
      SortType: "desc",
      FieldName: "CreatedDate",
      Condition: "",
      FinancialYearId: _currentFinancialYearId,
      CmpCode:this.CmpCode,
      loginUserid:this._authSer.getUserId()
    };
    this.userLoader = true;
    this._leaveSer.getAllLeave(parms).subscribe((res) => {
      debugger;
      this.userLoader = false;
      this.COUNTRIES = res["data"].table;
      this.collectionSize = this.COUNTRIES.length;
    });
  }
  getEmplyeDetails() {// Created By A Get All employees
    this._leaveSer.getEmployeeDetails(this.currentUserId,this.CmpCode).subscribe((res) => {
      // this.todatlEmployeeList = res['data'].table;
      // this.employeName = res["data"].table[0].employeeName;
      // this.employeCode = res["data"].table[0].empCode;
    });
  }
  decimalformat(datas: string) {// Created By K Global Date format
    return this._authSer.DecimalFormat(datas);
  }
  userAvaliableLeave() {// Created By K Get user available leaves balance
    debugger;
    // this.userAvalLeave = [];
    // this.applyLeaveSelectedUserLeaveBalance = [];
    this.userLoader = true;
    let currentUserId = +this.ddlEmployeeUserId; //this.currentUserId;
    let _currentFinancialYearId = this.currentFinancialYearId;
    if (_currentFinancialYearId == undefined || _currentFinancialYearId == null) {
      _currentFinancialYearId = 0;
    }
    if (currentUserId > 0) {
      this._leaveSer.getAvailableLeaveBalance(currentUserId, _currentFinancialYearId,this.CmpCode).subscribe((res) => {
        console.log(res);
        this.userLoader = false;
        this.userAvalLeave = res["data"].table;
        this.userExpireLeave = res["data"].table1;
        this.applyLeaveSelectedUserLeaveBalance = res["data"].table;
      });
    }
  }
  onSelectLeeaveType(event1) {// Created By K On Select Leave type
    debugger
    this.showAvlLeave = event1.value
    this.leaveApply.controls["LeaveTypeId"].patchValue(event1.value);
    this.applyLeaveSelectedUserLeaveBalance.filter(res => {
      if (res.leaveTypeId == event1.value) {
        this.showAvlLeave = res.availableLeaves;
      }
    });
    this.setAvailableLeaveBalanceByLeaveType();
    //console.log(event1.target.value,event1);
    //  if (this.leaveApply.value.LeaveTypeId != null) {
    this.getLeaveTypeDetails(this.leaveApply.value.LeaveTypeId);
    //  }
  }



  setCurrentUserIdForApplyLeave(evt) {// Created By A get user leave balance
    debugger;
    this.applyLeaveSelectedUserId = evt.value;
    this._leaveSer.getAvailableLeaveBalance(this.applyLeaveSelectedUserId, 0,this.CmpCode).subscribe((res) => {
      this.userLoader = false;
      this.applyLeaveSelectedUserLeaveBalance = res["data"].table;
      this.setAvailableLeaveBalanceByLeaveType();
    });
  }

  setAvailableLeaveBalanceByLeaveType() {// Created By A Get leave type balance
    if (this.leaveApply.value.LeaveTypeId != null) {
      this.applyLeaveSelectedUserLeaveBalance.forEach(type => {
        if (type.leaveTypeId == this.leaveApply.value.LeaveTypeId) {
          if (this.leaveApply.value.TotalDays == null || this.leaveApply.value.TotalDays == undefined || this.leaveApply.controls["LeaveEndDate"].value != "" ) {
            this.leaveApply.value.TotalDays = 0;

          }
          this.showAvlLeave = type.availableLeaves + this.leaveApply.value.TotalDays;
        }
      });
      // this.applyLeaveSelectedUserLeaveBalance.filter(res => {
      //   if (res.leaveTypeId == this.leaveApply.value.LeaveTypeId) {
      //     this.showAvlLeave = res.availableLeaves;
      //   }
      // });
    }
  }

  popUPCloseReset() {// Created By K  Reset and close modal
    this.leaveType="Apply"
    this.leaveApply.enable();
    this.submitted = false;
    this.disableSubmit = false;
    this.submittedForFileUpload = false;
    this.leaveApply.reset();
    this.showAvlLeave = null;
    this.editMode = false;
    this.LeaveCalendarDatesList = [];
    this.resetLeaveTypeData();
    this.leaveApply.patchValue({
      UserId: this.currentUserId,
    });
    this.value

    const currentEmp = this.todatlEmployeeList.find(x => x.userId == this.currentUserId);
    if (currentEmp !== undefined && currentEmp != null) {
      // setTimeout(() => {
      //   this.leaveApply.patchValue({
      //     UserId: currentEmp.userId
      //   });
      //   this.setCurrentUserIdForApplyLeave(undefined);
      // }, 300);
    }

    this.showWeekendHolidayCountNote = false;

    this.cancelLeaveReason = "";
    this.isCancelReasonTextboxEmpty = true;
  }
  selectLeaveID(selectId, avlLeave) {// Created By A On selct leave type
    debugger;
    this.popUPCloseReset();
    // this.leaveApply.reset();
    //  this.LeaveCalendarDatesList = [];
    // this.resetLeaveTypeData();

    this.showAvlLeave = avlLeave;
    this.leaveApply.patchValue({
      LeaveTypeId: selectId,
      UserId: this.currentUserId,
    });

     this.getLeaveTypeDetails(selectId);

    debugger
    // this.setCalendarListDDLs()
    // if(this.DayTypeSelectList.length == 0){
    //   this.DayTypeSelectList.push({ value: 1, text: "Full Day" },{ value: 2, text: "Half Day" },{ value: 3, text: "Quarter Day" })

    // }
  }
  getAllLeaveType1() {// Created By K Getting all leave type
    this._leaveSer.getAllLeavetype(this.CmpCode).subscribe((response) => {
      this.allLeaveTypeList = response.data.table;
    });
  }

  getAllHolidayList() {// Created By K Getting holidays list
    this.userLoader = true;
    let _currentFinancialYearId = this.currentFinancialYearId;
    if (_currentFinancialYearId == undefined || _currentFinancialYearId == null) {
      _currentFinancialYearId = 0;
    }
    let parms = {
      PageNo: 1,
      NoOfRecords: 100000,
      SortType: "desc",
      FieldName: "CreatedDate",
      Condition: "",
      FinancialYearId: _currentFinancialYearId,
      StartDate: null,
      EndDate: null,
      LocationIds: this.locationId,
      CmpCode:this.CmpCode
    };

    this._leaveSer.getAllHolidayListLeaveDashboard(parms).subscribe((response) => {
      this.userLoader = false;
      this.allHolidayList = JSON.parse(response["data"]);
    });
  }
  get f() {
    return this.leaveApply.controls;
  }

  getCSharpFormattedDate(val: any) {// Created By A backend date format
    if (val === null || val === "") {
      return null;
    }
    const date = new Date(val);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return month + "-" + day + "-" + year;
  }
  getFormattedDate(val: any) {// Created By A date to string
    if (val === null || val === "") {
      return null;
    }
    const date = new Date(val);
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    return new Date(year + "-" + month + "-" + day + " 00:00:00");
  }
  convertCSharpDateToFormattedDate(val: any) {// Created By A backend date format
    if (val === null || val === "") {
      return null;
    }
    const date = new Date(val);
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    //return new Date(year + "-" + (month + 1) + "-" + day + " 00:00:00");
    return new Date(year, month, day);
  }

  onFileChange(files: FileList) { // Created By K On file recived
    debugger;
    this.isUploadedFileInvalidFormat = false;
    if (files.length > 0) {
      this.fileToUpload = files.item(0);
      this.isFileUploaded = true;
      this.validateFileFormat(files.item(0));
    } else {
      this.fileToUpload = null;
      this.isFileUploaded = false;
    }
  }

  validateFileFormat(_file) {// Created By K File format validation
    this.isUploadedFileInvalidFormat = false;
    let sFileName = _file.name;
    let _validFileExtensions = [".jpg", ".png", ".pdf"];
    if (sFileName.length > 0) {
      let blnValid = false;
      for (let j = 0; j < _validFileExtensions.length; j++) {
          let sCurExtension = _validFileExtensions[j];
          if (sFileName.substr(sFileName.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
              blnValid = true;
              break;
          }
      }

      if (!blnValid) {
        this.isUploadedFileInvalidFormat = true;
        return false;
      }
    }
    return true;
  }


  startdate: any  = new Date ;
  enddate: any = new Date ;
  selectEndDate(val){// Created By K On To Date Select
    this.leaveApply.controls.LeaveEndDate.setValue(val) ;
    this.enddate = val;
    this.calculateDays();

    console.log(this.enddate)

  }
  selectStartDate(val){// Created By K On From Date Select
    this.leaveApply.controls.LeaveStartDate.setValue(val);
    this.startdate = val;
    this.minDate = this.startdate;
    this.calculateDays();

    console.log(this.startdate)
  }



  selectLeaveType(val){// Created By A On Leave type select
    this.leaveApply.controls.LeaveTypeId.setValue(val)
    this.leaveApply.controls.cmpCode.setValue(this.CmpCode) ;
  }


  givenEmail
  setTeamEmail(val){ // Created By K Set Team email id
    this.givenEmail = val
  }

  // Apply Leave
  applyLeave() {// Created By K On Submit apply leave form
   debugger;
   if(this.leaveApply.controls["LeaveReason"].value == null || this.leaveApply.controls["LeaveReason"].value == ""){

    this.toaster.show("error", "Please Enter Reason for Leave")
    return
   }
    if(this.leaveApply.controls["TeamEmailId"].value != null ){
      if(this.leaveApply.controls["TeamEmailId"].value != ""){
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let val = re.test(String(this.leaveApply.controls["TeamEmailId"].value).toLowerCase());
        if(!val){
          this.toaster.show("error", "Please Enter a Valid Email")
          return
      }
      }
    }


  if(this.leaveApply.controls["UserId"].value == null){
  this.toaster.show("error", "Please select the employee name")
    return
  }else{
    if(this.leaveApply.controls["LeaveTypeId"].value == null){
      this.toaster.show("error", "Please select the leave type")
    return
    }else{
      if(this.leaveApply.controls["LeaveStartDate"].value == null){
        this.toaster.show("error", "Please select the duration dates")
        return
      }
    }

  }

   this.leaveApply.controls.CreatedBy.patchValue(this._authSer.getUserId());
   this.leaveApply.controls.cmpCode.patchValue(this._authSer.getcompanyCode());
   if(this.givenEmail == undefined){
    this.leaveApply.controls.TeamEmailId.patchValue(this.givenEmail);
   }
   this.leaveApply.controls.leaveId.patchValue(0);

    this.submitted = true;
    this.submittedForFileUpload = true;
    if (this.leaveApply.invalid) {
      this.submitted = false;
      return;
    }
    if ((this.isRequiredFileUpload == true && this.isFileUploaded == false) ||
        this.isUploadedFileInvalidFormat == true) {
      this.submitted = false;
      return;
    }

    let tempLeaveCalendarDatesList = [];
    debugger
    for (let i = 0; i < this.LeaveCalendarDatesList.length; i++) {
      let _newDate = this.LeaveCalendarDatesList[i].LeaveDate;
      // let _newDate = this.globalArrayDatesList[i]
      if (_newDate != null) {
      // this.LeaveCalendarDatesList[i].LeaveDate = this.getFormattedDate(_newDate) ;

        let newDateData = {
          LeaveTypeId: this.leaveApply.value.LeaveTypeId,
          LeaveDate: this.datePipe.transform(_newDate, "MM-dd-yyyy"),
          DayType: this.LeaveCalendarDatesList[i].DayType,
          Type: this.LeaveCalendarDatesList[i].Type,
          Time: this.LeaveCalendarDatesList[i].Time,
          Hours: this.LeaveCalendarDatesList[i].Hours,
          IsWeekEnd: this.LeaveCalendarDatesList[i].IsWeekEnd,
        };
        tempLeaveCalendarDatesList.push(newDateData);
      }
    }

    // let startDate = this.leaveApply.value.LeaveStartDate;
    // let endDate = this.leaveApply.value.LeaveEndDate;
    // var date1: any = new Date(startDate);
    // var date2: any = new Date(endDate);
    // var diffDays: any = (date2 - date1) / (1000 * 60 * 60 * 24);

    // let _startDate = this.leaveApply.value.LeaveStartDate;
    // let _endDate = this.leaveApply.value.LeaveEndDate;


    let _startDate = this.startdate;
    let _endDate = this.enddate;

    if (_startDate != null) {
      this.leaveApply.patchValue({
        LeaveStartDate: this.getCSharpFormattedDate(_startDate)
      });
    } else {
      this.submitted = false;
      return;
    }

    if (_endDate != null) {
      this.leaveApply.patchValue({
        LeaveEndDate: this.getCSharpFormattedDate(_endDate)
      });
    } else {
      this.submitted = false;
      return;
    }
    debugger
    if(tempLeaveCalendarDatesList[0].LeaveTypeId == null){
    this.toaster.show("error", "Requested leave have invalid duration please try after some time")
    return
    }

    this.leaveApply.patchValue({
      // TotalDays: this.applyingLeaveDays,
      //LeaveDatesList: this.LeaveCalendarDatesList,
      LeaveDatesList: tempLeaveCalendarDatesList
    });
    console.log(this.leaveApply.value);
    // if (this.LeaveCalendarDatesList.length > 1) {
    //   return;
    // }

    this.userLoader = true;

    // const formData = new FormData();
    // formData.append('UserId', this.leaveApply.value.UserId);
    // formData.append('LeaveTypeId', this.leaveApply.value.LeaveTypeId);
    // formData.append('LeaveStartDate', this.leaveApply.value.LeaveStartDate);
    // formData.append('LeaveEndDate', this.leaveApply.value.LeaveEndDate);
    // for (let i = 0; i < tempLeaveCalendarDatesList.length; i ++) {
    //   formData.append('LeaveDatesList[]', tempLeaveCalendarDatesList[i]);
    // }
    // formData.append('TotalDays', this.leaveApply.value.TotalDays);
    // formData.append('LeaveReason', this.leaveApply.value.LeaveReason);
    // formData.append('CreatedBy', this.currentUserId);
    // formData.append('FileUpload', this.fileToUpload);

    // this.leaveApply.patchValue({
    //   FileUpload: this.fileToUpload
    // });

    // New Leave Apply
    if (this.editMode == false) {
      this._leaveSer.applyLeave(this.leaveApply.value).subscribe(

          (response) => {
            this.userLoader = false;
            this.submitted = false;
            this.submittedForFileUpload = false;
            this.showMessage = response.message;
            console.log(response);
            if (response.success == true) {
              this.saveUploadedFiles(response.leaveId, this.leaveApply.value.UserId);
              this.leaveApply.reset();
              this.showSuccess = true;
              this.showMessage = response.data;
              this.getAllLeave();
              this.Grid.refresh();
              this.toaster.show("success",this.showMessage);
              // this._commonSer.OpenModalHandler("userMessageModel");
              this._commonSer.CloseModalHandler("applyLeaveEditMode");


              this.userAvaliableLeave();
            } else {
              this.userLoader = false;
              this.showSuccess = false;
              this.showMessage = response.message;
              this.toaster.show("error",this.showMessage);
              // this._commonSer.OpenModalHandler("userMessageModel");
              // this._commonSer.CloseModalHandler("applyLeaveEditMode");
            }
          },
          (error) => {
            this.userLoader = false;
            this.showSuccess = false;
            this.showMessage = "Uh oh, Something went wrong !";
            this.toaster.show("error", this.showMessage )
            // this._commonSer.OpenModalHandler("userMessageModel");
            // this._commonSer.CloseModalHandler("applyLeaveEditMode");
          }
        );
    }
    else { // Update Leave
      // this._leaveSer.updateAppliedLeave(this.leaveApply.value).subscribe(
      //     (response) => {
      //       this.userLoader = false;
      //       this.submitted = false;
      //       this.submittedForFileUpload = false;
      //       this.showMessage = response.message;
      //       this.getAllLeave();
      //       console.log(response);
      //       if (response.success == true) {
      //         this.saveUploadedFiles(response.leaveId, this.leaveApply.value.UserId);
      //         this.leaveApply.reset();
      //         this.showSuccess = true;
      //         this.showMessage = response.data;
      //         //this.getAllLeave();
      //         this._commonSer.OpenModalHandler("userMessageModel");
      //         this._commonSer.CloseModalHandler("applyLeaveMode");
      //         this.userAvaliableLeave();
      //       } else {
      //         this.userLoader = false;
      //         this.showSuccess = false;
      //         this.showMessage = response.message;
      //         this._commonSer.OpenModalHandler("userMessageModel");
      //       }
      //     },
      //     (error) => {
      //       this.userLoader = false;
      //       this.showSuccess = false;
      //       this.showMessage = "Uh oh, Something went wrong !";
      //       this._commonSer.OpenModalHandler("userMessageModel");
      //     }
      //   );
    }
  }
  saveUploadedFiles(leaveId, userId) { // Created By A Upload Document
    if (this.fileToUpload != null && this.fileToUpload != undefined) {
      const formData = new FormData();
      formData.append('FileUpload', this.fileToUpload);

      this._leaveSer.saveUploadedFiles(formData, leaveId, userId).subscribe((response) => {
        this.userLoader = false;
        console.clear();
        console.log(response);
      },
      error => {
          console.log(error);
      });
    }
  }

  onSort({ column, direction }: SortEvent) {// Created By A Sorting Algorithm
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      }
    });

    // sorting countries
    if (direction === "" || column === "") {
      this.COUNTRIES = this.COUNTRIES;
    } else {
      this.COUNTRIES = [...this.COUNTRIES].sort((a, b) => {
        const res = compare(`${a[column]}`, `${b[column]}`);
        return direction === "asc" ? res : -res;
      });
    }
  }

  get countries(): Country[] {
    return this.COUNTRIES.map((country, i) => ({
      id: i + 1,
      ...country,
    })).slice(
      (this.page - 1) * this.pageSize,
      (this.page - 1) * this.pageSize + this.pageSize
    );
  }

  resetLeaveTypeData() {// Created By A Rset values
    this.LeaveTypeDetails = new LeaveTypeData();

    // this.DayTypeSelectList = [];
    this.TypeSelectList = [];

    this.totalApplyingLeaves = null;
    this.showBalLeave = null;
  }

  getLeaveTypeDetails(leaveTypeId) {// Created By K Getting leave details with leave Id
    this.userLoader = true;
    // this.resetLeaveTypeData();
    this._leaveSer.getLeaveTypeById(leaveTypeId,this.CmpCode)
      .subscribe((resLeaveType) => {
        debugger
        this.userLoader = false;
        // this.LeaveTypeDetails = new LeaveTypeData();
        this.LeaveTypeDetails.LeaveTypeId = resLeaveType["data"].leaveType[0].leaveTypeId;
        this.LeaveTypeDetails.LType = resLeaveType["data"].leaveType[0].type;
        this.LeaveTypeDetails.TypeText = resLeaveType["data"].leaveType[0].typeText;
        this.LeaveTypeDetails.Unit = resLeaveType["data"].leaveType[0].unit;
        this.LeaveTypeDetails.UnitText = resLeaveType["data"].leaveType[0].unitText;
        this.LeaveTypeDetails.IsDurationFullDay = resLeaveType["data"].restrictions[0].isDurationFullDay;
        this.LeaveTypeDetails.IsDurationHalfDay = resLeaveType["data"].restrictions[0].isDurationHalfDay;
        this.LeaveTypeDetails.IsDurationQuarterDay = resLeaveType["data"].restrictions[0].isDurationQuarterDay;

        this.LeaveTypeDetails.EnableFileUploadOption = resLeaveType["data"].restrictions[0].enableFileUploadOption;
        this.LeaveTypeDetails.OnAppliedLeavePeriodExceeds = resLeaveType["data"].restrictions[0].onAppliedLeavePeriodExceeds;
        this.LeaveTypeDetails.OnweekendBetweenLeaveCountAfter = resLeaveType["data"].restrictions[0].weekendBetweenLeaveCountAfter;
        this.LeaveTypeDetails.OnCanCountAsLeaveInWeekendBetweenLeave = resLeaveType["data"].restrictions[0].canCountAsLeaveInWeekendBetweenLeave;
        this.isRequiredFileUpload = false;
        this.isFileUploaded = false;
        this.isUploadedFileInvalidFormat = false;

        // console.clear();
        // console.log(resLeaveType);
        this.setCalendarListDDLs();
        this.calculateTotalLeaves();
      });
  }

  setCalendarListDDLs() {// Created By K Set Leave Day Type
    debugger
    this.DayTypeSelectList = [];
    this.TypeSelectList = [];

    if (this.LeaveTypeDetails.IsDurationFullDay == true) {
      this.DayTypeSelectList.push({ value: 1, text: "Full Day" });
    }
    if (this.LeaveTypeDetails.IsDurationHalfDay == true) {
      this.DayTypeSelectList.push({ value: 2, text: "Half Day" });
    }
    if (this.LeaveTypeDetails.IsDurationQuarterDay == true) {
      this.DayTypeSelectList.push({ value: 3, text: "Quarter Day" });
    }
  }

  setDayTypeValue(e, i) {// Created By K On Leave Day Select
    let _value = e.srcElement.value;
    this.LeaveCalendarDatesList[i].DayType = _value;
    // if (_value == "2") {
    //   this.TypeSelectList.push({ value: "1", text: "1st Half" });
    //   this.TypeSelectList.push({ value: "2", text: "2nd Half" });
    // } else if (_value == "3") {
    //   this.TypeSelectList.push({ value: "6", text: "1st Quarter" });
    //   this.TypeSelectList.push({ value: "7", text: "2nd Quarter" });
    //   this.TypeSelectList.push({ value: "8", text: "3rd Quarter" });
    //   this.TypeSelectList.push({ value: "9", text: "4th Quarter" });
    // }

    if (_value == "2") {
      this.LeaveCalendarDatesList[i].Type = "1";
    } else if (_value == "3") {
      this.LeaveCalendarDatesList[i].Type = "6";
    }

    this.calculateTotalLeaves();
  }
  setTypeValue(e, i) {// Created By K On Choosing half or full days leaves
    let _value = e.srcElement.value;
    this.LeaveCalendarDatesList[i].Type = _value;
  }
  setHoursValue(e, i) {// Created By K On Hour vales update
    let _value = e.srcElement.value;
    this.LeaveCalendarDatesList[i].Hours = _value;
    this.calculateTotalLeaves();
  }
  calculateTotalLeaves() {// Created By A Calculating total leaves
    debugger
    this.totalApplyingLeaves = null;
    this.showBalLeave = null;
    this.applyingLeaveDays = 0;
    let totalLeaves = 0;
    if (this.LeaveTypeDetails.Unit == 1) {
      for (let i = 0; i < this.LeaveCalendarDatesList.length; i++) {
        if (this.LeaveCalendarDatesList[i].DayType == "1") {
          totalLeaves = totalLeaves + 1;
        } else if (this.LeaveCalendarDatesList[i].DayType == "2") {
          totalLeaves = totalLeaves + 0.5;
        } else if (this.LeaveCalendarDatesList[i].DayType == "3") {
          totalLeaves = totalLeaves + 0.25;
        }
        else if(this.LeaveCalendarDatesList[i].DayType == "4"){
          if(this.LeaveTypeDetails.OnCanCountAsLeaveInWeekendBetweenLeave){
            if(this.LeaveTypeDetails.OnweekendBetweenLeaveCountAfter < totalLeaves ){
              // let weekend = this.LeaveCalendarDatesList.filter( x =>  x.DayType == "4")
              totalLeaves += 1;
            }
          }
        }
      }

      this.totalApplyingLeaves = totalLeaves.toString();
      this.applyingLeaveDays = totalLeaves;

    } else if (this.LeaveTypeDetails.Unit == 2) {
      for (let i = 0; i < this.LeaveCalendarDatesList.length; i++) {
        if (this.LeaveCalendarDatesList[i].Hours != null) {
          totalLeaves = totalLeaves + parseFloat(this.LeaveCalendarDatesList[i].Hours);
        }
      }

      this.totalApplyingLeaves = totalLeaves.toString();
      this.applyingLeaveDays = totalLeaves;
    }


    if (this.LeaveTypeDetails.EnableFileUploadOption == true) {
      if (this.LeaveTypeDetails.OnAppliedLeavePeriodExceeds < totalLeaves) {
        this.isRequiredFileUpload = true;
      }
      else
      {
        this.isRequiredFileUpload = false;
      }
    }

    this.showBalLeave = this.showAvlLeave -  +this.totalApplyingLeaves;
  }


  // Calendar Sync
  //AIzaSyA2boFDCFdVRo-CyqKa2T_o3dD1NNWT9zo
  googleSDK() {// Created By A google calendar syncing SDK
    window['googleSDKLoaded'] = () => {
      window['gapi'].load('auth2', () => {
        this.auth2 = window['gapi'].auth2.init({
          client_id: environment.google_client_id,
          //api_key:'AIzaSyA2boFDCFdVRo-CyqKa2T_o3dD1NNWT9zo',
          cookie_policy: 'single_host_origin',
          //scope: 'profile email'
          scope: "https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events.readonly https://www.googleapis.com/auth/calendar.events"
        });
        this.funGoogleAuthorize();
      });
    };

    (function(d, s, id) {
      let js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s); js.id = id;
      js.src = "https://apis.google.com/js/platform.js?onload=googleSDKLoaded";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'google-jssdk'));
  }
  CalendarSync() {// Created By A Syncing with google calendar
    this.userLoader = true;
    this.calendarSyncAuthorized = null;
    this._leaveSer.getUserCalendarSyncAuthorized(this.ddlEmployeeUserId).subscribe(
      (response) => {

        let calendarSyncAuthorized = response["data"];
        if (calendarSyncAuthorized != null && calendarSyncAuthorized != undefined) {
          this.calendarSyncAuthorized = {};

          this.calendarSyncAuthorized.authorizedId = calendarSyncAuthorized.authorizedId;
          this.calendarSyncAuthorized.userId = calendarSyncAuthorized.userId;
          this.calendarSyncAuthorized.googleSyncEmailId = calendarSyncAuthorized.googleSyncEmailId;
          this.calendarSyncAuthorized.googleSyncAccessToken = calendarSyncAuthorized.googleSyncAccessToken;
          this.calendarSyncAuthorized.lastGoogleSyncDate = calendarSyncAuthorized.lastGoogleSyncDate;
          this.calendarSyncAuthorized.createdDate = calendarSyncAuthorized.createdDate;

          this.currentGmailAccountId = calendarSyncAuthorized.googleSyncEmailId;
          this.syncCalendarType = "2";

          this._commonSer.OpenModalHandler("googleSyncModel");
        } else {
          // const source = interval(10000);
          // this.intervalFunGmailAccountSubscription = source.subscribe(val => this.setGoogleEmailData());
          this.intervalFunGmailAccount = setInterval(this.setGoogleEmailData, 10000);
          this._commonSer.OpenModalHandler("googleSyncAuthorizeModel");
        }
        this.userLoader = false;

        setTimeout(() => {
          this.funGoogleAuthorize();
        }, 300);
      },
      (error) => {
        this.userLoader = false;
        this.showSuccess = false;
        this.showMessage = "Uh oh, Something went wrong !";
        this._commonSer.OpenModalHandler("userMessageModel");
      }
    );
  }
  setGoogleEmailData() {// Created By A Getting Email data
    debugger;
    if (localStorage.getItem("stGoogleSyncEmailId") !== null) {
      let googleAuthorizedData = JSON.parse(localStorage.getItem("stGoogleSyncEmailId"));
      if (googleAuthorizedData != null) {
        this.currentGmailAccountId = googleAuthorizedData.googleSyncEmailId;
        localStorage.setItem("stGoogleSyncEmailId", null);
        //this.intervalFunGmailAccountSubscription.unsubscribe();
        clearInterval(this.intervalFunGmailAccount);
      }
    }
  }
  funGoogleAuthorize() {
    // Created By A This for Authorize the Google account to sync Leaves and Holidays
    let vm = this;
    vm.syncCalendarType = "2";

    if (this.googleAuthorizeElement != null && this.googleAuthorizeElement != undefined) {
      vm.auth2.attachClickHandler(this.googleAuthorizeElement.nativeElement, {},
      (googleUser) => {
        vm.userLoader = true;
        // this.calendarSyncAuthorized = null;
        let profile = googleUser.getBasicProfile();
        let gmailId = profile.$t;
        let accesToken = googleUser.getAuthResponse().access_token;
        console.clear();
        console.log(googleUser.getAuthResponse());

        vm.currentGmailAccountId = gmailId;

        let params = {
          UserId: vm.ddlEmployeeUserId,
          GoogleSyncEmailId: gmailId,
          GoogleSyncAccessToken: accesToken
        };
        vm._leaveSer.saveGoogleAuthorized(params).subscribe(
          (response) => {
            debugger;
            vm._commonSer.CloseModalHandler("googleSyncAuthorizeModel");
            //this.CalendarSync();

            vm.calendarSyncAuthorized = {
              authorizedId: 0,
              userId: vm.ddlEmployeeUserId,
              googleSyncEmailId: gmailId,
              googleSyncAccessToken: accesToken
              //lastGoogleSyncDate: null,
              //createdDate: null
            };

            let googleAuthorizedData = {
              googleSyncEmailId: gmailId,
              googleSyncAccessToken: accesToken
            };
            localStorage.setItem("stGoogleSyncEmailId", JSON.stringify(googleAuthorizedData));

            setTimeout(() => {
              // vm.calendarSyncAuthorized.authorizedId = 0;
              // vm.calendarSyncAuthorized.userId = vm.ddlEmployeeUserId;
              // vm.calendarSyncAuthorized.googleSyncEmailId = gmailId;
              // vm.calendarSyncAuthorized.googleSyncAccessToken = accesToken;
              // vm.calendarSyncAuthorized.lastGoogleSyncDate = null;
              // vm.calendarSyncAuthorized.createdDate = null;

              vm.syncCalendarType = "2";

              vm.userLoader = false;
              vm._commonSer.OpenModalHandler("googleSyncModel");
            }, 300);
          },
          (error) => {
            vm.userLoader = false;
            vm.showSuccess = false;
            vm.showMessage = "Uh oh, Something went wrong !";
            vm._commonSer.OpenModalHandler("userMessageModel");
          }
        );

      }, (error) => {
        //alert(JSON.stringify(error, undefined, 2));
        vm.userLoader = false;
        vm.showSuccess = false;
        vm.showMessage = "Uh oh, Something went wrong !";
        vm._commonSer.OpenModalHandler("userMessageModel");
      });
    }
  }
  googleCalendarPush() {// Created By A
    this.userLoader = true;

    let _currentFinancialYearId = this.currentFinancialYearId;
    if (_currentFinancialYearId == undefined || _currentFinancialYearId == null) {
      _currentFinancialYearId = 0;
    }

    let params = {
      UserId: this.ddlEmployeeUserId,
      AccessToken: this.calendarSyncAuthorized.googleSyncAccessToken,
      SyncType: this.syncCalendarType != null ? this.syncCalendarType : "2",
      FinancialYearId: _currentFinancialYearId,
      CmpCode:this.CmpCode,
    };
    this._leaveSer.syncGoogleCalendar(params).subscribe(
      (response) => {
        this.userLoader = false;
        this.showSuccess = true;
        //this.showMessage = "Calendar sync is in progress..";
        this.showMessage = "Calendar sync completed successfully";
        this._commonSer.CloseModalHandler("googleSyncModel");
        this._commonSer.OpenModalHandler("userMessageModel");
      },
      (error) => {
        console.clear();
        console.log(error);
        this.userLoader = false;
        this.showSuccess = false;
        this.showMessage = "Uh oh, Something went wrong !";
        this._commonSer.CloseModalHandler("googleSyncModel");
        this._commonSer.OpenModalHandler("userMessageModel");
      }
    );
  }
  RevokeGoogleAuthorized() {// Created By A Revoking Authorization to google calendars
    this.userLoader = true;
    this.calendarSyncAuthorized = null;
    this._leaveSer.revokeGoogleAuthorized(this.ddlEmployeeUserId).subscribe(
      (response) => {
        if (response["success"] == true) {
          this.showSuccess = true;
          this.showMessage = response["data"];
        } else {
          this.showSuccess = false;
          this.showMessage = response["message"];
        }
        this.userLoader = false;
        this._commonSer.CloseModalHandler("googleSyncModel");
        this._commonSer.OpenModalHandler("userMessageModel");
      },
      (error) => {
        this.userLoader = false;
        this.showSuccess = false;
        this.showMessage = "Uh oh, Something went wrong !";
        this._commonSer.CloseModalHandler("googleSyncModel");
        this._commonSer.OpenModalHandler("userMessageModel");
      }
    );
  }

  // General Settings Data
  getGeneralSettingsData() {// Created By K Getting Leaves genral tettings
    this._leaveSer.getGeneralSettings(this.CmpCode).subscribe((res) => {
      this.userLoader = false;
      this.generalSettingsData = res["data"];
      this.isCancelReasonMandatory = false;

      if (this.generalSettingsData.leaveCancellationReasonMandatory == true) {
        this.isCancelReasonMandatory = true;
      }
    },
    error => {
      console.log(error);
    });
  }

  // Edit Leave
  getLeaveDetails(leaveId: number, statusId: number, cmpCode, formtype) {// Created By A
    debugger
    if (leaveId > 0 ) {
      let vm = this;
      vm.userLoader = true;
      vm._leaveSer.getLeaveDetailsForEdit(leaveId, cmpCode)
        .subscribe((response) => {
          vm.userLoader = false;
          console.clear();
          console.log(response);
          if (response.success == true) {
            vm.setLeaveFormData(response.data, formtype);
          } else {
            vm.showSuccess = false;
            vm.showMessage = response.message;
            this.toaster.show("error", vm.showMessage )
            // vm._commonSer.OpenModalHandler("userMessageModel");
          }
        }, (error) => {
          vm.userLoader = false;
          vm.showSuccess = false;
          vm.showMessage = "Uh oh, Something went wrong !";
          this.toaster.show("error", vm.showMessage )
          // vm._commonSer.OpenModalHandler("userMessageModel");
        });
    }
  }

  // Set Form Data from DB
  setLeaveFormData(leaveDetails, type) {// Created By K
    debugger
    this.getLeaveTypeDetails(leaveDetails.leaveTypeId);
    this.minDate = new Date(leaveDetails.leaveStartDate)
    this.submitted = true;
    this.submittedForFileUpload = true;

    this.leaveApply.patchValue({
      leaveId: leaveDetails.leaveId,
      UserId: leaveDetails.userId,
      LeaveTypeId: leaveDetails.leaveTypeId,
      LeaveDatesList: leaveDetails.leaveDatesList,
      LeaveStartDate : this.datePipe.transform(leaveDetails.leaveStartDate,'MM/dd/yyyy') ,
      LeaveEndDate :  this.datePipe.transform(leaveDetails.leaveEndDate,'MM/dd/yyyy')  ,
      cmpCode: this.CmpCode,
      TeamEmailId: leaveDetails.teamEmailId,
      TotalDays: leaveDetails.totalDays,
      LeaveReason: leaveDetails.leaveReason,
      CreatedBy: leaveDetails.createdBy,
      FileUpload: [],
    });

    if(type == 'Edit'){
      this.editMode = true;

    }
   else{
    this.editMode = false;

    // let viewtype: HTMLElement = document.getElementsByName("LeaveType22")[0] as HTMLElement
    // viewtype.className = "e-input-group e-control-wrapper e-ddl e-lib e-keyboard e-valid-input e-disabled"

   }
    this.leaveType = type

    console.clear();
    console.log(leaveDetails);


    this.setAvailableLeaveBalanceByLeaveTypeEdit();

    setTimeout(() => {
      if (this.LeaveTypeDetails.EnableFileUploadOption == true) {
        if (this.LeaveTypeDetails.OnAppliedLeavePeriodExceeds < leaveDetails.totalDays) {
          this.isRequiredFileUpload = true;
        }
      }


      // if (leaveDetails.filePaths.length > 0) {
      //   this.isFileUploaded = true;
      //   this.isUploadedFileInvalidFormat = false;
      // }
    }, 1000);
    // this.setCalendarListDDLs()
    // debugger
    // if(this.DayTypeSelectList.length == 0){
    //   this.DayTypeSelectList.push({ value: 1, text: "Full Day" },{ value: 2, text: "Half Day" },{ value: 3, text: "Quarter Day" })

    // }

    let weekday = new Array(7);
    weekday[0] = "Sunday";
    weekday[1] = "Monday";
    weekday[2] = "Tuesday";
    weekday[3] = "Wednesday";
    weekday[4] = "Thursday";
    weekday[5] = "Friday";
    weekday[6] = "Saturday";

    for (let i = 0; i < leaveDetails.leaveDatesList.length; i++) {
      let _newDateItem = leaveDetails.leaveDatesList[i];
      let newData = new LeaveCalendarDates();
      newData.LeaveDate = _newDateItem.leaveDate;
      newData.DayType = _newDateItem.dayType;
      newData.Type = _newDateItem.type;
      newData.Time = _newDateItem.time;
      newData.Hours = _newDateItem.hours;
      newData.IsWeekEnd = false;

      let _tempDay = this.convertCSharpDateToFormattedDate(_newDateItem.leaveDate).getDay();
      let _day = weekday[_tempDay];
      if (_day == "Sunday" || _day == "Saturday") {
        newData.IsWeekEnd = true;
      }

      this.LeaveCalendarDatesList.push(newData);
    }

    // this._commonSer.OpenModalHandler("applyLeaveMode");
    this.submitted = false;
    this.submittedForFileUpload = false;
  }

  setAvailableLeaveBalanceByLeaveTypeEdit() {// Created By A Set Leave balance on edit
    debugger
    if (this.leaveApply.value.LeaveTypeId != null) {
      this.applyLeaveSelectedUserLeaveBalance.forEach(type => {
        if (type.leaveTypeId == this.leaveApply.value.LeaveTypeId) {
          this.showAvlLeave = type.availableLeaves + this.leaveApply.value.TotalDays;
          setTimeout(() => {
            this.calculateTotalLeaves();
          }, 300);
        }
      });
    }
  }


  CancelLeaveRequest() {// Created By A On cancle Leave
    this.cancelLeaveReason = "";
    this.isCancelReasonTextboxEmpty = false;

    this._commonSer.OpenModalHandler("confirmCancelModel");
  }
  ConfirmLeaveCancel() {// Created By A Initialize Confirmation modals
    debugger
    this.isCancelReasonTextboxEmpty = false;
    if (this.isCancelReasonMandatory && (this.cancelLeaveReason == null || this.cancelLeaveReason.trim().length == 0)) {
      this.isCancelReasonTextboxEmpty = true;
      return;
    }
    let currentUserID = this.currentUserId;
    this.parms = {
      LeaveId: this.leaveApply.value.leaveId,
      CancelReason: this.cancelLeaveReason,
      UserId: currentUserID,
      Cmpcode:this.CmpCode
    };
    this.userLoader = true;
    this.showSuccess = true;
    this._leaveSer.cancelLeaveRequest(this.parms).subscribe((response) => {
      this.userLoader = false;
      this.getAllLeave();
      if (response.success === true) {
        debugger;
        this.leaveApply.reset();
          this.showSuccess = true;
          this.showMessage = response.data;
          this.toaster.show('success',)
          this.toaster.show('warning',)
          if(this.showMessage=="The requested leave is cancelled successfully.")
          {
         this.toaster.show('success',this.showMessage)
         this._commonSer.CloseModalHandler("confirmCancelModel");
         this._commonSer.CloseModalHandler("applyLeaveEditMode");
          }
          // this._commonSer.OpenModalHandler("userMessageModel");

          this.userAvaliableLeave();
      } else {
        this.showSuccess = false;
        this.showMessage = response.message;
        this._commonSer.OpenModalHandler("applyLeaveEditMode");
        this._commonSer.CloseModalHandler("confirmCancelModel");
      }
    },
    error => {
      this.showSuccess = false;
      this.showMessage = "Uh oh, Something went wrong !";

      this._commonSer.OpenModalHandler("userMessageModel");
      this._commonSer.CloseModalHandler("confirmCancelModel");
      console.log(error);
    });
  }

 recieptIMg: any;
 userImage(item) {// Created By K Convert users images URL in standard format
   // <img src="/assets/img/Logo/male.png" style="width: 190px;">
   if (item.userImage == '' || item.userImage == undefined || item.userImage == null) {
     // this.recieptIMg = `${environment.siteUrl}` + "Uploads/Icon/UploadIcons.png";
     if (item.genders == "Female") {
       this.recieptIMg = "/assets/img/Logo/female.png"

     }
     else {
       this.recieptIMg = "/assets/img/Logo/male.png"

     }
   }
   else {
     this.recieptIMg = `${environment.siteUrl}` + item.userImage;
   }
   return this.recieptIMg

 }
}
