import {
  ValidatorFn,
  FormArray,
  FormControl,
  AbstractControl
} from "@angular/forms";

export class CustomValidators {
  public static minSelectedCheckboxes = (min = 1) => {
    const validator: ValidatorFn = (formArray: AbstractControl) => {
      if (formArray instanceof FormArray) {
      const totalSelected = formArray.controls
        .map(control => control.value)
        .reduce((prev, next) => (next ? prev + next : prev), 0);

      return totalSelected >= min ? null : { required: true };
      }
      throw new Error('formArray is not an instance of FormArray');
    };
    return validator;
  }
  public static integerValidator(control: FormControl) {
    const regex: RegExp = new RegExp(/^-?[0-9]*$/);
    if (regex.test(control.value)) {
      return null;
    }
    return {
      invalidInteger: true
    }
  }
  public static decimalValidator(control: FormControl) {
    if (+control.value >= 0 || +control.value <= 0) {
      return null;
    }
    return {
      invalidDecimal: true
    }
  }
  public static integerValuesValidator(control: FormControl) {
    const regex: RegExp = new RegExp(/^-?([0-9]*,*)*$/);
    if (regex.test(control.value)) {
      return null;
    }
    return {
      invalidInteger: true
    };
  }
  public static decimalValuesValidator(control: FormControl) {
    const regex: RegExp = new RegExp(/^(-?\d+\.\d+,?)+$/);
    if (regex.test(control.value)) {
      return null;
    }
    return {
      invalidDecimal: true
    };
  }
}
