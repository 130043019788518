<div class="bg-overlady" *ngIf="userLoader">
  <div class="loader-position">
    <div class="loader"></div>
    <h2>Loading....</h2>
  </div>
</div>
<!-- <hr class="new4"> -->
<mk-box header="" [isCollapsable]="false" [isRemovable]="false" contentStyleClasses="table-responsive">
  <div class="row" style="margin: 0; border-bottom: 1px solid #ddd; margin-bottom: 10px;">
    <div class="col-sm-3 no-padding">
      <h4 class="title-new" style="width: 200px;">Custom Leave Type</h4>
    </div>
    <div class="col-md-6 no-padding text-center">
      <ul class="new-version" style="padding-top: 5px;">
        <li><a style="cursor: pointer;" [ngClass]="{'activelegend': active === 1}" (click)="getTotalLeave(1)">Total</a> : <span class="value-get get-b">{{myRequestsListLength}}</span></li>
        <li> <a style="cursor: pointer;" [ngClass]="{'activelegend': active === 2}" (click)="getTotalLeave(2)">Active</a> : <span class="value-get get-g">{{ActiveLength}}</span></li>

        <li> <a style="cursor: pointer;" [ngClass]="{'activelegend': active === 3}" (click)="getTotalLeave(3)">In- Active</a> : <span class="value-get get-o">{{InActiveLength}}</span></li>
      </ul>
    </div>
    <div class="col-sm-3 text-right btnset" style="padding-top: 48px !important;">

      <button class="btn btn-primary btn-brand sm mx-auto" data-toggle="modal" data-backdrop="static"
        data-keyboard="false" data-target="#applyLeaveMode" *ngIf="AddPermission" (click)="OpenAddLeaveTypeForm()"
        type="Add" style="padding:7px 8px !important;">
        <img src="../assets/img/Logo/20.png" aria-hidden="true">
      </button>
      <span>&nbsp;&nbsp;&nbsp;</span>
      <!-- <button class="AddLeaveType mx-auto btn btn-primary btn-brand" type="button" id="navbarDropdown" (click)="filterShow()"  role="button" data-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false" title="Filter"> <img src="../assets/img/Logo/23.png" aria-hidden="true">
      </button> -->

      <!-- <div class="btn-group">
        <button type="button" class="AddLeaveType mx-auto btn btn-primary btn-brand" data-toggle="modal"
          data-target="#exampleModalfilter2" title="Filters" (click)="filterShow()">
          <img src="../assets/img/Logo/23.png">
        </button>
      </div> -->

      <!-- <div class="btn-group">
        <ul class="paggingUl">
          <ngb-pagination [collectionSize]="myRequestsListLength" [(page)]="myRequestsPage"
            [pageSize]="myRequestsPageSize"></ngb-pagination>
          <li style="    margin-top: -4px;">
            <span class="recordsMsg">No. of Records</span>
            <select class="NoOfpage" style="width: auto;" [(ngModel)]="myRequestsPageSize">
              <option [ngValue]="10">10</option>
              <option [ngValue]="20">20</option>
              <option [ngValue]="50">50</option>
            </select>
          </li>
        </ul>
      </div> -->

    </div>

    <!-- Filter Popup -->
    <!-- <div class="btn-group">
      <div class="container datashow rt-filters" *ngIf="flterShowData">
        <div class="card">
          <div class="card card-body">
            <div>
              <div class="row row-bor-bot">
                <div class="col-xs-9">
                  <h4>Filter</h4>
                </div>
                <div class="col-xs-3 text-right">
                  <h4 class="icon-close" (click)="closeFilter();">x</h4>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label for="status">Status</label>
                    <select [(ngModel)]="statusSelectValue" class="form-control statsuClass" style="margin-top: 10px;">
                      <option value=true selected="selected">Active</option>
                      <option value=false>InActive</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label for="employee">Employee</label>
                    <ng-select [allowClear]="true"  [(active)]="ActiveEmployee" [(items)]="EmployeeList"
                      (selected)="ddlSelection($event,'Employee','i','i')" placeholder="All Employees"
                      (removed)="removeFilterDropdownValue('Employee', $event)">
                    </ng-select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label for="depart">Department</label>
                    <ng-select [allowClear]="true"  [(active)]="ActiveDepartment" [(items)]="DepartmentList"
                      (selected)="ddlSelection($event,'Department','i','i')" placeholder="All Department"
                      (removed)="removeFilterDropdownValue('Department', $event)">
                    </ng-select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label for="location"  >Location</label>
                    <ng-select [allowClear]="true"  [(active)]="ActiveLocation" [(items)]="LocationList"
                      (selected)="ddlSelection($event,'Location','i','i')" placeholder="All Location"
                      (removed)="removeFilterDropdownValue('Location', $event)">
                    </ng-select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label for="desigation" >Desigation</label>
                    <ng-select [allowClear]="true"  [(active)]="ActiveDesigation" [(items)]="DesigationList"
                      (selected)="ddlSelection($event,'Desigation')" placeholder="All Desigation"
                      (removed)="removeFilterDropdownValue('Desigation', $event)">
                    </ng-select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label for="email">Role</label>
                    <ng-select [allowClear]="true"  [(active)]="ActiveRoles" [(items)]="RolesList"
                      (selected)="ddlSelection($event,'Roles','i','i')" placeholder="All Roles"
                      (removed)="removeFilterDropdownValue('Roles', $event)">
                    </ng-select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <button type="button" (click)="SideFilterFunc()" class="btn btn-primary btn-brand">Search</button>
                  <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  <button type="button" (click)="ResetFilter()" class="btn btn-default">Reset</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>

  <!-- Table -->
  <div class="row">
    <div class="col-md-12 no-padding">
      <span (click)="daysHoursFilter(1)" class="filter-links days"
        [ngClass]="{'active': (dayHrFilterValue == 1)}">Day</span>
      <span (click)="daysHoursFilter(2)" class="filter-links hours"
        [ngClass]="{'active': (dayHrFilterValue == 2)}">Hours</span>
    </div>
    <div class="col-sm-12 no-padding">
      <!-- <table class="table table-hover">
        <thead>
          <tr class="table-primary">
            <th scope="col" style="text-align: left;">Leave Name</th>
            <th scope="col" style="text-align: left;">Type</th>
            <th *ngIf="StatusPermission" scope="col" style="text-align: left;">Status</th>
            <th scope="col" style="text-align: left;">Action</th>
          </tr>
        </thead>
        <tbody style="text-align: left;">
          <tr
            *ngFor="let leaveType of allLeaveTypeList | slice: (myRequestsPage-1) * myRequestsPageSize : (myRequestsPage-1) * myRequestsPageSize + myRequestsPageSize; let i = index;">
            <td scope="row">
              <span><i class="fa fa-circle" [ngStyle]="{ color:leaveType.colorCode }"></i></span>
              <span>{{ leaveType.leaveTypeName }}</span>
            </td>
            <td>

              <span>{{ leaveType.typeText }}</span>
            </td>
            <td scope="row">

              <label class="switch1">
                <input type="checkbox" [checked]="leaveType.isActive" name="Status" (click)=" StatusPermission ? ChangeStatus(leaveType) : return">
                <span class="slider1 round"></span>
              </label>
            </td>
            <td scope="row" class="mx-auto">
              <a *ngIf="ViewPermission" href="javascript:void(0)" class="view" title="View" data-toggle="modal" data-target="#applyLeaveMode"
                data-backdrop="static" data-keyboard="false" (click)="viewLeaveType(leaveType)"><img src="../assets/img/Logo/25.png"></a>
              <a class="edit" *ngIf="leaveType.isActive == true && EditPermission" title="Edit" data-toggle="modal" data-target="#applyLeaveMode" data-backdrop="static"
                data-keyboard="false" (click)="editLeaveType(leaveType.leaveTypeId)"><img src="../assets/img/Logo/26.png"> </a>
              <a *ngIf="DeletePermission" href="javascript:void(0)" class="delete" title="Delete" (click)="deleteLeaveType(leaveType)"><img src="../assets/img/Logo/40.png"></a>
            </td>
          </tr>
        </tbody>
      </table> -->

      <ejs-grid #overviewgrid id='overviewgrid' [dataSource]='allLeaveTypeList' rowHeight='38' [allowSelection]='true'
        [allowSorting]='true' height='600' [enableHover]='false' [selectionSettings]='selectionSettings'
        [filterSettings]='filterSettings' [allowSorting]='true' [allowFiltering]='true' [enableVirtualization]='false'
        (load)='load()' [allowPaging]='true' [pageSettings]='pageSettings'>
        <e-columns>
          <e-column field='leaveTypeName' headerText='Leave Name' [filter]='filter' clipMode='EllipsisWithTooltip'>
            <ng-template #template let-leaveType>
              <span><i class="fa fa-circle" [ngStyle]="{ color:leaveType.colorCode }"></i></span>
              <span>{{ leaveType.leaveTypeName }}</span>
            </ng-template>

          </e-column>
          <e-column field='typeText' headerText='Type' [filter]='filter' clipMode='EllipsisWithTooltip'>
          </e-column>

          <e-column field='status' [allowFiltering]='false' headerText='Status' [allowSorting]='false'>
            <ng-template #template let-leaveType>
              <label class="switch1">
                <input type="checkbox" [checked]="leaveType.isActive" name="Status"
                  (click)=" StatusPermission ? ChangeStatus(leaveType) : return">
                <span class="slider1 round"></span>
              </label>
            </ng-template>
          </e-column>

          <!-- <e-column field='action' headerText='Action' [filter]='filterSettings'>
            <ng-template #template let-leaveType>
              <a *ngIf="ViewPermission" href="javascript:void(0)" class="view" title="View" data-toggle="modal"
                data-target="#applyLeaveMode" data-backdrop="static" data-keyboard="false"
                (click)="viewLeaveType(leaveType)"><img src="../assets/img/Logo/25.png"></a>
              <a class="edit" *ngIf="leaveType.isActive == true && EditPermission" title="Edit" data-toggle="modal"
                data-target="#applyLeaveMode" data-backdrop="static" data-keyboard="false"
                (click)="editLeaveType(leaveType.leaveTypeId)"><img src="../assets/img/Logo/26.png"> </a>
              <a *ngIf="DeletePermission" href="javascript:void(0)" class="delete" title="Delete"
                (click)="deleteLeaveType(leaveType)"><img src="../assets/img/Logo/40.png"></a>
            </ng-template>
          </e-column> -->

          <e-column field='action' headerText='Action' [allowFiltering]='false' textAlign='Center' width='100px'>
            <ng-template #template let-leaveType>

              <div class="btn-group btngg">
                <button type="button" class="btn action-button" data-toggle="dropdown" aria-haspopup="true"
                  aria-expanded="false" title="Action">
                  <span style="margin-top: -16px;">... </span>
                </button>
                <ul class="dropdown-menu actions-dropdown">
                  <li>
                    <a *ngIf="ViewPermission" href="javascript:void(0)" data-toggle="modal"
                      data-target="#applyLeaveMode" data-backdrop="static" data-keyboard="false"
                      (click)="viewLeaveType(leaveType.leaveTypeId)">View</a>
                  </li>

                  <li><a *ngIf="leaveType.isActive == true && EditPermission"
                      data-toggle="modal" data-target="#applyLeaveMode" data-backdrop="static" data-keyboard="false"
                      (click)="editLeaveType(leaveType.leaveTypeId)">Edit</a>
                  </li>

                  <li>
                    <a *ngIf="DeletePermission" href="javascript:void(0)"
                    (click)="deleteLeaveType(leaveType)">Delete</a>
                  </li>

                </ul>
              </div>

            </ng-template>
          </e-column>


        </e-columns>
      </ejs-grid>


    </div>
  </div>
</mk-box>

<div id="applyLeaveMode" class="modal fade" role="dialog">
  <div class="modal-dialog modal-lg" style="width: 1000px;">
    <div class="modal-content">
      <div class="modal-header">
        <button (click)="cloaseEditMode()" _ngcontent-c11="" class="close" data-dismiss="modal" type="button">
          ×
        </button>
        <h4 class="modal-title"> {{HeaderActionMsg}} Leave Type </h4>
      </div>
      <div class="modal-body model-bg user-modal-height col-xs-12 col-sm-12 less"
        *ngIf="tempLeaveType != null && tempLeaveType != undefined" style="border-radius: 0 0 10px 10px;">
        <div class="model-body col-xs-12 col-sm-12" style="padding: 0;">
          <div class="box-body" style="padding: 5px;">

            <form [formGroup]="leaveTypeForm" (ngSubmit)="saveLeaveType()">
              <div class="form-group row">
                <div class="col-md-4">
                  <label class="col-sm-12 col-form-label"> Name <span class="red">*</span></label>
                  <div class="col-sm-9">
                    <input type="text" class="e-input" [attr.disabled]="viewMode?'true':null && editMode? 'true':null"
                      id="inputEmail3" placeholder="Name" formControlName="LeaveTypeName">
                    <div class="invalidfeedback"
                      *ngIf="(leaveTypeControl.LeaveTypeName.touched || submitted) && leaveTypeControl.LeaveTypeName.errors?.required">
                      <small class="text-danger">Leave Type Name is required</small>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <input type="color" class="selectColorPicker"
                      [attr.disabled]="viewMode?'true':null && editMode? 'true':null" name="colorpicker" value="red"
                      formControlName="ColorCode" />
                  </div>
                </div>

                <div class="col-md-4">
                  <label class="col-sm-12 col-form-label">Code </label>
                  <div class="col-sm-12">
                    <input type="text" class="e-input" [attr.disabled]="viewMode?'true':null || editMode? 'true':null || leaveTyperequestCode? 'true':null"
                      formControlName="LeaveTypeCode" placeholder="Code">
                    <!-- <div class="invalidfeedback" *ngIf="(leaveTypeControl.LeaveTypeCode.touched || submitted) && leaveTypeControl.LeaveTypeCode.errors?.required">
                      <small class="text-danger"> Code is required</small>
                    </div> -->
                  </div>
                </div>

                <div class="col-md-4">
                  <label class="col-sm-12 col-form-label"> Type <span class="red">*</span> </label>
                  <div class="col-sm-12">
                    <ejs-dropdownlist id='Type255' [enabled]="LeaveTypeenable"  (change)="onChangeLeaveType($event);" formControlName="Type"
                      name="Type" [fields]='leavtypefields' [dataSource]='data'></ejs-dropdownlist>

                    <!-- <select class="form-control" [attr.disabled]="viewMode ? 'true' : null || editMode ? 'true' : null"
                      formControlName="Type" (change)="onChangeLeaveType($event);">
                      <option value='null' selected="selected" disabled> Select Type</option>
                      <option value="1" [attr.selected]="1">Paid</option>
                      <option value="2">Unpaid</option>
                      <option value="3">On Duty</option>
                      <option value="4">Restricted Holiday</option>
                      <option value="5" *ngIf="isCompOffExists == false">Compensatory Off</option>
                    </select> -->
                    <div class="invalidfeedback"
                      *ngIf="(leaveTypeControl.Type.touched || submitted) && leaveTypeControl.Type.errors?.required">
                      <small class="text-danger">Type is required</small>
                    </div>
                  </div>
                </div>


              </div>



              <div class="form-group row">


                <div class="col-md-4">
                  <label class="col-sm-12 col-form-label"> Unit <span class="red">*</span></label>
                  <div class="col-sm-12">
                    <div class="custom-control custom-radio custom-control-inline">
                      <input type="radio" class="custom-control-input" id="chckUnitDays"
                        [attr.disabled]="viewMode?'true':null || editMode? 'true':null" [(ngModel)]="LeaveTypeUnit"
                        name="Unit" formControlName="Unit" [value]=1 (change)="onChangeUnit($event, 1);" checked />
                      <label class="col-form-label mx-auto" style="margin-left: 10px;" for="chckUnitDays">Days</label>
                      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

                      <ng-container *ngIf="tempLeaveType.LType != '4'">
                        <input type="radio" class="custom-control-input" id="chckUnitHours"
                          [attr.disabled]="viewMode?'true':null || editMode? 'true':null" name="Unit"
                          formControlName="Unit" [value]=2 (change)="onChangeUnit($event, 2);" />
                        <label class="col-form-label mx-auto" style="margin-left: 10px;"
                          for="chckUnitHours">Hours</label>
                      </ng-container>
                    </div>
                    <div class="invalidfeedback"
                      *ngIf="(leaveTypeControl.Unit.touched || submitted) && leaveTypeControl.Unit.errors?.required">
                      <small class="text-danger">Unit is required</small>
                    </div>
                  </div>
                </div>




                <div class="col-md-4">
                  <label class="col-sm-12 col-form-label">Validity <span class="red">*</span></label>
                  <div class="col-sm-12">
                    <div class="row">
                      <div class="col-md-5 no-padding">


                          <!-- <input type="text" class="e-input"
                            [attr.disabled]="viewMode?'true':null || editMode? 'true':null"
                            formControlName="ValidityFrom" name="LeaveStartDate" placeholder="From" autocomplete="off"
                            bsDatepicker style="margin-top: 0; margin-bottom: 0;"
                            [bsConfig]="{dateInputFormat: 'MM-DD-YYYY',containerClass: 'theme-dark-blue', customTodayClass:'custom-today-class', showWeekNumbers: false }"
                            autocomplete="off" (bsValueChange)="datepickerStartDate($event)" bsDatepicker
                            id="dpLeaveStartDate1" required /> -->
                            <ejs-datepicker  [enabled]="LeaveTypeenable" [allowEdit]="false" formControlName="ValidityFrom"  id="LeaveStartDate" ></ejs-datepicker>

                        <div class="invalidfeedback"
                          *ngIf="(leaveTypeControl.ValidityFrom.touched || submitted) && leaveTypeControl.ValidityFrom.errors?.required">
                          <small class="text-danger">Effective date is required</small>
                        </div>
                      </div>
                      <div class="col-md-6" style="padding: 0 12px;">
                        <div class="form-group">


                            <!-- <input type="text" class="e-input"
                              [attr.disabled]="viewMode?'true':null || editMode? 'true':null"
                              formControlName="ValidityTo" name="LeaveEndDate" placeholder="To" [minDate]="minDate"
                              style="margin-top: 0; margin-bottom: 0;"
                              [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY',containerClass: 'theme-dark-blue',customTodayClass:'custom-today-class', showWeekNumbers: false }"
                              autocomplete="off" bsDatepicker (bsValueChange)="datepickerEndDate($event)"
                              id="dpLeaveEndDate" /> -->
                              <ejs-datepicker [enabled]="LeaveTypeenable" [allowEdit]="false" formControlName="ValidityTo" id="LeaveEndDate"  ></ejs-datepicker>

                          <div class="invalidfeedback"
                            *ngIf="(leaveTypeControl.ValidityTo.touched || submitted) && leaveTypeControl.ValidityTo.errors?.required">
                            <small class="text-danger">Validity To is required</small>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-1 no-padding">
                        <span
                          title="Report will be generated from the effective date.
Once added, effective  date cannot be edited." style="padding-top: 11px;
                          display: inline-block;"><i
                            class="fa fa-info-circle"></i></span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <label class="col-sm-12 col-form-label">Image<span class="red"></span></label>
                  <div class="col-sm-12">
                    <!-- <input type="file" name="file" formControlName="ImagePath" id="file" class="inputfile"
                      accept="image/*" (change)="onFileChanged($event)" />
                    <label for="file" class="selectText">Select File</label> -->
                    <div class="form-group row" style="margin-bottom: 0;">
                      <div class="dropdown rt-dropdown-img" style="margin-left: 0;">
                        <label class="col-form-label" data-toggle="dropdown">
                          <img src="{{tempLeaveType.ImagePath}}" />
                        </label>
                        <ul class="dropdown-menu">
                          <li (click)="setMoreOptionData('OpeningBalance', true)">
                            <table>
                              <tr>
                                <td>
                                  <img (click)="onImageSelect('assets/LeaveIcons/Icon_1.png')"
                                    [ngClass]="{'active': tempLeaveType.ImagePath === 'assets/LeaveIcons/Icon_1.png'}"
                                    src="assets/LeaveIcons/Icon_1.png" />
                                </td>
                                <td>
                                  <img (click)="onImageSelect('assets/LeaveIcons/Icon_5.png')"
                                    [ngClass]="{'active': tempLeaveType.ImagePath === 'assets/LeaveIcons/Icon_5.png'}"
                                    src="assets/LeaveIcons/Icon_5.png" />
                                </td>
                                <td>
                                  <img (click)="onImageSelect('assets/LeaveIcons/Icon_6.png')"
                                    [ngClass]="{'active': tempLeaveType.ImagePath === 'assets/LeaveIcons/Icon_6.png'}"
                                    src="assets/LeaveIcons/Icon_6.png" />
                                </td>
                                <td>
                                  <img (click)="onImageSelect('assets/LeaveIcons/Icon_7.png')"
                                    [ngClass]="{'active': tempLeaveType.ImagePath === 'assets/LeaveIcons/Icon_7.png'}"
                                    src="assets/LeaveIcons/Icon_7.png" />
                                </td>
                                <td>
                                  <img (click)="onImageSelect('assets/LeaveIcons/Icon_8.png')"
                                    [ngClass]="{'active': tempLeaveType.ImagePath === 'assets/LeaveIcons/Icon_8.png'}"
                                    src="assets/LeaveIcons/Icon_8.png" />
                                </td>
                                <td>
                                  <img (click)="onImageSelect('assets/LeaveIcons/Icon_9.png')"
                                    [ngClass]="{'active': tempLeaveType.ImagePath === 'assets/LeaveIcons/Icon_9.png'}"
                                    src="assets/LeaveIcons/Icon_9.png" />
                                </td>
                                <td>
                                  <img (click)="onImageSelect('assets/LeaveIcons/Icon_10.png')"
                                    [ngClass]="{'active': tempLeaveType.ImagePath === 'assets/LeaveIcons/Icon_10.png'}"
                                    src="assets/LeaveIcons/Icon_10.png" />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <img (click)="onImageSelect('assets/LeaveIcons/Icon_11.png')"
                                    [ngClass]="{'active': tempLeaveType.ImagePath === 'assets/LeaveIcons/Icon_11.png'}"
                                    src="assets/LeaveIcons/Icon_11.png" />
                                </td>
                                <td>
                                  <img (click)="onImageSelect('assets/LeaveIcons/Icon_12.png')"
                                    [ngClass]="{'active': tempLeaveType.ImagePath === 'assets/LeaveIcons/Icon_12.png'}"
                                    src="assets/LeaveIcons/Icon_12.png" />
                                </td>
                                <td>
                                  <img (click)="onImageSelect('assets/LeaveIcons/Icon_13.png')"
                                    [ngClass]="{'active': tempLeaveType.ImagePath === 'assets/LeaveIcons/Icon_13.png'}"
                                    src="assets/LeaveIcons/Icon_13.png" />
                                </td>
                                <td>
                                  <img (click)="onImageSelect('assets/LeaveIcons/Icon_31.png')"
                                    [ngClass]="{'active': tempLeaveType.ImagePath === 'assets/LeaveIcons/Icon_31.png'}"
                                    src="assets/LeaveIcons/Icon_31.png" />
                                </td>
                                <td>
                                  <img (click)="onImageSelect('assets/LeaveIcons/Icon_41.png')"
                                    [ngClass]="{'active': tempLeaveType.ImagePath === 'assets/LeaveIcons/Icon_41.png'}"
                                    src="assets/LeaveIcons/Icon_41.png" />
                                </td>
                                <td>
                                  <img (click)="onImageSelect('assets/LeaveIcons/Icon_51.png')"
                                    [ngClass]="{'active': tempLeaveType.ImagePath === 'assets/LeaveIcons/Icon_51.png'}"
                                    src="assets/LeaveIcons/Icon_51.png" />
                                </td>
                                <td>
                                  <img (click)="onImageSelect('assets/LeaveIcons/Loss-of-Pay.png')"
                                    [ngClass]="{'active': tempLeaveType.ImagePath === 'assets/LeaveIcons/Loss-of-Pay.png'}"
                                    src="assets/LeaveIcons/Loss-of-Pay.png" />
                                </td>


                              </tr>
                              <tr>
                                <td>
                                  <img (click)="onImageSelect('assets/LeaveIcons/Short-Leave.png')"
                                    [ngClass]="{'active': tempLeaveType.ImagePath === 'assets/LeaveIcons/Short-Leave.png'}"
                                    src="assets/LeaveIcons/Short-Leave.png" />
                                </td>
                                <td>
                                  <img (click)="onImageSelect('assets/LeaveIcons/Compensatory-off.png')"
                                    [ngClass]="{'active': tempLeaveType.ImagePath === 'assets/LeaveIcons/Compensatory-off.png'}"
                                    src="assets/LeaveIcons/Compensatory-off.png" />
                                </td>
                                <td>
                                  <img (click)="onImageSelect('assets/LeaveIcons/Delight-Leave.png')"
                                    [ngClass]="{'active': tempLeaveType.ImagePath === 'assets/LeaveIcons/Delight-Leave.png'}"
                                    src="assets/LeaveIcons/Delight-Leave.png" />
                                </td>
                                <td>
                                  <img (click)="onImageSelect('assets/LeaveIcons/Earned-Leave.png')"
                                    [ngClass]="{'active': tempLeaveType.ImagePath === 'assets/LeaveIcons/Earned-Leave.png'}"
                                    src="assets/LeaveIcons/Earned-Leave.png" />
                                </td>
                              </tr>
                            </table>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

              </div>


              <div class="form-group row">
                <label class="col-sm-12 col-form-label">Description</label>
                <div class="col-sm-12">
                  <textarea class="e-input rounded-0" [attr.disabled]="viewMode?'true':null && editMode? 'true':null"
                    formControlName="Description" id="exampleFormControlTextarea2" rows="4" style="height: 20px;"></textarea>
                </div>
              </div>



              <div class="row text-right">
                <button href="#Bar" class="btn btn-default" data-toggle="collapse" style="background: transparent; border: none; text-decoration: underline;margin-bottom: 10px; color: #1989e7;  margin-bottom: 5px;margin-top: -15px; ">More Settings</button>

              </div>




              <!-- Tabs -->
              <div class="row collapse" id="Bar">

                <div class="col-md-12">
                  <div class="inner-support">

                    <ul class="nav nav-tabs" role="tablist">
                      <li class="nav-item active">
                        <a class="nav-link active" data-toggle="tab" href="#home4" role="tab">Entitlement</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link"  data-toggle="tab" href="#profile" role="tab">Applicable </a>
                      </li>

                      <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#rest" role="tab">Restrictions </a>
                      </li>
                       <!-- <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#exception" role="tab">Exceptions </a>
                      </li> -->

                    </ul>

                    <!-- Tab panes -->
                    <div class="tab-content">
                      <div class="tab-pane active" id="home4" role="tabpanel">
                        <div class="card-body">
                          <div class="form-group" style="margin-top: 8px;">
                            <div class="row">
                              <div class="col-sm-1 no-padding" style="width: 90px;">
                                <label for="EffectiveAfter" class="col-form-label spacelabel">Effective After</label>
                              </div>
                              <div class="col-sm-2">
                                <input type="text" id="EffectiveAfter" name="EffectiveAfter"
                                  (keypress)="numberOnly($event)"
                                  [attr.disabled]="viewMode?'true':null && editMode? 'true':null"
                                  formControlName="EffectiveAfter" class="form-control">
                              </div>
                              <div class="col-sm-2 no-padding">
                                <!-- <select class="form-control" formControlName="EffectiveAfterPeriod"
                                  [attr.disabled]="viewMode?'true':null && editMode? 'true':null"
                                  [(ngModel)]="selectedEfficetiveHours">

                                  <option *ngFor="let item of effictiveYears" value="{{item.value}}"
                                    [attr.selected]="item.value==1">{{ item.name }}</option>
                                </select> -->
                                <ejs-dropdownlist  [enabled]="NeweditMode"
                                formControlName="EffectiveAfterPeriod" [fields]='effictiveYearsfield' [dataSource]='effictiveYears'>
                                </ejs-dropdownlist>
                              </div>
                              <div class="col-sm-1">
                                <label for="inputEmail3" class="col-sm-2 col-form-label spacelabel"> From </label>
                              </div>
                              <div class="col-sm-2 no-padding">
                                <!-- <select class="form-control" formControlName="EffectiveAfterFrom"
                                  [attr.disabled]="viewMode?'true':null && editMode? 'true':null"
                                  [(ngModel)]="selectEfficetiveFrom">

                                  <option value="1" [attr.selected]="1">Date of Joining</option>
                                  <option value="2">Date of Confirmation</option>
                                </select> -->
                                <ejs-dropdownlist  [enabled]="NeweditMode"
                                formControlName="EffectiveAfterFrom" [fields]='effictiveAfterfromfield' [dataSource]='effictiveAfterfrom'>
                               </ejs-dropdownlist>
                              </div>
                            </div>
                          </div>

                          <div class="form-group" style="margin-top: 14px;">
                            <div class="row">
                              <div class="col-sm-1 no-padding" style="padding-right: 0;">
                                <div class="custom-control custom-checkbox custom-control-inline">
                                  <!-- <input type="checkbox" id="isAccural" class="custom-control-input" formControlName="IsAccural" [attr.disabled]="viewMode?'true':null && editMode? 'true':null"
                                    [checked]="IsAccuralChange" (change)="IsAccuralChange = !IsAccuralChange" name="IsAccural" /> -->
                                  <input type="checkbox" id="isAccural" class="custom-control-input"
                                    formControlName="IsAccural"
                                    [attr.disabled]="viewMode?'true':null && editMode? 'true':null"
                                    [checked]="tempLeaveType.IsAccural" (change)="onChangeIsAccural($event);"
                                    name="IsAccural" />
                                  <label class="col-form-label mx-auto" style="margin-left: 10px;"
                                    for="isAccural">Accrual</label>
                                </div>
                              </div>
                              <div class="col-sm-2" *ngIf="tempLeaveType.IsAccural == true">
                                <!-- <select class="form-control"
                                  [attr.disabled]="viewMode?'true':null && editMode? 'true':null" name="AccuralPeriod"
                                  formControlName="AccuralPeriod" (change)="onChangeAccuralPeriod($event);">
                                  <option value=1>One Time</option>
                                  <option value=2>Yearly</option>
                                  <option value=3>Monthly</option>
                                  <option value=4>Half Yearly</option>
                                  <option value=5>Triannually</option>
                                  <option value=6>Quarterly</option>
                                  <option value=7>Bi-Monthly</option>
                                  <option value=8>Semi Monthly</option>
                                  <option value=9>Bi-Weekly</option>
                                  <option value=10>Weekly</option>
                                </select> -->
                                <ejs-dropdownlist id='AccuralPeriod' [enabled]="NeweditMode"  (change)="onChangeAccuralPeriod($event)"
                                formControlName="AccuralPeriod" [fields]='AccuralPeriodfield' [dataSource]='AccuralPeriod'>
                              </ejs-dropdownlist>
                              </div>

                              <div  class="col-sm-4 no-padding"  style="display: inline-flex;justify-content: space-between; width: auto;">
                                <!-- <div class="das" *ngIf="tempLeaveType.IsAccural == true ">
                                  <label class="col-form-label spacelabel"  style="    margin-right: 10px;"> on </label>
                                </div> -->

                              <div class="das" *ngIf="tempLeaveType.IsAccural == true" style="display: inline-flex;
                              align-items: center;">
                                <label  style="margin-right: 10px;"> on </label>

                                <!-- <select class="form-control"
                                  [attr.disabled]="viewMode?'true':null && editMode? 'true':null"
                                  formControlName="AccuralDay">
                                  <option [selected]=" isEditMode? AccuralDay:'' || IsAccuralChange? days==1:''"
                                    *ngFor="let days of AccuralDayList; let i= index" [ngValue]="i+1">{{days}}</option>
                                  <option *ngFor="let item of ddlAccuralDaysList;" [ngValue]="item.id">{{ item.text }}
                                  </option>
                                </select> -->
                                <!-- {{ ddlAccuralDaysList | json }} -->
                                <ejs-dropdownlist [enabled]="NeweditMode"
                                formControlName="AccuralDay" [fields]='ddlAccuralDaysListfield' [dataSource]='ddlAccuralDaysList'>
                              </ejs-dropdownlist>
                              </div>
                              <div class="das"
                                *ngIf="tempLeaveType.IsAccural == true && (tempLeaveType.AccuralPeriod != 3 && tempLeaveType.AccuralPeriod != 8 && tempLeaveType.AccuralPeriod != 9 && tempLeaveType.AccuralPeriod != 10)">
                                <!-- <select class="form-control"
                                  [attr.disabled]="viewMode?'true':null && editMode? 'true':null"
                                  formControlName="AccuralMonth">
                                  <option *ngFor="let item of ddlAccuralMonthsList"
                                    [selected]="isEditMode ? AccuralMonth : ''" [ngValue]="item.value">{{ item.name }}
                                  </option>
                                </select> -->
                                <ejs-dropdownlist [enabled]="NeweditMode"
                                formControlName="AccuralMonth" [fields]='ddlAccuralMonthsListfield' [dataSource]='ddlAccuralMonthsList'>
                              </ejs-dropdownlist>
                              </div>
                            </div>


                              <div class="col-sm-1" style="white-space: nowrap" *ngIf="tempLeaveType.IsAccural == true">
                                <label for="noofdays" class="col-form-label spacelabel" *ngIf="tempLeaveType.Unit == 1"> No. of Days
                                </label>
                                <label for="noofdays" class="col-form-label spacelabel" *ngIf="tempLeaveType.Unit == 2"> No. of Hours
                                </label>
                              </div>
                              <div class="col-sm-1" *ngIf="tempLeaveType.IsAccural == true">
                                <input style="padding-bottom: 4px !important; min-height: auto !important;" type="text" name="noofdays" (keypress)="numberOnlyWithDecimal($event)"
                                  [attr.disabled]="viewMode?'true':null && editMode? 'true':null"
                                  formControlName="AccuralNoOfDays" value="0" class="form-control"
                                  (change)="onchangeAccuralNoOfDays($event);" />
                              </div>
                              <div class="col-sm-1" style="position: relative; top: 12px; width: 10px;"
                                *ngIf="tempLeaveType.IsAccural == true && (tempLeaveType.AccuralPeriod == 3 || tempLeaveType.AccuralPeriod == 8 || tempLeaveType.AccuralPeriod == 9 || tempLeaveType.AccuralPeriod == 10)">
                                <label class="col-form-label" style="cursor: pointer;"
                                  (click)="openEntitlementCalendarPopup();"><i class="fa fa-calendar"></i></label>
                              </div>
                              <!-- <div class="col-sm-1" style="width: 11px;"
                                *ngIf="tempLeaveType.IsAccural == true && tempLeaveType.AccuralPeriod != 3 || isCheck == true">
                                <label for="AccuralDaysInPeriod"  class="col-form-label spacelabel"> in </label>
                              </div> -->
                              <div class="col-sm-2" style="display: inline-flex;justify-content: space-between"
                                *ngIf="tempLeaveType.IsAccural == true && tempLeaveType.AccuralPeriod != 1">
                                <label *ngIf="tempLeaveType.IsAccural == true && tempLeaveType.AccuralPeriod != 3 || isCheck == true" for="AccuralDaysInPeriod"  class="col-form-label spacelabel" style="    margin-right: 5px;"> in </label>
                                <!-- <select class="form-control" name="AccuralDaysInPeriod"
                                  [attr.disabled]=" viewMode?'true':null && editMode? 'true':null"
                                  formControlName="AccuralDaysInPeriod">
                                  <option [ngValue]="1">Current Accrual</option>
                                  <option [ngValue]="2">Next Accrual</option>
                                </select> -->
                                <ejs-dropdownlist name="AccuralDaysInPeriod" [enabled]="NeweditMode"
                                formControlName="AccuralDaysInPeriod" [fields]='AccuralDaysInPeriodfield' [dataSource]='AccuralDaysInPeriod'>
                              </ejs-dropdownlist>
                              </div>

                          </div>
                        </div>

                          <div class="form-group" style="margin-top: 10px;">
                            <div class="row">
                              <div class="col-sm-1 no-padding" style="padding-right: 0;">
                                <div class="custom-control custom-checkbox custom-control-inline">
                                  <!-- <input type="checkbox" id="IsReset" class="custom-control-input" formControlName="IsReset" [attr.disabled]="viewMode?'true':null && editMode? 'true':null"
                                    (change)="isResetChange = !isResetChange" name="IsReset" /> -->
                                  <input type="checkbox" id="IsReset" class="custom-control-input"
                                    formControlName="IsReset"
                                    [attr.disabled]="viewMode?'true':null && editMode? 'true':null"
                                    [checked]="tempLeaveType.IsReset" (change)="onChangeIsReset($event);"
                                    name="IsReset" />
                                  <label class="col-form-label mx-auto" style="margin-left: 10px;"
                                    for="IsReset">Reset</label>
                                </div>
                              </div>
                              <div class="col-sm-2" *ngIf="tempLeaveType.IsReset == true">
                                <!-- <select class="form-control"
                                  [attr.disabled]="viewMode?'true':null && editMode? 'true':null"
                                  formControlName="ResetPeriod" (change)="onChangeResetPeriod($event);">
                                  <option value=1>One Time</option>
                                  <option value=2>Yearly</option>
                                  <option value=3>Monthly</option>
                                  <option value=4>Half Yearly</option>
                                  <option value=5>Triannually</option>
                                  <option value=6>Quarterly</option>
                                  <option value=7>Bi-Monthly</option>
                                  <option value=8>Semi Monthly</option>
                                  <option value=9>Bi-Weekly</option>
                                  <option value=10>Weekly</option>
                                </select> -->
                                <ejs-dropdownlist id='ResetPeriod' [enabled]="NeweditMode"  (change)="onChangeResetPeriod($event)"
                                formControlName="ResetPeriod" [fields]='ResetPeriodfield' [dataSource]='ResetPeriod'>
                              </ejs-dropdownlist>
                              </div>
                              <div class="col-sm-1" *ngIf="tempLeaveType.IsReset == true">
                                <label class="col-form-label spacelabel"> on </label>
                              </div>
                              <div class="col-sm-2 no-padding" *ngIf="tempLeaveType.IsReset == true">
                                <!-- <select class="form-control"
                                  [attr.disabled]="viewMode?'true':null && editMode? 'true':null"
                                  formControlName="ResetDay">
                                  <option *ngFor="let item of ddlResetDaysList;" [ngValue]="item.id">{{ item.text }}
                                  </option>
                                </select> -->
                                <ejs-dropdownlist [enabled]="NeweditMode"
                                formControlName="ResetDay" [fields]='ddlResetDaysListfield' [dataSource]='ddlResetDaysList'>
                              </ejs-dropdownlist>
                              </div>
                              <div class="col-sm-2"
                                *ngIf="tempLeaveType.IsReset == true && (tempLeaveType.ResetPeriod != 3 && tempLeaveType.ResetPeriod != 8 && tempLeaveType.ResetPeriod != 9 && tempLeaveType.ResetPeriod != 10)">
                                <!-- <select class="form-control"
                                  [attr.disabled]="viewMode?'true':null && editMode? 'true':null"
                                  formControlName="ResetMonth">
                                   <option *ngFor="let mon of MonthList" [ngValue]="isEditMode? ResetMonth:'' || mon.value" [selected]="isResetChange ? mon.value==1 : ''">{{mon.name}}</option>
                                  <option *ngFor="let item of ddlResetMonthsList"
                                    [selected]="isEditMode ? ResetMonth : ''" [ngValue]="item.value">{{ item.name }}
                                  </option>
                                </select> -->
                                <ejs-dropdownlist [enabled]="NeweditMode"
                                formControlName="ResetMonth" [fields]='ddlResetMonthsListfield' [dataSource]='ddlResetMonthsList'>
                              </ejs-dropdownlist>
                              </div>
                            </div>
                          </div>

                          <div class="form-group" style="margin-top: 10px;" *ngIf="tempLeaveType.IsReset == true">
                            <div class="row">
                              <div class="col-sm-1 col-form-label no-padding">
                                &nbsp;
                              </div>
                              <div class="col-sm-3">
                                <!-- <select class="form-control"
                                  [attr.disabled]="viewMode?'true':null && editMode? 'true':null"
                                  formControlName="CarryForwardType" (change)="onChangeCarryForwardType($event);">
                                  <option value="1">Carry Forward</option>
                                  <option value="2">Carry Forward With Expiry</option>
                                  <option value="3">Carry Forward With Overall Limit</option>
                                </select> -->
                                <ejs-dropdownlist [enabled]="NeweditMode" (change)="onChangeCarryForwardType($event);"
                                formControlName="CarryForwardType" [fields]='CarryForwardTypefield' [dataSource]='CarryForwardType'>
                              </ejs-dropdownlist>
                              </div>
                              <div class="col-sm-1" style="width: 100px;">
                                <input type="text" name="crValue" (keypress)="numberOnlyWithDecimal($event)"
                                  [attr.disabled]="viewMode?'true':null && editMode? 'true':null"
                                  formControlName="CarryForwardValue" class="form-control" />
                              </div>
                              <div class="col-sm-2">
                                <!-- <select class="form-control"
                                  [attr.disabled]="viewMode?'true':null && editMode? 'true':null"
                                  formControlName="CarryForwardPercentageUnit"
                                  (change)="onChangeCarryForwardPercentageUnit($event);">
                                  <option value=1>Percentage</option>
                                  <option value=2>Unit(s)</option>
                                </select> -->
                                <ejs-dropdownlist [enabled]="NeweditMode" (change)="onChangeCarryForwardPercentageUnit($event);"
                                formControlName="CarryForwardPercentageUnit" [fields]='CarryForwardPercentagefield' [dataSource]='CarryForwardPercentage'>
                              </ejs-dropdownlist>
                              </div>
                              <div class="col-sm-1 no-padding" *ngIf="tempLeaveType.CarryForwardPercentageUnit != 2">
                                <label class="col-form-label spacelabel"> Max. Limit </label>
                              </div>
                              <div class="col-sm-1" *ngIf="tempLeaveType.CarryForwardPercentageUnit != 2">
                                <input type="text" name="CarrForLimit" style="margin-top: -5px;" (keypress)="numberOnlyWithDecimal($event)"
                                  [attr.disabled]=" viewMode?'true':null && editMode? 'true':null"
                                  formControlName="CarryForwardMaxLimit" class="form-control" />
                              </div>


                                <div class="col-sm-1 no-padding" *ngIf="tempLeaveType.CarryForwardType == 2">
                                  <label class="col-form-label spacelabel">Expires in</label>
                                </div>
                                <div class="col-sm-1 no-padding" style="width: 50px; margin: -2px 9px 0 0;" *ngIf="tempLeaveType.CarryForwardType == 2">
                                  <input type="text" name="CarryForwardExpiresIn" (keypress)="numberOnly($event)"
                                    [attr.disabled]=" viewMode?'true':null && editMode? 'true':null"
                                    formControlName="CarryForwardExpiresIn" class="form-control" />
                                </div>
                                <div class="col-sm-1 no-padding" *ngIf="tempLeaveType.CarryForwardType == 2">
                                  <!-- <select class="form-control"
                                    [attr.disabled]="viewMode?'true':null && editMode? 'true':null"
                                    formControlName="CarryForwardExpiresInType">
                                    <option value=1>Month(s)</option>
                                    <option value=2>Day(s)</option>
                                  </select> -->
                                  <ejs-dropdownlist [enabled]="NeweditMode"
                                  formControlName="CarryForwardExpiresInType" [fields]='CarryForwardExpiresinfield' [dataSource]='CarryForwardExpiresin'>
                                </ejs-dropdownlist>
                                </div>


                                <div class="col-sm-1 no-padding" *ngIf="tempLeaveType.CarryForwardType == 3">
                                  <label class="col-form-label spacelabel">Overall Limit</label>
                                </div>
                                <div class="col-sm-1 no-padding" style="width: 60px;" *ngIf="tempLeaveType.CarryForwardType == 3">
                                  <input type="text" name="CarryForwardOverallLimit"
                                    (keypress)="numberOnlyWithDecimal($event)"
                                    [attr.disabled]=" viewMode?'true':null && editMode? 'true':null"
                                    formControlName="CarryForwardOverallLimit" class="form-control" />
                                </div>


                            </div>
                          </div>

                          <div class="form-group" style="margin-top: 10px;" *ngIf="tempLeaveType.IsReset == true">
                            <div class="row">
                              <div class="col-sm-1 col-form-label no-padding">
                                &nbsp;
                              </div>
                              <div class="col-sm-2">
                                <label for="encash" class="col-form-label spacelabel">Encashment</label>
                              </div>
                              <div class="col-sm-1" style="width: 100px;">
                                <input type="text" name="encash" (keypress)="numberOnlyWithDecimal($event)"
                                  [attr.disabled]="viewMode?'true':null && editMode? 'true':null"
                                  formControlName="EncashmentValue" class="form-control">
                              </div>
                              <div class="col-sm-2">
                                <!-- <select class="form-control"
                                  [attr.disabled]="viewMode?'true':null && editMode? 'true':null"
                                  formControlName="EncashmentPercentageUnit"
                                  (change)="onChangeEncashmentPercentageUnit($event);">
                                  <option value=1>Percentage</option>
                                  <option value=2>Unit(s)</option>
                                </select> -->
                                <ejs-dropdownlist [enabled]="NeweditMode" (change)="onChangeEncashmentPercentageUnit($event);"
                                formControlName="EncashmentPercentageUnit" [fields]='EncashmentPercentageUnitfield' [dataSource]='EncashmentPercentageUnitdata'>
                              </ejs-dropdownlist>
                              </div>
                              <div class="col-sm-1 no-padding" *ngIf="tempLeaveType.EncashmentPercentageUnit != 2">
                                <label class="col-form-label spacelabel"> Max. Limit </label>
                              </div>
                              <div class="col-sm-2" *ngIf="tempLeaveType.EncashmentPercentageUnit != 2">
                                <input type="text" name="encash" style="margin-top: -5px;" (keypress)="numberOnlyWithDecimal($event)"
                                  [attr.disabled]="viewMode?'true':null && editMode? 'true':null"
                                  formControlName="EncashmentMaxLimit" class="form-control">
                              </div>
                            </div>
                          </div>

                          <!-- Opening Balance -->
                          <ng-container *ngIf="IsOpeningBalanceEnable == true">
                            <div class="form-group" style="margin-top: 30px;">
                              <div class="row">
                                <div class="col-sm-3">
                                  <label class="col-form-label ico-remove"
                                    (click)="setMoreOptionData('OpeningBalance', false)"><i
                                      class="fa fa-minus-square"></i></label>
                                  <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                  <label class="col-form-label">Opening Balance</label>
                                </div>
                                <div class="col-sm-2">
                                  <input type="text" id="OpeningBalance" name="OpeningBalance"
                                    (keypress)="numberOnlyWithDecimal($event)"
                                    [attr.disabled]="viewMode?'true':null && editMode? 'true':null"
                                    formControlName="OpeningBalance" class="form-control" />
                                </div>
                              </div>
                            </div>
                          </ng-container>

                          <!-- Maximum Balance -->
                          <ng-container *ngIf="IsMaximumBalanceEnable == true">
                            <div class="form-group" style="margin-top: 30px;">
                              <div class="row">
                                <div class="col-sm-3">
                                  <label class="col-form-label ico-remove"
                                    (click)="setMoreOptionData('MaximumBalance', false)"><i
                                      class="fa fa-minus-square"></i></label>
                                  <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                  <label class="col-form-label">Maximum Balance</label>
                                </div>
                                <div class="col-sm-2">
                                  <input type="text" id="MaximumBalance" name="MaximumBalance"
                                    (keypress)="numberOnlyWithDecimal($event)"
                                    [attr.disabled]="viewMode?'true':null && editMode? 'true':null"
                                    formControlName="MaximumBalance" class="form-control" />
                                </div>
                              </div>
                            </div>
                          </ng-container>

                          <!-- Prorate Accrual -->
                          <ng-container *ngIf="IsProrateAccuralEnable == true">
                            <div class="form-group" style="margin-top: 30px;">
                              <div class="row">
                                <div class="col-sm-3">
                                  <label class="col-form-label ico-remove"
                                    (click)="setMoreOptionData('ProrateAccural', false)"><i
                                      class="fa fa-minus-square"></i></label>
                                  <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                  <label class="col-form-label">Prorate Accrual</label>
                                </div>
                                <div class="col-sm-3">
                                  <!-- <select class="form-control"
                                    [attr.disabled]="viewMode?'true':null && editMode? 'true':null"
                                    formControlName="ProrateAccuralType" (change)="setprorateAccuralTypeValue($event);">
                                    <option [ngValue]="1">Start of Policy</option>
                                    <option [ngValue]="2">Start and End of Policy</option>
                                    <option [ngValue]="3">Do not Prorate</option>
                                  </select> -->
                                  <ejs-dropdownlist [enabled]="NeweditMode" (change)="setprorateAccuralTypeValue($event)"
                                  formControlName="ProrateAccuralType" [fields]='ProrateAccuralTypefield' [dataSource]='ProrateAccuralTypedata'>
                                </ejs-dropdownlist>
                                </div>
                                <div class="col-sm-1">
                                  <label class="col-form-label" *ngIf="prorateAccuralType != '3'"
                                    style="color: rgb(51, 153, 148); cursor: pointer;"
                                    (click)="openProrateAccuralModal();">Advanced </label>
                                </div>
                              </div>
                            </div>
                          </ng-container>

                          <!-- Deductible Holidays -->
                          <ng-container *ngIf="IsDeductibleHolidaysEnable == true">
                            <div class="form-group" style="margin-top: 30px;">
                              <div class="row">
                                <div class="col-sm-3">
                                  <label class="col-form-label ico-remove"
                                    (click)="setMoreOptionData('DeductibleHolidays', false)"><i
                                      class="fa fa-minus-square"></i></label>
                                  <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                  <label class="col-form-label">Deductible Holidays</label>
                                </div>
                                <div class="col-sm-3">
                                  <!-- <select class="form-control"
                                    [attr.disabled]="viewMode?'true':null && editMode? 'true':null"
                                    formControlName="DeductibleHolidays">
                                    <option [ngValue]="1">All Holidays</option>
                                    <option [ngValue]="2">Holidays on Workdays</option>
                                  </select> -->
                                  <ejs-dropdownlist [enabled]="NeweditMode"
                                  formControlName="DeductibleHolidays" [fields]='DeductibleHolidaysfield' [dataSource]='DeductibleHolidaysdata'>
                                </ejs-dropdownlist>
                                </div>
                                <div class="col-sm-1">
                                </div>
                              </div>
                            </div>
                          </ng-container>

                          <div class="form-group row" style="margin-top: 30px;" *ngIf="IsOpeningBalanceEnable != true ||
                              IsMaximumBalanceEnable != true ||
                              IsProrateAccuralEnable != true ||
                              IsDeductibleHolidaysEnable != true">
                            <div class="dropdown rt-dropdown">
                              <label class="col-form-label" data-toggle="dropdown"
                                style="color: rgb(51, 153, 148); cursor: pointer;">More Option <i class="fa fa-plus"
                                  aria-hidden="true"></i></label>
                              <ul class="dropdown-menu">
                                <li *ngIf="IsOpeningBalanceEnable != true"
                                  (click)="setMoreOptionData('OpeningBalance', true)">Add Opening Balance</li>
                                <li *ngIf="IsMaximumBalanceEnable != true"
                                  (click)="setMoreOptionData('MaximumBalance', true)">Set Maximum Balance</li>
                                <li *ngIf="IsProrateAccuralEnable != true"
                                  (click)="setMoreOptionData('ProrateAccural', true)">Prorate Accrual</li>
                                <li *ngIf="IsDeductibleHolidaysEnable != true"
                                  (click)="setMoreOptionData('DeductibleHolidays', true)">Deductible Holidays</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="tab-pane" id="profile" role="tabpanel">
                        <div class="card-body">
                          <div class="row" style="margin-top: 15px;">
                            <div class="col-sm-12">

                              <ul class="nav nav-tabs" role="tablist">
                                <li class="nav-item active">
                                  <a class="nav-link active"  data-toggle="tab" href="#applicable" role="tab">Applicable</a>
                                </li>
                                <li class="nav-item">
                                  <a class="nav-link" data-toggle="tab" href="#exceptions" role="tab">Exceptions</a>
                                </li>


                              </ul>

                              <div class="tab-content tabs-inner">
                                <div class="tab-pane active" id="applicable" role="tabpanel">
                                  <div class="card-body">
                                      <div class="form-group" style="margin-top: 20px;">
                                        <div class="row">
                                          <div class="col-sm-2 no-padding">
                                            <label for="applicableGender"> Gender </label>
                                          </div>

                                          <div class="col-sm-10">
                                            <div class="custom-control custom-checkbox custom-control-inline">
                                              <input type="checkbox" class="custom-control-input"
                                                id="chkApplicableGenderMale" formControlName="ApplicableGenderMale"
                                                [attr.disabled]=" viewMode?'true':null && editMode? 'true':null"
                                                name="ApplicableGenderMale" />
                                              <label class="col-form-label mx-auto" style="margin-left: 10px;"
                                                for="chkApplicableGenderMale">Male</label>
                                              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

                                              <input type="checkbox" class="custom-control-input"
                                                id="chkApplicableGenderFemale" formControlName="ApplicableGenderFemale"
                                                [attr.disabled]=" viewMode?'true':null && editMode? 'true':null"
                                                name="ApplicableGenderFemale" />
                                              <label class="col-form-label mx-auto" style="margin-left: 10px;"
                                                for="chkApplicableGenderFemale">Female</label>
                                              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

                                              <input type="checkbox" class="custom-control-input"
                                                id="chkApplicableGenderOthers" formControlName="ApplicableGenderOthers"
                                                [attr.disabled]=" viewMode?'true':null && editMode? 'true':null"
                                                name="ApplicableGenderOthers" />
                                              <label class="col-form-label mx-auto" style="margin-left: 10px;"
                                                for="chkApplicableGenderOthers">Others</label>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div class="form-group" style="margin-top: 0px; margin-bottom: 0;">
                                        <div class="row">
                                          <div class="col-sm-2 no-padding">
                                            <label for="Matrial"> Matrial Status </label>
                                          </div>
                                          <div class="col-sm-10">
                                            <div class="custom-control custom-checkbox custom-control-inline">
                                              <input type="checkbox" class="custom-control-input"
                                                id="chkAApplicableMaritalStatusSingle"
                                                formControlName="ApplicableMaritalStatusSingle"
                                                [attr.disabled]=" viewMode?'true':null && editMode? 'true':null"
                                                name="ApplicableMaritalStatusSingle" />
                                              <label class="col-form-label mx-auto" style="margin-left: 10px;"
                                                for="chkAApplicableMaritalStatusSingle">Single</label>
                                              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

                                              <input type="checkbox" class="custom-control-input"
                                                id="chkApplicableMaritalStatusMarried"
                                                formControlName="ApplicableMaritalStatusMarried"
                                                [attr.disabled]=" viewMode?'true':null && editMode? 'true':null"
                                                name="ApplicableMaritalStatusMarried" />
                                              <label class="col-form-label mx-auto" style="margin-left: 10px;"
                                                for="chkApplicableMaritalStatusMarried">Married</label>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <!-- Applicable Department -->
                                      <div class="form-group" style="margin-top: 0px;">
                                        <div class="row">
                                          <div class="col-sm-2 no-padding">
                                            <label class="col-form-label"> Department</label>
                                          </div>
                                          <div class="col-sm-4">
                                            <!-- <ng-select [multiple]="true" [(items)]="DepartmentList" [disabled]="viewMode?'true':null && editMode? 'true':null"
                                              (removed)="removedRole($event)"  (selected)="selectedDepartment($event)"
                                              (data)="refreshDepartmentValue($event)"  placeholder="Select Department">
                                            </ng-select> -->
                                            <ng-select [multiple]="true" [(items)]="applicableDDLDepartmentList"
                                              [disabled]="viewMode?'true':null && editMode? 'true':null"
                                              (removed)="removedDropdownValue('applicableDepartment', $event)"
                                              (selected)="selectDropdownValue('applicableDepartment', $event)"
                                              formControlName="ApplicableDepartments" #ddlADepartment
                                              (opened)="closeDropdownList(ddlADepartment)" placeholder="">
                                            </ng-select>
                                          </div>
                                        </div>
                                      </div>

                                      <!-- Applicable Desigation -->
                                      <div class="form-group" style="margin-top: 0px;">
                                        <div class="row">
                                          <div class="col-sm-2 no-padding">
                                            <label class="col-form-label"> Designation</label>
                                          </div>
                                          <div class="col-sm-4">
                                            <!-- <ng-select [multiple]="true" [(items)]="DesigationList" [disabled]="viewMode?'true':null && editMode? 'true':null"
                                              (removed)="removedRole($event)" (selected)="selectedDesigation($event)"
                                              (data)="refreshDesigationValue($event)" placeholder="Select Desigation">
                                            </ng-select> -->
                                            <ng-select [multiple]="true" [(items)]="applicableDDLDesigationList"
                                              [disabled]="viewMode?'true':null && editMode? 'true':null"
                                              (removed)="removedDropdownValue('applicableDesignation', $event)"
                                              (selected)="selectDropdownValue('applicableDesignation', $event)"
                                              formControlName="ApplicableDesignations" #ddlADesignations
                                              (opened)="closeDropdownList(ddlADesignations)" placeholder="">
                                            </ng-select>
                                          </div>
                                        </div>
                                      </div>

                                      <!-- Applicable Location -->
                                      <div class="form-group" style="margin-top: 0px;">
                                        <div class="row">
                                          <div class="col-sm-2 no-padding">
                                            <label class="col-form-label">Location</label>
                                          </div>
                                          <div class="col-sm-4">
                                            <!-- <ng-select [multiple]="true" [(items)]="LocationList" [disabled]=" viewMode?'true':null && editMode? 'true':null"
                                              [(active)]="ActiveLocation"
                                              (removed)="removedRole($event)" (selected)="selectedLocation($event)"
                                              (data)="refreshLocationValue($event)" placeholder="Select Location">
                                            </ng-select> -->
                                            <ng-select [multiple]="true" [(items)]="applicableDDLLocationList"
                                              [disabled]="viewMode?'true':null && editMode? 'true':null"
                                              (removed)="removedDropdownValue('applicableLocation', $event)"
                                              (selected)="selectDropdownValue('applicableLocation', $event)"
                                              formControlName="ApplicableLocations" #ddlALocations
                                              (opened)="closeDropdownList(ddlALocations)" placeholder="">
                                            </ng-select>
                                          </div>
                                        </div>
                                      </div>

                                      <!-- Applicable Role -->
                                      <div class="form-group" style="margin-top: 0px;">
                                        <div class="row">
                                          <div class="col-sm-2 no-padding">
                                            <label class="col-form-label"> Role </label>
                                          </div>
                                          <div class="col-sm-4">
                                            <!-- <ng-select [multiple]="true" [(items)]="RoleItemList" [disabled]=" viewMode?'true':null && editMode? 'true':null"
                                              [(active)]="activeRoleList"
                                              (removed)="removedRole($event)" (selected)="selectedRoles($event)"
                                              (data)="refreshValue($event)" placeholder="Select Role">
                                            </ng-select> -->
                                            <ng-select [multiple]="true" [(items)]="applicableDDLRoleList"
                                              [disabled]="viewMode?'true':null && editMode? 'true':null"
                                              (removed)="removedDropdownValue('applicableRole', $event)"
                                              (selected)="selectDropdownValue('applicableRole', $event)"
                                              formControlName="ApplicableRoles" #ddlARoles
                                              (opened)="closeDropdownList(ddlARoles)" placeholder="">
                                            </ng-select>
                                          </div>
                                        </div>
                                      </div>

                                      <!-- Applicable Employee Types -->
                                      <ng-container *ngIf="IsEmployeeTypes == true">
                                        <div class="form-group" style="margin-top: 0px;">
                                          <div class="row">
                                            <div class="col-sm-2 no-padding">
                                              <label class="col-form-label">User Type</label>
                                            </div>
                                            <div class="col-sm-4">
                                              <ng-select [multiple]="true" [(items)]="EmployeeTypesList"
                                                [disabled]="viewMode?'true':null && editMode? 'true':null"
                                                (removed)="removedDropdownValue('applicableEmployeeTypes', $event)"
                                                (selected)="selectDropdownValue('applicableEmployeeTypes', $event)"
                                                formControlName="ApplicableEmployeeTypes" #ddlAEmployeeTypes
                                                (opened)="closeDropdownList(ddlAEmployeeTypes)" placeholder="">
                                              </ng-select>
                                            </div>
                                            <div class="col-sm-2">
                                              <label class="col-form-label ico-remove"
                                                (click)="addApplicableField('ApplicableEmployeeTypes', false)"><i
                                                  class="fa fa-minus-square"></i></label>
                                            </div>
                                          </div>
                                        </div>
                                      </ng-container>

                                      <!-- Applicable Source Of Hire -->
                                      <ng-container *ngIf="IsSourceOfHire == true">
                                        <div class="form-group" style="margin-top: 0px;">
                                          <div class="row">
                                            <div class="col-sm-2 no-padding">
                                              <label class="col-form-label">User Engagement Type</label>
                                            </div>
                                            <div class="col-sm-4">
                                              <ng-select [multiple]="true" [(items)]="SourceOfHireList"
                                                [disabled]="viewMode?'true':null && editMode? 'true':null"
                                                (removed)="removedDropdownValue('applicableSourceOfHire', $event)"
                                                (selected)="selectDropdownValue('applicableSourceOfHire', $event)"
                                                formControlName="ApplicableSourceOfHire" #ddlASoh
                                                (opened)="closeDropdownList(ddlASoh)" placeholder="">
                                              </ng-select>
                                            </div>
                                            <div class="col-sm-2">
                                              <label class="col-form-label ico-remove"
                                                (click)="addApplicableField('ApplicableSourceOfHire', false)"><i
                                                  class="fa fa-minus-square"></i></label>
                                            </div>
                                          </div>
                                        </div>
                                      </ng-container>

                                      <!-- Applicable Job Bands -->
                                      <ng-container *ngIf="IsJobBands == true">
                                        <div class="form-group" style="margin-top: 0px;">
                                          <div class="row">
                                            <div class="col-sm-2 no-padding">
                                              <label class="col-form-label">Job Band</label>
                                            </div>
                                            <div class="col-sm-4">
                                              <ng-select [multiple]="true" [(items)]="JobBandsList"
                                                [disabled]="viewMode?'true':null && editMode? 'true':null"
                                                (removed)="removedDropdownValue('applicableJobBands', $event)"
                                                (selected)="selectDropdownValue('applicableJobBands', $event)"
                                                formControlName="ApplicableJobBands" #ddlAJobBands
                                                (opened)="closeDropdownList(ddlAJobBands)" placeholder="">
                                              </ng-select>
                                            </div>
                                            <div class="col-sm-2">
                                              <label class="col-form-label ico-remove"
                                                (click)="addApplicableField('ApplicableJobBands', false)"><i
                                                  class="fa fa-minus-square"></i></label>
                                            </div>
                                          </div>
                                        </div>
                                      </ng-container>

                                      <!-- Applicable Onboarding Status -->
                                      <ng-container *ngIf="IsOnboardingStatus == true">
                                        <div class="form-group" style="margin-top: 0px;">
                                          <div class="row">
                                            <div class="col-sm-2 no-padding">
                                              <label class="col-form-label">Onboarding Status</label>
                                            </div>
                                            <div class="col-sm-4">
                                              <ng-select [multiple]="true" [(items)]="OnboardingStatusList"
                                                [disabled]="viewMode?'true':null && editMode? 'true':null"
                                                (removed)="removedDropdownValue('applicableOnboardingStatus', $event)"
                                                (selected)="selectDropdownValue('applicableOnboardingStatus', $event)"
                                                formControlName="ApplicableOnboardingStatus" #ddlAOS
                                                (opened)="closeDropdownList(ddlAOS)" placeholder="">
                                              </ng-select>
                                            </div>
                                            <div class="col-sm-2">
                                              <label class="col-form-label ico-remove"
                                                (click)="addApplicableField('ApplicableOnboardingStatus', false)"><i
                                                  class="fa fa-minus-square"></i></label>
                                            </div>
                                          </div>
                                        </div>
                                      </ng-container>

                                      <div class="form-group row" style="margin-top: 0px;margin-bottom: 0;" *ngIf="IsEmployeeTypes != true ||
                                        IsSourceOfHire != true ||
                                        IsJobBands != true ||
                                        IsOnboardingStatus != true">
                                        <div class="dropdown rt-dropdown col-md-6 text-right" style="margin: 0;">
                                          <label class="col-form-label" data-toggle="dropdown"
                                            style="color: rgb(51, 153, 148); cursor: pointer;"><i class="fa fa-plus"
                                              aria-hidden="true"></i> Add field</label>
                                          <ul class="dropdown-menu" style="right: 0;left: auto;">
                                            <li *ngIf="IsEmployeeTypes != true"
                                              (click)="addApplicableField('ApplicableEmployeeTypes', true)">User Type
                                            </li>
                                            <li *ngIf="IsSourceOfHire != true"
                                              (click)="addApplicableField('ApplicableSourceOfHire', true)">User Engagement Type
                                            </li>
                                            <li *ngIf="IsJobBands != true"
                                              (click)="addApplicableField('ApplicableJobBands', true)">Job Band</li>
                                            <!-- <li *ngIf="IsOnboardingStatus != true"
                                              (click)="addApplicableField('ApplicableOnboardingStatus', true)">Onboarding
                                              Status</li> -->
                                          </ul>
                                        </div>
                                      </div>

                                      <div class="row">
                                        <div class="col-sm-6 text-center">
                                          <label class="col-form-label"> (OR) </label>
                                        </div>
                                      </div>

                                      <!-- Applicable Employees -->
                                      <div class="form-group" style="margin-top: 0px;">
                                        <div class="row">
                                          <div class="col-sm-2 no-padding">
                                            <label for="add" class="col-form-label">Employee </label>
                                          </div>
                                          <div class="col-sm-4">
                                            <!-- <ng-select [multiple]="true" [(items)]="EmployeeList" [disabled]=" viewMode?'true':null && editMode? 'true':null"
                                              (selected)="selectedEmployee($event)"
                                              (data)="refreshEmployeeValue($event)" placeholder="Select Employee">
                                            </ng-select>  -->
                                            <ng-select [multiple]="true" [(items)]="EmployeeList"
                                              [disabled]="viewMode?'true':null && editMode? 'true':null"
                                              (removed)="removedDropdownValue('applicableEmployee', $event)"
                                              (selected)="selectDropdownValue('applicableEmployee', $event)"
                                              formControlName="ApplicableEmployees" #ddlAEmployees
                                              (opened)="closeDropdownList(ddlAEmployees)" placeholder="All Employees">
                                            </ng-select>
                                          </div>
                                        </div>
                                      </div>


                                    </div>
                                </div>

                                <div class="tab-pane" id="exceptions" role="tabpanel">
                                  <div class="card-body">
                                      <!-- Exception Department -->
                                      <div class="row" style="margin-top: 10px;">
                                        <div class="col-sm-2 no-padding">
                                          <label class="col-form-label"> Department </label>
                                        </div>
                                        <div class="col-sm-4">
                                          <!-- <ng-select [multiple]="true" [(items)]="DepartmentList" [disabled]=" viewMode?'true':null && editMode? 'true':null"
                                            formControlName="ExceptionDepartments"
                                            (removed)="removedDepartmentException($event)" (selected)="selectedDepartmentException($event)"
                                            (data)="refreshDepartmentValueException($event)"  placeholder="Select Department">
                                          </ng-select> -->
                                          <ng-select [multiple]="true" [(items)]="exceptionDDLDepartmentList"
                                            [disabled]="viewMode?'true':null && editMode? 'true':null"
                                            (removed)="removedDropdownValue('exceptionDepartment', $event)"
                                            (selected)="selectDropdownValue('exceptionDepartment', $event)"
                                            formControlName="ExceptionDepartments" #ddlEDepartments
                                            (opened)="closeDropdownList(ddlEDepartments)" placeholder="">
                                          </ng-select>
                                        </div>
                                      </div>

                                      <!-- Exception Desigation -->
                                      <div class="row" style="margin-top: 0px;">
                                        <div class="col-sm-2 no-padding">
                                          <label class="col-form-label">Designation</label>
                                        </div>
                                        <div class="col-sm-4">
                                          <!-- <ng-select [multiple]="true" [(items)]="DesigationList" [disabled]=" viewMode?'true':null && editMode? 'true':null"
                                            formControlName="ExceptionDesignations" [(active)]="ActiveDesigation"
                                            (removed)="removedRole($event)" (selected)="desigationSelection($event)"
                                            (data)="refreshDesigationValue($event)" placeholder="Select Desigation">
                                          </ng-select> -->
                                          <ng-select [multiple]="true" [(items)]="exceptionDDLDesigationList"
                                            [disabled]="viewMode?'true':null && editMode? 'true':null"
                                            (removed)="removedDropdownValue('exceptionDesignation', $event)"
                                            (selected)="selectDropdownValue('exceptionDesignation', $event)"
                                            formControlName="ExceptionDesignations" #ddlEDesignations
                                            (opened)="closeDropdownList(ddlEDesignations)" placeholder="">
                                          </ng-select>
                                        </div>
                                      </div>

                                      <!-- Exception Location -->
                                      <div class="row" style="margin-top: 0px;">
                                        <div class="col-sm-2 no-padding">
                                          <label class="col-form-label"> Location</label>
                                        </div>
                                        <div class="col-sm-4">
                                          <!-- <ng-select [multiple]="true" [(items)]="LocationList" [disabled]=" viewMode?'true':null && editMode? 'true':null"
                                            [(active)]="ActiveLocation"
                                            (removed)="removedRole($event)" (selected)="selectedLocation($event)"
                                            (data)="refreshLocationValue($event)" placeholder="Select Location">
                                          </ng-select> -->
                                          <ng-select [multiple]="true" [(items)]="exceptionDDLLocationList"
                                            [disabled]="viewMode?'true':null && editMode? 'true':null"
                                            (removed)="removedDropdownValue('exceptionLocation', $event)"
                                            (selected)="selectDropdownValue('exceptionLocation', $event)"
                                            formControlName="ExceptionLocations" #ddlELocations
                                            (opened)="closeDropdownList(ddlELocations)" placeholder="">
                                          </ng-select>
                                        </div>
                                      </div>

                                      <!-- Exception Role -->
                                      <div class="row" style="margin-top: 0px; margin-bottom: 20px;">
                                        <div class="col-sm-2 no-padding">
                                          <label class="col-form-label"> Role </label>
                                        </div>
                                        <div class="col-sm-4">
                                          <!-- <ng-select [multiple]="true" [(items)]="RoleItemList" [disabled]=" viewMode?'true':null && editMode? 'true':null"
                                            [(active)]="activeRoleList"
                                            (removed)="removedRole($event)" (selected)="selectedRoles($event)"
                                            (data)="refreshValue($event)" placeholder="Select Role">
                                          </ng-select> -->
                                          <ng-select [multiple]="true" [(items)]="exceptionDDLRoleList"
                                            [disabled]="viewMode?'true':null && editMode? 'true':null"
                                            (removed)="removedDropdownValue('exceptionRole', $event)"
                                            (selected)="selectDropdownValue('exceptionRole', $event)"
                                            formControlName="ExceptionRoles" #ddlERoles
                                            (opened)="closeDropdownList(ddlERoles)" placeholder="">
                                          </ng-select>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                              </div>




                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="tab-pane" id="rest" role="tabpanel">
                        <div class="card-body">
                          <div class="row" style="margin-top: 30px;" *ngIf="tempLeaveType.Unit != 2">
                            <div class="col-sm-12">
                              <label class="col-form-label"> Weekends Between Leave Period :</label>
                            </div>
                            <div class="col-sm-3" style="width: 175px;">
                              <div class="custom-control custom-radio">
                                <input type="radio" class="custom-control-input"
                                  id="rdbCanCountAsLeaveInWeekendBetweenLeaveEnable"
                                  [attr.disabled]="viewMode?'true':null && editMode? 'true':null"
                                  name="CanCountAsLeaveInWeekendBetweenLeave"
                                  formControlName="CanCountAsLeaveInWeekendBetweenLeave" [value]="true"
                                  (change)="tempLeaveType.CanCountAsLeaveInWeekendBetweenLeave = true" />
                                <label class="col-form-label" for="rdbCanCountAsLeaveInWeekendBetweenLeaveEnable"
                                  style="margin-left: 10px;">Count as leave</label>
                                <br />
                                <input type="radio" class="custom-control-input"
                                  id="rdbCanCountAsLeaveInWeekendBetweenLeaveDisable"
                                  [attr.disabled]=" viewMode?'true':null && editMode? 'true':null"
                                  name="CanCountAsLeaveInWeekendBetweenLeave"
                                  formControlName="CanCountAsLeaveInWeekendBetweenLeave" [value]="false"
                                  (change)="tempLeaveType.CanCountAsLeaveInWeekendBetweenLeave = false" />
                                <label class="col-form-label" for="rdbCanCountAsLeaveInWeekendBetweenLeaveDisable"
                                  style="margin-left: 10px;">Don't count as leave</label>
                              </div>
                            </div>
                            <div class="col-sm-9">
                              <div class="row">
                                <div class="col-sm-1 no-padding" style="width: 75px;">
                                  <label class="col-form-label">Count after</label> &nbsp;
                                </div>
                                <div class="col-sm-1 no-padding" style="width: 50px;margin-top: -15px;">
                                  <input style="padding-bottom: 0; min-height: auto !important; margin-top: 6px;" type="text" id="WeekendBetweenLeaveCountAfter"
                                    name="WeekendBetweenLeaveCountAfter" (keypress)="numberOnly($event)"
                                    [attr.disabled]="(viewMode ? 'true' : null && editMode ? 'true' : null) || !tempLeaveType.CanCountAsLeaveInWeekendBetweenLeave ? 'true' : null"
                                    formControlName="WeekendBetweenLeaveCountAfter" class="form-control" />
                                </div>
                                <div class="col-sm-1">
                                  <label class="col-form-label">days</label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row" style="margin-top: 30px;" *ngIf="tempLeaveType.Unit != 2">
                            <div class="col-sm-12">
                              <label class="col-form-label"> Holidays Between Leave Period :</label>
                            </div>
                            <div class="col-sm-3" style="width: 175px;">
                              <div class="custom-control custom-radio">
                                <input type="radio" class="custom-control-input"
                                  id="rdbCanCountAsHolidayInWeekendBetweenLeaveEnable"
                                  [attr.disabled]=" viewMode?'true':null && editMode? 'true':null"
                                  name="CanCountAsHolidayInWeekendBetweenLeave"
                                  formControlName="CanCountAsHolidayInWeekendBetweenLeave" [value]="true"
                                  (change)="tempLeaveType.CanCountAsHolidayInWeekendBetweenLeave = true" />
                                <label class="col-form-label" for="rdbCanCountAsHolidayInWeekendBetweenLeaveEnable"
                                  style="margin-left: 10px;">Count as leave</label>
                                <br />
                                <input style="padding-bottom: 0; min-height: auto !important; margin-top: 6px;" type="radio" class="custom-control-input"
                                  id="rdbCanCountAsHolidayInWeekendBetweenLeaveDisable"
                                  [attr.disabled]=" viewMode?'true':null && editMode? 'true':null"
                                  name="CanCountAsHolidayInWeekendBetweenLeave"
                                  formControlName="CanCountAsHolidayInWeekendBetweenLeave" [value]="false"
                                  (change)="tempLeaveType.CanCountAsHolidayInWeekendBetweenLeave = false" />
                                <label class="col-form-label" for="rdbCanCountAsHolidayInWeekendBetweenLeaveDisable"
                                  style="margin-left: 10px;">Don't count as leave</label>
                              </div>
                            </div>
                            <div class="col-sm-9">
                              <div class="row">
                                <div class="col-sm-1 no-padding" style="width: 75px;">
                                  <label class="col-form-label">Count after</label> &nbsp;
                                </div>
                                <div class="col-sm-1 no-padding" style="width: 50px;margin-top: -15px;">
                                  <input style="padding-bottom: 0; min-height: auto !important; margin-top: 6px;" type="text" id="WeekendBetweenHolidayCountAfter"
                                    name="WeekendBetweenHolidayCountAfter" (keypress)="numberOnly($event)"
                                    [attr.disabled]="(viewMode ? 'true' : null && editMode ? 'true' : null) || !tempLeaveType.CanCountAsHolidayInWeekendBetweenLeave ? 'true' : null"
                                    formControlName="WeekendBetweenHolidayCountAfter" class="form-control" />
                                </div>
                                <div class="col-sm-1">
                                  <label class="col-form-label">days</label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row" style="margin-top: 30px;">
                            <div class="col-sm-6">
                              <label class="col-form-label"> While Applying Leave, Exceed Leave Balance :</label>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-sm-12" style="margin-left: 0px;">
                              <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" class="custom-control-input" id="rdbCanExceedLeaveBalanceEnable"
                                  formControlName="CanExceedLeaveBalance" [value]="true"
                                  (change)="canExceedLeaveBalance = true"
                                  [attr.disabled]=" viewMode?'true':null && editMode? 'true':null" checked>
                                <label class="col-form-label" for="rdbCanExceedLeaveBalanceEnable"
                                  style="margin-left: 10px;">Allow</label>
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

                                <input type="radio" class="custom-control-input" id="rdbCanExceedLeaveBalanceDisable"
                                  [attr.disabled]=" viewMode?'true':null && editMode? 'true':null"
                                  formControlName="CanExceedLeaveBalance" [value]="false"
                                  (change)="canExceedLeaveBalance = false" />
                                <label class="col-form-label" for="rdbCanExceedLeaveBalanceDisable"
                                  style="margin-left: 10px;">Don't
                                  Allow</label>
                              </div>
                            </div>
                          </div>

                          <div class="row" *ngIf="canExceedLeaveBalance == true">
                            <div class="col-sm-12" style="margin-left: 30px;">
                              <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" class="custom-control-input" formControlName="ExceedLeaveBalanceType"
                                  value="1" [attr.disabled]=" viewMode?'true':null && editMode? 'true':null"
                                  id="rdbExceedLeaveBalanceTypeW" />
                                <label class="col-form-label" for="rdbExceedLeaveBalanceTypeW"
                                  style="margin-left: 10px;">Without limit</label>
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

                                <input type="radio" class="custom-control-input" formControlName="ExceedLeaveBalanceType"
                                  value="2" [attr.disabled]=" viewMode?'true':null && editMode? 'true':null"
                                  id="rdbExceedLeaveBalanceTypeUY"  />
                                <label class="col-form-label" for="rdbExceedLeaveBalanceTypeUY"
                                  style="margin-left: 10px;">Until year end Max. Limit</label>
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

                                <input type="radio" class="custom-control-input" formControlName="ExceedLeaveBalanceType"
                                  value="3" [attr.disabled]=" viewMode?'true':null && editMode? 'true':null"
                                  id="rdbExceedLeaveBalanceTypeWL"  />
                                <label class="col-form-label" for="rdbExceedLeaveBalanceTypeWL"
                                  style="margin-left: 10px;">Without limits and marks LOP</label>
                              </div>

                            </div>
                          </div>

                          <div class="row" style="margin-top: 30px;" *ngIf="tempLeaveType.Unit != 2">
                            <div class="col-sm-12">
                              <label class="col-form-label"> Duration(s) Allowed: </label>
                            </div>
                            <div class="col-sm-12">
                              <div class="custom-control custom-checkbox custom-control-inline">
                                <input type="checkbox" id="IsDurationFullDay" class="custom-control-input"
                                  formControlName="IsDurationFullDay"
                                  [attr.disabled]="viewMode?'true':null && editMode? 'true':null"
                                  name="IsDurationFullDay" />
                                <label class="col-form-label mx-auto" style="margin-left: 10px;"
                                  for="IsDurationFullDay">Full Day</label>
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>

                                <input type="checkbox" id="IsDurationHalfDay" class="custom-control-input"
                                  formControlName="IsDurationHalfDay"
                                  [attr.disabled]="viewMode?'true':null && editMode? 'true':null"
                                  name="IsDurationHalfDay" />
                                <label class="col-form-label mx-auto" style="margin-left: 10px;"
                                  for="IsDurationHalfDay">Half Day</label>
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>

                                <input type="checkbox" id="IsDurationQuarterDay" class="custom-control-input"
                                  formControlName="IsDurationQuarterDay"
                                  [attr.disabled]="viewMode?'true':null && editMode? 'true':null"
                                  name="IsDurationQuarterDay" />
                                <label class="col-form-label mx-auto" style="margin-left: 10px;"
                                  for="IsDurationQuarterDay">Quarter Day</label>
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                              </div>
                            </div>
                          </div>

                          <div class="row" style="margin-top: 30px;">
                            <div class="col-sm-6">
                              <label for="Matrial" class="col-form-label"> Report Configuration: </label>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-sm-2">
                              <label for="Matrial" class="col-form-label" style="margin-top: 8px;"> Allow users to view
                              </label>
                            </div>
                            <div class="col-sm-3" style="margin-right: 30px;">
                              <!-- <select class="form-control"
                                [attr.disabled]=" viewMode?'true':null && editMode? 'true':null">
                                <option [ngValue]="1">Complete Leave Summary</option>
                                <option [ngValue]="2">Leave Taken Alone</option>
                                <option [ngValue]="3">Simple Leave Summary</option>
                              </select> -->
                              <ejs-dropdownlist  [enabled]="NeweditMode" [value]='1'
                                [fields]='AllowUserstToViewfield' [dataSource]='AllowUserstToView'>
                            </ejs-dropdownlist>
                            </div>
                          </div>

                          <div class="row" style="margin-top: 5px;">
                            <div class="col-sm-2" style="padding-right: 0;">
                              <label for="Matrial" class="col-form-label" style="margin-top: 8px;"> Balance to be
                                displayed
                              </label>
                            </div>
                            <div class="col-sm-3" style="margin-right: 30px;">
                              <!-- <select class="form-control"
                                [attr.disabled]=" viewMode?'true':null && editMode? 'true':null">
                                <option [ngValue]="1">Current Balance</option>
                                <option [ngValue]="2">Accural Period Balance</option>
                              </select> -->
                              <ejs-dropdownlist  [enabled]="NeweditMode" [value]='1'
                              [fields]='BalanceToBedisplayedfield' [dataSource]='BalanceToBedisplayed'>
                          </ejs-dropdownlist>
                            </div>
                          </div>

                          <div class="row" style="margin-top: 30px;">
                            <div class="col-sm-6">
                              <label for="Matrial" class="col-form-label"> Allow Requests For: </label>
                            </div>
                          </div>

                          <div class="row" style="margin-left: 0px;">
                            <div class="col-sm-3">
                              <input type="checkbox" name="AllowRequestsForPastDates"
                                [attr.disabled]=" viewMode?'true':null && editMode? 'true':null"
                                [checked]="isRequestAllowFor" (change)="isRequestAllowFor = !isRequestAllowFor"
                                formControlName="AllowRequestsForPastDates" />
                              <label for="Matrial" class="col-form-label" style="margin-left: 10px;"> Past Dates</label>
                            </div>
                          </div>

                          <div class="row" style="margin-left: 40px;">
                            <div class="col-sm-6">
                              <input type="checkbox" name="reportOf"
                                [attr.disabled]="viewMode?'true':null && editMode? 'true':null || isRequestAllowFor ? null : ''">
                              <label for="Matrial" class="col-form-label" style="margin-left: 10px;"> Past</label>

                              <input type="text" name="AllowRequestsForPastDays" (keypress)="numberOnly($event)"
                                [attr.disabled]=" viewMode?'true':null && editMode? 'true':null || isRequestAllowFor ? null : ''"
                                formControlName="AllowRequestsForPastDays" style="width: 55px; margin-left: 10px;" />
                              <label for="Matrial" class="col-form-label" style="margin-left: 10px;"> days</label>
                            </div>
                          </div>

                          <div class="row" style="margin-left: 0px;">
                            <div class="col-sm-6">
                              <input type="checkbox" name="AllowRequestsForFutureDates" [checked]="isFuturesDate"
                                (change)="isFuturesDate = !isFuturesDate" formControlName="AllowRequestsForFutureDates" />
                              <label for="Matrial" class="col-form-label" style="margin-left: 10px;"> Future Dates</label>
                            </div>
                          </div>

                          <div class="row" style="margin-left: 40px;">
                            <div class="col-sm-6">
                              <input type="checkbox" name="reportOf"
                                [attr.disabled]=" viewMode?'true':null && editMode? 'true':null || isFuturesDate ? null : ''">
                              <label for="Matrial" class="col-form-label" style="margin-left: 10px;"> Next</label>
                              <input type="text" name="reports" (keypress)="numberOnly($event)"
                                [attr.disabled]="viewMode?'true':null && editMode? 'true':null || isFuturesDate ? null : ''"
                                formControlName="AllowRequestsForNextDays" style="width: 55px;margin-left: 10px;" />
                              <label for="Matrial" class="col-form-label" style="margin-left: 10px;"> days</label>
                            </div>
                          </div>

                          <div class="row" style="margin-left: 40px;margin-top: 10px;">
                            <div class="col-sm-6">
                              <input type="checkbox" name="reportOf"
                                [attr.disabled]="viewMode?'true':null && editMode? 'true':null || isFuturesDate ? null : ''">
                              <label for="Matrial" class="col-form-label" style="margin-left: 10px;"> To be
                                applied</label>
                              <input type="text" name="AllowRequestsDaysinAdvance" (keypress)="numberOnly($event)"
                                [attr.disabled]="viewMode?'true':null && editMode? 'true':null || isFuturesDate ? null : ''"
                                style="width: 55px;margin-left: 10px;" formControlName="AllowRequestsDaysinAdvance" />

                              <label for="Matrial" class="col-form-label" style="margin-left: 10px;"> days in
                                advance</label>
                            </div>
                          </div>

                          <div class="row" style="margin-top: 10px;">
                            <div class="col-sm-6">
                              <input type="checkbox" name="IsMinimumLimitPerApplication"
                                [attr.disabled]="viewMode?'true':null && editMode? 'true':null"
                                [checked]="isMinimumApplied" (change)="isMinimumApplied = !isMinimumApplied"
                                formControlName="IsMinimumLimitPerApplication">
                              <label for="Matrial" class="col-form-label" style="margin-left: 10px;"> Minimum leave that
                                can
                                be availed per application </label>
                              <input type="text" name="MinimumLimitPerApplication" (keypress)="numberOnly($event)"
                                [attr.disabled]="viewMode?'true':null && editMode? 'true':null || isMinimumApplied ? null : ''"
                                formControlName="MinimumLimitPerApplication" style="width: 55px;margin-left: 10px;" />
                            </div>
                          </div>

                          <div class="row" style="margin-top: 10px;">
                            <div class="col-sm-6">
                              <input type="checkbox" name="IsMaximumLimitPerApplication"
                                [attr.disabled]="viewMode?'true':null && editMode? 'true':null"
                                [checked]="isMaximumAppliedLeaved"
                                (change)="isMaximumAppliedLeaved = !isMaximumAppliedLeaved"
                                formControlName="IsMaximumLimitPerApplication">
                              <label for="Matrial" class="col-form-label" style="margin-left: 10px;"> Maximum leave that
                                can
                                be availed per application </label>
                              <input type="text" name="MaximumLimitPerApplication" (keypress)="numberOnly($event)"
                                [attr.disabled]="viewMode?'true':null && editMode? 'true':null || isMaximumAppliedLeaved ? null : ''"
                                formControlName="MaximumLimitPerApplication" style="width: 55px;margin-left: 10px;" />
                            </div>
                          </div>

                          <div class="row" style="margin-top: 10px;">
                            <div class="col-sm-6">
                              <input type="checkbox" name="IsMaximumConsecutiveHoursAllowed"
                                [attr.disabled]="viewMode?'true':null && editMode? 'true':null"
                                [checked]="isMAximumConsectiveHoursAllow"
                                (change)="isMAximumConsectiveHoursAllow = !isMAximumConsectiveHoursAllow"
                                formControlName="IsMaximumConsecutiveHoursAllowed" value="false">

                              <label for="IsMaximumConsecutiveHoursAllowed" class="col-form-label"
                                style="margin-left: 10px;" *ngIf="tempLeaveType.Unit == 1">Maximum number of consecutive
                                days of leave allowed</label>
                              <label for="IsMaximumConsecutiveHoursAllowed" class="col-form-label"
                                style="margin-left: 10px;" *ngIf="tempLeaveType.Unit == 2">Maximum number of consecutive
                                hours of leave allowed</label>

                              <input type="text" name="MaximumConsecutiveHoursAllowed" (keypress)="numberOnly($event)"
                                [attr.disabled]="viewMode?'true':null && editMode? 'true':null || isMAximumConsectiveHoursAllow ? null : ''"
                                formControlName="MaximumConsecutiveHoursAllowed" style="width: 55px;margin-left: 10px;" />
                            </div>
                          </div>

                          <div class="row" style="margin-top: 10px;">
                            <div class="col-sm-6">
                              <input type="checkbox" name="IsMinimumGapBetweenTwoApplications"
                                [attr.disabled]="viewMode?'true':null && editMode? 'true':null"
                                [checked]="isMinimumGapInDay" (change)="isMinimumGapInDay = !isMinimumGapInDay"
                                formControlName="IsMinimumGapBetweenTwoApplications">
                              <label for="Matrial" class="col-form-label" style="margin-left: 10px;"> Minimum gap (in
                                days)
                                between two application </label>
                              <input type="text" name="MinimumGapBetweenTwoApplications" (keypress)="numberOnly($event)"
                                [attr.disabled]="viewMode?'true':null && editMode? 'true':null || isMinimumGapInDay ? null : ''"
                                formControlName="MinimumGapBetweenTwoApplications"
                                style="width: 55px;margin-left: 10px;" />
                            </div>
                          </div>


                          <div class="row" style="margin-top: 10px;" *ngIf="tempLeaveType.Unit == 1">
                            <div class="col-sm-6">
                              <input type="checkbox" name="EnableFileUploadOption"
                                [attr.disabled]="viewMode?'true':null && editMode? 'true':null"
                                [checked]="enableFileUploadOption"
                                (change)="enableFileUploadOption = !enableFileUploadOption"
                                formControlName="EnableFileUploadOption">
                              <label for="EnableFileUploadOption" class="col-form-label" style="margin-left: 10px;">Enable
                                file upload option if the applied leave period exceeds</label>
                              <input type="text" name="OnAppliedLeavePeriodExceeds" (keypress)="numberOnly($event)"
                                [attr.disabled]="viewMode?'true':null && editMode? 'true':null || enableFileUploadOption ? null : ''"
                                formControlName="OnAppliedLeavePeriodExceeds" style="width: 55px;margin-left: 10px;" />
                            </div>
                          </div>

                          <div class="row" style="margin-top: 10px;">
                            <div class="col-sm-12">
                              <div class="col-sm-7">
                                <label for="Matrial" class="col-form-label" style="margin-left: 10px;"> Maximum number of
                                  application allowed within the specified period </label>
                                <input type="text" name="MaximumApplicationsAllowed" (keypress)="numberOnly($event)"
                                  formControlName="MaximumApplicationsAllowed"
                                  [attr.disabled]="viewMode?'true':null && editMode? 'true':null"
                                  formControlName="MaximumApplicationsAllowed" style="width: 55px;margin-left: 10px;" />
                                <span> / </span>
                                <!-- <select formControlName="MaximumApplicationsAllowedType"
                                  [attr.disabled]="viewMode?'true':null && editMode? 'true':null">
                                  <option [ngValue]="1" [attr.selected]="1">Week</option>
                                  <option [ngValue]="2">Month</option>
                                  <option [ngValue]="3">Year</option>
                                  <option [ngValue]="4">Accrual Period</option>
                                  <option [ngValue]="5">Job Tenure</option>
                                </select> -->
                              </div>

                              <div class="col-sm-2" >
                                  <ejs-dropdownlist style="margin-left: -60px;width: 100%; margin-top: -4px;" [enabled]="NeweditMode" formControlName="MaximumApplicationsAllowedType"
                                  [fields]='Weekfield' [dataSource]='Week' >
                                  </ejs-dropdownlist>
                              </div>
                            </div>
                          </div>

                          <div class="col-md-12" style="margin-top: 10px;">
                            <div class="col-sm-4">
                              <label for="Matrial" class="col-form-label" style="margin-left: 10px;"> This leave can be
                                applied only on </label>
                            </div>
                            <div class="col-sm-4">
                              <!-- <ng-select [multiple]="true" [(items)]="AppliedList" [disabled]="viewMode?'true':null && editMode? 'true':null"
                                (selected)="selectedAppliedLeave($event)" (data)="refreshAppliedLeaveValue($event)" >
                              </ng-select>  -->
                              <ng-select [multiple]="true" [(items)]="AppliedList"
                                [disabled]="viewMode?'true':null && editMode? 'true':null"
                                (removed)="removedDropdownValue('canAppliedOnlyOn', $event)"
                                (selected)="selectDropdownValue('canAppliedOnlyOn', $event)"
                                formControlName="CanAppliedOnlyOn" placeholder="Select">
                              </ng-select>
                            </div>
                          </div>

                          <div class="col-md-12" style="margin-top: 10px; margin-bottom: 20px;">
                            <div class="col-sm-4">
                              <label for="Matrial" class="col-form-label" style="margin-left: 10px;"> This leave can not
                                be taken together with</label>
                            </div>
                            <div class="col-sm-4">
                              <!-- <ng-select [multiple]="true" [(items)]="LeaveTypeList" [disabled]=" viewMode?'true':null && editMode? 'true':null"
                                (selected)="selectedLeaveType($event)" (data)="refreshLeaveType($event)" >
                              </ng-select>  -->
                              <ng-select [multiple]="true" [(items)]="LeaveTypeList"
                                [disabled]="viewMode?'true':null && editMode? 'true':null"
                                (removed)="removedDropdownValue('leaveCannotbeTakenWith', $event)"
                                (selected)="selectDropdownValue('leaveCannotbeTakenWith', $event)"
                                formControlName="CannotTakenTogetherWith" placeholder="Select">
                              </ng-select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane" id="exception" role="tabpanel">
                       <div class="card-body">
                          <div class="row">
                            <table class="table table-bordered exceptionTable">
                              <tr>
                                <th>Employee Name</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>Action</th>
                              </tr>

                              <tr *ngFor="let field of fieldEmpArray; let i = index">
                                <td>
                                  {{ field.employeeName }}
                                </td>

                                <td>
                                  {{ field.fromDate | date: "MM-dd-yyyy" }}
                                </td>
                                <td>
                                  {{ field.toDate | date: "MM-dd-yyyy" }}
                                </td>
                                <td>

                                  <div>
                                    <i title="Remove User"  (click)="RemoveUserInExcept(i)" _ngcontent-ies-c11="" aria-hidden="true" class="fa fa-minus-circle" style="font-size: large;color: #af4949;"></i>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>

                                  <ejs-dropdownlist #ExceptionUser  placeholder="Select an employee"
                                  [fields]='exceptioUserfields' [dataSource]='EmployeeList'>
                                  </ejs-dropdownlist>

                                </td>

                                <td>
                                  <input #fromDateExcept class="e-input" type="date" id="newAttributeStartDate" name="newAttributeStartDate" />
                                </td>
                                <td>
                                  <input #toDateExcept class="e-input" type="date" id="newAttributeEndDate" name="newAttributeEndDate" />
                                </td>
                                <td>

                                  <div>
                                    <i _ngcontent-ies-c11="" (click)="AddUserInExcept(ExceptionUser.value,fromDateExcept.value,toDateExcept.value)" title="Add New User" aria-hidden="true" class="fa fa-plus-circle" style="font-size: large;color: #666;"></i>
                                  </div>
                                </td>
                              </tr>


                            </table>
                          </div>
                       </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group row" style="padding: 15px 15px 0;">
                <button type="submit" class="btn btn-primary btn-brand" *ngIf="viewMode == false"
                  style="padding: 7px 30px !important;">Submit</button>
                <!-- <button type="button" class="btn btn-light"  (click)="cloaseEditMode()"  _ngcontent-c11=""  data-dismiss="modal">Cancel</button> -->
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="manageEntitlementCalendar" class="modal fade" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
        <h4 class="modal-title">Entitlement Calendar
          <span title="Accrual entitlements will be based on the month they occur"><i
              class="fa fa-info-circle"></i></span>
        </h4>
      </div>
      <div class="modal-body model-bg" style="padding: 10px 0;">
        <div class="row">
          <ng-container *ngFor="let item of tempLeaveType.EntitlementCalendarList; let i = index">
            <div class="clearfix" *ngIf="i % 3 == 0"></div>
            <div class="col-sm-4">
              <div class="box">
                <div class="box-header" style="padding: 5px 5px 0;">
                  <input type="hidden"
                    [(ngModel)]="tempLeaveType.EntitlementCalendarList[i].LeavePolicyEntitlementCalendarId"
                    name="leavePolicyEntitlementCalendarId-{{i}}" #leavePolicyEntitlementCalendarId="ngModel" />
                  <input type="hidden" [(ngModel)]="tempLeaveType.EntitlementCalendarList[i].Month" name="month-{{i}}"
                    #month="ngModel" />
                  <label class="col-form-label">{{ item.MonthText }}</label>
                </div>
                <div class="box-body" style="padding: 5px;">
                  <input class="form-control" type="text" id="count-{{i}}" autocomplete="off"
                    (keypress)="numberOnlyWithDecimal($event)"
                    [(ngModel)]="tempLeaveType.EntitlementCalendarList[i].Count" name="count-{{i}}" #count="ngModel" />
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="modal-footer text-left">
        <button type="button" class="btn btn-primary btn-brand" data-dismiss="modal" style="width: 80px;">Ok</button>
      </div>
    </div>
  </div>
</div>

<div id="manageProrateAccuralModal" class="modal fade" role="dialog">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
        <h4 class="modal-title">Prorate Accrual</h4>
      </div>
      <div class="modal-body model-bg">
        <div class="col-sm-12 col-xs-12 model-body" *ngIf="prorateAccural !== null && prorateAccural !== undefined">
          <form name="form" (ngSubmit)="u.form.valid && saveProrateAccural()" autocomplete="off" #u="ngForm" novalidate>
            <div class="m-portlet__body">
              <input type="hidden" name="leaveTypeId" [(ngModel)]="prorateAccural.leaveTypeId">
              <div class="form-group row">
                <div class="col-sm-12">
                  <label for="chckProrateByMonths">Prorate By</label>
                </div>
                <div class="col-sm-12">
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" class="custom-control-input" id="chckProrateByMonths"
                      [attr.disabled]="viewMode?'true':null && editMode? 'true':null" name="prorateBy"
                      [(ngModel)]="prorateAccural.prorateBy" #prorateBy="ngModel" [value]="true" checked />
                    <label class="col-form-label mx-auto" style="margin-left: 10px;" for="chckProrateByMonths">Number of
                      effective months</label>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

                    <input type="radio" class="custom-control-input" id="chckProrateByDays"
                      [attr.disabled]="viewMode?'true':null && editMode? 'true':null" name="prorateBy"
                      [(ngModel)]="prorateAccural.prorateBy" #prorateBy="ngModel" [value]="false" />
                    <label class="col-form-label mx-auto" style="margin-left: 10px;" for="chckProrateByDays">Number of
                      effective days</label>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-12">
                  <label for="chckProrateByMonths">Round Off</label>
                </div>
                <div class="col-sm-4">
                  <div class="custom-control custom-checkbox custom-control-inline">
                    <input type="checkbox" class="custom-control-input" id="chckRoundOff"
                      [attr.disabled]="viewMode?'true':null && editMode? 'true':null" name="isRoundOff"
                      [(ngModel)]="prorateAccural.isRoundOff" #isRoundOff="ngModel" />
                    <label class="col-form-label mx-auto" style="margin-left: 10px;" for="chckRoundOff">Round Off
                      to</label>
                  </div>
                </div>
                <div class="col-sm-4">
                  <select class="form-control" name="roundOffToValue" [(ngModel)]="prorateAccural.roundOffToValue"
                    style="margin-top: 0;" #roundOffToValue="ngModel"
                    [attr.disabled]="(viewMode?'true':null && editMode? 'true':null) || !prorateAccural.isRoundOff ? 'true' : null">
                    <option *ngFor="let roundOff of roundOffValueSelectList" [ngValue]="roundOff.value">{{ roundOff.name
                      }}</option>
                  </select>
                </div>
                <div class="col-sm-4">
                  <select class="form-control" name="roundOffToType" [(ngModel)]="prorateAccural.roundOffToType"
                    style="margin-top: 0;" #roundOffToType="ngModel"
                    [attr.disabled]="(viewMode?'true':null && editMode? 'true':null) || !prorateAccural.isRoundOff ? 'true' : null">
                    <option *ngFor="let roundOff of roundOffTypeSelectList" [ngValue]="roundOff.value">{{ roundOff.name
                      }}</option>
                  </select>
                </div>
              </div>

              <div class="form-group row" *ngIf="prorateAccural.prorateBy == true">
                <div class="col-sm-12">
                  <label>First Month Rule</label>
                </div>
                <div class="col-sm-12" *ngIf="prorateAccural.prorateAccuralFirstMonthList.length === 0">
                  <label (click)="addFirstMonthRule()"><a href="javascript: void(0);">Define rule for the first month of
                      the policy</a></label>
                </div>
                <div class="col-sm-12" *ngIf="prorateAccural.prorateAccuralFirstMonthList.length > 0">
                  <table class="table table-bordered user-table text-left">
                    <thead>
                      <tr>
                        <th style="width: 10%;"></th>
                        <th style="width: 30%;">From</th>
                        <th style="width: 30%;">To</th>
                        <th style="width: 30%;">Count</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let rule of prorateAccural.prorateAccuralFirstMonthList; let i=index">
                        <td class="text-center">
                          {{i + 1}}
                          <input type="hidden"
                            [(ngModel)]="prorateAccural.prorateAccuralFirstMonthList[i].leavePolicyPAFMId"
                            name="leavePolicyPAFMId-{{i}}" #leavePolicyPAFMId="ngModel" />
                        </td>
                        <td>
                          <select class="form-control" [(ngModel)]="prorateAccural.prorateAccuralFirstMonthList[i].from"
                            name="fm-from-{{i}}" #from="ngModel" style="margin-top: 0;"
                            (change)='onFirstRuleFromSelected($event, i, prorateAccural.prorateAccuralFirstMonthList[i].from)'
                            [disabled]="viewMode?'true':null && editMode? 'true':null"
                            [ngClass]="{ 'is-invalid': u.submitted && from.invalid }" required>
                            <option *ngFor="let item of prorateAccuralDayList" [ngValue]="item.id">{{ item.text }}
                            </option>
                          </select>
                          <span *ngIf="duplicateFirstMonthRuleFrom[i]=='false'" class="error">Invalid selection.</span>
                          <div *ngIf="u.submitted && from.invalid" class="invalid-feedback error">
                            <div *ngIf="from.errors.required">From is required</div>
                          </div>
                        </td>
                        <td>
                          <select class="form-control" [(ngModel)]="prorateAccural.prorateAccuralFirstMonthList[i].to"
                            name="fm-to-{{i}}" #to="ngModel" style="margin-top: 0;"
                            (change)='onFirstRuleToSelected($event, i, prorateAccural.prorateAccuralFirstMonthList[i].to)'
                            [disabled]="viewMode?'true':null && editMode? 'true':null"
                            [ngClass]="{ 'is-invalid': u.submitted && to.invalid }" required>
                            <option *ngFor="let item of prorateAccuralDayList" [ngValue]="item.id">{{ item.text }}
                            </option>
                          </select>
                          <span *ngIf="duplicateFirstMonthRuleTo[i]=='false'" class="error">Invalid selection.</span>
                          <div *ngIf="u.submitted && to.invalid" class="invalid-feedback error">
                            <div *ngIf="to.errors.required">To is required</div>
                          </div>
                        </td>
                        <td>
                          <input type="text" class="form-control" (keypress)="numberOnlyWithDecimal($event)"
                            [(ngModel)]="prorateAccural.prorateAccuralFirstMonthList[i].count" name="fm-count-{{i}}"
                            #count="ngModel" style="margin-top: 0;"
                            [attr.disabled]="viewMode?'true':null && editMode? 'true':null"
                            [ngClass]="{ 'is-invalid': u.submitted && to.invalid }" required />
                          <div *ngIf="u.submitted && count.invalid" class="invalid-feedback error">
                            <div *ngIf="count.errors.required">Count is required</div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colspan="4" class="text-center">
                          <label (click)="addFirstMonthRule()"><a href="javascript: void(0);"><i class="fa fa-plus"></i>
                              Add Rule</a></label>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>

              <div class="form-group row" *ngIf="prorateAccural.prorateBy == true && prorateAccuralType == '2'">
                <div class="col-sm-12">
                  <label>Last Month Rule</label>
                </div>
                <div class="col-sm-12" *ngIf="prorateAccural.prorateAccuralLastMonthList.length === 0">
                  <label (click)="addLastMonthRule()"><a href="javascript: void(0);">Define rule for the last month of
                      the policy</a></label>
                </div>
                <div class="col-sm-12" *ngIf="prorateAccural.prorateAccuralLastMonthList.length > 0">
                  <table class="table table-bordered user-table text-left">
                    <thead>
                      <tr>
                        <th style="width: 10%;"></th>
                        <th style="width: 30%;">From</th>
                        <th style="width: 30%;">To</th>
                        <th style="width: 30%;">Count</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let rule of prorateAccural.prorateAccuralLastMonthList; let i=index">
                        <td class="text-center">
                          {{i + 1}}
                          <input type="hidden"
                            [(ngModel)]="prorateAccural.prorateAccuralLastMonthList[i].leavePolicyPALMId"
                            name="leavePolicyPALMId-{{i}}" #leavePolicyPALMId="ngModel" />
                        </td>
                        <td>
                          <select class="form-control" [(ngModel)]="prorateAccural.prorateAccuralLastMonthList[i].from"
                            name="lm-from-{{i}}" #from="ngModel" style="margin-top: 0;"
                            (change)='onLastRuleFromSelected($event, i, prorateAccural.prorateAccuralLastMonthList[i].from)'
                            [disabled]="viewMode?'true':null && editMode? 'true':null"
                            [ngClass]="{ 'is-invalid': u.submitted && from.invalid }" required>
                            <option *ngFor="let item of prorateAccuralDayList" [ngValue]="item.id">{{ item.text }}
                            </option>
                          </select>
                          <span *ngIf="duplicateLastMonthRuleFrom[i]=='false'" class="error">Invalid selection.</span>
                          <div *ngIf="u.submitted && from.invalid" class="invalid-feedback error">
                            <div *ngIf="from.errors.required">From is required</div>
                          </div>
                        </td>
                        <td>
                          <select class="form-control" [(ngModel)]="prorateAccural.prorateAccuralLastMonthList[i].to"
                            name="lm-to-{{i}}" #to="ngModel" style="margin-top: 0;"
                            (change)='onLastRuleFromSelected($event, i, prorateAccural.prorateAccuralLastMonthList[i].to)'
                            [disabled]="viewMode?'true':null && editMode? 'true':null"
                            [ngClass]="{ 'is-invalid': u.submitted && to.invalid }" required>
                            <option *ngFor="let item of prorateAccuralDayList" [ngValue]="item.id">{{ item.text }}
                            </option>
                          </select>
                          <span *ngIf="duplicateLastMonthRuleTo[i]=='false'" class="error">Invalid selection.</span>
                          <div *ngIf="u.submitted && to.invalid" class="invalid-feedback error">
                            <div *ngIf="to.errors.required">To is required</div>
                          </div>
                        </td>
                        <td>
                          <input type="text" class="form-control" (keypress)="numberOnlyWithDecimal($event)"
                            [(ngModel)]="prorateAccural.prorateAccuralLastMonthList[i].count" name="lm-count-{{i}}"
                            #count="ngModel" style="margin-top: 0;"
                            [attr.disabled]="viewMode?'true':null && editMode? 'true':null"
                            [ngClass]="{ 'is-invalid': u.submitted && to.invalid }" required />
                          <div *ngIf="u.submitted && count.invalid" class="invalid-feedback error">
                            <div *ngIf="count.errors.required">Count is required</div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colspan="4" class="text-center">
                          <label (click)="addLastMonthRule()"><a href="javascript: void(0);"><i class="fa fa-plus"></i>
                              Add Rule</a></label>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>

            </div>
            <div class=" form-group pull-left" *ngIf="!viewMode">
              <div class="m-form__actions">
                <button type="submit" class="btn btn-primary btn-brand" (click)="u.submitted = true">Save</button>
                <button type="button" class="btn btn-light" data-dismiss="modal">Cancel</button>
              </div>
            </div>
          </form>
        </div>
      </div>

    </div>
  </div>
</div>

<!-- Pop up for Message start  -->
<div id="leaveTypeMessageModel" class="modal fade" role="dialog">
  <div class="modal-dialog modal-confirm modal-sm">
    <div class="modal-content" style="border-radius: 10px; top: 150px; left: 350px;">
      <div class="modal-header success-header">
        <div class="icon-box" *ngIf="showSuccess">
          <i class="fa fa-check"></i>
        </div>
        <div class="icon-box-remove" *ngIf="!showSuccess">
          <i class="fa fa-remove"></i>
        </div>
      </div>
      <div class="modal-body">
        <p class="text-center">{{showMessage}}</p>
      </div>
      <div class="modal-footer">
        <button class="btn btn-success btn-block" data-dismiss="modal">
          OK
        </button>
      </div>
    </div>
  </div>
</div>
<!-- Pop up message end -->

<div id="userMessageModel" class="modal fade" role="dialog">
  <div class="modal-dialog modal-confirm modal-sm">
    <div class="modal-content" style="border-radius: 10px; top: 150px; left: 350px;">
      <div class="modal-header success-header">
        <button _ngcontent-icx-c7="" _ngcontent-c11="" aria-label="Close" class="close" data-dismiss="modal"
          type="button"><span _ngcontent-icx-c7="" _ngcontent-c11="" aria-hidden="true">×</span></button>
        <div class="icon-box" *ngIf="showSuccess">
          <i class="fa fa-check"></i>
        </div>
        <div class="icon-box-remove" *ngIf="!showSuccess">
          <i class="fa fa-remove"></i>
        </div>
      </div>
      <div class="modal-body">
        <p class="text-center">{{showMessage}}</p>
      </div>
      <div class="modal-footer" *ngIf="!IsDelete">
        <button class="btn btn-danger btn-block" data-dismiss="modal">
          OK
        </button>
      </div>
      <div class="modal-footer" *ngIf="IsDelete" style="text-align: center;">
        <button class="btn btn-danger messagebutton" (click)="ConfdeleteDepartment(deleteId)" data-dismiss="modal">
          OK
        </button>

      </div>
    </div>
  </div>
</div>







<!-- Modal -->
<div class="modal fade small-filter" id="exampleModalfilter2" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Filters</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="width: 100%; display: inline-block;">
        <div class="btn-group" style="width:100%">
          <div class="container datashow rt-filters no-padding" *ngIf="flterShowData">
            <div class="card">
              <div class="card card-body">
                <div>

                  <div class="row">
                    <div class="col-sm-12">
                      <div class="form-group">
                        <label for="status">Status</label>
                        <select [(ngModel)]="statusSelectValue" class="form-control statsuClass"
                          style="margin-top: 10px;">
                          <option value=true selected="selected">Active</option>
                          <option value=false>InActive</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="form-group">
                        <label for="employee">Employee</label>
                        <ng-select [allowClear]="true" [(active)]="ActiveEmployee" [(items)]="EmployeeList"
                          (selected)="ddlSelection($event,'Employee','i','i')" placeholder="All Employees"
                          (removed)="removeFilterDropdownValue('Employee', $event)">
                        </ng-select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="form-group">
                        <label for="depart">Department</label>
                        <ng-select [allowClear]="true" [(active)]="ActiveDepartment" [(items)]="DepartmentList"
                          (selected)="ddlSelection($event,'Department','i','i')" placeholder="All Department"
                          (removed)="removeFilterDropdownValue('Department', $event)">
                        </ng-select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="form-group">
                        <label for="location">Location</label>
                        <ng-select [allowClear]="true" [(active)]="ActiveLocation" [(items)]="LocationList"
                          (selected)="ddlSelection($event,'Location','i','i')" placeholder="All Location"
                          (removed)="removeFilterDropdownValue('Location', $event)">
                        </ng-select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="form-group">
                        <label for="desigation">Designation</label>
                        <ng-select [allowClear]="true" [(active)]="ActiveDesigation" [(items)]="DesigationList"
                          (selected)="ddlSelection($event,'Desigation')" placeholder="All Designation"
                          (removed)="removeFilterDropdownValue('Desigation', $event)">
                        </ng-select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="form-group">
                        <label for="email">Role</label>
                        <ng-select [allowClear]="true" [(active)]="ActiveRoles" [(items)]="RolesList"
                          (selected)="ddlSelection($event,'Roles','i','i')" placeholder="All Roles"
                          (removed)="removeFilterDropdownValue('Roles', $event)">
                        </ng-select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <button type="button" (click)="SideFilterFunc()" class="btn btn-primary btn-brand">Search</button>
                      <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <button type="button" (click)="ResetFilter()" class="btn btn-default">Reset</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
