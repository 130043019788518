<div class="bg-overlady" *ngIf="userLoader">
  <div class="loader-position">
    <div class="loader"></div> 
    <h2>Loading....</h2>
  </div> 
</div>

<mk-box header="" [isCollapsable]="false" [isRemovable]="false" contentStyleClasses="table-responsive">
  <div class="row" style="margin: 0px;">

    <div class="col-sm-12 no-padding" style="border-bottom: 1px solid #ddd;">
      <h4 class="title-new" style="border-bottom: 2px solid #1877f2 !important;">General Settings</h4>
    </div>

  </div>  
  <!-- u.form.valid &&  -->
  <form name="saveGeneralSettings" (ngSubmit)="saveGeneralSettings()" autocomplete="off" #u="ngForm" novalidate>
    <div class="containers" *ngIf="generalSettingsData != null">
      <div class="row">
        <div class="col-sm-12">
          <h4><strong>Holidays/ Weekend Settings</strong></h4>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 text-left">
          <label class="control-label">Number of consecutive days after which Holidays/Weekends in the leave period should be considered as leave</label>
        </div>
        <div class="col-sm-2 col-xs-7">
          <input type="number" id="consecutiveDays" class="e-input"  placeholder="In Day(s)" 
            [(ngModel)]="generalSettingsData.consecutiveDays" name="consecutiveDays" #consecutiveDays="ngModel" 
            min="1" max="100" />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 text-left">
          <label class="control-label margin-top-8">Applicable for</label>
        </div>
        <div class="col-sm-3 col-xs-7">
          <!-- <select class="form-control" [(ngModel)]="generalSettingsData.applicableFor"
            name="applicableFor" #applicableFor="ngModel" [ngClass]="{ 'is-invalid': u.submitted && applicableFor.invalid }">
            <option [value]="null">Select</option>
            <option *ngFor="let item of applicableForList" [value]="item.id">{{ item.text }}</option>
          </select>  -->
          <ng-select [multiple]="true" [(items)]="applicableForList" [(ngModel)]="generalSettingsData.applicableFor"
            name="applicableFor" #applicableFor="ngModel"
            (removed)="removedDropdownValue('applicableFor', $event)" (selected)="selectDropdownValue('applicableFor', $event)"
            placeholder="Select Leave Types">
          </ng-select>
          <!-- <div *ngIf="applicableFor.invalid" class="invalid-feedback error">
            test - {{applicableFor.errors}} - {{generalSettingsData.applicableFor}}            
            <div *ngIf="applicableFor.errors.required">required</div>
            <div *ngIf="applicableFor.errors.minlength">minlength</div>
            <div *ngIf="applicableFor.errors.forbiddenName">forbiddenName</div>
          </div> -->
        </div>
      </div>


      <div class="row">
        <div class="col-sm-12">
          <h4><strong>Compensatory Requests</strong></h4>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 text-left">
          <label class="control-label margin-top-8">Mode to add Compensatory Request entry</label>
        </div>
        <div class="col-sm-3 col-xs-7">
          <select class="form-control" [(ngModel)]="generalSettingsData.compensatoryRequestMode"
            name="compensatoryRequestMode" #compensatoryRequestMode="ngModel" [ngClass]="{ 'is-invalid': u.submitted && compensatoryRequestMode.invalid }">
            <option [value]="null" disabled>Select</option>
            <option *ngFor="let item of compensatoryRequestList" [value]="item.id">{{ item.text }}</option>
          </select> 
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 text-left">
          <label class="control-label">Add Compensatory Request for future date</label>
        </div>
        <div class="col-sm-7 col-xs-7">
          <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" class="custom-control-input" id="rdbCompensatoryRequestFutureDateEnable" 
              [(ngModel)]="generalSettingsData.compensatoryRequestFutureDate" name="compensatoryRequestFutureDate" 
              #compensatoryRequestFutureDate="ngModel" [value]="true" />
            <label class="col-form-label mx-auto" style="margin-left: 10px;" for="rdbCompensatoryRequestFutureDateEnable">Enable</label>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <input type="radio" class="custom-control-input" id="rdbCompensatoryRequestFutureDateDisable" 
            [(ngModel)]="generalSettingsData.compensatoryRequestFutureDate" name="compensatoryRequestFutureDate" 
            #compensatoryRequestFutureDate="ngModel" [value]="false" checked />
            <label class="col-form-label mx-auto" style="margin-left: 10px;" for="rdbCompensatoryRequestFutureDateDisable">Disable</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 text-left">
          <label class="control-label margin-top-8">Leave to be credited when work is done on a Weekend</label>
        </div>
        <div class="col-sm-7 col-xs-7">
          <span>1 &#x2715; &nbsp;</span>
          <input type="number" class="e-input"  style="width: auto; display: inline-block;" placeholder="1.0" 
            [(ngModel)]="generalSettingsData.leavesCreditedOnWeekend" name="leavesCreditedOnWeekend" #leavesCreditedOnWeekend="ngModel" 
            step="0.1" min="1.0" max="100" />
          <span><code>(i.e) 1 day(s) &#x2715; 1 = 1 day(s), 3 hour(s) &#x2715; 1 = 3.00 hour(s) </code>
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 text-left">
          <label class="control-label margin-top-8">Leave to be credited when work is done on a Holidays</label>
        </div>
        <div class="col-sm-8 col-xs-7">
          <span>1 &#x2715; &nbsp;</span>
          <input type="number" class="e-input"  style="width: auto; display: inline-block;" placeholder="1.0" 
            [(ngModel)]="generalSettingsData.leavesCreditedOnHoliday" name="leavesCreditedOnHoliday" #leavesCreditedOnHoliday="ngModel" 
            step="0.1" min="1.0" max="100" />
          <span><code>(i.e) 1 day(s) &#x2715; 1 = 1 day(s), 3 hour(s) &#x2715; 1 = 3.00 hour(s) </code>
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 text-left">
          <label class="control-label margin-top-8">Credited Leaves expires after</label>
        </div>
        <div class="col-sm-8 col-xs-7">
          <input type="number" class="e-input"  style="width: auto; display: inline-block;" placeholder="90" 
            [(ngModel)]="generalSettingsData.creditedLeaveExpiresAfter" name="creditedLeaveExpiresAfter" #creditedLeaveExpiresAfter="ngModel"
            min="1" max="100" />
          <span>&nbsp;</span>
          <span>
            <select class="form-control" [(ngModel)]="generalSettingsData.creditedLeaveExpiresAfterType"
              name="creditedLeaveExpiresAfterType" #creditedLeaveExpiresAfterType="ngModel" 
              [ngClass]="{ 'is-invalid': u.submitted && creditedLeaveExpiresAfterType.invalid }"
              style="width: auto; display: inline-block;">
              <option [value]="null">Select</option>
              <option *ngFor="let item of dayMonthList" [value]="item.id">{{ item.text }}</option>
            </select>
          </span>
        </div>
      </div>
      <div class="row">
        <div class="form-group row mar-bot-10">
          <div class="col-sm-12">
            <div class="custom-control custom-checkbox custom-control-inline">
              <input type="checkbox" class="custom-control-input" id="chckEnableFollowUp"
                  name="enableFollowUp" value="true" [(ngModel)]="generalSettingsData.reminderEnable" (change)="ScheduleEmail(!showEmailSchedule)"
                  />
              <label class="col-form-label mx-auto" style="margin-left: 10px;" for="chckEnableFollowUp">Schedule Email Reminder</label>
            </div>
          </div>
          <div class="col-sm-12">
            <ng-container *ngIf="generalSettingsData.reminderEnable"  >
              <div class="row">
                <div class="col-sm-12 no-padding">
                  <div class="custom-control custom-radio custom-control-inline">
                  
                      <input type="radio" class="custom-control-input" id="rdbPayrollReportAdminEnable" 
                      [(ngModel)]="generalSettingsData.reminderOccasion" name="reminderOccasion" 
                      #reminderOccasion="ngModel" value="1" checked />
                    <label class="col-form-label mx-auto" style="margin-left: 10px;" for="rdbPayrollReportAdminEnable">One Time</label>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        
                    <input type="radio" class="custom-control-input" id="rdbPayrollReportAdminDisable" 
                      [(ngModel)]="generalSettingsData.reminderOccasion" name="reminderOccasion" 
                      #reminderOccasion="ngModel" value="2" checked />
                    <label class="col-form-label mx-auto" style="margin-left: 10px;" for="rdbPayrollReportAdminDisable">Repeat</label>
                  </div>

                  <div class="clearfix mar-bot-10"></div>

                  <ng-container >
  
                    <div class="row">

                      <div class="col-sm-4" style="padding-left: 0;">
                        <span>Reminder email sent at</span>
                      </div>
                      <div class="col-sm-2 no-padding">
                        <input type="time" id="ReminderTime" 
                           name="ReminderTime"  [(ngModel)]="generalSettingsData.reminderTime"
                           #ReminderTime="ngModel" 
                          style="position: relative;
                          top: -11px; line-height: inherit;" class="form-control" />
                      </div>
                      <div class="col-sm-6">&nbsp;</div>
                    </div>

                    <div class="row">

                      <div class="col-sm-4" style="padding-left: 0;">
                        <span>Reminder email sent </span>  <span *ngIf="generalSettingsData.reminderOccasion == 2">at every </span>
                      </div>
                      <div class="col-sm-8 col-xs-7">
                         <input type="number" class="e-input" [(ngModel)]="generalSettingsData.reminderNumber"  style="width: auto; display: inline-block;" 
                           name="ReminderNumber" id="ReminderNumber" 
                           #ReminderNumber="ngModel" 
                          min="1" max="100" required/>
                        <span>&nbsp;</span>
                        <span > 
                          <select class="form-control" [(ngModel)]="generalSettingsData.reminderLimit"
                            name="ReminderLimit"  id="ReminderLimit"
                            [ngClass]="{ 'is-invalid': u.submitted }"
                            style="width: auto; display: inline-block;">
                        
                            <option *ngFor="let item of ReminderDaysList" [value]="item.id">{{ item.text }}</option>
                          </select> from the requested date till the compoff expiry date.
                        </span>
                        
                       
                      </div>
                    </div>


                    <div class="row">
                      <div class="col-sm-4" style="padding-left: 0;">
                        <span>Email Template Name</span>
                      </div>
                      <div class="col-sm-8 col-xs-7">
                        <span>Comp off Expiry Date Reminder</span>&nbsp;&nbsp; <a [routerLink]="['/paSystem/Masters/emailtemplate', 109]" routerLinkActive="router-link-active" >Edit</a>
                        &nbsp;&nbsp;<span title="Email template used for Reminder email">  <i class="fa fa-info-circle"> </i></span> 
                      </div>
                    </div>
                  
                   
                  </ng-container>

                  

                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 text-left">
          <label class="control-label margin-top-8">Comp- off Limit</label>
        </div>
        <div class="col-sm-8 col-xs-7">
          <input type="number" class="e-input"  style="width: auto; display: inline-block;" placeholder="1" 
             name="CompoffLimit" [(ngModel)]="generalSettingsData.compLimit" 
            min="1" max="100" required/>
          <span>&nbsp;</span>
          <span>
            <select class="form-control" [(ngModel)]="generalSettingsData.compLimitType"
              name="CompOffLimitType"  
              [ngClass]="{ 'is-invalid': u.submitted }"
              style="width: auto; display: inline-block;">
              <option [value]="null">Select</option>
              <option *ngFor="let item of CompoffLimitDaysList" [value]="item.id">{{ item.text }}</option>
            </select>
          </span>
         
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 text-left">
          <label class="control-label margin-top-8">Compoff worked date allowed past </label>
        </div>
        <div class="col-sm-8 col-xs-7">
          <input type="number" class="e-input"  style="width: auto; display: inline-block;" placeholder="30" 
             name="CompoffLimitDays" [(ngModel)]="generalSettingsData.pastWorkingday" min="1" max="100" required/>
          <span>&nbsp;</span>
          <label class="control-label">&nbsp;Day(s)</label>
         
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 text-left">
          <label class="control-label margin-top-8">Unit(s) Allowed</label>
        </div>
        <div class="col-sm-3 col-xs-7">
          <select class="form-control" [(ngModel)]="generalSettingsData.unitsAllowed"
            name="unitsAllowed" #unitsAllowed="ngModel" [ngClass]="{ 'is-invalid': u.submitted && unitsAllowed.invalid }"
            (change)="setUnitValue($event);">
            <option [value]="null">Select</option>
            <option *ngFor="let item of unitList" [value]="item.id">{{ item.text }}</option>
          </select> 
        </div>
      </div>
      <div class="row" *ngIf="generalSettingsData.unitsAllowed != 2">
        <div class="col-sm-4 text-left">
          <label class="control-label">Duration(s) Allowed</label>
        </div>
        <div class="col-sm-7 col-xs-7">
          <div class="custom-control custom-checkbox custom-control-inline">
            <input type="checkbox" class="custom-control-input" id="chkDurationAllowedFullDay" 
              [(ngModel)]="generalSettingsData.durationAllowedFullDay" name="durationAllowedFullDay" 
              #durationAllowedFullDay="ngModel" />
            <label class="col-form-label mx-auto" style="margin-left: 10px;" for="chkDurationAllowedFullDay">Full Day</label>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

            <input type="checkbox" class="custom-control-input" id="chkDurationAllowedHalfDay" 
              [(ngModel)]="generalSettingsData.durationAllowedHalfDay" name="durationAllowedHalfDay" 
              #durationAllowedHalfDay="ngModel" />
            <label class="col-form-label mx-auto" style="margin-left: 10px;" for="chkDurationAllowedHalfDay">Half Day</label>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

            <input type="checkbox" class="custom-control-input" id="chkDurationAllowedQuarterDay" 
              [(ngModel)]="generalSettingsData.durationAllowedQuarterDay" name="durationAllowedQuarterDay" 
              #durationAllowedQuarterDay="ngModel" />
            <label class="col-form-label mx-auto" style="margin-left: 10px;" for="chkDurationAllowedQuarterDay">Quarter Day</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 text-left">
          <label class="control-label">Time Input</label>
        </div>
        <div class="col-sm-7 col-xs-7">
          <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" class="custom-control-input" id="rdbTimeInputEnable" 
              [(ngModel)]="generalSettingsData.timeInput" name="timeInput" 
              #timeInput="ngModel" [value]="true" />
            <label class="col-form-label mx-auto" style="margin-left: 10px;" for="rdbTimeInputEnable">Enable</label>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

            <input type="radio" class="custom-control-input" id="rdbTimeInputDisable" 
              [(ngModel)]="generalSettingsData.timeInput" name="timeInput" 
              #timeInput="ngModel" [value]="false" checked />
            <label class="col-form-label mx-auto" style="margin-left: 10px;" for="rdbTimeInputDisable">Disable</label>
          </div>
        </div>
      </div>


      <div class="row">
        <div class="col-sm-12">
          <h4><strong>Permissions</strong></h4>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 text-left">
          <label class="control-label margin-top-8">User Availability Report Access</label>
        </div>
        <div class="col-sm-3 col-xs-7">
          <select class="form-control" [(ngModel)]="generalSettingsData.userReportAccess"
            name="userReportAccess" #userReportAccess="ngModel" [ngClass]="{ 'is-invalid': u.submitted && userReportAccess.invalid }">
            <option [value]="null">Select</option>
            <option *ngFor="let item of useravailabilityReportAccess" [value]="item.id">{{ item.text }}</option>
          </select> 
        </div>
      </div>


      <div class="row">
        <div class="col-sm-12">
          <h4><strong>Payroll Report Settings</strong></h4>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 text-left">
          <label class="control-label">Enable Payroll Report for Admin</label>
        </div>
        <div class="col-sm-7 col-xs-7">
          <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" class="custom-control-input" id="rdbPayrollReportAdminEnable" 
              [(ngModel)]="generalSettingsData.enablePayrollReportAdmin" name="enablePayrollReportAdmin" 
              #enablePayrollReportAdmin="ngModel" [value]="true" checked />
            <label class="col-form-label mx-auto" style="margin-left: 10px;" for="rdbPayrollReportAdminEnable">Enable</label>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

            <input type="radio" class="custom-control-input" id="rdbPayrollReportAdminDisable" 
              [(ngModel)]="generalSettingsData.enablePayrollReportAdmin" name="enablePayrollReportAdmin" 
              #enablePayrollReportAdmin="ngModel" [value]="false" checked />
            <label class="col-form-label mx-auto" style="margin-left: 10px;" for="rdbPayrollReportAdminDisable">Disable</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 text-left">
          <label class="control-label">Include Weekend(s)</label>
        </div>
        <div class="col-sm-7 col-xs-7">
          <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" class="custom-control-input" id="rdbIncludeWeekendsEnable" 
              [(ngModel)]="generalSettingsData.includeWeekends" name="includeWeekends" 
              #includeWeekends="ngModel" [value]="true" />
            <label class="col-form-label mx-auto" style="margin-left: 10px;" for="rdbIncludeWeekendsEnable">Enable</label>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

            <input type="radio" class="custom-control-input" id="rdbIncludeWeekendsDisable" 
              [(ngModel)]="generalSettingsData.includeWeekends" name="includeWeekends" 
              #includeWeekends="ngModel" [value]="false" />
            <label class="col-form-label mx-auto" style="margin-left: 10px;" for="rdbIncludeWeekendsDisable">Disable</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 text-left">
          <label class="control-label">Include Holiday(s)</label>
        </div>
        <div class="col-sm-7 col-xs-7">
          <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" class="custom-control-input" id="rdbIncludeHolidaysEnable" 
              [(ngModel)]="generalSettingsData.includeHolidays" name="includeHolidays" 
              #includeHolidays="ngModel" [value]="true" />
            <label class="col-form-label mx-auto" style="margin-left: 10px;" for="rdbIncludeHolidaysEnable">Enable</label>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

            <input type="radio" class="custom-control-input" id="rdbIncludeHolidaysDisable" 
              [(ngModel)]="generalSettingsData.includeHolidays" name="includeHolidays" 
              #includeHolidays="ngModel" [value]="false" />
            <label class="col-form-label mx-auto" style="margin-left: 10px;" for="rdbIncludeHolidaysDisable">Disable</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 text-left">
          <label class="control-label">Manage Leave Request With</label>
        </div>
        <div class="col-sm-7 col-xs-7">
          <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" class="custom-control-input" id="ManageLeaveid" 
              [(ngModel)]="generalSettingsData.manageLeaveRequest" name="ManageLeave" 
              #ManageLeave="ngModel" [value]="1" />
            <label class="col-form-label mx-auto" style="margin-left: 10px;" for="ManageLeave"> Absent</label>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            
            <input type="radio" class="custom-control-input" id="ManageLeaveid" 
              [(ngModel)]="generalSettingsData.manageLeaveRequest" name="ManageLeave" 
              #ManageLeave="ngModel" [value]="2" />
            <label class="col-form-label mx-auto" style="margin-left: 10px;" for="ManageLeave"> Leaves</label>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

            <!-- <input type="radio" class="custom-control-input" id="ManageLeaveid" 
            [(ngModel)]="generalSettingsData.manageLeaveRequest" name="ManageLeave" 
            #ManageLeave="ngModel" [value]="3" />
          <label class="col-form-label mx-auto" style="margin-left: 10px;" for="ManageLeave">Present ,Leaves & Absent</label> -->
          </div>
        </div>
      </div>


      <div class="row">
        <div class="col-sm-12">
          <h4><strong>LOP Report Settings</strong></h4>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 text-left">
          <label class="control-label">Unpaid leave to be marked as</label>
        </div>
        <div class="col-sm-7 col-xs-7">
          <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" class="custom-control-input" id="rdbUnpaidLeavesMarkedAsLOP" 
              [(ngModel)]="generalSettingsData.unpaidLeavesMarkedAs" name="unpaidLeavesMarkedAs" 
              #unpaidLeavesMarkedAs="ngModel" [value]="1" checked />
            <label class="col-form-label mx-auto" style="margin-left: 10px;" for="rdbUnpaidLeavesMarkedAsLOP">Loss of Pay</label>
            <br />
            <input type="radio" class="custom-control-input" id="rdbUnpaidLeavesMarkedAsCarryOver" 
              [(ngModel)]="generalSettingsData.unpaidLeavesMarkedAs" name="unpaidLeavesMarkedAs" 
              #unpaidLeavesMarkedAs="ngModel" [value]="2" />
            <label class="col-form-label mx-auto" style="margin-left: 10px;" for="rdbUnpaidLeavesMarkedAsCarryOver">Carry Over to Next Pay Period</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 text-left">
          <label class="control-label margin-top-8">Maximum LOP count allowed</label>
        </div>
        <div class="col-sm-7 col-xs-7">
          <input type="number" class="e-input" style="width: auto; display: inline-block;" placeholder="" 
            [(ngModel)]="generalSettingsData.maxLOPAllowedDays" name="maxLOPAllowedDays" #maxLOPAllowedDays="ngModel"
            min="1" max="100" />
          <span>&nbsp; day(s)</span>
          <span>&nbsp;&nbsp;&nbsp;</span>
          <span title="The default count in the total no. of days in the pay period. Unpaid leave count beyond the maximum count defined here will be marked as 'Carry Over'"><i class="fa fa-info-circle"></i></span>
        </div>
      </div>


      <div class="row">
        <div class="col-sm-12">
          <h4><strong>Export Settings</strong></h4>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 text-left">
          <label class="control-label">Enable Password Protection</label>
        </div>
        <div class="col-sm-7 col-xs-7">
          <div class="custom-control custom-checkbox custom-control-inline">
            <input type="checkbox" class="custom-control-input" id="chkExportEnablePasswordProtection" 
              [(ngModel)]="generalSettingsData.exportEnablePasswordProtection" name="exportEnablePasswordProtection" 
              #exportEnablePasswordProtection="ngModel" />
            <span>&nbsp;&nbsp;&nbsp;</span>
            <span title="The file will be encrypted with a password, which will be sent to your mail."><i class="fa fa-info-circle"></i></span>
          </div>
        </div>
      </div>


      <div class="row">
        <div class="col-sm-12">
          <h4><strong>Leave Cancellation</strong></h4>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 text-left">
          <label class="control-label">Make reason for Leave cancellation mandatory</label>
        </div>
        <div class="col-sm-7 col-xs-7">
          <div class="custom-control custom-checkbox custom-control-inline">
            <input type="checkbox" class="custom-control-input" id="chkLeaveCancellationReasonMandatory" 
              [(ngModel)]="generalSettingsData.leaveCancellationReasonMandatory" name="leaveCancellationReasonMandatory" 
              #leaveCancellationReasonMandatory="ngModel" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 text-left">
          <label class="control-label">Allow Leave requests until the next</label>
        </div>
        <div class="col-sm-7 col-xs-7">
          <div class="custom-control custom-checkbox custom-control-inline">
            <input type="checkbox" class="custom-control-input" id="chkleaveRequestsUntiltheNext" 
              [(ngModel)]="generalSettingsData.leaveRequestsUntiltheNext" name="leaveRequestsUntiltheNext" 
              #leaveRequestsUntiltheNext="ngModel" />
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-sm-12">
        <button type="submit" class="btn btn-primary btn-brand ">Submit</button>
        <button type="reset" class="btn btn-outline-secondary ">Reset</button>
      </div>
    </div>
  </form>
</mk-box>

<!-- Message Modal -->
<div id="userMessageModel" class="modal  fade" role="dialog">
  <div class="modal-dialog modal-confirm modal-sm">
    <div class="modal-content" style="border-radius:10px; top: 150px; left: 350px;">
      <div class="modal-header success-header">
        <div class="icon-box" *ngIf="showSuccess">
          <i class="fa fa-check"></i>

        </div>
        <div class="icon-box-remove" *ngIf="!showSuccess">
          <i class="fa fa-remove"></i>
        </div>
      </div>
      <div class="modal-body">
        <p class="text-center">{{showMessage}}</p>
      </div>
      <div class="modal-footer">
        <button class="btn btn-success btn-block" data-dismiss="modal">OK</button>
      </div>
    </div>
  </div>
</div>