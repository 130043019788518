import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { LeaveService } from "../../leave.service";
import { AuthService } from "src/app/services/auth.service";
import { CommonService } from "src/app/common.service";
import { ToasterService } from 'src/app/+layout/expense/toaster/toaster.service';


@Component({
  selector: 'app-generalsettings',
  templateUrl: './generalsettings.component.html',
  styleUrls: ['./generalsettings.component.css']
})
export class GeneralsettingsComponent implements OnInit {
  currentUser: any;
  userLoader = false;

  public showMessage: string;
  public showSuccess: Boolean;

  ViewPermission: boolean;
  EditPermission: boolean;

  generalSettingsData: any;

  tempapplicableForLeaveType: any;
  public applicableForLeaveType = [];

  applicableForList: any;
  compensatoryRequestList: any;
  dayMonthList: any;
  unitList: any;
  CompoffLimitDaysList: any;
  useravailabilityReportAccess: any;
  CmpCode:any;
  ReminderDaysList: any;
  constructor(
    private _leaveServ: LeaveService,
    private _authSer: AuthService,
    private commonService: CommonService,
    private  toasterService:ToasterService
  ) {
    this.CmpCode = this._authSer.getcompanyCode();
  }

  ngOnInit() {// Angular lifecycle hook
    this.CmpCode = this._authSer.getcompanyCode();
    this._authSer.currentUser.subscribe((res) => {
      this.currentUser = res;
      this.ViewPermission = true; //this.currentUser.permission.includes(Permission.MoMView);
      this.EditPermission = true; //this.currentUser.permission.includes(Permission.MoMEdit);

      // Call after getting current userId
      this.setDefault();
    });
  }

  setDefault() {// Created By K Set Default values
    let vm = this;
    //vm.applicableForList = [{ 'id': 1, 'text': 'Every 1st Day of the month' }];
    vm.compensatoryRequestList = [{ 'id': 1, 'text': 'None' }, { 'id': 2, 'text': 'Manual' }, { 'id': 3, 'text': 'Automatic' }, { 'id': 4, 'text': 'Both' }];
    vm.dayMonthList = [{ 'id': 1, 'text': 'Day(s)' }, { 'id': 2, 'text': 'Month(s)' }];
    vm.CompoffLimitDaysList = [{ 'id': 1, 'text': 'Day(s)' }, { 'id': 2, 'text': 'Month(s)' }, { 'id': 3, 'text': 'Year(s)' }];
    vm.ReminderDaysList = [{ 'id': "1", 'text': 'Day(s)' }, { 'id': "2", 'text': 'Month(s)' }];
    vm.unitList = [{ 'id': 1, 'text': 'Day' }, { 'id': 2, 'text': 'Hour' }, { 'id': 3, 'text': 'Both' }];
    vm.useravailabilityReportAccess = [{ 'id': 1, 'text': 'All data to admin' }, { 'id': 2, 'text': 'Department data to department head' }, { 'id': 3, 'text': 'Department data to department member' }, { 'id': 4, 'text': 'All data to all users' }];
    vm.getLeaveTypes();
    vm.getGeneralSettings();
  }

  getLeaveTypes() {// Created By A Getting all leave type
    this.userLoader = true;
    this.applicableForList = [];
    this._leaveServ.getAllLeavetype(this.CmpCode).subscribe((res) => {
      let leaveTypeList = res["data"].table;
      console.clear();
      console.log(leaveTypeList);
      this.userLoader = false;
      let templeaveTypeListForSelect = [];

      leaveTypeList.forEach(function (item) {
        if (item.unit === 1) {
          let _lType = { 'id': item.leaveTypeId, 'text': item.leaveTypeName };
          templeaveTypeListForSelect.push(_lType);
        }
      });
      this.applicableForList = templeaveTypeListForSelect;
      if (this.generalSettingsData != null && this.generalSettingsData != undefined) {
        this.bindNGSelectList('applicableFor', this.generalSettingsData.applicableFor);
      }
    });
  }


  getGeneralSettings() {// Created By A Getting leave general settings data
    const vm = this;
    vm.userLoader = true;
    this._leaveServ.getGeneralSettings(this.CmpCode).subscribe((res) => {
      vm.userLoader = false;
      vm.generalSettingsData = res["data"];

      vm.bindNGSelectList('applicableFor', vm.generalSettingsData.applicableFor);
    },
    error => {
      console.log(error);
    });
  }

  showEmailSchedule  = false
  ScheduleEmail(val){// Created By A set value
  this.showEmailSchedule  = val

  }

  // Save Settings
  saveGeneralSettings() {// Created By A On save leave general settings
    debugger
    const vm = this;
    vm.userLoader = true;
    vm.generalSettingsData.applicableFor = "";
    let tempapplicableFor = "";
    vm.applicableForLeaveType.forEach(function (id) {
      tempapplicableFor = tempapplicableFor + id + ",";
    });
    if (tempapplicableFor.length > 1) {
      tempapplicableFor = tempapplicableFor.slice(0, -1);
    }
    vm.generalSettingsData.cmpCode=this.CmpCode
    vm.generalSettingsData.applicableFor = tempapplicableFor;
    vm.generalSettingsData.leaveRequestsUntiltheNext

    this._leaveServ.saveGeneralSettings(vm.generalSettingsData).subscribe((data: any) => {
      vm.userLoader = false;
      if (data && data.success == true) {
        vm.showMessage = data.data;
        vm.showSuccess = true;
        this.toasterService.show('success',this.showMessage)
      //  vm.commonService.OpenModalHandler('userMessageModel');
      } else {
        console.log(data);
      }
    }, error => {
      vm.userLoader = false;
    });
  }

  selectDropdownValue(_type, value) {// Created By A On select applicable for
    switch (_type) {
      case 'applicableFor':
        if (this.applicableForLeaveType.includes(value.id.toString()) === false) {
          this.applicableForLeaveType.push(value.id.toString());
        }
        console.log(this.applicableForLeaveType);
        break;
    }
  }

  removedDropdownValue(_type, value) {// Created By A On clear applicable for
    let _index = -1;
    switch (_type) {
      case 'applicableFor':
        _index = this.applicableForLeaveType.indexOf(value.id.toString());
        if (_index !== -1) {
          this.applicableForLeaveType.splice(_index, 1);
        }
        break;
    }
  }

  bindNGSelectList(_type, _list) {// Created By A Binding applicable for
    const vm = this;
    switch (_type) {
      case 'applicableFor' :
        if (_list !== null && _list !== undefined) {
          let list = _list.split(",");
          let tempSelectList = this.applicableForList;
          let selectedData = [];
          this.applicableForLeaveType = [];
          list.forEach(function (id) {
            let item = tempSelectList.find(i => i.id == id);
            if (item !== undefined && item !== null) {
              selectedData.push(item);
            }
          });
          vm.applicableForLeaveType = list;
          vm.generalSettingsData.applicableFor = selectedData;
        }
        break;
    }
  }

  setUnitValue(e) {// Created By K On submit unit value
    let _value = e.srcElement.value;
    if (_value == 2) {
      this.generalSettingsData.durationAllowedFullDay = false;
      this.generalSettingsData.durationAllowedHalfDay = false;
      this.generalSettingsData.durationAllowedQuarterDay = false;
    } else {
      this.generalSettingsData.durationAllowedFullDay = true;
      this.generalSettingsData.durationAllowedHalfDay = true;
      this.generalSettingsData.durationAllowedQuarterDay = true;
    }
  }
}
