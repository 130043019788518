import { Component, OnInit, HostListener} from '@angular/core';
import { LayoutService } from 'angular-admin-lte';
import { NgxZendeskWebwidgetService } from 'ngx-zendesk-webwidget';
import { disableDebugTools } from "@angular/platform-browser";
import { ToasterService } from './+layout/expense/toaster/toaster.service';
import { AuthService } from './services/auth.service';
import { ExpenseHttpService } from './services/expense/expense-http.service';
import { DomSanitizer } from '@angular/platform-browser';
import { from, Subject} from 'rxjs';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  public customLayout: boolean;
  currentYear: number;
  public async: any;
  message = '';
  messages: string[] = [];
  userActivity;
  userInactive: Subject<any> = new Subject();
  usermose = [];

  dynamicCSSUrl: string;
  UserID: any;
  ThemeId: any;
Logo:any;
  constructor( private layoutService: LayoutService, 
    private toaster: ToasterService, 
    // private _NgxZendeskWebwidgetService: NgxZendeskWebwidgetService,
    private authService: AuthService,
    private Http: ExpenseHttpService,
    public sanitizer: DomSanitizer) {
    // this._NgxZendeskWebwidgetService.setSettings({
    //   webWidget: { launcher: { chatLabel: { '*': 'Support' } } }
    // });
    // this._NgxZendeskWebwidgetService.initZendesk();
    this.UserID = this.authService.getUserId();
    this.setTimeout();
    this.userInactive.subscribe(() => { 
                                        this.toaster.show("warning", "You were In- Active for 20 Minutes")
                                        setTimeout(() => {
                                        this.authService.logout();
                                        }, 3000);
                                      });
  }
  setTimeout() {
    this.userActivity = setTimeout(() => this.userInactive.next(undefined), 1200000); //Time for session time out 1200000
  } 

  @HostListener('window:mousemove') refreshUserState() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }
  @HostListener('window:keydown') refreshUserState2() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }
  // setBaseUrl(){
  //   debugger
  //   let baseDOM = document.getElementsByTagName("base")
  //   let strURL = baseDOM[0].href.split("Customer")
  //   let cmp = this.authService.getCmpName()
  //   if(cmp == undefined  || cmp == null ){
  //     baseDOM[0].href = strURL[0]+"Customer"
  //   }else{
  //     if(this.authService.getToken()){
   
  //       baseDOM[0].href  = strURL[0] + cmp 
  //     }else{
  //       baseDOM[0].href = strURL[0]+"Customer"
  //     }
     
  //   }
   
  // }

  ngOnInit() {
    this.currentYear = (new Date()).getFullYear();
    this.layoutService.isCustomLayout.subscribe((value: boolean) => {
      this.customLayout = value;
    });
    this.ThemeId = this.authService.getThemeId();
    this.Logo = this.authService.getLogo();
    disableDebugTools();
    console.log = function (): void { };
    console.debug = function (): void { debugger ;};
    console.warn = function (): void { };
    console.info = function (): void { };
    // this.setBaseUrl();
    
  }
}