<div class="bg-overlady" *ngIf="userLoader">
  <div class="loader-position">
    <div class="loader"></div>
    <h2>Loading....</h2>
  </div>
</div> 

<mk-box header="" [isCollapsable]="false" [isRemovable]="false" contentStyleClasses="table-responsive">
  <div class="row" style="margin: 0px;">

    <div class="col-sm-3 no-padding">
      <h4 class="title-new">Customize Leave Balance</h4>
    </div>

    <div class="col-sm-9 text-right btnset" style="padding-top: 46px;">
      <a routerLink ='/leave/setting/customize-leave-balance/update-balance' routerLinkActive="active">
        <button *ngIf="AddPermission || EditPermission" type="button" class="btn btn-primary btn-brand" style="background: #f5f7f7;
        border-radius: 8px !important;
        font-size: 16px;
        padding: 7px 20px !important;width:auto; height: auto;">Customize</button>
      </a>
      <span>&nbsp;&nbsp;&nbsp;</span>
      <div class="btn-group">
        <button type="button" class="btn btn-primary btn-brand" data-toggle="dropdown" aria-haspopup="true"
          aria-expanded="false" title="Export">
          <img src="../assets/img/Logo/22.png">
        </button>
        <ul class="dropdown-menu">
          <li>
            <a (click)="export()">Excel</a>
          </li>
          <li>
            <a (click)="printList('tableData')">Print</a>
          </li>
          <li>
            <a (click)="exportTableToCSV()">CSV</a>
          </li>
          <!-- <li>
              <a href="javascript:void(0)" (click)="ExporttoExcel(1)"
                >Flat file - Comma delimited</a
              >
            </li>
            <li>
              <a href="javascript:void(0)" (click)="ExporttoExcel(2)"
                >Flat file - Tab delimited</a
              >
            </li> -->
        </ul>
      </div>
      <!-- <button class="mx-auto" type="button" id="navbarDropdown" (click)="filterShow()"  role="button" data-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false"><i class="fa fa-filter" aria-hidden="true"></i>
      </button> -->

      <!-- <div class="btn-group">
        <button type="button" class="AddLeaveType mx-auto btn btn-primary btn-brand" data-toggle="modal" data-target="#exampleModalfilter2" title="Filters" (click)="filterShow()">
          <img src="../assets/img/Logo/23.png">
        </button>
      </div>


      <div class="btn-group">
        <ul class="paggingUl">
          <ngb-pagination  [collectionSize]="myRequestsListLength" [(page)]="myRequestsPage" [pageSize]="myRequestsPageSize"></ngb-pagination>
          <li style="margin-top: -4px;">
            <span class="recordsMsg">No. of Records</span>
            <select class="NoOfpage" style="width: auto;" [(ngModel)]="myRequestsPageSize">
              <option [ngValue]="10">10</option>
              <option [ngValue]="20">20</option>
              <option [ngValue]="50">50</option>
            </select>
          </li>
        </ul>
        </div> -->

      <!-- Filter and Export -->
      <!-- <div class="btn-group">
        <div class="container datashow rt-filters" *ngIf="flterShowData">
          <div class="card">
            <div class="card card-body">
              <div>
                <div class="row row-bor-bot">                   
                  <div class="col-xs-9">
                    <h4>Filter</h4>
                  </div>
                  <div class="col-xs-3 text-right">
                    <h4 class="icon-close" (click)="closeFilter();">x</h4>
                  </div>
                </div>
               
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="depart">Type</label>
                      <select class="form-control" name="filterType" [(ngModel)]="filterType"
                        (change)="onChangeFilterType($event);">
                        <option value=-1>All Types</option>
                        <option value=1>Paid</option>
                        <option value=2>Unpaid</option>
                        <option value=3>On Duty</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="depart">Leave Type</label>
                      <ng-select [allowClear]="true" [(active)]="ActiveLeaveTypes" [(items)]="ddlLeaveTypeList"
                        (selected)="ddlSelection($event, 'LeaveType')" placeholder="All Leave Types"
                        #ddlFilterLeaveType (opened)="closeDropdownList(ddlFilterLeaveType)"
                        (removed)="removeFilterDropdownValue($event, 'LeaveType')">
                      </ng-select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="depart">Employee</label>
                      <ng-select [allowClear]="true" [(active)]="ActiveEmployees" [(items)]="ddlEmployeeList"
                        (selected)="ddlSelection($event, 'Employee')" placeholder="All Employees"
                        #ddlFilterEmployee (opened)="closeDropdownList(ddlFilterEmployee)"
                        (removed)="removeFilterDropdownValue($event, 'Employee')">
                      </ng-select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="depart">Department</label>
                      <ng-select [allowClear]="true" [(active)]="ActiveDepartments" [(items)]="ddlDepartmentList"
                        (selected)="ddlSelection($event, 'Department')" placeholder="All Department"
                        #ddlFilterDepartments (opened)="closeDropdownList(ddlFilterDepartments)"
                        (removed)="removeFilterDropdownValue($event, 'Department')">
                      </ng-select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="location">Location</label>
                      <ng-select [allowClear]="true" [(active)]="ActiveLocations" [(items)]="ddlLocationList"
                        (selected)="ddlSelection($event, 'Location')" placeholder="All Location"
                        #ddlFilterLocations (opened)="closeDropdownList(ddlFilterLocations)"
                        (removed)="removeFilterDropdownValue($event, 'Location')">
                      </ng-select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="desigation">Desigation</label>
                      <ng-select [allowClear]="true" [(active)]="ActiveDesignations" [(items)]="ddlDesignationList"
                        (selected)="ddlSelection($event, 'Desigation')" placeholder="All Desigation"
                        #ddlFilterDesigations (opened)="closeDropdownList(ddlFilterDesigations)"
                        (removed)="removeFilterDropdownValue($event, 'Desigation')">
                      </ng-select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="depart">User Status</label>
                      <select class="form-control" name="filterUserStatus" [(ngModel)]="filterUserStatus">
                        <option value=null>All</option>
                        <option value="0">Active</option>
                        <option value="1">Inactive</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">               
                  <div class="col-sm-12">
                    <button type="button" (click)="processFilters()" class="btn btn-primary btn-brand">Search</button>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <button type="button" (click)="resetFilters()" class="btn btn-default">Reset</button>
                  </div>                    
                </div>
              </div>
            </div>
          </div>
        </div>

      
      </div> -->
    </div>
  </div>

  <!-- Table -->
  <div class="row">
    <div class="col-sm-12 no-padding" *ngIf="getLeaveAllBalanceData.length > 0">
      <div class="rt-table-outer" id="tableData">
        <div class="rt-table-inner horizontal-scroll-except-first-column">
        

        <ejs-grid #overviewgrid id='overviewgrid'  [dataSource]='newArray' rowHeight='38' [allowSelection]='true' [columns]="columns" 
        [allowSorting]='true' height='600' [enableHover]='false' [selectionSettings]='selectionSettings' (queryCellInfo)="customiseCell($event)"
        [filterSettings]='filterSettings' [allowSorting]='true' [allowFiltering]='true' [enableVirtualization]='false' 
        (load)='load()' [allowPaging]='true'   [pageSettings]='pageSettings' [allowExcelExport]='true' [allowPdfExport]="true">
        <e-columns>
            <ng-template #template let-data>
              <e-column field='data.value' headerText='data.key'  clipMode='EllipsisWithTooltip'>
              </e-column>
            </ng-template>
        </e-columns>
      </ejs-grid>
          <!-- <table class="table table-bordered table-hover rt-table">
            <thead>
              <tr>
                <th scope="col" rowspan="2" style="text-align: left;border-right: 2px solid #ddd !important;">Employee</th>
                <ng-container *ngIf="(filterType == -1 || filterType == 1) && paidListCount > 0">
                  <th scope="col" [attr.colspan]="paidListCount" style="text-align: center;">Paid</th>
                </ng-container>
                <ng-container *ngIf="(filterType == -1 || filterType == 2) && unpaidListCount > 0">
                  <th scope="col" [attr.colspan]="unpaidListCount" style="text-align: center;">Unpaid</th>
                </ng-container>
                <ng-container *ngIf="(filterType == -1 || filterType == 3) && onDutyListCount > 0">
                  <th scope="col" [attr.colspan]="onDutyListCount" style="text-align: center;">On Duty</th>
                </ng-container>
              </tr> 
              <tr>
                <ng-container *ngIf="(filterType == -1 || filterType == 1) && paidListCount > 0">
                  <ng-container *ngIf="paidListCount > 0">
                    <th scope="col" *ngFor="let leaveType of getLeaveAllBalanceData[0].paidLeavesList" style="text-align: center;"> {{ leaveType.leaveTypeName }}</th>                
                  </ng-container>
                  <ng-container *ngIf="paidListCount == 0">
                    <th scope="col" style="text-align: center;">&nbsp;</th>                
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="(filterType == -1 || filterType == 2) && unpaidListCount > 0">
                  <ng-container *ngIf="unpaidListCount > 0">
                    <th scope="col" *ngFor="let leaveType of getLeaveAllBalanceData[0].unpaidLeavesList" style="text-align: center;"> {{ leaveType.leaveTypeName }}</th>
                  </ng-container>
                  <ng-container *ngIf="unpaidListCount == 0">
                    <th scope="col" style="text-align: center;">&nbsp;</th>                
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="(filterType == -1 || filterType == 3) && onDutyListCount > 0">
                  <ng-container *ngIf="onDutyListCount > 0">
                    <th scope="col" *ngFor="let leaveType of getLeaveAllBalanceData[0].onDutyLeavesList" style="text-align: center;"> {{ leaveType.leaveTypeName }}</th>
                  </ng-container>
                  <ng-container *ngIf="onDutyListCount == 0">
                    <th scope="col" style="text-align: center;">&nbsp;</th>                
                  </ng-container>
                </ng-container>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let item of countries">
                <tr [ngClass]="{'tr-deleted' : item.isdeleted == true, 
                      'tr-inactive' : (item.inActive == true && item.isdeleted == false)}" 
                    data-toggle="modal" data-backdrop="static" data-keyboard="false"
                    data-target="#customizeleave">
                  <td scope="row" style="text-align: left;" title="{{ (item.inActive == true && item.isdeleted == false) ? 'User Inactive' : (item.isdeleted == true ? 'User Deleted' : '') }}">
                  
                    <span>
                      <img src="{{userImage(item)}}" style="width: 28px;margin-right: 5px; border-radius: 50%;">
                      {{ item.employeeName }} - {{ item.empCode }}
                    </span>

                   
                  </td>
                  <ng-container *ngIf="(filterType == -1 || filterType == 1) && paidListCount > 0">
                    <ng-container *ngIf="paidListCount > 0">
                      <td style="text-align: center;" *ngFor="let paidItem of item.paidLeavesList">{{ paidItem.balance }}</td>
                    </ng-container>
                    <ng-container *ngIf="paidListCount == 0">
                      <th scope="col" style="text-align: center;">&nbsp;</th>                
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="(filterType == -1 || filterType == 2) && unpaidListCount > 0">
                    <ng-container *ngIf="unpaidListCount > 0">
                      <td style="text-align: center;" *ngFor="let unpaidItem of item.unpaidLeavesList">{{ unpaidItem.balance }}</td>
                    </ng-container>
                    <ng-container *ngIf="unpaidListCount == 0">
                      <th scope="col" style="text-align: center;">&nbsp;</th>                
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="(filterType == -1 || filterType == 3) && onDutyListCount > 0">
                    <ng-container *ngIf="onDutyListCount > 0">
                      <td style="text-align: center;" *ngFor="let onDutyItem of item.onDutyLeavesList">{{ onDutyItem.balance }}</td>  
                    </ng-container>
                    <ng-container *ngIf="onDutyListCount == 0">
                      <th scope="col" style="text-align: center;">&nbsp;</th>                
                    </ng-container>
                  </ng-container>
                </tr>
              </ng-container>
            </tbody>
          </table> -->
        </div>
      </div>
    </div>
  </div>
  <br/>
  <!-- Pagination -->
  <!-- <div class="row">
    <div class="col-sm-12">
      <div class="d-flex justify-content-between p-2" class="col-sm-12 text-right">
        <ul class="paggingUl">
          <ngb-pagination  [collectionSize]="myRequestsListLength" [(page)]="myRequestsPage" [pageSize]="myRequestsPageSize"></ngb-pagination>
          <li>
            <span class="recordsMsg">No. of Records</span>
            <select class="NoOfpage" style="width: auto;" [(ngModel)]="myRequestsPageSize">
              <option [ngValue]="10">10</option>
              <option [ngValue]="20">20</option>
              <option [ngValue]="50">50</option>
            </select>
          </li>
        </ul>
      </div>
    </div>
  </div> -->
  
</mk-box>





<!-- pop up model  -->
<!-- <div id="leaveTypeMessageModel" class="modal fade" role="dialog">
  <div class="modal-dialog modal-confirm modal-sm">
    <div class="modal-content" style="border-radius: 10px; top: 150px; left: 350px;">
      <div class="modal-header success-header">
        <div class="icon-box" *ngIf="showSuccess">
          <i class="fa fa-check"></i>
        </div>
        <div class="icon-box-remove" *ngIf="!showSuccess">
          <i class="fa fa-remove"></i>
        </div>
      </div>
      <div class="modal-body">
        <p class="text-center">{{showMessage}}</p>
      </div>
      <div class="modal-footer">
        <button class="btn btn-success btn-block" data-dismiss="modal">
          OK
        </button>
      </div>
    </div>
  </div>
</div> -->




<!-- Modal -->
<div class="modal fade small-filter" id="exampleModalfilter2" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Filters</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="width: 100%; display: inline-block;">
        <div class="container datashow rt-filters" *ngIf="flterShowData">
          <div class="card">
            <div class="card card-body">
              <div>
                
               
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="depart">Type</label>
                      <select class="form-control" name="filterType" [(ngModel)]="filterType"
                        (change)="onChangeFilterType($event);">
                        <option value=-1>All Types</option>
                        <option value=1>Paid</option>
                        <option value=2>Unpaid</option>
                        <option value=3>On Duty</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="depart">Leave Type</label>
                      <ng-select [allowClear]="true" [(active)]="ActiveLeaveTypes" [(items)]="ddlLeaveTypeList"
                        (selected)="ddlSelection($event, 'LeaveType')" placeholder="All Leave Types"
                        #ddlFilterLeaveType (opened)="closeDropdownList(ddlFilterLeaveType)"
                        (removed)="removeFilterDropdownValue($event, 'LeaveType')">
                      </ng-select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="depart">Employee</label>
                      <ng-select [allowClear]="true" [(active)]="ActiveEmployees" [(items)]="ddlEmployeeList"
                        (selected)="ddlSelection($event, 'Employee')" placeholder="All Employees"
                        #ddlFilterEmployee (opened)="closeDropdownList(ddlFilterEmployee)"
                        (removed)="removeFilterDropdownValue($event, 'Employee')">
                      </ng-select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="depart">Department</label>
                      <ng-select [allowClear]="true" [(active)]="ActiveDepartments" [(items)]="ddlDepartmentList"
                        (selected)="ddlSelection($event, 'Department')" placeholder="All Department"
                        #ddlFilterDepartments (opened)="closeDropdownList(ddlFilterDepartments)"
                        (removed)="removeFilterDropdownValue($event, 'Department')">
                      </ng-select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="location">Location</label>
                      <ng-select [allowClear]="true" [(active)]="ActiveLocations" [(items)]="ddlLocationList"
                        (selected)="ddlSelection($event, 'Location')" placeholder="All Location"
                        #ddlFilterLocations (opened)="closeDropdownList(ddlFilterLocations)"
                        (removed)="removeFilterDropdownValue($event, 'Location')">
                      </ng-select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="desigation">Designation</label>
                      <ng-select [allowClear]="true" [(active)]="ActiveDesignations" [(items)]="ddlDesignationList"
                        (selected)="ddlSelection($event, 'Designation')" placeholder="All Desigation"
                        #ddlFilterDesigations (opened)="closeDropdownList(ddlFilterDesigations)"
                        (removed)="removeFilterDropdownValue($event, 'Desigation')">
                      </ng-select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="depart">User Status</label>
                      <select class="form-control" name="filterUserStatus" [(ngModel)]="filterUserStatus">
                        <option value=null>All</option>
                        <option value="0">Active</option>
                        <option value="1">Inactive</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">               
                  <div class="col-sm-12">
                    <button type="button" (click)="processFilters()" class="btn btn-primary btn-brand">Search</button>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <button type="button" (click)="resetFilters()" class="btn btn-default">Reset</button>
                  </div>                    
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</div>