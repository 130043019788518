import { Component, OnInit, ViewChildren, QueryList, ViewChild } from '@angular/core';
import { CommonService } from "src/app/common.service";
import { AuthService } from "src/app/services/auth.service";
import { DepartmentService } from "src/app/+layout/department/department.service";
import { DesignationService } from "src/app/+layout/designation/designation.service";
import { LocationService } from "src/app/+layout/location/location.service";
import { SelectComponent } from "ng2-select/ng2-select";
import { LeaveService } from "../../leave.service";
//import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import { Permission } from 'src/app/permission-keyword';
import { ExpenseHttpService } from 'src/app/services/expense/expense-http.service';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { ToasterService } from 'src/app/+layout/expense/toaster/toaster.service';
import { JobbandService } from "src/app/+layout/jobband/jobband.service";


@Component({
  selector: 'app-approval-setting',
  templateUrl: './approval-setting.component.html',
  styleUrls: ['./approval-setting.component.css']
})
export class ApprovalSettingComponent implements OnInit {

  //public Editor = CKEditorComponent;

  public showMessage: string;
  public showSuccess: Boolean;
  IsDelete: boolean;
  deleteId: any;

  userLoader: boolean;
  currentUserId: any;

  viewMode: Boolean;
  editMode: Boolean;

  filterServiceId: any;
  filterSearchText: any;

  approvalsList: any[];
  myapprovalsListLength: any;
  InActiveLength: any;
  ActiveLength: any;
  myApprovalsPage = 1;
  myApprovalsPageSize = 10;
  myApprovalsListLength = 0;

  approvalSettingsData: any;

  isSetCriteriaForm: Boolean = false;
  isSetApprovalForm: Boolean = false;
  isConfigureEmailTemplate: Boolean = false;

  emailTemplatesList: any[];

  // For User Dropdownlist
  serviceList: any[];
  ddlServiceList: any[];

  RoleList: any[];
  ddlRoleList: any[];

  DepartmentList: any[];
  ddlDepartmentList: any[];

  DesignationList: any[];
  ddlDesignationList: any[];

  LocationList: any[];
  ddlLocationList: any[];

  EmployeeList: any[];
  ddlEmployeeList: any[];

  projectList: any[];
  ddlProjectList: any[];

  JobBandList: any[];
  ddlJobBandList: any[];

  LeaveTypeList: any[];
  ddlLeaveTypeList: any[];

  EmployeeTypeList: any[];
  CriteriaListfield = {id:"id", text:"txt" }
  CriteriaList = [
    {id:"==", txt: "Equal"},
    {id:"===", txt: "Strictly Equal"},
    {id:"!=", txt: "Not Equal"},
    {id:"!==", txt: "Strictly Not Equal"},
    {id:">", txt: "Greater-than"},
    {id:"<", txt: "Less-than"},
    {id:">=", txt: "Greater-than Or Equal"},
    {id:"<=", txt: "Less-than Equal"},
  ]

  EqualTofield1 = {id:"name", text:"name" }
  EqualTo1  = []

  EqualTofield2 = {id:"designationName", text:"designationName" }
  EqualTo2  = []

  EqualTofield3 = {id:"departmentName", text:"departmentName" } 
  EqualTo3  = []

  EqualTofield4 = {id:"locationName", text:"locationName" }
  EqualTo4  = []

  EqualTofield5
  EqualTo5 = []

  EqualTofield6 
  EqualTo6 = []

  EqualTofield7
  EqualTo7 = []

  EqualTofield11 = {id:"leaveTypeName", text:"leaveTypeName" }
  EqualTo11  = []

  EqualTofield12 
  EqualTo12 = []

  EqualTofield13 
  EqualTo13 = []

  EqualTofield41 = {id:"projectName", text:"projectName" }
  EqualTo41 = []

  EqualTofield42 = {id:"id", text:"txt" }
  EqualTo42 = []

  EqualTofield44
  EqualTo44 = []

  EqualTofield32 = {id:"projectName", text:"projectName" }
  EqualTo32 = []

  EqualTofield33
  EqualTo33 = []

  EqualTofield21
  EqualTo21 = []

  EqualTofield23 = {id:"name", text:"name" }
  EqualTo23 = []

  EqualTofield24 = {id:"designationName", text:"designationName" }
  EqualTo24 = []

  EqualTofield25 = {id:"departmentName", text:"departmentName" }
  EqualTo25 = []

  EqualTofield26 = {id:"locationName", text:"locationName" }
  EqualTo26 = []

  EqualTofield27 = {id:"bandName", text:"bandName" }
  EqualTo27 = []

  EqualTofield28 = {id:"id", text:"txt" }
  EqualTo28 = []

  EqualTofield29 = {id:"id", text:"txt" }
  EqualTo29 = []

  EqualTofield30 = {id:"firstname", text:"firstname" }
  EqualTo30 = []

  // this.EqualTofield2 = undefined
  // this.EqualTo2 = undefined
  // this.EqualTofield3 = undefined
  // this.EqualTo3 = undefined
  // this.EqualTofield4 = undefined
  // this.EqualTo4= undefined
  // this.EqualTofield5= undefined
  // this.EqualTo5= undefined
  // this.EqualTofield6 = undefined
  // this.EqualTo6= undefined
  // this.EqualTofield7= undefined
  // this.EqualTo7= undefined
  // this.EqualTofield11 = undefined
  // this.EqualTo11= undefined
  // this.EqualTofield12 = undefined
  // this.EqualTo12= undefined
  // this.EqualTofield13 = undefined
  // this.EqualTo13= undefined
  // this.EqualTofield41= undefined
  // this.EqualTo41= undefined
  // this.EqualTofield42 = undefined
  // this.EqualTo4= undefined
  // this.EqualTofield44= undefined
  // this.EqualTo44= undefined
  // this.EqualTofield32 = undefined
  // this.EqualTo32= undefined
  // this.EqualTofield33= undefined
  // this.EqualTo33= undefined
  // this.EqualTofield21= undefined
  // this.EqualTo21= undefined
  // this.EqualTofield23= undefined
  // this.EqualTo23= undefined
  // this.EqualTofield24= undefined
  // this.EqualTo24= undefined
  // this.EqualTofield25 = undefined
  // this.EqualTo25= undefined
  // this.EqualTofield26= undefined
  // this.EqualTo26= undefined
  // this.EqualTofield27= undefined
  // this.EqualTo27= undefined
  // this.EqualTofield28= undefined
  // this.EqualTo28= undefined
  // this.EqualTofield29 = undefined
  // this.EqualTo29= undefined
  // this.EqualTofield30= undefined
  // this.EqualTo30= undefined

  ddlEmployeeTypeList: any[];

  GenderList: any[];
  ddlGenderList: any[];

  ReportingToList: any[];
  ddlReportingToList: any[];

  GroupList: any[];
  ddlGroupList: any[];

  criteriaTypeActiveDropdownsList: Boolean[];
  ApprovalsStepList: any[];
  ProductHeader:any[]=[{name:"Reporting",id:1},{name:"Department Head of Login User",id:2},{name:"Approver based on Role",id:3},{name:"Department Head",id:4},{name:"Department Members",id:5},
  {name:"Project Managers",id:6}]

  // Email
  emailToDDLActive: Boolean = false;
  emailCCDDLActive: Boolean = false;
  emailBCCDDLActive: Boolean = false;

  mailTemplateType: Number = 2;
  emailTemplateId: any;

  emailTemplateBody: '';
  emailTemplatesPredefinedTextList: any[];
  predefinedTextSelected: any;
  isEditModeEmailTemplate: Boolean = false;
  //editror config
  editorConfig = {
    editable: true,
    spellcheck: false,
    height: '10rem',
    minHeight: '5rem',
    placeholder: 'Email content',
    translate: 'no',
    "toolbar": [
      ["bold", "italic", "underline", "strikeThrough", "superscript", "subscript"]
    ]
  };

  // To
  To_SystemOptions: any[];
  To_Users: any[];
  To_Roles: any[];
  To_Departments: any[];
  To_Groups: any[];
  To_Locations: any[];

  To_SystemOptions_Selected: any[];
  To_Users_Selected: any[];
  To_Roles_Selected: any[];
  To_Departments_Selected: any[];
  To_Groups_Selected: any[];
  To_Locations_Selected: any[];

  // CC
  CC_SystemOptions: any[];
  CC_Users: any[];
  CC_Roles: any[];
  CC_Departments: any[];
  CC_Groups: any[];
  CC_Locations: any[];

  CC_SystemOptions_Selected: any[];
  CC_Users_Selected: any[];
  CC_Roles_Selected: any[];
  CC_Departments_Selected: any[];
  CC_Groups_Selected: any[];
  CC_Locations_Selected: any[];

  // BCC
  BCC_SystemOptions: any[];
  BCC_Users: any[];
  BCC_Roles: any[];
  BCC_Departments: any[];
  BCC_Groups: any[];
  BCC_Locations: any[];

  BCC_SystemOptions_Selected: any[];
  BCC_Users_Selected: any[];
  BCC_Roles_Selected: any[];
  BCC_Departments_Selected: any[];
  BCC_Groups_Selected: any[];
  BCC_Locations_Selected: any[];

  @ViewChildren(SelectComponent) selectElements: QueryList<any>;

  //Permissions
  AddPermission: boolean;
  EditPermission: boolean;
  DeletePermission: boolean;
  ViewPermission: boolean;
  StatusPermission: boolean;

  saveMessage: any;
  CmpCode: any;
  public EmployeeFields: Object = { text: 'text', value: 'id' };
  isEditable: boolean;

  constructor(private _leaveService: LeaveService,
    private _commonSer: CommonService,
    private _authSer: AuthService,
    private _depratmentSer: DepartmentService,
    private _desigation: DesignationService,
    private _locationSer: LocationService,
    private _jobbandSer: JobbandService,
    private toaster: ToasterService,
    private Http: ExpenseHttpService) { this.CmpCode = this._authSer.getcompanyCode(); }

  ngOnInit() {// Angular Lifecycle hook
    this.filterServiceId = null;
    this.filterSearchText = null;
    this.approvalsList = [];
    this.emailTemplatesList = [];
    this.CmpCode = this._authSer.getcompanyCode();
    this._authSer.currentUser.subscribe((res) => {
      this.currentUserId = res.userId;
      this.AddPermission = true; //res.permission.includes(Permission.ApprovalSettingsAdd);
      this.EditPermission = true; // res.permission.includes(Permission.ApprovalSettingsEdit);
      this.DeletePermission = true; // res.permission.includes(Permission.ApprovalSettingsDelete);
      this.ViewPermission = true; // res.permission.includes(Permission.ApprovalSettingsView);
      this.StatusPermission = true; // res.permission.includes(Permission.ApprovalSettingsStatus);
    });

    this.filterSettings = { type: "Menu" };
    this.filter = { type: "CheckBox" };
    this.selectionSettings = { persistSelection: true, type: "Multiple", checkboxOnly: true };
    this.bindServiceList();
    this.getApprovalsList();
    this.getEmailTemplatesList();
    this.bindAllDDLData();
    this.setPredefinedTexts();
    this.GetEmployees();
  }

  public filter: Object;
  public filterSettings: Object;
  public selectionSettings: Object;
  public height: string = '240px';
  public fields: Object = { text: 'text', value: 'value' };
  public item: number[] = [1, 2, 3, 4, 5];
  public pageSettings = { pageCount: 3 };
  DateFormat: any;

  @ViewChild('overviewgrid') public Grid: GridComponent;
  load() {// Created By G Initialize grid properties
    const rowHeight: number = this.Grid.getRowHeight();  // height of the each row
    const gridHeight: any = this.Grid.height;  // grid height
    const pageSize: number = this.Grid.pageSettings.pageSize;   // initial page size
    const pageResize: any = (gridHeight - (pageSize * rowHeight)) / rowHeight; // new page size is obtained here
    this.Grid.pageSettings.pageSize = pageSize + Math.round(pageResize);
  }

  numberOnly(event): boolean {// Created By G Numbers validation
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  currentstatus:any='total';
  active:any='total';
  getvalue(date){// Created By G Get All Records
    this.active=date;
    this.currentstatus=date;
    this.getApprovalsList();
   // this.getRoadblocks();

  }
  filtervalue(){// Created By G Filter Records
    if(this.currentstatus=='total')
    {

    }
    if(this.currentstatus=='active')
    {
      this.approvalsList = this.approvalsList.filter(user => user.isActive == true);
    }
    if(this.currentstatus=='inactive')
    {
      this.approvalsList = this.approvalsList.filter(user => user.isActive == false);
      
    }
    
  }

  getServiceText(_serviceId) {// Created By G On Select Form name
    switch (_serviceId) {
      case 1:
        return "Leaves";
      case 2:
        return "Compensatory Off";
      case 3:
        return "Expense";
        case 4:
        return "Advance Payment"
      default:
        return "";
    }
  }

  // 
  getApprovalsList() {// Created By G Get Approval Settings List
    this.userLoader = true;
    let parms = {
      ServiceId: this.filterServiceId != null && this.filterServiceId != "null" ? this.filterServiceId : 0,
      SearchText: this.filterSearchText,
      CmpCode: this.CmpCode
    };
    this._leaveService.getAllApprovalSettings(parms).subscribe((res) => {
      this.approvalsList = res["data"];
      this.myapprovalsListLength = res["data"].length;
      this.InActiveLength = this.approvalsList.filter(user => user.isActive == false).length;
      this.ActiveLength = this.approvalsList.filter(user => user.isActive == true).length;
      this.userLoader = false;
      this.myApprovalsListLength = this.approvalsList.length;
      this.filtervalue();
    }, error => {
      this.userLoader = false;
      console.log(error);
    });
  }

  onChangeFilterServiceId(e) {// Created By G Set Value
    this.filterServiceId = e.itemData.id
    //this.filterServiceId = e.srcElement.value;
    this.getApprovalsList();
  }

  //
  ChangeStatus(data) {// Created By G On Change Status
    data.isActive = !data.isActive;
    this._leaveService.updateApprovalSettingsStatus(data.approvalId, data.isActive, this.CmpCode).subscribe((res:any) => {
      this.toaster.show("success",  res.data);
      debugger;
      this.getApprovalsList();
    
    });
  }

  // Delete
  deleteApprovalSettings(approvalSetings) {// Created By G Initialize confirm modal
    try {
      if (approvalSetings.approvalId != '') {
        this.IsDelete = true;
        this.showSuccess = false;

        this.deleteId = approvalSetings.approvalId;
        this.openModel('confirmDeleteModel', 'Are you sure, you want to delete this record?');
      }
    } catch (ex) {

    }
  }
  openModel(ModalId, Message) {// Created By G Open Modals from code
    this._commonSer.OpenModalHandler(ModalId);
    this.showMessage = Message;
  }
  ConfirmDelete(approvalId) {// Created By G On Confirm Delete approval settings
    let parms = { CmpCode: this.CmpCode };
    try {
      this.userLoader = true;
      this._leaveService.deleteApprovalSettings(parms, approvalId).subscribe((res) => {
        this.userLoader = false;
        if (res['success'] == true) {
          this.showSuccess = true;
          this.showMessage = res['data'];
          this._commonSer.CloseModalHandler('confirmDeleteModel');
          this.toaster.show("success", this.showMessage);
         // this._commonSer.OpenModalHandler('userMessageModel');
          this.getApprovalsList();
        } else {
          this.showSuccess = false;
          this.showMessage = res['message'];
          this._commonSer.CloseModalHandler('confirmDeleteModel');
          this.toaster.show("warning", this.showMessage);

        //  this._commonSer.OpenModalHandler('userMessageModel');
        }
      });
    } catch (ex) {
      console.log(ex);
      this.userLoader = false;
      this.getApprovalsList();
    }
  }

  // New Approval Settings
  resetForm() {// Created By G Reset Form
    let vm = this;
    vm.approvalSettingsData = {
      approvalId: 0,
      serviceId: 1,
      settingName: null,
      settingDescription: null,
      approvalType: 1,
      enableFollowUp: false,
      followUpType: null,
      followUpAfterDays: null,
      followUpTime: null,
      isActive: true,
      criteriaList: [],
      approvalList: [],
      approvalEmailTemplate: null,
      CmpCode: this.CmpCode
    };

    vm.isSetCriteriaForm = false;
    vm.isSetApprovalForm = false;
    vm.isConfigureEmailTemplate = false;
    vm.isEditModeEmailTemplate = false;

    vm.viewMode = false;
    vm.editMode = false;
    vm.criteriaTypeActiveDropdownsList = [];
    vm.ApprovalsStepList = [];
    vm.saveMessage = "";

    vm.predefinedTextSelected = null;
  }

  onChangeServiceId(_serviceId) {// Created By G On change service
    let vm = this;
    vm.isSetCriteriaForm = false;
    vm.approvalSettingsData.criteriaList = [];
    vm.criteriaTypeActiveDropdownsList = [];
  }

  // Criteria
  setCriteriaPanel() { // Created By G On change criteria
    let vm = this;
    vm.isSetCriteriaForm = true;
    vm.criteriaTypeActiveDropdownsList = [];
    vm.addCriteria(0);
  }
  removeCriteriaPanel() { // Created By G On remove criteria
    let vm = this;
    vm.isSetCriteriaForm = false;
    vm.criteriaTypeActiveDropdownsList = [];
    vm.approvalSettingsData.criteriaList = [];
  }
  arr = []

  setVal(i,e){// Created By G Set Value
    debugger
    this.approvalSettingsData.criteriaList[i].value =  e
  }
  addCriteria(_position) {// Created By G On add criteria
    let vm = this;
    let _criteriaList = vm.approvalSettingsData.criteriaList;
     
    debugger
    let newCriteria = {
      condition: "",
      criteriaType: null,
      criteriaTypeText: null,
      whereCondition: "",
      criteriaSubType: null,
      criteriaSubTypeText: null,
      value: "",
      ddlList: [],
    };
   
    if (_criteriaList.length == 0 && _position == 0) {
      _criteriaList.push(newCriteria);
    } else {
      newCriteria.condition = "AND";
      _criteriaList.splice(_position, 0, newCriteria);
    }
    vm.approvalSettingsData.criteriaList = _criteriaList;
    vm.criteriaTypeActiveDropdownsList.push(false);
  }
  removeCriteria(_index) {// Created By G On remove criteria
    let vm = this;
    vm.approvalSettingsData.criteriaList.splice(_index, 1);
    vm.criteriaTypeActiveDropdownsList.splice(_index, 1);
  }
  openCriteriaTypeDDL(_index) {// Created By G Set criteria dropdown
    let vm = this;
    for (let i = 0; i < vm.criteriaTypeActiveDropdownsList.length; i++) {
      vm.criteriaTypeActiveDropdownsList[i] = false;
    }
    vm.criteriaTypeActiveDropdownsList[_index] = (vm.criteriaTypeActiveDropdownsList[_index] == true) ? false : true;
    if (vm.approvalSettingsData.criteriaList[_index].criteriaType == null) {
      if (vm.approvalSettingsData.serviceId == 1) {
        vm.approvalSettingsData.criteriaList[_index].criteriaType = 2;
      } else if (vm.approvalSettingsData.serviceId == 1) {
        vm.approvalSettingsData.criteriaList[_index].criteriaType = 4;
      }
    }
  }
  closeAllCriteriaTypeDDL() {// Created By G
    let vm = this;
    for (let i = 0; i < vm.criteriaTypeActiveDropdownsList.length; i++) {
      vm.criteriaTypeActiveDropdownsList[i] = false;
    }
  }
  isAmount: boolean = false;
  setSelectedCriteriaType(_value, _index) {// Created By G On Select criteria type
    let vm = this;

    vm.closeAllCriteriaTypeDDL();
    vm.approvalSettingsData.criteriaList[_index].value = null;
    vm.approvalSettingsData.criteriaList[_index].ddlList = [];

    vm.approvalSettingsData.criteriaList[_index].criteriaType = vm.getCriteriaType(_value);
    vm.approvalSettingsData.criteriaList[_index].criteriaTypeText = vm.getCriteriaTypeText(vm.approvalSettingsData.criteriaList[_index].criteriaType);

    vm.approvalSettingsData.criteriaList[_index].criteriaSubType = _value;
    vm.approvalSettingsData.criteriaList[_index].criteriaSubTypeText = vm.getCriteriaSubTypeText(_value);
    // Amount
    vm.approvalSettingsData.criteriaList[_index].amount = vm.getCriteriaSubTypeText(_value);

    if (_value == 1 || _value == 23) { // Role
      vm.approvalSettingsData.criteriaList[_index].ddlList = vm.ddlRoleList;
    } else if (_value == 2 || _value == 24) { // Designation
      vm.approvalSettingsData.criteriaList[_index].ddlList = vm.ddlDesignationList;
    } else if (_value == 3 || _value == 25) { // Department
      vm.approvalSettingsData.criteriaList[_index].ddlList = vm.ddlDepartmentList;
    } else if (_value == 4 || _value == 26) { // Location
      vm.approvalSettingsData.criteriaList[_index].ddlList = vm.ddlLocationList;
    } else if (_value == 11 || _value == 21 || _value == 30 || _value == 41) { // Employee ID
      vm.approvalSettingsData.criteriaList[_index].ddlList = vm.ddlEmployeeList;
    } else if (_value == 12) { // LeaveType
      vm.approvalSettingsData.criteriaList[_index].ddlList = vm.ddlLeaveTypeList;
    } else if (_value == 27) { // Job Band
      vm.approvalSettingsData.criteriaList[_index].ddlList = vm.ddlJobBandList;
    } else if (_value == 28) { // Employee Type
      vm.approvalSettingsData.criteriaList[_index].ddlList = vm.ddlEmployeeTypeList;
    } else if (_value == 29) { // Gender
      vm.approvalSettingsData.criteriaList[_index].ddlList = vm.ddlGenderList;
    } else if (_value == 32) { // project
      vm.approvalSettingsData.criteriaList[_index].ddlList = vm.ddlProjectList;
    }

    //Experience
    //Date of Joining

    this.criteriaChange(_value)
  }
  getCriteriaType(_value) {// Created By G
    if (_value > 0 && _value <= 10) { //System Options
      return 1;
    } else if (_value >= 11 && _value <= 20) { //Leaves
      return 2;
    } else if (_value >= 21 && _value <= 40) { //Employee
      return 3;
    } else if (_value >= 41 && _value <= 50) { //Compensatory Off
      return 4;
    }
  }
  getCriteriaTypeText(_value) {// Created By G
    switch (_value) {
      case 1:
        return "System Options";
      case 2:
        return "Leaves";
      case 3:
        return "Employee";
      case 4:
        return "Compensatory Off";
    }
  }
  getCriteriaSubTypeText(_value) {// Created By G
    switch (_value) {
      case 1:
        return "Role";
      case 2:
        return "Designation";
      case 3:
        return "Department";
      case 4:
        return "Location";
      case 11:
        return "Leave Name";
      case 12:
        return "Leave Type";
      case 13:
        return "Units";
      case 21:
        return "Employee ID";
      case 22:
        return "Experience";
      case 23:
        return "Role";
      case 24:
        return "Designation";
      case 25:
        return "Department";
      case 26:
        return "Location";
      case 27:
        return "Job Band";
      case 28:
        return "Employee Type";
      case 29:
        return "Gender";
      case 30:
        return "Reporting To";
      case 31:
        return "Date of Joining";
      case 32:
        return "Project Name";
      case 33:
        return "Amount";
      case 41:
        return "Project Name";
      case 42:
        return "Units";
      case 44:
        return "Amount";
    }

  }
  
  onChangeCriteriaWhereCondition(_value, _index) {// Created By G
    let vm = this;
    debugger
    vm.approvalSettingsData.criteriaList[_index].value = "";
  }


  
  setApprovalPanel() {// Created By G Approvals
    let vm = this;
    vm.isSetApprovalForm = true;
    vm.addApproval(0);
  }
  removeApprovalPanel() {// Created By G Remove approval
    let vm = this;
    vm.isSetApprovalForm = false;
    vm.approvalSettingsData.approvalList = [];
    vm.approvalSettingsData.approvalType = 1;
  }
  addApproval(_position) {// Created By G Add approval
    let vm = this;
    let _approvalList = vm.approvalSettingsData.approvalList;
    let newApproval = {
      approvalType: null,
      value: "",
      ddlValueList: [],
    };
    _approvalList.splice(_position, 0, newApproval);
    vm.approvalSettingsData.approvalList = _approvalList;
  }
  PageNumber:any=1
  NoOfRecords:any=1000000
  SortType = "asc";
  ColumnName = "name";
  roles:any=[]
  myRequestsListLength:any=[]
  designationDetails:any=[]
  departmentDetails:any=[]
  locationDetails :any=[]
  jobBandList:any=[]
  allLeaveTypeList:any=[]
  totalMyRequestList:any=[]
  checkDdl:boolean=true
  criteriaChange(val){// Created By G On Update Criteria
    debugger

    switch (val) {
    
      case 1:
     this.checkDdl=true
      //  return "Role";
      ///fullquery = "where name like '%" + (this.SearchbyName).trim() + "%'" + "and Cmpcode=" + this.CmpCode;
      let filterModel = {
        "PageNo": this.PageNumber,
        "NoOfRecords": this.NoOfRecords,
        "SortType": this.SortType,
        "FieldName": this.ColumnName,
        "Condition": '',
        "CmpCode": this.CmpCode,
      };
      this._commonSer.CRG_PostAPI("Admin/GetAllRoles", JSON.stringify(filterModel)).subscribe(result => {
        let response = JSON.parse(result["_body"]);
        this.roles = response.data.table;
        this.EqualTo1=this.roles.filter(state => state.inActive === false)
        
      ///  this.createPagination(response.data.table1[0].filterCount, this.PageNumber, this.NoOfRecords);
        // this.showLoader = false;
      });



       break
      case 2:
     this.checkDdl=true

        this.userLoader = true;
        let parms = {
          PageNo: 1,
          NoOfRecords: 10000,
          SortType: "desc", //'asc',
          FieldName: "CreatedDate", //'EmployeeId',
          Condition: null, 
          CmpCode:this.CmpCode,
        };
    
        this._desigation.getDesignationList(parms).subscribe((res) => {
          debugger;
          this.userLoader = false;
          debugger;
          this.designationDetails = res.data == null ? [] : res.data.table;
          this.EqualTo2=this.designationDetails.filter(state => state.inActive === false)
       //   this.LocationDetails.filter(state => state.inActive === true)
       
    
        });
        // return "Designation";

        

        break
      case 3:
     this.checkDdl=true

          this.userLoader = true;
          let parmsfor = {
            PageNo: 1,
            NoOfRecords: 10000,
            SortType: "desc",
            FieldName: "CreatedDate",
            Condition:  null ,
            CmpCode:this.CmpCode,
          };
          this._depratmentSer.getDepartmentList(parms).subscribe((res) => {
            this.userLoader = false;
            this.departmentDetails = res.data == null ? [] : res.data.table;
            this.EqualTo3=this.departmentDetails.filter(state => state.inActive === false)
      
      
          });
        // return "Department";
        break
      case 4:
     this.checkDdl=true

        let parmsLoca = {
          PageNo: 1,
          NoOfRecords: 10000,
          SortType: "desc", //'asc',
          FieldName: "CreatedDate", //'EmployeeId',
          Condition:null,
          CmpCode:this.CmpCode,
        };
        this._locationSer.getlocationlist(parmsLoca).subscribe((res) => {
    
          this.locationDetails = res.data.table;
          this.EqualTo4= this.locationDetails.filter(state => state.inActive === false)
          this.EqualTofield4 
        });
        // return "Location";
        break
      case 11:
     this.checkDdl=true

     let parmsleavefor = {
       PageNo: 1,
       NoOfRecords: 100000,
       FilterStatus:  null,
       FilterEmployees: null,
       FilterDepartments: null,
       FilterLocations: null,
       FilterDesignations: null,
       FilterRoles: null,
       FilterUnit:  null,
       CmpCode:this.CmpCode,
     };
     this._leaveService.getAllLeaveTypeData(parmsleavefor).subscribe((res) => {
       this.allLeaveTypeList = res["data"].table;
       this.totalMyRequestList=this.allLeaveTypeList
       this.EqualTo11=this.allLeaveTypeList.filter(state => state.isActive === true)
       this.EqualTofield11 
       this.userLoader = false;
     });
     break
        // return "Leave Name";
      case 12:
     this.checkDdl=true


        this.EqualTofield12 = {id:"id", text:"txt" }
        this.EqualTo12 = [
          {id:"Paid", txt: "Paid"},
          {id:"UnPaid", txt: "UnPaid"}
         
        ]
        break
        // return "Leave Type";
        break
      case 13:
     this.checkDdl=true

        // return "Units";
        this.EqualTofield13 = {id:"id", text:"txt" }
        this.EqualTo13 = [
          {id:"Day(s)", txt: "Day(s)"},
          {id:"Hour(s)", txt: "Hour(s)"}
         
        ]
        break
      case 21:
     this.checkDdl=false
        // return "Employee ID";
        break
    
      case 23:
     this.checkDdl=true

        // return "Role";
        let filterModelfor = {
          "PageNo": this.PageNumber,
          "NoOfRecords": this.NoOfRecords,
          "SortType": this.SortType,
          "FieldName": this.ColumnName,
          "Condition": '',
          "CmpCode": this.CmpCode,
        };
        this._commonSer.CRG_PostAPI("Admin/GetAllRoles", JSON.stringify(filterModelfor)).subscribe(result => {
          let response = JSON.parse(result["_body"]);
          this.roles = response.data.table;
          this.EqualTo23=this.roles.filter(state => state.inActive === false)
          this.EqualTofield23 
          this.myRequestsListLength = this.roles.length;
        ///  this.createPagination(response.data.table1[0].filterCount, this.PageNumber, this.NoOfRecords);
          // this.showLoader = false;
        });
   
        break
      case 24:
     this.checkDdl=true

        this.userLoader = true;
        let parmsf = {
          PageNo: 1,
          NoOfRecords: 10000,
          SortType: "desc",
          FieldName: "CreatedDate",
          Condition: null, 
          CmpCode:this.CmpCode,
        };
        
        this._desigation.getDesignationList(parmsf).subscribe((res) => {
          debugger;
          this.userLoader = false;
          debugger;
          this.designationDetails = res.data == null ? [] : res.data.table;
          this.EqualTo24=this.designationDetails.filter(state => state.inActive === false)
          this.EqualTofield24 
       
    
        });
        // return "Designation";
        break
      case 25:
     this.checkDdl=true

        this.userLoader = true;
        let parmsdes = {
          PageNo: 1,
          NoOfRecords: 10000,
          SortType: "desc",
          FieldName: "CreatedDate",
          Condition:  null ,
          CmpCode:this.CmpCode,
        };
        this._depratmentSer.getDepartmentList(parmsdes).subscribe((res) => {
          this.userLoader = false;
          this.departmentDetails = res.data == null ? [] : res.data.table;
          this.EqualTo25=this.departmentDetails.filter(state => state.inActive === false)


          this.EqualTofield25 
    
        });
        // return "Department";
        break
      case 26:
     this.checkDdl=true

        let parmsLoc = {
          PageNo: 1,
          NoOfRecords: 10000,
          SortType: "desc", //'asc',
          FieldName: "CreatedDate", //'EmployeeId',
          Condition:null,
          CmpCode:this.CmpCode,
        };
        this._locationSer.getlocationlist(parmsLoc).subscribe((res) => {
    
          this.locationDetails = res.data.table;
       
          this.EqualTo26=this.locationDetails.filter(state => state.inActive === false)
          this.EqualTofield26
        });
        // return "Location";
        break
      case 27:
     this.checkDdl=true

        this.userLoader = true;
    let parmsjob = {
      PageNo: 1,
      NoOfRecords: 10000,
      SortType: "desc", //'asc',
      FieldName: "CreatedDate",
      CmpCode:this.CmpCode,
    };
    this._jobbandSer.getJobBandList(parmsjob).subscribe((res) => {
      this.userLoader = false;
      this.jobBandList = res.data.table;
      this.EqualTo27=this.jobBandList.filter(state => state.inActive === false)
      this.EqualTofield27 
    });
        // return "Job Band";
        break
      case 28:
     this.checkDdl=true

        this.EqualTofield28
        this.EqualTo28 = [
          {id:"Employee", txt: "Employee"},
          {id:"Vendor", txt: "Vendor"},
          {id:"Contact Person", txt: "Contact Person"},

          {id:"Sales Person", txt: "Sales Person"}
        ]
        // return "Employee Type";
        break
      case 29:
     this.checkDdl=true

        // return "Gender";
        this.EqualTofield29
        this.EqualTo29 = [
          {id:"Male", txt: "Male"},
          {id:"Female", txt: "Female"}
         
        ]
        break
      case 30:
     this.checkDdl=true
     this.Http.getReportingTo(this.CmpCode).then((res) => {
       debugger
      this.userLoader = false;
      let  reportingList = res.data.table;
      this.EqualTo30=reportingList
      this.EqualTofield30
    });

        // return "Reporting To";
        break
      case 31:
     this.checkDdl=true

        // return "Date of Joining";
        break
      case 32:
     this.checkDdl=true


        let paramprojectfor={
          CmpCode: "1",
          Condition: null,
          FieldName: "ProjectName",
          NoOfRecords: "1000",
          PageNo: 1,
          SortType: "asc",
          userId: 10098,
          userType: 1
        }
        
        
        this._commonSer.CRG_PostAPI("Project/GetProjects", paramprojectfor).subscribe( (res: any) =>{
          if (res.status === 200) {
            const response = JSON.parse(res._body);
                      if (response.data != null) {
                        debugger;
                        let ProjectList = response.data.table;
                        this.EqualTo32=ProjectList.filter(state => state.inActive === false)
                        this.EqualTofield32 
          }
          }
        }, err => {
         // this.Loader = false;
         this.userLoader = false;
        
        });

        // return "Project Name";


        break
        case 33:
          this.checkDdl=false
     
             // return "Date of Joining";
             break

      case 41:
     

let paramproject={
  CmpCode: "1",
  Condition: null,
  FieldName: "ProjectName",
  NoOfRecords: "1000",
  PageNo: 1,
  SortType: "asc",
  userId: 10098,
  userType: 1
}


this._commonSer.CRG_PostAPI("Project/GetProjects", paramproject).subscribe( (res: any) => {
  if (res.status === 200) {
    const response = JSON.parse(res._body);
              if (response.data != null) {
                debugger;
                let ProjectList = response.data.table;
                this.EqualTo41=ProjectList.filter(state => state.inActive === false)
                        this.EqualTofield41 
  }
  }
}, err => {
 // this.Loader = false;
 this.userLoader = false;

});



        // return "Project Name";
        break
      case 42:
     this.checkDdl=true

        // return "Units";
        this.EqualTofield42 
        this.EqualTo42 = [
          {id:"Day(s)", txt: "Day(s)"},
          {id:"Day(s)", txt: "Hour(s)"}
         
        ]
        break
    
    }
  }
  removeApproval(_index) {// Created By G Remove approval
    let vm = this;
    vm.approvalSettingsData.approvalList.splice(_index, 1);
  }
  onChangeApprovalType(_value, _index) {// Created By G On change approval type
    debugger
    let vm = this;
   // vm.approvalSettingsData.approvalList[_index].value = null;
    vm.approvalSettingsData.approvalList[_index].ddlValueList = [];

    if (_value == 1) { // Reporting To
      vm.approvalSettingsData.approvalList[_index].ddlValueList = vm.ddlReportingToList;
    } else if (_value == 3) { // Approver based on Role
      vm.approvalSettingsData.approvalList[_index].ddlValueList = vm.ddlRoleList;
    } else if (_value == 4) { // Department Head
      vm.approvalSettingsData.approvalList[_index].ddlValueList = vm.ddlDepartmentList;
    } else if (_value == 5) { // Department Members
      vm.approvalSettingsData.approvalList[_index].ddlValueList = vm.ddlDepartmentList;
    } else if (_value == 7) { // Group
      vm.approvalSettingsData.approvalList[_index].ddlValueList = vm.ddlGroupList;
    } else if (_value == 8) { // Employee
      vm.approvalSettingsData.approvalList[_index].ddlValueList = vm.ddlEmployeeListData;
    } 

    vm.setApprovalsStepList();
  }
  onChangeApprovalTypeValue(_value, _index) {// Created By G Set Value
    debugger
    let vm = this;
    vm.approvalSettingsData.approvalList[_index].value = _value;
    vm.setApprovalsStepList();
  }
  setApprovalsStepList() {// Created By G After changing approval type
    debugger
    let vm = this;
    vm.ApprovalsStepList = [];

    for (let i = 0; i < vm.approvalSettingsData.approvalList.length; i++) {
      let _approvalType = vm.approvalSettingsData.approvalList[i].approvalType;
      let _value = vm.approvalSettingsData.approvalList[i].value;

      if (_approvalType == 1) { // Reporting To
        if (_value != null && _value != "") {
          vm.ApprovalsStepList.push(_value + " Level(s) of Reporting To");
        }
      } else if (_approvalType == 2) { // Department Head of Login User
        vm.ApprovalsStepList.push("Department Head of Login User");
      } else if (_approvalType == 3) { // Approver based on Role
        const currentItem = vm.ddlRoleList.find(x => x.id == _value);
        if (currentItem !== undefined && currentItem != null) {
          vm.ApprovalsStepList.push(currentItem.text);
        }
      } else if (_approvalType == 4) { // Department Head
        const currentItem = vm.ddlDepartmentList.find(x => x.id == _value);
        if (currentItem !== undefined && currentItem != null) {
          vm.ApprovalsStepList.push(currentItem.text + " Department Head");
        }
      } else if (_approvalType == 5) { // Department Members
        const currentItem = vm.ddlDepartmentList.find(x => x.id == _value);
        if (currentItem !== undefined && currentItem != null) {
          vm.ApprovalsStepList.push(currentItem.text + " Department Members");
        }
      } 
      else if (_approvalType == 6) { // Department Members
          vm.ApprovalsStepList.push( " Project Manager");
      }
      
      else if (_approvalType == 7) { // Group
        const currentItem = vm.ddlGroupList.find(x => x.id == _value);
        if (currentItem !== undefined && currentItem != null) {
          vm.ApprovalsStepList.push("Group - " + currentItem.text);
        }
      }
      else if (_approvalType == 8) { // Employee
        const currentItem = vm.ddlEmployeeListData.find(x => x.id == _value);
        if (currentItem !== undefined && currentItem != null) {
          vm.ApprovalsStepList.push(currentItem.text + " Employee");
        }
      } 
      else if (_approvalType == 9) { // Client
          vm.ApprovalsStepList.push( " Client");
        
      } 
      else if (_approvalType == 10) { // Project Admins
        vm.ApprovalsStepList.push( " Project Admins");
        
      } 
      else if (_approvalType == 11) { // Project Managers
          vm.ApprovalsStepList.push( " Reporting Managers");
       
      } 
      else if (_approvalType == 12) { // Project Managers
        vm.ApprovalsStepList.push( " Timesheet Approval");
     
    } 
    }
  }


  // Followup
  onChangeFollowup(e) {// Created By G Followup
    console.log(e);
    let vm = this;
    if (e.target.checked) {
      vm.approvalSettingsData.followUpType = 1;
      vm.approvalSettingsData.followUpAfterDays = 1;
      vm.approvalSettingsData.followUpTime = "12:00 PM";
    } else {
      vm.approvalSettingsData.followUpType = null;
      vm.approvalSettingsData.followUpAfterDays = null;
      vm.approvalSettingsData.followUpTime = null;
    }
  }

  removeEmailPanel(){// Created By G Set value
  this.isConfigureEmailTemplate=false;
  }
  
  setEmailTemplatePanel() {// Created By G Email Template
    let vm = this;
    let _approvalEmailTemplate = {
      from: 1,
      to: null,
      cc: null,
      bcc: null,
      subject: "New Leave Request",
      emailBody: null
    };
    vm.approvalSettingsData.approvalEmailTemplate = _approvalEmailTemplate;
    this.setEmailToDDLs();
    this.setEmailCCDDLs();
    this.setEmailBCCDDLs();
    vm.getDefaultTemplate();
    vm.emailToDDLActive = false;
    vm.emailCCDDLActive = false;
    vm.emailBCCDDLActive = false;

    vm.mailTemplateType = 2;
    vm.emailTemplateId = 1;

    vm.isConfigureEmailTemplate = true;
    vm.isEditModeEmailTemplate = false;

    vm.predefinedTextSelected = null;
  }
  getEmailTemplatesList() {// Created By G Get Email Template List
    let parms = {CmpCode:this.CmpCode};
    this._leaveService.getEmailTemplatesForSelect(parms).subscribe((res) => {
      this.emailTemplatesList = res["data"];
      this.getDefaultTemplate();
    }, error => {
      console.log(error);
    });
  }
  getDefaultTemplate() {// Created By G Get Default template details
    if (this.emailTemplatesList.length > 0 && this.approvalSettingsData != null && this.approvalSettingsData != undefined) {
      this.emailTemplateId = this.emailTemplatesList[0].emailTemplateId;
      if (this.approvalSettingsData.approvalEmailTemplate != null &&
        this.approvalSettingsData.approvalEmailTemplate != undefined) {
        if (this.approvalSettingsData.approvalEmailTemplate.emailBody == null ||
          this.approvalSettingsData.approvalEmailTemplate.emailBody == "") {
          this.approvalSettingsData.approvalEmailTemplate.emailBody = this.emailTemplatesList[0].emailBody;
          this.emailTemplateBody = this.approvalSettingsData.approvalEmailTemplate.emailBody;
        }
      }
    }
  }
  setEmailToDDLs() {// Created By G Set Email to dropdown
    let vm = this;
    vm.To_SystemOptions = [];
    vm.To_Users = [];
    vm.To_Roles = [];
    vm.To_Departments = [];
    vm.To_Groups = [];
    vm.To_Locations = [];

    vm.To_SystemOptions_Selected = [];
    vm.To_Users_Selected = [];
    vm.To_Roles_Selected = [];
    vm.To_Departments_Selected = [];
    vm.To_Groups_Selected = [];
    vm.To_Locations_Selected = [];

    // System Options
    vm.To_SystemOptions.push({ id: 1, text: 'Department Head of Login User', isChecked: false });
    vm.To_SystemOptions.push({ id: 2, text: 'Team mailid of the person performing the action', isChecked: false });
    vm.To_SystemOptions.push({ id: 3, text: 'Reporting Manager of Employee Lookup', isChecked: false });
    vm.To_SystemOptions.push({ id: 4, text: 'Current Approver', isChecked: false });
    vm.To_SystemOptions.push({ id: 5, text: 'Reporting Manager of login user', isChecked: false });

    // Users
    for (let i = 0; i < this.ddlEmployeeList.length; i++) {
      let _item = this.ddlEmployeeList[i];
      vm.To_Users.push({ id: _item.id, text: _item.text, isChecked: false });
    }

    // Roles
    for (let i = 0; i < this.ddlRoleList.length; i++) {
      let _item = this.ddlRoleList[i];
      vm.To_Roles.push({ id: _item.id, text: _item.text, isChecked: false });
    }

    // Departments
    for (let i = 0; i < this.ddlDepartmentList.length; i++) {
      let _item = this.ddlDepartmentList[i];
      vm.To_Departments.push({ id: _item.id, text: _item.text, isChecked: false });
    }

    // Groups
    for (let i = 0; i < this.ddlGroupList.length; i++) {
      let _item = this.ddlGroupList[i];
      vm.To_Groups.push({ id: _item.id, text: _item.text, isChecked: false });
    }

    // Locations
    for (let i = 0; i < this.ddlLocationList.length; i++) {
      let _item = this.ddlLocationList[i];
      vm.To_Locations.push({ id: _item.id, text: _item.text, isChecked: false });
    }


    // ------------ CC
    vm.CC_SystemOptions = [];
    vm.CC_Users = [];
    vm.CC_Roles = [];
    vm.CC_Departments = [];
    vm.CC_Groups = [];
    vm.CC_Locations = [];

    vm.CC_SystemOptions_Selected = [];
    vm.CC_Users_Selected = [];
    vm.CC_Roles_Selected = [];
    vm.CC_Departments_Selected = [];
    vm.CC_Groups_Selected = [];
    vm.CC_Locations_Selected = [];

    // ------------ BCC
    vm.BCC_SystemOptions = [];
    vm.BCC_Users = [];
    vm.BCC_Roles = [];
    vm.BCC_Departments = [];
    vm.BCC_Groups = [];
    vm.BCC_Locations = [];

    vm.BCC_SystemOptions_Selected = [];
    vm.BCC_Users_Selected = [];
    vm.BCC_Roles_Selected = [];
    vm.BCC_Departments_Selected = [];
    vm.BCC_Groups_Selected = [];
    vm.BCC_Locations_Selected = [];

    vm.setDefaultToDDL();

    for (let i = 0; i < vm.CC_SystemOptions.length; i++) {
      this.CC_SystemOptions[i].isChecked = false;
    }
    for (let i = 0; i < vm.BCC_SystemOptions.length; i++) {
      this.BCC_SystemOptions[i].isChecked = false;
    }
  }
  setDefaultToDDL() {// Created By G set default values
    for (let i = 0; i < this.To_SystemOptions.length; i++) {
      if (this.To_SystemOptions[i].id == 4) {
        this.To_SystemOptions[i].isChecked = true;
      }
    }
    this.onChangeEmailTo(null, 1);
  }
  clearToDDL() {// Created By G Clear To dropdown
    for (let i = 0; i < this.To_SystemOptions.length; i++) {
      this.To_SystemOptions[i].isChecked = false;
    }
    this.onChangeEmailTo(null, 1);
  }
  setEmailCCDDLs() {// Created By G Get CC Dropown list
    let vm = this;
    vm.CC_SystemOptions = [];
    vm.CC_Users = [];
    vm.CC_Roles = [];
    vm.CC_Departments = [];
    vm.CC_Groups = [];
    vm.CC_Locations = [];

    vm.CC_SystemOptions_Selected = [];
    vm.CC_Users_Selected = [];
    vm.CC_Roles_Selected = [];
    vm.CC_Departments_Selected = [];
    vm.CC_Groups_Selected = [];
    vm.CC_Locations_Selected = [];

    // System Options
    vm.CC_SystemOptions.push({ id: 1, text: 'Department Head of Login User', isChecked: false });
    vm.CC_SystemOptions.push({ id: 2, text: 'Team mailid of the person performing the action', isChecked: false });
    vm.CC_SystemOptions.push({ id: 3, text: 'Reporting Manager of Employee Lookup', isChecked: false });
    vm.CC_SystemOptions.push({ id: 4, text: 'Current Approver', isChecked: false });
    vm.CC_SystemOptions.push({ id: 5, text: 'Reporting Manager of login user', isChecked: false });

    // Users
    for (let i = 0; i < this.ddlEmployeeList.length; i++) {
      let _item = this.ddlEmployeeList[i];
      vm.CC_Users.push({ id: _item.id, text: _item.text, isChecked: false });
    }

    // Roles
    for (let i = 0; i < this.ddlRoleList.length; i++) {
      let _item = this.ddlRoleList[i];
      vm.CC_Roles.push({ id: _item.id, text: _item.text, isChecked: false });
    }

    // Departments
    for (let i = 0; i < this.ddlDepartmentList.length; i++) {
      let _item = this.ddlDepartmentList[i];
      vm.CC_Departments.push({ id: _item.id, text: _item.text, isChecked: false });
    }

    // Groups
    for (let i = 0; i < this.ddlGroupList.length; i++) {
      let _item = this.ddlGroupList[i];
      vm.CC_Groups.push({ id: _item.id, text: _item.text, isChecked: false });
    }

    // Locations
    for (let i = 0; i < this.ddlLocationList.length; i++) {
      let _item = this.ddlLocationList[i];
      vm.CC_Locations.push({ id: _item.id, text: _item.text, isChecked: false });
    }
  }
  setEmailBCCDDLs() {// Created By G Set BCC Dropdown 
    let vm = this;
    vm.BCC_SystemOptions = [];
    vm.BCC_Users = [];
    vm.BCC_Roles = [];
    vm.BCC_Departments = [];
    vm.BCC_Groups = [];
    vm.BCC_Locations = [];

    vm.BCC_SystemOptions_Selected = [];
    vm.BCC_Users_Selected = [];
    vm.BCC_Roles_Selected = [];
    vm.BCC_Departments_Selected = [];
    vm.BCC_Groups_Selected = [];
    vm.BCC_Locations_Selected = [];

    // System Options
    vm.BCC_SystemOptions.push({ id: 1, text: 'Department Head of Login User', isChecked: false });
    vm.BCC_SystemOptions.push({ id: 2, text: 'Team mailid of the person performing the action', isChecked: false });
    vm.BCC_SystemOptions.push({ id: 3, text: 'Reporting Manager of Employee Lookup', isChecked: false });
    vm.BCC_SystemOptions.push({ id: 4, text: 'Current Approver', isChecked: false });
    vm.BCC_SystemOptions.push({ id: 5, text: 'Reporting Manager of login user', isChecked: false });

    // Users
    for (let i = 0; i < this.ddlEmployeeList.length; i++) {
      let _item = this.ddlEmployeeList[i];
      vm.BCC_Users.push({ id: _item.id, text: _item.text, isChecked: false });
    }

    // Roles
    for (let i = 0; i < this.ddlRoleList.length; i++) {
      let _item = this.ddlRoleList[i];
      vm.BCC_Roles.push({ id: _item.id, text: _item.text, isChecked: false });
    }

    // Departments
    for (let i = 0; i < this.ddlDepartmentList.length; i++) {
      let _item = this.ddlDepartmentList[i];
      vm.BCC_Departments.push({ id: _item.id, text: _item.text, isChecked: false });
    }

    // Groups
    for (let i = 0; i < this.ddlGroupList.length; i++) {
      let _item = this.ddlGroupList[i];
      vm.BCC_Groups.push({ id: _item.id, text: _item.text, isChecked: false });
    }

    // Locations
    for (let i = 0; i < this.ddlLocationList.length; i++) {
      let _item = this.ddlLocationList[i];
      vm.BCC_Locations.push({ id: _item.id, text: _item.text, isChecked: false });
    }
  }

  // To
  openEmailToDDL() {// Created By G Set values
    let vm = this;
    vm.emailToDDLActive = true;
    vm.emailCCDDLActive = false;
    vm.emailBCCDDLActive = false;
  }
  closeEmailToDDL() {// Created By G Set values
    let vm = this;
    vm.emailToDDLActive = false;
    vm.emailCCDDLActive = false;
    vm.emailBCCDDLActive = false;
  }
  onChangeEmailTo(e, _type) {// Created By G
    if (_type == 1) { // System Options
      this.To_SystemOptions_Selected = [];
      for (let i = 0; i < this.To_SystemOptions.length; i++) {
        if (this.To_SystemOptions[i].isChecked) {
          this.To_SystemOptions_Selected.push(this.To_SystemOptions[i]);
        }
      }
    } else if (_type == 2) { // Users
      this.To_Users_Selected = [];
      for (let i = 0; i < this.To_Users.length; i++) {
        if (this.To_Users[i].isChecked) {
          this.To_Users_Selected.push(this.To_Users[i]);
        }
      }
    } else if (_type == 3) { // Roles
      this.To_Roles_Selected = [];
      for (let i = 0; i < this.To_Roles.length; i++) {
        if (this.To_Roles[i].isChecked) {
          this.To_Roles_Selected.push(this.To_Roles[i]);
        }
      }
    } else if (_type == 4) { // Departments
      this.To_Departments_Selected = [];
      for (let i = 0; i < this.To_Departments.length; i++) {
        if (this.To_Departments[i].isChecked) {
          this.To_Departments_Selected.push(this.To_Departments[i]);
        }
      }
    } else if (_type == 5) { // Groups
      this.To_Groups_Selected = [];
      for (let i = 0; i < this.To_Groups.length; i++) {
        if (this.To_Groups[i].isChecked) {
          this.To_Groups_Selected.push(this.To_Groups[i]);
        }
      }
    } else if (_type == 6) { // Locations
      this.To_Locations_Selected = [];
      for (let i = 0; i < this.To_Locations.length; i++) {
        if (this.To_Locations[i].isChecked) {
          this.To_Locations_Selected.push(this.To_Locations[i]);
        }
      }
    }
  }
  removeEmailTo(_type, item) {// Created By G On Remove email to
    if (this.viewMode) {
      return;
    }
    if (_type == 1) {
      this.To_SystemOptions_Selected = [];
      for (let i = 0; i < this.To_SystemOptions.length; i++) {
        if (item.id == this.To_SystemOptions[i].id) {
          this.To_SystemOptions[i].isChecked = false;
        }

        if (this.To_SystemOptions[i].isChecked) {
          this.To_SystemOptions_Selected.push(this.To_SystemOptions[i]);
        }
      }
    } else if (_type == 2) { // Users
      this.To_Users_Selected = [];
      for (let i = 0; i < this.To_Users.length; i++) {
        if (item.id == this.To_Users[i].id) {
          this.To_Users[i].isChecked = false;
        }

        if (this.To_Users[i].isChecked) {
          this.To_Users_Selected.push(this.To_Users[i]);
        }
      }
    } else if (_type == 3) { // Roles
      this.To_Roles_Selected = [];
      for (let i = 0; i < this.To_Roles.length; i++) {
        if (item.id == this.To_Roles[i].id) {
          this.To_Roles[i].isChecked = false;
        }

        if (this.To_Roles[i].isChecked) {
          this.To_Roles_Selected.push(this.To_Roles[i]);
        }
      }
    } else if (_type == 4) { // Departments
      this.To_Departments_Selected = [];
      for (let i = 0; i < this.To_Departments.length; i++) {
        if (item.id == this.To_Departments[i].id) {
          this.To_Departments[i].isChecked = false;
        }

        if (this.To_Departments[i].isChecked) {
          this.To_Departments_Selected.push(this.To_Departments[i]);
        }
      }
    } else if (_type == 5) { // Groups
      this.To_Groups_Selected = [];
      for (let i = 0; i < this.To_Groups.length; i++) {
        if (item.id == this.To_Groups[i].id) {
          this.To_Groups[i].isChecked = false;
        }

        if (this.To_Groups[i].isChecked) {
          this.To_Groups_Selected.push(this.To_Groups[i]);
        }
      }
    } else if (_type == 6) { // Groups
      this.To_Locations_Selected = [];
      for (let i = 0; i < this.To_Locations.length; i++) {
        if (item.id == this.To_Locations[i].id) {
          this.To_Locations[i].isChecked = false;
        }

        if (this.To_Locations[i].isChecked) {
          this.To_Locations_Selected.push(this.To_Locations[i]);
        }
      }
    }
  }

  // CC--
  openEmailCCDDL() {// Created By G Set values
    let vm = this;
    vm.emailToDDLActive = false;
    vm.emailCCDDLActive = true;
    vm.emailBCCDDLActive = false;
  }
  closeEmailCCDDL() {// Created By G Set values
    let vm = this;
    vm.emailToDDLActive = false;
    vm.emailCCDDLActive = false;
    vm.emailBCCDDLActive = false;
  }
  onChangeEmailCC(e, _type) {// Created By G
    if (_type == 1) { // System Options
      this.CC_SystemOptions_Selected = [];
      for (let i = 0; i < this.CC_SystemOptions.length; i++) {
        if (this.CC_SystemOptions[i].isChecked) {
          this.CC_SystemOptions_Selected.push(this.CC_SystemOptions[i]);
        }
      }
    } else if (_type == 2) { // Users
      this.CC_Users_Selected = [];
      for (let i = 0; i < this.CC_Users.length; i++) {
        if (this.CC_Users[i].isChecked) {
          this.CC_Users_Selected.push(this.CC_Users[i]);
        }
      }
    } else if (_type == 3) { // Roles
      this.CC_Roles_Selected = [];
      for (let i = 0; i < this.CC_Roles.length; i++) {
        if (this.CC_Roles[i].isChecked) {
          this.CC_Roles_Selected.push(this.CC_Roles[i]);
        }
      }
    } else if (_type == 4) { // Departments
      this.CC_Departments_Selected = [];
      for (let i = 0; i < this.CC_Departments.length; i++) {
        if (this.CC_Departments[i].isChecked) {
          this.CC_Departments_Selected.push(this.CC_Departments[i]);
        }
      }
    } else if (_type == 5) { // Groups
      this.CC_Groups_Selected = [];
      for (let i = 0; i < this.CC_Groups.length; i++) {
        if (this.CC_Groups[i].isChecked) {
          this.CC_Groups_Selected.push(this.CC_Groups[i]);
        }
      }
    } else if (_type == 6) { // Locations
      this.CC_Locations_Selected = [];
      for (let i = 0; i < this.CC_Locations.length; i++) {
        if (this.CC_Locations[i].isChecked) {
          this.CC_Locations_Selected.push(this.CC_Locations[i]);
        }
      }
    }
  }
  removeEmailCC(_type, item) {// Created By G
    if (this.viewMode) {
      return;
    }
    if (_type == 1) {
      this.CC_SystemOptions_Selected = [];
      for (let i = 0; i < this.CC_SystemOptions.length; i++) {
        if (item.id == this.CC_SystemOptions[i].id) {
          this.CC_SystemOptions[i].isChecked = false;
        }

        if (this.CC_SystemOptions[i].isChecked) {
          this.CC_SystemOptions_Selected.push(this.CC_SystemOptions[i]);
        }
      }
    } else if (_type == 2) { // Users
      this.CC_Users_Selected = [];
      for (let i = 0; i < this.CC_Users.length; i++) {
        if (item.id == this.CC_Users[i].id) {
          this.CC_Users[i].isChecked = false;
        }

        if (this.CC_Users[i].isChecked) {
          this.CC_Users_Selected.push(this.CC_Users[i]);
        }
      }
    } else if (_type == 3) { // Roles
      this.CC_Roles_Selected = [];
      for (let i = 0; i < this.CC_Roles.length; i++) {
        if (item.id == this.CC_Roles[i].id) {
          this.CC_Roles[i].isChecked = false;
        }

        if (this.CC_Roles[i].isChecked) {
          this.CC_Roles_Selected.push(this.CC_Roles[i]);
        }
      }
    } else if (_type == 4) { // Departments
      this.CC_Departments_Selected = [];
      for (let i = 0; i < this.CC_Departments.length; i++) {
        if (item.id == this.CC_Departments[i].id) {
          this.CC_Departments[i].isChecked = false;
        }

        if (this.CC_Departments[i].isChecked) {
          this.CC_Departments_Selected.push(this.CC_Departments[i]);
        }
      }
    } else if (_type == 5) { // Groups
      this.CC_Groups_Selected = [];
      for (let i = 0; i < this.CC_Groups.length; i++) {
        if (item.id == this.CC_Groups[i].id) {
          this.CC_Groups[i].isChecked = false;
        }

        if (this.CC_Groups[i].isChecked) {
          this.CC_Groups_Selected.push(this.CC_Groups[i]);
        }
      }
    } else if (_type == 6) { // Groups
      this.CC_Locations_Selected = [];
      for (let i = 0; i < this.CC_Locations.length; i++) {
        if (item.id == this.CC_Locations[i].id) {
          this.CC_Locations[i].isChecked = false;
        }

        if (this.CC_Locations[i].isChecked) {
          this.CC_Locations_Selected.push(this.CC_Locations[i]);
        }
      }
    }
  }

  // BCC
  openEmailBCCDDL() {// Created By G Set Values
    let vm = this;
    vm.emailToDDLActive = false;
    vm.emailCCDDLActive = false;
    vm.emailBCCDDLActive = true;
  }
  closeEmailBCCDDL() {// Created By G Set Values
    let vm = this;
    vm.emailToDDLActive = false;
    vm.emailCCDDLActive = false;
    vm.emailBCCDDLActive = false;
  }
  onChangeEmailBCC(e, _type) {// Created By G
    if (_type == 1) { // System Options
      this.BCC_SystemOptions_Selected = [];
      for (let i = 0; i < this.BCC_SystemOptions.length; i++) {
        if (this.BCC_SystemOptions[i].isChecked) {
          this.BCC_SystemOptions_Selected.push(this.BCC_SystemOptions[i]);
        }
      }
    } else if (_type == 2) { // Users
      this.BCC_Users_Selected = [];
      for (let i = 0; i < this.BCC_Users.length; i++) {
        if (this.BCC_Users[i].isChecked) {
          this.BCC_Users_Selected.push(this.BCC_Users[i]);
        }
      }
    } else if (_type == 3) { // Roles
      this.BCC_Roles_Selected = [];
      for (let i = 0; i < this.BCC_Roles.length; i++) {
        if (this.BCC_Roles[i].isChecked) {
          this.BCC_Roles_Selected.push(this.BCC_Roles[i]);
        }
      }
    } else if (_type == 4) { // Departments
      this.BCC_Departments_Selected = [];
      for (let i = 0; i < this.BCC_Departments.length; i++) {
        if (this.BCC_Departments[i].isChecked) {
          this.BCC_Departments_Selected.push(this.BCC_Departments[i]);
        }
      }
    } else if (_type == 5) { // Groups
      this.BCC_Groups_Selected = [];
      for (let i = 0; i < this.BCC_Groups.length; i++) {
        if (this.BCC_Groups[i].isChecked) {
          this.BCC_Groups_Selected.push(this.BCC_Groups[i]);
        }
      }
    } else if (_type == 6) { // Locations
      this.BCC_Locations_Selected = [];
      for (let i = 0; i < this.BCC_Locations.length; i++) {
        if (this.BCC_Locations[i].isChecked) {
          this.BCC_Locations_Selected.push(this.BCC_Locations[i]);
        }
      }
    }
  }
  removeEmailBCC(_type, item) {// Created By G On Remove email BCC
    if (this.viewMode) {
      return;
    }
    if (_type == 1) {
      this.BCC_SystemOptions_Selected = [];
      for (let i = 0; i < this.BCC_SystemOptions.length; i++) {
        if (item.id == this.BCC_SystemOptions[i].id) {
          this.BCC_SystemOptions[i].isChecked = false;
        }

        if (this.BCC_SystemOptions[i].isChecked) {
          this.BCC_SystemOptions_Selected.push(this.BCC_SystemOptions[i]);
        }
      }
    } else if (_type == 2) { // Users
      this.BCC_Users_Selected = [];
      for (let i = 0; i < this.BCC_Users.length; i++) {
        if (item.id == this.BCC_Users[i].id) {
          this.BCC_Users[i].isChecked = false;
        }

        if (this.BCC_Users[i].isChecked) {
          this.BCC_Users_Selected.push(this.BCC_Users[i]);
        }
      }
    } else if (_type == 3) { // Roles
      this.BCC_Roles_Selected = [];
      for (let i = 0; i < this.BCC_Roles.length; i++) {
        if (item.id == this.BCC_Roles[i].id) {
          this.BCC_Roles[i].isChecked = false;
        }

        if (this.BCC_Roles[i].isChecked) {
          this.BCC_Roles_Selected.push(this.BCC_Roles[i]);
        }
      }
    } else if (_type == 4) { // Departments
      this.BCC_Departments_Selected = [];
      for (let i = 0; i < this.BCC_Departments.length; i++) {
        if (item.id == this.BCC_Departments[i].id) {
          this.BCC_Departments[i].isChecked = false;
        }

        if (this.BCC_Departments[i].isChecked) {
          this.BCC_Departments_Selected.push(this.BCC_Departments[i]);
        }
      }
    } else if (_type == 5) { // Groups
      this.BCC_Groups_Selected = [];
      for (let i = 0; i < this.BCC_Groups.length; i++) {
        if (item.id == this.BCC_Groups[i].id) {
          this.BCC_Groups[i].isChecked = false;
        }

        if (this.BCC_Groups[i].isChecked) {
          this.BCC_Groups_Selected.push(this.BCC_Groups[i]);
        }
      }
    } else if (_type == 6) { // Groups
      this.BCC_Locations_Selected = [];
      for (let i = 0; i < this.BCC_Locations.length; i++) {
        if (item.id == this.BCC_Locations[i].id) {
          this.BCC_Locations[i].isChecked = false;
        }

        if (this.BCC_Locations[i].isChecked) {
          this.BCC_Locations_Selected.push(this.BCC_Locations[i]);
        }
      }
    }
  }


  onChangeEmailTemplate() {// Created By G On change email template
    if (this.emailTemplatesList.length > 0) {
      const item = this.emailTemplatesList.find(x => x.emailTemplateId == this.emailTemplateId);
      if (item !== undefined && item != null) {
        this.approvalSettingsData.approvalEmailTemplate.subject = item.subject;
        this.approvalSettingsData.approvalEmailTemplate.emailBody = item.emailBody;
        this.emailTemplateBody = item.emailBody;
      }
    }
  }
  editEmailTemplate() {// Created By G Set Value
    let vm = this;
    vm.isEditModeEmailTemplate = true;
  }
  setPredefinedTexts() {// Created By G Set Predefined texts
    let vm = this;
    vm.predefinedTextSelected = null;
    vm.emailTemplatesPredefinedTextList = [];
    vm.emailTemplatesPredefinedTextList.push({ value: "${EmployeeName}", text: 'Employee Name' });
    vm.emailTemplatesPredefinedTextList.push({ value: "${EmployeeId}", text: 'Employee Id' });
    vm.emailTemplatesPredefinedTextList.push({ value: "${ApproverName}", text: 'Approver Name' });
    vm.emailTemplatesPredefinedTextList.push({ value: "${Duration}", text: 'Duration' });
  }
  insertPredefinedParameter() {// Created By G Set values
    let _value = this.predefinedTextSelected;
    if (_value != null && _value != "") {
      let htmlText = " " + _value + " ";
      let textEditor = document.getElementById('txtEditor');
      if (textEditor != null) {
        textEditor.focus();
        if (!document.execCommand("InsertInputText", false, '')) {
          document.execCommand("InsertHTML", false, htmlText);
        }
      }
    }
  }

  ddlSelection(value: any, placeHolder: any) {// Created By G
    try {
      // switch (placeHolder) {
      //   case "Department":
      //     this.ActiveDepartments = [];
      //     this.ActiveDepartments.push(value);
      //     this.filterDepartmentId = value.id;
      //     break;
      //   case "Desigation":
      //     this.ActiveDesignations = [];
      //     this.ActiveDesignations.push(value);
      //     this.filterDesignationId = value.id;
      //     break;
      //   case "Location":
      //     this.ActiveLocations = [];
      //     this.ActiveLocations.push(value);
      //     this.filterLocationId = value.id;
      //     break;
      // }
    } catch (ex) {
      console.log(ex);
    }
  }
  removeDropdownValue(value, _type) {// Created By G
    try {
      // switch (_type) {
      //   case "Department":
      //     this.ActiveDepartments = [];
      //     this.filterDepartmentId = null;
      //     break;
      //   case "Desigation":
      //     this.ActiveDesignations = [];
      //     this.filterDesignationId = null;
      //     break;
      //   case "Location":
      //     this.ActiveLocations = [];
      //     this.filterLocationId = null;
      //     break;
      // }
    } catch (ex) {
      console.log(ex);
    }
  }

  bindAllDDLData() {// Created By G Bind all dropdown lists
    let vm = this;
    vm.getRoleList();
    vm.getDesigationList();
    vm.getDepartmentList();
    vm.getLocationList();
    vm.getEmployeeList();
    vm.getLeaveTypeList();
    vm.getJobBandList();
    vm.getEmployeeTypeList();
    vm.getGenderList();

    vm.getReportingToList();
    vm.getGroupList();

    vm.getEmailTemplatesList();
    vm.GetProject();
  }

  bindServiceList() {// Created By G Set Service list values
    this.serviceList = [];
    this.serviceList.push({ text: "Leaves", value: "1" });
    this.serviceList.push({ text: "Compensatory Request", value: "2" });
    this.serviceList.push({ text: "Expense", value: "3" });
    this.serviceList.push({ text: "Advance Payment", value: "4" });

    this.ddlServiceList = this._commonSer.SetDDlDropdown(this.serviceList, "value", "text");
  }

  getRoleList() {// Created By G Get Roles list
    this._leaveService.getRolesForSelect(this.CmpCode).subscribe((res) => {
      this.RoleList = res["data"].table;
      this.ddlRoleList = this._commonSer.SetDDlDropdown(res["data"].table, "roleId", "name");
    }, error => {
      this.userLoader = false;
      console.log(error);
    });
  }

  GetProject() {// Created By G Get all projects
    this.Http.GetProject(0, this.CmpCode).then((res) => {
      this.projectList = res.data.table;
      this.ddlProjectList = this._commonSer.SetDDlDropdown(res["data"].table, "projectId", "name");
    });
  }

  getDepartmentList() {// Created By G Get all departments
    let parms = {
      Condition: "",
      FieldName: "CreatedDate",
      NoOfRecords: 10000,
      PageNo: 1,
      SortType: "desc",
      CmpCode: this.CmpCode
    };
    this._depratmentSer.getDepartmentList(parms).subscribe((res) => {
      this.DepartmentList = res["data"].table;
      this.ddlDepartmentList = this._commonSer.SetDDlDropdown(res["data"].table, "departmentId", "departmentName");
    }, error => {
      this.userLoader = false;
      console.log(error);
    });
  }

  getDesigationList() {// Created By G Get all designations
    let parms = {
      Condition: "",
      FieldName: "CreatedDate",
      NoOfRecords: 10000,
      PageNo: 1,
      SortType: "desc",
      CmpCode: this.CmpCode
    };
    this._desigation.getDesignationList(parms).subscribe((res) => {
      this.DesignationList = res["data"].table;
      this.ddlDesignationList = this._commonSer.SetDDlDropdown(res["data"].table, "designationId", "designationName");
    }, error => {
      this.userLoader = false;
      console.log(error);
    });
  }

  getLocationList() {// Created By G Get all locations
    let parms = {
      Condition: "",
      FieldName: "CreatedDate",
      NoOfRecords: 10000,
      PageNo: 1,
      SortType: "desc",
      CmpCode: this.CmpCode
    };
    this._locationSer.getlocationlist(parms).subscribe((res) => {
      this.LocationList = res["data"].table;
      this.ddlLocationList = this._commonSer.SetDDlDropdown(res["data"].table, "locationId", "locationName");
    }, error => {
      this.userLoader = false;
      console.log(error);
    });
  }

  getJobBandList() {// Created By G Get Job Band List
    this._leaveService.getJobBandsForSelect().subscribe((res) => {
      this.JobBandList = res["data"].table;
      this.ddlJobBandList = this._commonSer.SetDDlDropdown(res["data"].table, "jobBandId", "bandName");
    }, error => {
      this.userLoader = false;
      console.log(error);
    });
  }

  getEmployeeList() {// Created By G Get all Employees
    this._leaveService.getEmployeesListForSelect(this.currentUserId, this.CmpCode).subscribe((res) => {
      this.EmployeeList = res["data"].table;
      this.ddlEmployeeList = this._commonSer.SetDDlDropdown(res["data"].table, "employeeId", "employeeCodeName");
    }, error => {
      this.userLoader = false;
      console.log(error);
    });
  }

  EmployeeListData: any = []
  ddlEmployeeListData: any = []
  GetEmployees() {// Created By G Get all employees list
    this.Http.GetEmployees(0, this.CmpCode).then((res) => {
      this.EmployeeListData = res.data.table;
      this.ddlEmployeeListData = this._commonSer.SetDDlDropdown(this.EmployeeListData, "employeeId", "name");
    })
  }

  getLeaveTypeList() {// Created By G get leave type lists
    this._leaveService.getAllLeaveTypeForSelect(this.CmpCode).subscribe((res) => {
      this.LeaveTypeList = res["data"].table;
      this.ddlLeaveTypeList = this._commonSer.SetDDlDropdown(res["data"].table, "leaveTypeId", "leaveTypeName");
    }, error => {
      this.userLoader = false;
      console.log(error);
    });
  }

  getEmployeeTypeList() {// Created By G Set Employee type list
    this.EmployeeTypeList = [{ 'id': 1, 'text': 'Employee' }, { 'id': 2, 'text': 'Vendor' }, { 'id': 3, 'text': 'Contact Person' }, { 'id': 4, 'text': 'Sales Person' }];
    this.ddlEmployeeTypeList = this._commonSer.SetDDlDropdown(this.EmployeeTypeList, "id", "text");
  }

  getGenderList() {// Created By G Set Genders list
    this.GenderList = [{ 'id': 0, 'text': 'Male' }, { 'id': 1, 'text': 'Female' }];
    this.ddlGenderList = this._commonSer.SetDDlDropdown(this.GenderList, "id", "text");
  }

  getReportingToList() {// Created By G Set Reporting to list
    this.ReportingToList = [{ 'id': 1, 'text': 'Single Level' }, { 'id': 2, 'text': 'Two Levels' }, { 'id': 3, 'text': 'Three Levels' }, { 'id': 4, 'text': 'Four Levels' }, { 'id': 5, 'text': 'Five Levels' },
    { 'id': 6, 'text': 'Six Levels' }, { 'id': 7, 'text': 'Seven Levels' }, { 'id': 8, 'text': 'Eight Levels' }, { 'id': 9, 'text': 'Nine Levels' }, { 'id': 10, 'text': 'Ten Levels' }];
    this.ddlReportingToList = this._commonSer.SetDDlDropdown(this.ReportingToList, "id", "text");
  }

  getGroupList() {// Created By G Get groups list
    let parms = {
      Condition: "",
      FieldName: "CreatedDate",
      NoOfRecords: 10000,
      PageNo: 1,
      SortType: "desc",
      CmpCode: this.CmpCode
    };
    this._leaveService.getGroupListForSelect(parms).subscribe((res) => {
      this.GroupList = res["data"].table;
      this.ddlGroupList = this._commonSer.SetDDlDropdown(res["data"].table, "groupId", "groupName");
    }, error => {
      this.userLoader = false;
      console.log(error);
    });
  }

 
  saveApprovalSettings() {// Created By G Save approval Settings
    debugger
    let vm = this;
    vm.saveMessage = "";
    if (vm.isConfigureEmailTemplate == true) {
      // To
      if (vm.To_SystemOptions_Selected.length == 0 &&
        vm.To_Users_Selected.length == 0 &&
        vm.To_Roles_Selected.length == 0 &&
        vm.To_Departments_Selected.length == 0 &&
        vm.To_Groups_Selected.length == 0 &&
        vm.To_Locations_Selected.length == 0) {
        vm.saveMessage = "Please select the Email To in message section";
        return;
      } else {
        let toData = {
          SystemOptions: vm.To_SystemOptions_Selected,
          Users: vm.To_Users_Selected,
          Roles: vm.To_Roles_Selected,
          Departments: vm.To_Departments_Selected,
          Groups: vm.To_Groups_Selected,
          Locations: vm.To_Locations_Selected,
        };
        vm.approvalSettingsData.approvalEmailTemplate.to = JSON.stringify(toData);
      }

      // CC
      let ccData = {
        SystemOptions: vm.CC_SystemOptions_Selected,
        Users: vm.CC_Users_Selected,
        Roles: vm.CC_Roles_Selected,
        Departments: vm.CC_Departments_Selected,
        Groups: vm.CC_Groups_Selected,
        Locations: vm.CC_Locations_Selected,
      };
      vm.approvalSettingsData.approvalEmailTemplate.cC = JSON.stringify(ccData);

      // BCC
      let bccData = {
        SystemOptions: vm.BCC_SystemOptions_Selected,
        Users: vm.BCC_Users_Selected,
        Roles: vm.BCC_Roles_Selected,
        Departments: vm.BCC_Departments_Selected,
        Groups: vm.BCC_Groups_Selected,
        Locations: vm.BCC_Locations_Selected,
      };
      vm.approvalSettingsData.approvalEmailTemplate.bCC = JSON.stringify(bccData);
      vm.approvalSettingsData.CmpCode = this.CmpCode;
    }

    console.clear();
    console.log(vm.approvalSettingsData);

    if (true != true) {
      return;
    }

    vm._leaveService.saveApprovalSettings(vm.approvalSettingsData).subscribe((res) => {
      debugger
      if (res["success"] === true) {
        vm.userLoader = false;
        vm.showSuccess = true;
        vm.showMessage = res["data"];
        this.toaster.show("success", vm.showMessage);
        //vm._commonSer.OpenModalHandler("userMessageModel");
        vm._commonSer.CloseModalHandler("addApprovalpopup");

        vm.resetForm();
        vm.getApprovalsList();
      } else {
        vm.userLoader = false;
        vm.showSuccess = false;
        vm.showMessage = res["message"];
        this.toaster.show("success", vm.showMessage);
        //vm._commonSer.OpenModalHandler("userMessageModel");
      }
    }, (error) => {
      vm.userLoader = false;
      vm.showSuccess = false;
      vm.showMessage = "Uh oh, Something went wrong !";
      this.toaster.show("warning", vm.showMessage);

     // vm._commonSer.OpenModalHandler("userMessageModel");
    });
  }

  
  editApprovalSettings(item) {// Created By G Initialize Edit Approval Settings
    let vm = this;
    vm.userLoader = true;
    vm._leaveService.getApprovalSettingsDetails(item.approvalId, this.CmpCode)
      .subscribe((resApprovalSettings) => {
        vm.userLoader = false;
        vm.editMode = true;
        vm.viewMode = false;
     
          vm.setApprovalFormData(resApprovalSettings, true, false);
          setTimeout(() => {
            vm.setApprovalFormData(resApprovalSettings, true, false);
          }, 500);
      
     
      }, (error) => {
        vm.userLoader = false;
        vm.showSuccess = false;
        vm.showMessage = "Uh oh, Something went wrong !";
        vm._commonSer.OpenModalHandler("userMessageModel");
      });
  }


  viewApprovalSettings(item) {// Created By G Initialize View Approval Settings
    let vm = this;
    vm.userLoader = true;
    vm._leaveService.getApprovalSettingsDetails(item.approvalId, this.CmpCode)
      .subscribe((resApprovalSettings) => {
        vm.userLoader = false;
        vm.editMode = false;
        vm.viewMode = true;
        vm.setApprovalFormData(resApprovalSettings, false, true);
      }, (error) => {
        vm.userLoader = false;
        vm.showSuccess = false;
        vm.showMessage = "Uh oh, Something went wrong !";
        vm._commonSer.OpenModalHandler("userMessageModel");
      });
  }


  setApprovalFormData(resApprovalSettings, isEdit, isView) {// Created By G Set Form Data from DB
    this.isEditable = true
    debugger;
    let data = resApprovalSettings.data;
    this.resetForm();

    this.editMode = isEdit;
    this.viewMode = isView;

    this.approvalSettingsData.approvalId = data["approvalId"];
    this.approvalSettingsData.serviceId = data["serviceId"];
    this.approvalSettingsData.settingName = data["settingName"];
    this.approvalSettingsData.settingDescription = data["settingDescription"];
    this.approvalSettingsData.approvalType = data["approvalType"];
    this.approvalSettingsData.enableFollowUp = data["enableFollowUp"];
    this.approvalSettingsData.followUpType = data["followUpType"];
    this.approvalSettingsData.followUpAfterDays = data["followUpAfterDays"];
    this.approvalSettingsData.followUpTime = data["followUpTime"];

    // Criteria
    let criteriaList = data["criteriaList"];
    if (criteriaList.length > 0) {
      this.isSetCriteriaForm = true;
      this.criteriaTypeActiveDropdownsList = [];

      for (let i = 0; i < criteriaList.length; i++) {
        let item = criteriaList[i];
        debugger
        let newCriteria = {
          condition: item.condition,
          criteriaType: item.criteriaType,
          criteriaTypeText: "",
          whereCondition: item.whereCondition,
          criteriaSubType: item.criteriaSubType,
          criteriaSubTypeText: "",
          value: item.value,
          ddlList: [],
        };

        this.approvalSettingsData.criteriaList.push(newCriteria);
        this.criteriaTypeActiveDropdownsList.push(false);

        this.setSelectedCriteriaType(item.criteriaSubType, i);
        this.approvalSettingsData.criteriaList[i].value = item.value;
      }
    }

    // Approval
    let approvalList = data["approvalList"];
    if (approvalList.length > 0) {
      this.isSetApprovalForm = true;

      for (let i = 0; i <approvalList.length; i++) {
        let item = approvalList[i];

        let newApproval = {
          approvalType: item.approvalType,
          value: item.value,
          ddlValueList: [],
        };

        this.approvalSettingsData.approvalList.push(newApproval);
        debugger
        this.onChangeApprovalType(item.approvalType, i);
        this.approvalSettingsData.approvalList[i].value = item.value;
        
      }
    }

    // Email
    let approvalEmailTemplate = data["approvalEmailTemplate"];
    if (approvalEmailTemplate != null && approvalEmailTemplate != undefined) {
      this.setEmailToDDLs();
      this.setEmailCCDDLs();
      this.setEmailBCCDDLs();

      this.clearToDDL();

      this.isConfigureEmailTemplate = true;

      let _approvalEmailTemplate = {
        from: approvalEmailTemplate.from,
        to: null,
        cc: null,
        bcc: null,
        subject: approvalEmailTemplate.subject,
        emailBody: approvalEmailTemplate.emailBody
      };
      //this.getDefaultTemplate();
      this.emailToDDLActive = false;
      this.emailCCDDLActive = false;
      this.mailTemplateType = 2;
      this.emailTemplateId = 1;

      this.getDefaultTemplate();
      this.approvalSettingsData.approvalEmailTemplate = _approvalEmailTemplate;

      //Email To
      if (approvalEmailTemplate.to != null && approvalEmailTemplate.to != undefined) {
        let emailTo = JSON.parse(approvalEmailTemplate.to);

        // System Options
        for (let i = 0; i < emailTo.SystemOptions.length; i++) {
          let tempItem = emailTo.SystemOptions[i];
          const _index = this.To_SystemOptions.findIndex(x => x.id == tempItem.id);
          if (_index !== undefined && _index != null && _index >= 0) {
            this.To_SystemOptions[_index].isChecked = true;
          }
        }
        // Users
        for (let i = 0; i < emailTo.Users.length; i++) {
          let tempItem = emailTo.Users[i];
          const _index = this.To_Users.findIndex(x => x.id == tempItem.id);
          if (_index !== undefined && _index != null && _index >= 0) {
            this.To_Users[_index].isChecked = true;
          }
        }
        // Roles
        for (let i = 0; i < emailTo.Roles.length; i++) {
          let tempItem = emailTo.Roles[i];
          const _index = this.To_Roles.findIndex(x => x.id == tempItem.id);
          if (_index !== undefined && _index != null && _index >= 0) {
            this.To_Roles[_index].isChecked = true;
          }
        }
        // Departments
        for (let i = 0; i < emailTo.Departments.length; i++) {
          let tempItem = emailTo.Departments[i];
          const _index = this.To_Departments.findIndex(x => x.id == tempItem.id);
          if (_index !== undefined && _index != null && _index >= 0) {
            this.To_Departments[_index].isChecked = true;
          }
        }
        // Groups
        for (let i = 0; i < emailTo.Groups.length; i++) {
          let tempItem = emailTo.Groups[i];
          const _index = this.To_Groups.findIndex(x => x.id == tempItem.id);
          if (_index !== undefined && _index != null && _index >= 0) {
            this.To_Groups[_index].isChecked = true;
          }
        }
        // Locations
        for (let i = 0; i < emailTo.Locations.length; i++) {
          let tempItem = emailTo.Locations[i];
          const _index = this.To_Locations.findIndex(x => x.id == tempItem.id);
          if (_index !== undefined && _index != null && _index >= 0) {
            this.To_Locations[_index].isChecked = true;
          }
        }

        this.onChangeEmailTo(null, 1);
        this.onChangeEmailTo(null, 2);
        this.onChangeEmailTo(null, 3);
        this.onChangeEmailTo(null, 4);
        this.onChangeEmailTo(null, 5);
        this.onChangeEmailTo(null, 6);
      }
      //Email CC
      if (approvalEmailTemplate.cc != null && approvalEmailTemplate.cc != undefined) {
        let emailCC = JSON.parse(approvalEmailTemplate.cc);

        // System Options
        for (let i = 0; i < emailCC.SystemOptions.length; i++) {
          let tempItem = emailCC.SystemOptions[i];
          const _index = this.CC_SystemOptions.findIndex(x => x.id == tempItem.id);
          if (_index !== undefined && _index != null && _index >= 0) {
            this.CC_SystemOptions[_index].isChecked = true;
          }
        }
        // Users
        for (let i = 0; i < emailCC.Users.length; i++) {
          let tempItem = emailCC.Users[i];
          const _index = this.CC_Users.findIndex(x => x.id == tempItem.id);
          if (_index !== undefined && _index != null && _index >= 0) {
            this.CC_Users[_index].isChecked = true;
          }
        }
        // Roles
        for (let i = 0; i < emailCC.Roles.length; i++) {
          let tempItem = emailCC.Roles[i];
          const _index = this.CC_Roles.findIndex(x => x.id == tempItem.id);
          if (_index !== undefined && _index != null && _index >= 0) {
            this.CC_Roles[_index].isChecked = true;
          }
        }
        // Departments
        for (let i = 0; i < emailCC.Departments.length; i++) {
          let tempItem = emailCC.Departments[i];
          const _index = this.CC_Departments.findIndex(x => x.id == tempItem.id);
          if (_index !== undefined && _index != null && _index >= 0) {
            this.CC_Departments[_index].isChecked = true;
          }
        }
        // Groups
        for (let i = 0; i < emailCC.Groups.length; i++) {
          let tempItem = emailCC.Groups[i];
          const _index = this.CC_Groups.findIndex(x => x.id == tempItem.id);
          if (_index !== undefined && _index != null && _index >= 0) {
            this.CC_Groups[_index].isChecked = true;
          }
        }
        // Locations
        for (let i = 0; i < emailCC.Locations.length; i++) {
          let tempItem = emailCC.Locations[i];
          const _index = this.CC_Locations.findIndex(x => x.id == tempItem.id);
          if (_index !== undefined && _index != null && _index >= 0) {
            this.CC_Locations[_index].isChecked = true;
          }
        }

        this.onChangeEmailCC(null, 1);
        this.onChangeEmailCC(null, 2);
        this.onChangeEmailCC(null, 3);
        this.onChangeEmailCC(null, 4);
        this.onChangeEmailCC(null, 5);
        this.onChangeEmailCC(null, 6);
      }

      //Email BCC
      if (approvalEmailTemplate.bcc != null && approvalEmailTemplate.bcc != undefined) {
        let emailBCC = JSON.parse(approvalEmailTemplate.bcc);

        // System Options
        for (let i = 0; i < emailBCC.SystemOptions.length; i++) {
          let tempItem = emailBCC.SystemOptions[i];
          const _index = this.BCC_SystemOptions.findIndex(x => x.id == tempItem.id);
          if (_index !== undefined && _index != null && _index >= 0) {
            this.BCC_SystemOptions[_index].isChecked = true;
          }
        }
        // Users
        for (let i = 0; i < emailBCC.Users.length; i++) {
          let tempItem = emailBCC.Users[i];
          const _index = this.BCC_Users.findIndex(x => x.id == tempItem.id);
          if (_index !== undefined && _index != null && _index >= 0) {
            this.BCC_Users[_index].isChecked = true;
          }
        }
        // Roles
        for (let i = 0; i < emailBCC.Roles.length; i++) {
          let tempItem = emailBCC.Roles[i];
          const _index = this.BCC_Roles.findIndex(x => x.id == tempItem.id);
          if (_index !== undefined && _index != null && _index >= 0) {
            this.BCC_Roles[_index].isChecked = true;
          }
        }
        // Departments
        for (let i = 0; i < emailBCC.Departments.length; i++) {
          let tempItem = emailBCC.Departments[i];
          const _index = this.BCC_Departments.findIndex(x => x.id == tempItem.id);
          if (_index !== undefined && _index != null && _index >= 0) {
            this.BCC_Departments[_index].isChecked = true;
          }
        }
        // Groups
        for (let i = 0; i < emailBCC.Groups.length; i++) {
          let tempItem = emailBCC.Groups[i];
          const _index = this.BCC_Groups.findIndex(x => x.id == tempItem.id);
          if (_index !== undefined && _index != null && _index >= 0) {
            this.BCC_Groups[_index].isChecked = true;
          }
        }
        // Locations
        for (let i = 0; i < emailBCC.Locations.length; i++) {
          let tempItem = emailBCC.Locations[i];
          const _index = this.BCC_Locations.findIndex(x => x.id == tempItem.id);
          if (_index !== undefined && _index != null && _index >= 0) {
            this.BCC_Locations[_index].isChecked = true;
          }
        }

        this.onChangeEmailBCC(null, 1);
        this.onChangeEmailBCC(null, 2);
        this.onChangeEmailBCC(null, 3);
        this.onChangeEmailBCC(null, 4);
        this.onChangeEmailBCC(null, 5);
        this.onChangeEmailBCC(null, 6);
      }
    }
  }
}
