import { DynamicControlBase } from "./dynamic-control-base";

export class DynamicControlTextArea extends DynamicControlBase<string> {
  controlType = "textarea";
  type: string;

  constructor(options: {} = {}) {
    super(options);
    this.type = options["type"] || "";
  }
}
