<div class="bg-overlady" *ngIf="userLoader">
    <div class="loader-position">
        <div class="loader"></div>
        <h2>Loading....</h2>
    </div>
</div>

<div class="col-md-12 no-padding" style="border-bottom: 1px solid #ddd;">
    <div class="col-md-2 no-padding">
        <h4 class="title-new">Leave Approvals</h4>
    </div>

    <div class="col-md-10 col-sm-4 text-center">
        <div class="financial-calendar">
          <button type="button" class="btn" title="Previous Year"
            (click)="getFinancialYearDetailsback($event, prevFinancialYearId)" [disabled]="prevFinancialYearId == 0" style="background: transparent;
            padding: 0;">
            <svg id="i-chevron-left" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="20" height="20"
              fill="none" stroke="#204d74" stroke-linecap="round" stroke-linejoin="round" stroke-width="3">
              <path d="M20 30 L8 16 20 2" />
            </svg>
          </button>
          <span>&nbsp; {{financialYearText}} &nbsp;</span>
          <button type="button" class="btn" title="Next Year"
            (click)="getFinancialYearDetailsForwrd($event, nextFinancialYearId)" [disabled]="nextFinancialYearId == 0" style="background: transparent;
            padding: 0;">
            <svg id="i-chevron-right" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="20" height="20"
              fill="none" stroke="#204d74" stroke-linecap="round" stroke-linejoin="round" stroke-width="3">
              <path d="M12 30 L24 16 12 2" />
            </svg>
          </button>
        </div>
      </div>
    <!--
      <div class="col-md-6 text-right btnset" style="padding-top: 50px;">
           <div id="Appr">
                    <div class="btn-group">
                        <button type="button" class="btn btn-primary btn-brand" data-toggle="modal" data-target="#exampleModalfilter" title="Filters">
                        <img src="../assets/img/Logo/23.png">
                        </button>
                    </div>
                    <div class="btn-group">
                        <div class="row" *ngIf="showtable">
                            <div class="col-sm-12">
                            <div class="d-flex justify-content-between p-2" class="col-sm-12 text-right">
                            <ul class="paggingUl">
                                <ngb-pagination [collectionSize]="myApprovalsListLength" [(page)]="myApprovalsPage" [pageSize]="myApprovalsPageSize"></ngb-pagination>
                                <li>
                                    <span class="recordsMsg">{{myApprovalsPage}}-{{myApprovalsPageSize}} of {{myApprovalsListLength}}</span>
                                </li>
                                </ul>
                            </div>
                            </div>
                        </div>
                    </div>

           </div>

           <div id="Reqt" style="display:none">
                    <div class="btn-group">
                        <button type="button" class="btn btn-primary btn-brand" data-toggle="modal" data-target="#exampleModalfilter2" title="Filters">
                        <img src="../assets/img/Logo/23.png">
                        </button>
                    </div>
                    <div class="btn-group">
                        <div class="row">
                            <div class="col-sm-12">
                            <div class="d-flex justify-content-between p-2" class="col-sm-12 text-right">
                                <ul class="paggingUl">
                                <ngb-pagination [collectionSize]="myRequestsListLength" [(page)]="myRequestsPage" [pageSize]="myRequestsPageSize"></ngb-pagination>
                                 <li>
                                     <span class="recordsMsg">{{myRequestsPage}}-{{myRequestsPageSize}} of {{myRequestsListLength}}</span>
                                </li>
                                </ul>
                            </div>
                            </div>
                        </div>
                    </div>

           </div>

      </div> -->
</div>

<div class="row" style="margin: 10px 0 0; display: inline-block; width: 100%;">
    <div class="col-md-12 col-xs-12 no-padding">
        <ul class="nav nav-tabs" role="tablist" style="border-bottom: none;">
            <li class="nav-item active">
                <a class="nav-link active" data-toggle="tab" href="#MyApprovals" role="tab">My Approvals</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#MyRequests" role="tab">My Requests</a>
            </li>
        </ul>

        <div class="tab-content" style="border-top: 2px solid #ececec;">
            <div class="tab-pane active" id="MyApprovals" role="tabpanel">
                <div class="app-rej" style="margin-top: -35px;">
                    <div class="col no-padding text-right" style="margin-left:auto;">
                        <ul class="new-version">
                            <li><a [ngClass]="{'activelegend': active === 1}" style="cursor:pointer"
                                    (click)="getFilterVaLue(1)">Total</a> : <span
                                    class="value-get get-b">{{total}}</span></li>
                            <li><a [ngClass]="{'activelegend': active === 2}" style="cursor:pointer"
                                    (click)="getFilterVaLue(2)">Pending</a> : <span
                                    class="value-get get-o">{{pendingLength}}</span></li>
                            <li><a [ngClass]="{'activelegend': active === 3}" style="cursor:pointer"
                                    (click)="getFilterVaLue(3)">Approve</a> : <span
                                    class="value-get get-g">{{approvedLength}}</span></li>
                            <li><a [ngClass]="{'activelegend': active === 4}" style="cursor:pointer"
                                    (click)="getFilterVaLue(4)">Reject</a> : <span class="value-get"
                                    style="background-color: red;">{{rejectLength}}</span></li>
                            <li><a [ngClass]="{'activelegend': active === 5}" style="cursor:pointer"
                                    (click)="getFilterVaLue(5)">Cancelled</a> : <span class="value-get"
                                    style="background-color: rgb(129, 129, 129);">{{CancelledLength}}</span></li>

                        </ul>
                    </div>

                </div>
                <div class="form-group" id="tableData">
                    <div class="col-sm-12 col-xs-12 less form-group grid-scroll-mobile LeaveTypeList">

                        <ejs-grid #overviewgrid id='overviewgrid' [dataSource]='myApprovalsList' rowHeight='38'
                            [allowSelection]='true' [allowSorting]='true' height='600' [enableHover]='false'
                            [selectionSettings]='selectionSettings' [filterSettings]='filterSettings'
                            [allowSorting]='true' [allowFiltering]='true' [enableVirtualization]='false' (load)='load()'
                            [allowPaging]='true' [pageSettings]='pageSettings'>
                            <e-columns>
                                <!-- <e-column field='chkAllApprovals' headerText='#' [filter]='filter' >
                    <ng-template #template let-data>
                        <input type="checkbox" id="chkAllApprovals" name="chkAllApprovals" [(ngModel)]="chkAllApprovals" (change)="checkAllMyApprovals()" />
                        <p class="no-padding no-margin"></p>
                    </ng-template>
                  </e-column> -->

                                <e-column field='employeeName' headerText='Employee' [filter]='filter'>
                                    <ng-template #template let-Approval>
                                        <span>
                                            <!-- <img src="../assets/img/Logo/male.png" style="width: 28px;margin-right: 5px;"> {{data.userName}} -->
                                            <img src="{{userImage(Approval)}}"
                                                style="width: 28px;margin-right: 5px; border-radius: 50%;">
                                            {{Approval.submitedBy}}
                                        </span>

                                        <a data-toggle="modal" *ngIf="Approval.status != 'Cancelled' " data-target="#requestDetailsModal"
                                            (click)="getRequestDetails(Approval.requestId,Approval.requestType)">
                                            {{ Approval.employeeName }} {{ Approval.empCode }}
                                        </a>
                                        <a  *ngIf="Approval.status == 'Cancelled' " >
                                        {{ Approval.employeeName }} {{ Approval.empCode }}
                                    </a>
                                    </ng-template>
                                </e-column>

                                <e-column field='createdDate' headerText='Date of requests' [filter]='filter'>
                                    <ng-template #template let-Approval>
                                        {{ dateformat(Approval.createdDate) }}
                                    </ng-template>
                                </e-column>


                                <e-column field="leaveTypeName" headerText='Request type' [filter]='filter'>
                                    <ng-template #template let-Approval>
                                        <p> {{Approval.leaveTypeName}} </p>
                                       </ng-template>
                                </e-column>
                                <e-column field='approvaltype' headerText='Action Type' [filter]='filter'>
                                    <ng-template #template let-Approval>
                                        <p *ngIf="Approval.type == 1">Approver </p>
                                        <p *ngIf="Approval.type == 0">Informer </p>
                                       </ng-template>
                                </e-column>

                                <e-column field='leaveStartDate' headerText='Period' [filter]='filter'>
                                    <ng-template #template let-Approval>
                                        {{Approval.leaveStartDate | date}} - {{Approval.leaveEndDate | date}}
                                    </ng-template>
                                </e-column>
                                <e-column field='totalDays' headerText='Duration' [filter]='filter'>
                                    <ng-template #template let-Approval>
                                        {{ Approval.totalDays }} {{ Approval.dayHoursTaken }}
                                    </ng-template>
                                </e-column>

                                <e-column field="updatedDate" headerText='Date Of Approval' [filter]='filter'>
                                    <ng-template #template let-request>
                                        <span *ngIf="request.updatedDate != null">{{ dateformat(request.updatedDate)
                                            }}</span>
                                        <span *ngIf="request.updatedDate == null">&nbsp;-&nbsp;</span>
                                    </ng-template>
                                </e-column>

                                <!-- <e-column field="status" headerText='Actions' [filter]='filter'>
                </e-column> -->

                                <e-column field="status" headerText='Status' [filter]='filter'>
                                    <ng-template #template let-data>
                                        <div *ngIf="data.status == 'Pending' ">
                                            <img src="../assets/img/Logo/Pending.gif" style="width: 22px;"
                                                title="Pending">
                                        </div>
                                        <div *ngIf="data.status == 'Submit' ">
                                            <img src="../assets/img/Logo/Submitted.png" style="width: 12px;"
                                                title="Submitted">
                                        </div>
                                        <div *ngIf="data.status == 'Cancelled' ">
                                            <img src="../assets/img/Logo/cancel.jpg" style="width: 20px;"
                                                title="Cancelled">
                                        </div>
                                        <div *ngIf="data.status == 'Approved' ">
                                            <img src="../assets/img/Logo/Approve1.png" style="width: 18px;"
                                                title="Approved">
                                        </div>
                                        <div *ngIf="data.status == 'Rejected' ">
                                            <img src="../assets/img/Logo/Reject1.png" style="width: 18px;"
                                                title="Rejected">
                                        </div>
                                        <!-- <input type="checkbox" id="chkAllApprovals" name="chkAllApprovals" [(ngModel)]="chkAllApprovals" (change)="checkAllMyApprovals()" /> -->
                                        <p class="no-padding no-margin"></p>
                                    </ng-template>
                                </e-column>
                            </e-columns>
                        </ejs-grid>
                    </div>
                </div>
            </div>

            <div class="tab-pane" id="MyRequests" role="tabpanel">
                <div class="app-rej" style="margin-top: -35px;">
                    <div class="col no-padding text-right" style="margin-left:auto;">
                        <ul class="new-version">
                            <li> <a [ngClass]="{'activelegend': active === 1}" (click)="getFilterVaLueReq(1)">Total</a>
                                : <span class="value-get get-b">{{myRequestsListLength}}</span></li>
                            <li> <a [ngClass]="{'activelegend': active === 2}"
                                    (click)="getFilterVaLueReq(2)">Pending</a> : <span
                                    class="value-get get-o">{{pendingReqLength}}</span></li>
                            <li> <a [ngClass]="{'activelegend': active === 3}"
                                    (click)="getFilterVaLueReq(3)">Approve</a> : <span
                                    class="value-get get-g">{{approvedReqLength}}</span></li>
                            <li> <a [ngClass]="{'activelegend': active === 4}" (click)="getFilterVaLueReq(4)">Reject</a>
                                : <span class="value-get" style="background-color: red;">{{rejectReqLength}}</span></li>
                            <li><a [ngClass]="{'activelegend': active === 5}"
                                    (click)="getFilterVaLueReq(5)">Cancelled</a> : <span class="value-get"
                                    style="background-color: rgb(129, 129, 129);">{{mycancelledLeaveslength}}</span>
                            </li>

                        </ul>
                    </div>



                </div>
                <div class="form-group" id="tableData">
                    <div class="col-sm-12 col-xs-12 less form-group grid-scroll-mobile LeaveDisablesOnpre">
                        <ejs-grid #overviewgrid id='overviewgrid' [dataSource]='myRequestsList' rowHeight='38'
                            [allowSelection]='true' [allowSorting]='true' height='600' [enableHover]='false'
                            [selectionSettings]='selectionSettings' [filterSettings]='filterSettings'
                            [allowSorting]='true' [allowFiltering]='true' [enableVirtualization]='false' (load)='load()'
                            [allowPaging]='true' [pageSettings]='pageSettings'>
                            <e-columns>
                                <!-- <e-column field='' headerText='#' [filter]='filter' >
                        <ng-template #template let-data>


                            <span *ngIf="request.statusId==1" class="status-icon"><i class="fa fa-filter pending" aria-hidden="true"></i></span>
                            <span *ngIf="request.statusId==2" class="status-icon"><i class="fa fa-thumbs-up approved"  aria-hidden="true"></i></span>
                            <span *ngIf="request.statusId==3" class="status-icon"><i class="fa fa-thumbs-down rejected" aria-hidden="true"></i></span>
                            <span *ngIf="request.statusId==4" class="status-icon"><i class="fa fa-times-circle cancelled" aria-hidden="true"></i></span>
                        </ng-template>
                      </e-column> -->

                                <e-column field='employeeName' headerText='Employee' [filter]='filter'>
                                    <ng-template #template let-Approval>
                                        <span>
                                            <!-- <img src="../assets/img/Logo/male.png" style="width: 28px;margin-right: 5px;"> {{data.userName}} -->
                                            <img src="{{userImage(Approval)}}"
                                                style="width: 28px;margin-right: 5px; border-radius: 50%;">
                                            {{Approval.submitedBy}}
                                        </span>
                                        <a *ngIf="Approval.status != 'Cancelled' " (click)="getRequestDetails(Approval.requestId,Approval.requestType)">
                                            {{ Approval.employeeName }} {{ Approval.empCode }}
                                        </a>

                                        <a  *ngIf="Approval.status == 'Cancelled' " >
                                            {{ Approval.employeeName }} {{ Approval.empCode }}
                                        </a>

                                    </ng-template>
                                </e-column>

                                <e-column field='createdDate' headerText='Date of requests' [filter]='filter'>
                                    <ng-template #template let-Approval>
                                        {{ dateformat(Approval.createdDate) }}
                                    </ng-template>
                                </e-column>

                                <e-column field="requestType" headerText='Request type' [filter]='filter'>
                                </e-column>
                                <e-column field='totalDays' headerText='Period' [filter]='filter'>
                                    <ng-template #template let-Approval>
                                        {{ Approval.totalDays }} {{ Approval.dayHoursTaken }}
                                    </ng-template>
                                </e-column>

                                <e-column field="updatedDate" headerText='Date Of Approval' [filter]='filter'>
                                    <ng-template #template let-request>
                                        <span *ngIf="request.updatedDate != null">{{ dateformat(request.updatedDate)
                                            }}</span>
                                        <span *ngIf="request.updatedDate == null">&nbsp;-&nbsp;</span>
                                    </ng-template>
                                </e-column>
                                <e-column field="status" headerText='Status' [filter]='filter'>
                                    <ng-template #template let-data>
                                        <div *ngIf="data.status == 'Pending' ">
                                            <img src="../assets/img/Logo/Pending.gif" style="width: 22px;"
                                                title="Pending">
                                        </div>
                                        <div *ngIf="data.status == 'Submit' ">
                                            <img src="../assets/img/Logo/Submitted.png" style="width: 12px;"
                                                title="Submitted">
                                        </div>
                                        <div *ngIf="data.status == 'Cancelled' ">
                                            <img src="../assets/img/Logo/cancel.jpg" style="width: 20px;"
                                                title="Cancelled">
                                        </div>
                                        <div *ngIf="data.status == 'Approved' ">
                                            <img src="../assets/img/Logo/Approve1.png" style="width: 18px;"
                                                title="Approved">
                                        </div>
                                        <div *ngIf="data.status == 'Rejected' ">
                                            <img src="../assets/img/Logo/Reject1.png" style="width: 18px;"
                                                title="Rejected">
                                        </div>
                                    </ng-template>
                                </e-column>
                                <!-- <e-column field="actions" headerText='Actions' [filter]='filter'>
                    </e-column>
                    <ng-template #template let-request>
                        <span *ngIf="request.updatedDate != null">{{ dateformat(request.updatedDate) }}</span>
                        <span *ngIf="request.updatedDate == null">&nbsp;-&nbsp;</span>
                    </ng-template> -->
                            </e-columns>
                        </ejs-grid>
                    </div>
                </div>
            </div>
        </div>

        <mk-tabs>
            <!-- <mk-tab >
                <mk-tab-header>
                    <p (click)="OnPagingHide(2)">My Approvals</p>
                </mk-tab-header>
                <mk-tab-content >
                    <mk-box boxColor="white">
                        <div class="row mar-bot-10" style="margin-bottom: 0px;">

                            <div class="col-md-3 col-sm-4 col-xs-6 text-right pull-right" style="margin-top: -40px;">
                                <div class="btn-group">
                                    <button type="button" class="btn btn-primary" (click)="showBulkApprovalModal()">Approve/Reject</button>

                                    <div class="dropdown rt-dropdown">
                                        <label class="col-form-label" data-toggle="dropdown"><i class="fa fa-ellipsis-h" aria-hidden="true"></i></label>
                                        <ul class="dropdown-menu pull-right list-group">
                                            <li (click)="Import(1)"><i class="fa fa-upload dropdown-item active" aria-hidden="true"></i> Import-Leaves</li>
                                            <li (click)="Import(2)"><i class="fa fa-upload dropdown-item active" aria-hidden="true"></i> Import-CompOff</li>
                                            <li (click)="ExportLeavesExcel()"><i class="fa fa-download dropdown-item active" aria-hidden="true"></i> Export Leaves</li>
                                            <li (click)="ExportCompOffExcel()"><i class="fa fa-download dropdown-item active" aria-hidden="true"></i> Export CompOff</li>
                                        </ul>
                                  </div>
                                </div>
                            </div>
                        </div>

                         <div class="row">
                            <div class="col-sm-12" >
                               <div class="less form-group table-responsive" *ngIf="showtable">
                                    <table class="table table-bordered user-table">
                                        <thead>
                                            <tr>
                                                <ng-container>
                                                    <th scope="col" class="tableHeader">
                                                        <input type="checkbox" id="chkAllApprovals" name="chkAllApprovals" [(ngModel)]="chkAllApprovals" (change)="checkAllMyApprovals()" />
                                                        <p class="no-padding no-margin"></p>
                                                    </th>
                                                    <th scope="col" class="tableHeader" sortable="empCode" (sort)="onSortMyApprovals($event)">
                                                        <p class="no-padding no-margin">Employee <span><i class="fa fa-fw fa-unsorted"></i></span></p>
                                                    </th>
                                                    <th scope="col" class="tableHeader" sortable="createdDate" (sort)="onSortMyApprovals($event)">
                                                        <p class="no-padding no-margin">Date of requests<span><i class="fa fa-fw fa-unsorted"></i></span></p>
                                                    </th>
                                                    <th scope="col" class="tableHeader" sortable="requestType" (sort)="onSortMyApprovals($event)">
                                                        <p class="no-padding no-margin">Request type <span><i class="fa fa-fw fa-unsorted"></i></span></p>
                                                    </th>
                                                    <th scope="col" class="tableHeader" sortable="status" (sort)="onSortMyApprovals($event)">
                                                        <p class="no-padding no-margin">Status <span><i class="fa fa-fw fa-unsorted"></i></span></p>
                                                    </th>
                                                    <th scope="col" class="tableHeader" sortable="updatedDate" (sort)="onSortMyApprovals($event)">
                                                        <p class="no-padding no-margin">Date of Approval <span><i class="fa fa-fw fa-unsorted"></i></span></p>
                                                    </th>
                                                </ng-container>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let request of myApprovalsList | slice: (myApprovalsPage-1) * myApprovalsPageSize : (myApprovalsPage-1) * myApprovalsPageSize + myApprovalsPageSize">
                                                <td class="text-center">

                                                    <ng-container *ngIf="request.statusId != 4">
                                                        <input type="checkbox" [(ngModel)]="request.isSelected" />
                                                    </ng-container>

                                                    <span *ngIf="request.statusId==1" class="status-icon"><i class="fa fa-filter pending" aria-hidden="true"></i></span>
                                                    <span *ngIf="request.statusId==2" class="status-icon"><i class="fa fa-thumbs-up approved"  aria-hidden="true"></i></span>
                                                    <span *ngIf="request.statusId==3" class="status-icon"><i class="fa fa-thumbs-down rejected" aria-hidden="true"></i></span>
                                                    <span *ngIf="request.statusId==4" class="status-icon"><i class="fa fa-times-circle cancelled" aria-hidden="true"></i></span>
                                                </td>
                                                <td class="text-left" (click)="getRequestDetails(request.requestId, request.requestType)">
                                                    <a href="javascript: void(0);">
                                                        <span>
                                                            <img class="img-circle img-sm" src="./../../../assets/img/avatar5.png" alt="User Image">
                                                        </span>
                                                        <strong><span style="margin-left: 10px;">{{ request.employeeName}}</span></strong>
                                                        <span>&nbsp;-&nbsp;</span>
                                                        <span>{{ request.empCode }}</span>
                                                    </a>
                                                </td>
                                                <td class="text-center">{{ request.createdDate | date }}</td>
                                                <td class="text-center">{{ request.requestType }}</td>
                                                <td class="text-center">{{ request.status }}</td>
                                                <td class="text-center">
                                                    <span *ngIf="request.updatedDate != null">{{ request.updatedDate | date }}</span>
                                                    <span *ngIf="request.updatedDate == null">&nbsp;-&nbsp;</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <ng-container *ngIf="showimport">
                                    <div class="container-box">
                                        <span style="position: absolute;top: 1px;right: 20px;font-size: 18px; cursor: pointer;" (click)="resetImportForm()" title="Close">
                                            <i class="fa fa-times"></i>
                                        </span>
                                        <div style="margin-top: 1px;">
                                            <form novalidate [formGroup]="formImport">
                                                <div>
                                                    <div *ngIf="showname">
                                                        <i class="fa fa-file-text fa-4x" style="color: blue;"></i>
                                                    </div>
                                                    <label #labelImport for="importFile" style="font-size:20px; color: black;"></label>
                                                    <div *ngIf="showtext">
                                                    <i class="fa fa-upload fa-2x active" style="color: blue;" aria-hidden="true"></i>
                                                        <p style=" font-weight: bold;color: black;">Choose the file to be Imported</p>
                                                        <p style=" font-weight: lighter;">[only xls,xlsx and csv formats are supported]<br>Maximum Upload File Size is 5 MB</p>
                                                    </div>
                                                </div>
                                                <div class="input-group mb-3">
                                                    <div class="custom-file">
                                                        <input type="file" class="custom-file-input e-input butt"  name="file" multiple accept=".xls,.xlsx,.csv" id="importFile" (change)="onFileChange($event.target.files)">
                                                    </div>
                                                    <br><br>
                                                    <label *ngIf="importType == 1">
                                                        <a href="../../../../assets/templates/Import_Leaves.xlsx" class="btn-file" style="color: blue;" download="">Download Sample template for import</a>
                                                    </label>
                                                    <label *ngIf="importType == 2">
                                                        <a href="../../../../assets/templates/Import_CompensatoryOff.xlsx" class="btn-file" style="color: blue;" download="">Download Sample template for import</a>
                                                    </label>
                                                </div>
                                                <div class="form-group">
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div class="container-box1" *ngIf="showNext">
                                        <div class="col-sm-4 text-right">
                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                <input type="checkbox" class="custom-control-input" id="chckSendApproval" [(ngModel)]="chckSendForApproval" name="sendForApproval" />
                                                <label class="col-form-label mx-auto" style="margin-left: 10px;" for="chckSendApproval">Send for Approval</label>
                                            </div>
                                        </div>
                                        <div class="col-sm-4" style="text-align: left;">
                                            <span data-toggle="tooltip" data-placement="right" title="New records will be auto Approved if deselected">
                                                <i class="fa fa-exclamation-circle"></i>
                                            </span>
                                        </div>
                                        <div class="col-sm-4 text-right">
                                            <button type="button" class="btn btn-primary" (click)="uploadData()">Upload</button>
                                        </div>
                                    </div>
                                    <div class="container-box2" *ngIf="showMapData">
                                        <div class="row">
                                            <div class="col-md-6">Leave</div>
                                            <div class="col-md-6"><span style="position: absolute;top: 1px;right: 20px;font-size: 18px;" (click)="resetImportForm()">x</span></div>
                                        <br>
                                            <div class="col-md-6"><label>Employee Id</label><br>
                                                <select id="countrySelect" name="countrySelect">
                                                    <option>Option 1</option>
                                                    </select>

                                            </div>
                                            <div class="col-md-6"><label>Mannager Comments-Status</label><br>
                                                <select id="countrySelect" name="countrySelect">
                                                    <option>Option 1</option>
                                                    </select>

                                            </div>
                                        <br>
                                            <div class="col-md-6"><label>Leave Type</label><br>
                                                <select id="countrySelect" name="countrySelect">
                                                    <option>Option 1</option>
                                                    </select>

                                            </div>
                                            <div class="col-md-6"><label>Reason for Leave</label><br>
                                                <select id="countrySelect" name="countrySelect">
                                                    <option>Option 1</option>
                                                    </select>

                                            </div>
                                        <br>
                                            <div class="col-md-6"><label>From</label><br>
                                                <select id="countrySelect" name="countrySelect">
                                                    <option>Option 1</option>
                                                    </select>

                                            </div>
                                            <div class="col-md-6">

                                            </div>
                                        <br>
                                            <div class="col-md-6"><label>To</label><br>
                                                <select id="countrySelect" name="countrySelect">
                                                    <option>Option 1</option>
                                                    </select>

                                            </div>
                                            <div class="col-md-6">

                                            </div>
                                        <br>
                                            <div class="col-md-6"><label>Duration</label><br>
                                                <select id="countrySelect" name="countrySelect">
                                                    <option>Option 1</option>
                                                    </select>

                                            </div>
                                            <div class="col-md-6">

                                            </div>
                                        <br>
                                            <div class="col-md-6">
                                                <button type="button" class="btn btn-primary" (click)="showUpload()">Cancel</button>
                                            </div>
                                            <div class="col-md-6">
                                                <button type="button" class="btn btn-primary">Next</button>
                                            </div>


                                    </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>


                    </mk-box>
                </mk-tab-content>
            </mk-tab> -->
            <!-- <mk-tab >
                <mk-tab-header>
                    <p (click)="OnPagingHide(1)">My Requests</p>
                </mk-tab-header>
                <mk-tab-content >
                    <mk-box boxColor="white">
                        <div class="row mar-bot-10">

                        </div>

                        <div class="row">
                            <div class="col-sm-12">

                                <div class="less form-group table-responsive">
                                    <table class="table table-bordered user-table">
                                        <thead>
                                            <tr>
                                                <ng-container>
                                                    <th scope="col" class="tableHeader">
                                                        <p class="no-padding no-margin"></p>
                                                    </th>
                                                    <th scope="col" class="tableHeader" sortable="empCode" (sort)="onSortMyApprovals($event)">
                                                        <p class="no-padding no-margin">Employee <span><i class="fa fa-fw fa-unsorted"></i></span></p>
                                                    </th>
                                                    <th scope="col" class="tableHeader" sortable="createdDate" (sort)="onSortMyApprovals($event)">
                                                        <p class="no-padding no-margin">Date of requests<span><i class="fa fa-fw fa-unsorted"></i></span></p>
                                                    </th>
                                                    <th scope="col" class="tableHeader" sortable="requestType" (sort)="onSortMyApprovals($event)">
                                                        <p class="no-padding no-margin">Request type <span><i class="fa fa-fw fa-unsorted"></i></span></p>
                                                    </th>
                                                    <th scope="col" class="tableHeader" sortable="status" (sort)="onSortMyApprovals($event)">
                                                        <p class="no-padding no-margin">Status <span><i class="fa fa-fw fa-unsorted"></i></span></p>
                                                    </th>
                                                    <th scope="col" class="tableHeader" sortable="updatedDate" (sort)="onSortMyApprovals($event)">
                                                        <p class="no-padding no-margin">Date of Approval <span><i class="fa fa-fw fa-unsorted"></i></span></p>
                                                    </th>
                                                </ng-container>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let request of myRequestsList | slice: (myRequestsPage-1) * myRequestsPageSize : (myRequestsPage-1) * myRequestsPageSize + myRequestsPageSize">
                                                <td class="text-center">
                                                    <span *ngIf="request.statusId==1" class="status-icon"><i class="fa fa-filter pending" aria-hidden="true"></i></span>
                                                    <span *ngIf="request.statusId==2" class="status-icon"><i class="fa fa-thumbs-up approved"  aria-hidden="true"></i></span>
                                                    <span *ngIf="request.statusId==3" class="status-icon"><i class="fa fa-thumbs-down rejected" aria-hidden="true"></i></span>
                                                    <span *ngIf="request.statusId==4" class="status-icon"><i class="fa fa-times-circle cancelled" aria-hidden="true"></i></span>
                                                </td>
                                                <td class="text-left" (click)="getLeaveDetails(request.leaveId)" data-toggle="modal" data-backdrop="static" data-keyboard="false" data-target="#leaveDetailModal">
                                                    <a href="javascript: void(0);">
                                                    <span>
                                                        <img class="img-circle img-sm" src="./../../../assets/img/avatar5.png" alt="User Image">
                                                    </span>
                                                    <strong><span style="margin-left: 10px;">{{ request.employeeName}}</span></strong>
                                                    <span>&nbsp;-&nbsp;</span>
                                                    <span>{{ request.empCode }}</span>
                                                    </a>
                                                </td>
                                                <td class="text-center">{{ request.createdDate | date }}</td>
                                                <td class="text-center">{{ request.requestType }}</td>
                                                <td class="text-center">{{ request.status }}</td>
                                                <td class="text-center">
                                                    <span *ngIf="request.updatedDate != null">{{ request.updatedDate | date }}</span>
                                                    <span *ngIf="request.updatedDate == null">&nbsp;-&nbsp;</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>


                    </mk-box>
                </mk-tab-content>
            </mk-tab> -->
        </mk-tabs>
    </div>
</div>

<div id="requestDetailsModal" class="modal fade" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button _ngcontent-c11="" class="close" data-dismiss="modal" type="button">×</button>
                <h4 class="modal-title">{{currentRequestType}} Request Details</h4>
            </div>
            <div class="modal-body user-modal-height col-xs-12 col-sm-12 less" style="padding: 0;">
                <div *ngIf="leaveDetails != null" class="box"
                    style="border-top: 0; padding-bottom: 15px;border-radius: 0px 0px 10px 10px;">
                    <div class="box-header" style=" padding: 8px;">
                        <div class="row">
                            <div class="col-sm-12 text-right">
                                <p *ngIf="leaveDetails.statusId==1" class="no-margin">
                                    <i class="fa fa-filter" style="margin-left: 10px; color: orange;"
                                        aria-hidden="true"></i> &nbsp;
                                    <strong>Pending</strong> &nbsp;&nbsp;&nbsp;
                                    <button type="button" class="btn btn-success" data-toggle="modal"
                                        data-backdrop="static" data-keyboard="false"
                                        data-target="#requestApprovalModal">Approve/Reject</button>
                                </p>
                                <p *ngIf="leaveDetails.statusId==2" class="no-margin">
                                    <i class="fa fa-thumbs-up" style="margin-left: 10px; color: rgb(0, 141, 76);"
                                        aria-hidden="true"></i> &nbsp;
                                    <strong>Approved</strong> &nbsp;&nbsp;&nbsp;
                                    <button type="button" class="btn btn-info" data-toggle="modal"
                                        data-backdrop="static" data-keyboard="false"
                                        data-target="#requestApprovalModal">Timeline</button>
                                </p>
                                <p *ngIf="leaveDetails.statusId==3" class="no-margin">
                                    <i class="fa fa-thumbs-down" style="margin-left: 10px; color: rgb(255, 38, 49);"
                                        aria-hidden="true"></i> &nbsp;
                                    <strong>Rejected</strong> &nbsp;&nbsp;&nbsp;
                                    <button type="button" class="btn btn-info" data-toggle="modal"
                                        data-backdrop="static" data-keyboard="false"
                                        data-target="#requestApprovalModal">Timeline</button>
                                </p>
                                <p *ngIf="leaveDetails.statusId==4" class="no-margin">
                                    <i class="fa fa-times-circle" style="margin-left: 10px; color: rgb(51, 122, 183);"
                                        aria-hidden="true"></i> &nbsp;
                                    <strong>Cancelled</strong> &nbsp;&nbsp;&nbsp;
                                    <button type="button" class="btn btn-info" data-toggle="modal"
                                        data-backdrop="static" data-keyboard="false"
                                        data-target="#requestApprovalModal">Timeline</button>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="box-body app-subn" style="padding: 0;">
                        <div class="row">
                            <div class="col-md-12 no-padding">
                                <div class="col-md-6">
                                    <label>Employee Name</label>
                                    <span class="leave-app-info">{{ leaveDetails.empCode }} - {{
                                        leaveDetails.employeeName }}</span>
                                </div>

                                <div class="col-md-6">
                                    <label>Leave Type</label>
                                    <span class="leave-app-info">{{ leaveDetails.leaveTypeName }} ({{ leaveDetails.type
                                        }})</span>
                                </div>

                                <div class="col-md-6">
                                    <label>Date</label>
                                    <span class="leave-app-info">{{ leaveDetails.leaveStartDate | date }} - {{
                                        leaveDetails.leaveEndDate | date }}</span>
                                </div>

                                <div class="col-md-6">
                                    <label>Days/Hours</label>
                                    <span class="leave-app-info">{{ leaveDetails.totalDays }} {{
                                        leaveDetails.dayHoursTaken }}</span>
                                </div>

                                <div class="col-md-6">
                                    <label>Reason for Leave</label>
                                    <span class="leave-app-info">{{ leaveDetails.leaveReason }}</span>
                                </div>

                            </div>





                            <ng-container *ngIf="(leaveDetails.filePaths != null && leaveDetails.filePaths != '') &&
                            (apiURL != null && apiURL != '')">
                                <div class="row">
                                  <div class="view-leave-doc">
                                    <label>Uploaded files:- </label>
                                    <a href="{{apiURL}}/{{leaveDetails.filePaths}}" target="_blank" class="btn btn-primary">View / Download</a>
                                </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>


                </div>
                <div *ngIf="compOffDetails != null" class="box"
                    style="border-top: 0;padding-bottom: 15px; border-radius: 0px 0px 10px 10px;">
                    <div class="box-header" style=" padding: 8px;">
                        <div class="row">
                            <div class="col-sm-12 text-right">
                                <p *ngIf="compOffDetails.statusId==1" class="no-margin">
                                    <i class="fa fa-filter" style="margin-left: 10px; color: orange;"
                                        aria-hidden="true"></i> &nbsp;
                                    <strong>Pending</strong> &nbsp;
                                    <button type="button" class="btn btn-success" data-toggle="modal"
                                        data-backdrop="static" data-keyboard="false"
                                        data-target="#requestApprovalModal">Approve/Reject</button>
                                </p>
                                <p *ngIf="compOffDetails.statusId==2" class="no-margin">
                                    <i class="fa fa-thumbs-up" style="margin-left: 10px; color: rgb(0, 141, 76);"
                                        aria-hidden="true"></i> &nbsp;
                                    <strong>Approved</strong> &nbsp;
                                    <button type="button" class="btn btn-info" data-toggle="modal"
                                        data-backdrop="static" data-keyboard="false"
                                        data-target="#requestApprovalModal">Timeline</button>
                                </p>
                                <p *ngIf="compOffDetails.statusId==3" class="no-margin">
                                    <i class="fa fa-thumbs-down" style="margin-left: 10px; color: rgb(255, 38, 49);"
                                        aria-hidden="true"></i> &nbsp;
                                    <strong>Rejected</strong> &nbsp;
                                    <button type="button" class="btn btn-info" data-toggle="modal"
                                        data-backdrop="static" data-keyboard="false"
                                        data-target="#requestApprovalModal">Timeline</button>
                                </p>
                                <p *ngIf="compOffDetails.statusId==4" class="no-margin">
                                    <i class="fa fa-times-circle" style="margin-left: 10px; color: rgb(51, 122, 183);"
                                        aria-hidden="true"></i> &nbsp;
                                    <strong>Cancelled</strong> &nbsp;
                                    <button type="button" class="btn btn-info" data-toggle="modal"
                                        data-backdrop="static" data-keyboard="false"
                                        data-target="#requestApprovalModal">Timeline</button>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="box-body app-subn">
                        <div class="row">
                            <div class="col-xs-6">
                                <label>Employee Name</label>
                                <span class="leave-app-info">{{ compOffDetails.empCode }} - {{
                                    compOffDetails.employeeName }}</span>
                            </div>
                            <div class="col-xs-6">
                                <label>Worked Date</label>
                                <span class="leave-app-info">{{ compOffDetails.workedDate | date }}</span>
                            </div>
                            <div class="col-xs-6">
                                <label>Duration</label>
                                <span *ngIf="compOffDetails.duration === '1' && compOffDetails.unit === 1"
                                    class="leave-app-info">Full Day</span>
                                <span *ngIf="compOffDetails.duration === '2' && compOffDetails.unit === 1"
                                    class="leave-app-info">Half Day</span>
                                <span *ngIf="compOffDetails.duration === '3' && compOffDetails.unit === 1"
                                    class="leave-app-info">Quater Day</span>
                                <span *ngIf="compOffDetails.unit === 2"
                                    class="leave-app-info">{{compOffDetails.duration}} {{ compOffDetails.unitText}}
                                </span>
                            </div>
                            <div class="col-xs-6">
                                <label>Project Name</label>
                                <span class="leave-app-info">{{ compOffDetails.projectname }}</span>
                            </div>
                            <div class="col-xs-6">
                                <label>Credited</label>
                                <span class="leave-app-info">{{ compOffDetails.toBeCredited }} {{
                                    compOffDetails.unitText }}</span>
                            </div>
                            <div class="col-xs-6">
                                <label>Expiry Date</label>
                                <span class="leave-app-info">{{ compOffDetails.expiryDate | date }}</span>
                            </div>
                            <div class="col-xs-6">
                                <label>Description</label>
                                <span class="leave-app-info">{{ compOffDetails.description }}</span>
                            </div>

                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="requestApprovalModal" class="modal fade" role="dialog">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <div class="modal-header">
                <button class="close" data-dismiss="modal" type="button">×</button>
                <h4 class="modal-title">Approval Timeline</h4>
            </div>
            <div class="modal-body">
                <div *ngIf="leaveDetails != null">
                    <div class="row">
                        <div class="col-sm-12">
                            <span>
                                <img class="img-circle img-sm" src="./../../../assets/img/avatar5.png" alt="User Image">
                            </span>
                            <strong>
                                <span style="color:#858481;margin-left: 10px;">{{ leaveDetails.empCode }}</span>
                                <span> - </span>
                                <span>{{ leaveDetails.employeeName }}</span>
                            </strong>

                            <span>'s request has been sent for approval</span>
                            <span style="display: block; margin-left: 40px; font-size: 11px;">{{
                                leaveDetails.createdDate | date:'medium' }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="request-approval-timeline">
                                <ul class="timeline">
                                    <li *ngFor="let leaveApproval of leaveApprovalList">
                                        <div class="col-md-12">
                                            <ng-container
                                                *ngIf="(leaveApproval.type==1 && (leaveApproval.approverGroup==6 || leaveApproval.approverGroup==10)) === 1;else second">

                                                <span>{{leaveApproval.status}}</span>

                                                <span *ngIf="leaveApproval.statusId==1" class="status-icon"><i
                                                        class="fa fa-filter pending" aria-hidden="true"></i></span>
                                                <span *ngIf="leaveApproval.statusId==2" class="status-icon"><i
                                                        class="fa fa-thumbs-up approved" aria-hidden="true"></i></span>
                                                <span *ngIf="leaveApproval.statusId==3" class="status-icon"><i
                                                        class="fa fa-thumbs-down rejected"
                                                        aria-hidden="true"></i></span>
                                                <span *ngIf="leaveApproval.statusId==4" class="status-icon"><i
                                                        class="fa fa-times-circle cancelled"
                                                        aria-hidden="true"></i></span>
                                            </ng-container>
                                            <ng-template #second>
                                                <span *ngIf="leaveApproval.status">{{leaveApproval.status}}</span>
                                                <span *ngIf="leaveApproval.statusId==1" class="status-icon"><i
                                                        class="fa fa-filter pending" aria-hidden="true"></i></span>
                                                <span *ngIf="leaveApproval.statusId==2" class="status-icon"><i
                                                        class="fa fa-thumbs-up approved" aria-hidden="true"></i></span>
                                                <span *ngIf="leaveApproval.statusId==3" class="status-icon"><i
                                                        class="fa fa-thumbs-down rejected"
                                                        aria-hidden="true"></i></span>
                                                <span *ngIf="leaveApproval.statusId==4" class="status-icon"><i
                                                        class="fa fa-times-circle cancelled"
                                                        aria-hidden="true"></i></span>
                                            </ng-template>


                                            <!-- <option [value]="1">Submited To</option>
                                                                                                                           <option [value]="2">Department Head of Login User</option>
                                                                                                                           <option [value]="3">Approver based on Role</option>
                                                                                                                           <option [value]="4">Department Head</option>
                                                                                                                           <option [value]="5">Department Members</option>
                                                                                                                           <option [value]="6">Project Managers</option>
                                                                                                                           <option [value]="8">Employee</option>
                                                                                                                           <option [value]="9">Client</option>
                                                                                                                           <option [value]="10">Project Admins</option>
                                                                                                                           <option [value]="11">Reporting Manager</option> -->

                                        </div>

                                        <!-- <div *ngIf="((leaveApproval.type==1 && (leaveApproval.approverGroup==6 || leaveApproval.approverGroup==10));else loggedOut )">

                                        </div> -->
                                        <!-- <ng-template #loggedOut>
                                            <span>{{leaveApproval.status}}</span>
                                            <span *ngIf="leaveApproval.statusId==1" class="status-icon"><i class="fa fa-filter pending" aria-hidden="true"></i></span>
                                            <span *ngIf="leaveApproval.statusId==2" class="status-icon"><i class="fa fa-thumbs-up approved"  aria-hidden="true"></i></span>
                                            <span *ngIf="leaveApproval.statusId==3" class="status-icon"><i class="fa fa-thumbs-down rejected" aria-hidden="true"></i></span>
                                            <span *ngIf="leaveApproval.statusId==4" class="status-icon"><i class="fa fa-times-circle cancelled" aria-hidden="true"></i></span>
                                            </ng-template> -->

                                        <ng-container
                                            *ngIf="leaveDetails.statusId != 1 && leaveApproval.statusId == 1 && leaveApproval.approvedBy == loggedUserId">
                                            <span>&nbsp; Leave approved based on approval settings.</span>
                                        </ng-container>

                                        <div>
                                            <table class="table"
                                                style="margin-bottom: 5px;padding: 10px 0 0 !important;display: inline-block;">
                                                <tr>
                                                    <td width="40">
                                                        <span>
                                                            <img class="img-circle img-sm"
                                                                src="./../../../assets/img/avatar5.png"
                                                                alt="User Image">
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <div class="col-sm-8" style="    padding-bottom: 8px;">
                                                            <strong>
                                                                <span style="color:#858481;margin-left: 10px;">{{
                                                                    leaveApproval.approverEmpCode }}</span>
                                                                <span> - </span>
                                                                <span>{{ leaveApproval.approverName }}</span>
                                                            </strong>
                                                        </div>
                                                        <div *ngIf="leaveApproval.type==1 " class="col-sm-4"
                                                            style="    padding-bottom: 8px;">
                                                            Approver
                                                        </div>
                                                        <div *ngIf="((leaveApproval.type==0) && (leaveApproval.approverGroup==6 || leaveApproval.approverGroup==10))"
                                                            class="col-sm-4" style="    padding-bottom: 8px;">
                                                            Informer


                                                        </div>

                                                        <div class="col-sm-12" style="    padding-bottom: 8px;">
                                                            <strong>
                                                                <span
                                                                    style="color:#858481;margin-left: 10px;">Email</span>
                                                                <span> - </span>
                                                                <span>{{ leaveApproval.approverEmail }}</span>
                                                            </strong>
                                                        </div>

                                                        <div class="col-sm-12" style="    padding-bottom: 8px;">
                                                            <strong style="display: -webkit-box;">
                                                                <span style="color:#858481;margin-left: 10px;">Approver
                                                                    authority</span>
                                                                <span> - </span>
                                                                {{leaveApproval.approverGroupName}}
                                                                <!-- <div [ngSwitch]="leaveApproval.approverGroup">
                                                                    <div *ngSwitchCase="'0'"> Admin</div>
                                                                    <div *ngSwitchCase="'1'">Submited To</div>
                                                                    <div *ngSwitchCase="'2'">Department Head of Login
                                                                        User</div>
                                                                    <div *ngSwitchCase="'3'">Approver based on Role
                                                                    </div>
                                                                    <div *ngSwitchCase="'4'">Department Head</div>
                                                                    <div *ngSwitchCase="'5'">Department Members</div>
                                                                    <div *ngSwitchCase="'6'">Project Managers</div>

                                                                    <div *ngSwitchCase="'8'">Direct Approval</div>
                                                                    <div *ngSwitchCase="'9'">Client</div>
                                                                    <div *ngSwitchCase="'10'">Project Admins</div>
                                                                    <div *ngSwitchCase="'31'">Transferred from  {{leaveApproval.approverName}}</div>
                                                                    <div *ngSwitchDefault>Reporting Manager</div>
                                                                </div> -->
                                                            </strong>
                                                        </div>

                                                        <div *ngIf="leaveApproval.projectname" class="col-sm-8"
                                                            style="    padding-bottom: 8px;">
                                                            <strong>
                                                                <span
                                                                    style="color:#858481;margin-left: 10px;">Project</span>
                                                                <span> - </span>
                                                                <span>

                                                                    <a data-toggle="modal" style="pointer-events:all ;"
                                                                        data-target="#myModal24"
                                                                        (click)="getproject(1,leaveApproval)"
                                                                        title="View Project Details">
                                                                        <img src="/assets/img/Logo/44.png"
                                                                            class="memimg"
                                                                            style="padding: 5px;max-width: 27px;">
                                                                    </a>
                                                                </span>
                                                            </strong>
                                                        </div>


                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div
                                            *ngIf="leaveApproval.approverGroup==6 || leaveApproval.approverGroup==10; else loggedOut">
                                            <ng-container *ngIf="leaveApproval.statusId == 1 || leaveApproval.type==1 ">
                                                <div class="row"
                                                    *ngIf="leaveApproval.statusId == 1 && leaveApproval.approvedBy == loggedUserId">
                                                    <div class="col-sm-12">
                                                        <textarea type="text" class="form-control"
                                                            style="width: 100%; height: auto;" rows="2"
                                                            [(ngModel)]="requestApprovalComment"
                                                            placeholder="Write a comment.."></textarea>
                                                    </div>
                                                    <div class="col-sm-12"
                                                        *ngIf="isCancelReasonMandatory && isLeaveReasonTextboxEmpty">
                                                        <span class="text-danger">This field is mandatory</span>
                                                    </div>
                                                    <div *ngIf="roleid != 11" class="col-sm-12" style="margin-top: 10px;">
                                                        <button type="button" class="btn btn-success"
                                                            (click)="processLeaveApproval(leaveApproval.leaveId, 2)">Approve</button>
                                                        <button type="button" class="btn btn-danger btn-outline"
                                                            (click)="processLeaveApproval(leaveApproval.leaveId, 3)">Reject</button>
                                                        <button type="button" class="close" data-dismiss="modal"
                                                            class="btn btn-success"
                                                            (click)="processLeaveApprovalAll(leaveDetails.leaveId )">Approve
                                                            All Level</button>

                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                        <ng-template #loggedOut>
                                            <ng-container *ngIf="leaveApproval.statusId == 1 ">
                                                <div class="row"
                                                    *ngIf="leaveApproval.statusId == 1 && leaveApproval.approvedBy == loggedUserId">
                                                    <div class="col-sm-12">
                                                        <textarea type="text" class="form-control"
                                                            style="width: 100%; height: auto;" rows="2"
                                                            [(ngModel)]="requestApprovalComment"
                                                            placeholder="Write a comment.."></textarea>
                                                    </div>
                                                    <div class="col-sm-12"
                                                        *ngIf="isCancelReasonMandatory && isLeaveReasonTextboxEmpty">
                                                        <span class="text-danger">This field is mandatory</span>
                                                    </div>
                                                    <div *ngIf="roleid != 11" class="col-sm-12" style="margin-top: 10px;">
                                                        <button type="button" class="btn btn-success"
                                                            (click)="processLeaveApproval(leaveApproval.leaveId, 2)">Approve</button>
                                                        <button type="button" class="btn btn-danger btn-outline"
                                                            (click)="processLeaveApproval(leaveApproval.leaveId, 3)">Reject</button>
                                                        <button type="button" class="close" data-dismiss="modal"
                                                            class="btn btn-success"
                                                            (click)="processLeaveApprovalAll(leaveDetails.leaveId )">Approve
                                                            All Level</button>

                                                    </div>
                                                </div>
                                            </ng-container>
                                        </ng-template>
                                        <!-- <ng-container *ngIf="leaveApproval.isCurrentWorkflow == true"> -->

                                        <!-- <ng-container *ngIf="leaveApproval.isCurrentWorkflow == false">
                                            <div class="row" *ngIf="leaveApproval.statusId == 1 && leaveApproval.approvedBy == loggedUserId">
                                                <div class="col-sm-12">
                                                    <textarea type="text" class="form-control" style="width: 100%; height: auto;" rows="2" [(ngModel)]="requestApprovalComment" placeholder="Write a comment.." disabled></textarea>
                                                </div>
                                            </div>
                                        </ng-container> -->
                                        <div
                                            *ngIf="((leaveApproval.type==1) && (leaveApproval.approverGroup==6 || leaveApproval.approverGroup==10))">
                                            <div class="row" *ngIf="leaveApproval.statusId != 1">
                                                <div class="col-sm-12">
                                                    <label>Comment:</label>&nbsp; <span>{{ leaveApproval.comment
                                                        }}</span>
                                                </div>
                                            </div>

                                        </div>

                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="row"
                        *ngIf="canPostApproveRequest && (leaveDetails.statusId == 2 || leaveDetails.statusId == 3)" style="padding-left: 50px;">
                        <div class="col-sm-12" *ngIf="leaveDetails.postApprovalComment"
                            style="border-top: 1px solid #eee; padding-top: 10px;">
                            <label>Post Approval Comment:</label>&nbsp; <span>{{ leaveDetails.postApprovalComment
                                }}</span>
                        </div>
                        <div class="col-sm-12" style="border-top: 1px solid #eee; padding-top: 10px;">
                            <textarea type="text" class="form-control" style="width: 100%; height: auto;" rows="2"
                                [(ngModel)]="requestApprovalComment" placeholder="Write a comment.."></textarea>
                        </div>
                        <div *ngIf="roleid != 11" class="col-sm-12" style="margin-top: 10px;">
                            <button type="button" class="close" data-dismiss="modal" class="btn btn-success"
                                (click)="processLeaveApproval(leaveDetails.leaveId, 2)">Approve</button>
                            <button type="button" class="close" data-dismiss="modal" class="btn btn-danger btn-outline"
                                (click)="processLeaveApproval(leaveDetails.leaveId, 3)">Reject</button>
                            <button type="button" class="close" data-dismiss="modal" class="btn btn-success"
                                (click)="processLeaveApprovalAll(leaveDetails.leaveId )">Approve All Level</button>
                        </div>
                    </div>
                    <div *ngIf="roleid=='1'" class="row">

                        <div *ngIf="disbutton==true" class="col-sm-12">
                            <div class="request-approval-timeline">
                                <ul class="timeline">
                                    <li style="margin: -31px 0 0;">
                                        <table class="table" style="    padding-top: 10px; display: inline-block;">
                                            <tr>
                                                <td width="40">
                                                    <span><img class="img-circle img-sm"
                                                            src="./../../../assets/img/avatar5.png"></span>
                                                </td>
                                                <td>
                                                    <div class="col-sm-8">
                                                        <div class="col-sm-12">
                                                            Name - <b>{{name}}</b> </div>

                                                        <div class="col-sm-12">Approver authority - <b>Admin</b></div>
                                                    </div>

                                                    <div class="col-sm-4" style="padding-left: 24px;"> Approver </div>
                                                </td>
                                            </tr>
                                        </table>

                                    </li>
                                </ul>
                            </div>
                        </div>





                    </div>
                    <div *ngIf="roleid=='1'" class="row" style="padding-left: 50px;">
                        <div *ngIf="disbutton==true" class="col-sm-12"
                            style="border-top: 1px solid #eee; padding-top: 10px;">
                            <textarea type="text" class="form-control" style="width: 100%; height: auto;" rows="2"
                                [(ngModel)]="requestApprovalComment" placeholder="Write a comment.."></textarea>
                        </div>
                        <div *ngIf="disbutton==true" class="col-sm-12" style="margin-top: 10px;">
                            <!-- <button type="button" class="close" data-dismiss="modal" class="btn btn-success" (click)="processLeaveApproval(leaveDetails.leaveId, 2)">Approve</button> -->
                            <button *ngIf="roleid != 11" type="button" class="close" data-dismiss="modal" class="btn btn-success"
                                (click)="processLeaveApprovalAll(leaveDetails.leaveId )">Approve All Level</button>

                            <button *ngIf="roleid != 11" type="button" class="close" data-dismiss="modal" class="btn btn-danger btn-outline"
                                (click)="processLeaveApproval(leaveDetails.leaveId, 3)">Reject</button>
                        </div>
                    </div>


                </div>

                <div *ngIf="compOffDetails != null">
                    <div class="row">
                        <div class="col-sm-12">
                            <span>
                                <img class="img-circle img-sm" src="./../../../assets/img/avatar5.png" alt="User Image">
                            </span>
                            <strong>
                                <span style="color:#858481;margin-left: 10px;">{{ compOffDetails.empCode }}</span>
                                <span> - </span>
                                <span>{{ compOffDetails.employeeName }}</span>
                            </strong>
                            <span>'s request has been sent for approval</span>
                            <span style="display: block; margin-left: 40px; font-size: 11px;">{{
                                compOffDetails.createdDate | date:'medium' }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="request-approval-timeline">
                                <ul class="timeline">

                                    <li *ngFor="let compoffApproval of compOffApprovalList">
                                        <div class="col-md-12">
                                            <ng-container
                                                *ngIf="(compoffApproval.type==1 && (compoffApproval.approverGroup==6 || compoffApproval.approverGroup==10)) === 1;else second">

                                                <span>{{compoffApproval.status}}</span>
                                                <span *ngIf="compoffApproval.statusId==1" class="status-icon"><i
                                                        class="fa fa-filter pending" aria-hidden="true"></i></span>
                                                <span *ngIf="compoffApproval.statusId==2" class="status-icon"><i
                                                        class="fa fa-thumbs-up approved" aria-hidden="true"></i></span>
                                                <span *ngIf="compoffApproval.statusId==3" class="status-icon"><i
                                                        class="fa fa-thumbs-down rejected"
                                                        aria-hidden="true"></i></span>
                                                <span *ngIf="compoffApproval.statusId==4" class="status-icon"><i
                                                        class="fa fa-times-circle cancelled"
                                                        aria-hidden="true"></i></span>
                                            </ng-container>
                                            <ng-template #second>
                                                <span>{{compoffApproval.status}}</span>
                                                <span *ngIf="compoffApproval.statusId==1" class="status-icon"><i
                                                        class="fa fa-filter pending" aria-hidden="true"></i></span>
                                                <span *ngIf="compoffApproval.statusId==2" class="status-icon"><i
                                                        class="fa fa-thumbs-up approved" aria-hidden="true"></i></span>
                                                <span *ngIf="compoffApproval.statusId==3" class="status-icon"><i
                                                        class="fa fa-thumbs-down rejected"
                                                        aria-hidden="true"></i></span>
                                                <span *ngIf="compoffApproval.statusId==4" class="status-icon"><i
                                                        class="fa fa-times-circle cancelled"
                                                        aria-hidden="true"></i></span>
                                            </ng-template>

                                            <!-- <option [value]="1">Submited To</option>
                                                                                                                                                           <option [value]="2">Department Head of Login User</option>
                                                                                                                                                           <option [value]="3">Approver based on Role</option>
                                                                                                                                                           <option [value]="4">Department Head</option>
                                                                                                                                                           <option [value]="5">Department Members</option>
                                                                                                                                                           <option [value]="6">Project Managers</option>
                                                                                                                                                           <option [value]="8">Employee</option>
                                                                                                                                                           <option [value]="9">Client</option>
                                                                                                                                                           <option [value]="10">Project Admins</option>
                                                                                                                                                           <option [value]="11">Reporting Manager</option> -->

                                        </div>
                                        <!-- <div *ngIf="((compoffApproval.type==1) && (compoffApproval.approverGroup==6 || compoffApproval.approverGroup==10))"  >
                                            <span>{{compoffApproval.status}}</span>
                                            <span *ngIf="compoffApproval.statusId==1" class="status-icon"><i class="fa fa-filter pending" aria-hidden="true"></i></span>
                                            <span *ngIf="compoffApproval.statusId==2" class="status-icon"><i class="fa fa-thumbs-up approved"  aria-hidden="true"></i></span>
                                            <span *ngIf="compoffApproval.statusId==3" class="status-icon"><i class="fa fa-thumbs-down rejected" aria-hidden="true"></i></span>
                                            <span *ngIf="compoffApproval.statusId==4" class="status-icon"><i class="fa fa-times-circle cancelled" aria-hidden="true"></i></span>

                                        </div> -->

                                        <ng-container
                                            *ngIf="compOffDetails.statusId != 1 && compoffApproval.statusId == 1 && compoffApproval.approvedBy == loggedUserId">
                                            <span>&nbsp; Compensatory Off approved based on approval settings.</span>
                                        </ng-container>

                                        <div>
                                            <table class="table" style="margin-bottom: 5px;">
                                                <tr>
                                                    <td width="40">
                                                        <span>
                                                            <img class="img-circle img-sm"
                                                                src="./../../../assets/img/avatar5.png"
                                                                alt="User Image">
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <div class="col-sm-8 no-padding no-margin">
                                                            <strong>
                                                                <span style="color:#858481;margin-left: 10px;">{{
                                                                    compoffApproval.approverEmpCode }}</span>
                                                                <span> - </span>
                                                                <span>{{ compoffApproval.approverName }}</span>
                                                            </strong>
                                                        </div>

                                                        <div *ngIf="compoffApproval.type==1" class="col-sm-4"
                                                            style="    padding-bottom: 8px;">
                                                            Approver
                                                        </div>
                                                        <div *ngIf="((compoffApproval.type==0) && (compoffApproval.approverGroup==6 || compoffApproval.approverGroup==10))"
                                                            class="col-sm-4" style="    padding-bottom: 8px;">
                                                            Informer
                                                        </div>
                                                        <div class="col-sm-12 no-padding no-margin">

                                                            <strong>
                                                                <span
                                                                    style="color:#858481;margin-left: 10px;">Email</span>
                                                                <span> - </span>
                                                                <span>{{ compoffApproval.approverEmail }}</span>
                                                            </strong>
                                                        </div>
                                                        <div class="col-sm-12 no-padding no-margin">
                                                            <strong style="display: -webkit-box; padding-left: 10px;">
                                                                <span style="color:#858481;">Approver authority</span>
                                                                <span> - </span>
                                                                {{compoffApproval.approverGroupName}}
                                                                <!-- <div [ngSwitch]="compoffApproval.approverGroup">
                                                                    <div *ngSwitchCase="'0'">Admin</div>
                                                                    <div *ngSwitchCase="'1'">Submited To</div>
                                                                    <div *ngSwitchCase="'2'">Department Head of Login
                                                                        User</div>
                                                                    <div *ngSwitchCase="'3'">Approver based on Role
                                                                    </div>
                                                                    <div *ngSwitchCase="'4'">Department Head</div>
                                                                    <div *ngSwitchCase="'5'">Department Members</div>
                                                                    <div *ngSwitchCase="'6'">Project Managers</div>

                                                                    <div *ngSwitchCase="'8'">Direct Approval</div>
                                                                    <div *ngSwitchCase="'9'">Client</div>
                                                                    <div *ngSwitchCase="'31'">Transferred from  {{compoffApproval.approverName}}</div>

                                                                    <div *ngSwitchCase="'10'">Project Admins</div>
                                                                    <div *ngSwitchDefault>Reporting Manager</div>
                                                                </div> -->
                                                            </strong>
                                                        </div>

                                                        <div *ngIf="compoffApproval.projectname"
                                                            class="col-sm-12 no-padding no-margin">
                                                            <strong>
                                                                <span
                                                                    style="color:#858481;margin-left: 10px;">Project</span>
                                                                <span> - </span>
                                                                <span>
                                                                    <!-- {{ compoffApproval.projectname }} -->
                                                                    <a data-toggle="modal" style="pointer-events:all ;"
                                                                        data-target="#myModal24"
                                                                        (click)="getproject(2,compoffApproval)"
                                                                        title="View Project Details">
                                                                        <img src="/assets/img/Logo/44.png"
                                                                            class="memimg"
                                                                            style="padding: 5px;max-width: 27px;">
                                                                    </a>


                                                                </span>
                                                            </strong>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        <!-- <ng-container *ngIf="compoffApproval.isCurrentWorkflow == true"> -->
                                        <div
                                            *ngIf="compoffApproval.approverGroup==6 || compoffApproval.approverGroup==10; else loggedOut">
                                            <ng-container
                                                *ngIf="compoffApproval.statusId == 1 && compoffApproval.type==1 ">
                                                <div class="row"
                                                    *ngIf="compoffApproval.statusId == 1 && compoffApproval.approvedBy == loggedUserId">
                                                    <div class="col-sm-12">
                                                        <textarea type="text" class="form-control"
                                                            style="width: 100%; height: auto;" rows="2"
                                                            [(ngModel)]="requestApprovalComment"
                                                            placeholder="Write a comment.."></textarea>
                                                    </div>
                                                    <div class="col-sm-12"
                                                        *ngIf="isCancelReasonMandatory && isLeaveReasonTextboxEmpty">
                                                        <span class="text-danger">This field is mandatory</span>
                                                    </div>
                                                    <div *ngIf="roleid != 11" class="col-sm-12" style="margin-top: 10px;">
                                                        <button type="button" class="btn btn-success"
                                                            (click)="processCompOffApproval(compoffApproval.compOffId, 2)">Approve</button>
                                                        <button type="button" class="btn btn-danger btn-outline"
                                                            (click)="processCompOffApproval(compoffApproval.compOffId, 3)">Reject</button>
                                                        <button type="button" class="btn btn-success"
                                                            (click)="processComppoffApprovalAll(compoffApproval.compOffId)">Approve
                                                            All Level</button>

                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                        <ng-template #loggedOut>
                                            <ng-container *ngIf="compoffApproval.statusId == 1 ">
                                                <div class="row"
                                                    *ngIf="compoffApproval.statusId == 1 && compoffApproval.approvedBy == loggedUserId">
                                                    <div class="col-sm-12">
                                                        <textarea type="text" class="form-control"
                                                            style="width: 100%; height: auto;" rows="2"
                                                            [(ngModel)]="requestApprovalComment"
                                                            placeholder="Write a comment.."></textarea>
                                                    </div>
                                                    <div class="col-sm-12"
                                                        *ngIf="isCancelReasonMandatory && isLeaveReasonTextboxEmpty">
                                                        <span class="text-danger">This field is mandatory</span>
                                                    </div>
                                                    <div *ngIf="roleid != 11" class="col-sm-12" style="margin-top: 10px;">
                                                        <button type="button" class="btn btn-success"
                                                            (click)="processCompOffApproval(compoffApproval.compOffId, 2)">Approve</button>
                                                        <button type="button" class="btn btn-danger btn-outline"
                                                            (click)="processCompOffApproval(compoffApproval.compOffId, 3)">Reject</button>
                                                        <button type="button" class="btn btn-success"
                                                            (click)="processComppoffApprovalAll(compoffApproval.compOffId)">Approve
                                                            All Level</button>

                                                    </div>
                                                </div>
                                            </ng-container>

                                        </ng-template>

                                        <!-- <ng-container *ngIf="compoffApproval.isCurrentWorkflow == false">
                                            <div class="row" *ngIf="compoffApproval.statusId == 1 && compoffApproval.approvedBy == loggedUserId">
                                                <div class="col-sm-12">
                                                    <textarea type="text" class="form-control" style="width: 100%; height: auto;" rows="2" [(ngModel)]="requestApprovalComment" placeholder="Write a comment.." disabled></textarea>
                                                </div>
                                            </div>
                                        </ng-container> -->
                                        <div
                                            *ngIf="((compoffApproval.type==1) && (compoffApproval.approverGroup==6 || compoffApproval.approverGroup==10))">
                                            <div class="row" *ngIf="compoffApproval.statusId != 1">
                                                <div class="col-sm-12">
                                                    <label>Comment:</label>&nbsp; <span>{{ compoffApproval.comment
                                                        }}</span>
                                                </div>
                                            </div>

                                        </div>




                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="row"
                        *ngIf="canPostApproveRequest && (compOffDetails.statusId == 2 || compOffDetails.statusId == 3)"  style="padding-left: 50px;">
                        <div class="col-sm-12" style="border-top: 1px solid #eee; padding-top: 10px;">
                            <textarea type="text" class="form-control" style="width: 100%; height: auto;" rows="2"
                                [(ngModel)]="requestApprovalComment" placeholder="Write a comment.."></textarea>
                        </div>
                        <div *ngIf="roleid != 11" class="col-sm-12" style="margin-top: 10px;">
                            <button type="button" class="btn btn-success"
                                (click)="processCompOffApproval(compOffDetails.compOffId, 2)">Approve</button>
                            <button type="button" class="btn btn-danger btn-outline"
                                (click)="processCompOffApproval(compOffDetails.compOffId, 3)">Reject</button>
                            <button type="button" class="close" data-dismiss="modal" class="btn btn-success"
                                (click)="processComppoffApprovalAll(compOffDetails.compOffId)">Approve All Level</button>

                        </div>
                    </div>
                    <div *ngIf="roleid=='1'" class="row">

                        <div *ngIf="disbutton==true" class="col-sm-12">
                            <div class="request-approval-timeline">
                                <ul class="timeline">
                                    <li style="margin: -31px 0 0;">
                                        <table class="table" style="    padding-top: 10px; display: inline-block;">
                                            <tr>
                                                <td width="40">
                                                    <span><img class="img-circle img-sm"
                                                            src="./../../../assets/img/avatar5.png"></span>
                                                </td>
                                                <td>
                                                    <div class="col-sm-8">
                                                        <div class="col-sm-12">
                                                            Name - <b>{{name}}</b> </div>

                                                        <div class="col-sm-12">Approver authority - <b>Admin</b></div>
                                                    </div>

                                                    <div class="col-sm-4"> Approver </div>
                                                </td>
                                            </tr>
                                        </table>

                                    </li>
                                </ul>
                            </div>
                        </div>





                    </div>
                    <div *ngIf="roleid=='1'" class="row" style="padding-left: 50px;">
                        <div *ngIf="disbutton==true" class="col-sm-12"
                            style="border-top: 1px solid #eee; padding-top: 10px;">
                            <textarea type="text" class="form-control" style="width: 100%; height: auto;" rows="2"
                                [(ngModel)]="requestApprovalComment" placeholder="Write a comment.."></textarea>
                        </div>
                        <div *ngIf="disbutton==true" class="col-sm-12" style="margin-top: 10px;">
                            <!-- <button type="button" class="btn btn-success" (click)="processCompOffApproval(compOffDetails.compOffId, 2)">Approve</button> -->
                            <button *ngIf="roleid != 11" type="button" class="close" data-dismiss="modal" class="btn btn-success"
                            (click)="processComppoffApprovalAll(compOffDetails.compOffId)">Approve All Level</button>

                            <button *ngIf="roleid != 11" type="button" class="btn btn-danger btn-outline"
                                (click)="processCompOffApproval(compOffDetails.compOffId, 3)">Reject</button>

                        </div>
                    </div>
                </div>
                <!-- <div *ngIf="compOffDetails != null">
                    <div class="row">
                        <div class="col-sm-12">
                            <span>
                                <img class="img-circle img-sm" src="./../../../assets/img/avatar5.png" alt="User Image">
                            </span>
                            <strong>
                                <span style="color:#858481;margin-left: 10px;">{{ compOffDetails.empCode }}</span>
                                <span> - </span>
                                <span>{{ compOffDetails.employeeName }}</span>
                            </strong>
                            <span>'s request has been sent for approval</span>
                            <span style="display: block; margin-left: 40px; font-size: 11px;">{{ compOffDetails.createdDate | date:'medium' }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="request-approval-timeline">
                                <ul class="timeline">
                                    <li>
                                        <span>{{ compOffDetails.status }}</span>
                                        <div>
                                            <table class="table" style="margin-bottom: 5px;">
                                                <tr>
                                                    <td width="40">
                                                        <span>
                                                            <img class="img-circle img-sm" src="./../../../assets/img/avatar5.png" alt="User Image">
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <div class="col-sm-12 no-padding no-margin">
                                                            <strong>
                                                                <span style="color:#858481;margin-left: 10px;">{{ compOffDetails.reportingToEmpCode }}</span>
                                                                <span> - </span>
                                                                <span>{{ compOffDetails.reportingToName }}</span>
                                                            </strong>
                                                        </div>
                                                        <div class="col-sm-12">
                                                            <span>{{ compOffDetails.reportingToEmail }}</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div class="row" *ngIf="compOffDetails.statusId == 1 && compOffDetails.reportingToUserId == loggedUserId">
                                            <div class="col-sm-12">
                                                <textarea type="text" class="form-control" style="width: 100%; height: auto;" rows="2" [(ngModel)]="requestApprovalComment" placeholder="Write a comment.."></textarea>
                                            </div>
                                            <div class="col-sm-12" style="margin-top: 10px;">
                                                <button type="button" class="btn btn-success" (click)="processCompOffApproval(compOffDetails.compOffId, 2)">Approve</button>
                                                <button type="button" class="btn btn-danger btn-outline" (click)="processCompOffApproval(compOffDetails.compOffId, 3)">Reject</button>
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="compOffDetails.statusId != 1">
                                            <div class="col-sm-12">
                                                <label>Comment:</label>&nbsp; <span>{{ compOffDetails.comment }}</span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="canPostApproveRequest && (compOffDetails.statusId == 2 || compOffDetails.statusId == 3)">
                        <div class="col-sm-12" style="border-top: 1px solid #eee; padding-top: 10px;">
                            <textarea type="text" class="form-control" style="width: 100%; height: auto;" rows="2" [(ngModel)]="requestApprovalComment" placeholder="Write a comment.."></textarea>
                        </div>
                        <div class="col-sm-12" style="margin-top: 10px;">
                            <button type="button" class="btn btn-success" (click)="processCompOffApproval(compOffDetails.compOffId, 2)">Approve</button>
                            <button type="button" class="btn btn-danger btn-outline" (click)="processCompOffApproval(compOffDetails.compOffId, 3)">Reject</button>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>

<div id="bulkRequestsApprovalModal" class="modal fade" role="dialog">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <div class="modal-header">
                <button class="close" data-dismiss="modal" type="button">×</button>
                <h4 class="modal-title">Approval Timeline</h4>
            </div>
            <div class="modal-body">
                <div class="row mar-bot-10">
                    <div class="col-sm-12">
                        <div class="bulk-approval-table">
                            <table class="table table-bordered user-table">
                                <thead>
                                    <tr>
                                        <ng-container>
                                            <th class="tableHeader">
                                                <p class="no-padding no-margin">Employee</p>
                                            </th>
                                            <th class="tableHeader">
                                                <p class="no-padding no-margin">Date of request</p>
                                            </th>
                                            <th class="tableHeader">
                                                <p class="no-padding no-margin">Request type</p>
                                            </th>
                                        </ng-container>
                                    </tr>
                                </thead>
                                <tbody *ngFor="let request of myApprovalsList">
                                    <tr *ngIf="request.isSelected == true && request.statusId == 1">
                                        <td class="text-left">
                                            <strong><span style="margin-left: 10px;">{{
                                                    request.employeeName}}</span></strong>
                                            <span>&nbsp;-&nbsp;</span>
                                            <span>{{ request.empCode }}</span>
                                        </td>
                                        <td class="text-center">{{ request.createdDate | date }}</td>
                                        <td class="text-center">{{ request.requestType }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="col-sm-12">
                            <textarea type="text" class="form-control" rows="2" [(ngModel)]="requestBulkApprovalComment"
                                id="txtComment" placeholder="Write a comment.."></textarea>
                        </div>
                        <div *ngIf="roleid != 11" class="col-sm-12" style="margin-top: 10px;">
                            <button type="button" class="btn btn-success"
                                (click)="processBulkRequestsApproval(2)">Approve</button>
                            <button type="button" class="btn btn-danger btn-outline"
                                (click)="processBulkRequestsApproval(3)">Reject</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="userMessageModel" class="modal fade" role="dialog">
    <div class="modal-dialog modal-confirm modal-sm">
        <div class="modal-content" style="border-radius: 10px; top: 150px; left: 350px;">
            <div class="modal-header success-header">
                <div class="icon-box" *ngIf="showSuccess">
                    <i class="fa fa-check"></i>
                </div>
                <div class="icon-box-remove" *ngIf="!showSuccess">
                    <i class="fa fa-remove"></i>
                </div>
            </div>
            <div class="modal-body">
                <p class="text-center">{{ showMessage }}</p>
            </div>
            <div class="modal-footer">
                <button class="btn btn-success btn-block" (click)="closeAllModals()">OK</button>
            </div>
        </div>
    </div>
</div>




<!-- Modal -->
<div class="modal fade small-filter" id="exampleModalfilter" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Filters</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" style="width: 100%; display: inline-block;padding: 10px 0 0;">
                <div class="col-md-12 col-sm-12 form-group">
                    <ng-select [allowClear]="true" (removed)="removed('Employee')" [(active)]="ActiveEmployee"
                        [(items)]="EmployeeList" (selected)="ddlSelection($event, 'Employee')"
                        placeholder="Select Employee">
                    </ng-select>
                </div>
                <div class="col-md-12 col-sm-12 form-group">
                    <select class="form-control" [ngModel]="myApprovalsFilterRequestType"
                        (ngModelChange)="filterRequestTypeChange($event, 1)">
                        <option selected="selected" [value]="-1">All Request Types</option>
                        <option [value]="1">Leaves</option>
                        <option [value]="2">Compensatory Off</option>
                    </select>
                </div>
                <div class="col-md-12 col-sm-12 form-group">
                    <select class="form-control" [ngModel]="myApprovalsFilterStatusId"
                        (ngModelChange)="filterStatusChange($event, 1)">
                        <option selected="selected" [value]="-1">All Requests</option>
                        <option [value]="1">Pending</option>
                        <option [value]="2">Approved</option>
                        <option [value]="3">Rejected</option>
                        <option [value]="4">Cancelled</option>
                    </select>
                </div>

                <div class="col-sm-3 col-xs-6 form-group">
                    <input type="button" value="Search" class="btn btn-primary btn-brand">
                </div>

                <div class="col-sm-3 col-xs-6 form-group">
                    <input type="button" value="Reset" class="btn btn-primary btn-brand reset">
                </div>
            </div>
        </div>
    </div>
</div>

<!--
<div class="modal fade" id="infoLeave" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document" style="width: 600px;">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Report Advance Information</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <div class="col-md-4"><label>Employee Name</label>
              <span class="infovalue">{{leaveDetails?.employeeName}}</span>
            </div>

            <div class="col-md-4"><label>Leave Type</label>
              <span class="infovalue">{{leaveDetails.leaveTypeName}}
             </span>
            </div>

            <div class="col-md-4"><label>Date</label>
              <span class="infovalue">{{leaveDetails.leaveStartDate}}-{{leaveDetails.leaveEndDate}}
            </span>
            </div>

          </div>

        </div>





      </div>
    </div>
  </div> -->
<div class="modal fade" id="myModal24" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document" style="width: 500px;">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Project List</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" style="width: 100%; display: inline-block;">

                <ejs-grid #overviewgrid id='overviewgrid' [dataSource]='projectList' rowHeight='38'
                    [allowSelection]='true' [allowSorting]='true' height='600' [enableHover]='false'
                    [selectionSettings]='selectionSettings' [filterSettings]='filterSettings' [allowSorting]='true'
                    [allowFiltering]='true' [enableVirtualization]='false' (load)='load()' [allowPaging]='true'
                    [pageSettings]='pageSettings'>
                    <e-columns>
                        <e-column field='projectname' headerText='Project Name' [filter]='filter'>
                            <ng-template #template let-data>
                                <!-- <a [routerLink]="['/paSystem/Masters/projects/'+data.projectid]" > -->
                                <span>
                                    {{data.projectname}}
                                </span>
                                <!-- </a> -->
                            </ng-template>
                        </e-column>
                        <e-column field='approvaltype' headerText='Approval Type' [filter]='filter'>
                            <ng-template #template let-data>
                                <!-- <a [routerLink]="['/paSystem/Masters/projects/'+data.projectid]" > -->
                                <span>
                                    {{data.approvaltype}}
                                </span>
                                <!-- </a> -->
                            </ng-template>
                        </e-column>
                    </e-columns>
                </ejs-grid>
            </div>
        </div>
    </div>
</div>
<div id="myModal245" class="modal fade" role="dialog" style="overflow-y: auto;" data-backdrop="static">
    <div class="modal-dialog" style="width: 900px;">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Project</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <ul class="project-teams">


                    <li *ngFor="let admin of projectList;  let i=index">


                        <div class="infos">
                            <div style="font-weight: 600; font-size: 15px; color: #393938;"><img
                                    src="../assets/img/Logo/19.png" style="margin-right: 2px;
                  margin-left: -2px;">
                                <span title="{{admin.projectname}}">{{admin.projectname}}</span>
                            </div>
                        </div>


                    </li>



                </ul>


            </div>
        </div>
    </div>
</div>
<!-- Modal -->
<div class="modal fade small-filter" id="exampleModalfilter2" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Filters</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" style="width: 100%; display: inline-block;padding: 10px 0 0;">
                <div class="col-md-12 col-sm-4 form-group">
                    <select class="form-control" [ngModel]="myRequestsFilterRequestType"
                        (ngModelChange)="filterRequestTypeChange($event, 2)">
                        <option selected="selected" [value]="-1">All Request Types</option>
                        <option [value]="1">Leaves</option>
                        <option [value]="2">Compensatory Off</option>
                    </select>
                </div>
                <div class="col-md-12 col-sm-4 form-group">
                    <select class="form-control" [ngModel]="myRequestsFilterStatusId"
                        (ngModelChange)="filterStatusChange($event, 2)">
                        <option selected="selected" [value]="-1">All Requests</option>
                        <option [value]="1">Pending</option>
                        <option [value]="2">Approved</option>
                        <option [value]="3">Rejected</option>
                        <option [value]="4">Cancelled</option>
                    </select>
                </div>

                <div class="col-sm-3 col-xs-6 form-group">
                    <input type="button" value="Search" class="btn btn-primary btn-brand">
                </div>
                <div class="col-sm-3 col-xs-6 form-group">
                    <input type="button" value="Reset" class="btn btn-primary btn-brand reset">
                </div>
            </div>
        </div>
    </div>
</div>
