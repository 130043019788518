<mk-box header="" [isCollapsable]="false" [isRemovable]="false" contentStyleClasses="table-responsive">
  <div class="row" style="margin: 0px;">

    <!--<div class="col-sm-4 text-right">
      <button (click)="logText(1)"  #loginRef >Sync with google</button>
    </div>-->

    <!--<div class="col-md-4" id="googleBtn" >
      <input type="text" class="form-control" [(ngModel)]="filterHoliday" (keydown.enter)="Search()"
       placeholder="Title">
    </div>-->
    <!--<div class="col-sm-11 text-right">-->
    <div class="col-md-6 no-padding">
      <h4 class="title-new">Client Holiday List</h4>
    </div>
    <div class="col-sm-6 text-right btnset" style="padding-top: 46px;">

      <a href='javascript:void(0);' (click)="newHoliday()" class="btn btn-primary btn-brand" title="Add">
        <span>
          <img src="../assets/img/Logo/20.png">

        </span>
      </a>

<div class="btn-group">
  <button type="button" class="btn btn-primary btn-brand" data-toggle="dropdown" aria-haspopup="true"
    aria-expanded="false" title="Export">
    <img src="../assets/img/Logo/22.png">
  </button>
  <ul class="dropdown-menu">
    <li>
      <a (click)="export()">Excel</a>
    </li>
    <li>
      <a (click)="printList('tableData')">Print</a>
    </li>
    <li>
      <a (click)="exportTableToCSV('Client_Holiday_List.csv')">CSV</a>
    </li>

  </ul>
</div>
    <!-- <div class="btn-group">
      <button type="button" class="btn btn-primary btn-brand" data-toggle="modal" data-target="#exampleModalfilter2" title="Filters" (click)="filterShow()">
        <img src="../assets/img/Logo/23.png">
      </button>
    </div> -->



      <!-- <div class="btn-group">
      <button type="button" class="btn btn-primary btn-brand" (click)="getAllLocations()"title="Reset Filter">
        <img src="../assets/img/Logo/28.png">
      </button>
    </div> -->


      <!-- <div class="btn-group">
    <ul class="paggingUl" style="top: 7px;">
      <ngb-pagination [collectionSize]="myRequestsListLength" [(page)]="myRequestsPage"
        [pageSize]="myRequestsPageSize"></ngb-pagination>
      <li>
        <span class="recordsMsg">{{myRequestsPage}}-{{myRequestsPageSize}} of {{myRequestsListLength}}</span>

      </li>
    </ul>
</div> -->

    </div>
  </div>

  <div class="col-sm-12 col-xs-12 form-group no-padding">
    <div class="col-sm-12 col-xs-12 less form-group" id="tableData">

      <!-- Table -->
      <!-- <table class="table table-bordered table-hover">
        <thead>
          <tr>
            <th class="text-center"> Name</th>
            <th class="text-center">Date</th>
            <th class="text-center">Location</th>

            <th class="text-center"> Description</th>
            <th class="text-center">Actions</th>
          </tr>
        </thead>
        <tbody>

          <tr
            *ngFor="let holiday of holidays | slice: (myRequestsPage-1) * myRequestsPageSize : (myRequestsPage-1) * myRequestsPageSize + myRequestsPageSize | filter:'HolidayName':searchString; let i = index;">
            <td>{{holiday.HolidayTitle}}</td>
            <td>{{dateformat(holiday.HolidayStartDate)}}</td>
            <td>
              <ng-container *ngIf="holiday.LocationName != null && holiday.LocationName != undefined">
                {{holiday.LocationName}}
              </ng-container>
              <ng-container *ngIf="holiday.LocationName == null || holiday.LocationName == undefined">
                All Locations
              </ng-container>

            </td>
            <td>{{holiday.Description}}</td>
            <td>
              <a href="javascript:void(0)" (click)="viewHoliday(holiday)" class="view" title="Details">
                <img src="../assets/img/Logo/25.png">
              </a>
              <a href="javascript:void(0)" class="edit" title="Edit" (click)="editHoliday(holiday)">
                <img src="../assets/img/Logo/26.png">
              </a>
              <a href="javascript:void(0)" class="delete" title="Delete" (click)="deleteHoliday(holiday)">
                <img src="../assets/img/Logo/40.png">
              </a>
            </td>
          </tr>

          <ng-template [ngIf]="!holidays?.length">
            <tr>
              <td colspan="12" class="text-center">
                <img src="../assets/img/Logo/task.png">
                <span class="emptyMsg"> No Record(s) Found</span>
              </td>
            </tr>
          </ng-template>
        </tbody>
      </table> -->


      <ejs-grid #overviewgrid id='overviewgrid' [dataSource]='holidays' rowHeight='38' [allowSelection]='true'
      [allowSorting]='true' height='600' [enableHover]='false' [selectionSettings]='selectionSettings'
      [filterSettings]='filterSettings' [allowSorting]='true' [allowFiltering]='true' [enableVirtualization]='false'
      (load)='load()' [allowPaging]='true' [pageSettings]='pageSettings'  [allowExcelExport]='true'>
      <e-columns>
        <!-- <e-column field='HolidayTitle' headerText='Client Name' [filter]='filter' clipMode='EllipsisWithTooltip'>

        </e-column> -->
          <e-column field='holidayTitle' headerText='Holiday Name' [filter]='filter' clipMode='EllipsisWithTooltip'>

          </e-column>

          <e-column field='holidayStartDate' headerText='Start Date' [filter]='filter' clipMode='EllipsisWithTooltip'>

            <ng-template #template let-holiday>
              {{dateformat(holiday.holidayStartDate)}}
            </ng-template>

          </e-column>

          <e-column field='holidayEndDate' headerText='End Date' [filter]='filter' clipMode='EllipsisWithTooltip'>

            <ng-template #template let-holiday>
              {{dateformat(holiday.holidayEndDate)}}
            </ng-template>

          </e-column>
          <!-- <e-column field='LocationName' headerText='Location' [filter]='filter' clipMode='EllipsisWithTooltip'>
          <ng-template #template let-holiday>
            <ng-container *ngIf="holiday.LocationName != null && holiday.LocationName != undefined">
              {{holiday.LocationName}}
            </ng-container>
            <ng-container *ngIf="holiday.LocationName == null || holiday.LocationName == undefined">
              All Locations
            </ng-container>
          </ng-template>
        </e-column> -->


          <e-column field='description' headerText='Description' [filter]='filter' clipMode='EllipsisWithTooltip'>

          </e-column>

          <!-- <e-column field='Action' [allowFiltering]='false' headerText='Actions' [allowSorting]='false' textAlign='Center'>
          <ng-template #template let-holiday>
            <a href="javascript:void(0)" (click)="viewHoliday(holiday)" class="view" title="Details">
              <img src="../assets/img/Logo/25.png">
            </a>
            <a href="javascript:void(0)" class="edit" title="Edit" (click)="editHoliday(holiday)">
              <img src="../assets/img/Logo/26.png">
            </a>
            <a href="javascript:void(0)" class="delete" title="Delete" (click)="deleteHoliday(holiday)">
              <img src="../assets/img/Logo/40.png">
            </a>
          </ng-template>
        </e-column> -->

          <e-column field='Action' [allowFiltering]='false' headerText='Actions' [allowSorting]='false'
            textAlign='Center' width='100px'>
            <ng-template #template let-holiday>
              <div class="btn-group btngg">
                <button type="button" class="btn action-button" data-toggle="dropdown" aria-haspopup="true"
                  aria-expanded="false" title="Action">
                  <span style="margin-top: -16px;">... </span>
                </button>
                <ul class="dropdown-menu actions-dropdown">
                  <li>
                    <a href="javascript:void(0)" (click)="viewHoliday(holiday)">View
                    </a>
                  </li>

                  <li> <a href="javascript:void(0)" (click)="editHoliday(holiday)">Edit
                    </a>

                  </li>

                  <li><a href="javascript:void(0)" (click)="deleteHoliday(holiday)">Delete
                    </a></li>

                </ul>
              </div>
            </ng-template>
          </e-column>


        </e-columns>
      </ejs-grid>




    </div>
  </div>

  <!-- Filter Popup
 <div class="btn-group">
  <div class="container datashow rt-filters" *ngIf="flterShowData">
    <div class="card">
      <div class="card card-body">
        <div>
          <div class="row row-bor-bot">
            <div class="col-xs-9">
              <h4>Filter</h4>
            </div>
            <div class="col-xs-3 text-right">
              <h4 class="icon-close" (click)="closeFilter();">x</h4>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label for="depart">Period</label>
                <select class="form-control" name="filterPeriod" [(ngModel)]="filterPeriod"
                  (change)="onChangeFilterPeriod($event);">
                  <option value=1>Yesterday</option>
                  <option value=2>Today</option>
                  <option value=3>Last Week</option>
                  <option value=4>This Week</option>
                  <option value=5>Last Month</option>
                  <option value=6>This Month</option>
                  <option value=7>Custom</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label for="depart">From</label>
                <div class="input-group date">
                       <div class="input-group-addon">
                              <i class="fa fa-calendar"></i>
                       </div>
                    <input type="text" class="form-control" name="filterStartDate" [(ngModel)]="filterStartDate"
                    [attr.disabled]="filterPeriod != 7 ? 'true' : null"
                    placeholder="From" autocomplete="off" bsDatepicker style="margin-top: 0; margin-bottom: 0;"
                    [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', containerClass: 'theme-dark-blue', customTodayClass: 'custom-today-class', showWeekNumbers: false }"
                    autocomplete="off" (bsValueChange)="onDPStartDateChange($event)"
                    [maxDate]="maxDate" bsDatepicker id="dpStartDate" />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label for="depart">To</label>
                <div class="input-group date">
                  <div class="input-group-addon">
                    <i class="fa fa-calendar"></i>
                  </div>
                  <input type="text" class="form-control" name="filterEndDate" [(ngModel)]="filterEndDate"
                    [attr.disabled]="filterPeriod != 7 ? 'true' : null"
                    placeholder="From" autocomplete="off" bsDatepicker style="margin-top: 0; margin-bottom: 0;"
                    [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', containerClass: 'theme-dark-blue', customTodayClass: 'custom-today-class', showWeekNumbers: false }"
                    autocomplete="off" (bsValueChange)="onDPEndDateChange($event)"
                    [minDate]="minDate" bsDatepicker id="dpEndDate" />
                </div>
              </div>
            </div>
          </div> -->
  <!--  <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label for="depart">Department</label>
                <ng-select [allowClear]="true" [(active)]="ActiveDepartments" [(items)]="ddlDepartmentList"
                  (selected)="ddlSelection($event, 'Department')" placeholder="All Department"
                  #ddlFilterDepartments (opened)="closeDropdownList(ddlFilterDepartments)"
                  (removed)="removeFilterDropdownValue($event, 'Department')">
                </ng-select>
              </div>
            </div>
          </div> -->
  <!--  <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label for="location">Location</label>
                <ng-select [allowClear]="true" [(active)]="ActiveDesignations" [(items)]="ddlDesignationList"
                  (selected)="ddlSelection($event, 'Location')" placeholder="All Location"
                  #ddlFilterLocations (opened)="closeDropdownList(ddlFilterLocations)"
                  (removed)="removeFilterDropdownValue($event, 'Location')">
                </ng-select>
              </div>
            </div>
          </div> -->
  <!--  <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label for="desigation" >Desigation</label>
                <ng-select [allowClear]="true" [(active)]="ActiveLocations" [(items)]="ddlLocationList"
                  (selected)="ddlSelection($event, 'Desigation')" placeholder="All Desigation"
                  #ddlFilterDesigations (opened)="closeDropdownList(ddlFilterDesigations)"
                  (removed)="removeFilterDropdownValue($event, 'Desigation')">
                </ng-select>
              </div>
            </div>
          </div> -->
  <!-- <div class="row">
            <div class="col-sm-12">
              <button type="button" (click)="processFilters()" class="btn btn-primary btn-brand">Search</button>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <button type="button" (click)="resetFilters()" class="btn btn-default">Reset</button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
</div>
</div> -->


  <!--Add Holiday-->
  <div id="addHoliday" class="modal fade" role="dialog">
    <div class="modal-dialog modal-lg" style="width: 650px;">
      <div class="modal-content">
        <div class="modal-header">
          <button _ngcontent-c11="" id="closemodel" class="close" data-dismiss="modal" type="button">
            ×
          </button>
          <h4 class="modal-title">{{showHoliday}} Client Holiday</h4>
        </div>




        <form name="addMeetingMinutes" [formGroup]="holidayForm" autocomplete="off" #u="ngForm" novalidate>
          <div class="modal-body model-bg user-modal-height col-xs-12 col-sm-12 less"
            style="background-color: #fff;padding-top: 20px;border-radius: 0px 0px 10px 10px;">
            <div class="col-xs-12 col-sm-12">
              <div class="m-portlet__body">
                <input type="hidden" class="e-input" formControlName="Id">

                <div class="form-group row">
                  <div class="col-md-4 no-padding">
                    <div class="col-xs-12 text-left">
                      <label class="control-label">Holiday Name</label>&nbsp;
                      <span class="text-danger">*</span>
                    </div>
                    <div class="col-xs-12">
                      <input type="text" id="holidayName" formControlName="holidayName" name="holidayName"
                        (change)="onHolidayNameChanged()" class="e-input txtclass" placeholder="Holiday Name" required />

                      <div *ngIf="requiredHolidayName != null && requiredHolidayName != undefined">
                        <span class="error">{{ requiredHolidayName }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4 no-padding">
                    <div class="col-xs-12 text-left">
                      <label class="control-label">Start Date</label>&nbsp;
                      <span class="text-danger">*</span>
                    </div>
                    <div class="col-xs-12">
                      <input type="date" *ngIf="this.isstartdate==1" class="e-input" #FileName id="FileName"
                        [(ngModel)]="projectDocumentForm.startdate" [ngModelOptions]="{standalone: true}"
                        placeholder="End Date" (click)="IsDateMinMaxAssignstart(projectDocumentForm)"
                        (change)="IsAssigndateChangestart(projectDocumentForm)" [max]="maxDate1" [min]="minDBDate"
                        [ngClass]="{ 'is-invalid': u.submitted && startdate.invalid }" #startdate="ngModel"
                        [disabled]="isView" required />
                      <!-- <div *ngIf="u.submitted && startdate.invalid" class="invalid-feedback">
                    <div *ngIf="startdate.errors.required">Expire date is required</div>
                  </div> -->


                      <input type="date" *ngIf="this.isstartdate==0" class="e-input" id="FileName"
                        [(ngModel)]="projectDocumentForm.startdates" [ngModelOptions]="{standalone: true}"
                        placeholder="End Dates" (click)="IsDateMinMaxAssignstartsetenddate(projectDocumentForm)"
                        (change)="IsAssigndateChangestartsetenddate(projectDocumentForm)"
                        [ngClass]="{ 'is-invalid': u.submitted && startdates.invalid }" #startdates="ngModel"
                        [disabled]="isView" required />
                      <!-- <div *ngIf="u.submitted && startdates.invalid" class="invalid-feedback">
                  <div *ngIf="startdates.errors.required">Expire date is required</div>
                </div> -->




                      <!-- <input type="text" id="holidayDate" class="e-input" autocomplete="off"
                      formControlName="holidayDate" name="holidayDate" [minDate]="currentDate" placeholder="Start Date"
                      [bsConfig]="{ showWeekNumbers:false, dateInputFormat: 'MM/DD/YYYY' }" [disabled]="isView"
                      (bsValueChange)="datepickerStartDate($event)"
                      bsDatepicker required /> -->


                      <div *ngIf="u.submitted && (requiredHolidayDate != null && requiredHolidayDate != undefined)">
                        <span class="error">{{ requiredHolidayDate }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4 no-padding">
                    <div class="col-xs-12 text-left">
                      <label class="control-label">End Date</label>&nbsp;
                      <span class="text-danger">*</span>
                    </div>
                    <div class="col-xs-12">
                      <input type="date" *ngIf="this.isstartdate==1" class="e-input" id="FileName"
                        [(ngModel)]="projectDocumentForm.enddate" [ngModelOptions]="{standalone: true}"
                        placeholder="End Date" [max]="maxDate1" [min]="minDBDate"
                        (click)="IsDateMinMaxAssign(projectDocumentForm)"
                        (change)="IsAssigndateChange(projectDocumentForm)"
                        [ngClass]="{ 'is-invalid': u.submitted && enddate.invalid }" #enddate="ngModel"
                        [disabled]="isView" required />
                      <!-- <div *ngIf="u.submitted && enddate.invalid" class="invalid-feedback">
                    <div *ngIf="enddate.errors.required">Expire date is required</div>
                  </div> -->


                      <input type="date" *ngIf="this.isstartdate==0" class="e-input" id="FileName"
                        [(ngModel)]="projectDocumentForm.enddates" [ngModelOptions]="{standalone: true}"
                        placeholder="End Date" [ngClass]="{ 'is-invalid': u.submitted && enddate.invalid }"
                        [min]="minDBDate" #enddate="ngModel" [disabled]="isView" required />
                      <!-- <span>{{validationError}}</span> -->

                      <!-- <div *ngIf="u.submitted && enddate.invalid" class="invalid-feedback">
                  <div *ngIf="enddate.errors.required">Expire date is required</div>
                </div> -->
                      <!-- <input type="date" id="endDate" class="e-input" autocomplete="off" formControlName="endDate"
                      name="endDate" [minDate]="startDate" placeholder="End Date"
                      [bsConfig]="{ showWeekNumbers:false, dateInputFormat: 'MM/DD/YYYY' }" [disabled]="isView"
                      (bsValueChange)="datepickerEndDate($event)"
                       /> -->

                    </div>
                    <div *ngIf="endDateInvalid" class="invalid-feedback error">
                      {{endDateInvalidMessage}}
                    </div>
                  </div>

                </div>

                <div class="form-group row">
                  <div class="col-xs-12 text-left"></div>
                  <div class="col-sm-12">
                    <table class="table">
                      <tr *ngFor="let item of HolidayCalendarDatesList; let i=index">
                        <td>
                          {{ HolidayCalendarDatesList[i].HolidayDate | date }}
                        </td>
                        <td>

                        </td>
                      </tr>
                    </table>
                  </div>
                </div>


                <div class="form-group row">
                  <div class="col-xs-12 text-left">
                    <label class="control-label">Internal Notes</label>
                  </div>
                  <div class="col-xs-12">
                    <textarea id="description" name="description" class="e-input txtclass" formControlName="Description"
                      placeholder="Write description here...">
                    </textarea>
                  </div>
                </div>



              </div>
            </div>
          </div>
          <div class="Modal-footer">
            <button id="btnSave" style="margin:10px 0 20px 25px;padding: 7px 30px !important;" type="submit"
              class="btn btn-primary btn-brand" *ngIf="isView == false" (click)="saveHoliday()"> Save </button>

          </div>
        </form>
      </div>
    </div>
  </div>

  <div id="MessageModel" class="modal  fade" role="dialog">
    <div class="modal-dialog modal-confirm modal-sm">
      <div class="modal-content" style="border-radius: 10px; top: 150px; left: 350px;">
        <div class="modal-header success-header">
          <button _ngcontent-icx-c7="" _ngcontent-c11="" aria-label="Close" class="close" data-dismiss="modal"
            type="button"><span _ngcontent-icx-c7="" _ngcontent-c11="" aria-hidden="true">×</span></button>
          <div class="icon-box" *ngIf="showSuccess">
            <i class="fa fa-check"></i>

          </div>
          <div class="icon-box-remove" *ngIf="!showSuccess">
            <i class="fa fa-remove"></i>
          </div>
        </div>
        <div class="modal-body">
          <p class="text-center">{{showMessage}}</p>
        </div>
        <div class="modal-footer" *ngIf="showSuccess || showWarning">
          <button class="btn btn-danger btn-block" data-dismiss="modal">Yes</button>
        </div>
        <div class="modal-footer" *ngIf="IsDelete" style="text-align: center !important;">
          <button class="btn btn-danger messagebutton" *ngIf="!isView" data-dismiss="modal"
            (click)="ConfirmDeleteAction(deleteId)">Yes</button>

        </div>
      </div>
    </div>
  </div>



  <!-- Modal -->
  <div class="modal fade small-filter" id="exampleModalfilter2" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Filters</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" style="width: 100%; display: inline-block;">
          <div class="btn-group" id="exampleModalfilter">
            <div class="container datashow rt-filters" *ngIf="flterShowData">
              <div class="card">
                <div class="card card-body">
                  <div>

                    <div class="row">
                      <div class="col-sm-12">
                        <div class="form-group">
                          <label for="depart">Period</label>
                          <ejs-dropdownlist [(ngModel)]="filterPeriod" [ngModelOptions]="{standalone: true}"
                            [(value)]='filterPeriod' [placeholder]='servicestatustext' [dataSource]='FilterPeriodList'
                            [fields]='clientHolidayField'>
                          </ejs-dropdownlist>
                          <!-- <select class="form-control" name="filterPeriod" [(ngModel)]="filterPeriod"
                            (change)="onChangeFilterPeriod($event)">
                            <option value=1>Yesterday</option>
                            <option value=2>Today</option>
                            <option value=3>Last Week</option>
                            <option value=4>This Week</option>
                            <option value=5>Last Month</option>
                            <option value=6>This Month</option>
                            <option value=7>Last Year</option>
                            <option value=8>This Year</option>
                            <option value=9>Custom</option>
                          </select> -->
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="form-group">
                          <label for="depart">From</label>
                          <div class="input-group date">
                            <div class="input-group-addon">
                              <i class="fa fa-calendar"></i>
                            </div>
                            <input type="text" class="form-control" name="filterStartDate" [(ngModel)]="filterStartDate"
                              [attr.disabled]="filterPeriod != 9 ? 'true' : null" placeholder="From" autocomplete="off"
                              bsDatepicker style="margin-top: 0; margin-bottom: 0;"
                              [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', containerClass: 'theme-dark-blue', customTodayClass: 'custom-today-class', showWeekNumbers: false }"
                              autocomplete="off" (bsValueChange)="onDPStartDateChange($event)" [maxDate]="maxDate"
                              bsDatepicker id="dpStartDate" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="form-group">
                          <label for="depart">To</label>
                          <div class="input-group date">
                            <div class="input-group-addon">
                              <i class="fa fa-calendar"></i>
                            </div>
                            <input type="text" class="form-control" name="filterEndDate" [(ngModel)]="filterEndDate"
                              [attr.disabled]="filterPeriod != 9 ? 'true' : null" placeholder="From" autocomplete="off"
                              bsDatepicker style="margin-top: 0; margin-bottom: 0;"
                              [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', containerClass: 'theme-dark-blue', customTodayClass: 'custom-today-class', showWeekNumbers: false }"
                              autocomplete="off" (bsValueChange)="onDPEndDateChange($event)" [minDate]="minDate"
                              bsDatepicker id="dpEndDate" />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-sm-12">
                        <div class="form-group">
                          <label for="location">Location</label>
                          <ng-select [allowClear]="true" [(active)]="ActiveDesignations" [(items)]="ddlLocationList"
                            (selected)="ddlSelection($event, 'Location')" placeholder="All Location" #ddlFilterLocations
                            (opened)="closeDropdownList(ddlFilterLocations)"
                            (removed)="removeFilterDropdownValue($event, 'Location')">
                          </ng-select>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-sm-12">
                        <button type="button" (click)="processFilters()" class="rfit btn-primary btn-brand"
                          style="border-radius: 5px !important;">Search</button>
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <button type="button" (click)="resetFilters()" class="rfit2 btn-default"
                          style="border-radius: 5px !important;">Reset</button>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>



  <!-- Modal -->
  <div class="modal fade" id="exampleModal3" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document" style="width: 400px;">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Import File</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <ejs-uploader #file (change)="onFileChangeImg($event)" allowedExtensions=".xlsx ,.xls" multiple="false">
          </ejs-uploader>
          <!-- <label for="myfile">Browse File</label>
        <input type="file" id="myfile" name="myfile" style="padding-left: 200px;"/> -->
          <div *ngIf="errorData?.length == 0" class="form-group col-sm-12">
            <div class="col-sm-12">
              <p class="importtext text-center"> View
                <a href="../assets/SampleImports/CHolidayList.xlsx" download> sample files</a> so that you can import files in a standard format.
              </p>
            </div>
          </div>
          <div *ngIf="errorData?.length > 0" >
            <span style="font-size: 17px; font-weight: bold;" class="importResultText">Results</span>
            <span *ngFor="let item of errorData" style="color:rgb(173, 42, 42)">{{item}}<br></span>
          </div>
          <div class="modal-footer errorbutton">
            <button data-dismiss="modal" class="btn btn-primary messagebutton" (click)="SaveImportExcelData()">OK</button>

          </div>

        </div>
      </div>
    </div>
  </div>
