import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicFormComponent } from './dynamic-form.component';
import { DynamicControlComponent } from './dynamic-control/dynamic-control.component';
import { DynamicControlService } from './dynamic-control.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SelectModule } from 'ng2-select';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SelectModule
  ],
  declarations: [DynamicFormComponent, DynamicControlComponent],
  providers: [DynamicControlService],
  exports: [DynamicFormComponent]
})
export class DynamicFormModule { }
