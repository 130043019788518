<div class="bg-overlady" *ngIf="userLoader">
  <div class="loader-position">
    <div class="loader"></div>
    <h2>Loading....</h2>
  </div>
</div> 
<mk-box header="" [isCollapsable]="false" [isRemovable]="false" contentStyleClasses="table-responsive">
  <div class="row" style="margin: 0px; border-bottom: 1px solid #ddd;">

    <div class="col-sm-6 no-padding">
      <h4 class="title-new">Customize Leave Balance</h4>
    </div>

 

  
    <div class="col-md-3 col-sm-6 pull-left" style="text-align: right; position: relative;top: 5px;">
      <label>Employee :</label>
    </div>
    <div class="col-md-3 col-sm-6">
      <ng-select [allowClear]="true"  [(active)]="ActiveEmployee" [(items)]="EmployeeList"
      (selected)="ddlSelection($event, 'Employee', 'manualy')" placeholder="Select Employee">
      </ng-select>
    </div>
    <!-- <div class="col-sm-2 pull-right">
      <button type="button" routerLink="/leave/setting/customize-leave-balance" class="btn btn-primary pull-right">
        <i class="fa fa-back" aria-hidden="true"></i> Back
      </button> 
    </div> -->
    
  
  </div>
  <div class="row">
    <form [formGroup]="updateLeaveBalanceForm" (ngSubmit)="updateLeaveBalance()">
  
    <div class="col-sm-12 no-padding">
      <table class="table table-bordered table-hover">
        <thead>
          <tr class="table-primary">
            <th scope="col" style="text-align: left;">Leave Type</th>
            <th scope="col" style="text-align: left;">Date</th>
            <th scope="col" style="text-align: left;">Existing Balance</th>
            <th scope="col" style="text-align: left;">New Balance</th>
            <th scope="col" style="text-align: left;">Reason</th>
          </tr> 
        </thead>
        <tbody style="text-align: left;"  formArrayName="LeaveTypeList">
          <ng-container *ngFor="let address of updateLeaveBalanceForm.get('LeaveTypeList')['controls']; let i = index;" [formGroupName]="i">
            <tr data-toggle="modal" data-backdrop="static" data-keyboard="false" data-target="#customizeleave"
              *ngIf="getLeaveBalcanceByEmplyeList[i] != undefined && getLeaveBalcanceByEmplyeList[i] != null">
              <td>
                <span> 
                  <i class="fa fa-circle" [ngStyle]="{ color:getLeaveBalcanceByEmplyeList[i].colorCode }"></i>
                </span>
                <span>
                  {{ getLeaveBalcanceByEmplyeList[i].leaveTypeName }}
                </span>
              </td>
              <td>
                <div class="form-group">
                  <div class="input-group date">
                    <!-- <div class="input-group-addon">
                      <i class="fa fa-calendar"></i>
                    </div> -->
                    <!-- <input type="text" formControlName="Date"  class="e-input" placeholder="From" 
                      [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', containerClass: 'theme-dark-blue', customTodayClass:'custom-today-class', showWeekNumbers: false }" autocomplete="off"
                      bsDatepicker required /> -->
                          <!-- <input type="date" formControlName="Date"  class="e-input" placeholder="MM/dd/YYYY" 
  autocomplete="off"
                     /> -->
                     
                     <ejs-datepicker  [enabled]="LeaveTypeenable" [allowEdit]="false" formControlName="Date"  id="LeaveStartDate" ></ejs-datepicker>

                  </div>
                </div>
              </td>
              <td>{{ getLeaveBalcanceByEmplyeList[i].balance }}</td>
              <td>
                <input type="number" formControlName="NewBalance" class="e-input reason">
              </td>
              <td>
                <input type="text" formControlName="Reason" class="e-input" placeholder="Manual Correction">
              </td>
            </tr>
          </ng-container>
          </tbody>
        </table>
        <!-- Pagination -->
        <!-- <div class="row">
          <div class="col-sm-12">
            <div class="d-flex justify-content-between p-2" class="col-sm-12 text-right">
              <ul class="paggingUl">
                <ngb-pagination [collectionSize]="myRequestsListLength" [(page)]="myRequestsPage" [pageSize]="myRequestsPageSize"></ngb-pagination>
                <li>
                  <span class="recordsMsg">No. of Records</span>
                  <select class="NoOfpage" style="width: auto;" [(ngModel)]="myRequestsPageSize">
                    <option [ngValue]="10">10</option>
                    <option [ngValue]="20">20</option>
                    <option [ngValue]="50">50</option>
                  </select>
                </li>
              </ul>
            </div>
          </div>
        </div> -->
    </div>

    <div class="col-sm-12">
      <button type="submit" class="btn btn-primary btn-brand pull-left">Submit</button>
      <button type="button" routerLink="/leave/setting/customize-leave-balance" class="btn btn-light" data-dismiss="modal">Cancel</button>
    </div>
    <!-- <div >
      <button type="submit" class="btn btn-primary btn-rounded pull-left">Submit and New</button>
    </div> -->
  </form>
  </div> 
</mk-box>

<!-- pop up model  -->
<div id="leaveTypeMessageModel" class="modal fade" role="dialog">
  <div class="modal-dialog modal-confirm modal-sm">
    <div class="modal-content" style="border-radius: 10px; top: 150px; left: 350px;">
      <div class="modal-header success-header">
        <div class="icon-box" *ngIf="showSuccess">
          <i class="fa fa-check"></i>
        </div>
        <div class="icon-box-remove" *ngIf="!showSuccess">
          <i class="fa fa-remove"></i>
        </div>
      </div>
      <div class="modal-body">
        <p class="text-center">{{showMessage}}</p>
      </div>
      <div class="modal-footer">
        <button class="btn btn-success btn-block" data-dismiss="modal">
          OK
        </button>
      </div>
    </div>
  </div>
</div>