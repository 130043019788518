<div class="navbar-custom-menu">

  <ul class="nav navbar-nav">

    <!-- <li class="currentdatetime">
      <span >Finish PM- Studio Setup</span>
    </li> -->

    <li class="currentdatetime">
      <span style="font-size: 14px;">{{now}}</span>
    </li>
    <!-- <li mk-dropdown type="list" [isWrapper]="false" class="user user-menu">
      <mk-dropdown-toggle>
        <a #toggleElement>
          <span class="hidden-xs">Choose Language</span>
        </a>
      </mk-dropdown-toggle>
      <mk-dropdown-menu>
    <li class="user-body">
      <ul style="list-style-type: none;">
        <li *ngFor="let lang of languageArray">
          <a href="javascript:void(0)">
            <span [ngClass]="{'text-bold' : defaultLanguageName === lang.displayName}">
              <i [ngClass]="lang.icon" class="margin-right-5"></i>
              {{lang.displayName}} <span *ngIf="defaultLanguageName === lang.displayName">({{('Default')}})</span>
            </span>
          </a>
        </li>
      </ul>
    </li>
    </mk-dropdown-menu>
    </li>
    <li mk-dropdown type="list" [isWrapper]="false" class="user notifications-menu">
      <mk-dropdown-toggle>
        <a #toggleElement>
          <i class="fa fa-bell-o"></i>
          <span class="label label-danger">10</span>
        </a>
      </mk-dropdown-toggle>
      <mk-dropdown-menu>
    <li class="header">You have 10 notifications</li>
    <li>
      <ul class="menu">
        <li>
          <a href="#">
            <i class="fa fa-users text-aqua"></i> 5 new members joined today
          </a>
        </li>
        <li>
          <a href="#">
            <i class="fa fa-warning text-yellow"></i> Very long description here that may not fit into the
            page and may cause design problems
          </a>
        </li>
        <li>
          <a href="#">
            <i class="fa fa-users text-red"></i> 5 new members joined
          </a>
        </li>
        <li>
          <a href="#">
            <i class="fa fa-shopping-cart text-green"></i> 25 sales made
          </a>
        </li>
        <li>
          <a href="#">
            <i class="fa fa-user text-red"></i> You changed your username
          </a>
        </li>
      </ul>
    </li>
    <li class="footer"><a href="#">View all</a></li>
    </mk-dropdown-menu>
    </li> -->

    <!-- <li
    mk-dropdown
    type="list"
    [isWrapper]="false"
    class="user user-menu">

    <mk-dropdown-toggle>
      <div>

        <a #toggleElement class="user-top-icons" (click)="IsNotification(NotificationLength, Notifications)" style="    position: relative;">
          <img src="../assets/img/Logo/1.png" class="top-icons">
        <sup *ngIf="NotificationLength > 0" style="color: #fff; background: #e3000f; font-size: 11px;font-weight: 600; padding: 8px 9px; border-radius: 3px; top: 5px; left: 11px; position: absolute;">{{NotificationLength}}</sup>
        </a>
      </div>
    </mk-dropdown-toggle>
    <mk-dropdown-menu>
      <div class="user-output">
        <div class="user-infos">
          <p style="margin: 0; color: #565655; font-weight: 600; font-size: 20px; padding: 15px 15px 10px;">Notifications</p>
          <ul>

            <li *ngFor="let notify of Notify" style="    border-top: 1px solid #ddd;">
              <a *ngIf="notify.eventstatus === 0"  (click)="IsStatus(notify,notify.notificationId)" style="font-size: 13px; font-weight: 600;">{{notify.discription}}
              <div style="font-size: 11px; font-weight: 500; margin-top: 5px;">{{notify.eventDate | date: "medium"}}</div></a>
            </li>
           </ul>
        </div>
    </div>
  </mk-dropdown-menu>
    </li> -->
    <!-- <li
    mk-dropdown
    type="list"
    [isWrapper]="false"
    class="user user-menu new-tube">

    <mk-dropdown-toggle>

      <div *ngIf="!(roleId === '30')">
        <a #toggleElement class="user-top-icons" style="cursor: pointer;">
          <img src="../assets/img/Logo/2.png" class="top-icons"></a>
      </div>
    </mk-dropdown-toggle>
    <mk-dropdown-menu>
      <div class="user-output">
        <div class="user-infos">
          <p style="margin: 0; color: #565655; font-weight: 600; font-size: 20px; padding: 15px 15px 10px;">Quick Add</p>
          <ul>
            <li><a href="/#/paSystem/Masters/projectPhases"><img src="../assets/img/Logo/16.png"> New Task</a></li>
            <li><a href="/#/paSystem/Masters/timesheets"><img src="../assets/img/Logo/time.png">Timesheet</a></li>
            <li><a href="/#/leave"><img src="../assets/img/Logo/le.png">Leave</a></li>
            <li><a href="/#/expense/Addexpense"><img src="../assets/img/Logo/ex.png">Expense</a></li>
            <li><a href="/#/paSystem/Masters/mom"><img src="../assets/img/Logo/17.png">MOM</a></li>
            <li><a href="/#/paSystem/Masters/roadblock"><img src="../assets/img/Logo/18.png">Roadblock</a></li>



          </ul>

        </div>
    </div>


    </mk-dropdown-menu>


  </li> -->

    <li class="new-tube">
      <div class="hm-gradient">
        <main>

          <div class="mt-4">

            <div class="card mb-4">
              <div class="card-body clearfix d-md-flex d-inline-block justify-content-center">

                <div class="btn-group mr-2 mb-md-0 mb-3">
                  <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false" style="color:#000;">
                    <div>

                      <a #toggleElement class="user-top-icons"
                        (click)="IsNotification(NotificationLength, Notifications)" style="    position: relative;">
                        <img src="../assets/img/Logo/1.png" class="top-icons">
                        <sup *ngIf="NotificationLength > 0"
                          style="color: #fff; background: #e3000f; font-size: 11px;font-weight: 600; padding: 8px 9px; border-radius: 3px; top: 5px; left: 11px; position: absolute;">{{NotificationLength}}</sup>
                      </a>
                    </div>
                  </button>

                  <div class="dropdown-menu notifa" style="padding: 0;
                  width: 420px; left: auto; right: 0;">
                    <div class="user-output" style="display: block;">
                      <div class="user-infos">
                        <div class="not-head"
                          style="position: relative; display: inline-flex; align-items: center; width: 100%;">
                          <p style="margin: 0; color: #565655; font-weight: 600; font-size: 20px;
                           padding: 15px 6px 10px 15px;">
                            Notifications</p>
                          <a style="font-size: 12px;
                          color: #3496db !important;margin-top: 8px; cursor: pointer;"
                            (click)="setRedAllNotification()">Mark all as
                            read</a>
                        </div>
                        <ul style="max-height: 385px; overflow-y: scroll;">

                          <li *ngFor="let notify of Notify" style="border-top: 1px solid #ddd;padding: 9px 10px;">
                            <a *ngIf="notify.eventstatus === 0" (click)="IsStatus(notify,notify.notificationId)"
                              style="font-size: 13px; font-weight: 600; cursor: pointer; display: inline-block; width: 100%;">

                              <span class="info-notify">{{notify.discription}}
                                <span
                                  style="font-size: 11px; font-weight: 500; margin-top: 5px; width: 100%;display: inline-block;">
                                  {{notify.eventDate | date: "medium"}}</span></span> </a>
                          </li>
                        </ul>
                        <!--[href]="notify.link" -->
                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>


          </div>


        </main>

      </div>
    </li>

    <li type="list" class="user user-menu new-tube">
      <div class="hm-gradient">
        <main>

          <div class="mt-4">

            <div class="card mb-4">
              <div class="card-body clearfix d-md-flex d-inline-block justify-content-center">

                <div class="btn-group mr-2 mb-md-0 mb-3" >
                  <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false" style="color:#000;">
                    <div>
                      <a #toggleElement class="user-top-icons" style="cursor: pointer;" [title]="!googleIntegratedAdmin && !microsoftIntegratedAdmin?'Integration Pending From Admin':'Integration'">
                        <img src="../assets/img/Logo/plug.png" class="top-icons"></a>
                    </div>
                  </button>

                  <div *ngIf="googleIntegratedAdmin || microsoftIntegratedAdmin" class="dropdown-menu" style="left: -50%; width: 120px; min-width: 120px;">
                    <div class="user-infos">
                      <div class="{{googleIntegrated?'btn-group btnexport':'btn-group btnexport disable-int'}}">
                        <button type="button"  class="btn-primary no-padding" title="Google Integration" >
                          <img src="../assets/img/Logo/google1.png" class="integrate-icon-header">

                        </button>
                        <ul class="dropdown-menu2" style="left: auto; right: 0;top: 100%;">
                          <li *ngIf="!googleIntegrated"><a href="javascript:void(0)" (click)="startGoogleSync()"  >Start Sync</a></li>
                          <li *ngIf="googleIntegrated">
                            <a href="javascript:void(0)" (click)="pauseSync('Google')">Pause Sync</a>
                          </li>
                        </ul>
                      </div>

                      <div class="{{microsoftIntegrated?'btn-group btnexport':'btn-group btnexport disable-int'}}">
                        <button type="button"  class="btn-primary no-padding" title="Microsoft Integration" >
                          <img src="../assets/img/Logo/teams.png" class="integrate-icon-header">
                        </button>
                        <ul class="dropdown-menu2" style="left: auto; right: 0;top: 100%;">
                          <li *ngIf="!microsoftIntegrated"><a href="javascript:void(0)" (click)="startMicrosoftSyc()" >Start Sync</a></li>
                          <li *ngIf="microsoftIntegrated">
                            <a href="javascript:void(0)" (click)="pauseSync('Microsoft')">Pause Sync</a>
                          </li>
                        </ul>
                      </div>

                    </div>
                  </div>
                </div>


              </div>
            </div>


          </div>


        </main>

      </div>
    </li>

    <li type="list" class="user user-menu new-tube">
      <div class="hm-gradient">
        <main>

          <div class="mt-4">

            <div class="card mb-4">
              <div class="card-body clearfix d-md-flex d-inline-block justify-content-center">

                <div class="btn-group mr-2 mb-md-0 mb-3">
                  <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false" style="color:#000;">
                    <div *ngIf="!(roleId === '30')">
                      <a #toggleElement class="user-top-icons" style="cursor: pointer;">
                        <img src="../assets/img/Logo/2.png" class="top-icons"></a>
                    </div>
                  </button>

                  <div class="dropdown-menu" style="left: -50%; width: 250px;">
                    <div class="user-infos">
                      <p style="margin: 0; color: #565655; font-weight: 600; font-size: 20px; padding: 15px 15px 10px;">
                        Quick Add</p>
                      <ul>
                        <li><a href="/#/paSystem/Masters/projects"><img src="../assets/img/Logo/16.png">New
                            Task</a></li>
                        <li><a href="/#/paSystem/Masters/timesheetnew"><img src="../assets/img/Logo/time.png">Record
                            Time</a></li>
                        <li><a href="/#/leave"><img src="../assets/img/Logo/le.png">Leave</a></li>
                        <li><a href="/#/expense/Addexpense"><img src="../assets/img/Logo/ex.png">Expense</a></li>
                        <li><a href="/#/paSystem/Masters/mom"><i class="fa fa-users"
                              style="color: #888887; font-size: 17px;  margin: 0 6px 0 4px;"></i>Minutes of Meeting</a>
                        </li>
                        <li><a href="/#/paSystem/Masters/roadblock"><i class="fa fa-exclamation-triangle"
                              style="margin: 0 7px 0 4px; font-size:20px;color: #888887;"></i>Roadblocks</a>
                        </li>
                      </ul>

                    </div>
                  </div>
                </div>


              </div>
            </div>


          </div>


        </main>

      </div>
    </li>

    <!-- <li type="list" class="user user-menu new-tube" *ngIf="roleId === '1'">
      <div class="hm-gradient">
        <main>

          <div class="mt-4">

            <div class="card mb-4">
              <div class="card-body clearfix d-md-flex d-inline-block justify-content-center">

                <div class="btn-group mr-2 mb-md-0 mb-3">
                  <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false" style="color:#000;">
                    <div *ngIf="!(roleId === '30')">
                      <a #toggleElement class="user-top-icons" style="cursor: pointer;">
                        <img src="../assets/img/Logo/3.png" class="top-icons"></a>
                    </div>
                  </button>

                  <div class="dropdown-menu" style="left: -50%; width: 250px;">
                    <div class="user-infos">
                      <p style="margin: 0; color: #565655; font-weight: 600; font-size: 20px; padding: 15px 15px 10px;">
                        Setting</p>
                      <ul>
                        <li><a href="/#/expense/orgsetting"><img src="../assets/img/Logo/3.png" style="width: 20px;"> View Setting</a></li>
                        <li><a href="/#/expense/integration"><img
                              src="../assets/img/Logo/28.png" style="width: 22px;">Integration Setting</a></li>

                      </ul>

                    </div>
                  </div>
                </div>


              </div>
            </div>


          </div>


        </main>

      </div>
    </li> -->

    <!-- <li class="user user-menu new-tube" *ngIf="roleId === '1'">
      <div>
        <a href="/#/expense/orgsetting" class="user-top-icons"><img src="../assets/img/Logo/3.png"
            class="top-icons"></a>
      </div>
    </li> -->

    <!-- <li class="user-logo">
      <img src="http://103.10.234.199/api//Uploads/companyLogo\CRG_logo_14-12-2020-14-29-17.png">
    </li> -->
    <li type="list" class="user user-menu" style="display: inline-flex;
    border: 1px solid #ddd;
    padding: 7px;
    height: 43px;
    border-radius: 5px;
    position: relative;
    top: 5px;
    right: 5px;margin-left: 12px;">
      <div class="hm-gradient">

        <main>

          <div class="mt-4">



            <div class="card mb-4">
              <div class="card-body clearfix d-md-flex d-inline-block justify-content-center">

                <div class="btn-group mr-2 mb-md-0 mb-3">
                  <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false"
                    style="color:#000;padding: 0; width: 110px; display: inline-flex;  margin-right: 0 !important;">
                    <img src="{{Logo}}" style="width: 70px;
          height: 25px;
          margin-top: 4px;">
                    <div class="profileImage" style="margin-right: 0 !important;">
                      <a #toggleElement><img src="{{userImage}}" style="width:100%; height:100%; border-radius:50%;" /></a>
                      <!-- <a #toggleElement>{{ UserName1 }}</a>   <img src="{{userImage}}" style="width:100%; border-radius:50%;" />-->
                    </div>
                  </button>

                  <div class="dropdown-menu" style="    right: -8px; left: auto; padding:0;">
                    <!-- <a class="dropdown-item" href="#">Action</a> -->
                    <div class="dropdown-item user-output">
    <li class="user-header" style="height: auto">
      <span class="prof-img"><img src="{{userImage}}" /></span>
      <p style="padding: 3px;margin: 0px;font-weight: 600;">
        {{ UserFullName }}
        <button type="button" class="btn btn-primary btn-brand" data-toggle="modal" data-target="#exampleModal1"
          (click)="getuserdata()" style="width: auto;
                                    margin: 0 !important;
                                    background: transparent; padding: 4px !important;">
          <i class="fa fa-pencil" aria-hidden="true" style="color: #000;"></i>
        </button>
      </p>
      <p style="padding: 3px;
                                      margin: 0px;
                                      color: #666 !important;
                                      font-size: 15px;
                                      margin-top: -5px;">
        {{ UserEmail }}
        <br /><span style="font-size: 14px; margin-top: 5px; display: inline-block;">User Id: {{UserId}}</span>
      </p>
    </li>
    <li class="user-footer" style="width: 285px;border-top: 2px solid #ddd;">
      <div class="user-infos">
        <ul>
          <!-- <li><a href="#" class="btn btn-default btn-flat" style="margin-left: 0 !important;"><img
                src="../assets/img/Logo/19.png">
              My Accounts
            </a></li> -->

          <li>

            <button type="button" class="dsaa" data-toggle="modal" data-target="#exampleModal59"><img
                src="../assets/img/Logo/on.png"> Onboarding Session
            </button>
          </li>

          <li *ngIf="roleId === '1'">
            <button type="button" class="dsaa" data-toggle="modal" data-target="#exampleModal51"><img
                src="../assets/img/Logo/ds.png"> Subscription Details
            </button>
          </li>

          <li>
            <a href="/#/expense/showfaq">
              <button type="button" class="dsaa"><img src="../assets/img/Logo/faq.png"> FAQ
              </button></a>
          </li>



          <li><a href="javascript:void(0)" (click)="signout()" class="btn btn-default btn-flat"
              style="margin-left: 0 !important;">
              <img src="../assets/img/Logo/log.png"> Sign Out
            </a></li>

        </ul>

      </div>
      <p *ngIf="roleId === '1'" style="padding: 15px 10px 0;">Your Portal is Currently in {{subscriptionPlan}}</p>


    </li>
</div>
</div>
</div>


</div>
</div>


</div>


</main>

</div>
</li>

<!-- <li
      mk-dropdown
      type="list"
      [isWrapper]="false"
      class="user user-menu" style="display: inline-flex;
      border: 1px solid #ddd;
      padding: 7px;
      height: 43px;
      border-radius: 5px;
      position: relative;
      top: 5px;
      right: 5px;margin-left: 5px;">

     <mk-dropdown-toggle>
        <img src="http://103.10.234.199/api//Uploads/companyLogo\CRG_logo_14-12-2020-14-29-17.png" style="width: 70px;
        height: 25px;
        margin-top: 4px;">
        <div class="profileImage">
         <a #toggleElement><img src="{{userImage}}" style="width:100%; border-radius:50%;" /></a>

        </div>
      </mk-dropdown-toggle>
       <mk-dropdown-menu>
        <div class="user-output">
        <li class="user-header" style="height: auto">
          <span class="prof-img"
            ><img src="{{userImage}}"
          /></span>
          <p style="padding: 3px;margin: 0px;font-weight: 600;">
            {{ UserFullName }}
            <button
              type="button"
              class="btn btn-primary btn-brand"
              data-toggle="modal"
              data-target="#exampleModal1"
              (click)="getuserdata()"
              style="width: auto;
          margin: 0 !important;
          background: transparent; padding: 4px !important;"
            >
              <i
                class="fa fa-pencil"
                aria-hidden="true"
                style="color: #000;"
              ></i>
            </button>
          </p>
          <p
            style="padding: 3px;
            margin: 0px;
            color: #666 !important;
            font-size: 15px;
            margin-top: -5px;"
          >
            {{ UserEmail }}
            <br /><span
              style="font-size: 14px; margin-top: 5px; display: inline-block;"
              >User Id: 0254512630</span
            >
          </p>
        </li>
        <li class="user-footer" style="width: 285px;border-top: 2px solid #ddd;">
          <div class="user-infos">
            <ul>
              <li><a href="#" class="btn btn-default btn-flat" style="margin-left: 0 !important;"><img src="../assets/img/Logo/19.png">
                My Accounts
              </a></li>

              <li *ngIf="roleId === '1'">
                <a href="javaScript:void(0)"><img src="../assets/img/Logo/on.png"> Onboarding Session <span class="coming">(Coming Soon)</span></a></li>

              <li *ngIf="roleId === '1'">
              <button
                type="button"
                class="dsaa"
                data-toggle="modal"
                data-target="#exampleModal51"
              ><img src="../assets/img/Logo/ds.png"> Subscription Details
              </button></li>

              <li><a
                href="javascript:void(0)"
                (click)="signout()"
                class="btn btn-default btn-flat" style="margin-left: 0 !important;"
              >
              <img src="../assets/img/Logo/log.png"> Sign Out
              </a></li>

            </ul>

          </div>
          <p *ngIf="roleId === '1'" style="padding: 15px 10px 0;">Your Portal is Currently in Enterprise Plan</p>


        </li>
      </div>


      </mk-dropdown-menu>


    </li> -->




</ul>
</div>



<div id="AdminNewLanguage" class="modal fade" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" onclick="removeErrorMessage()" data-dismiss="modal">
          &times;
        </button>
        <h4 class="modal-title">New Language</h4>
      </div>
      <div class="modal-body model-bg row lessrow">
        <div class="col-sm-12 col-xs-12 model-body">
          <div class="m-portlet__body">
            <div class="form-group m-form__group row">
              <div class="col-lg-6">
                <label class="labelGap">Name</label>
                <input type="text" name="Name" autocomplete="off" class="e-input txtclass" placeholder="Culture Name"
                  [(ngModel)]="language.name" />
              </div>
              <div class="col-lg-6">
                <label class="labelGap">DisplayName</label>
                <input type="text" name="DisplayName" autocomplete="off" class="e-input txtclass"
                  placeholder="Display Name" [(ngModel)]="language.displayName" />
              </div>
            </div>
            <div class="form-group m-form__group row">
              <div class="col-lg-6">
                <label class="labelGap">Icon</label>
                <input type="text" name="Icon" autocomplete="off" class="e-input txtclass" placeholder="Icon"
                  [(ngModel)]="language.icon" />
              </div>
              <!-- <div class="col-lg-6">
                  <label><input type="checkbox" [(ngModel)]="language.isDefault">IsDefault</label>
                </div> -->
            </div>
            <div class="m-form__actions pull-right">
              <button type="button" class="btn btn-brand" (click)="addNewLanguage()">
                Save
              </button>
              <button type="button" class="btn btn-danger" data-dismiss="modal">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade expense-det" id="exampleModal51" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog text-left" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title pull-left" id="exampleModalLabel">Subscription Details</h5>
        <button type="button" (click)="ChangePlan()" class="btn btn-primary pull-right">Add Users</button>
        <button type="button" (click)="UpgradeUser()" class="btn btn-primary pull-right">Change Plan</button>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form name="form" >

          <table class="table table-responsive" style="margin-bottom: 0;">
            <tr>
              <th width="130px">Item</th>
              <th class="text-left">User Count</th>
              <th class="text-left">Customer Profile Count</th>
              <th class="text-center">Total/Monthly</th>
              <th class="text-center">Total/Yearly</th>
            </tr>
            <tr *ngFor="let detail of CompanyDetail">
              <td *ngIf="!isItem" width="130px">{{detail.itemPlan}}</td>
              <td *ngIf="isItem">
                <select class="form-control" (change)="updateCustomers($event)" [(ngModel)]="detail.itemPlan" [ngModelOptions]="{standalone: true}">
                  <option [ngValue]="null" [disabled]="true">Select Item</option>
                  <option value="Basic Plan">Basic Plan</option>
                  <option value="Business Plan">Business Plan</option>
                  <option value="Enterprise Plan">Enterprise plan</option>
                  <!-- <option value="Customize Plan">Customize Plan</option> -->
                </select>
              </td>
              <td class="text-center" *ngIf="!isNoOfUnits" >
                <input type="number" class="e-input" [disabled]="detail.itemPlan != 'Customize Plan'"  [(ngModel)]="detail.noofUsers" [ngModelOptions]="{standalone: true}"
                style="width: 90%; margin: auto;" >
              </td>
              <td class="text-center" *ngIf="!isNoOfUnits">
                <input type="number" [disabled]="detail.itemPlan != 'Customize Plan'" class="e-input" [(ngModel)]="detail.noofCustomer"
                  [ngModelOptions]="{standalone: true}" style="width: 90%; margin: auto;">
              </td>
              <td class="text-center" *ngIf="!isNoOfUnits" >${{detail?.totalMonth}}</td>
              <td class="text-center" *ngIf="!isNoOfUnits" >${{(+CompanyDetail[0]?.totalYear)}}</td>

              <td class="text-left" *ngIf="isNoOfUnits" >
                <span class="e-input">{{originalUsers}}</span>
              </td>
              <td class="text-left" *ngIf="isNoOfUnits">
                <span class="e-input">{{originalcustomers}}</span>
              </td>
              <td class="text-center" *ngIf="isNoOfUnits" >${{originalMonth}}</td>
              <td class="text-center" *ngIf="isNoOfUnits" >${{(originalYear)}}</td>
            </tr>

            <ng-container *ngIf="isNoOfUnits">
              <tr *ngFor="let detail of CompanyDetail">
                <td width="130px">Additional Users</td>
                <td class="text-left"  >
                  <input type="number" class="e-input" (input)="AddedUsersChange($event.target.value)"   [(ngModel)]="detail.addedUsers" [ngModelOptions]="{standalone: true}"
                  style="width: 90%; margin: auto;" >
                </td>
                <td class="text-left">
                  <input type="number"  class="e-input" (input)="AddedUsersCustChange($event.target.value)" [(ngModel)]="detail.addedCustomer"
                    [ngModelOptions]="{standalone: true}" style="width: 90%; margin: auto;">
                </td>
                <td class="text-center" *ngIf="isNoOfUnits" >${{(originalMonth - CompanyDetail[0]?.totalMonth) < 0?(originalMonth - CompanyDetail[0]?.totalMonth)*-1:(originalMonth - CompanyDetail[0]?.totalMonth)}}</td>
                <td class="text-center" *ngIf="isNoOfUnits" >${{((originalMonth - CompanyDetail[0]?.totalMonth) < 0?(originalMonth - CompanyDetail[0]?.totalMonth)*-1:(originalMonth - CompanyDetail[0]?.totalMonth))*10}}</td>
              </tr>
            </ng-container>
            <tr style="background-color:#f5f7f7 !important; color:#000;">
              <td class="text-left" width="130px">Total</td>
              <td class="text-left total-row" >
                <input type="number" class="e-input" *ngIf="isNoOfUnits" [disabled]="CompanyDetail[0].itemPlan != 'Customize Plan'"  [(ngModel)]="CompanyDetail[0].noofUsers" [ngModelOptions]="{standalone: true}"
                style="width: 90%; margin: auto;" >
              </td>
              <td class="text-left total-row" >
                <input type="number" *ngIf="isNoOfUnits" class="e-input" [disabled]="CompanyDetail[0].itemPlan != 'Customize Plan'"  [(ngModel)]="CompanyDetail[0].noofCustomer"
                  [ngModelOptions]="{standalone: true}" style="width: 90%; margin: auto;">
              </td>
              <td class="text-center">${{CompanyDetail[0]?.totalMonth}}</td>
              <td class="text-center">${{(+CompanyDetail[0]?.totalYear)}}</td>
            </tr>
            <tr style="color:#000;">
              <td colspan="3" class="text-right">Discount</td>
              <td class="text-center">$0.0</td>
              <td class="text-center">$0.0</td>
            </tr>

            <tr style="color:#000;">
              <td colspan="3" class="text-right">Net Total</td>
              <td class="text-center">${{CompanyDetail[0]?.totalMonth}}</td>
              <td class="text-center">${{(+CompanyDetail[0]?.totalYear)}}</td>
            </tr>
            <tr style="color:#000;">
              <td colspan="3" class="text-right">Tax({{userAmount?.taxRate}})%</td>
              <td class="text-center">${{+CompanyDetail[0]?.totalMonth*(userAmount?.taxRate/100)+(+CompanyDetail[0]?.totalMonth)}}</td>
              <td class="text-center">${{(+CompanyDetail[0]?.totalYear)*(userAmount?.taxRate/100)+(+CompanyDetail[0]?.totalYear)}}</td>
            </tr>
            <tr style="color:#000;">
              <td colspan="3" class="text-right"><b>Payable Amount</b></td>
              <td class="text-center"><b>${{+CompanyDetail[0]?.totalMonth*(userAmount?.taxRate/100)+(+CompanyDetail[0]?.totalMonth)}}</b></td>
              <td class="text-center"><b>${{(+CompanyDetail[0]?.totalYear)*(userAmount?.taxRate/100)+(+CompanyDetail[0]?.totalYear)}}</b></td>
            </tr>

            <tr style="background-color:#fff !important; color:#000;">
              <td colspan="3" class="text-right">Account Status</td>
              <td class="text-center"><a  style="color: red;">Overdue</a></td>
            </tr>
          </table>
          <div class="m-form__actions" style="padding-top: 24px; display: flex; align-items: center; justify-content: space-between;">
            <!-- <button type="submit" class="btn btn-primary">Save</button> -->
            <div class="payment-div">
              <div class="payment-col"><input [(ngModel)]="CompanyDetail[0].subcriptionType" type="radio" value="1" name="payType" id="payType"> <h4>Pay Monthly</h4></div>
              <div class="payment-col m-0"><input  [(ngModel)]="CompanyDetail[0].subcriptionType" type="radio" value="2" name="payType" id="payType"> <h4>Pay Yearly</h4> </div>
            </div>
          </div>

        </form>
        <br />

        <!-- <h5 class="pull-right" style="margin-top: -30px;">

        </h5> -->
        <div> <ngx-paypal [config]="payPalConfig"></ngx-paypal></div>

      </div>
    </div>
  </div>
</div>




<!-- Modal -->
<div class="modal fade expense-det" id="exampleModal59" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog" role="document" style="width: 800px;">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title pull-left" id="exampleModalLabel">Onboarding Session </h5>

        <button type="button" class="close" (click)="videoPlayer.pause()" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="display: inline-block; width:100%;padding:0;">
        <div class="col-md-12 no-padding">
          <video style="width: 100%;" controls id="videoPlayer" (click)="toggleVideo()" #videoPlayer>
            <source *ngIf="roleId == 1 || roleId == 2 || roleId == 10;else templateName" src="../assets/img/PM_Walkthrough_Latest.mp4" type="video/mp4" />
            Browser not supported
            <ng-template #templateName>
              <source  src="../assets/img/Team_Member_Walkthrough.mp4" type="video/mp4" />
            </ng-template>
          </video>
          <!-- <video style="width: 100%;" controls>  <source src="../assets/img/Team_Member_Walkthrough.mp4" type="video/mp4"></video>
          <video style="width: 100%;" controls>  <source src="../assets/img/PM_Walkthrough_Latest.mp4" type="video/mp4"></video> -->
        </div>

      </div>
    </div>
  </div>
</div>



<!-- Modal -->
<div class="modal fade expense-det" id="exampleModal1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog text-left" role="document" style="width: 750px;">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Edit User Profile

        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">

          <form #f="ngForm" (ngSubmit)="UpdateUserDataSubmit()">

            <div class="col-md-12 no-padding">
              <div class="col-md-4 no-padding">
                <div *ngFor="let userdata of userdatalist" class="col-md-12 no-padding">
                  <h4 style="font-weight: 600;
                  color: #000;margin-top: 0;text-align: center;">Profile Picture</h4>


                  <div class="col-md-12 text-center no-padding">
                    <img [src]="userImage" style="width: 90px;
                    height: 90px;
                    border-radius: 50%;
                    border: 1px solid #DDD;
                    padding: 4px;
                    margin-bottom: 10px;" />
                    <small class="attach-label">
                      <!-- <input value="" id="uploadimage" class="e-input" name="reciptImage" type="file"
                        (change)="onSelectFile($event)" class="form-control" style="padding: 0 0 0 35px;
                      border: none;font-size: 12px;" accept="image/jpg, image/jpeg, image/png" /> -->
                      <ejs-uploader #file [asyncSettings]='path' (change)="onSelectFile($event)" multiple="false">
                      </ejs-uploader>
                    </small>

                    <!-- <h4 style="margin: 0 0 5px; font-size: 16px;">Update Your Profile Picture</h4> -->
                    <p style="font-size: 12px; text-decoration: underline; cursor: pointer;margin: 5px 0 10px;"
                      (click)="SetDefaultImg()">Set Default Picture</p>
                  </div>

                  <!-- <img [src]="userImage" height="50" />
                    <small class="attach-label"><input value="" id="uploadimage" name="reciptImage" type="file" (change)="onSelectFile($event)" class="form-control" style="padding: 0;
                      border: none;"/>or
                      click here to attach</small> -->

                </div>
              </div>

              <div class="col-md-8 no-padding langs"
                style="border-left: 1px solid #ddd; margin-bottom: 15px;     padding-left: 15px !important;">
                <tr *ngFor="let userdata of userdatalist">
                  <div class="col-md-6">
                    <label>Name</label><br />
                    <td>
                      <input class="e-input" [(ngModel)]="userdata.firstName" name="firstName" />
                    </td>
                  </div>

                  <div class="col-md-6">
                    <label>Language</label><br />
                    <select [(ngModel)]="userdata.language" name="language"
                      style="border: none; padding-left: 0; border-bottom: 1px solid #ccc;margin-top: -4px;" class="form-control" form>
                      <option value="1">English</option>


                    </select>
                  </div>

                  <!-- <div class="col-md-6">
                    <label>Last Name</label><br />
                    <td>
                      <input class="e-input" [(ngModel)]="userdata.lastName" name="lastName" />
                    </td>
                  </div> -->

                  <div class="col-md-6" style="display: none;">
                    <label>Official Contact</label><br />
                    <td>
                      <!-- <input class="e-input" [(ngModel)]="userdata.workPhone" name="workPhone" /> -->
                      <input class="e-input" value="0" name="workPhone" />
                    </td>
                  </div>

                  <div class="col-md-6">
                    <label>Phone Number</label><br />
                    <td>
                      <input class="e-input" [(ngModel)]="userdata.homePhoneNo" name="homePhoneNo" />
                    </td>
                  </div>

                  <!-- <div class="col-md-6">
                    <label>Mobile No.</label><br />
                    <td><input class="form-control" name="mobileNo" /></td>
                  </div> -->

                </tr>
              </div>
            </div>

            <div class="form-group picktheme" *ngFor="let userdata of userdatalist" style="padding-left: 10px;">
              <hr style="margin-top:0;" />
              <h4>Themes</h4>
              <p>Pick a theme and pattern to apply for the background.</p>
              <ul>

                <li>
                  <label>
                    <input type="radio" [(ngModel)]="userdata.themeId" name="themeId" [value]="0">
                    <span class="colorv2" style="background:#307898;"></span>
                    <span class="colorv21 colorv" style="background:#005880;"></span>
                  </label>
                </li>

                <li>
                  <label>
                    <input type="radio" [(ngModel)]="userdata.themeId" name="themeId" [value]="1">
                    <span class="colorv2" style="background:#71badb;"></span>
                    <span class="colorv21 colorv" style="background:#50a9d3;"></span>
                  </label>
                </li>

                <li>
                  <label>

                    <input type="radio" [(ngModel)]="userdata.themeId" name="themeId" [value]="2">
                    <span class="colorv1" style="background:#f1dc5d;"></span>
                    <span class="colorv11 colorv" style="background:#f1c34b;"></span>
                  </label>
                </li>

                <li>
                  <label>

                    <input type="radio" [(ngModel)]="userdata.themeId" name="themeId" [value]="3">
                    <span class="colorv1" style="background:#2c82a8;"></span>
                    <span class="colorv11 colorv" style="background:#4f9bbd;"></span>
                  </label>
                </li>

                <li>
                  <label>

                    <input type="radio" [(ngModel)]="userdata.themeId" name="themeId" [value]="4">
                    <span class="colorv1" style="background:#79b08b;"></span>
                    <span class="colorv11 colorv" style="background:#82bf96;"></span>
                  </label>
                </li>

                <li>
                  <label>

                    <input type="radio" [(ngModel)]="userdata.themeId" name="themeId" [value]="5">
                    <span class="colorv1" style="background:#df6756;"></span>
                    <span class="colorv11 colorv" style="background:#e58476;"></span>
                  </label>
                </li>

                <li>
                  <label>

                    <input type="radio" [(ngModel)]="userdata.themeId" name="themeId" [value]="6">
                    <span class="colorv1" style="background:#565655;"></span>
                    <span class="colorv11 colorv" style="background:#df6756;"></span>
                  </label>
                </li>

                <li>
                  <label>

                    <input type="radio" [(ngModel)]="userdata.themeId" name="themeId" [value]="7">
                    <span class="colorv1" style="background:#005880;"></span>
                    <span class="colorv11 colorv" style="background:#f1c34b;"></span>
                  </label>
                </li>

                <li>
                  <label>

                    <input type="radio" [(ngModel)]="userdata.themeId" name="themeId" [value]="8">
                    <span class="colorv1" style="background:#565655;"></span>
                    <span class="colorv11 colorv" style="background:#f1c34b;"></span>
                  </label>
                </li>


              </ul>


            </div>



            <hr style="position: relative; width: 100%; display: inline-block; margin-top: 0;" />
            <button type="submit" class="btn btn-primary" style="margin-left: 10px;">Update</button>
            <!-- <button type="button" class="btn btn-primary btn-brand" data-toggle="modal" data-target="#addSkill" style="background: #f9f0f0;
            color: #222; border: 1px solid #ddd;">Add Skill</button> -->
          </form>
        </div>
        <!--     <div class="col-md-12">

                  <button class="btn btn-primary">Update</button>
                      <button class="btn btn-primary cancel" style="background: #e2692b;
                      color: #fff;
                      padding: 7px 15px;
                      border: 1px solid;">Cancel</button>
                     </div> -->
      </div>
    </div>
  </div>
</div>



<!-- Modal -->
<div class="modal fade expense-det" id="addSkill" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog text-left" role="document">
    <div class="modal-content" style="width:755px">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Add Skill Information

        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">
            <div class="skillRow">
              <label>1. Total Experience</label>
              <div class="col-md-12 no-padding">
                <div class="col-md-4 d-flex align-center">
                 <label class="textskill">Years</label>
                 <ejs-dropdownlist  [dataSource]='Experaince'
                 [fields]='fields'  >
               </ejs-dropdownlist>
                </div>


                <div class="col-md-2 text-center">
                  <label class="pt-10">and</label>
                </div>
                <div class="col-md-4 d-flex align-center">
                  <label class="textskill">Months</label>
                  <ejs-dropdownlist  [dataSource]='ExperainceMonth'
                  [fields]='fields'   >
                </ejs-dropdownlist>
                </div>


              </div>
            </div>


            <div class="skillRow">
              <label>2. Current Designation</label>
              <div class="col-md-12">
                 <input class="form-control" type="text" name="designation" id="designation">
                </div>

              </div>

              <div class="skillRow">
                <label>3. Skills</label>
                <div class="col-md-12">
                  <ng-select [multiple]="true" (removed)="ddlSelection($event)"   [(items)]="SkillLists"
                  (selected)="ddlSelection($event)"  placeholder="Select Skills">
                </ng-select><br>
                <label *ngIf="ExperainceType.length > 0" class="skillHead"><b>Please provide experience and skill rating as per skills selected.</b></label>
                  </div>

                  <div class="col-md-12" *ngFor="let item of ExperainceType; let i=index">
                    <div class="ratingRow">
                      <div class="ratingAmt">
                        <div class="skillRow mb-5">
                          <label>{{item}}</label>
                          <div class="col-md-12 no-padding">
                            <div class="col-md-5 d-flex align-center no-padding">
                              <span class="skilltextlabel">Years</span>
                              <ejs-dropdownlist  [dataSource]='Experaince'
                              [fields]='fields'  >
                            </ejs-dropdownlist>
                            </div>

                            <div class="col-md-2 text-center">
                              <label class="pt-10">and</label>
                            </div>
                            <div class="col-md-5 d-flex align-center">
                              <span class="skilltextlabel">Months</span>
                              <ejs-dropdownlist  [dataSource]='ExperainceMonth'
                              [fields]='fields'   >
                            </ejs-dropdownlist>
                            </div>

                          </div>
                        </div>
                        <bar-rating name="rating"   [max]="5" required ></bar-rating>
                        <!-- [(rate)]="formRatingFeature" [(ngModel)]="formRatingFeature" -->
                      </div>
                    </div>
                  </div>

                </div>

                <button type="submit" class="btn btn-primary" style="margin-left: 10px;min-width:100px;">Save</button>

            </div>

        </div>

      </div>
    </div>
  </div>

  <!-- Modal -->
<div class="modal fade expense-det" id="exampleModal1222" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
aria-hidden="true">
<div class="modal-dialog" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="exampleModalLabel">Google Integration</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="display: inline-block;
    width: 100%;">
      <div class="col-md-12">
        <label>Client Id</label>
        <input type="text"  class="e-input" placeholder="Enter Client Id" [(ngModel)]="clientId"  />
      </div>
      <div class="col-md-12">
        <label>Secret Key</label>
        <input type="text"  class="e-input" placeholder="Enter Integration Key" [(ngModel)]="secretKey"  />
      </div>
      <div class="col-md-12">
        <label>API Key</label>
        <input type="text"  class="e-input" placeholder="Enter API Key" [(ngModel)]="tenentId"  />
      </div>
      <div class="col-md-12">
        <label>Scopes </label>
        <input type="text"  class="e-input" placeholder="Scopes" [(ngModel)]="scopes"  />
      </div>
      <div class="col-md-12" >
        <button class="btn btn-brand" (click)="saveGOOGLEIntegration(1)">
          Save
        </button>
      </div>
    </div>
  </div>
</div>
</div>

<!-- Modal -->
<div class="modal fade expense-det" id="exampleModal1232" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Microsoft Integration</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="display: inline-block;
      width: 100%;">
        <div class="col-md-12">
          <label>Client Id</label>
          <input type="text"  class="e-input" placeholder="Enter Client Id" [(ngModel)]="clientId1"  />
        </div>
        <div class="col-md-12">
          <label>Integration Key</label>
          <input type="text"  class="e-input" placeholder="Enter Integration Key" [(ngModel)]="secretKey1" />
        </div>
        <div class="col-md-12">
          <label>Tenant Id </label>
          <input type="text"  class="e-input" placeholder="Tenant Id" [(ngModel)]="tenentId1"/>
        </div>
        <div class="col-md-12">
          <label>Scopes </label>
          <input type="text"  class="e-input" placeholder="Scopes" [(ngModel)]="scopes1"/>
        </div>
        <div class="col-md-12"  >
          <button class="btn btn-brand" (click)="saveMICROSOFTIntegration(1)">Save</button>
        </div>
      </div>
    </div>
  </div>
</div>



<script type="text/javascript" src="assets/js/jquery-2.1.1.min.js"></script>
<script>
  $('input[type="checkbox"]').on('change', function () {
    $(this).siblings('input[type="checkbox"]').not(this).prop('checked', false);
  });

</script>
