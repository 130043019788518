<div class="bg-overlady" *ngIf="userLoader">
  <div class="loader-position">
    <div class="loader"></div>
    <h2>Loading....</h2>
  </div>
</div>

<div class="row">
  <div class="col-sm-12 no-padding">
    <mk-box header="" [isCollapsable]="false" [isRemovable]="false" contentStyleClasses="table-responsive">
      <!-- Filters -->
      <div class="row" style="margin:0;">
        <div class="col-md-3 no-padding">
          <h4 class="title-new title-new-100">Compensatory Off Requests</h4>
        </div>

        <div class="col-md-3 col-sm-4 text-center">
          <div class="financial-calendar">
            <button type="button" class="btn" title="Previous Year"
              (click)="getFinancialYearDetailsback($event, prevFinancialYearId)" [disabled]="prevFinancialYearId == 0" style="background: transparent;
              padding: 0;margin: 0 !important;">
              <svg id="i-chevron-left" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="20" height="20"
                fill="none" stroke="#204d74" stroke-linecap="round" stroke-linejoin="round" stroke-width="3">
                <path d="M20 30 L8 16 20 2" />
              </svg>
            </button>
            <span>&nbsp; {{financialYearText}} &nbsp;</span>
            <button type="button" class="btn" title="Next Year"
              (click)="getFinancialYearDetailsForwrd($event, nextFinancialYearId)" [disabled]="nextFinancialYearId == 0" style="background: transparent;
              padding: 0;margin: 0 !important;">
              <svg id="i-chevron-right" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="20" height="20"
                fill="none" stroke="#204d74" stroke-linecap="round" stroke-linejoin="round" stroke-width="3">
                <path d="M12 30 L24 16 12 2" />
              </svg>
            </button>
          </div>
        </div>

        <div class="col-md-3 no-padding text-center">
          <ul class="new-version comlegend">
            <li><a [ngClass]="{'activelegend': active === 0}" style="cursor:pointer"
                (click)="getlistbylegend(0)"> Total</a> : <span class="value-get get-b">{{totallength}}</span></li>
            <li><a  [ngClass]="{'activelegend': active === 1}" style="cursor:pointer"   (click)="getlistbylegend(1)">
                Pending</a>  : <span class="value-get get-o">{{pendingLength}}</span></li>
            <li><a  [ngClass]="{'activelegend': active === 2}" style="cursor:pointer"
              (click)="getlistbylegend(2)">Approved</a>  : <span class="value-get get-g">{{approvedLength}}</span></li>
            <li><a  [ngClass]="{'activelegend': active === 3}" style="cursor:pointer"
              (click)="getlistbylegend(3)"> Rejected</a>: <span class="value-get get-r" style=" background-color:red;">{{rejectedLength}}</span></li>

          </ul>
        </div>
        <!-- <div class="col-md-5" class="col-md-4 no-padding text-center">
          <ul  class="timelegend">
            <li><a
                (click)="getlistbylegend(0)"> Total</a> <span style="    background-color: rgb(7, 182, 118);"></span></li>
            <li><a  (click)="getlistbylegend(1)">
                Pending</a> <span style="    background-color: #fb9c3f;"></span></li>
            <li><a
                (click)="getlistbylegend(4)">Approved</a> <span style="    background-color: rgb(106, 13, 173);"></span></li>

            <li><a
                (click)="getlistbylegend(2)"> Rejected</a> <span style="    background-color: rgb(230, 101, 101);"></span></li>


          </ul>
        </div> -->

        <div class="col-md-3 col-sm-4 col-xs-6 form-group no-padding" style="margin-bottom: 0;margin-top: -4px; display: flex;
        align-items: center;justify-content: space-between;">
          <!-- <ng-select [allowClear]="true" [(active)]="ActiveEmployee" [(items)]="EmployeeList"
            (selected)="ddlSelection($event,'Employee')" placeholder="Select Employee">
          </ng-select> -->

          <ejs-dropdownlist id='ddlelement' #emplist [dataSource]='EmployeeList' [allowFiltering]='true'
          [fields]='employeeFields' (change)="ddlSelection($event,'Employee')"  [value]='value' [placeholder]='employeetext' width="200px"></ejs-dropdownlist>

          <div class="text-right btnset" style="padding-top:48px !important;margin-bottom: 0;">
            <button type="button" (click)="openApplyCompOffModal()" class="btn btn-primary btn-brand  pt-15 LeaveDisablesOnpre" title="Request Comp-Off">
              <img src="../assets/img/Logo/20.png" aria-hidden="true">
            </button>
            <div class="btn-group">
              <button type="button" class="btn btn-primary btn-brand" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false" title="Export">
                <img src="../assets/img/Logo/22.png">
  
              </button>
              <ul class="dropdown-menu">
                <li><a href="javascript:void(0)" (click)="export()">Excel</a></li>
                <!-- <li>
                  <a (click)="printList('tableData')">Print</a>
                </li>
                <li><a href="javascript:void(0)" (click)="exportAsCsv()">CSV</a></li> -->
              </ul>
            </div>
            <!-- <div class="btn-group">
              <ul class="paggingUl">
                <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize"></ngb-pagination>
                <li>
                  <span class="recordsMsg">{{page}}-{{pageSize}} of {{collectionSize}}</span>
  
                </li>
              </ul>
            </div> -->
          </div>
        </div>
        
      </div>

      <!-- Table -->
      <div class="row">
        <div class="col-sm-12 no-padding LeaveTypeList">
          <!-- <div class="less form-group table-responsive">
            <table class="table table-bordered user-table">
              <thead>
                <tr>
                  <ng-container>

                    <th
                      scope="col"
                      class="tableHeader"
                      sortable="empCode"
                      (sort)="onSort($event)"
                    >
                      <p class="no-padding no-margin">Employee <span><i class="fa fa-fw fa-unsorted"></i></span></p>
                    </th>
                    <th
                      scope="col"
                      lass="tableHeader"
                      sortable="workedDate"
                      (sort)="onSort($event)"
                    >
                      <p class="no-padding no-margin">Worked Date <span><i class="fa fa-fw fa-unsorted"></i></span></p>
                    </th>
                    <th
                      scope="col"
                      lass="tableHeader"
                      sortable="duration"
                      (sort)="onSort($event)"
                    >
                      <p class="no-padding no-margin">Days/Hours Taken <span><i class="fa fa-fw fa-unsorted"></i></span></p>
                    </th>
                    <th
                      scope="col"
                      lass="tableHeader"
                      sortable="expiryDate"
                      (sort)="onSort($event)"
                    >
                      <p class="no-padding no-margin">Expiry Date <span><i class="fa fa-fw fa-unsorted"></i></span></p>
                    </th>
                    <th
                      scope="col"
                      lass="tableHeader"
                      sortable="status"
                      (sort)="onSort($event)"
                    >
                      <p class="no-padding no-margin">Status <span><i class="fa fa-fw fa-unsorted"></i></span></p>
                    </th>
                    <th
                      scope="col"
                      lass="tableHeader"
                      sortable="createdDate"
                      (sort)="onSort($event)"
                    >
                      <p class="no-padding no-margin">Date of Request <span><i class="fa fa-fw fa-unsorted"></i></span></p>
                    </th>
                  </ng-container>
                </tr>
              </thead>
              <tbody *ngFor="let country of countries">
                <tr (click)="getCompOffDetails(country.compOffId, country.statusId)">
                  <td scope="row">
                    <span>
                      <img class="img-circle img-sm" src="./../../../assets/img/avatar5.png" alt="User Image">
                    </span>
                    &nbsp;
                    <span style="color:#858481;">{{ country.employeeName }}</span>
                    <span>&nbsp;-&nbsp;</span>
                    <span>{{ country.empCode }}</span>
                  </td>

                  <td> {{dateformat(country.workedDate)}}
                  </td>
                  <td>
                    <ng-container *ngIf="country.unit==1">
                      <span *ngIf="country.duration == '1'">Full Day</span>
                      <span *ngIf="country.duration == '2'">Half Day</span>
                      <span *ngIf="country.duration == '3'">Quarter Day</span>
                      <span *ngIf="country.duration != '1' && country.duration != '2' && country.duration != '3'"> {{ country.duration }}</span>
                    </ng-container>
                    <ng-container *ngIf="country.unit==2">
                      <span>{{ country.duration }}</span>
                      <span>&nbsp;&nbsp;</span>
                      <span>{{ country.unitText }}</span>
                    </ng-container>
                  </td>
                  <td>{{dateformat(country.expiryDate)}}</td>
                  <td>
                    <span *ngIf="country.statusId==1" class="status-icon"><i class="fa fa-filter pending" aria-hidden="true"></i></span>
                    <span *ngIf="country.statusId==2" class="status-icon"><i class="fa fa-thumbs-up approved"  aria-hidden="true"></i></span>
                    <span *ngIf="country.statusId==3" class="status-icon"><i class="fa fa-thumbs-down rejected" aria-hidden="true"></i></span>
                    <span *ngIf="country.statusId==4" class="status-icon"><i class="fa fa-times-circle cancelled" aria-hidden="true"></i></span>
                    <span>{{ country.status }}</span>
                  </td>
                  <td>
                    {{ country.createdDate | date }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div> -->

          <ejs-grid #overviewgrid id='overviewgrid' [dataSource]='COUNTRIES' rowHeight='38' [allowSelection]='true'
  [allowSorting]='true' height='600' [enableHover]='false' [selectionSettings]='selectionSettings'
  [filterSettings]='filterSettings'  [allowExcelExport]='true' [allowSorting]='true' [allowFiltering]='true' [enableVirtualization]='false'
  (load)='load()' [allowPaging]='true' [pageSettings]='pageSettings'>
  <e-columns>
    <e-column field='empCode' headerText='Employee Code' [visible]='false' [filter]='filter' clipMode='EllipsisWithTooltip'  width='280'>


    </e-column>
    <e-column field='employeeName' headerText='Employee' [filter]='filter' clipMode='EllipsisWithTooltip'  width='280'>
      <ng-template #template let-data>
        <span>
          <!-- <img src="../assets/img/Logo/male.png" style="width: 28px;margin-right: 5px;"> {{data.userName}} -->
          <img src="{{userImage(data)}}" style="width: 28px;margin-right: 5px; border-radius: 50%;">
          {{ data.employeeName}}
        </span>

        <!-- <span>
          <img class="img-circle img-sm" src="./../../../assets/img/avatar5.png" alt="User Image">
        </span>
        &nbsp;
        <span style="color:#858481; margin-top: 15px; display: inline-block;">{{ data.employeeName }}</span>
        <span>&nbsp;-&nbsp;</span> -->
        <span style=" margin-top: 10px;">{{ data.empCode }}</span>

      </ng-template>

    </e-column>

    <e-column field='email' headerText='Email' [visible]='false' [filter]='filter' clipMode='EllipsisWithTooltip'  width='280'>


    </e-column>




    <e-column field='workedDate' headerText='Worked Date'  [filter]='filter' clipMode='EllipsisWithTooltip'>
      <ng-template #template let-data>
        {{dateformat(data.workedDate)}}
      </ng-template>
    </e-column>

    <e-column field='workedtime' headerText='Worked Time' [visible]='false' [filter]='filter' clipMode='EllipsisWithTooltip'  width='280'>


    </e-column>

    <e-column field='requestdate' [allowFiltering]='false' headerText='Days/Hours Taken'>
    <ng-template  #template let-country>
      <ng-container *ngIf="country.unit==1">
        <span *ngIf="country.duration == '1'">Full Day</span>
        <span *ngIf="country.duration == '2'">Half Day</span>
        <span *ngIf="country.duration == '3'">Quarter Day</span>
        <span *ngIf="country.duration != '1' && country.duration != '2' && country.duration != '3'"> {{ country.duration }}</span>
      </ng-container>
      <ng-container *ngIf="country.unit==2">
        <span>{{ country.duration }}</span>
        <span>&nbsp;&nbsp;</span>
        <span>{{ country.unitText }}</span>
      </ng-container>
    </ng-template>
  </e-column>

    <e-column field='expiryDate' headerText='Expiry Date' [filter]='filter'>
      <ng-template  #template let-data>
        {{dateformat(data.expiryDate)}}
      </ng-template>
    </e-column>

    <e-column field='status' [allowFiltering]='false' headerText='Status' [allowSorting]='false'>
        <ng-template #template let-data>

          <span *ngIf="data.status=='Rejected'" class="status-icon">
            <!-- <i aria-hidden="true" class="fa fa-thumbs-down rejected" style="color: red;"></i>  -->
            <img src="../assets/img/Logo/Reject1.png" style="width: 20px;margin-right: 4px;" title="Rejected">
          </span>

          <span *ngIf="data.status=='Approved'" class="status-icon">
            <img src="../assets/img/Logo/Approve1.png" style="width: 20px;margin-right: 4px;"
              title="Approved">
            <!-- <i aria-hidden="true" class="fa fa-thumbs-up approved" style="color: #07b676;"></i>  -->
          </span>

          <span *ngIf="data.status=='Pending' || data.expenseStatus==''" class="status-icon">
            <img src="../assets/img/Logo/Pending.gif" style="width: 22px;margin-right: 4px;" title="Pending">
            <!-- <i class="fa fa-hourglass-start" aria-hidden="true" style="color: #fb9c3f;"></i>  -->
          </span>
          <span *ngIf="data.status=='Cancelled' || data.expenseStatus==''" class="status-icon">
            <img src="../assets/img/Logo/cancel.jpg" style="width: 22px;margin-right: 4px;" title="Cancelled">
            <!-- <i class="fa fa-hourglass-start" aria-hidden="true" style="color: #fb9c3f;"></i>  -->
          </span>

    </ng-template>
        <!-- <span *ngIf="country.statusId==1" class="status-icon"><i class="fa fa-filter pending" aria-hidden="true"></i></span>
        <span *ngIf="country.statusId==2" class="status-icon"><i class="fa fa-thumbs-up approved"  aria-hidden="true"></i></span>
        <span *ngIf="country.statusId==3" class="status-icon"><i class="fa fa-thumbs-down rejected" aria-hidden="true"></i></span>
        <span *ngIf="country.statusId==4" class="status-icon"><i class="fa fa-times-circle cancelled" aria-hidden="true"></i></span>
        <span>{{ country.status }}</span> -->
    </e-column>
    <e-column field='unit' headerText='unit' [visible]='false' [filter]='filter' clipMode='EllipsisWithTooltip'  width='280'>

    </e-column>

    <e-column field='createdDate' headerText='Date of Request' [filter]='filter' >
      <ng-template #template let-country>
        {{dateformat(country.createdDate)}}
      </ng-template>
    </e-column>
    <e-column field='unit' headerText='Credited' [visible]='false' [filter]='filter' clipMode='EllipsisWithTooltip'  width='280'>

    </e-column>


    <e-column field='Action' [allowFiltering]='false' headerText='Actions'
              textAlign='Center' width='100'>

              <ng-template #template let-data>

                <div class="btn-group btngg">
                  <button type="button" class="btn action-button" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false" title="Action">
                    <span style="margin-top: -16px;">... </span>
                  </button>
                  <ul class="dropdown-menu actions-dropdown">
                    <li ><span (click)="InviteEdituser(data,'view')" data-toggle="modal" data-target="#applyLeaveMode"
                     >View
                    </span> </li>

                    <!-- <li *ngIf="data.status !=='Approved'">  <span
                      (click)="InviteEdituser(data,'edit')" data-toggle="modal" data-target="#applyLeaveMode">Edit
                    </span>
                    </li> -->
            <li  *ngIf="data.status=='Pending' && year == currentyear">  <span class="disableActionbutton11"
                      (click)="InviteEdituser(data,'edit')" data-toggle="modal" data-target="#applyLeaveMode">Edit
                    </span>
                    </li>
                    <!-- <li><span (click)="deleteHoliday(data)"
                    >Delete
                    </span></li> -->




                  </ul>
                </div>
              </ng-template>
            </e-column>
            <e-column field='unit' headerText='Availed' [visible]='false' [filter]='filter' clipMode='EllipsisWithTooltip'  width='280'>

            </e-column>
            <e-column field='unit' headerText='Available' [visible]='false' [filter]='filter' clipMode='EllipsisWithTooltip'  width='280'>

            </e-column>
            <e-column field='description' headerText='Reason' [visible]='false' [filter]='filter' clipMode='EllipsisWithTooltip'  width='280'>


            </e-column>


  </e-columns>
</ejs-grid>


        </div>
      </div>

      <!-- Pagination -->
      <!-- <div class="row">
        <div class="col-sm-12">
          <div class="d-flex justify-content-between p-2" class="col-sm-12 text-right">
            <ul class="paggingUl">
              <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize"></ngb-pagination>
              <li>
                <span class="recordsMsg">No. of Records</span>
                <select class="NoOfpage" style="width: auto;" [(ngModel)]="pageSize">
                  <option  [ngValue]="10" selected >10</option>
                  <option [ngValue]="20">20</option>
                  <option [ngValue]="50">50</option>
                </select>
              </li>
            </ul>
          </div>
        </div>
      </div> -->
    </mk-box>
  </div>
</div>

<div id="applyLeaveMode" class="modal fade" role="dialog">
  <div class="modal-dialog modal-lg" style="width: 750px;">
    <div class="modal-content">
      <div class="modal-header">
        <button _ngcontent-c11="" class="close" data-dismiss="modal" type="button">×</button>
        <h4 class="modal-title">Compensatory Off Request</h4>
      </div>
      <div class="modal-body model-bg user-modal-height col-xs-12 col-sm-12 less">
        <div class="model-body col-xs-12 col-sm-12" *ngIf="generalSettingsData != null">
          <!-- <div *ngIf="showEmptyErrorMessage" class="model-title errorMessage" id="errorMessage">You are trying to submit
            an Empty Form.</div>
          <div *ngIf="duplicateMobile" class="invalid-feedback error">Mobile number cannot be same!</div>
          <div *ngIf="duplicateVendorId" class="invalid-feedback error">Vendor id cannot be same!</div> -->
          <div class="box-body no-padding">
            <form [formGroup]="compLeaveApply" (ngSubmit)="applyLeave()">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-6">
                    <label class="labelType">Employee Name <span class="red">*</span></label>
                    <!-- <select class="form-control select2" formControlName="UserId"
                      [attr.disabled]="editMode ? 'true' : null">
                      <option [ngValue]="null" disabled hidden>Select employee name</option>
                      <option *ngFor="let employee of todatlEmployeeList" [ngValue]="employee.userId">
                        {{ employee.employeeName }} - {{ employee.empCode }}
                      </option>
                    </select> -->
                    <ejs-dropdownlist  [enabled]="!disName"
                    formControlName="UserId" id='ddlelementRequest' #empName [dataSource]='EmployeeList' (change) = "selectEmp(empName.value)"
                    [allowFiltering]='true'
                    [fields]='employeeFields' [placeholder]='employeetext'>

                    </ejs-dropdownlist>

                    <div class="invalidfeedback" *ngIf="(submitted && compLeaveApply.controls.UserId.errors) || compLeaveApply.get('UserId').touched">
                      <div *ngIf="compLeaveApply.controls.UserId.errors?.required || compLeaveApply.controls.UserId.errors?.UserId">
                        <small class="text-danger">Employee name is required *</small>
                      </div>
                    </div>

                  </div>
                  <div class="col-sm-6">
                    <label class="labelType">Project Name <span class="red">*</span></label>
                    <!-- <select class="form-control select2" formControlName="UserId"
                      [attr.disabled]="editMode ? 'true' : null">
                      <option [ngValue]="null" disabled hidden>Select employee name</option>
                      <option *ngFor="let employee of todatlEmployeeList" [ngValue]="employee.userId">
                        {{ employee.employeeName }} - {{ employee.empCode }}
                      </option>
                    </select> -->
                    <ejs-dropdownlist [enabled]="view"
                    formControlName="ProjectId" id='ddProjectId' #projectName [dataSource]='ProjectList' [allowFiltering]='true'
                    [fields]='ProjectFields' placeholder = "Please Select an Project" (change) = "selectProj(projectName)">

                    </ejs-dropdownlist>
                    <div class="invalidfeedback" *ngIf="(submitted && compLeaveApply.controls.ProjectId.errors) || compLeaveApply.get('ProjectId').touched">
                      <div *ngIf="compLeaveApply.controls.ProjectId.errors?.required || compLeaveApply.controls.ProjectId.errors?.ProjectId">
                        <small class="text-danger">Project name is required *</small>
                      </div>
                    </div>



                  </div>
                </div>
              </div>


              <div class="form-group">
                <div class="row">
                  <div class="col-sm-6">
                    <label class="labelType">Worked Date <span class="red">*</span></label>
                    <div class="form-group">
                      <div class="input-group date" style="width: 100%;">
                        <!-- <div class="input-group-addon">
                          <i class="fa fa-calendar"></i>
                        </div>
                        <ng-container *ngIf="generalSettingsData.compensatoryRequestFutureDate == true">
                          <input type="text" formControlName="WorkedDate" class="e-input" name="WorkedDate"
                          autocomplete="off" bsDatepicker
                          [bsConfig]="{ containerClass: 'theme-dark-blue', customTodayClass:'custom-today-class', showWeekNumbers: false }"
                          id="dpWorkedDate" placeholder="Worked Date" (bsValueChange)="dateRangeCreated($event)"
                          required />
                        </ng-container>
                        <ng-container *ngIf="generalSettingsData.compensatoryRequestFutureDate == false">
                          <input type="text" formControlName="WorkedDate" class="e-input" name="WorkedDate"
                          autocomplete="off" bsDatepicker [maxDate]="todaysDate"
                          [bsConfig]="{ containerClass: 'theme-dark-blue', customTodayClass:'custom-today-class', showWeekNumbers: false }"
                          id="dpWorkedDate" placeholder="Worked Date" (bsValueChange)="dateRangeCreated($event)"
                          required />
                        </ng-container> -->
                        <!-- <ejs-datepicker #ejDatePicker formControlName="WorkedDate"  (change) = "selectWorkDate(workDate.value)"   [min]='today'  name="WorkedDate" [placeholder]='placeholder' floatLabelType='Auto'>
                        </ejs-datepicker>
                        <div *ngIf="WorkedDate.invalid && (WorkedDate.dirty||WorkedDate.touched)" class="alert alert-danger">
                          Work date is required *
                        </div> -->
                        <ejs-datepicker [enabled]="view" [max]="maxdate" [allowEdit]="false" formControlName="WorkedDate" [value]='dateValue' #workDate  (change) = "selectWorkDate(workDate.value)"  ></ejs-datepicker>
                      </div>
                      <div class="invalidfeedback" *ngIf="(submitted) || compLeaveApply.get('WorkedDate').touched">
                        <div *ngIf="compLeaveApply.controls.WorkedDate.errors ?.required || compLeaveApply.controls.WorkedDate.errors ?.WorkedDate">
                          <small class="text-danger" *ngIf="workdateError">Worked date is required *</small>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <label class="labelType" style="margin-top: 5px;">Unit <span class="red">*</span></label>
                    <div class="form-group">
                      <div class="custom-control custom-radio custom-control-inline">
                        <ng-container *ngIf="generalSettingsData.unitsAllowed == 1 || generalSettingsData.unitsAllowed == 3">
                          <input [ngStyle]="{'pointer-events': view==false?'none':'auto'}" type="radio" class="custom-control-input" id="chckUnitDays" name="Unit"
                          formControlName="Unit" [value]=1 (click)="setradio(1)" checked />
                          <label class="col-form-label mx-auto" style="margin-left: 10px; margin-right: 15px;" for="chckUnitDays">Days</label>
                        </ng-container>


                        <ng-container *ngIf="generalSettingsData.unitsAllowed == 2 || generalSettingsData.unitsAllowed == 3">
                          <input [ngStyle]="{'pointer-events': view==false?'none':'auto'}" type="radio" class="custom-control-input" id="chckUnitHours"
                            name="Unit" formControlName="Unit" [value]=2 (click)="setradio(2)" />
                          <label class="col-form-label mx-auto" style="margin-left: 10px;" for="chckUnitHours">Hours</label>
                        </ng-container>
                      </div>

                      <div class="invalidfeedback" *ngIf="(submitted && compLeaveApply.controls.Unit.errors) || compLeaveApply.get('Unit').touched">
                        <div *ngIf="compLeaveApply.controls.Unit.errors ?.required || compLeaveApply.controls.Unit.errors ?.Unit">
                          <small class="text-danger">Unit is required *</small>
                        </div>
                      </div>
                    </div>

                  </div>


                </div>
              </div>



              <div class="form-group" *ngIf="generalSettingsData.timeInput == true">
                <div class="row">
                  <div class="col-sm-6">
                    <label class="labelType" style="margin-top: 5px;">Duration <span class="red">*</span></label>
                    <!-- <div *ngIf="isSelected(1)">
                      <select class="form-control select2" formControlName="Duration">
                        <option [ngValue]="null" disabled hidden>Select Duration</option>
                        <option *ngFor="let type of DayTypeSelectList" [value]="type.value">{{ type.text }}</option>
                      </select>
                    </div> -->
                    <div *ngIf=" checkItem==1">
                      <div  *ngIf="checkItem==1">
                        <ejs-dropdownlist [enabled]="view" formControlName="Duration" #duration id='duration'  [(value)]="selectedItem"  name="Duration"   [(ngModel)]="Duration" #duration [dataSource]='leaveDuration' (change) = "selectDuration(duration.value)" [fields]='durationFields' [placeholder]='durationtext'></ejs-dropdownlist>
                      </div>
                    </div>
                    <div *ngIf=" checkItem==2" class="input-group"  style="width: 100%;
                    display: inline-flex;">
                      <!-- <input type="number" id="workedTimeInput" class="form-control" name="hourrs" placeholder="HH:MM" formControlName="Duration"> -->
                      <select [ngStyle]="{'pointer-events': view==false?'none':'auto'}" class="form-control" id="workedTimeInput" name="hours"  formControlName="Duration" [(ngModel)]="Duration"
                      >
                        <option value="00.50">00:30</option>
                        <option value="01.00">01:00</option>
                        <option value="01.50">01:30</option>
                        <option value="02.00">02:00</option>
                        <option value="02.50">02:30</option>
                        <option value="03.00">03:00</option>
                        <option value="03.50">03:30</option>
                        <option value="04.00">04:00</option>
                        <option value="04.50">04:30</option>
                        <option value="05.00">05:00</option>
                        <option value="05.50">05:30</option>
                        <option value="06.00">06:00</option>
                        <option value="06.50">06:30</option>
                        <option value="07.00">07:00</option>
                        <option value="07.50">07:30</option>
                        <option value="08.00">08:00</option>
                        <option value="08.50">08:30</option>
                        <option value="09.00">09:00</option>
                      </select>

                      <span style="float: left; line-height: 40px;width: 100%;">Hrs</span>
                    </div>
                    <div class="invalidfeedback" *ngIf="(submitted && compLeaveApply.controls.Duration.errors) || compLeaveApply.get('Duration').touched">
                      <div *ngIf="compLeaveApply.controls.Duration.errors?.required || compLeaveApply.controls.Duration.errors?.Duration">
                        <small class="text-danger"> Duration is required *</small>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <label class="labelType" style="margin-top: 5px;">Worked Time </label>
                    <div class="col-md-12 no-padding">
                      <div class="col-md-6" style="padding-left: 0;">
                        <input [ngStyle]="{'pointer-events': view==false?'none':'auto'}" type="time"  id="timepicker" class="form-control timepicker" placeholder="From"
                          formControlName="WorkedStartTime" (change)="calculateDuration()" />
                      </div>
                      <!-- <div
                        class="invalidfeedback"
                        *ngIf="
                          (submitted && compLeaveApply.controls.WorkedStartTime.errors) ||
                          compLeaveApply.get('WorkedStartTime').touched
                        "
                      >
                        <div
                          *ngIf="
                            compLeaveApply.controls.WorkedStartTime.errors?.required ||
                            compLeaveApply.controls.WorkedStartTime.errors?.WorkedStartTime
                          "
                        >
                          <small class="text-danger"
                            >Start time is required *</small
                          >
                        </div>
                      </div> -->

                      <div class="col-md-6 no-padding">
                        <input type="time" [ngStyle]="{'pointer-events': view==false?'none':'auto'}" id="timepickerEnd" class="form-control timepicker"
                          formControlName ="WorkedEndTime" placeholder="To" (change)="calculateDuration()" />
                      </div>

                    </div>



                  </div>


                </div>
              </div>



              <div class="form-group" style="padding-top: 5px;">
                <div class="col-sm-6">
                  <!-- <ejs-datepicker formControlName="WorkedDate" #workDate [min]='today' (change) = "selectWorkDate(workDate.value)"  ></ejs-datepicker> -->
                  <div *ngIf="setValue">
                  <label class="labelType" style="margin-top: 5px;">Expiry Date <span class="red"></span></label>
                  </div>
                  <div class="form-group">
                    <div class="input-group date" style="width: 100%;margin-top: 5px;">
                      <!-- <div class="input-group-addon">
                        <i class="fa fa-calendar"></i>
                      </div>
                      <input
                        type="text"
                        formControlName="ExpiryDate"
                        class="e-input"
                        disabled
                        name="ExpiryDate"
                        placeholder="Expiry Date"
                        [bsConfig]="{ containerClass: 'theme-dark-blue' }"
                        autocomplete="off"
                        bsDatepicker
                        [value]="currentDate | date"
                        id="DateOfBirth"
                      />-->
                      <!-- <ejs-datepicker [allowEdit]="false" formControlName="ExpiryDate" #expiryDate   ></ejs-datepicker> -->
                  <div *ngIf="false">

                        <input type="text"  disabled formControlName="ExpiryDate" #expiryDate />

                      </div>
                      <div *ngIf="setValue">
                        <span style="border-bottom: 1px solid #ccc; display: inline-block; width: 100%; padding-bottom: 6px; margin-top: 3px;">
                        {{datValue}}
                        </span>
                        <!-- <input type="text" disabled formControlName="ExpiryDate" #expiryDate /> -->

                      </div>
                    </div>

                    <!-- <div
                      class="invalidfeedback"
                      *ngIf="
                        (submitted &&
                          compLeaveApply.controls.ExpiryDate.errors) ||
                        compLeaveApply.get('ExpiryDate').touched
                      "
                    >
                      <div
                        *ngIf="
                          compLeaveApply.controls.ExpiryDate.errors
                            ?.required ||
                          compLeaveApply.controls.ExpiryDate.errors
                            ?.ExpiryDate
                        "
                      >
                        <small class="text-danger"
                          >Expiry date is required *</small
                        >
                      </div>
                    </div> -->
                  </div>
                </div>
                <div class="col-sm-6">
                <label>Description </label>
                <textarea [ngStyle]="{'pointer-events': view==false?'none':'auto'}"  formControlName="Description" class="form-control" ></textarea>
                <div class="invalidfeedback" *ngIf="(submitted && compLeaveApply.controls.Description.errors) || compLeaveApply.get('Description').touched">
                  <div *ngIf="compLeaveApply.controls.Description.errors?.required || compLeaveApply.controls.Description.errors?.Description">
                    <small class="text-danger">Leave Description is required *</small>
                  </div>
                </div>
              </div>
              </div>

              <div class="form-group row" *ngIf="chkButtonView" >
                <div class="col-sm-6">
                  <button type="submit" *ngIf="editMode == false" class="btn btn-primary btn-brand btn-sm" style="padding: 7px 26px !important;
                  font-size: 14px; margin-top: 20px;" (click)="save()">Submit</button>
                  <button type="submit" *ngIf="editMode == true " class="btn btn-primary btn-brand btn-sm" style="padding: 7px 26px !important;
                  font-size: 14px; margin-top: 20px;">Update Compensatory Off</button>

                </div>
                <div class="col-sm-6 text-right">
                  <ng-container *ngIf="editMode == true">
                    <button type="button" class="btn btn-danger btn-sm" (click)="CancelCompOffRequest()" style="padding: 7px 26px !important;
                    font-size: 14px; margin-top: 20px;">Cancel Compensatory Off</button>
                  </ng-container>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- CompOff Cancel Confirmation Modal -->
<div id="confirmCancelModel" class="modal  fade" role="dialog">
  <div class="modal-dialog modal-confirm modal-sm">
    <div class="modal-content" style="border-radius:10px; top: 150px; left: 350px;">
      <div class="modal-header success-header">
        <div class="icon-box-remove">
          <i class="fa fa-remove"></i>
        </div>
      </div>
      <div class="modal-body">
        <p class="text-center">Are you sure? You want to cancel this compensatory off request?</p>
        <div class="row">
          <div class="col-sm-12">
            <textarea type="text" class="form-control" style="width: 100%; height: auto;" rows="2"
              [(ngModel)]="cancelCompOffReason" placeholder="Cancel Reason"></textarea>
          </div>
          <div class="col-sm-12" *ngIf="isCancelReasonMandatory && isCancelReasonTextboxEmpty">
            <span class="text-danger">This field is mandatory</span>
          </div>
        </div>
      </div>
      <div class="modal-footer text-center">
        <button class="btn btn-danger messagebutton" (click)="ConfirmCompOffCancel()">
          Yes
        </button>
        <button class="btn btn-defualt messagebutton" data-dismiss="modal">
          No
        </button>
      </div>
    </div>
  </div>
</div>
<!-- CompOff Cancel Confirmation Modal end -->
<div id="MessageModel" class="modal  fade" role="dialog">
  <div class="modal-dialog modal-confirm modal-sm">
    <div class="modal-content" style="border-radius: 10px; top: 150px; left: 350px;">
      <div class="modal-header success-header">
        <button _ngcontent-icx-c7="" _ngcontent-c11="" aria-label="Close" class="close" data-dismiss="modal"
          type="button"><span _ngcontent-icx-c7="" _ngcontent-c11="" aria-hidden="true">×</span></button>
        <div class="icon-box" *ngIf="showSuccess">
          <i class="fa fa-check"></i>

        </div>
        <div class="icon-box-remove" *ngIf="!showSuccess">
          <i class="fa fa-remove"></i>
        </div>
      </div>
      <div class="modal-body">
        <p class="text-center">{{showMessage}}</p>
      </div>
      <div class="modal-footer" *ngIf="showSuccess || showWarning">
        <button class="btn btn-danger btn-block" data-dismiss="modal">Yes</button>
      </div>
      <div class="modal-footer" *ngIf="IsDelete" style="text-align: center !important;">
        <button class="btn btn-danger messagebutton" *ngIf="!isView" data-dismiss="modal"
          (click)="ConfirmDeleteAction(deleteId)">Yes</button>

      </div>
    </div>
  </div>
</div>

<div id="userMessageModel" class="modal fade" role="dialog">
  <div class="modal-dialog modal-confirm modal-sm">
    <div class="modal-content" style="border-radius: 10px; top: 150px; left: 350px;">
      <div class="modal-header success-header">
        <div class="icon-box" *ngIf="showSuccess">
          <i class="fa fa-check"></i>
        </div>
        <div class="icon-box-remove" *ngIf="!showSuccess">
          <i class="fa fa-remove"></i>
        </div>
      </div>
      <div class="modal-body">
        <p class="text-center">{{ showMessage }}</p>
      </div>
      <div class="modal-footer text-center">
        <button class="btn btn-brand btn-block" data-dismiss="modal" style="width: auto; margin: auto !important;">
          OK
        </button>
      </div>
    </div>
  </div>
</div>


