<div class="bg-overlady" *ngIf="userLoader">
  <div class="loader-position">
    <div class="loader"></div>
    <h2>Loading....</h2>
  </div>
</div>

<div class="row" style="margin:0;">

  <div class="col-sm-4 no-padding">
    <h4 class="title-new">Approval Settings</h4>
  </div>
  <div class="col-md-4 no-padding text-center">
    <ul class="new-version">
      <li  [ngClass]="{'activelegend': active === 'total'}" (click)="getvalue('total')" style="cursor:pointer">Total : <span class="value-get get-b">{{myapprovalsListLength}}</span></li>
      <li  [ngClass]="{'activelegend': active === 'active'}" (click)="getvalue('active')" style="cursor:pointer">Active : <span class="value-get get-g">{{ActiveLength}}</span></li>
      <li  [ngClass]="{'activelegend': active === 'inactive'}" (click)="getvalue('inactive')" style="cursor:pointer">In- Active : <span class="value-get get-o">{{InActiveLength}}</span></li>
    </ul>
  </div>
  <div class="col-sm-4 no-padding">
    <div class="col-md-2"></div>
    <div class="col-md-5 col-sm-3">
      <!-- <ejs-dropdownlist #kkkk (change)="setVal(i,kkkk .value)" id='ddllocation' [(ngModel)]="filterServiceId"  (change)="onChangeFilterServiceId($event);" name="location"  [fields]='EmployeeFields' [dataSource]='ddlServiceList'   placeholder = 'Select Service'></ejs-dropdownlist>  -->
      <!-- <select class="form-control" [(ngModel)]="filterServiceId" (change)="onChangeFilterServiceId($event);" style="margin-top: -4px;">
        <option value=null>All Request Types</option>
        <option *ngFor="let item of ddlServiceList" value="{{item.id}}">{{ item.text }}</option>
      </select> -->
    </div>
    <div class="col-md-5 col-sm-7 text-right btnset" style="padding-top: 46px;">
      <button type="button" *ngIf="AddPermission" data-toggle="modal" data-backdrop="static" data-keyboard="false"
        (click)="resetForm()" data-target="#addApprovalpopup" class="btn btn-primary btn-brand sm mx-auto"
        title="Add Approval">
        <img src="../assets/img/Logo/20.png">
      </button>

      <!-- <div class="btn-group">
        <ul class="paggingUl">
          <ngb-pagination [collectionSize]="myApprovalsListLength" [(page)]="myApprovalsPage"
            [pageSize]="myApprovalsPageSize"></ngb-pagination>
          <li style="margin-top: -4px;">
            <span class="recordsMsg">No. of Records</span>
            <select class="NoOfpage" style="width: auto;" [(ngModel)]="myApprovalsPageSize">
              <option [ngValue]="10">10</option>
              <option [ngValue]="20">20</option>
              <option [ngValue]="50">50</option>
            </select>
          </li>
        </ul>
        </div> -->

    </div>
  </div>

  <div class="col-md-12 col-xs-12 no-padding">

    <mk-box boxColor="white">
      <!-- <div class="row mar-bot-10">
        <div class="col-md-3 col-sm-3">
          <select class="form-control" [(ngModel)]="filterServiceId" (change)="onChangeFilterServiceId($event);">
            <option value=null>All Request Types</option>
            <option *ngFor="let item of ddlServiceList" value="{{item.id}}">{{ item.text }}</option>
          </select>
        </div>

        <div class="col-md-7 col-sm-7 text-right btnset" style="padding-top: 46px;">
          <button type="button" *ngIf="AddPermission" data-toggle="modal" data-backdrop="static" data-keyboard="false" (click)="resetForm()"
            data-target="#addApprovalpopup" class="btn btn-primary btn-brand sm mx-auto" title="Add Approval">
            <img src="../assets/img/Logo/20.png">
          </button>
        </div>

      </div> -->

      <!-- Table -->
      <div class="row">
        <div class="col-sm-12 no-padding">
          <ejs-grid #overviewgrid id='overviewgrid' [dataSource]='approvalsList' rowHeight='38' [allowSelection]='true'
            [allowSorting]='true' height='600' [enableHover]='false' [selectionSettings]='selectionSettings'
            [filterSettings]='filterSettings' [allowSorting]='true' [allowFiltering]='true'
            [enableVirtualization]='false' (load)='load()' [allowPaging]='true' [pageSettings]='pageSettings'>
            <e-columns>
              <e-column field='settingName' headerText='Name' [filter]='filter'>
              </e-column>
              <e-column field='serviceId' headerText='Form Name' [filter]='filter'>
                <ng-template #template let-item>
                  {{ getServiceText(item.serviceId) }}
                </ng-template>
              </e-column>

              <e-column field='settingDescription' headerText='Description' [filter]='filter'></e-column>
              <e-column field='isActive' headerText='Status' [allowFiltering]='false' [allowSorting]='false'>
                <ng-template #template let-item>
                  <label class="switch1">
                    <input type="checkbox" [checked]="item.isActive" name="Status"
                      (click)="StatusPermission ? ChangeStatus(item) : return">
                    <span class="slider1 round"></span>
                  </label>
                </ng-template>
              </e-column>

              <!-- <e-column field='action' headerText='Action' [filter]='filter'>
                <ng-template #template let-item>
                  <a *ngIf="ViewPermission" href="javascript:void(0)" class="view" title="View" data-toggle="modal"
                    data-target="#addApprovalpopup" data-backdrop="static" data-keyboard="false"
                    (click)="viewApprovalSettings(item)"><img src="../assets/img/Logo/25.png"></a>
                  <a class="edit" *ngIf="item.isActive == true && EditPermission" title="Edit" data-toggle="modal"
                    data-target="#addApprovalpopup" data-backdrop="static" data-keyboard="false"
                    (click)="editApprovalSettings(item)"><img src="../assets/img/Logo/26.png"></a>
                  <a *ngIf="DeletePermission" href="javascript:void(0)" class="delete" title="Delete"
                    (click)="deleteApprovalSettings(item)">
                    <img src="../assets/img/Logo/40.png">
                  </a>
                </ng-template>
              </e-column> -->


              <e-column field='action' headerText='Actions' [allowFiltering]='false' [allowSorting]='false'
                textAlign='Center' width='100px'>
                <ng-template #template let-item>
                  <div class="btn-group btngg">
                    <button type="button" class="btn action-button" data-toggle="dropdown" aria-haspopup="true"
                      aria-expanded="false" title="Action">
                      <span style="margin-top: -16px;">... </span>
                    </button>
                    <ul class="dropdown-menu actions-dropdown">
                      <li>
                        <a *ngIf="ViewPermission" href="javascript:void(0)" data-toggle="modal"
                          data-target="#addApprovalpopup" data-backdrop="static" data-keyboard="false"
                          (click)="viewApprovalSettings(item)">View</a>
                      </li>

                      <li> <a *ngIf="item.isActive == true && EditPermission" data-toggle="modal"
                          data-target="#addApprovalpopup" data-backdrop="static" data-keyboard="false"
                          (click)="editApprovalSettings(item)">Edit</a>

                      </li>

                      <li>
                        <a *ngIf="DeletePermission" href="javascript:void(0)"
                          (click)="deleteApprovalSettings(item)">Delete
                        </a>
                      </li>

                    </ul>
                  </div>
                </ng-template>
              </e-column>


            </e-columns>
          </ejs-grid>


          <!-- <div class="less form-group table-responsive">
            <table class="table table-bordered user-table">
              <thead>
                <tr>
                  <ng-container>
                    <th scope="col" class="tableHeader" style="width: 20%;">
                      <p class="no-padding no-margin">Name</p>
                    </th>
                    <th scope="col" class="tableHeader" style="width: 20%;">
                      <p class="no-padding no-margin">Form Name</p>
                    </th>
                    <th scope="col" class="tableHeader" style="width: 25%;">
                      <p class="no-padding no-margin">Description</p>
                    </th>
                    <th scope="col" class="tableHeader" style="width: 15%;">
                      <p class="no-padding no-margin">Status</p>
                    </th>
                    <th scope="col" class="tableHeader" style="width: 20%;">
                      <p class="no-padding no-margin">Action</p>
                    </th>
                  </ng-container>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of approvalsList">
                  <td>{{ item.settingName }}</td>
                  <td>{{ getServiceText(item.serviceId) }}</td>
                  <td>{{ item.settingDescription }}</td>
                  <td>
                    <label class="switch1">
                      <input type="checkbox" [checked]="item.isActive" name="Status" (click)="StatusPermission ? ChangeStatus(item) : return">
                      <span class="slider1 round"></span>
                    </label>
                  </td>
                  <td scope="row" class="mx-auto">
                    <a *ngIf="ViewPermission" href="javascript:void(0)" class="view" title="View" data-toggle="modal" data-target="#addApprovalpopup"
                      data-backdrop="static" data-keyboard="false" (click)="viewApprovalSettings(item)"><img src="../assets/img/Logo/25.png"></a>
                    <a class="edit" *ngIf="item.isActive == true && EditPermission" title="Edit" data-toggle="modal" data-target="#addApprovalpopup" data-backdrop="static"
                      data-keyboard="false" (click)="editApprovalSettings(item)"><img src="../assets/img/Logo/26.png"></a>
                    <a *ngIf="DeletePermission" href="javascript:void(0)" class="delete" title="Delete" (click)="deleteApprovalSettings(item)">
                      <img src="../assets/img/Logo/40.png">
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div> -->
        </div>
      </div>

      <!-- Pagination -->
      <!-- <div class="row">
        <div class="col-sm-12">
          <div class="d-flex justify-content-between p-2" class="col-sm-12 text-right">
            <ul class="paggingUl">
              <ngb-pagination [collectionSize]="myApprovalsListLength" [(page)]="myApprovalsPage"
                [pageSize]="myApprovalsPageSize"></ngb-pagination>
              <li>
                <span class="recordsMsg">No. of Records</span>
                <select class="NoOfpage" style="width: auto;" [(ngModel)]="myApprovalsPageSize">
                  <option [ngValue]="10">10</option>
                  <option [ngValue]="20">20</option>
                  <option [ngValue]="50">50</option>
                </select>
              </li>
            </ul>
          </div>
        </div>
      </div> -->
    </mk-box>

  </div>
</div>

<!-- Add aprroval form popup start  -->
<div id="addApprovalpopup" class="modal fade" role="dialog">
  <div class="modal-dialog modal-lg" style="width: 800px;">
    <div class="modal-content">
      <div class="modal-header">
        <button _ngcontent-c11="" class="close" data-dismiss="modal" type="button">×</button>
        <h4 class="modal-title">
          <span *ngIf="!viewMode && !editMode">Add Approval</span>
          <span *ngIf="!viewMode && editMode">Edit Approval</span>
          <span *ngIf="viewMode && !editMode">View Approval</span>
        </h4>
      </div>
      <div class="modal-body model-bg user-modal-height col-xs-12 col-sm-12 less" style="background-color: #fff; "
        *ngIf="approvalSettingsData != null && approvalSettingsData.length != 0">
        <form name="approvalSettings" (ngSubmit)="u.form.valid && saveApprovalSettings($event)" autocomplete="off"
          #u="ngForm" style="padding-top:0 !important;" novalidate>
          <div class="model-body col-xs-12 col-sm-12" style="padding: 15px 0;">
            <div class="box-body no-padding" style="overflow: hidden;">
              <div class="col-xs-12 col-sm-12 no-padding">
                <div class="m-portlet__body">

                  <div class="form-group row">
                    <div class="col-sm-6">
                      <label class="control-label">Form Name</label>

                      <!-- <ejs-dropdownlist #kkkk (change)="setVal(i,kkkk .value)" id='ddllocation' [(ngModel)]="approvalSettingsData.serviceId"
                        (change)="onChangeServiceId(serviceId.value)" name="serviceId" [fields]='EmployeeFields'
                        [dataSource]='ddlServiceList' placeholder='Select Service'></ejs-dropdownlist> -->

                      <select class="form-control" [(ngModel)]="approvalSettingsData.serviceId" name="serviceId"
                        [disabled]="viewMode" (change)="onChangeServiceId(serviceId.value)" required
                        style="border: none; border-bottom: 1px solid #ccc; padding: 0%;">
                        <option value="null" disabled>Select Service</option>
                        <option *ngFor="let item of ddlServiceList" value="{{item.id}}">{{ item.text }}</option>
                      </select>
                    </div>
                    <div class="col-sm-6">
                      <label class="control-label">Name of the Approval</label>
                      <input type="text" id="settingName" class="e-input" [(ngModel)]="approvalSettingsData.settingName"
                        name="settingName" #settingName="ngModel" [disabled]="viewMode" placeholder="Name" required />
                      <div *ngIf="(u.submitted && settingName.invalid)" class="invalid-feedback error">
                        <div *ngIf="(settingName.errors != null ? settingName.errors.required : false)">Name is required
                        </div>
                      </div>
                    </div>
                  </div>



                  <div class="form-group row">
                    <div class="col-sm-12">
                      <label class="control-label">Description</label>
                      <textarea id="settingDescription" class="e-input"
                        [(ngModel)]="approvalSettingsData.settingDescription" name="settingDescription"
                        #meetingObjective="ngModel" [disabled]="viewMode" placeholder="Description">
                      </textarea>
                    </div>

                  </div>

                  <!-- Criteria -->
                  <div class="row mar-bot-10">
                    <div class="col-sm-12">
                      <strong>Criteria</strong>
                      <div class="card-box">
                        <div class="card-box-body">

                          <div class="form-group row">
                            <div class="col-sm-12 no-padding">
                              <ng-container *ngIf="!isSetCriteriaForm">
                                <div class="criteria-text" style="text-align: center;">
                                  <p class="card-text">The workflow will be triggered when the following criteria
                                    requirements are satisfied.</p>
                                </div>
                                <div class="text-center">
                                  <button type="button" class="btn btn-primary btn-brand" name="setcreitia"
                                    (click)="setCriteriaPanel()" [disabled]="viewMode">Set Criteria</button>
                                </div>
                              </ng-container>

                              <ng-container *ngIf="isSetCriteriaForm">

                                <div class="row">
                                  <div class="col-sm-12 text-right">
                                    <span class="cursor-pointer text-danger"
                                      (click)="!viewMode ? removeCriteriaPanel() : false"><i
                                        class="fa fa-times"></i></span>
                                  </div>
                                </div>


                                <ng-container *ngFor="let item of approvalSettingsData.criteriaList; let i=index">
                                  <div class="row mar-bot-10">
                                    <div class="col-sm-1">
                                      <label class="criteria-index">{{i + 1}}</label>
                                    </div>
                                    <div class="col-sm-2">
                                      <ng-container *ngIf="i > 0">
                                        <select class="form-control"
                                          [(ngModel)]="approvalSettingsData.criteriaList[i].condition"
                                          name="condition-{{i}}" #condition="ngModel" [disabled]="viewMode"
                                          [ngClass]="{ 'is-invalid': u.submitted && condition.invalid }" required>
                                          <option value="OR">OR</option>
                                          <option value="AND">AND</option>
                                        </select>
                                        <div *ngIf="u.submitted && condition.invalid" class="invalid-feedback error">
                                          <div *ngIf="condition.errors.required">This field is required</div>
                                        </div>
                                      </ng-container>
                                    </div>
                                    <div class="col-sm-3">

                                      <div class="input-group" style="max-width: 400px;">
                                        <input type="text" id="criteriaSubTypeText-{{i}}" class="e-input"
                                          autocomplete="off"
                                          [(ngModel)]="approvalSettingsData.criteriaList[i].criteriaSubTypeText"
                                          name="criteriaSubTypeText-{{i}}" #criteriaSubTypeText="ngModel"
                                          [ngClass]="{ 'is-invalid': u.submitted && criteriaSubTypeText.invalid }"
                                          [disabled]="true" placeholder="" required />

                                        <input type="hidden" id="criteriaType-{{i}}" class="e-input" autocomplete="off"
                                          [(ngModel)]="approvalSettingsData.criteriaList[i].criteriaType"
                                          name="criteriaType-{{i}}" #criteriaType="ngModel" />
                                        <input type="hidden" id="criteriaTypeText-{{i}}" class="e-input"
                                          autocomplete="off"
                                          [(ngModel)]="approvalSettingsData.criteriaList[i].criteriaTypeText"
                                          name="criteriaTypeText-{{i}}" #criteriaTypeText="ngModel" />
                                        <input type="hidden" id="criteriaSubType-{{i}}" class="e-input"
                                          autocomplete="off"
                                          [(ngModel)]="approvalSettingsData.criteriaList[i].criteriaSubType"
                                          name="criteriaSubType-{{i}}" #criteriaSubType="ngModel" />

                                        <div class="input-group-addon">
                                          <button type="button" class="btn btn-primary" (click)="openCriteriaTypeDDL(i)"
                                            [disabled]="viewMode" style="height: 32px;
                                          position: relative;
                                          top: -3px;"><i class="fa fa-plus"></i></button>
                                          <div class="dl-drop" *ngIf="criteriaTypeActiveDropdownsList[i]">
                                            <div class="dl-droplist">
                                              <ul class="nav nav-tabs" role="tablist">
                                                <li role="presentation"
                                                  [ngClass]="{'active': (approvalSettingsData.criteriaList[i].criteriaType == 1)}">
                                                  <a href="#systemOptions" aria-controls="systemOptions" role="tab"
                                                    data-toggle="tab" class="cursor-pointer">System Options</a>
                                                </li>
                                                <li role="presentation"
                                                  [ngClass]="{'active': (approvalSettingsData.criteriaList[i].criteriaType == 2)}"
                                                  *ngIf="approvalSettingsData.serviceId == 1"><a href="#leave"
                                                    aria-controls="leave" role="tab" data-toggle="tab"
                                                    class="cursor-pointer">Leave</a></li>
                                                <li role="presentation"
                                                  [ngClass]="{'active': (approvalSettingsData.criteriaList[i].criteriaType == 4)}"
                                                  *ngIf="approvalSettingsData.serviceId == 2"><a href="#compoff"
                                                    aria-controls="compoff" role="tab" data-toggle="tab"
                                                    class="cursor-pointer">Compensatory Off</a></li>
                                                <li role="presentation"
                                                  [ngClass]="{'active': (approvalSettingsData.criteriaList[i].criteriaType == 5)}"
                                                  *ngIf="approvalSettingsData.serviceId == 3"><a href="#expense"
                                                    aria-controls="expense" role="tab" data-toggle="tab"
                                                    class="cursor-pointer">Expense</a></li>

                                                <li role="presentation"
                                                    [ngClass]="{'active': (approvalSettingsData.criteriaList[i].criteriaType == 6)}"
                                                    *ngIf="approvalSettingsData.serviceId == 4"><a href="#advancepay"
                                                      aria-controls="advancepay" role="tab" data-toggle="tab"
                                                      class="cursor-pointer">Advance Payment</a></li>

                                                <li role="presentation"
                                                  [ngClass]="{'active': (approvalSettingsData.criteriaList[i].criteriaType == 3)}">
                                                  <a href="#employee" aria-controls="employee" role="tab"
                                                    data-toggle="tab" class="cursor-pointer">Employee ID (Employee)</a>
                                                </li>
                                              </ul>
                                            </div>
                                            <div class="dl-dropdata">
                                              <div class="tab-content">
                                                <!-- System Options -->
                                                <div role="tabpanel" class="tab-pane" id="systemOptions"
                                                  [ngClass]="{'active': (approvalSettingsData.criteriaList[i].criteriaType == 1)}">
                                                  <!-- <input type="search" placeholder="Search"> -->
                                                  <div class="dl-dropselect">
                                                    <label class="cursor-pointer"
                                                      [ngClass]="{'active': (approvalSettingsData.criteriaList[i].criteriaSubType == 1)}"
                                                      (click)="setSelectedCriteriaType(1, i)">Role</label>
                                                    <label class="cursor-pointer"
                                                      [ngClass]="{'active': (approvalSettingsData.criteriaList[i].criteriaSubType == 2)}"
                                                      (click)="setSelectedCriteriaType(2, i)">Designation</label>
                                                    <label class="cursor-pointer"
                                                      [ngClass]="{'active': (approvalSettingsData.criteriaList[i].criteriaSubType == 3)}"
                                                      (click)="setSelectedCriteriaType(3, i)">Department</label>
                                                    <label class="cursor-pointer"
                                                      [ngClass]="{'active': (approvalSettingsData.criteriaList[i].criteriaSubType == 4)}"
                                                      (click)="setSelectedCriteriaType(4, i)">Location</label>
                                                  </div>
                                                </div>
                                                <!-- Leaves -->
                                                <div role="tabpanel" class="tab-pane" id="leave"
                                                  [ngClass]="{'active': (approvalSettingsData.criteriaList[i].criteriaType == 2)}"
                                                  *ngIf="approvalSettingsData.serviceId == 1">
                                                  <!-- <input type="search" placeholder="Search"> -->
                                                  <div class="dl-dropselect">
                                                    <label class="cursor-pointer"
                                                      [ngClass]="{'active': (approvalSettingsData.criteriaList[i].criteriaSubType == 11)}"
                                                      (click)="setSelectedCriteriaType(11, i)">Leave Name</label>
                                                    <label class="cursor-pointer"
                                                      [ngClass]="{'active': (approvalSettingsData.criteriaList[i].criteriaSubType == 12)}"
                                                      (click)="setSelectedCriteriaType(12, i)">Leave Type</label>
                                                    <label class="cursor-pointer"
                                                      [ngClass]="{'active': (approvalSettingsData.criteriaList[i].criteriaSubType == 13)}"
                                                      (click)="setSelectedCriteriaType(13, i)">Units</label>
                                                  </div>
                                                </div>
                                                <!-- Compensatory Off -->
                                                <div role="tabpanel" class="tab-pane" id="compoff"
                                                  [ngClass]="{'active': (approvalSettingsData.criteriaList[i].criteriaType == 4)}"
                                                  *ngIf="approvalSettingsData.serviceId == 2">
                                                  <!-- <input type="search" placeholder="Search"> -->
                                                  <div class="dl-dropselect">
                                                    <label class="cursor-pointer"
                                                      [ngClass]="{'active': (approvalSettingsData.criteriaList[i].criteriaSubType == 41)}"
                                                      (click)="setSelectedCriteriaType(41, i)">Project Name</label>
                                                    <label class="cursor-pointer"
                                                      [ngClass]="{'active': (approvalSettingsData.criteriaList[i].criteriaSubType == 42)}"
                                                      (click)="setSelectedCriteriaType(42, i)">Units</label>
                                                    <!-- <label class="cursor-pointer" (click)="setSelectedCriteriaType(42, i)">Leave Type</label> -->
                                                  </div>
                                                </div>
                                                <!-- Expense -->
                                                <div role="tabpanel" class="tab-pane" id="expense"
                                                  [ngClass]="{'active': (approvalSettingsData.criteriaList[i].criteriaType == 5)}"
                                                  *ngIf="approvalSettingsData.serviceId == 3">
                                                  <!-- <input type="search" placeholder="Search"> -->
                                                  <div class="dl-dropselect">
                                                    <label class="cursor-pointer"
                                                      [ngClass]="{'active': (approvalSettingsData.criteriaList[i].criteriaSubType == 32)}"
                                                      (click)="setSelectedCriteriaType(32, i)">Project Name</label>
                                                    <label class="cursor-pointer"
                                                      [ngClass]="{'active': (approvalSettingsData.criteriaList[i].criteriaSubType == 33)}"
                                                      (click)="setSelectedCriteriaType(33, i)"> Amount</label>
                                                    <label class="cursor-pointer"
                                                      [ngClass]="{'active': (approvalSettingsData.criteriaList[i].criteriaSubType == 4)}"
                                                      (click)="setSelectedCriteriaType(4, i)">Location</label>
                                                    <!-- <label class="cursor-pointer" (click)="setSelectedCriteriaType(42, i)">Leave Type</label> -->
                                                  </div>
                                                </div>
                                                <!-- Employee -->
                                                <!-- Advance Payments -->
                                                <div role="tabpanel" class="tab-pane" id="advancepay"
                                                [ngClass]="{'active': (approvalSettingsData.criteriaList[i].criteriaType == 6)}"
                                                *ngIf="approvalSettingsData.serviceId == 4">
                                                <!-- <input type="search" placeholder="Search"> -->
                                                <div class="dl-dropselect">
                                                  <label class="cursor-pointer"
                                                    [ngClass]="{'active': (approvalSettingsData.criteriaList[i].criteriaSubType == 44)}"
                                                    (click)="setSelectedCriteriaType(44, i)"> Amount</label>

                                                  <!-- <label class="cursor-pointer" (click)="setSelectedCriteriaType(42, i)">Leave Type</label> -->
                                                  </div>
                                                </div>
                                               <!-- Advance Payments -->
                                                <div role="tabpanel" class="tab-pane" id="employee"
                                                  [ngClass]="{'active': (approvalSettingsData.criteriaList[i].criteriaType == 3)}">
                                                  <!-- <input type="search" placeholder="Search"> -->
                                                  <div class="dl-dropselect">
                                                    <label class="cursor-pointer"
                                                      [ngClass]="{'active': (approvalSettingsData.criteriaList[i].criteriaSubType == 21)}"
                                                      (click)="setSelectedCriteriaType(21, i)">Employee ID</label>
                                                    <!-- <label class="cursor-pointer" [ngClass]="{'active': (approvalSettingsData.criteriaList[i].criteriaSubType == 22)}" (click)="setSelectedCriteriaType(22, i)">Experience</label> -->
                                                    <label class="cursor-pointer"
                                                      [ngClass]="{'active': (approvalSettingsData.criteriaList[i].criteriaSubType == 23)}"
                                                      (click)="setSelectedCriteriaType(23, i)">Role</label>
                                                    <label class="cursor-pointer"
                                                      [ngClass]="{'active': (approvalSettingsData.criteriaList[i].criteriaSubType == 24)}"
                                                      (click)="setSelectedCriteriaType(24, i)">Designation</label>
                                                    <label class="cursor-pointer"
                                                      [ngClass]="{'active': (approvalSettingsData.criteriaList[i].criteriaSubType == 25)}"
                                                      (click)="setSelectedCriteriaType(25, i)">Department</label>
                                                    <label class="cursor-pointer"
                                                      [ngClass]="{'active': (approvalSettingsData.criteriaList[i].criteriaSubType == 26)}"
                                                      (click)="setSelectedCriteriaType(26, i)">Location</label>
                                                    <label class="cursor-pointer"
                                                      [ngClass]="{'active': (approvalSettingsData.criteriaList[i].criteriaSubType == 27)}"
                                                      (click)="setSelectedCriteriaType(27, i)">Job Band</label>
                                                    <label class="cursor-pointer"
                                                      [ngClass]="{'active': (approvalSettingsData.criteriaList[i].criteriaSubType == 28)}"
                                                      (click)="setSelectedCriteriaType(28, i)">Employee Type</label>
                                                    <label class="cursor-pointer"
                                                      [ngClass]="{'active': (approvalSettingsData.criteriaList[i].criteriaSubType == 29)}"
                                                      (click)="setSelectedCriteriaType(29, i)">Gender</label>
                                                    <label class="cursor-pointer"
                                                      [ngClass]="{'active': (approvalSettingsData.criteriaList[i].criteriaSubType == 30)}"
                                                      (click)="setSelectedCriteriaType(30, i)">Reporting To</label>
                                                    <!-- <label class="cursor-pointer" [ngClass]="{'active': (approvalSettingsData.criteriaList[i].criteriaSubType == 31)}" (click)="setSelectedCriteriaType(31, i)">Date of Joining</label> -->
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div *ngIf="u.submitted && criteriaType.invalid" class="invalid-feedback error">
                                        <div *ngIf="criteriaType.errors.required">This field is required</div>
                                      </div>
                                    </div>
                                    <div class="col-sm-3 no-padding" style="padding: 0 5px 0 0; display: inline-flex; align-items: center;">
                                      <div >
                                      <ejs-dropdownlist [dataSource]='CriteriaList' [(ngModel)]="approvalSettingsData.criteriaList[i].whereCondition"
                                      (change)="onChangeCriteriaWhereCondition(whereCondition.value, i)" [enabled]="!viewMode"
                                      name="whereCondition-{{i}}" #whereCondition="ngModel" placeholder="Select"
                                      [fields]='CriteriaListfield'>

                                    </ejs-dropdownlist>
                                      <!-- <select class="form-control"
                                        [(ngModel)]="approvalSettingsData.criteriaList[i].whereCondition"
                                        name="whereCondition-{{i}}" #whereCondition="ngModel" [disabled]="viewMode"
                                        (change)="onChangeCriteriaWhereCondition(whereCondition.value, i)"
                                        [ngClass]="{ 'is-invalid': u.submitted && whereCondition.invalid }" required>
                                        <option value="==">Equal</option>
                                        <option value="===">Strictly Equal</option>
                                        <option value="!=">Not Equal</option>
                                        <option value="!==">!==</option>
                                        <option value=">">></option>
                                        <option value="<">
                                          < </option>
                                        <option value=">="> => </option>
                                        <option value="<=">
                                          <= </option>
                                      </select> -->
                                      <div *ngIf="u.submitted && whereCondition.invalid" class="invalid-feedback error">
                                        <div *ngIf="whereCondition.errors.required">This field is required</div>
                                      </div>
                                    </div>
                                      <span style="width: 25px; padding-left: 8px;">to</span>
                                    </div>


                                    <div class="col-sm-2 no-padding">
                                      <!-- <ng-container
                                        *ngIf="approvalSettingsData.criteriaList[i].whereCondition == 'Contains' || approvalSettingsData.criteriaList[i].whereCondition == 'Not Contains'">
                                        <select class="form-control"
                                          [(ngModel)]="approvalSettingsData.criteriaList[i].value" name="value-{{i}}"
                                          #value="ngModel" [disabled]="viewMode"
                                          [ngClass]="{ 'is-invalid': u.submitted && value.invalid }" required>
                                          <option value=null disabled>Select</option>
                                          <option *ngFor="let ddlItem of approvalSettingsData.criteriaList[i].ddlList"
                                            [value]="ddlItem.id">{{ ddlItem.text }}</option>
                                        </select>
                                        <div *ngIf="u.submitted && value.invalid" class="invalid-feedback error">
                                          <div *ngIf="value.errors.required">This field is required</div>
                                        </div>
                                      </ng-container> -->
                                      <!-- <div *ngIf="!checkDdl">
                                        <input type="text"  [(ngModel)]="approvalSettingsData.criteriaList[i].amount"/>


                                      </div> -->

                                      <div>
                                        <ng-container *ngIf="approvalSettingsData.criteriaList[i].criteriaSubTypeText == 'Amount'">
                                          <input

                                           type="number" class="form-control" style="margin-top: -2px;"
                                            [(ngModel)]="approvalSettingsData.criteriaList[i].value" name="value-{{i}}"
                                            #value="ngModel" [disabled]="viewMode"
                                            [ngClass]="{ 'is-invalid': u.submitted && value.invalid }">

                                        </ng-container>

                                          <ng-container *ngIf="approvalSettingsData.criteriaList[i].criteriaSubTypeText != 'Amount'">


                                          <div *ngIf="approvalSettingsData.criteriaList[i].criteriaSubType == 1">
                                            <ejs-dropdownlist #kkkk1 (change)="setVal(i,kkkk1.value)"
                                            [ngClass]="{ 'is-invalid': u.submitted && value.invalid }"
                                            [(ngModel)]="approvalSettingsData.criteriaList[i].value"
                                            name="value-{{i}}"
                                            #value="ngModel" [enabled]="!viewMode"
                                             [dataSource]='EqualTo1'  placeholder="Select"
                                            [fields]='EqualTofield1'></ejs-dropdownlist>
                                          </div>

                                          <div *ngIf="approvalSettingsData.criteriaList[i].criteriaSubType == 2">
                                            <ejs-dropdownlist #kkkk2 (change)="setVal(i,kkkk2.value)"
                                            [ngClass]="{ 'is-invalid': u.submitted && value.invalid }"
                                            [(ngModel)]="approvalSettingsData.criteriaList[i].value"
                                            name="value-{{i}}"
                                            #value="ngModel" [enabled]="!viewMode"
                                             [dataSource]='EqualTo2'  placeholder="Select"
                                            [fields]='EqualTofield2'></ejs-dropdownlist>
                                          </div>

                                          <div *ngIf="approvalSettingsData.criteriaList[i].criteriaSubType == 3">
                                            <ejs-dropdownlist #kkkk3 (change)="setVal(i,kkkk3.value)"
                                            [ngClass]="{ 'is-invalid': u.submitted && value.invalid }"
                                            [(ngModel)]="approvalSettingsData.criteriaList[i].value"
                                            name="value-{{i}}"
                                            #value="ngModel" [enabled]="!viewMode"
                                             [dataSource]='EqualTo3'  placeholder="Select"
                                            [fields]='EqualTofield3'></ejs-dropdownlist>
                                          </div>

                                          <div *ngIf="approvalSettingsData.criteriaList[i].criteriaSubType == 4">
                                            <ejs-dropdownlist #kkkk4 (change)="setVal(i,kkkk4.value)"
                                            [ngClass]="{ 'is-invalid': u.submitted && value.invalid }"
                                            [(ngModel)]="approvalSettingsData.criteriaList[i].value"
                                            name="value-{{i}}"
                                            #value="ngModel" [enabled]="!viewMode"
                                             [dataSource]='EqualTo4'  placeholder="Select"
                                            [fields]='EqualTofield4'></ejs-dropdownlist>
                                          </div>

                                          <div *ngIf="approvalSettingsData.criteriaList[i].criteriaSubType == 5">
                                            <ejs-dropdownlist #kkkk5 (change)="setVal(i,kkkk5.value)"
                                            [ngClass]="{ 'is-invalid': u.submitted && value.invalid }"
                                            [(ngModel)]="approvalSettingsData.criteriaList[i].value"
                                            name="value-{{i}}"
                                            #value="ngModel" [enabled]="!viewMode"
                                             [dataSource]='EqualTo5'  placeholder="Select"
                                            [fields]='EqualTofield5'></ejs-dropdownlist>
                                          </div>

                                          <div *ngIf="approvalSettingsData.criteriaList[i].criteriaSubType == 6">
                                            <ejs-dropdownlist #kkkk6 (change)="setVal(i,kkkk6.value)"
                                            [ngClass]="{ 'is-invalid': u.submitted && value.invalid }"
                                            [(ngModel)]="approvalSettingsData.criteriaList[i].value"
                                            name="value-{{i}}"
                                            #value="ngModel" [enabled]="!viewMode"
                                             [dataSource]='EqualTo6'  placeholder="Select"
                                            [fields]='EqualTofield6'></ejs-dropdownlist>
                                          </div>

                                          <div *ngIf="approvalSettingsData.criteriaList[i].criteriaSubType == 7">
                                            <ejs-dropdownlist #kkkk7 (change)="setVal(i,kkkk7.value)"
                                            [ngClass]="{ 'is-invalid': u.submitted && value.invalid }"
                                            [(ngModel)]="approvalSettingsData.criteriaList[i].value"
                                            name="value-{{i}}"
                                            #value="ngModel" [enabled]="!viewMode"
                                             [dataSource]='EqualTo7'  placeholder="Select"
                                            [fields]='EqualTofield7'></ejs-dropdownlist>
                                          </div>

                                          <div *ngIf="approvalSettingsData.criteriaList[i].criteriaSubType == 11">
                                            <ejs-dropdownlist #kkkk11 (change)="setVal(i,kkkk11.value)"
                                            [ngClass]="{ 'is-invalid': u.submitted && value.invalid }"
                                            [(ngModel)]="approvalSettingsData.criteriaList[i].value"
                                            name="value-{{i}}"
                                            #value="ngModel" [enabled]="!viewMode"
                                             [dataSource]='EqualTo11'  placeholder="Select"
                                            [fields]='EqualTofield11'></ejs-dropdownlist>
                                          </div>

                                          <div *ngIf="approvalSettingsData.criteriaList[i].criteriaSubType == 12">
                                            <ejs-dropdownlist #kkkk12 (change)="setVal(i,kkkk12.value)"
                                            [ngClass]="{ 'is-invalid': u.submitted && value.invalid }"
                                            [(ngModel)]="approvalSettingsData.criteriaList[i].value"
                                            name="value-{{i}}"
                                            #value="ngModel" [enabled]="!viewMode"
                                             [dataSource]='EqualTo12'  placeholder="Select"
                                            [fields]='EqualTofield12'></ejs-dropdownlist>
                                          </div>

                                          <div *ngIf="approvalSettingsData.criteriaList[i].criteriaSubType == 13">
                                            <ejs-dropdownlist #kkkk13 (change)="setVal(i,kkkk13.value)"
                                            [ngClass]="{ 'is-invalid': u.submitted && value.invalid }"
                                            [(ngModel)]="approvalSettingsData.criteriaList[i].value"
                                            name="value-{{i}}"
                                            #value="ngModel" [enabled]="!viewMode"
                                             [dataSource]='EqualTo13'  placeholder="Select"
                                            [fields]='EqualTofield13'></ejs-dropdownlist>
                                          </div>

                                          <div *ngIf="approvalSettingsData.criteriaList[i].criteriaSubType == 41">
                                            <ejs-dropdownlist #kkkk41 (change)="setVal(i,kkkk41.value)"
                                            [ngClass]="{ 'is-invalid': u.submitted && value.invalid }"
                                            [(ngModel)]="approvalSettingsData.criteriaList[i].value"
                                            name="value-{{i}}"
                                            #value="ngModel" [enabled]="!viewMode"
                                             [dataSource]='EqualTo41'  placeholder="Select"
                                            [fields]='EqualTofield41'></ejs-dropdownlist>
                                          </div>

                                          <div *ngIf="approvalSettingsData.criteriaList[i].criteriaSubType == 42">
                                            <ejs-dropdownlist #kkkk42 (change)="setVal(i,kkkk42.value)"
                                            [ngClass]="{ 'is-invalid': u.submitted && value.invalid }"
                                            [(ngModel)]="approvalSettingsData.criteriaList[i].value"
                                            name="value-{{i}}"
                                            #value="ngModel" [enabled]="!viewMode"
                                             [dataSource]='EqualTo42'  placeholder="Select"
                                            [fields]='EqualTofield42'></ejs-dropdownlist>
                                          </div>

                                          <div *ngIf="approvalSettingsData.criteriaList[i].criteriaSubType == 44">
                                            <ejs-dropdownlist #kkkk44 (change)="setVal(i,kkkk44.value)"
                                            [ngClass]="{ 'is-invalid': u.submitted && value.invalid }"
                                            [(ngModel)]="approvalSettingsData.criteriaList[i].value"
                                            name="value-{{i}}"
                                            #value="ngModel" [enabled]="!viewMode"
                                             [dataSource]='EqualTo44'  placeholder="Select"
                                            [fields]='EqualTofield44'></ejs-dropdownlist>
                                          </div>

                                          <div *ngIf="approvalSettingsData.criteriaList[i].criteriaSubType == 32">
                                            <ejs-dropdownlist #kkkk32 (change)="setVal(i,kkkk32.value)"
                                            [ngClass]="{ 'is-invalid': u.submitted && value.invalid }"
                                            [(ngModel)]="approvalSettingsData.criteriaList[i].value"
                                            name="value-{{i}}"
                                            #value="ngModel" [enabled]="!viewMode"
                                             [dataSource]='EqualTo32'  placeholder="Select"
                                            [fields]='EqualTofield32'></ejs-dropdownlist>
                                          </div>

                                          <div *ngIf="approvalSettingsData.criteriaList[i].criteriaSubType == 33">
                                            <ejs-dropdownlist #kkkk33 (change)="setVal(i,kkkk33.value)"
                                            [ngClass]="{ 'is-invalid': u.submitted && value.invalid }"
                                            [(ngModel)]="approvalSettingsData.criteriaList[i].value"
                                            name="value-{{i}}"
                                            #value="ngModel" [enabled]="!viewMode"
                                             [dataSource]='EqualTo33'  placeholder="Select"
                                            [fields]='EqualTofield33'></ejs-dropdownlist>
                                          </div>

                                          <div *ngIf="approvalSettingsData.criteriaList[i].criteriaSubType == 21">
                                            <ejs-dropdownlist #kkkk21 (change)="setVal(i,kkkk21.value)"
                                            [ngClass]="{ 'is-invalid': u.submitted && value.invalid }"
                                            [(ngModel)]="approvalSettingsData.criteriaList[i].value"
                                            name="value-{{i}}"
                                            #value="ngModel" [enabled]="!viewMode"
                                             [dataSource]='EqualTo21'  placeholder="Select"
                                            [fields]='EqualTofield21'></ejs-dropdownlist>
                                          </div>

                                          <div *ngIf="approvalSettingsData.criteriaList[i].criteriaSubType == 23">
                                            <ejs-dropdownlist #kkkk23 (change)="setVal(i,kkkk23.value)"
                                            [ngClass]="{ 'is-invalid': u.submitted && value.invalid }"
                                            [(ngModel)]="approvalSettingsData.criteriaList[i].value"
                                            name="value-{{i}}"
                                            #value="ngModel" [enabled]="!viewMode"
                                             [dataSource]='EqualTo23'  placeholder="Select"
                                            [fields]='EqualTofield23'></ejs-dropdownlist>
                                          </div>

                                          <div *ngIf="approvalSettingsData.criteriaList[i].criteriaSubType == 24">
                                            <ejs-dropdownlist #kkkk24 (change)="setVal(i,kkkk24.value)"
                                            [ngClass]="{ 'is-invalid': u.submitted && value.invalid }"
                                            [(ngModel)]="approvalSettingsData.criteriaList[i].value"
                                            name="value-{{i}}"
                                            #value="ngModel" [enabled]="!viewMode"
                                             [dataSource]='EqualTo24'  placeholder="Select"
                                            [fields]='EqualTofield24'></ejs-dropdownlist>
                                          </div>

                                          <div *ngIf="approvalSettingsData.criteriaList[i].criteriaSubType == 25">
                                            <ejs-dropdownlist #kkkk25 (change)="setVal(i,kkkk25.value)"
                                            [ngClass]="{ 'is-invalid': u.submitted && value.invalid }"
                                            [(ngModel)]="approvalSettingsData.criteriaList[i].value"
                                            name="value-{{i}}"
                                            #value="ngModel" [enabled]="!viewMode"
                                             [dataSource]='EqualTo25'  placeholder="Select"
                                            [fields]='EqualTofield25'></ejs-dropdownlist>
                                          </div>

                                          <div *ngIf="approvalSettingsData.criteriaList[i].criteriaSubType == 26">
                                            <ejs-dropdownlist #kkkk26 (change)="setVal(i,kkkk26.value)"
                                            [ngClass]="{ 'is-invalid': u.submitted && value.invalid }"
                                            [(ngModel)]="approvalSettingsData.criteriaList[i].value"
                                            name="value-{{i}}"
                                            #value="ngModel" [enabled]="!viewMode"
                                             [dataSource]='EqualTo26'  placeholder="Select"
                                            [fields]='EqualTofield26'></ejs-dropdownlist>
                                          </div>

                                          <div *ngIf="approvalSettingsData.criteriaList[i].criteriaSubType == 27">
                                            <ejs-dropdownlist #kkkk27 (change)="setVal(i,kkkk27.value)"
                                            [ngClass]="{ 'is-invalid': u.submitted && value.invalid }"
                                            [(ngModel)]="approvalSettingsData.criteriaList[i].value"
                                            name="value-{{i}}"
                                            #value="ngModel" [enabled]="!viewMode"
                                             [dataSource]='EqualTo27'  placeholder="Select"
                                            [fields]='EqualTofield27'></ejs-dropdownlist>
                                          </div>

                                          <div *ngIf="approvalSettingsData.criteriaList[i].criteriaSubType == 28">
                                            <ejs-dropdownlist #kkkk28 (change)="setVal(i,kkkk28.value)"
                                            [ngClass]="{ 'is-invalid': u.submitted && value.invalid }"
                                            [(ngModel)]="approvalSettingsData.criteriaList[i].value"
                                            name="value-{{i}}"
                                            #value="ngModel" [enabled]="!viewMode"
                                             [dataSource]='EqualTo28'  placeholder="Select"
                                            [fields]='EqualTofield28'></ejs-dropdownlist>
                                          </div>

                                          <div *ngIf="approvalSettingsData.criteriaList[i].criteriaSubType == 29">
                                            <ejs-dropdownlist #kkkk29 (change)="setVal(i,kkkk29.value)"
                                            [ngClass]="{ 'is-invalid': u.submitted && value.invalid }"
                                            [(ngModel)]="approvalSettingsData.criteriaList[i].value"
                                            name="value-{{i}}"
                                            #value="ngModel" [enabled]="!viewMode"
                                             [dataSource]='EqualTo29'  placeholder="Select"
                                            [fields]='EqualTofield29'></ejs-dropdownlist>
                                          </div>

                                          <div *ngIf="approvalSettingsData.criteriaList[i].criteriaSubType == 30">
                                            <ejs-dropdownlist #kkkk30 (change)="setVal(i,kkkk30.value)"
                                            [ngClass]="{ 'is-invalid': u.submitted && value.invalid }"
                                            [(ngModel)]="approvalSettingsData.criteriaList[i].value"
                                            name="value-{{i}}"
                                            #value="ngModel" [enabled]="!viewMode"
                                             [dataSource]='EqualTo30'  placeholder="Select"
                                            [fields]='EqualTofield30'></ejs-dropdownlist>
                                          </div>


                                        </ng-container>


                                      </div>




                                    </div>
                                    <div class="col-sm-1 text-left" style="    padding-top: 10px;">
                                      <span *ngIf="i > 0 && !viewMode" (click)="removeCriteria(i)">
                                        <i class="fa fa-minus-circle" style="font-size: large;color: #af4949;"
                                          aria-hidden="true"></i>
                                      </span>
                                      <span>&nbsp;&nbsp;&nbsp;</span>
                                      <span *ngIf="((i + 1) == approvalSettingsData.criteriaList.length) && !viewMode"
                                        (click)="addCriteria(i + 1)">
                                        <i class="fa fa-plus-circle" style="font-size: large;color: #666;"
                                          aria-hidden="true"></i>
                                      </span>
                                    </div>
                                  </div>
                                </ng-container>
                              </ng-container>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Approvals -->
                  <div class="row mar-bot-10">
                    <div class="col-sm-12">
                      <strong>Approvals</strong>
                      <div class="card-box">
                        <div class="card-box-body">

                          <div class="form-group row">
                            <div class="col-sm-12 no-padding">
                              <ng-container *ngIf="!isSetApprovalForm">
                                <div class="criteria-text" style="text-align: center;">
                                  <p class="card-text">You can either configure approver levels or allow the system to
                                    auto approve/reject the record.</p>
                                </div>
                                <div class="text-center">
                                  <button type="button" class="btn btn-primary btn-brand" name="setApproval"
                                    (click)="setApprovalPanel()" [disabled]="viewMode">Configure Approver</button>
                                </div>
                              </ng-container>

                              <ng-container *ngIf="isSetApprovalForm">

                                <div class="row">
                                  <div class="col-sm-12 text-right">
                                    <span class="cursor-pointer text-danger"
                                      (click)="!viewMode ? removeApprovalPanel() : false"><i
                                        class="fa fa-times"></i></span>
                                  </div>
                                </div>

                                <ng-container *ngFor="let item of approvalSettingsData.approvalList; let i=index">
                                  <div class="row mar-bot-10">
                                    <div class="col-sm-4">
                                      <!-- <ejs-dropdownlist #kkkk (change)="setVal(i,kkkk .value)" id='ddlCurrency' (change)="onChangeLeaveType($event);" formControlName="Type" name="Type"  [fields]='fields' [dataSource]='data'   placeholder = 'Select Type'></ejs-dropdownlist>  -->

                                      <select class="form-control"
                                        [(ngModel)]="approvalSettingsData.approvalList[i].approvalType"
                                        name="approvalType-{{i}}" #approvalType="ngModel" [disabled]="viewMode"
                                        (change)="onChangeApprovalType(approvalType.value, i)"
                                        [ngClass]="{ 'is-invalid': u.submitted && approvalType.invalid }" required>
                                        <!-- <option *ngFor="let prod of ProductHeader"  [value]="prod.id">{{prod.name}} </option>   -->
                                        <option value=null disabled>Select</option>
                                        <option [value]="1">Submited To</option>
                                        <option [value]="2">Department Head of Login User</option>
                                        <option [value]="3">Approver based on Role</option>
                                        <option [value]="4">Department Head</option>
                                        <option [value]="5">Department Members</option>
                                        <option [value]="6">Project Managers</option>
                                        <option [value]="8">Employee</option>
                                        <option [value]="9">Client</option>
                                        <option [value]="10">Project Admins</option>
                                        <option [value]="11">Reporting Manager</option>
                                        <option [value]="12">Timesheet Approval</option>
                                      </select>
                                    </div>
                                    <div class="col-sm-4">
                                      <ng-container *ngIf="approvalSettingsData.approvalList[i].approvalType != 2 &&
                                        approvalSettingsData.approvalList[i].approvalType != 6 &&
                                        approvalSettingsData.approvalList[i].approvalType != 9 &&
                                        approvalSettingsData.approvalList[i].approvalType != 10 &&
                                        approvalSettingsData.approvalList[i].approvalType != 11 &&
                                        approvalSettingsData.approvalList[i].approvalType != 12
                                         ">
                                        <select class="form-control"
                                          [(ngModel)]="approvalSettingsData.approvalList[i].value" name="value-{{i}}"
                                          #value="ngModel" [disabled]="viewMode"
                                          (change)="onChangeApprovalTypeValue(value.value, i)"
                                          [ngClass]="{ 'is-invalid': u.submitted && value.invalid }" required>
                                          <option value=null disabled>Select</option>
                                          <option
                                            *ngFor="let ddlItem of approvalSettingsData.approvalList[i].ddlValueList"
                                            [value]="ddlItem.id">{{ ddlItem.text }}</option>
                                        </select>
                                      </ng-container>
                                    </div>
                                    <div class="col-sm-4 text-left">
                                      <span *ngIf="i > 0 && !viewMode" (click)="removeApproval(i)">
                                        <i class="fa fa-minus-circle" style="font-size: large;color: #af4949;"
                                          aria-hidden="true"></i>
                                      </span>
                                      <span>&nbsp;&nbsp;&nbsp;</span>
                                      <span *ngIf="((i + 1) == approvalSettingsData.approvalList.length) && !viewMode"
                                        (click)="addApproval(i + 1)">
                                        <i class="fa fa-plus-circle" style="font-size: large;color: #666;"
                                          aria-hidden="true"></i>
                                      </span>
                                    </div>
                                  </div>
                                </ng-container>

                                <div class="row mar-bot-10">
                                  <div class="col-sm-12">
                                    <div style="border: 1px solid #ddd; padding: 19px;">
                                      <div class="row">
                                        <ng-container *ngFor="let item of ApprovalsStepList">
                                          <div class="col-sm-3 no-padding">
                                            <label style="background-color: #d5d0e0;padding: 6px;">{{ item }}</label>
                                          </div>
                                          <div class="col-sm-1">
                                            <i class="fa fa-arrow-right" style="font-size: large;color: #666;"
                                              aria-hidden="true"></i>
                                          </div>
                                        </ng-container>

                                        <div class="col-sm-2">
                                          <label>
                                            <span style="color:green">Approved</span>
                                            <span>&nbsp;/&nbsp;</span>
                                            <span style="color: red;">Rejected</span>
                                          </label>
                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                </div>
                              </ng-container>

                              <ng-container *ngIf="!isSetApprovalForm">
                                <div class="text-center">
                                  <div class="mar-bot-10"></div>
                                  <p>(OR)</p>

                                  <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" class="custom-control-input" id="chckApprovalTypeAutoApprove"
                                      [disabled]="viewMode" [(ngModel)]="approvalSettingsData.approvalType"
                                      name="approvalType" #approvalType="ngModel" [value]=1
                                      (change)="onChangeApprovalType($event, 1);" />
                                    <label class="col-form-label mx-auto" style="margin-left: 10px;"
                                      for="chckApprovalTypeAutoApprove">Auto Approve</label>
                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

                                    <input type="radio" class="custom-control-input" id="chckApprovalTypeAutoReject"
                                      [disabled]="viewMode" [(ngModel)]="approvalSettingsData.approvalType"
                                      name="approvalType" #approvalType="ngModel" [value]=2
                                      (change)="onChangeApprovalType($event, 2);" />
                                    <label class="col-form-label mx-auto" style="margin-left: 10px;"
                                      for="chckApprovalTypeAutoReject">Auto Reject</label>
                                  </div>
                                </div>
                              </ng-container>

                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group row mar-bot-10">
                    <div class="col-sm-12">
                      <div class="custom-control custom-checkbox custom-control-inline">
                        <input type="checkbox" class="custom-control-input" id="chckEnableFollowUp"
                          [disabled]="viewMode" [(ngModel)]="approvalSettingsData.enableFollowUp" name="enableFollowUp"
                          #enableFollowUp="ngModel" (change)="onChangeFollowup($event);" />
                        <label class="col-form-label mx-auto" style="margin-left: 10px;" for="chckEnableFollowUp">Enable
                          follow-up option for this approval.</label>
                      </div>
                    </div>
                    <div class="col-sm-12">
                      <ng-container *ngIf="approvalSettingsData.enableFollowUp == true">
                        <div class="row">
                          <div class="col-sm-offset-1 col-sm-11">
                            <div class="custom-control custom-radio custom-control-inline">
                              <input type="radio" class="custom-control-input" id="chckFollowUpTypeOneTime"
                                [disabled]="viewMode" [(ngModel)]="approvalSettingsData.followUpType"
                                name="followUpType" #followUpType="ngModel" [value]=1 />
                              <label class="col-form-label mx-auto" style="margin-left: 10px;"
                                for="chckFollowUpTypeOneTime">One Time</label>
                              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

                              <input type="radio" class="custom-control-input" id="chckFollowUpTypeRepeat"
                                [disabled]="viewMode" [(ngModel)]="approvalSettingsData.followUpType"
                                name="followUpType" #followUpType="ngModel" [value]=2 />
                              <label class="col-form-label mx-auto" style="margin-left: 10px;"
                                for="chckFollowUpTypeRepeat">Repeat</label>
                            </div>

                            <div class="clearfix mar-bot-10"></div>

                            <ng-container *ngIf="approvalSettingsData.followUpType == 1">
                              <div class="row mar-bot-10">

                                <div class="col-sm-3 no-padding">
                                  <span>One-time follow-up after</span>
                                </div>
                                <div class="col-sm-2 no-padding">
                                  <input type="text" class="e-input" id="txtFollowUpAfterDays"
                                    (keypress)="numberOnly($event)" [disabled]="viewMode"
                                    [(ngModel)]="approvalSettingsData.followUpAfterDays" name="followUpAfterDays"
                                    #followUpAfterDays="ngModel" style="position: relative;
                                    top: -11px;" class="form-control" />
                                </div>
                                <div class="col-sm-7">
                                  <span>day(s) from the approval trigger date.</span>
                                </div>
                              </div>

                              <div class="row">

                                <div class="col-sm-3" style="padding-left: 0;">
                                  <span>Follow-up email sent at</span>
                                </div>
                                <div class="col-sm-2 no-padding">
                                  <input type="time" id="txtFollowUpTime" [disabled]="viewMode"
                                    [(ngModel)]="approvalSettingsData.followUpTime" name="followUpTime"
                                    #followUpTime="ngModel" style="position: relative;
                                    top: -11px; line-height: inherit;" class="form-control" />
                                </div>
                                <div class="col-sm-7">&nbsp;</div>
                              </div>
                              <div class="row mar-bot-10">
                                <div class="col-sm-1">&nbsp;</div>
                                <div class="col-sm-11">
                                  <p>(Approval request time will be considered if follow-up time is not provided.)</p>
                                </div>
                              </div>
                            </ng-container>

                            <ng-container *ngIf="approvalSettingsData.followUpType == 2">
                              <div class="row mar-bot-10">
                                <div class="col-sm-1">&nbsp;</div>
                                <div class="col-sm-2 no-padding">
                                  <span>Repeat this follow-up every</span>
                                </div>
                                <div class="col-sm-2">
                                  <input type="text" id="txtFollowUpAfterDays" (keypress)="numberOnly($event)"
                                    [disabled]="viewMode" [(ngModel)]="approvalSettingsData.followUpAfterDays"
                                    name="followUpAfterDays" #followUpAfterDays="ngModel" class="e-input" />
                                </div>
                                <div class="col-sm-7">
                                  <span>day(s) from the approval trigger date till the request is approved.</span>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-sm-1">&nbsp;</div>
                                <div class="col-sm-2 no-padding">
                                  <span>Follow-up email sent at</span>
                                </div>
                                <div class="col-sm-2">
                                  <input type="time" id="txtFollowUpTime" [disabled]="viewMode"
                                    [(ngModel)]="approvalSettingsData.followUpTime" name="followUpTime"
                                    #followUpTime="ngModel" class="form-control" />
                                </div>
                                <div class="col-sm-7">&nbsp;</div>
                              </div>
                              <div class="row mar-bot-10">
                                <div class="col-sm-1">&nbsp;</div>
                                <div class="col-sm-11">
                                  <p>(Approval request time will be considered if follow-up time is not provided.)</p>
                                </div>
                              </div>
                            </ng-container>

                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>

                  <!-- Message -->
                  <div class="row mar-bot-10">
                    <div class="col-sm-12">
                      <strong>Message</strong>
                      <div class="card-box">
                        <div class="card-box-body">

                          <div class="form-group row">
                            <div class="col-sm-12 no-padding">
                              <ng-container *ngIf="!isConfigureEmailTemplate">
                                <div class="criteria-text" style="text-align: center;">
                                  <p class="card-text">Configure suitable approval message to send out to approvers &
                                    other recipient.</p>
                                </div>
                                <div class="text-center">
                                  <button type="button" class="btn btn-primary btn-brand" name="setEmailTemplate"
                                    [disabled]="viewMode" (click)="setEmailTemplatePanel()">Configure Email
                                    Template</button>
                                </div>
                              </ng-container>

                              <ng-container
                                *ngIf="isConfigureEmailTemplate && approvalSettingsData.approvalEmailTemplate != null && approvalSettingsData.approvalEmailTemplate.length != 0">
                                <div class="row">
                                  <div class="col-sm-12 text-right">
                                    <span class="cursor-pointer text-danger"
                                      (click)="!viewMode ? removeEmailPanel() : false"><i
                                        class="fa fa-times"></i></span>
                                  </div>
                                </div>
                                <div class="form-group row">
                                  <div class="col-sm-3">
                                    <label class="control-label">From</label>
                                  </div>
                                  <div class="col-sm-4">
                                    <select class="form-control"
                                      [(ngModel)]="approvalSettingsData.approvalEmailTemplate.from" name="from"
                                      #from="ngModel" [disabled]="viewMode"
                                      (change)="onChangeApprovalEmailFrom(from.value)"
                                      [ngClass]="{ 'is-invalid': u.submitted && from.invalid }" required>
                                      <option value=1>Person performing this action</option>
                                    </select>
                                  </div>
                                </div>

                                <!-- TO -->
                                <div class="form-group row">
                                  <div class="col-sm-3">
                                    <label class="control-label">To</label>
                                  </div>
                                  <div class="col-sm-4">
                                    <div class="input-group" style="max-width: 400px;">
                                      <div
                                        style="width: 100%; min-height: 35px; max-height: 100px; overflow-x: hidden; overflow-y: auto; border: 1px solid #dedede; padding: 5px;">
                                        <!-- System Options -->

                                        <ng-container *ngIf="To_SystemOptions_Selected.length > 0">
                                          <div class="row mar-bot-10">
                                            <div class="col-sm-12 text-left">
                                              <span>System Options</span>
                                            </div>
                                            <div class="col-sm-12">
                                              <ng-container *ngFor="let item of To_SystemOptions_Selected; let i=index">
                                                <span class="rt-tag">
                                                  {{ To_SystemOptions_Selected[i].text }}
                                                  <i class="fa fa-times"
                                                    (click)="removeEmailTo(1, To_SystemOptions_Selected[i])"></i>
                                                </span>
                                              </ng-container>
                                            </div>
                                          </div>
                                        </ng-container>

                                        <!-- Users -->
                                        <ng-container *ngIf="To_Users_Selected.length > 0">
                                          <div class="row mar-bot-10">
                                            <div class="col-sm-12 text-left">
                                              <span>Users</span>
                                            </div>
                                            <div class="col-sm-12">
                                              <ng-container *ngFor="let item of To_Users_Selected; let i=index">
                                                <span class="rt-tag">
                                                  {{ To_Users_Selected[i].text }}
                                                  <i class="fa fa-times"
                                                    (click)="removeEmailTo(2, To_Users_Selected[i])"></i>
                                                </span>
                                              </ng-container>
                                            </div>
                                          </div>
                                        </ng-container>

                                        <!-- Roles -->
                                        <ng-container *ngIf="To_Roles_Selected.length > 0">
                                          <div class="row mar-bot-10">
                                            <div class="col-sm-12 text-left">
                                              <span>Roles</span>
                                            </div>
                                            <div class="col-sm-12">
                                              <ng-container *ngFor="let item of To_Roles_Selected; let i=index">
                                                <span class="rt-tag">
                                                  {{ To_Roles_Selected[i].text }}
                                                  <i class="fa fa-times"
                                                    (click)="removeEmailTo(3, To_Roles_Selected[i])"></i>
                                                </span>
                                              </ng-container>
                                            </div>
                                          </div>
                                        </ng-container>

                                        <!-- Departments -->
                                        <ng-container *ngIf="To_Departments_Selected.length > 0">
                                          <div class="row mar-bot-10">
                                            <div class="col-sm-12 text-left">
                                              <span>Departments</span>
                                            </div>
                                            <div class="col-sm-12">
                                              <ng-container *ngFor="let item of To_Departments_Selected; let i=index">
                                                <span class="rt-tag">
                                                  {{ To_Departments_Selected[i].text }}
                                                  <i class="fa fa-times"
                                                    (click)="removeEmailTo(4, To_Departments_Selected[i])"></i>
                                                </span>
                                              </ng-container>
                                            </div>
                                          </div>
                                        </ng-container>

                                        <!-- Groups -->
                                        <ng-container *ngIf="To_Groups_Selected.length > 0">
                                          <div class="row mar-bot-10">
                                            <div class="col-sm-12 text-left">
                                              <span>Groups</span>
                                            </div>
                                            <div class="col-sm-12">
                                              <ng-container *ngFor="let item of To_Groups_Selected; let i=index">
                                                <span class="rt-tag">
                                                  {{ To_Groups_Selected[i].text }}
                                                  <i class="fa fa-times"
                                                    (click)="removeEmailTo(5, To_Groups_Selected[i])"></i>
                                                </span>
                                              </ng-container>
                                            </div>
                                          </div>
                                        </ng-container>

                                        <!-- Locations -->
                                        <ng-container *ngIf="To_Locations_Selected.length > 0">
                                          <div class="row mar-bot-10">
                                            <div class="col-sm-12 text-left">
                                              <span>Locations</span>
                                            </div>
                                            <div class="col-sm-12">
                                              <ng-container *ngFor="let item of To_Locations_Selected; let i=index">
                                                <span class="rt-tag">
                                                  {{ To_Locations_Selected[i].text }}
                                                  <i class="fa fa-times"
                                                    (click)="removeEmailTo(6, To_Locations_Selected[i])"></i>
                                                </span>
                                              </ng-container>
                                            </div>
                                          </div>
                                        </ng-container>

                                      </div>

                                      <div class="input-group-addon" style="">
                                        <button type="button" class="btn btn-primary" (click)="openEmailToDDL()"
                                          [disabled]="viewMode"><i class="fa fa-plus"></i></button>
                                        <div class="dl-drop" *ngIf="emailToDDLActive">
                                          <div class="dl-droplist">
                                            <ul class="nav nav-tabs" role="tablist">
                                              <li role="presentation" class="active"><a href="#email_to_systemOptions"
                                                  aria-controls="systemOptions" role="tab" data-toggle="tab"
                                                  class="cursor-pointer">System Options</a></li>
                                              <li role="presentation"><a href="#email_to_users" aria-controls="users"
                                                  role="tab" data-toggle="tab" class="cursor-pointer">Users</a></li>
                                              <li role="presentation"><a href="#email_to_roles" aria-controls="roles"
                                                  role="tab" data-toggle="tab" class="cursor-pointer">Roles</a></li>
                                              <li role="presentation"><a href="#email_to_department"
                                                  aria-controls="department" role="tab" data-toggle="tab"
                                                  class="cursor-pointer">Departments</a></li>
                                              <li role="presentation"><a href="#email_to_group" aria-controls="group"
                                                  role="tab" data-toggle="tab" class="cursor-pointer">Groups</a></li>
                                              <li role="presentation"><a href="#email_to_location"
                                                  aria-controls="locations" role="tab" data-toggle="tab"
                                                  class="cursor-pointer">Locations</a></li>
                                            </ul>
                                          </div>
                                          <div class="dl-dropdata" style="padding-top: 5px;">
                                            <div class="row">
                                              <div class="col-sm-12 text-right" style="color: #e66565;">
                                                <i class="fa fa-times cursor-pointer" (click)="closeEmailToDDL()"></i>
                                              </div>
                                            </div>
                                            <div class="tab-content">
                                              <!-- System Options -->
                                              <div role="tabpanel" class="tab-pane active" id="email_to_systemOptions">
                                                <div class="dl-dropselect">
                                                  <ng-container *ngFor="let item of To_SystemOptions; let i=index">
                                                    <div
                                                      class="custom-control custom-checkbox custom-control-inline text-left">
                                                      <input type="checkbox" id="chckTo-1-{{i}}"
                                                        class="custom-control-input" [disabled]="viewMode"
                                                        [(ngModel)]="To_SystemOptions[i].isChecked"
                                                        name="isChecked-to-1-{{i}}" #isChecked="ngModel"
                                                        (change)="onChangeEmailTo($event, 1);" />
                                                      <label class="col-form-label mx-auto" style="margin-left: 10px;"
                                                        for="chckTo-1-{{i}}">{{ To_SystemOptions[i].text }}</label>
                                                    </div>
                                                  </ng-container>
                                                </div>
                                              </div>

                                              <!-- Users -->
                                              <div role="tabpanel" class="tab-pane" id="email_to_users">
                                                <div class="dl-dropselect">
                                                  <ng-container *ngFor="let item of To_Users; let i=index">
                                                    <div
                                                      class="custom-control custom-checkbox custom-control-inline text-left">
                                                      <input type="checkbox" id="chckTo-2-{{i}}"
                                                        class="custom-control-input" [disabled]="viewMode"
                                                        [(ngModel)]="To_Users[i].isChecked" name="isChecked-to-2-{{i}}"
                                                        #isChecked="ngModel" (change)="onChangeEmailTo($event, 2);" />
                                                      <label class="col-form-label mx-auto" style="margin-left: 10px;"
                                                        for="chckTo-2-{{i}}">{{ To_Users[i].text }}</label>
                                                    </div>
                                                  </ng-container>
                                                </div>
                                              </div>

                                              <!-- Roles -->
                                              <div role="tabpanel" class="tab-pane" id="email_to_roles">
                                                <div class="dl-dropselect">
                                                  <ng-container *ngFor="let item of To_Roles; let i=index">
                                                    <div
                                                      class="custom-control custom-checkbox custom-control-inline text-left">
                                                      <input type="checkbox" id="chckTo-3-{{i}}"
                                                        class="custom-control-input" [disabled]="viewMode"
                                                        [(ngModel)]="To_Roles[i].isChecked" name="isChecked-to-3-{{i}}"
                                                        #isChecked="ngModel" (change)="onChangeEmailTo($event, 3);" />
                                                      <label class="col-form-label mx-auto" style="margin-left: 10px;"
                                                        for="chckTo-3-{{i}}">{{ To_Roles[i].text }}</label>
                                                    </div>
                                                  </ng-container>
                                                </div>
                                              </div>

                                              <!-- Departments -->
                                              <div role="tabpanel" class="tab-pane" id="email_to_department">
                                                <div class="dl-dropselect">
                                                  <ng-container *ngFor="let item of To_Departments; let i=index">
                                                    <div
                                                      class="custom-control custom-checkbox custom-control-inline text-left">
                                                      <input type="checkbox" id="chckTo-4-{{i}}"
                                                        class="custom-control-input" [disabled]="viewMode"
                                                        [(ngModel)]="To_Departments[i].isChecked"
                                                        name="isChecked-to-4-{{i}}" #isChecked="ngModel"
                                                        (change)="onChangeEmailTo($event, 4);" />
                                                      <label class="col-form-label mx-auto" style="margin-left: 10px;"
                                                        for="chckTo-4-{{i}}">{{ To_Departments[i].text }}</label>
                                                    </div>
                                                  </ng-container>
                                                </div>
                                              </div>

                                              <!-- Groups -->
                                              <div role="tabpanel" class="tab-pane" id="email_to_group">
                                                <div class="dl-dropselect">
                                                  <ng-container *ngFor="let item of To_Groups; let i=index">
                                                    <div
                                                      class="custom-control custom-checkbox custom-control-inline text-left">
                                                      <input type="checkbox" id="chckTo-5-{{i}}"
                                                        class="custom-control-input" [disabled]="viewMode"
                                                        [(ngModel)]="To_Groups[i].isChecked" name="isChecked-to-5-{{i}}"
                                                        #isChecked="ngModel" (change)="onChangeEmailTo($event, 5);" />
                                                      <label class="col-form-label mx-auto" style="margin-left: 10px;"
                                                        for="chckTo-5-{{i}}">{{ To_Groups[i].text }}</label>
                                                    </div>
                                                  </ng-container>
                                                </div>
                                              </div>

                                              <!-- Locations -->
                                              <div role="tabpanel" class="tab-pane" id="email_to_location">
                                                <div class="dl-dropselect">
                                                  <ng-container *ngFor="let item of To_Locations; let i=index">
                                                    <div
                                                      class="custom-control custom-checkbox custom-control-inline text-left">
                                                      <input type="checkbox" id="chckTo-6-{{i}}"
                                                        class="custom-control-input" [disabled]="viewMode"
                                                        [(ngModel)]="To_Locations[i].isChecked"
                                                        name="isChecked-to-6-{{i}}" #isChecked="ngModel"
                                                        (change)="onChangeEmailTo($event, 6);" />
                                                      <label class="col-form-label mx-auto" style="margin-left: 10px;"
                                                        for="chckTo-6-{{i}}">{{ To_Locations[i].text }}</label>
                                                    </div>
                                                  </ng-container>
                                                </div>
                                              </div>

                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                </div>

                                <!-- CC -->
                                <div class="form-group row">
                                  <div class="col-sm-3">
                                    <label class="control-label">CC</label>
                                  </div>
                                  <div class="col-sm-4">
                                    <div class="input-group" style="max-width: 400px;">
                                      <div
                                        style="width: 100%; min-height: 35px; max-height: 100px; overflow-x: hidden; overflow-y: auto; border: 1px solid #dedede; padding: 5px;">
                                        <!-- System Options -->
                                        <ng-container *ngIf="CC_SystemOptions_Selected.length > 0">
                                          <div class="row mar-bot-10">
                                            <div class="col-sm-12 text-left">
                                              <span>System Options</span>
                                            </div>
                                            <div class="col-sm-12">
                                              <ng-container *ngFor="let item of CC_SystemOptions_Selected; let i=index">
                                                <span class="rt-tag">
                                                  {{ CC_SystemOptions_Selected[i].text }}
                                                  <i class="fa fa-times"
                                                    (click)="removeEmailCC(1, CC_SystemOptions_Selected[i])"></i>
                                                </span>
                                              </ng-container>
                                            </div>
                                          </div>
                                        </ng-container>

                                        <!-- Users -->
                                        <ng-container *ngIf="CC_Users_Selected.length > 0">
                                          <div class="row mar-bot-10">
                                            <div class="col-sm-12 text-left">
                                              <span>Users</span>
                                            </div>
                                            <div class="col-sm-12">
                                              <ng-container *ngFor="let item of CC_Users_Selected; let i=index">
                                                <span class="rt-tag">
                                                  {{ CC_Users_Selected[i].text }}
                                                  <i class="fa fa-times"
                                                    (click)="removeEmailCC(2, CC_Users_Selected[i])"></i>
                                                </span>
                                              </ng-container>
                                            </div>
                                          </div>
                                        </ng-container>

                                        <!-- Roles -->
                                        <ng-container *ngIf="CC_Roles_Selected.length > 0">
                                          <div class="row mar-bot-10">
                                            <div class="col-sm-12 text-left">
                                              <span>Roles</span>
                                            </div>
                                            <div class="col-sm-12">
                                              <ng-container *ngFor="let item of CC_Roles_Selected; let i=index">
                                                <span class="rt-tag">
                                                  {{ CC_Roles_Selected[i].text }}
                                                  <i class="fa fa-times"
                                                    (click)="removeEmailCC(3, CC_Roles_Selected[i])"></i>
                                                </span>
                                              </ng-container>
                                            </div>
                                          </div>
                                        </ng-container>

                                        <!-- Departments -->
                                        <ng-container *ngIf="CC_Departments_Selected.length > 0">
                                          <div class="row mar-bot-10">
                                            <div class="col-sm-12 text-left">
                                              <span>Departments</span>
                                            </div>
                                            <div class="col-sm-12">
                                              <ng-container *ngFor="let item of CC_Departments_Selected; let i=index">
                                                <span class="rt-tag">
                                                  {{ CC_Departments_Selected[i].text }}
                                                  <i class="fa fa-times"
                                                    (click)="removeEmailCC(4, CC_Departments_Selected[i])"></i>
                                                </span>
                                              </ng-container>
                                            </div>
                                          </div>
                                        </ng-container>

                                        <!-- Groups -->
                                        <ng-container *ngIf="CC_Groups_Selected.length > 0">
                                          <div class="row mar-bot-10">
                                            <div class="col-sm-12 text-left">
                                              <span>Groups</span>
                                            </div>
                                            <div class="col-sm-12">
                                              <ng-container *ngFor="let item of CC_Groups_Selected; let i=index">
                                                <span class="rt-tag">
                                                  {{ CC_Groups_Selected[i].text }}
                                                  <i class="fa fa-times"
                                                    (click)="removeEmailCC(5, CC_Groups_Selected[i])"></i>
                                                </span>
                                              </ng-container>
                                            </div>
                                          </div>
                                        </ng-container>

                                        <!-- Locations -->
                                        <ng-container *ngIf="CC_Locations_Selected.length > 0">
                                          <div class="row mar-bot-10">
                                            <div class="col-sm-12 text-left">
                                              <span>Locations</span>
                                            </div>
                                            <div class="col-sm-12">
                                              <ng-container *ngFor="let item of CC_Locations_Selected; let i=index">
                                                <span class="rt-tag">
                                                  {{ CC_Locations_Selected[i].text }}
                                                  <i class="fa fa-times"
                                                    (click)="removeEmailCC(6, CC_Locations_Selected[i])"></i>
                                                </span>
                                              </ng-container>
                                            </div>
                                          </div>
                                        </ng-container>

                                      </div>

                                      <div class="input-group-addon" style="">
                                        <button type="button" class="btn btn-primary" (click)="openEmailCCDDL()"
                                          [disabled]="viewMode"><i class="fa fa-plus"></i></button>
                                        <div class="dl-drop" *ngIf="emailCCDDLActive">
                                          <div class="dl-droplist">
                                            <ul class="nav nav-tabs" role="tablist">
                                              <li role="presentation" class="active"><a href="#email_cc_systemOptions"
                                                  aria-controls="systemOptions" role="tab" data-toggle="tab"
                                                  class="cursor-pointer">System Options</a></li>
                                              <li role="presentation"><a href="#email_cc_users" aria-controls="users"
                                                  role="tab" data-toggle="tab" class="cursor-pointer">Users</a></li>
                                              <li role="presentation"><a href="#email_cc_roles" aria-controls="roles"
                                                  role="tab" data-toggle="tab" class="cursor-pointer">Roles</a></li>
                                              <li role="presentation"><a href="#email_cc_department"
                                                  aria-controls="department" role="tab" data-toggle="tab"
                                                  class="cursor-pointer">Departments</a></li>
                                              <li role="presentation"><a href="#email_cc_group" aria-controls="group"
                                                  role="tab" data-toggle="tab" class="cursor-pointer">Groups</a></li>
                                              <li role="presentation"><a href="#email_cc_location"
                                                  aria-controls="locations" role="tab" data-toggle="tab"
                                                  class="cursor-pointer">Locations</a></li>
                                            </ul>
                                          </div>
                                          <div class="dl-dropdata" style="padding-top: 5px;">
                                            <div class="row">
                                              <div class="col-sm-12 text-right" style="color: #e66565;">
                                                <i class="fa fa-times cursor-pointer" (click)="closeEmailCCDDL()"></i>
                                              </div>
                                            </div>
                                            <div class="tab-content">
                                              <!-- System Options -->
                                              <div role="tabpanel" class="tab-pane active" id="email_cc_systemOptions">
                                                <div class="dl-dropselect">
                                                  <ng-container *ngFor="let item of CC_SystemOptions; let i=index">
                                                    <div
                                                      class="custom-control custom-checkbox custom-control-inline text-left">
                                                      <input type="checkbox" id="chckCC-1-{{i}}"
                                                        class="custom-control-input" [disabled]="viewMode"
                                                        [(ngModel)]="CC_SystemOptions[i].isChecked"
                                                        name="isChecked-cc-1-{{i}}" #isChecked="ngModel"
                                                        (change)="onChangeEmailCC($event, 1);" />
                                                      <label class="col-form-label mx-auto" style="margin-left: 10px;"
                                                        for="chckCC-1-{{i}}">{{ CC_SystemOptions[i].text }}</label>
                                                    </div>
                                                  </ng-container>
                                                </div>
                                              </div>

                                              <!-- Users -->
                                              <div role="tabpanel" class="tab-pane" id="email_cc_users">
                                                <div class="dl-dropselect">
                                                  <ng-container *ngFor="let item of CC_Users; let i=index">
                                                    <div
                                                      class="custom-control custom-checkbox custom-control-inline text-left">
                                                      <input type="checkbox" id="chckCC-2-{{i}}"
                                                        class="custom-control-input" [disabled]="viewMode"
                                                        [(ngModel)]="CC_Users[i].isChecked" name="isChecked-cc-2-{{i}}"
                                                        #isChecked="ngModel" (change)="onChangeEmailCC($event, 2);" />
                                                      <label class="col-form-label mx-auto" style="margin-left: 10px;"
                                                        for="chckCC-2-{{i}}">{{ CC_Users[i].text }}</label>
                                                    </div>
                                                  </ng-container>
                                                </div>
                                              </div>

                                              <!-- Roles -->
                                              <div role="tabpanel" class="tab-pane" id="email_cc_roles">
                                                <div class="dl-dropselect">
                                                  <ng-container *ngFor="let item of CC_Roles; let i=index">
                                                    <div
                                                      class="custom-control custom-checkbox custom-control-inline text-left">
                                                      <input type="checkbox" id="chckCC-3-{{i}}"
                                                        class="custom-control-input" [disabled]="viewMode"
                                                        [(ngModel)]="CC_Roles[i].isChecked" name="isChecked-cc-3-{{i}}"
                                                        #isChecked="ngModel" (change)="onChangeEmailCC($event, 3);" />
                                                      <label class="col-form-label mx-auto" style="margin-left: 10px;"
                                                        for="chckCC-3-{{i}}">{{ CC_Roles[i].text }}</label>
                                                    </div>
                                                  </ng-container>
                                                </div>
                                              </div>

                                              <!-- Departments -->
                                              <div role="tabpanel" class="tab-pane" id="email_cc_department">
                                                <div class="dl-dropselect">
                                                  <ng-container *ngFor="let item of CC_Departments; let i=index">
                                                    <div
                                                      class="custom-control custom-checkbox custom-control-inline text-left">
                                                      <input type="checkbox" id="chckCC-4-{{i}}"
                                                        class="custom-control-input" [disabled]="viewMode"
                                                        [(ngModel)]="CC_Departments[i].isChecked"
                                                        name="isChecked-cc-4-{{i}}" #isChecked="ngModel"
                                                        (change)="onChangeEmailCC($event, 4);" />
                                                      <label class="col-form-label mx-auto" style="margin-left: 10px;"
                                                        for="chckCC-4-{{i}}">{{ CC_Departments[i].text }}</label>
                                                    </div>
                                                  </ng-container>
                                                </div>
                                              </div>

                                              <!-- Groups -->
                                              <div role="tabpanel" class="tab-pane" id="email_cc_group">
                                                <div class="dl-dropselect">
                                                  <ng-container *ngFor="let item of CC_Groups; let i=index">
                                                    <div
                                                      class="custom-control custom-checkbox custom-control-inline text-left">
                                                      <input type="checkbox" id="chckCC-5-{{i}}"
                                                        class="custom-control-input" [disabled]="viewMode"
                                                        [(ngModel)]="CC_Groups[i].isChecked" name="isChecked-cc-5-{{i}}"
                                                        #isChecked="ngModel" (change)="onChangeEmailCC($event, 5);" />
                                                      <label class="col-form-label mx-auto" style="margin-left: 10px;"
                                                        for="chckCC-5-{{i}}">{{ CC_Groups[i].text }}</label>
                                                    </div>
                                                  </ng-container>
                                                </div>
                                              </div>

                                              <!-- Locations -->
                                              <div role="tabpanel" class="tab-pane" id="email_cc_location">
                                                <div class="dl-dropselect">
                                                  <ng-container *ngFor="let item of CC_Locations; let i=index">
                                                    <div
                                                      class="custom-control custom-checkbox custom-control-inline text-left">
                                                      <input type="checkbox" id="chckCC-6-{{i}}"
                                                        class="custom-control-input" [disabled]="viewMode"
                                                        [(ngModel)]="CC_Locations[i].isChecked"
                                                        name="isChecked-cc-6-{{i}}" #isChecked="ngModel"
                                                        (change)="onChangeEmailCC($event, 6);" />
                                                      <label class="col-form-label mx-auto" style="margin-left: 10px;"
                                                        for="chckCC-6-{{i}}">{{ CC_Locations[i].text }}</label>
                                                    </div>
                                                  </ng-container>
                                                </div>
                                              </div>

                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                </div>

                                <!-- BCC -->
                                <div class="form-group row">
                                  <div class="col-sm-3">
                                    <label class="control-label">BCC</label>
                                  </div>
                                  <div class="col-sm-4">
                                    <div class="input-group" style="max-width: 400px;">
                                      <div
                                        style="width: 100%; min-height: 35px; max-height: 100px; overflow-x: hidden; overflow-y: auto; border: 1px solid #dedede; padding: 5px;">
                                        <!-- System Options -->
                                        <ng-container *ngIf="BCC_SystemOptions_Selected.length > 0">
                                          <div class="row mar-bot-10">
                                            <div class="col-sm-12 text-left">
                                              <span>System Options</span>
                                            </div>
                                            <div class="col-sm-12">
                                              <ng-container
                                                *ngFor="let item of BCC_SystemOptions_Selected; let i=index">
                                                <span class="rt-tag">
                                                  {{ BCC_SystemOptions_Selected[i].text }}
                                                  <i class="fa fa-times"
                                                    (click)="removeEmailBCC(1, BCC_SystemOptions_Selected[i])"></i>
                                                </span>
                                              </ng-container>
                                            </div>
                                          </div>
                                        </ng-container>

                                        <!-- Users -->
                                        <ng-container *ngIf="BCC_Users_Selected.length > 0">
                                          <div class="row mar-bot-10">
                                            <div class="col-sm-12 text-left">
                                              <span>Users</span>
                                            </div>
                                            <div class="col-sm-12">
                                              <ng-container *ngFor="let item of BCC_Users_Selected; let i=index">
                                                <span class="rt-tag">
                                                  {{ BCC_Users_Selected[i].text }}
                                                  <i class="fa fa-times"
                                                    (click)="removeEmailBCC(2, BCC_Users_Selected[i])"></i>
                                                </span>
                                              </ng-container>
                                            </div>
                                          </div>
                                        </ng-container>

                                        <!-- Roles -->
                                        <ng-container *ngIf="BCC_Roles_Selected.length > 0">
                                          <div class="row mar-bot-10">
                                            <div class="col-sm-12 text-left">
                                              <span>Roles</span>
                                            </div>
                                            <div class="col-sm-12">
                                              <ng-container *ngFor="let item of BCC_Roles_Selected; let i=index">
                                                <span class="rt-tag">
                                                  {{ BCC_Roles_Selected[i].text }}
                                                  <i class="fa fa-times"
                                                    (click)="removeEmailBCC(2, BCC_Roles_Selected[i])"></i>
                                                </span>
                                              </ng-container>
                                            </div>
                                          </div>
                                        </ng-container>

                                        <!-- Departments -->
                                        <ng-container *ngIf="BCC_Departments_Selected.length > 0">
                                          <div class="row mar-bot-10">
                                            <div class="col-sm-12 text-left">
                                              <span>Departments</span>
                                            </div>
                                            <div class="col-sm-12">
                                              <ng-container *ngFor="let item of BCC_Departments_Selected; let i=index">
                                                <span class="rt-tag">
                                                  {{ BCC_Departments_Selected[i].text }}
                                                  <i class="fa fa-times"
                                                    (click)="removeEmailBCC(3, BCC_Departments_Selected[i])"></i>
                                                </span>
                                              </ng-container>
                                            </div>
                                          </div>
                                        </ng-container>

                                        <!-- Groups -->
                                        <ng-container *ngIf="BCC_Groups_Selected.length > 0">
                                          <div class="row mar-bot-10">
                                            <div class="col-sm-12 text-left">
                                              <span>Groups</span>
                                            </div>
                                            <div class="col-sm-12">
                                              <ng-container *ngFor="let item of BCC_Groups_Selected; let i=index">
                                                <span class="rt-tag">
                                                  {{ BCC_Groups_Selected[i].text }}
                                                  <i class="fa fa-times"
                                                    (click)="removeEmailBCC(4, BCC_Groups_Selected[i])"></i>
                                                </span>
                                              </ng-container>
                                            </div>
                                          </div>
                                        </ng-container>

                                        <!-- Locations -->
                                        <ng-container *ngIf="BCC_Locations_Selected.length > 0">
                                          <div class="row mar-bot-10">
                                            <div class="col-sm-12 text-left">
                                              <span>Locations</span>
                                            </div>
                                            <div class="col-sm-12">
                                              <ng-container *ngFor="let item of BCC_Locations_Selected; let i=index">
                                                <span class="rt-tag">
                                                  {{ BCC_Locations_Selected[i].text }}
                                                  <i class="fa fa-times"
                                                    (click)="removeEmailBCC(5, BCC_Locations_Selected[i])"></i>
                                                </span>
                                              </ng-container>
                                            </div>
                                          </div>
                                        </ng-container>

                                      </div>

                                      <div class="input-group-addon" style="">
                                        <button type="button" class="btn btn-primary" (click)="openEmailBCCDDL()"
                                          [disabled]="viewMode"><i class="fa fa-plus"></i></button>
                                        <div class="dl-drop" *ngIf="emailBCCDDLActive">
                                          <div class="dl-droplist">
                                            <ul class="nav nav-tabs" role="tablist">
                                              <li role="presentation" class="active"><a href="#email_bcc_systemOptions"
                                                  aria-controls="systemOptions" role="tab" data-toggle="tab"
                                                  class="cursor-pointer">System Options</a></li>
                                              <li role="presentation"><a href="#email_bcc_users" aria-controls="users"
                                                  role="tab" data-toggle="tab" class="cursor-pointer">Users</a></li>
                                              <li role="presentation"><a href="#email_bcc_roles" aria-controls="roles"
                                                  role="tab" data-toggle="tab" class="cursor-pointer">Roles</a></li>
                                              <li role="presentation"><a href="#email_bcc_department"
                                                  aria-controls="department" role="tab" data-toggle="tab"
                                                  class="cursor-pointer">Departments</a></li>
                                              <li role="presentation"><a href="#email_bcc_group" aria-controls="group"
                                                  role="tab" data-toggle="tab" class="cursor-pointer">Groups</a></li>
                                              <li role="presentation"><a href="#email_bcc_location"
                                                  aria-controls="locations" role="tab" data-toggle="tab"
                                                  class="cursor-pointer">Locations</a></li>
                                            </ul>
                                          </div>
                                          <div class="dl-dropdata" style="padding-top: 5px;">
                                            <div class="row">
                                              <div class="col-sm-12 text-right" style="color: #e66565;">
                                                <i class="fa fa-times cursor-pointer" (click)="closeEmailCCDDL()"></i>
                                              </div>
                                            </div>
                                            <div class="tab-content">
                                              <!-- System Options -->
                                              <div role="tabpanel" class="tab-pane active" id="email_bcc_systemOptions">
                                                <div class="dl-dropselect">
                                                  <ng-container *ngFor="let item of BCC_SystemOptions; let i=index">
                                                    <div
                                                      class="custom-control custom-checkbox custom-control-inline text-left">
                                                      <input type="checkbox" id="chckBCC-1-{{i}}"
                                                        class="custom-control-input" [disabled]="viewMode"
                                                        [(ngModel)]="BCC_SystemOptions[i].isChecked"
                                                        name="isChecked-bcc-1-{{i}}" #isChecked="ngModel"
                                                        (change)="onChangeEmailBCC($event, 1);" />
                                                      <label class="col-form-label mx-auto" style="margin-left: 10px;"
                                                        for="chckBCC-1-{{i}}">{{ BCC_SystemOptions[i].text }}</label>
                                                    </div>
                                                  </ng-container>
                                                </div>
                                              </div>

                                              <!-- Users -->
                                              <div role="tabpanel" class="tab-pane" id="email_bcc_users">
                                                <div class="dl-dropselect">
                                                  <ng-container *ngFor="let item of BCC_Users; let i=index">
                                                    <div
                                                      class="custom-control custom-checkbox custom-control-inline text-left">
                                                      <input type="checkbox" id="chckBCC-2-{{i}}"
                                                        class="custom-control-input" [disabled]="viewMode"
                                                        [(ngModel)]="BCC_Users[i].isChecked"
                                                        name="isChecked-bcc-2-{{i}}" #isChecked="ngModel"
                                                        (change)="onChangeEmailBCC($event, 2);" />
                                                      <label class="col-form-label mx-auto" style="margin-left: 10px;"
                                                        for="chckBCC-2-{{i}}">{{ BCC_Users[i].text }}</label>
                                                    </div>
                                                  </ng-container>
                                                </div>
                                              </div>

                                              <!-- Roles -->
                                              <div role="tabpanel" class="tab-pane" id="email_bcc_roles">
                                                <div class="dl-dropselect">
                                                  <ng-container *ngFor="let item of BCC_Roles; let i=index">
                                                    <div
                                                      class="custom-control custom-checkbox custom-control-inline text-left">
                                                      <input type="checkbox" id="chckBCC-3-{{i}}"
                                                        class="custom-control-input" [disabled]="viewMode"
                                                        [(ngModel)]="BCC_Roles[i].isChecked"
                                                        name="isChecked-bcc-3-{{i}}" #isChecked="ngModel"
                                                        (change)="onChangeEmailBCC($event, 3);" />
                                                      <label class="col-form-label mx-auto" style="margin-left: 10px;"
                                                        for="chckBCC-3-{{i}}">{{ BCC_Roles[i].text }}</label>
                                                    </div>
                                                  </ng-container>
                                                </div>
                                              </div>

                                              <!-- Departments -->
                                              <div role="tabpanel" class="tab-pane" id="email_bcc_department">
                                                <div class="dl-dropselect">
                                                  <ng-container *ngFor="let item of BCC_Departments; let i=index">
                                                    <div
                                                      class="custom-control custom-checkbox custom-control-inline text-left">
                                                      <input type="checkbox" id="chckBCC-4-{{i}}"
                                                        class="custom-control-input" [disabled]="viewMode"
                                                        [(ngModel)]="BCC_Departments[i].isChecked"
                                                        name="isChecked-bcc-4-{{i}}" #isChecked="ngModel"
                                                        (change)="onChangeEmailBCC($event, 4);" />
                                                      <label class="col-form-label mx-auto" style="margin-left: 10px;"
                                                        for="chckBCC-4-{{i}}">{{ BCC_Departments[i].text }}</label>
                                                    </div>
                                                  </ng-container>
                                                </div>
                                              </div>

                                              <!-- Groups -->
                                              <div role="tabpanel" class="tab-pane" id="email_bcc_group">
                                                <div class="dl-dropselect">
                                                  <ng-container *ngFor="let item of BCC_Groups; let i=index">
                                                    <div
                                                      class="custom-control custom-checkbox custom-control-inline text-left">
                                                      <input type="checkbox" id="chckBCC-5-{{i}}"
                                                        class="custom-control-input" [disabled]="viewMode"
                                                        [(ngModel)]="BCC_Groups[i].isChecked"
                                                        name="isChecked-bcc-5-{{i}}" #isChecked="ngModel"
                                                        (change)="onChangeEmailBCC($event, 5);" />
                                                      <label class="col-form-label mx-auto" style="margin-left: 10px;"
                                                        for="chckBCC-5-{{i}}">{{ BCC_Groups[i].text }}</label>
                                                    </div>
                                                  </ng-container>
                                                </div>
                                              </div>

                                              <!-- Locations -->
                                              <div role="tabpanel" class="tab-pane" id="email_bcc_location">
                                                <div class="dl-dropselect">
                                                  <ng-container *ngFor="let item of BCC_Locations; let i=index">
                                                    <div
                                                      class="custom-control custom-checkbox custom-control-inline text-left">
                                                      <input type="checkbox" id="chckBCC-6-{{i}}"
                                                        class="custom-control-input" [disabled]="viewMode"
                                                        [(ngModel)]="BCC_Locations[i].isChecked"
                                                        name="isChecked-bcc-6-{{i}}" #isChecked="ngModel"
                                                        (change)="onChangeEmailBCC($event, 6);" />
                                                      <label class="col-form-label mx-auto" style="margin-left: 10px;"
                                                        for="chckBCC-6-{{i}}">{{ BCC_Locations[i].text }}</label>
                                                    </div>
                                                  </ng-container>
                                                </div>
                                              </div>

                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                </div>

                                <div class="form-group row">
                                  <div class="col-sm-3">
                                    <label class="control-label">Email Template</label>
                                  </div>
                                  <div class="col-sm-4">
                                    <select class="form-control" [(ngModel)]="emailTemplateId" name="emailTemplateId"
                                      [disabled]="viewMode" (change)="onChangeEmailTemplate()">
                                      <option value="null" disabled>Select</option>
                                      <option *ngFor="let item of emailTemplatesList" value="{{item.emailTemplateId}}">
                                        {{ item.subject }}</option>
                                    </select>
                                  </div>
                                </div>

                                <div class="form-group row">
                                  <div class="col-sm-3">
                                    <label class="control-label">Subject</label>
                                  </div>
                                  <div class="col-sm-4">
                                    <input type="text" id="subject" class="form-control"
                                      [(ngModel)]="approvalSettingsData.approvalEmailTemplate.subject" name="subject"
                                      #subject="ngModel" [disabled]="viewMode" placeholder="Subject" />
                                  </div>
                                </div>

                                <div class="form-group row">
                                  <div class="col-sm-3">
                                    <label class="control-label">Message</label>
                                  </div>
                                  <div class="col-sm-9">
                                    <span class="cursor-pointer rt-editTemplate" (click)="editEmailTemplate()"
                                      *ngIf="!isEditModeEmailTemplate">Edit Template</span>
                                  </div>
                                </div>

                                <div class="form-group row">
                                  <div class="col-sm-12">
                                    <ng-container *ngIf="isEditModeEmailTemplate == false">
                                      <app-ngx-editor [config]="editorConfig" [spellcheck]="true" name="emailBody"
                                        #emailBody="ngModel"
                                        [(ngModel)]="approvalSettingsData.approvalEmailTemplate.emailBody"
                                        [enableToolbar]="false" [editable]="false"></app-ngx-editor>
                                    </ng-container>
                                    <ng-container *ngIf="isEditModeEmailTemplate == true">

                                      <div class="row">
                                        <div class="col-sm-12">
                                          <div class="card-box">
                                            <div class="card-box-header">
                                              <div class="row">
                                                <div class="col-sm-4">
                                                  <label class="control-label">Select Field</label>
                                                  <select class="form-control" [(ngModel)]="predefinedTextSelected"
                                                    name="emailTemplatePredefinedTexts" [disabled]="viewMode">
                                                    <option value="null" disabled>Select</option>
                                                    <option *ngFor="let item of emailTemplatesPredefinedTextList"
                                                      value="{{item.value}}">{{ item.text }}</option>
                                                  </select>
                                                </div>
                                                <div class="col-sm-4">
                                                  <label class="control-label">Field Value</label>
                                                  <div class="clearfix"></div>
                                                  <span>{{predefinedTextSelected}}</span>
                                                </div>
                                                <div class="col-sm-4">
                                                  <label class="control-label">&nbsp;</label>
                                                  <div class="clearfix"></div>
                                                  <button type="button"
                                                    class="btn btn-primary btn-xs btn-brand pull-right"
                                                    (click)="insertPredefinedParameter()"
                                                    [disabled]="(predefinedTextSelected == null || predefinedTextSelected == '') ? true : false">Insert</button>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="card-box-body no-padding">
                                              <app-ngx-editor id="txtEditor" [config]="editorConfig" [spellcheck]="true"
                                                name="emailBody" #emailBody="ngModel"
                                                [(ngModel)]="approvalSettingsData.approvalEmailTemplate.emailBody"
                                                [enableToolbar]="true" [editable]="true"></app-ngx-editor>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </ng-container>

                                    <!-- <textarea class="form-control" [(ngModel)]="approvalSettingsData.approvalEmailTemplate.emailBody"
                                      name="emailBody" #emailBody="ngModel" [disabled]="viewMode" placeholder="Email"></textarea> -->
                                  </div>
                                </div>

                              </ng-container>

                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="row" *ngIf="!viewMode">
              <div class="col-sm-6 text-left no-padding">
                <button id="btnSave" type="submit" class="btn btn-primary btn-brand"> Save
                </button>
                <!-- <button type="button" class="btn btn-danger"
                  style="background-color: #fff !important; color: #e66565; margin:10px;" id="CancelBtn" data-dismiss="modal">
                  Cancel
                </button> -->
              </div>

              <div class="col-sm-6 text-right">
                <div *ngIf="saveMessage != null && saveMessage.length != 0" style="margin-top: 10px">
                  <span class="text-danger">{{ saveMessage }}</span>
                </div>
              </div>

            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<!-- Approval popup end -->

<!-- Message Modal -->
<div id="userMessageModel" class="modal  fade" role="dialog">
  <div class="modal-dialog modal-confirm modal-sm">
    <div class="modal-content" style="border-radius:10px; top: 150px; left: 350px;">
      <div class="modal-header success-header">
        <div class="icon-box" *ngIf="showSuccess">
          <i class="fa fa-check"></i>
        </div>
        <div class="icon-box-remove" *ngIf="!showSuccess">
          <i class="fa fa-remove"></i>
        </div>
      </div>
      <div class="modal-body">
        <p class="text-center">{{showMessage}}</p>
      </div>
      <div class="modal-footer">
        <button class="btn btn-success btn-block" data-dismiss="modal">OK</button>
      </div>
    </div>
  </div>
</div>
<!-- Message Modal end -->

<!-- Delete Confirmation Modal -->
<div id="confirmDeleteModel" class="modal  fade" role="dialog">
  <div class="modal-dialog modal-confirm modal-sm">
    <div class="modal-content" style="border-radius:10px; top: 150px; left: 350px;" *ngIf="IsDelete">
      <div class="modal-header success-header">
        <button _ngcontent-icx-c7="" _ngcontent-c11="" aria-label="Close" class="close" data-dismiss="modal"
          type="button"><span _ngcontent-icx-c7="" _ngcontent-c11="" aria-hidden="true">×</span></button>
        <div _ngcontent-fop-c7="" class="icon-box-remove"><i _ngcontent-fop-c7="" class="fa fa-remove"></i></div>
      </div>
      <div class="modal-body">
        <p class="text-center">{{showMessage}}</p>
      </div>
      <div class="modal-footer" style="text-align: center !important;">
        <button class="btn btn-danger messagebutton" (click)="ConfirmDelete(deleteId)">
          OK
        </button>

      </div>
    </div>
  </div>
</div>
<!-- Delete Confirmation Modal end -->
