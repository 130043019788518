<div class="bg-overlady" *ngIf="userLoader">
  <div class="loader-position">
    <div class="loader"></div>
    <h2>Loading....</h2>
  </div>
</div>
<mk-box header="" [isCollapsable]="false" [isRemovable]="false" contentStyleClasses="table-responsive" id="">
  <div class="row" style="margin: 0;">
    <div class="col-md-3 no-padding">
      <h4 class="title-new">Leave Dashboard</h4>
    </div>

    <div class="col-md-4 col-sm-4">
      <div class="financial-calendar">
        <button type="button" class="btn" title="Previous Year"
          (click)="getFinancialYearDetailsback($event, prevFinancialYearId)" [disabled]="prevFinancialYearId == 0">
          <svg id="i-chevron-left" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="20" height="20"
            fill="none" stroke="#204d74" stroke-linecap="round" stroke-linejoin="round" stroke-width="3">
            <path d="M20 30 L8 16 20 2" />
          </svg>
        </button>
        <span>&nbsp; {{financialYearText}} &nbsp;</span>
        <button type="button" class="btn" title="Next Year"
          (click)="getFinancialYearDetailsForwrd($event, nextFinancialYearId)" [disabled]="nextFinancialYearId == 0">
          <svg id="i-chevron-right" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="20" height="20"
            fill="none" stroke="#204d74" stroke-linecap="round" stroke-linejoin="round" stroke-width="3">
            <path d="M12 30 L24 16 12 2" />
          </svg>
        </button>
      </div>
    </div>

    <!-- <div class="col-md-3 col-sm-4 no-padding" style="margin-top: -4px;">
      <ng-select [allowClear]="true" [(active)]="ActiveEmployee" [(items)]="EmployeeList"
        (selected)="ddlSelection($event,'Employee')" placeholder="Select Employee">
      </ng-select>
    </div> -->


     <div class="col-md-3 col-sm-4 no-padding" style="margin-top: -4px;">
      <div class="leaveTeam">
      <ejs-dropdownlist id='ddlelement' #emplist [dataSource]='EmployeeList' [allowFiltering]='true'
       [fields]='employeeFields' [value]="value" (change)="ddlSelection($event,'Employee')"
       [placeholder]='text' ></ejs-dropdownlist>
      </div>
    </div>



    <div class="col-md-2 col-sm-4 text-right btnset" style="padding-top:50px;">
      <button  type="button" (click)="popUPCloseReset()" class="btn btn-primary btn-brand pt-15 LeaveTypeList" data-toggle="modal"
        data-backdrop="static" data-keyboard="false" data-target="#applyLeaveEditMode" title="Apply Leave">
        <img src="../assets/img/Logo/20.png" aria-hidden="true">
      </button>

      <div class="btn-group">
        <!-- <div class="dropdown rt-dropdown">
          <label class="col-form-label" data-toggle="dropdown"><i class="glyphicon glyphicon-calendar" aria-hidden="true"></i></label>
          <ul class="dropdown-menu pull-right list-group" style="margin-top: 10px;">
            <li  class="list-group-item active"><button class="dropdown-item btn btn-primary" (click)="logText(2)" type="button" #loginRef1><i  class="fa fa-refresh dropdown-item active" aria-hidden="true"></i> Sync with google-Leaves</button></li>
            <li  class="list-group-item active" ><button class="dropdown-item btn btn-primary" (click)="logText(1)" type="button" #loginRef2><i  class="fa fa-refresh dropdown-item active" aria-hidden="true"></i> Sync with google-Holidays</button></li>
          </ul>
        </div> -->

        <!-- <div class="dropdown" style="min-width: auto;">
          <label class="col-form-label btn" data-toggle="dropdown" style="padding-top: 10px;">
            <i class="fa fa-calendar-check-o" aria-hidden="true" style="margin-top: 0;
            font-size: 22px;"></i></label>
          <ul class="dropdown-menu pull-right">
            <li (click)="CalendarSync()"><i class="fa fa-refresh" aria-hidden="true"></i> Calendar Sync</li>
          </ul>
        </div> -->
      </div>
    </div>

  </div>
</mk-box>

<div class="leave-page">
  <div class="leave-page-inner">
    <div class="row LeaveDisablesOnpre">
      <div class="col-md-2 col-xs-12 item-leave-row" *ngFor="let leavetype of userAvalLeave; let i= index">
        <mk-box (click)="selectLeaveID(leavetype.leaveTypeId, leavetype.availableLeaves)"  data-toggle="modal"
          data-backdrop="static" data-keyboard="false" data-target="#applyLeaveEditMode">
          <div style="">
            <div style="text-align: left;">
              <strong>{{ leavetype.leaveTypeName }}</strong>
            </div>
            <div class="row leaveLayout">
              <div class="col-md-4 no-padding">
                <div class="rt-applicable-leave-icon">
                  <a>
                    <img src="{{ leavetype.imagePath }}" alt="LeaveIcon" />
                  </a>
                </div>

                <!-- <i
              class="fa fa-fw fa-calendar-check-o"
              style="font-size: xxx-large; line-height: 2; margin-left: 40px;"
            ></i> -->
              </div>
              <div class="showLeaveAVl col-md-8 no-padding" style="margin-top: 12px;">
                <div class="col-md-12 no-padding leave-dues">
                  <div class="col-md-8"> Available </div>
                  <div class="col-md-4"> <span>{{leavetype.availableLeaves}}</span></div>
                </div>

                <div class="col-md-12 no-padding leave-dues">
                  <div class="col-md-8">Booked </div>
                  <div class="col-md-4 leaveCol" style="display: flex; align-items: center;"><span>{{leavetype.takenLeaves}}</span>
                    <div class="myDIV"><i class="fa fa-info-circle" aria-hidden="true"></i>
                      <div class="hides">
                        <div class="col-md-12 no-padding">
                          <div class="col-md-8 no-padding">Used</div>
                          <div class="col-md-4 text-right">{{ leavetype.usedleave  }}</div>
                        </div>
                        <div class="col-md-12 no-padding" style="margin-top: 5px;">
                          <div class="col-md-8 no-padding">Planned</div>
                          <div class="col-md-4 text-right">{{ leavetype.planedleave }}</div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </mk-box>
      </div>
    </div>
    <div class="row">
      <!-- <span style="color: #333;
  font-weight: 600;
  font-size: 17px;
  padding-bottom: 5px;
  display: inline-block;
  border-bottom: 2px solid;
  margin: 20px;">Leave/Holidays List</span> -->
      <div class="col-md-12 col-xs-12 no-padding" style="background: #fff; border: 1px solid #ddd; padding: 15px !important;">

        <ul class="nav nav-tabs" role="tablist" style="border-bottom: none;">
          <li class="nav-item active">
            <a class="nav-link active" data-toggle="tab" href="#Holidays" role="tab">Holidays</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#Leaves" role="tab">Leaves</a>
          </li>
        </ul>

        <div class="tab-content" style="border-top: 2px solid #ececec;">

          <div class="tab-pane active" id="Holidays" role="tabpanel">
            <ejs-grid #overviewgrid id='overviewgrid' [dataSource]='allHolidayList' rowHeight='38'
              [allowSelection]='true' [allowSorting]='true' height='600' [enableHover]='false'
              [selectionSettings]='selectionSettings' [filterSettings]='filterSettings' [allowSorting]='true'
              [allowFiltering]='true' [enableVirtualization]='false' (load)='load()' [allowPaging]='true'
              [pageSettings]='pageSettings'>
              <e-columns>
                <e-column field='HolidayStartDate' headerText='Holiday Date' [filter]='filter'>
                  <ng-template #template let-holidayList>
                    <i class="fa fa-calendar"></i>
                    {{dateformat(holidayList.HolidayStartDate)}}
                  </ng-template>
                </e-column>
                <e-column field='day' headerText='Day' [filter]='filter'>
                  <ng-template #template let-holidayList>
                    {{holidayList.day}}
                  </ng-template>
                </e-column>

                <e-column field="HolidayTitle" headerText='Holiday Title' [filter]='filter'>
                  <ng-template #template let-holidayList>
                    {{ holidayList.HolidayTitle }}
                  </ng-template>
                </e-column>
                <e-column field="holidaytype" headerText='Holiday Type' [filter]='filter'>
                  <ng-template #template let-holidayList>
                    {{ holidayList.holidaytype }}
                  </ng-template>
                </e-column>
              </e-columns>
            </ejs-grid>

          </div>
          <div class="tab-pane disableActionbutton11" id="Leaves" role="tabpanel">
            <!-- {{COUNTRIES | json}} -->
            <ejs-grid #overviewgrid id='overviewgrid' [dataSource]='COUNTRIES' rowHeight='38' [allowSelection]='true'
              [allowSorting]='true' height='600' [enableHover]='false' [selectionSettings]='selectionSettings'
              [filterSettings]='filterSettings' [allowSorting]='true' [allowFiltering]='true'
              [enableVirtualization]='false' (load)='load()' [allowPaging]='true' [pageSettings]='pageSettings'>
              <e-columns>
                <e-column field='employeeName' headerText='Employee' [filter]='filter' clipMode='EllipsisWithTooltip'>
                  <ng-template #template let-country>
                     <!-- <span><img class="img-circle img-sm" src="./../../../assets/img/avatar5.png"
                        alt="User Image"></span>  -->
                        <span>
                          <!-- <img src="../assets/img/Logo/male.png" style="width: 28px;margin-right: 5px;"> {{data.userName}} -->
                          <img src="{{userImage(country)}}" style="width: 28px;margin-right: 5px; border-radius: 50%;">
                          {{ country.employeeName}}
                        </span>

                        <!-- <span style="color:#858481;margin-left: 10px;">{{ country.employeeName}} -->
                      <!-- - </span><span style="margin-left: 5px;">{{ country.empCode }}  </span>-->
                  </ng-template>
                </e-column>
                <e-column field='leaveTypeName' headerText='Leave type ' [filter]='filter'
                  clipMode='EllipsisWithTooltip'>
                </e-column>

                <e-column field='type' headerText='Type' [filter]='filter'></e-column>

                <e-column field='leaveStartDate' headerText='Leave Period' [filter]='filter' width='200'>
                  <ng-template #template let-country>
                    {{ dateformat(country.leaveStartDate) }} -
                    {{ dateformat(country.leaveEndDate) }}
                  </ng-template>
                </e-column>

                <e-column field="totalDays" headerText='Days/Hours Taken' [filter]='filter'>
                  <ng-template #template let-country>
                    {{ country.totalDays }} {{country.dayHoursTaken}}
                  </ng-template>
                </e-column>

                <e-column field='statusText' headerText='Date of Request' [filter]='filter'>
                  <ng-template #template let-country>
                    {{ dateformat(country.createdDate) }}
                  </ng-template>
                </e-column>

                <e-column field='status' headerText='Status' [filter]='filter'>
                  <ng-template #template let-data>

                    <span *ngIf="data.status=='Rejected'" class="status-icon">
                      <!-- <i aria-hidden="true" class="fa fa-thumbs-down rejected" style="color: red;"></i>  -->
                      <img src="../assets/img/Logo/Reject1.png" style="width: 20px;margin-right: 4px;" title="Rejected">
                    </span>

                    <span *ngIf="data.status=='Approved'" class="status-icon">
                      <img src="../assets/img/Logo/Approve1.png" style="width: 20px;margin-right: 4px;"
                        title="Approved">
                      <!-- <i aria-hidden="true" class="fa fa-thumbs-up approved" style="color: #07b676;"></i>  -->
                    </span>

                    <span *ngIf="data.status=='Pending' || data.expenseStatus==''" class="status-icon">
                      <img src="../assets/img/Logo/Pending.gif" style="width: 22px;margin-right: 4px;" title="Pending">
                      <!-- <i class="fa fa-hourglass-start" aria-hidden="true" style="color: #fb9c3f;"></i>  -->
                    </span>
                    <span *ngIf="data.status=='Cancelled' || data.expenseStatus==''" class="status-icon">
                      <img src="../assets/img/Logo/cancel.jpg" style="width: 22px;margin-right: 4px;" title="Cancelled">
                      <!-- <i class="fa fa-hourglass-start" aria-hidden="true" style="color: #fb9c3f;"></i>  -->
                    </span>

                  </ng-template>
                </e-column>



                <e-column field='Action' [allowFiltering]='false' headerText='Actions' [allowSorting]='false' textAlign='Center' width='100'>
                  <ng-template #template  let-data>
                    <div class="btn-group btngg">
                      <button type="button" class="btn action-button" data-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false" title="Action">
                        <span style="margin-top: -16px;" >... </span>
                      </button>
                      <ul class="dropdown-menu actions-dropdown">
                         <li >
                          <a data-toggle="modal" data-target="#applyLeaveViewMode"
                          (click)="viewLeave(data)">View
                          </a>
                        </li>

                        <li *ngIf="data.status=='Pending' && data.vaildLeavetype">
                          <span *ngIf=" data.pastdates==0" style="padding: 0;">
                            <a data-toggle="modal" data-target="#applyLeaveEditMode"
                             (click)="editLeave(data)" >Edit

                            </a>
                          </span>

                        </li>

                        <!-- <li >
                          <a  data-toggle="modal" data-target="#MessageModel1"
                          (click)="Deleteleave(country)">Delete

                          </a>
                        </li> -->

                      </ul>
                    </div>
                  </ng-template>
                </e-column>
              </e-columns>
            </ejs-grid>

          </div>
        </div>


        <!-- <mk-box boxColor="white">

     <mk-tabs>
        <mk-tab >
          <mk-tab-header>
            <span style="font-size: 15px;">Holidays</span>
          </mk-tab-header>
          <mk-tab-content>
            <div class="table-responsive" style="overflow: hidden;">
              <div class="row" *ngFor="let holidayList of allHolidayList" style="margin: 0;">
                <div class="col-sm-2" style="font-size: 15px;
                background: #f5f7f7;
                padding: 7px !important;
                border: 1px solid #ddd;">
                  <i class="fa fa-calendar"></i>
                  {{dateformat(holidayList.HolidayStartDate)}}
                </div>
                <div class="col-sm-3" style="font-size: 15px;

                padding: 7px !important;
                border: 1px solid #ddd;">
                  <p style="margin-bottom: 0;">{{ holidayList.HolidayTitle }}</p>
                </div>
              </div>
            </div>
          </mk-tab-content>
        </mk-tab>
        <mk-tab>
          <mk-tab-header>
            <span style="font-size: 15px;">Leaves</span>
          </mk-tab-header>
          <mk-tab-content>
            <div class="col-sm-12 less form-group table-responsive">
              <table class="table table-bordered user-table">
                <thead>
                  <tr>
                    <ng-container>

                      <th scope="col" class="tableHeader" sortable="empCode" (sort)="onSort($event)">
                        <p class="no-padding no-margin">Employee <span><i class="fa fa-fw fa-unsorted"></i></span></p>
                      </th>
                      <th scope="col" lass="tableHeader" sortable="leaveTypeName" (sort)="onSort($event)">
                        <p class="no-padding no-margin">Leave type <span><i class="fa fa-fw fa-unsorted"></i></span></p>
                      </th>
                      <th scope="col" lass="tableHeader" sortable="leaveTypeName" (sort)="onSort($event)">
                        <p class="no-padding no-margin">Type <span><i class="fa fa-fw fa-unsorted"></i></span></p>
                      </th>
                      <th scope="col" lass="tableHeader" sortable="leaveStartDate" (sort)="onSort($event)">
                        <p class="no-padding no-margin">Leave Period <span><i class="fa fa-fw fa-unsorted"></i></span>
                        </p>
                      </th>
                      <th scope="col" lass="tableHeader" sortable="totalDays" (sort)="onSort($event)">
                        <p class="no-padding no-margin">Days/Hours Taken <span><i
                              class="fa fa-fw fa-unsorted"></i></span></p>
                      </th>
                      <th scope="col" lass="tableHeader" sortable="status" (sort)="onSort($event)">
                        <p class="no-padding no-margin">Status <span><i class="fa fa-fw fa-unsorted"></i></span></p>
                      </th>
                      <th scope="col" lass="tableHeader" sortable="createdDate" (sort)="onSort($event)">
                        <p class="no-padding no-margin">Date of Request <span><i
                              class="fa fa-fw fa-unsorted"></i></span></p>
                      </th>
                    </ng-container>
                  </tr>
                </thead>
                <tbody *ngFor="let country of countries">
                  <tr (click)="getLeaveDetails(country.leaveId, country.statusId)">
                    <td scope="row">
                      <span><img class="img-circle img-sm" src="./../../../assets/img/avatar5.png"
                          alt="User Image"></span><span
                        style="color:#858481;margin-left: 10px;">{{ country.employeeName}} - </span><span
                        style="margin-left: 5px;">{{ country.empCode }} </span></td>

                    <td class="text-center">{{ country.leaveTypeName }}</td>
                    <td class="text-center"> {{country.type}}</td>

                    <td class="text-center">
                      {{ country.leaveStartDate | date }} -
                      {{ country.leaveEndDate | date }}
                    </td>
                    <td class="text-center">{{ country.totalDays }} Day(s)</td>
                    <td class="text-center">{{ country.status }}</td>
                    <td class="text-center">
                      {{ country.createdDate | date }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="d-flex justify-content-between p-2" class="col-sm-12 text-right">
              <ul class="paggingUl">
                <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize">
                </ngb-pagination>
                <li>
                  <span class="recordsMsg">No. of Records</span>
                  <select class="NoOfpage" style="width: auto;" [(ngModel)]="pageSize">
                    <option [ngValue]="10">10</option>
                    <option [ngValue]="20">20</option>
                    <option [ngValue]="50">50</option>
                  </select>
                </li>
              </ul>
            </div>
          </mk-tab-content>
        </mk-tab>
      </mk-tabs>
    </mk-box> -->
      </div>
    </div>
  </div>

</div>

<!-- Apply Leave Edit -->
<div id="applyLeaveEditMode" class="modal fade" role="dialog">
  <div class="modal-dialog modal-lg" style="width: 850px;">
    <div class="modal-content">
      <div class="modal-header">
        <button _ngcontent-c11="" class="close" data-dismiss="modal" type="button" >
          ×
        </button>
        <h4 class="modal-title">{{leaveType}} Leave </h4>
      </div>
      <div class="modal-body model-bg user-modal-height col-xs-12 col-sm-12 less">
        <div class="model-body col-xs-12 col-sm-12">
          <!-- <div *ngIf="showEmptyErrorMessage" class="model-title errorMessage" id="errorMessage">You are trying to submit
            an Empty Form.</div>
          <div *ngIf="duplicateMobile" class="invalid-feedback error">Mobile number cannot be same!</div>
          <div *ngIf="duplicateVendorId" class="invalid-feedback error">Vendor id cannot be same!</div> -->
          <div class="box-body no-padding">
            <div class="row">

              <div class="col-md-7 no-padding" style="border-right: 1px solid #ddd;">
                <form [formGroup]="leaveApply" (ngSubmit)="applyLeave()">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-sm-6">
                        <label class="labelType">Employee  <span class="red">*</span></label>
                          <!-- <select class="form-control select2" formControlName="UserId"
                          (change)="setCurrentUserIdForApplyLeave($event)" [attr.disabled]="editMode ? 'true' : null">
                        </select>  -->
                        <div>
                          <ejs-dropdownlist  (change)="setCurrentUserIdForApplyLeave($event)"  formControlName="UserId"
                          #todatlemplist [allowFiltering]='true'
                          [dataSource]='todatlEmployeeList' [fields]='todatlemployeeFields' [placeholder]='text'
                          [itemTemplate]='itemTemplate'></ejs-dropdownlist>

                            <ng-template #itemTemplate="" let-todatlEmployeeList="">
                            <span class='item'><span class='name'> {{todatlEmployeeList.employeeName}}</span> - <span class ='city'>{{todatlEmployeeList.empCode}}</span> - <span class ='city'>{{todatlEmployeeList.userId}}</span></span>
                          </ng-template>
                        </div>

                      <div class="invalidfeedback" *ngIf="
                          (submitted && leaveApply.controls.UserId.errors) ||
                          leaveApply.get('UserId').touched">
                        <div *ngIf="
                            leaveApply.controls.UserId.errors?.required ||
                            leaveApply.controls.UserId.errors?.UserId">
                          <small class="text-danger">Employee name is required *</small>
                        </div>
                      </div>

                      </div>
                      <!-- <div class="col-sm-12">
                        <select class="form-control select2" formControlName="UserId"
                          (change)="setCurrentUserIdForApplyLeave($event)" [attr.disabled]="editMode ? 'true' : null">
                          <option [ngValue]="null" disabled hidden>Select employee name</option>
                          <option *ngFor="let employee of todatlEmployeeList" [ngValue]="employee.userId">
                            {{ employee.employeeName}} - {{ employee.empCode}}
                          </option>
                        </select>
                        <div class="invalidfeedback" *ngIf="
                            (submitted && leaveApply.controls.UserId.errors) ||
                            leaveApply.get('UserId').touched">
                          <div *ngIf="
                              leaveApply.controls.UserId.errors?.required ||
                              leaveApply.controls.UserId.errors?.UserId">
                            <small class="text-danger">Employee name is required *</small>
                          </div>
                        </div>
                      </div> -->

                      <div class="col-sm-6">
                        <label class="labelType" style="margin-top: 5px;">Leave Type <span class="red">*</span></label>
                         <!-- <select class="form-control select2" formControlName="LeaveTypeId"
                          (change)="onSelectLeeaveType($event)" [attr.disabled]="editMode ? 'true' : null">
                          <option [value]="null" disabled hidden>Select leave type</option>
                          <option *ngFor="let list of userAvalLeave" [value]="list.leaveTypeId">
                            {{ list.leaveTypeName }}
                          </option>
                        </select> -->

                        <ejs-dropdownlist  id='LeaveType22' formControlName="LeaveTypeId"
                        (change)="onSelectLeeaveType($event)" [dataSource]='userAvalLeave' [fields]='AvlLeaveFields'
                        [placeholder]='AvlLeavetext' >

                        </ejs-dropdownlist>
                        <!-- <div *ngIf="showAvlLeave || showAvlLeave == 0">
                          <strong style="color:Darkblue;"> &nbsp; Available Leave: {{showAvlLeave}}
                            <span *ngIf="LeaveTypeDetails.Unit == 1">Day(s)</span>
                            <span *ngIf="LeaveTypeDetails.Unit == 2">Hour(s)</span>
                          </strong>
                        </div> -->

                        <div class="invalidfeedback" *ngIf="
                            (submitted && leaveApply.controls.LeaveTypeId.errors) ||
                            leaveApply.get('LeaveTypeId').touched">
                          <div *ngIf="
                              leaveApply.controls.LeaveTypeId.errors?.required ||
                              leaveApply.controls.LeaveTypeId.errors?.LeaveTypeId">
                            <small class="text-danger">Leave type is required *</small>
                          </div>
                        </div>
                      </div>


                    </div>
                  </div>



                  <div class="form-group" style="margin-bottom: 0;">
                    <div class="row">
                      <div class="col-sm-12">
                        <label class="labelType" style="margin-top: 5px;">Dates <span class="red">*</span></label>

                      </div>
                      <div class="col-sm-6">
                        <div class="form-group">
                          <div class="input-group date" style="width: 100%;">
                            <!-- <div class="input-group-addon">
                              <i class="fa fa-calendar"></i>
                            </div> -->
                            <!-- <input type="text" formControlName="LeaveStartDate" class="e-input" name="LeaveStartDate"
                              placeholder="From" autocomplete="off" bsDatepicker
                              [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', containerClass: 'theme-dark-blue', customTodayClass:'custom-today-class', showWeekNumbers: false }"
                              id="dpLeaveStartDate" placeholder="From Date"
                              (bsValueChange)="datepickerStartDate($event)" required /> -->
                              <ejs-datepicker  formControlName="LeaveStartDate" [allowEdit]="false"   #start (change) = "selectStartDate(start.value)" id="startdate"></ejs-datepicker>
                          </div>
                          <div class="invalidfeedback"
                            *ngIf="(submitted && leaveApply.controls.LeaveStartDate.errors) || leaveApply.get('LeaveStartDate').touched">
                            <div
                              *ngIf="leaveApply.controls.LeaveStartDate.errors ?.required  ||  leaveApply.controls.LeaveStartDate.errors ?.LeaveStartDate">
                              <small class="text-danger">Duration dates are required *</small>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group">
                          <div class="input-group date" style="width: 100%;">
                            <!-- <div class="input-group-addon">
                              <i class="fa fa-calendar"></i>
                            </div>
                            <input type="text" formControlName="LeaveEndDate" class="e-input" name="LeaveEndDate"
                              placeholder="To" [minDate]="minDate" autocomplete="off" bsDatepicker
                              [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', containerClass: 'theme-dark-blue', customTodayClass:'custom-today-class', showWeekNumbers: false }"
                              id="dpLeaveEndDate" placeholder="To Date" (bsValueChange)="datepickerEndDate($event)"
                              required /> -->

                              <ejs-datepicker formControlName="LeaveEndDate" [allowEdit]="false" id="enddate"  [min]='minDate' #end (change) = "selectEndDate(end.value)" ></ejs-datepicker>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="row" *ngIf="LeaveCalendarDatesList != null && LeaveCalendarDatesList.length > 0">
                      <div class="col-sm-12">

                      </div>
                      <div class="col-sm-12" *ngIf="LeaveTypeDetails != null">

                        <table class="table table-bordered">
                          <tbody>
                            <tr *ngFor="let item of LeaveCalendarDatesList;let i=index">
                              <td>
                              {{dateformat(LeaveCalendarDatesList[i].LeaveDate)}}
                              </td>
                              <td *ngIf="LeaveTypeDetails.Unit == 1">
                                <select class="form-control" [(ngModel)]="LeaveCalendarDatesList[i].DayType"
                                  [ngModelOptions]="{standalone: true}"
                                  [disabled]="LeaveCalendarDatesList[i].IsWeekEnd == true ? true : null"
                                  name="DayType-{{i}}" #DayType="ngModel" (change)="setDayTypeValue($event, i);">
                                  <option *ngFor="let type of DayTypeSelectList" [value]="type.value">{{ type.text }}
                                  </option>
                                  <option *ngIf="LeaveCalendarDatesList[i].IsWeekEnd == true" [value]="4">Weekend
                                  </option>
                                </select>
                              </td>
                              <td *ngIf="LeaveTypeDetails.Unit == 1">
                                <select class="form-control" [(ngModel)]="LeaveCalendarDatesList[i].Type"
                                  [ngModelOptions]="{standalone: true}" name="Type-{{i}}" #Type="ngModel"
                                  [disabled]="(LeaveCalendarDatesList[i].DayType == '1' || LeaveCalendarDatesList[i].DayType == '4') ? true : null"
                                  (change)="setTypeValue($event, i);">
                                  <!-- <option *ngFor="let type of TypeSelectList" value="{{type.value}}">{{ type.text }}</option> -->
                                  <option *ngIf="LeaveCalendarDatesList[i].DayType == '2'" selected value="1">1st Half
                                  </option>
                                  <option *ngIf="LeaveCalendarDatesList[i].DayType == '2'" value="2">2nd Half</option>
                                  <option *ngIf="LeaveCalendarDatesList[i].DayType == '3'" selected value="6">1st Quarter</option>
                                  <option *ngIf="LeaveCalendarDatesList[i].DayType == '3'" value="7">2nd Quarter </option>
                                  <option *ngIf="LeaveCalendarDatesList[i].DayType == '3'" value="8">3rd Quarter
                                  </option>
                                  <option *ngIf="LeaveCalendarDatesList[i].DayType == '3'" value="9">4th Quarter
                                  </option>

                                </select>
                              </td>
                              <td *ngIf="LeaveTypeDetails.Unit == 2">
                                <input type="time" class="form-control timepicker" placeholder=""
                                  [(ngModel)]="LeaveCalendarDatesList[i].Time" [ngModelOptions]="{standalone: true}"
                                  name="Time-{{i}}" #Time="ngModel" />
                              </td>
                              <td *ngIf="LeaveTypeDetails.Unit == 2">
                                <!-- <input type="time" class="form-control timepicker without_ampm" style="width: 75%; float: left; display: inline-block;" placeholder="" [(ngModel)]="LeaveCalendarDatesList[i].Hours" [ngModelOptions]="{standalone: true}"
                                  name="Hours-{{i}}" #Hours="ngModel" min="00:00" min="09:00" /> -->
                                <select class="form-control" [(ngModel)]="LeaveCalendarDatesList[i].Hours"
                                  [ngModelOptions]="{standalone: true}" name="Hours-{{i}}" #Hours="ngModel"
                                  style="width: 75%; float: left; display: inline-block;"
                                  (change)="setHoursValue($event, i);">
                                  <option value="0.50">00:30</option>
                                  <option value="1.00">01:00</option>
                                  <option value="1.50">01:30</option>
                                  <option value="2.00">02:00</option>
                                  <option value="2.50">02:30</option>
                                  <option value="3.00">03:00</option>
                                  <option value="3.50">03:30</option>
                                  <option value="4.00">04:00</option>
                                  <option value="4.50">04:30</option>
                                  <option value="5.00">05:00</option>
                                  <option value="5.50">05:30</option>
                                  <option value="6.00">06:00</option>
                                  <option value="6.50">06:30</option>
                                  <option value="7.00">07:00</option>
                                  <option value="7.50">07:30</option>
                                  <option value="8.00">08:00</option>
                                  <option value="8.50">08:30</option>
                                  <option value="9.00">09:00</option>
                                </select>

                                <span style="float: left; line-height: 40px;">Hrs</span>
                              </td>
                            </tr>
                            <tr>
                              <td  colspan="2" *ngIf="totalApplyingLeaves != null">
                                <strong style="color:rgb(39, 39, 41);"> Total </strong>
                              </td>
                              <td class="text-center" *ngIf="totalApplyingLeaves != null">
                                <strong style="color:rgb(39, 39, 41);">
                                  {{ totalApplyingLeaves }}
                                  <span *ngIf="LeaveTypeDetails.Unit == 1">Day(s)</span>
                                  <span *ngIf="LeaveTypeDetails.Unit == 2">Hour(s)</span>
                                </strong>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-sm-4">&nbsp;</div>
                      <div class="col-sm-8">
                        <div *ngIf="showWeekendHolidayCountNote == true">
                          <strong>Note:</strong>
                          <span>Weekends and Holidays will be counted as leave.</span>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-sm-8">
                        <div *ngIf="(totalApplyingLeaves != null) && (startDate && endDate)">
                          <strong style="color:Darkblue;margin-left:53%;"> Applying for {{ totalApplyingLeaves }} leave</strong>
                         </div>
                      </div>
                    </div>

                  </div>

                  <div class="form-group">
                    <div class="row">
                      <div class="col-sm-12">
                        <label class="labelType" style="margin-top: 5px;">Team Email ID</label>

                      </div>
                      <div class="col-sm-12">
                        <input formControlName="TeamEmailId"  type="text" class="e-input emailtxt" name="TotalDays" #email
                        (change) = "setTeamEmail(email.value)" placeholder="custom@crgsystems.co.in" [disabled] = "disableSubmit"
                          autocomplete="off" />
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="col-sm-12">
                    <label>Reason for Leave   <small class="text-danger">*</small></label>
                    <textarea [disabled] = "disableSubmit"  formControlName="LeaveReason" #reasonleave id="reasonleave" class="form-control" rows="3"></textarea>
                  </div>
                </div>

                  <ng-container *ngIf="isRequiredFileUpload">
                    <div class="form-group" style="padding: 15px; clear: both; display: inline-block; width: 100%; margin-bottom: 0;">
                      <label>File Upload</label>
                      <input type="file" class="e-input" name="file" multiple accept=".jpg,.png,.pdf" id="fileToUpload"
                        (change)="onFileChange($event.target.files)">

                      <div class="invalidfeedback"
                        *ngIf="submittedForFileUpload && isRequiredFileUpload && !isFileUploaded">
                        <small class="text-danger">Please upload file</small>
                      </div>
                      <div class="invalidfeedback" *ngIf="isFileUploaded && isUploadedFileInvalidFormat">
                        <small class="text-danger">Please upload JPG, PNG or PDF file formats.</small>
                      </div>
                    </div>
                  </ng-container>

                  <div class="form-group row">
                    <div class="col-sm-6">
                      <button type="submit" [disabled] = "disableSubmit" *ngIf="editMode == false"
                      class="btn btn-primary btn-brand btn-sm" style="padding: 7px 26px !important; margin-top: 20px;
                      font-size: 14px;">Submit</button>
                      <button type="button" *ngIf="editMode == true" class="btn btn-primary btn-brand btn-sm"
                      style="padding: 7px 26px !important; margin-top: 20px; font-size: 14px;"
                      (click) = "UpdateLeave()"
                      >Update Leave</button>
                      <!-- <button type="button" class="btn btn-defualt btn-sm" data-dismiss="modal">Cancel</button> -->
                    </div>
                    <div class="col-sm-6 text-right">
                      <ng-container *ngIf="editMode == true">
                        <button type="button" class="btn btn-danger btn-sm" (click)="CancelLeaveRequest()" style="padding: 7px 26px !important;
                        font-size: 14px; margin-top: 20px;">Cancel Leave</button>
                      </ng-container>
                    </div>
                  </div>
                </form>
              </div>

             <div class="col-md-5">
                <table class="table table-bordered table-hover">
                  <thead>
                    <tr>
                      <th class="text-left"> As of {{currentDate | date}}</th>
                      <th class="text-center" style="text-align: center !important;">
                        <span *ngIf="LeaveTypeDetails.Unit == 1">Day(s)</span>
                        <span *ngIf="LeaveTypeDetails.Unit == 2">Hour(s)</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>

                    <tr>
                      <td class="text-left">Available balance</td>
                      <td class="text-center">{{  decimalformat(showAvlLeave)}}</td>
                    </tr>
                    <tr>
                      <td class="text-left">Currently booked</td>
                      <td class="text-center">{{  decimalformat(totalApplyingLeaves)}}</td>
                    </tr>
                    <tr>
                      <td class="text-left">Balance after booked leave</td>
                      <td class="text-center">{{decimalformat(showBalLeave)}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Apply Leave View -->
<div id="applyLeaveViewMode" class="modal fade" style="z-index: 99999;" role="dialog">
  <div class="modal-dialog modal-lg" style="width: 850px;">
    <div class="modal-content">
      <div class="modal-header">
        <button _ngcontent-c11="" class="close" data-dismiss="modal" type="button" style="pointer-events: auto ;" >
          ×
        </button>
        <h4 class="modal-title">{{leaveType}} Leave</h4>
      </div>
      <div class="modal-body model-bg user-modal-height col-xs-12 col-sm-12 less">
        <div class="model-body col-xs-12 col-sm-12">
          <!-- <div *ngIf="showEmptyErrorMessage" class="model-title errorMessage" id="errorMessage">You are trying to submit
            an Empty Form.</div>
          <div *ngIf="duplicateMobile" class="invalid-feedback error">Mobile number cannot be same!</div>
          <div *ngIf="duplicateVendorId" class="invalid-feedback error">Vendor id cannot be same!</div> -->
          <div class="box-body no-padding">
            <div class="row">

              <div class="col-md-7 no-padding" style="border-right: 1px solid #ddd;cursor: not-allowed;">
                <form [formGroup]="leaveApply" style="cursor: not-allowed; pointer-events: none ;">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-sm-6">
                        <label class="labelType">Employee  <span class="red">*</span></label>
                          <!-- <select class="form-control select2" formControlName="UserId"
                          (change)="setCurrentUserIdForApplyLeave($event)" [attr.disabled]="editMode ? 'true' : null">
                        </select>                      -->
                        <div>
                          <ejs-dropdownlist [enabled]="editMode" formControlName="UserId" [allowFiltering]='true'
                          [dataSource]='todatlEmployeeList' [fields]='todatlemployeeFields' [placeholder]='text'
                          [itemTemplate]='itemTemplate'></ejs-dropdownlist>

                            <ng-template #itemTemplate="" let-todatlEmployeeList="">
                            <span class='item'><span class='name'> {{todatlEmployeeList.employeeName}}</span> - <span class ='city'>{{todatlEmployeeList.empCode}}</span> - <span class ='city'>{{todatlEmployeeList.userId}}</span></span>
                          </ng-template>
                        </div>

                      <div class="invalidfeedback" *ngIf="
                          (submitted && leaveApply.controls.UserId.errors) ||
                          leaveApply.get('UserId').touched">
                        <div *ngIf="
                            leaveApply.controls.UserId.errors?.required ||
                            leaveApply.controls.UserId.errors?.UserId">
                          <small class="text-danger">Employee name is required *</small>
                        </div>
                      </div>

                      </div>
                      <!-- <div class="col-sm-12">
                        <select class="form-control select2" formControlName="UserId"
                          (change)="setCurrentUserIdForApplyLeave($event)" [attr.disabled]="editMode ? 'true' : null">
                          <option [ngValue]="null" disabled hidden>Select employee name</option>
                          <option *ngFor="let employee of todatlEmployeeList" [ngValue]="employee.userId">
                            {{ employee.employeeName}} - {{ employee.empCode}}
                          </option>
                        </select>
                        <div class="invalidfeedback" *ngIf="
                            (submitted && leaveApply.controls.UserId.errors) ||
                            leaveApply.get('UserId').touched">
                          <div *ngIf="
                              leaveApply.controls.UserId.errors?.required ||
                              leaveApply.controls.UserId.errors?.UserId">
                            <small class="text-danger">Employee name is required *</small>
                          </div>
                        </div>
                      </div> -->
                      <!-- {{userAvalLeave | json}} -->
                      <div class="col-sm-6">
                        <label class="labelType" style="margin-top: 5px;">Leave Type <span class="red">*</span></label>
                         <!-- <select class="form-control select2" formControlName="LeaveTypeId"
                          (change)="onSelectLeeaveType($event)" [attr.disabled]="editMode ? 'true' : null">
                          <option [value]="null" disabled hidden>Select leave type</option>
                          <option *ngFor="let list of userAvalLeave" [value]="list.leaveTypeId">
                            {{ list.leaveTypeName }}
                          </option>
                        </select> -->

                        <ejs-dropdownlist  [enabled]="editMode" formControlName="LeaveTypeId"
                         [dataSource]='userExpireLeave' [fields]='AvlLeaveFields'
                        [placeholder]='AvlLeavetext' >

                        </ejs-dropdownlist>
                        <!-- <div *ngIf="showAvlLeave || showAvlLeave == 0">
                          <strong style="color:Darkblue;"> &nbsp; Available Leave: {{showAvlLeave}}
                            <span *ngIf="LeaveTypeDetails.Unit == 1">Day(s)</span>
                            <span *ngIf="LeaveTypeDetails.Unit == 2">Hour(s)</span>
                          </strong>
                        </div> -->

                        <div class="invalidfeedback" *ngIf="
                            (submitted && leaveApply.controls.LeaveTypeId.errors) ||
                            leaveApply.get('LeaveTypeId').touched">
                          <div *ngIf="
                              leaveApply.controls.LeaveTypeId.errors?.required ||
                              leaveApply.controls.LeaveTypeId.errors?.LeaveTypeId">
                            <small class="text-danger">Leave type is required *</small>
                          </div>
                        </div>
                      </div>


                    </div>
                  </div>



                  <div class="form-group" style="margin-bottom: 0;">
                    <div class="row">
                      <div class="col-sm-12">
                        <label class="labelType" style="margin-top: 5px;">Dates <span class="red">*</span></label>

                      </div>
                      <div class="col-sm-6">
                        <div class="form-group">
                          <div class="input-group date" style="width: 100%;">
                            <!-- <div class="input-group-addon">
                              <i class="fa fa-calendar"></i>
                            </div> -->
                            <!-- <input type="text" formControlName="LeaveStartDate" class="e-input" name="LeaveStartDate"
                              placeholder="From" autocomplete="off" bsDatepicker
                              [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', containerClass: 'theme-dark-blue', customTodayClass:'custom-today-class', showWeekNumbers: false }"
                              id="dpLeaveStartDate" placeholder="From Date"
                              (bsValueChange)="datepickerStartDate($event)" required /> -->
                              <ejs-datepicker  [enabled]="editMode" formControlName="LeaveStartDate" [min]="" [max]="" [allowEdit]="false" ></ejs-datepicker>
                          </div>
                          <div class="invalidfeedback"
                            *ngIf="(submitted && leaveApply.controls.LeaveStartDate.errors) || leaveApply.get('LeaveStartDate').touched">
                            <div
                              *ngIf="leaveApply.controls.LeaveStartDate.errors ?.required  ||  leaveApply.controls.LeaveStartDate.errors ?.LeaveStartDate">
                              <small class="text-danger">Duration dates are required *</small>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group">
                          <div class="input-group date" style="width: 100%;">
                            <!-- <div class="input-group-addon">
                              <i class="fa fa-calendar"></i>
                            </div>
                            <input type="text" formControlName="LeaveEndDate" class="e-input" name="LeaveEndDate"
                              placeholder="To" [minDate]="minDate" autocomplete="off" bsDatepicker
                              [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', containerClass: 'theme-dark-blue', customTodayClass:'custom-today-class', showWeekNumbers: false }"
                              id="dpLeaveEndDate" placeholder="To Date" (bsValueChange)="datepickerEndDate($event)"
                              required /> -->

                              <ejs-datepicker [enabled]="editMode" formControlName="LeaveEndDate" [min]="" [max]="" [allowEdit]="false"  [min]='minDate'></ejs-datepicker>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="row" *ngIf="LeaveCalendarDatesList != null && LeaveCalendarDatesList.length > 0">
                      <div class="col-sm-12">

                      </div>
                      <div class="col-sm-12" *ngIf="LeaveTypeDetails != null">

                        <table class="table table-bordered">
                          <tbody>
                            <tr *ngFor="let item of LeaveCalendarDatesList;let i=index">
                              <td>
                                 {{dateformat(LeaveCalendarDatesList[i].LeaveDate)}}
                              </td>
                              <td *ngIf="LeaveTypeDetails.Unit == 1">
                                <select class="form-control" [(ngModel)]="LeaveCalendarDatesList[i].DayType"
                                  [ngModelOptions]="{standalone: true}"
                                  [disabled]="LeaveCalendarDatesList[i].IsWeekEnd == true ? true : null"
                                  name="DayType-{{i}}" #DayType="ngModel" (change)="setDayTypeValue($event, i);">
                                  <option *ngFor="let type of DayTypeSelectList" [value]="type.value">{{ type.text }}
                                  </option>
                                  <option *ngIf="LeaveCalendarDatesList[i].IsWeekEnd == true" [value]="4">Weekend
                                  </option>
                                </select>
                              </td>
                              <td *ngIf="LeaveTypeDetails.Unit == 1">
                                <select class="form-control" [(ngModel)]="LeaveCalendarDatesList[i].Type"
                                  [ngModelOptions]="{standalone: true}" name="Type-{{i}}" #Type="ngModel"
                                  [disabled]="(LeaveCalendarDatesList[i].DayType == '1' || LeaveCalendarDatesList[i].DayType == '4') ? true : null"
                                  (change)="setTypeValue($event, i);">
                                  <!-- <option *ngFor="let type of TypeSelectList" value="{{type.value}}">{{ type.text }}</option> -->
                                  <option *ngIf="LeaveCalendarDatesList[i].DayType == '2'" selected value="1">1st Half
                                  </option>
                                  <option *ngIf="LeaveCalendarDatesList[i].DayType == '2'" value="2">2nd Half</option>
                                  <option *ngIf="LeaveCalendarDatesList[i].DayType == '3'" selected value="6">1st Quarter</option>
                                  <option *ngIf="LeaveCalendarDatesList[i].DayType == '3'" value="7">2nd Quarter </option>
                                  <option *ngIf="LeaveCalendarDatesList[i].DayType == '3'" value="8">3rd Quarter
                                  </option>
                                  <option *ngIf="LeaveCalendarDatesList[i].DayType == '3'" value="9">4th Quarter
                                  </option>

                                </select>
                              </td>
                              <td *ngIf="LeaveTypeDetails.Unit == 2">
                                <input type="time" class="form-control timepicker" placeholder=""
                                  [(ngModel)]="LeaveCalendarDatesList[i].Time" [ngModelOptions]="{standalone: true}"
                                  name="Time-{{i}}" #Time="ngModel" />
                              </td>
                              <td *ngIf="LeaveTypeDetails.Unit == 2">
                                <!-- <input type="time" class="form-control timepicker without_ampm" style="width: 75%; float: left; display: inline-block;" placeholder="" [(ngModel)]="LeaveCalendarDatesList[i].Hours" [ngModelOptions]="{standalone: true}"
                                  name="Hours-{{i}}" #Hours="ngModel" min="00:00" min="09:00" /> -->
                                <select class="form-control" [(ngModel)]="LeaveCalendarDatesList[i].Hours"
                                  [ngModelOptions]="{standalone: true}" name="Hours-{{i}}" #Hours="ngModel"
                                  style="width: 75%; float: left; display: inline-block;"
                                  (change)="setHoursValue($event, i);">
                                  <option value="0.50">00:30</option>
                                  <option value="1.00">01:00</option>
                                  <option value="1.50">01:30</option>
                                  <option value="2.00">02:00</option>
                                  <option value="2.50">02:30</option>
                                  <option value="3.00">03:00</option>
                                  <option value="3.50">03:30</option>
                                  <option value="4.00">04:00</option>
                                  <option value="4.50">04:30</option>
                                  <option value="5.00">05:00</option>
                                  <option value="5.50">05:30</option>
                                  <option value="6.00">06:00</option>
                                  <option value="6.50">06:30</option>
                                  <option value="7.00">07:00</option>
                                  <option value="7.50">07:30</option>
                                  <option value="8.00">08:00</option>
                                  <option value="8.50">08:30</option>
                                  <option value="9.00">09:00</option>
                                </select>

                                <span style="float: left; line-height: 40px;">Hrs</span>
                              </td>
                            </tr>
                            <tr>
                              <td  colspan="2" *ngIf="totalApplyingLeaves != null">
                                <strong style="color:rgb(39, 39, 41);"> Total </strong>
                              </td>
                              <td class="text-center" *ngIf="totalApplyingLeaves != null">
                                <strong style="color:rgb(39, 39, 41);">
                                  {{ totalApplyingLeaves }}
                                  <span *ngIf="LeaveTypeDetails.Unit == 1">Day(s)</span>
                                  <span *ngIf="LeaveTypeDetails.Unit == 2">Hour(s)</span>
                                </strong>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-sm-4">&nbsp;</div>
                      <div class="col-sm-8">
                        <div *ngIf="showWeekendHolidayCountNote == true">
                          <strong>Note:</strong>
                          <span>Weekends and Holidays will be counted as leave.</span>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-sm-8">
                        <div *ngIf="(totalApplyingLeaves != null) && (startDate && endDate)">
                          <strong style="color:Darkblue;margin-left:53%;"> Applying for {{ totalApplyingLeaves }} leave</strong>
                         </div>
                      </div>
                    </div>

                  </div>

                  <div class="form-group">
                    <div class="row">
                      <div class="col-sm-12">
                        <label class="labelType" style="margin-top: 5px;">Team Email ID</label>

                      </div>
                      <div class="col-sm-12">
                        <input formControlName="TeamEmailId"  type="text" class="e-input emailtxt" name="TotalDays" #email
                        (change) = "setTeamEmail(email.value)" placeholder="custom@crgsystems.co.in" [disabled] = "disableSubmit"
                          autocomplete="off" />
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="col-sm-12">
                    <label>Reason for Leave <small class="text-danger">*</small></label>
                    <textarea [disabled] = "disableSubmit"  formControlName="LeaveReason" #reasonleave id="reasonleave" class="form-control" rows="3"></textarea>
                  </div>
                </div>

                  <ng-container *ngIf="isRequiredFileUpload">
                    <div class="form-group">
                      <label>File Upload</label>
                      <input type="file" class="e-input" name="file" multiple accept=".jpg,.png,.pdf" id="fileToUpload"
                        (change)="onFileChange($event.target.files)">

                      <div class="invalidfeedback"
                        *ngIf="submittedForFileUpload && isRequiredFileUpload && !isFileUploaded">
                        <small class="text-danger">Please upload file</small>
                      </div>
                      <div class="invalidfeedback" *ngIf="isFileUploaded && isUploadedFileInvalidFormat">
                        <small class="text-danger">Please upload JPG, PNG or PDF file formats.</small>
                      </div>
                    </div>
                  </ng-container>


                </form>
              </div>

             <div class="col-md-5">
                <table class="table table-bordered table-hover">
                  <thead>
                    <tr>
                      <th class="text-left"> As of {{showLeaveTakenDay | date}}</th>
                      <th class="text-center" style=" text-align: center !important;">
                        <span *ngIf="LeaveTypeDetails.Unit == 1">Day(s)</span>
                        <span *ngIf="LeaveTypeDetails.Unit == 2">Hour(s)</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>

                    <tr>
                      <td class="text-left">Available balance</td>
                      <td class="text-center">{{decimalformat(ViewshowAvlLeave)}}</td>
                    </tr>
                    <tr>
                      <td class="text-left">Currently booked</td>
                      <td class="text-center">{{decimalformat(ViewtotalApplyingLeaves)}}</td>
                    </tr>
                    <tr>
                      <td class="text-left">Balance after booked leave</td>
                      <td class="text-center">{{decimalformat(ViewshowBalLeave)}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Google Authorize -->
<div id="googleSyncAuthorizeModel" class="modal fade" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row">
          <div class="col-sm-12 text-right">
            <button _ngcontent-c11="" class="close text-red" data-dismiss="modal" type="button">×</button>
          </div>
          <div class="col-sm-12 text-center">
            <h4>Please authorize to push leave and holidays to Google Calendar</h4>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="col-sm-12 text-center">
            <button class="btn btn-primary btn-brand" type="button" #googleAuthorize>Authorize</button>
            <button class="btn btn-default" data-dismiss="modal" type="button">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Google Sync -->
<div id="googleSyncModel" class="modal fade" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row">
          <div class="col-sm-12 text-right">
            <button _ngcontent-c11="" class="close text-red" data-dismiss="modal" type="button">×</button>
          </div>
          <div class="col-sm-12 text-center">
            <h4>Push to Google Calendar</h4>
          </div>
        </div>

        <div class="row mar-t-15">
          <div class="col-sm-5 text-right">
            <label class="control-label">Authorized Google account</label>
          </div>
          <div class="col-sm-7" *ngIf="calendarSyncAuthorized != null && calendarSyncAuthorized != undefined">
            <span>{{ calendarSyncAuthorized.googleSyncEmailId }}</span>
            <span class="spanRevoke" (click)="RevokeGoogleAuthorized()">Revoke</span>
          </div>
          <div class="col-sm-7"
            *ngIf="(calendarSyncAuthorized == null || calendarSyncAuthorized == undefined) && (currentGmailAccountId != null && currentGmailAccountId != '')">
            <span>{{ currentGmailAccountId }}</span>
            <span class="spanRevoke" (click)="RevokeGoogleAuthorized()">Revoke</span>
          </div>
        </div>

        <div class="row mar-t-15">
          <div class="col-sm-5 text-right">
            <label class="control-label">Select Calendar to Push data</label>
          </div>
          <div class="col-sm-7">
            <select class="form-control" name="filterDate" [(ngModel)]="syncCalendarType">
              <option value="2">Leave data</option>
              <option value="1">Holiday data</option>
            </select>
          </div>
        </div>

        <div class="row mar-t-15">
          <div class="col-sm-12">
            <span class="text-red">*</span> <label class="control-label">Note:</label>
            <span>Upcoming leave and holidays will be automatically pushed to this Calendar</span>
          </div>
        </div>

      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="col-sm-12 text-center">
            <button class="btn btn-primary btn-brand" (click)="googleCalendarPush()" type="button">Push</button>
            <button class="btn btn-default" data-dismiss="modal" type="button">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="userMessageModel" class="modal fade" role="dialog">
  <div class="modal-dialog modal-confirm modal-sm">
    <div class="modal-content" style="border-radius: 10px; top: 150px; left: 350px;">
      <div class="modal-header success-header">
        <button aria-label="Close" class="close" data-dismiss="modal"
          type="button"><span aria-hidden="true">×</span></button>
        <div class="icon-box" *ngIf="showSuccess">
          <i class="fa fa-check"></i>
        </div>
        <div class="icon-box-remove" *ngIf="!showSuccess">
          <i class="fa fa-remove"></i>
        </div>
      </div>
      <div class="modal-body">
        <p class="text-center">{{showMessage}}</p>
      </div>
      <!-- <div class="modal-footer">
        <button class="btn btn-success btn-block" data-dismiss="modal">
          OK
        </button>
      </div> -->
    </div>
  </div>
</div>

<div id="MessageModel1" class="modal fade" role="dialog">
  <div class="modal-dialog modal-confirm modal-sm" style="width: 300px;">
    <div class="modal-content" style="border-radius: 10px; top: 150px; left: 350px">
      <div class="modal-header success-header">
        <button _ngcontent-fgj-c12="" aria-label="Close" class="close" data-dismiss="modal" type="button"><span
            _ngcontent-fgj-c12="" aria-hidden="true">×</span></button>
        <!-- <div class="icon-box" *ngIf="showSuccess">
          <i class="fa fa-check"></i>
        </div> -->
        <div class="icon-box-remove">
          <i class="fa fa-remove"></i>
        </div>
      </div>
      <div class="modal-body">
        <p class="text-center">Are you sure you want to delete this leave.</p>
      </div>
      <!-- <div class="modal-footer" *ngIf="showSuccess">
        <button class="btn btn-danger btn-block" data-dismiss="modal">
          Yes
        </button>
      </div> -->
      <div class="modal-footer errorbutton">
        <!-- <span [hidden]="true">{{ DeletedID }}</span> -->
        <button class="btn btn-danger messagebutton" data-dismiss="modal" (click)="LeaveDelete()">
          Yes
        </button>

      </div>
    </div>
  </div>
</div>


<!-- Leave Cancel Confirmation Modal -->
<div id="confirmCancelModel" class="modal  fade" role="dialog">
  <div class="modal-dialog modal-confirm modal-sm">
    <div class="modal-content" style="border-radius:10px; top: 150px; left: 350px;">
      <div class="modal-header success-header">
        <div class="icon-box-remove">
          <i class="fa fa-remove"></i>
        </div>
      </div>
      <div class="modal-body">
        <p class="text-center">Are you sure? You want to cancel this leave request?</p>
        <div class="row">
          <div class="col-sm-12">
            <textarea type="text" class="form-control" style="width: 100%; height: auto;" rows="2"
              [(ngModel)]="cancelLeaveReason" placeholder="Cancel Reason"></textarea>
          </div>
          <div class="col-sm-12" *ngIf="isCancelReasonMandatory && isCancelReasonTextboxEmpty">
            <span class="text-danger">This field is mandatory</span>
          </div>
        </div>
      </div>
      <div class="modal-footer text-center">
        <button class="btn btn-danger messagebutton" (click)="ConfirmLeaveCancel()">
          Yes
        </button>
        <button class="btn btn-defualt messagebutton" data-dismiss="modal">
          No
        </button>
      </div>
    </div>
  </div>
</div>
<!-- Leave Cancel Confirmation Modal end -->
