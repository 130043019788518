import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BoxModule } from 'angular-admin-lte';
import { SelectModule } from 'ng2-select';
import { CoreModule } from 'src/app/core/core.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TabsModule as MkTabsModule } from 'angular-admin-lte';
import { TimepickerModule, BsDatepickerModule } from 'ngx-bootstrap';
import { ApprovalComponent, NgbdSortableHeader } from './approval.component';
import { GridAllModule, PagerModule } from '@syncfusion/ej2-angular-grids';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    BoxModule, SelectModule,
    MkTabsModule,
    BoxModule,
    CoreModule,
    SelectModule,
    ReactiveFormsModule,
    NgbModule,
    TimepickerModule.forRoot(),
    BsDatepickerModule.forRoot(),
    GridAllModule,
    PagerModule,
  ],
  declarations: [ApprovalComponent, NgbdSortableHeader]
})
export class ApprovalModule { }
