import { Component, OnInit,QueryList,ChangeDetectionStrategy,
  ViewChildren,
  Directive,
  Input,
  Output,
  EventEmitter,
  ViewChild, } from '@angular/core';
import { LeaveService } from "./../leave.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AuthService } from "src/app/services/auth.service";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { CommonService } from "src/app/common.service";
import { DatePipe } from '@angular/common';
import { DatePickerComponent } from '@syncfusion/ej2-angular-calendars';
import { state } from '@angular/animations';
import { environment } from "src/app/environment";
import { ToasterService } from '../../expense/toaster/toaster.service';
import { GridComponent ,Column, ExcelExportProperties} from '@syncfusion/ej2-angular-grids';




interface Country {
  empCode?: any;
  WorkedDate: any;
  ExpiryDate: any;
  duration: number;
  status: string;
  createdDate: string;
}

export type SortColumn = keyof Country | "";
export type SortDirection = "asc" | "desc" | "";
const rotate: { [key: string]: SortDirection } = {
  asc: "desc",
  desc: "",
  "": "asc",
};
const compare = (v1: string, v2: string) => (v1 < v2 ? -1 : v1 > v2 ? 1 : 0);

export interface SortEvent {
  column: SortColumn;
  direction: SortDirection;
}

@Directive({
  selector: "th[sortable]",
  host: {
    "[class.asc]": 'direction === "asc"',
    "[class.desc]": 'direction === "desc"',
    "(click)": "rotate()",
  },
})
export class NgbdSortableHeader {
  @Input() sortable: SortColumn = "";
  @Input() direction: SortDirection = "";
  @Output() sort = new EventEmitter<SortEvent>();

  rotate() {
    this.direction = rotate[this.direction];
    this.sort.emit({ column: this.sortable, direction: this.direction });
  }
}

@Component({
  selector: 'app-comp-off',
  templateUrl: './comp-off.component.html',
  styleUrls: ['./comp-off.component.css'],
 // changeDetection: ChangeDetectionStrategy.OnPush

})
export class CompOffComponent implements OnInit {
  @ViewChild('ejDatePicker') ejDatePicker: DatePickerComponent;
public targetElement: HTMLElement;
public placeholder: String = 'Date of Birth';
  datePickerConfiq: Partial<BsDatepickerConfig>;
  compLeaveApply: FormGroup;
  allLeaveTypeList: any;
  allHolidayList: any;
  currentUserId: any;
  submitted = false;
  leaveStartFrom: Date;
  userLoader: Boolean = false;
  collection: any[];
  public showSuccess: Boolean;
  public showMessage: string;
  page = 1;
  pageSize = 10;
  COUNTRIES: Country[] = [];
  parms;
  collectionSize: any;
  startDateChange;
  minDate: Date;
  bsValue: Date = new Date();
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  myDateValue;
  startDate: any;
  endDate: any;
  userAvalLeave: any;
  mytime: Date = new Date();
  DateFormat:any
  unitValue: number;
  selectHours: boolean = true;
  private selectedLink: any = 1;
  currentDate: any;
  todatlEmployeeList: any;
  employeName: any;
  employeCode: any;

  ddlEmployeeUserId: any;
  EmployeeList = [];
  ActiveEmployee = [];

  workedDate: Date;
  expiryDate: Date;

  generalSettingsData: any;
  DayTypeSelectList = [];
  todaysDate: Date;
  ViewPermission:boolean
  canApplyCompOff: Boolean = true;
  DeletePermission:boolean
  editMode = false;
  LocalDataPermission:any
  cancelCompOffReason: any = "";
  isCancelReasonMandatory: Boolean = false;
  isCancelReasonTextboxEmpty: Boolean = true;
  CmpCode:any;
  EditPermission:boolean
  AddPermission:boolean
  public filter: Object;
  public filterSettings: Object;
  public selectionSettings: Object;
  public height: string = '240px';
  public employeeFields: Object = { text: 'text', value: 'id' };
  public durationFields: Object = { text: 'text', value: 'val' };
  public employeetext: string = "Please select an employee"
  public durationtext: string = "Select duration"
  public item: number[] = [1, 2, 3, 4, 5];
  public value: any ;
  public leaveDuration = [{text: "Full Day", val: "1"},
                          {text: "Half Day", val: "2"},
                          {text: "Quarter Day", val: "3"}]
                          

  //--------DatePicker-------
  public today: Date = new Date();
  public currentYear: number = this.today.getFullYear();
  public currentMonth: number = this.today.getMonth();
  public currentDay: number = this.today.getDate();
  public dateValue: Object = new Date(new Date().setDate(14));
  public minCalDate: Object = new Date(this.currentYear, this.currentMonth, 1);
  public maxCalDate: Object =  new Date(this.currentYear, this.currentMonth, 27);
  ProjectCode: any;
  ProjectName: any;
  ProjectList: any;
  ProjectFields: any;
  myno: number;
  maxdate: any;
  financialYearText: any;
  currentFinancialYearId: any;
  prevFinancialYearId: any;
  nextFinancialYearId: any;
  currentyear: number ;
  year: any ;
  view: boolean = true;
  //-------------------------

  constructor(
    private _leaveSer: LeaveService,
    private _fb: FormBuilder,
    private _authSer: AuthService,
    private _commonSer: CommonService,
    private toasterService:ToasterService,
    private datePipe: 
    DatePipe
  ) {
    this.CmpCode = this._authSer.getcompanyCode();
    this.unitValue = 1;

    this._authSer.IsPermissions('Compensatory Off');
    this.LocalDataPermission = this._authSer.LocalDataPermission;
    if (this.LocalDataPermission == '' || this.LocalDataPermission === null || this.LocalDataPermission === undefined) {
      this.LocalDataPermission[0].addPermission = 0;
      this.LocalDataPermission[0].editPermission = 0;
      this.LocalDataPermission[0].viewpermission = 0;
      this.LocalDataPermission[0].deletePermission = 0;
    }
    this.ViewPermission = this.LocalDataPermission[0].viewpermission //this.CurrentUser.permission.includes(Permission.ProjectPhaseView);
    this.AddPermission = this.LocalDataPermission[0].addPermission; //this.CurrentUser.permission.includes(Permission.ProjectPhaseAdd);
    this.EditPermission = this.LocalDataPermission[0].editPermission //this.CurrentUser.permission.includes(Permission.ProjectPhaseEdit);
    this.DeletePermission = this.LocalDataPermission[0].deletePermission; //this.CurrentUser.permission.includes(Permission.ProjectPhaseDelete);
    // this.ArchivePermission = this.LocalDataPermission[0].addPermission; //this.CurrentUser.permission.includes(Permission.ProjectArchive);   
  }

  ngOnInit() {// Angular Lifecycle hook
    this.filterSettings = { type: "Menu" };
    this.filter = { type: "CheckBox" };
    this.ProjectFields = { text: 'projectname', value: 'projectid' };
    // this.stTime = performance.now();
    this.selectionSettings = { persistSelection: true, type: "Multiple", checkboxOnly: true };
 
    this.CmpCode = this._authSer.getcompanyCode();
    this.DateFormat= this._authSer.getdateFormat();
    // this.minDate.setDate(this.minDate.getDate() - 1);
    // var now = new Date() ;
    // let converDate = now.setDate(now.getDate() + 90);
    // this.currentDate = converDate
    this.myno = 123
    this.maxdate = new Date()
    this.compLeaveApply = this._fb.group({
      CompOffId: [""],
      UserId: [, Validators.required],
      WorkedDate: ["", Validators.required],
      ProjectId: [0, Validators.required],
      Unit: [1, Validators.required],
      Duration: ["1", Validators.required],
      ExpiryDate: [""],
      WorkedStartTime: [""],
      WorkedEndTime: [""],
      Description: [""],
      CreatedBy: [""],
      CmpCode:[""]
    });

    this._authSer.currentUser.subscribe((res) => {
      this.currentUserId = res.userId;
      this.getProjectsListForSelect(this.currentUserId);
      this.compLeaveApply.patchValue({
        UserId: this.currentUserId,
        Unit: 1,
        Duration: "1"
      });
    });

    this.todaysDate = new Date();

    this.getEmployeesListForSelect();
    this.getAllLeaveType1();
    this.getAllHolidaysLsist();
    this.getAllLeave();
    this.userAvaliableLeave();
    this.getEmplyeDetails();
    this.getGeneralSettingsData();
    this.getCurrentFinancialYear();
    this.editMode = false;
  }
  

  public pageSettings = { pageCount: 3 };
  @ViewChild('overviewgrid') public Grid: GridComponent;
  load() {// Created By K Initialize grid properties
    const rowHeight: number = this.Grid.getRowHeight();  // height of the each row
    const gridHeight: any = this.Grid.height;  // grid height
    const pageSize: number = this.Grid.pageSettings.pageSize;   // initial page size
    const pageResize: any = (gridHeight - (pageSize * rowHeight)) / rowHeight; // new page size is obtained here
    this.Grid.pageSettings.pageSize = pageSize + Math.round(pageResize);
  }
  
  //DateFormat='MM/dd/yyyy';
 
  getCurrentFinancialYear() {// Created By K Getting Current financial year details
    debugger
    this._leaveSer.getCurrentFinancialYear(this.CmpCode).subscribe((res) => {
      this.financialYearText = res["data"].table[0].title;
      this.currentFinancialYearId = res["data"].table[0].financialYearId;
      this.prevFinancialYearId = res["data"].table1[0].prevId;
      this.nextFinancialYearId = res["data"].table1[0].nextId;
    });
  }
  
  dateformat(datas:string){// Created By K Global Date Format
    return this._authSer.Dateformat(datas);

  }

  toRoleid:any
  getEmployeesListForSelect () {// Created By A Getting all employees list
    debugger
    this._leaveSer.getEmployeesListForSelect(this.currentUserId,this.CmpCode).subscribe((res) => {
      this.todatlEmployeeList = res['data'].table;
      if (res['data'].table != null && res['data'].table.lenght > 0) {
        this.employeName = res["data"].table[0].employeeName;
        this.employeCode = res["data"].table[0].empCode;
      }
     var roleid=this._authSer.getRoleId()
      if(roleid=="1" || roleid=="2" ||roleid=="10")
      {
       // this.todatlEmployeeList.push({userId:0,employeeCodeName:"My Team"})
      this.toRoleid=0
 
      }
      // For Dashboard Emplyee Select List
      this.EmployeeList = this._commonSer.SetDDlDropdownfor(res["data"].table, 'userId', 'employeeCodeName',this.toRoleid);
      const selectedEmp = this.todatlEmployeeList.find(x => x.userId === this.currentUserId);
      if(this.toRoleid==0) 
      {
        if(res["data"].table.length==1)
        {
          if (selectedEmp !== undefined) {
            const empValue = { 'id': selectedEmp.userId, 'text': selectedEmp.employeeCodeName };
          this.value=selectedEmp.userId
  
            setTimeout(() => {
              this.ddlSelection(empValue, 'Employee');
            }, 0);
          }
        }
        else
        {
          if (selectedEmp !== undefined) {
            this.value=0
    
            //  const empValue = { 'id': selectedEmp.userId, 'text': selectedEmp.employeeCodeName };
            const empValue = { 'id': 0, 'text': "My Team" };
    
              setTimeout(() => {
                this.ddlSelection(empValue, 'Employee');
              }, 0);
            }
        }
       
      }
      else{
        if (selectedEmp !== undefined) {
          const empValue = { 'id': selectedEmp.userId, 'text': selectedEmp.employeeCodeName };
        this.value=selectedEmp.userId

          setTimeout(() => {
            this.ddlSelection(empValue, 'Employee');
          }, 0);
        }
    
      }
    });
  }

  getProjectsListForSelect (val) {// Created By A Get All Employees
    
    this._leaveSer.getProjectsListForSelect(val,this.CmpCode).subscribe((res) => {
      debugger
      this.todatlEmployeeList = res['data'].table;
      if (res['data'].table != null && res['data'].table.length > 0) {
        this.ProjectName = res["data"].table[0].projectname;
        this.ProjectCode = res["data"].table[0].projectid;
      }
      this.ProjectList = res['data'].table
    
      
    });
  }

  getFinancialYearDetailsback(evnt, financialYearId) {// Created By K Getting Previous financial year details
    debugger
   
     
      let cmp = this._authSer.getcompanyCode()
        this._leaveSer.getFinancialYearDetails(financialYearId, cmp).subscribe((res: any) => {
       
    
          this._leaveSer.getFinancialYearDetails(res.data.table1[0].prevId, cmp).subscribe((res) => {
            this.financialYearText = res["data"].table[0].title;
            this.currentFinancialYearId = res["data"].table[0].financialYearId;
            this.prevFinancialYearId = res["data"].table1[0].prevId;
            this.nextFinancialYearId = res["data"].table1[0].nextId;
            let today = new Date();
            let currentyear = today.getFullYear();
            let year =  res["data"].table[0].startYear;
            this.currentyear = today.getFullYear();
            this.year =  res["data"].table[0].startYear;
            if(year != currentyear){
              let elementAddLeave: HTMLElement = document.getElementsByClassName("LeaveTypeList")[0] as HTMLElement;
              let elementTypeList: HTMLElement = document.getElementsByClassName("LeaveDisablesOnpre")[0] as HTMLElement;
              let elementLeaves: HTMLElement = document.getElementsByClassName("disableActionbutton11")[0] as HTMLElement;
    
              // elementAddLeave.style.pointerEvents = "none"
              elementTypeList.style.pointerEvents = "none"
              // elementLeaves.style.pointerEvents = "none"
            }else{
              let elementAddLeave: HTMLElement = document.getElementsByClassName("LeaveTypeList")[0] as HTMLElement;
              let elementTypeList: HTMLElement = document.getElementsByClassName("LeaveDisablesOnpre")[0] as HTMLElement;
              let elementLeaves: HTMLElement = document.getElementsByClassName("disableActionbutton11")[0] as HTMLElement;
    
              // elementAddLeave.style.pointerEvents = "auto"
              elementTypeList.style.pointerEvents = "auto"
              // elementLeaves.style.pointerEvents = "auto"
            }
         
            this.getAllLeave();
          
            
          });
        });
      }

    getFinancialYearDetailsForwrd(evnt, financialYearId) {// Created By K Get Next Financial year details
      debugger
    
      let cmp = this._authSer.getcompanyCode()
        this._leaveSer.getFinancialYearDetails(financialYearId, cmp).subscribe((res: any) => {
          this.financialYearText = res["data"].table[0].title;
          this.currentFinancialYearId = res["data"].table[0].financialYearId;
          this.prevFinancialYearId = res["data"].table1[0].prevId;
          this.nextFinancialYearId = res["data"].table1[0].nextId;
          let today = new Date();
          let currentyear = today.getFullYear();
          let year =  res["data"].table[0].startYear;
          this.currentyear = today.getFullYear();
          this.year =  res["data"].table[0].startYear;
          if(year != currentyear){
            let elementAddLeave: HTMLElement = document.getElementsByClassName("LeaveTypeList")[0] as HTMLElement;
            let elementTypeList: HTMLElement = document.getElementsByClassName("LeaveDisablesOnpre")[0] as HTMLElement;
            // let elementHoliday: HTMLElement = document.getElementsByClassName("disableActionbutton11")[0] as HTMLElement;
            let elementLeaves: HTMLElement = document.getElementsByClassName("disableActionbutton11")[0] as HTMLElement;
  
            // elementAddLeave.style.pointerEvents = "none"
            elementTypeList.style.pointerEvents = "none"
            // elementHoliday.style.pointerEvents = "none"
            // elementLeaves.style.pointerEvents = "none"
          }else{
            let elementAddLeave: HTMLElement = document.getElementsByClassName("LeaveTypeList")[0] as HTMLElement;
            let elementTypeList: HTMLElement = document.getElementsByClassName("LeaveDisablesOnpre")[0] as HTMLElement;
            // let elementHoliday: HTMLElement = document.getElementsByClassName("disableActionbutton11")[0] as HTMLElement;
            let elementLeaves: HTMLElement = document.getElementsByClassName("disableActionbutton11")[0] as HTMLElement;
  
            // elementAddLeave.style.pointerEvents = "auto"
            elementTypeList.style.pointerEvents = "auto"
            // elementHoliday.style.pointerEvents = "auto"
            // elementLeaves.style.pointerEvents = "auto"
          }
         
          this.getAllLeave(); 
            
        });
      }
  ddlSelection(value: any, placeHolder: any) {// Created By K Global Controller For Fields Updates
    debugger;
    try {
      switch (placeHolder) {
        case 'Employee':
          this.ActiveEmployee = [];
          this.ActiveEmployee.push(value.item.innerText);
          this.ddlEmployeeUserId =value.item.id
          this.getAllLeave();
          this.userAvaliableLeave();
          break;

      }
    } catch (ex) {

    }
  }


  export(): void {// Created By K Export as Comp-off Lits as excel
    debugger
    (this.Grid.columns[0] as Column).visible = true;

    (this.Grid.columns[2] as Column).visible = true;
    (this.Grid.columns[4] as Column).visible = true;
    (this.Grid.columns[5] as Column).visible = false;
    (this.Grid.columns[8] as Column).visible = true;
    (this.Grid.columns[9] as Column).visible = false;
    (this.Grid.columns[10] as Column).visible = true;
    (this.Grid.columns[11] as Column).visible = false;
    (this.Grid.columns[12] as Column).visible = true;
    (this.Grid.columns[13] as Column).visible = true;

    (this.Grid.columns[14] as Column).visible = true;





    const excelExportProperties: ExcelExportProperties = {
      fileName: 'daily-report.xlsx'
  };
    this.Grid.excelExport(excelExportProperties);
  }
  Duration:any
  chkButtonView:boolean=true
  selectedItem:any
  disName:boolean;
  checkItem:any
   InviteEdituser(data,type){ // Created By A Initialize edit and view comp-off
    debugger

   let date =  this.datePipe.transform(data.workedDate, 'MM-dd-yyyy')
   
    this.getProjectsListForSelect(data.employeeId);
    if(type=='view'){
      this.disName=true
      this.chkButtonView=false
      this.dateValue=date
      this.view = false
     

      this.Duration=data.duration
      this.selectedItem=data.duration
      this.compLeaveApply.setValue({
       CompOffId:data.compOffId,
        UserId: data.employeeId,
        ProjectId: data.projectid,
        WorkedDate:date,
        Unit:data.unit,
        Duration:data.duration,
        ExpiryDate:data.expiryDate,
        WorkedStartTime:data.workedStartTime,
        WorkedEndTime:data.workedEndTime,
        Description:data.description,
        CreatedBy:data.userId,
        CmpCode:this.CmpCode,
      })
      setTimeout(() => {
        this.compLeaveApply.controls["ProjectId"].patchValue(data.projectid) 
      }, 500);
   
      this.checkItem=data.unit
      this.compLeaveApply.get('Duration').setValue(data.duration);
      this.datValue=this.dateformat(data.expiryDate)
      if(this.datValue)
      {
      this.setValue=true

      }

    }
    else{
      this.editMode=true
      this.chkButtonView=true
      this.dateValue=date
      this.view = true
      this.Duration=data.duration
      this.compLeaveApply.setValue({
       CompOffId:data.compOffId,
        UserId: data.employeeId,
        WorkedDate:date,
        Unit:data.unit,
        ProjectId: data.projectid,
        Duration:data.duration,
        ExpiryDate:data.expiryDate,
        WorkedStartTime:data.workedStartTime,
        WorkedEndTime:data.workedEndTime,
        Description:data.description,
        CreatedBy:data.userId,
        CmpCode:this.CmpCode,
      })
      setTimeout(() => {
        this.compLeaveApply.controls["ProjectId"].patchValue(data.projectid) 
      }, 500);
      this.datValue=this.dateformat(data.expiryDate)
      this.checkItem=data.unit
      this.compLeaveApply.get('Duration').setValue(data.duration);
      if(this.datValue)
      {
      this.setValue=true
      }


    }
  }
  showDeleteModel(data){// Created By A

  }
  setradio(_value: any): void {// Created By A Initialize unit of days
    this.selectedLink = _value;
    this.checkItem=_value
    if (_value == 1) {
      this.compLeaveApply.patchValue({
        Duration: "1"
      });
    } else {
      this.compLeaveApply.patchValue({
        Duration: "01.00"
      });
    }
    this.checkItem=_value
  }
  isSelected(name: any): boolean {// Created By K
    debugger
    if (!this.selectedLink) { // if no radio button is selected, always return false so every nothing is shown
      return false;
    }
    
    return (this.selectedLink === name); // if current radio button is selected, return true, else return false
  }

  selectUnit(unit) {// Created By A 
    this.selectHours = true;
  }
  selectUnit1(unit) {// Created By A
    this.selectHours = false;
  }

  getEmplyeDetails() {// Created By A Getting employees details
    this._leaveSer.getEmployeeDetails(this.currentUserId,this.CmpCode).subscribe((res) => {
      //this.todatlEmployeeList = res['data'].table
      this.employeName = res["data"].table[0].employeeName;
      this.employeCode = res["data"].table[0].empCode;
    });
  }

  dateRangeCreated($event1) {// Created By A Create date intervals
    this.startDate = $event1;
    this.minDate = this.startDate;
    this.minDate.setDate(this.minDate.getDate());
    let now = new Date(this.startDate);
    //let converDate: any = now.setDate(now.getDate() + 90);

    let _expireDate;
    if (this.generalSettingsData.creditedLeaveExpiresAfterType == 1) {
      _expireDate = now.setDate(now.getDate() + this.generalSettingsData.creditedLeaveExpiresAfter);
    } else if (this.generalSettingsData.creditedLeaveExpiresAfterType == 2) {
      _expireDate = now.setMonth(now.getMonth() + this.generalSettingsData.creditedLeaveExpiresAfter);
    } else {
      _expireDate = now.setDate(now.getDate() + 90); // Default
    }
    this.currentDate = new Date(_expireDate);

    this.workedDate = this.getFormattedDate($event1);
    this.expiryDate = this.getFormattedDate(this.currentDate);
  }
  dateRangeCreated1($event1) {// Created By A
    this.minDate = this.startDate;
  }
  totalData:any=[]
  getAllLeave() {// Created By A get all aplied comp-off
    debugger;
    let currentID = this.ddlEmployeeUserId; //this.currentUserId;
    this.parms = {
      UserId: currentID,
      PageNo: 1,
      NoOfRecords: 100000,
      SortType: "desc",
      FinancialYearId: this.currentFinancialYearId,
      FieldName: "CreatedDate",
      Condition: "",
      CmpCode:this.CmpCode
    };
    this.userLoader = true;
    this._leaveSer.getAllCompLeave(this.parms).subscribe((res) => {
      debugger;
      this.userLoader = false;
      this.COUNTRIES = res["data"].table;
      this.totalData=this.COUNTRIES
      this.checkLength=this.COUNTRIES
      this.totallength=this.COUNTRIES.length
      this.checkLength=this.totalData.filter(x=>x.status=="Pending")
      this.pendingLength=this.checkLength.length
      this.checkLength=this.totalData.filter(x=>x.status=="Approved")
      this.approvedLength=this.checkLength.length
 
     this.checkLength=this.totalData.filter(x=>x.status=="Rejected")
     this.rejectedLength=this.checkLength.length
      this.collectionSize = this.COUNTRIES.length;
    });
  }

  userAvaliableLeave() {// Created By A get availability detail
    this.userLoader = true;
    this._leaveSer.getAvailableLeaveBalance(this.currentUserId, 0,this.CmpCode).subscribe(res => {
      this.userLoader = false;
      this.userAvalLeave = res['data'].table;
    });
  }
  workdateError: boolean = true;
  openApplyCompOffModal() {// Created By A // Created By A information modal
    this.popUPCloseReset();
    this.datValue=""
    this.view = true
  this.disName=false
  this.chkButtonView=true
    this.workdateError = false;
    if (this.canApplyCompOff === true) {
      this._commonSer.OpenModalHandler("applyLeaveMode");
    } else {
      this.showSuccess = false;
      this.showMessage = "Compensatory Off request cannot be completed. Please contact administrator.";
      this._commonSer.OpenModalHandler("userMessageModel");
    }
  }
  popUPCloseReset() {// Created By A Reset modal
    this.compLeaveApply.reset();
    this.compLeaveApply.patchValue({
      UserId: this.currentUserId,
      Unit: 1,
      Duration: "1"
    });
    this.selectedLink = 1;

    if (this.generalSettingsData.unitsAllowed == 1 || this.generalSettingsData.unitsAllowed == 3) {
      this.compLeaveApply.patchValue({
        Unit: 1
      });
      this.setradio(1);
    } else if (this.generalSettingsData.unitsAllowed == 2 || this.generalSettingsData.unitsAllowed == 3) {
      this.compLeaveApply.patchValue({
        Unit: 2
      });
      this.setradio(2);
    }

    this.canApplyCompOff = true;
    if (this.generalSettingsData.compensatoryRequestMode == 1) {
      this.canApplyCompOff = false;
    }

    this.editMode = false;
  }
  selectLeaveID(selectId) {// Created By A
    this.compLeaveApply.patchValue({
      LeaveTypeId: selectId,
      UserId: this.currentUserId,
    });
  }
  getAllLeaveType1() {// Created By K Get all leave types
    this._leaveSer.getAllLeavetype(this.CmpCode).subscribe((response) => {
      this.allLeaveTypeList = response.data.table;
    });
  }

  getAllHolidaysLsist() {// Created By K All holidays list
    const parms = {};
    this._leaveSer.getAllHolidayList(parms).subscribe((response) => {
      this.allHolidayList = response.data.table;
    });
  }
  get f() {
    return this.compLeaveApply.controls;
  }
  getFormattedDate(val: any) {// Created By A Date Format
    if (val === null || val === "") {
      return null;
    }
    const date = new Date(val);
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    return new Date(year, month, day);
  }

  getCSharpFormattedDate(val: any) {// Created By A Backend date format
    if (val === null || val === "") {
      return null;
    }
    const date = new Date(val);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return month + "-" + day + "-" + year;
  }
  convertCSharpDateToFormattedDate(val: any) {// Created By A Backend date format
    if (val === null || val === "") {
      return null;
    }
    const date = new Date(val);
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    //return new Date(year + "-" + (month + 1) + "-" + day + " 00:00:00");
    return new Date(year, month, day);
  }

  selectedEmp: any;
  selectedWorkDate: any;
  selectedDuration: any;
  selectedExpDate: any;

 getDate:any
 setValue:boolean=false
 datValue:any
  selectWorkDate(val){// Created By A on Work date Select
    debugger;
    this.selectedWorkDate = val; 
    this.compLeaveApply.controls.WorkedDate.setValue(val);
    console.log(this.selectedWorkDate);
    var date=this.datePipe.transform(this.selectedWorkDate, 'MM/dd/yyyy')
    this._leaveSer.getcompDate(date,this.CmpCode).subscribe((res) => {
     debugger;
     var date=res['data']
   
    if(date.table[0].type==0)
    {
    this.setValue=false
    this.getDate=date.table[0].expiredate
    this.datValue=this.dateformat(this.getDate)
    this.compLeaveApply.controls.ExpiryDate.setValue(this.getCSharpFormattedDate(this.getDate));
    }
    else{
    this.setValue=true
    this.getDate=date.table[0].expiredate
    this.datValue=this.dateformat(this.getDate)
    
    this.compLeaveApply.controls.ExpiryDate.setValue(this.getCSharpFormattedDate(this.getDate));
    }

    }
    )
  }

  selectDuration(val){// Created By A On Duration select
    this.selectedExpDate = val; 
    this.compLeaveApply.controls.Duration.setValue(val) ;
    console.log(this.selectedExpDate);
  }

  
  selectEmp(val){// Created By A On Employee Select
    this.selectedEmp = val;
    this.getProjectsListForSelect(val);
    this.compLeaveApply.controls.UserId.setValue(val) ;
    console.log(this.selectedEmp);
  }
  selectProj(val){// Created By A On Project Select
    debugger
    this.compLeaveApply.controls.WorkedDate.setValue(null) ;
    let data = this.ProjectList.filter(x => x.projectid ==  val.value)
    this.maxdate = new Date(data[0].actualdate)
  }
  closeAllModals() {// Created By A Modal reset
    // this._commonSer.CloseModalHandler('requestDetailsModal');
    // this._commonSer.CloseModalHandler('requestApprovalModal');
    // this._commonSer.CloseModalHandler('bulkRequestsApprovalModal');
    // this._commonSer.CloseModalHandler('userMessageModel');
       this._commonSer.CloseModalHandler("confirmCancelModel");
       this._commonSer.CloseModalHandler("applyLeaveMode");
}
  // Apply Comoff
  applyLeave() {// Created By K On submit apply comp-off 
    debugger;
    this.submitted = true;
    if (this.compLeaveApply.invalid) {
      return;
    }

    if(this.compLeaveApply.controls["ProjectId"].value == null){
      this.compLeaveApply.controls["ProjectId"].patchValue(0)
    }

    this.userLoader = true;
    // let startDate = this.compLeaveApply.value.LeaveStartDate;
    // let endDate = this.compLeaveApply.value.LeaveEndDate;
    // var date1: any = new Date(startDate);
    // var date2: any = new Date(endDate);
    // var diffDays: any = (date2 - date1) / (1000 * 60 * 60 * 24);  
    if(this.compLeaveApply.controls.ExpiryDate.value)
    {
      this.compLeaveApply.patchValue({
        ExpiryDate:this.datePipe.transform(this.compLeaveApply.controls.ExpiryDate.value, 'MM/dd/yyyy'),
        
        Unit: this.selectedLink,
        CmpCode:this._authSer.getcompanyCode(),
        CreatedBy:this._authSer.getUserId()
      });
    }
    else if(this.compLeaveApply.controls.WorkedDate.value)
    {
      this.compLeaveApply.patchValue({
        WorkedDate:this.datePipe.transform(this.compLeaveApply.controls.WorkedDate.value, 'MM/dd/yyyy'),
        Unit: this.selectedLink,
        CmpCode:this._authSer.getcompanyCode(),
        CreatedBy:this._authSer.getUserId()
      });
    }
    else{
      this.compLeaveApply.patchValue({
        WorkedDate:this.datePipe.transform(this.selectedWorkDate, 'MM/dd/yyyy'),
        ExpiryDate:this.datePipe.transform(this.experyDate, 'MM/dd/yyyy'),
        Unit: this.selectedLink,
        CmpCode:this._authSer.getcompanyCode(),
        CreatedBy:this._authSer.getUserId()
      });
    }
   
    this.showSuccess = true;
   if( this.compLeaveApply.value.CompOffId==null) 
   {
    this.compLeaveApply.patchValue({
      CompOffId: 0
    });
   }
      this.compLeaveApply.patchValue({
        WorkedDate:this.datePipe.transform(this.compLeaveApply.controls.WorkedDate.value, 'MM/dd/yyyy'),
        
      });
      if(this.checkItem !=undefined)
      {
        this.compLeaveApply.patchValue({
          Unit:this.checkItem
          
        });
     
      }
     
    this._leaveSer.applyCompLeave(this.compLeaveApply.value).subscribe(
      (response) => {
        if(response.success == false){
          this.toasterService.show("error",response.message);
          this.userLoader = false;
          return
        }else{
          this.userLoader = false;
          this.submitted = false;
          this.showMessage = response.message;
          this.datValue=""
          //console.log(response);
          if (response.success == true) {
            //this.compLeaveApply.reset();
            this.showMessage = response.data;
            this.datValue=""
  
            this.getAllLeave();
          } else {
            this.userLoader = false;
            this.showSuccess = false;
            this.showMessage = response.message;
            this.datValue=""
  
          }
          this.closeAllModals()
          this.toasterService.show("success",this.showMessage);
  
        //  this._commonSer.OpenModalHandler("userMessageModel");
          this.popUPCloseReset();
        //  this._commonSer.CloseModalHandler("applyLeaveMode");
        }
       
      },
      (error) => {
        this.userLoader = false;
        this.showSuccess = false;
        this.showMessage = "Uh oh, Something went wrong !";
        this.toasterService.show("error",this.showMessage);
        this.datValue=""

        //this._commonSer.OpenModalHandler("userMessageModel");
        this.popUPCloseReset();
        this._commonSer.CloseModalHandler("applyLeaveMode");
      }
    );
  }

  // onSort({ column, direction }: SortEvent) {
  //   this.headers.forEach((header) => {
  //     if (header.sortable !== column) {
  //       header.direction = "";
  //     }
  //   });

  //   if (direction === "" || column === "") {
  //     this.COUNTRIES = this.COUNTRIES;
  //   } else {
  //     this.COUNTRIES = [...this.COUNTRIES].sort((a, b) => {
  //       const res = compare(`${a[column]}`, `${b[column]}`);
  //       return direction === "asc" ? res : -res;
  //     });
  //   }
  // }

  // get countries(): Country[] {
  //   return this.COUNTRIES.map((country, i) => ({
  //     id: i + 1,
  //     ...country,
  //   })).slice(
  //     (this.page - 1) * this.pageSize,
  //     (this.page - 1) * this.pageSize + this.pageSize
  //   );
  // }

  // General Settings Data
  getGeneralSettingsData() {// Created By A Get leaves genral settings info
    this._leaveSer.getGeneralSettings(this.CmpCode).subscribe((res) => {
      this.userLoader = false;
      this.generalSettingsData = res["data"];
      // this.setDayListDDL();
      this.canApplyCompOff = true;
      if (this.generalSettingsData.compensatoryRequestMode == 1) {
        this.canApplyCompOff = false;
      }

      if (this.generalSettingsData.unitsAllowed == 1 || this.generalSettingsData.unitsAllowed == 3) {
        this.compLeaveApply.patchValue({
          Unit: 1
        });
        this.setradio(1);
      } else if (this.generalSettingsData.unitsAllowed == 2 || this.generalSettingsData.unitsAllowed == 3) {
        this.compLeaveApply.patchValue({
          Unit: 2
        });
        this.setradio(2);
      }

      if (this.generalSettingsData.leaveCancellationReasonMandatory == true) {
        this.isCancelReasonMandatory = true;
      }
    },
    error => {
      console.log(error);
    });
  }

  // setDayListDDL() {
  //   this.DayTypeSelectList = [];

  //   if (this.generalSettingsData.durationAllowedFullDay == true) {
  //     this.DayTypeSelectList.push({ value: "1", text: "Full Day" });
  //   }
  //   if (this.generalSettingsData.durationAllowedHalfDay == true) {
  //     this.DayTypeSelectList.push({ value: "2", text: "Half Day" });
  //   }
  //   if (this.generalSettingsData.durationAllowedQuarterDay == true) {
  //     this.DayTypeSelectList.push({ value: "3", text: "Quarter Day" });
  //   }
  // }
  experyDate:any  
  selectChangeExpiryDate(val)// Created By A
  {
    debugger;
   this.experyDate=val

  }

  calculateDuration() {// Created By A Comp-off duration calculation
    let start = this.compLeaveApply.value.WorkedStartTime;
    let end = this.compLeaveApply.value.WorkedEndTime;
    if (start != null && end != null) {
      if (this.isSelected(2)) {
        let a = start.split(':'); // split it at the colons
        let b = end.split(':');
        // minutes are worth 60 seconds. Hours are worth 60 minutes.
        let seconds = a.length == 3 ? (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]) : (+a[0]) * 60 * 60 + (+a[1]) * 60;
        let second2 = b.length == 3 ? (+b[0]) * 60 * 60 + (+b[1]) * 60 + (+a[2]) : (+b[0]) * 60 * 60 + (+b[1]) * 60;
        let duration = second2 - seconds;
        let totalDuration = new Date(duration * 1000).toISOString().substr(11, 8);

        let hour = totalDuration.split(':')[0];
        let minutes = totalDuration.split(':')[1];
        let ddlDuration = this.getNearestHalfHourTimeString(hour, minutes);
        //alert(ddlDuration);
        this.compLeaveApply.patchValue({
          Duration: ddlDuration.trim()
        });
      }
    }
  }

  getNearestHalfHourTimeString(hour, minutes) {// Created By A Get hours and minutes
    let _hr = 0;
    let _min = "00";
    if (minutes < 15) {
        minutes = "00";
    } else if (minutes < 45) {
        minutes = "30";
    } else {
        minutes = "00";
        ++hour;
    }
    if (hour > 23) {
        hour = 12;
    } else if (hour > 12) {
        hour = hour - 12;
    } else if (hour == 0) {
        hour = 12;
    }

    if (hour > 9) {
      hour = 9;
      if (minutes == "30") {
        minutes = "00";
      }
    }

    _hr = hour;
    if (minutes == "00") {
      _min = "00";
    } else if (minutes = "30") {
      _min = "50";
    }

    return(_hr + "." + _min);
  }


  // Edit Compensatory off
  getCompOffDetails(compOffId: number, statusId: number) {// Created By A // Created By A Get comp-off detalis with id
    if (compOffId > 0 && statusId === 1) {
      let vm = this;
      vm.userLoader = true;
      vm._leaveSer.getCompOffDetailsForEdit(compOffId)
        .subscribe((response) => {
          vm.userLoader = false;
          if (response["success"] == true) {
            vm.setCompOffFormData(response["data"]);
          } else {
            vm.showSuccess = false;
            vm.showMessage = response["message"];
            vm._commonSer.OpenModalHandler("userMessageModel");
          }
        }, (error) => {
          vm.userLoader = false;
          vm.showSuccess = false;
          vm.showMessage = "Uh oh, Something went wrong !";
          vm._commonSer.OpenModalHandler("userMessageModel");
        });
    }
  }
  showWarning: Boolean = false;
  deleteId = 0;
  IsDelete: Boolean = false;
  deleteHoliday(data) {// Created By A Initialize Confirmation modal
    this.deleteId = data.compOffId;
    this.showSuccess = false;
    this.showWarning = false;
    this.IsDelete = true;
    // this.confirmBox = true;
    // this.isView = false;
    this.showMessage = "Are you sure, you want to delete this record?";
    this._commonSer.OpenModalHandler("MessageModel");
    

  }

  ConfirmDeleteAction() {// Created By A On Confirm delete
    debugger
    const params={
      Id:this.deleteId,
     // deleteBy:this.UserId,
      formName :'Client Holiday',
      CmpCode: this.CmpCode,
     }
   
    try {
      this._commonSer.CloseModalHandler("MessageModel");
      
    //   this.Http.deleteClientHoliday(params).then((res) =>{
        
    //     this.getAllHolidays();
    //     this.showMessage =  res.message;
    //     this.IsDelete = false;
    //     this.deleteId = 0;
    //     this.confirmBox = false;
    //     this.showWarning = false;
    //     if (res.success == true) {
    //       //this.showSuccess = true;
    //       this.toaster.show("success", "Record Deleted Successfully");
    //     } else {
    //       this.toaster.show("warning", res.message);
    //     }

    //   });
   } catch (ex) {
  console.log(ex);
    //  this.getAllHolidays();
     }
  }

  // 
  setCompOffFormData(compOffDetails) {// Created By A Set Form Data from DB
    this.popUPCloseReset();
    this.submitted = true;
    this.editMode = true;

    if (compOffDetails.unit == 1) {
      this.setradio(1);
    } else if (compOffDetails.unit == 2) {
      this.setradio(2);
    }

    this.compLeaveApply.patchValue({
      CompOffId: compOffDetails.compOffId,
      UserId: compOffDetails.userId,
      WorkedDate: this.convertCSharpDateToFormattedDate(compOffDetails.workedDate),
      Unit: compOffDetails.unit,
      Duration: compOffDetails.duration,
      ExpiryDate: this.convertCSharpDateToFormattedDate(compOffDetails.expiryDate),
      WorkedStartTime: compOffDetails.workedStartTime,
      WorkedEndTime: compOffDetails.workedEndTime,
      Description: compOffDetails.description,
      CreatedBy: compOffDetails.createdBy
    });

    this._commonSer.OpenModalHandler("applyLeaveMode");
  }

  CancelCompOffRequest() {// Created By K Initialize Cancle comp-off
    this.cancelCompOffReason = "";
    this.isCancelReasonTextboxEmpty = false;

    this._commonSer.OpenModalHandler("confirmCancelModel");
  }
  ConfirmCompOffCancel() {// Created By K On Confirm cancle comp-off
    this.isCancelReasonTextboxEmpty = false;
    if (this.isCancelReasonMandatory && (this.cancelCompOffReason == null || this.cancelCompOffReason.trim().length == 0)) {
      this.isCancelReasonTextboxEmpty = true;
      return;
    }

    let currentUserID = this.currentUserId;
    this.parms = {
      CompOffId: this.compLeaveApply.value.CompOffId,
      CancelReason: this.cancelCompOffReason,
      UserId: currentUserID,
      CmpCode:this.CmpCode
    };
    this.userLoader = true;
    this.showSuccess = true;
    this._leaveSer.cancelCompOffRequest(this.parms).subscribe((response) => {
      this.userLoader = false;
      this.getAllLeave();
      if (response.success === true) {
        this.compLeaveApply.reset();
        this.showSuccess = true;
        this.showMessage = response.data;
      this.closeAllModals()

      
          this.toasterService.show("success",this.showMessage);
     //   this._commonSer.OpenModalHandler("userMessageModel");
       // this._commonSer.CloseModalHandler("confirmCancelModel");
      //  this._commonSer.CloseModalHandler("applyLeaveMode");
        this.userAvaliableLeave();
      } else {
        this.showSuccess = false;
        this.showMessage = response.message;
        this.toasterService.show("success",this.showMessage);
      this.closeAllModals()


      //  this._commonSer.OpenModalHandler("userMessageModel");
     //   this._commonSer.CloseModalHandler("confirmCancelModel");
      }
    },
    error => {
      this.showSuccess = false;
      this.showMessage = "Something went's wrong, please try again.";
      this.toasterService.show("success",this.showMessage);
      this.closeAllModals()


    //  this._commonSer.OpenModalHandler("userMessageModel");
   //   this._commonSer.CloseModalHandler("confirmCancelModel");
      console.log(error);
    });
  }
  
 recieptIMg: any;
 userImage(item) {// Created By K Convert users image URL in standard format 
   // <img src="/assets/img/Logo/male.png" style="width: 190px;">
   if (item.userImage == '' || item.userImage == undefined || item.userImage == null) {
     // this.recieptIMg = `${environment.siteUrl}` + "Uploads/Icon/UploadIcons.png";
     if (item.genders == "Female") {
       this.recieptIMg = "/assets/img/Logo/female.png"

     }
     else {
       this.recieptIMg = "/assets/img/Logo/male.png"

     }
   }
   else {
     this.recieptIMg = `${environment.siteUrl}` + item.userImage;
   }
   return this.recieptIMg

 }
 approvedLength:any=0
 rejectedLength:any=0
 pendingLength:any=0
 totallength:any=0
 checkLength:any=[]
 active:any=0
 getlistbylegend(val)// Created By K Filters by legends
 {
   if(val==0)
   {
     this.active=0
    this.COUNTRIES=this.totalData
   }
   else
   if(val==1)
   {
    this.active=1
      this.COUNTRIES=[]
     this.COUNTRIES=this.totalData.filter(x=>x.status=="Pending")
   }
   else if(val==2)
   {
    this.active=2

     this.COUNTRIES=[]
     this.COUNTRIES=this.totalData.filter(x=>x.status=="Approved")

   }
   else if(val==3)
   {
    this.active=3

    this.COUNTRIES=[]
    this.COUNTRIES=this.totalData.filter(x=>x.status=="Rejected")

     
   }
debugger
 }
}
