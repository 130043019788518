import { Injectable } from '@angular/core';

@Injectable()
export class Language {
    languageId: number;
    name: string;
    displayName: string;
    icon: string;
    isDefault: boolean;
    inActive: boolean;
    updatedBy: string;
    attributes: any;
}
export class LanguageTranslation {
    id:number;
    languageId: number;
    keyWord: string;
    value: string;
    attributes: any;
}
export class Listing {
    pageNo: number = 1;
    noOfRecords: number = 10;
    sortType: string = 'asc';
    fieldName: string = 'displayName';
    condition: string;
    CmpCode:any;
}