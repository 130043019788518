<div [ngClass]="{'tdefault':ThemeId === '0', 't1':ThemeId === '1', 'tzero':ThemeId === '2', 't2':ThemeId === '3', 't3':ThemeId === '4', 't4':ThemeId === '5',
't5':ThemeId === '6', 't6':ThemeId === '7', 't7':ThemeId === '8'}">

<ng-container *ngIf="customLayout; else noCustomLayout">
  <router-outlet></router-outlet>
</ng-container>
<ng-template #noCustomLayout>
  <mk-layout-wrapper>
    <mk-layout-header>
      <mk-layout-header-logo>
        <a   href="/#/home"><img src="/assets/img/Logo/CRGLogo.png" style="width: 190px;" /></a>
        <!-- ../assets/img/Logo/CRGLogo.png -->
      </mk-layout-header-logo>
      <mk-layout-header-logo-mini>
        <img
          src="../assets/img/Logo/CRGLogo1.png"
          style="width: 36px;
        height: auto;

        border-radius: 50%;
        border: 2px solid #fff;"
        />
      </mk-layout-header-logo-mini>

      <app-header-inner></app-header-inner>
    </mk-layout-header>
    <mk-layout-sidebar-left>
      <!-- <app-sidebar-left-inner></app-sidebar-left-inner> -->
    </mk-layout-sidebar-left>
    <!-- <mk-layout-sidebar-right>
      <app-sidebar-right-inner></app-sidebar-right-inner>
    </mk-layout-sidebar-right> -->
    <mk-layout-content>
      <!-- <div mk-layout-content-before-header>
        <div
          *mkLoadingPage="{ checkPendingHttp: true, checkPendingRoute: true }"
        >
          <mk-material-bar></mk-material-bar>
        </div>
      </div> -->
      <router-outlet></router-outlet>
    </mk-layout-content>
    <mk-layout-footer>
      <mk-layout-footer-left>
        <span class="footer">Version 1.0 |
          {{ currentYear }} © Powered by
          <a  target="_blank" href="https://crgsolutions.co"
            >CRG Solutions Pvt. Ltd.</a
          ></span
        >
 
      </mk-layout-footer-left>
      <mk-layout-footer-right>
      
      </mk-layout-footer-right>
    </mk-layout-footer>
    <app-toaster-container></app-toaster-container>
  </mk-layout-wrapper>
</ng-template>
</div>
