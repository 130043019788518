<header #headerElement class="main-header">
  <div>
  <a *ngIf="isSidebarLeftToggle" #sidebarLeftToggleElement href="#" class="sidebar-toggle" id="asidemenu"
    (click)="settoggle()">
    <span class="sr-only">Toggle navigation</span>
    <span class="icon-bar"></span>
    <span class="icon-bar"></span>
    <span class="icon-bar"></span>
  </a>
  <a href="#/home" class="logo">
    <span class="logo-mini" style="margin-left: 0px !important;">
      <ng-template [ngTemplateOutlet]="headerLogoMiniComponent?.templateRef"></ng-template>
    </span>
    <span class="logo-lg">
      <ng-template [ngTemplateOutlet]="headerLogoComponent?.templateRef"></ng-template>
    </span>
  </a>
</div>
  <nav class="navbar navbar-static-top text-left">



    <!-- <div *ngIf="isSidebarRightToggle" class="sidebar-right-toggle">
      <a #sidebarRightToggleElement href="#"><i class="fa fa-gears"></i></a>
    </div> -->
    <ng-content></ng-content>

    <div *ngIf="ischeckedin=='no'" class="checkin text-center">

      <span (click)="checkin()" style="    clear: both;
        display: block;
        background: #27cda5;cursor: pointer;
        color: #fff;
        margin: 0 8px 0 15px;
        width: 120px;
        padding: 5px;
        border-radius: 25px;">Check-In<span class="badge">{{NoOfattemps}}</span></span>


    </div>

    <div *ngIf="ischeckedin=='yes'" class="checkin text-center">


      <span (click)="checkout()" style="    clear: both;
        display: block;
        background: #e95b6d;cursor: pointer;
        color: #fff;
        margin: 0 8px 0 15px;
        width: 120px;
        padding: 5px;
        border-radius: 20px;">Check-Out<span class="badge">{{NoOfattemps}}</span></span>
        <label style="margin:0;font-weight: 500; font-family: 'Digital-7';
        font-size: 20px; width: 70px; text-align: left;">{{hour}}:{{mint}}:{{timeLeft}}</label>


    </div>


  </nav>
</header>
