<div class="bg-overlady" *ngIf="userLoader">
  <div class="loader-position">
    <div class="loader"></div>
    <h2>Loading....</h2>
  </div>
</div>

<mk-box header="" [isCollapsable]="false" [isRemovable]="false" contentStyleClasses="table-responsive">
  <div class="row" style="margin: 0px;">
    <div class="col-sm-4 no-padding">
      <h4 class="title-new">User Availability Report</h4>
    </div>
    <div class="col-sm-4">
      <div class="navigation-buttons">
        <button type="button" class="btn" title="Previous" (click)="navigatePrevious($event)">
          <svg id="i-chevron-left" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="20" height="20" fill="none"
            stroke="#204d74" stroke-linecap="round" stroke-linejoin="round" stroke-width="3">
            <path d="M20 30 L8 16 20 2" />
          </svg>
        </button>
        <h4 style="display: inline;">{{ navigationText }}</h4>
        <button type="button" class="btn" title="Next" (click)="navigateNext($event)">
          <svg id="i-chevron-right" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="20" height="20"
            fill="none" stroke="#204d74" stroke-linecap="round" stroke-linejoin="round" stroke-width="3">
            <path d="M12 30 L24 16 12 2" />
          </svg>
        </button>
      </div>
    </div>
    <!-- <div class="btn-group">
      <button type="button" class="btn btn-primary btn-brand" data-toggle="dropdown" aria-haspopup="true"
        aria-expanded="false" title="Export">
        <img src="../assets/img/Logo/22.png">
      </button>
      <ul class="dropdown-menu">
        <li>
          <a (click)="export()">Excel</a>
        </li>
        <li>
          <a (click)="printList('tableData')">Print</a>
        </li>
        <li>
          <a (click)="exportTableToCSV()">CSV</a>
        </li>
     
      </ul>
    </div> -->
    <div class="col-sm-4 text-right btnset" style="padding-top: 50px;">
      <!-- <button class="mx-auto" type="button" id="navbarDropdown" (click)="filterShow()"  role="button" data-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false"><i class="fa fa-filter" aria-hidden="true"></i>
      </button> -->

      <div class="btn-group">
        <button type="button" class="AddLeaveType mx-auto btn btn-primary btn-brand" data-toggle="modal" data-target="#exampleModalfilter2" title="Filters" (click)="filterShow()">
          <img src="../assets/img/Logo/23.png">
        </button>
      </div>

      <!-- Filter and Export -->
      <div class="btn-group">
        <!-- <div class="container datashow rt-filters" *ngIf="flterShowData">
          <div class="card">
            <div class="card card-body">
              <div>
                <div class="row row-bor-bot">                   
                  <div class="col-xs-9">
                    <h4>Filter</h4>
                  </div>
                  <div class="col-xs-3 text-right">
                    <h4 class="icon-close" (click)="closeFilter();">x</h4>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="depart">Period</label>
                      <select class="form-control" name="filterPeriod" [(ngModel)]="filterPeriod"
                        (change)="onChangeFilterPeriod($event);">
                        <option value=1>Yesterday</option>
                        <option value=2>Today</option>
                        <option value=3>Last Week</option>
                        <option value=4>This Week</option>
                        <option value=5>Last Month</option>
                        <option value=6>This Month</option>
                        <option value=7>Custom</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="depart">From</label>
                      <div class="input-group date">
                        <div class="input-group-addon">
                          <i class="fa fa-calendar"></i>
                        </div>
                        <input type="text" class="form-control" name="filterStartDate" [(ngModel)]="filterStartDate"
                          [attr.disabled]="filterPeriod != 7 ? 'true' : null" 
                          placeholder="From" autocomplete="off" bsDatepicker style="margin-top: 0; margin-bottom: 0;"
                          [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', containerClass: 'theme-dark-blue', customTodayClass: 'custom-today-class', showWeekNumbers: false }"
                          autocomplete="off" (bsValueChange)="onDPStartDateChange($event)"
                          bsDatepicker id="dpStartDate" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="depart">To</label>
                      <div class="input-group date">
                        <div class="input-group-addon">
                          <i class="fa fa-calendar"></i>
                        </div>
                        <input type="text" class="form-control" name="filterEndDate" [(ngModel)]="filterEndDate"
                          [attr.disabled]="filterPeriod != 7 ? 'true' : null" 
                          placeholder="From" autocomplete="off" bsDatepicker style="margin-top: 0; margin-bottom: 0;"
                          [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', containerClass: 'theme-dark-blue', customTodayClass: 'custom-today-class', showWeekNumbers: false }"
                          autocomplete="off" (bsValueChange)="onDPEndDateChange($event)"
                          bsDatepicker id="dpEndDate" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="depart">Department</label>
                      <ng-select [allowClear]="true" [(active)]="ActiveDepartments" [(items)]="ddlDepartmentList"
                        (selected)="ddlSelection($event, 'Department')" placeholder="All Department"
                        #ddlFilterDepartments (opened)="closeDropdownList(ddlFilterDepartments)"
                        (removed)="removeFilterDropdownValue($event, 'Department')">
                      </ng-select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="location">Location</label>
                      <ng-select [allowClear]="true" [(active)]="ActiveLocations" [(items)]="ddlLocationList"
                        (selected)="ddlSelection($event, 'Location')" placeholder="All Location"
                        #ddlFilterLocations (opened)="closeDropdownList(ddlFilterLocations)"
                        (removed)="removeFilterDropdownValue($event, 'Location')">
                      </ng-select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="desigation" >Desigation</label>
                      <ng-select [allowClear]="true" [(active)]="ActiveDesignations" [(items)]="ddlDesignationList"
                        (selected)="ddlSelection($event, 'Desigation')" placeholder="All Desigation"
                        #ddlFilterDesigations (opened)="closeDropdownList(ddlFilterDesigations)"
                        (removed)="removeFilterDropdownValue($event, 'Desigation')">
                      </ng-select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="depart">User Status</label>
                      <select class="form-control" name="filterUserStatus" [(ngModel)]="filterUserStatus">
                        <option value=null>All</option>
                        <option value="0">Active</option>
                        <option value="1">Inactive</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">               
                  <div class="col-sm-12">
                    <button type="button" (click)="processFilters()" class="btn btn-primary btn-brand">Search</button>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <button type="button" (click)="resetFilters()" class="btn btn-default">Reset</button>
                  </div>                    
                </div>
              </div>
            </div>
          </div>
        </div> -->
        
        <div class="dropdown rt-dropdown">
          <label class="col-form-label btn btn-primary btn-brand" data-toggle="dropdown"><img src="../assets/img/Logo/22.png" aria-hidden="true"></label>
          <ul class="dropdown-menu pull-right">
            <li > <a (click)="ExportExcel()">Excel</a> </li>
            <!-- <li>
              <a (click)="export()">Excel</a>
            </li> -->
            <li>
              <a (click)="printList('tableData')">Print</a>
            </li>
            <li>
              <a (click)="ExportCSV()">CSV</a>
            </li>
         
          </ul>
        </div>
      </div>

    </div>
  </div>
  
  <div class="row" id="tableData">
    <div class="col-sm-12 no-padding" style="overflow-x: scroll;">
      <div class="rt-table-outer" style="width: 2000px;">
        <div class="rt-table-inner horizontal-scroll-except-first-column">
          <!-- <ejs-grid #overviewgrid id='overviewgrid' [dataSource]='newArray' rowHeight='38' [allowSelection]='true'
          [allowSorting]='true' height='600' [enableHover]='false' [selectionSettings]='selectionSettings'
          [filterSettings]='filterSettings' [allowSorting]='true' [allowFiltering]='true' [enableVirtualization]='false'
          (load)='load()' [allowPaging]='true' [pageSettings]='pageSettings'>
          <e-columns>
            <e-column field='employeeName' headerText='Name'>
              <ng-template #template let-listData>
               {{listData.employeeName}}
              
              </ng-template>
            </e-column>
           
          </e-columns>
        </ejs-grid> -->
          <!-- <ejs-grid #overviewgridfor id='overviewgridfor'  [dataSource]='newArray' rowHeight='38' [allowSelection]='true' [columns]="columns" 
          [allowSorting]='true' height='600' [enableHover]='false' [selectionSettings]='selectionSettings' 
          [filterSettings]='filterSettings' [allowSorting]='true' [allowFiltering]='true' [enableVirtualization]='false' 
          (load)='load()' [allowPaging]='true'   [pageSettings]='pageSettings' [allowExcelExport]='true' [allowPdfExport]="true">
          <e-columns>
              <ng-template #template let-data>
                <e-column field='data.value' headerText='data.key' [filter]='filter' clipMode='EllipsisWithTooltip'>
                </e-column>
              </ng-template>
          </e-columns>
        </ejs-grid> -->
        <!-- {{newArrayGrid | json}} -->
        <ejs-grid #overviewgrid id='overviewgrid'   [dataSource]='newArrayGrid' rowHeight='38' [allowSelection]='true' [columns]="columns"  (queryCellInfo)='tooltip($event)' 
        [allowSorting]='true' height='600' [enableHover]='false' [selectionSettings]='selectionSettings'
        [filterSettings]='filterSettings' [allowSorting]='true' [allowFiltering]='true' [enableVirtualization]='false' (actionComplete)="actionComplete($event)" 
        (load)='load()' [allowPaging]='true'   [pageSettings]='pageSettings' [allowExcelExport]='true' [allowPdfExport]="true">
        <e-columns>
           <!-- <ng-template #template let-data> -->
            <e-column field='employeeId' headerText='Employee ID' [filter]='filter'  clipMode='EllipsisWithTooltip'>
            </e-column>
              <e-column field='employeeName'  headerText='Employee Name' width='120' [filter]='filter' clipMode='EllipsisWithTooltip'>
                <ng-template #template let-data>
                  <span>
                    <img src="{{userImage(data)}}" style="width: 28px;margin-right: 5px; border-radius: 50%;">
                    {{data.employeeName}}
                  </span>
                </ng-template>
              </e-column>
              <e-column field='01' headerText='Email' [filter]='filter'  clipMode='EllipsisWithTooltip'>
              </e-column>
              <e-column field='01' headerText='01' [filter]='filter'  clipMode='EllipsisWithTooltip'>
              </e-column>
              <e-column field='02' headerText='02' [filter]='filter' clipMode='EllipsisWithTooltip'>
              </e-column>
              <e-column field='03' headerText='03' [filter]='filter' clipMode='EllipsisWithTooltip'>
              </e-column>
              <e-column field='04' headerText='04' [filter]='filter' clipMode='EllipsisWithTooltip'>
              </e-column>
              <e-column field='05' headerText='05' [filter]='filter' clipMode='EllipsisWithTooltip'>
              </e-column>
              <e-column field='06' headerText='06' [filter]='filter' clipMode='EllipsisWithTooltip'>
              </e-column>
              <e-column field='07' headerText='07' [filter]='filter' clipMode='EllipsisWithTooltip'>
              </e-column>
              <e-column field='08' headerText='08' [filter]='filter' clipMode='EllipsisWithTooltip'>
              </e-column>
              <e-column field='09' headerText='09' [filter]='filter' clipMode='EllipsisWithTooltip'>
              </e-column>
              <e-column field='10' headerText='10' [filter]='filter' clipMode='EllipsisWithTooltip'>
              </e-column>
              <e-column field='11' headerText='11' [filter]='filter' clipMode='EllipsisWithTooltip'>
              </e-column>
              <e-column field='12' headerText='12' [filter]='filter' clipMode='EllipsisWithTooltip'>
              </e-column>
              <e-column field='13' headerText='13' [filter]='filter' clipMode='EllipsisWithTooltip'>
              </e-column>
              <e-column field='14' headerText='14' [filter]='filter' clipMode='EllipsisWithTooltip'>
              </e-column>
              <e-column field='15' headerText='15' [filter]='filter' clipMode='EllipsisWithTooltip'>
              </e-column>
              <e-column field='16' headerText='16' [filter]='filter' clipMode='EllipsisWithTooltip'>
              </e-column>
              <e-column field='17' headerText='17' [filter]='filter' clipMode='EllipsisWithTooltip'>
              </e-column>
              <e-column field='18' headerText='18' [filter]='filter' clipMode='EllipsisWithTooltip'>
              </e-column>
              <e-column field='19' headerText='19' [filter]='filter' clipMode='EllipsisWithTooltip'>
              </e-column>
              <e-column field='20' headerText='20' [filter]='filter' clipMode='EllipsisWithTooltip'>
              </e-column>
              
              <e-column field='21' headerText='21' [filter]='filter' clipMode='EllipsisWithTooltip'>
              </e-column>
              <e-column field='22' headerText='22' [filter]='filter' clipMode='EllipsisWithTooltip'>
              </e-column>
              <e-column field='23' headerText='23' [filter]='filter' clipMode='EllipsisWithTooltip'>
              </e-column>
              <e-column field='24' headerText='24' [filter]='filter' clipMode='EllipsisWithTooltip'>
              </e-column>
              <e-column field='25' headerText='25' [filter]='filter' clipMode='EllipsisWithTooltip'>
              </e-column>
              <e-column field='26' headerText='26' [filter]='filter' clipMode='EllipsisWithTooltip'>
              </e-column>
              <e-column field='27' headerText='27' [filter]='filter' clipMode='EllipsisWithTooltip'>
              </e-column>
              <e-column field='28' headerText='28' [filter]='filter' clipMode='EllipsisWithTooltip'>
              </e-column>
              <e-column field='29' headerText='29' [filter]='filter' clipMode='EllipsisWithTooltip'>
              </e-column>
              <e-column field='30' headerText='30' [filter]='filter' clipMode='EllipsisWithTooltip'>
              </e-column>
              <e-column field='31' headerText='31' [filter]='filter' clipMode='EllipsisWithTooltip'>
              </e-column>
            <!-- </ng-template> -->
        </e-columns>
      </ejs-grid>

          <!-- <table class="table table-bordered table-hover rt-table">
            <thead>
              <tr>
                <th scope="col" style="text-align: left; min-width: 300px;">Employee</th>
                <th scope="col" style="text-align: center; min-width: 80px;" *ngFor="let item of datesList">
                  <strong>{{ item.dateString }}</strong>
                  <br />
                  <span>{{ item.day }}</span>
                </th>
              </tr> 
            </thead>
            <tbody>
              <ng-container *ngFor="let item of employeeDataList">
                <tr [ngClass]="{'tr-deleted' : item.isdeleted == true, 
                    'tr-inactive' : (item.inActive == true && item.isdeleted == false)}">
                  <td scope="row" title="{{ (item.inActive == true && item.isdeleted == false) ? 'User Inactive' : (item.isdeleted == true ? 'User Deleted' : '') }}">
                    <span>
                      <img class="img-circle img-sm" src="./../../../assets/img/avatar5.png" alt="User Image">
                      &nbsp;
                    </span>
                    <span>{{ item.empCode }}</span>
                    <span>&nbsp;-&nbsp;</span>
                    <label>{{ item.employeeName }}</label>

                    <span> &nbsp;&nbsp; </span>
                   
                    
                  </td>
                  <ng-container *ngFor="let dateItem of item.dateList">
                    <td style="text-align: center;" [ngStyle]="{ 'background-color': (dateItem.isWeekend == true ? dateItem.colorCode : '#fff') }">
                      <span>&nbsp;</span>
                      <div *ngIf="dateItem.isWeekend == false && dateItem.isHoliday == true">
                        <h5 class="holidayHr">
                          <label style="margin-bottom: 5px;" title="{{ dateItem.fullText }}">H</label>
                        </h5>
                      </div>
                      <div *ngIf="dateItem.isWeekend == false && dateItem.isHoliday == false && dateItem.isLeave == true">
                        <h5 class="leaveHr" [ngStyle]="{ borderColor: dateItem.colorCode }">
                          <label style="margin-bottom: 5px;" title="{{ dateItem.fullText }}">{{ dateItem.text }}</label>
                        </h5>
                      </div>
                    </td>
                  </ng-container>
                </tr>
              </ng-container>
            </tbody>
          </table> -->
        </div>
      </div>
    </div>
  </div>

  <div class="row" style="margin-top: 20px; margin-bottom: 20px; overflow-x: scroll;">
    <div class="col-sm-12" style="width: 4000px;">
      <table style="border-right: 1px solid #ddd;">
        <tr>
          <td *ngFor="let item of LeaveTypeList">
            <div class="dvLeaveType" [ngStyle]="{ borderColor: item.colorCode }">
              {{ item.leaveTypeName }}
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>

</mk-box>




<!-- Modal -->
<div class="modal fade small-filter" id="exampleModalfilter2" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Filters</h5>
        <button type="button" id="saveClick" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="width: 100%; display: inline-block;">
        <div class="container datashow rt-filters no-padding" *ngIf="flterShowData">
          <div class="card">
            <div class="card card-body">
              <div>
                
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="depart">Period</label>
                      <select class="form-control" name="filterPeriod" [(ngModel)]="filterPeriod"
                        (change)="onChangeFilterPeriod($event);">
                        <option value=1>Yesterday</option>
                        <option value=2>Today</option>
                        <option value=3>Last Week</option>
                        <option value=4>This Week</option>
                        <option value=5>Last Month</option>
                        <option value=6>This Month</option>
                        <option value=7>Custom</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="depart">From</label>
                      <div class="input-group date">
                        <div class="input-group-addon">
                          <i class="fa fa-calendar"></i>
                        </div>
                        <input type="text" class="e-input" name="filterStartDate" [(ngModel)]="filterStartDate"
                          [attr.disabled]="filterPeriod != 7 ? 'true' : null" 
                          placeholder="From" autocomplete="off" bsDatepicker style="margin-top: 0; margin-bottom: 0;"
                          [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', containerClass: 'theme-dark-blue', customTodayClass: 'custom-today-class', showWeekNumbers: false }"
                          autocomplete="off" (bsValueChange)="onDPStartDateChange($event)"
                          bsDatepicker id="dpStartDate" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="depart">To</label>
                      <div class="input-group date">
                        <div class="input-group-addon">
                          <i class="fa fa-calendar"></i>
                        </div>
                        <input type="text" class="form-control" name="filterEndDate" [(ngModel)]="filterEndDate"
                          [attr.disabled]="filterPeriod != 7 ? 'true' : null" 
                          placeholder="From" autocomplete="off" bsDatepicker style="margin-top: 0; margin-bottom: 0;"
                          [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', containerClass: 'theme-dark-blue', customTodayClass: 'custom-today-class', showWeekNumbers: false }"
                          autocomplete="off" (bsValueChange)="onDPEndDateChange($event)"
                          bsDatepicker id="dpEndDate" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="depart">Department</label>
                      <ng-select [allowClear]="true" [(active)]="ActiveDepartments" [(items)]="ddlDepartmentList"
                        (selected)="ddlSelection($event, 'Department')" placeholder="All Department"
                        #ddlFilterDepartments (opened)="closeDropdownList(ddlFilterDepartments)"
                        (removed)="removeFilterDropdownValue($event, 'Department')">
                      </ng-select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="location">Location</label>
                      <ng-select [allowClear]="true" [(active)]="ActiveLocations" [(items)]="ddlLocationList"
                        (selected)="ddlSelection($event, 'Location')" placeholder="All Location"
                        #ddlFilterLocations (opened)="closeDropdownList(ddlFilterLocations)"
                        (removed)="removeFilterDropdownValue($event, 'Location')">
                      </ng-select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="desigation" >Designation</label>
                      <ng-select [allowClear]="true" [(active)]="ActiveDesignations" [(items)]="ddlDesignationList"
                        (selected)="ddlSelection($event, 'Designation')" placeholder="All Designation"
                        #ddlFilterDesignations (opened)="closeDropdownList(ddlFilterDesignations)"
                        (removed)="removeFilterDropdownValue($event, 'Designation')">
                      </ng-select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="depart">User Status</label>
                      <select class="form-control" name="filterUserStatus" [(ngModel)]="filterUserStatus">
                        <option value=null>All</option>
                        <option value="0">Active</option>
                        <option value="1">Inactive</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">               
                  <div class="col-sm-12">
                    <button type="button" (click)="processFilters()" class="btn btn-primary btn-brand">Search</button>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <button type="button" (click)="resetFilters()" class="btn btn-default">Reset</button>
                  </div>                    
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</div>