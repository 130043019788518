import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  CurrentUser: any;
  constructor(private auth: AuthService, private myRoute: Router) {
    this.auth.getPermission();
    this.auth.currentUser.subscribe(x => this.CurrentUser = x);
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.auth.isLoggednIn()) {
      this.myRoute.navigate(["Login"], { queryParams: { returnUrl: state.url } });
      return false;
    }
    if (next.data && next.data['module']) {
      return true;
      // if (this.CurrentUser.permission.includes(next.data['module'] + '_URL_VISIBILITY')) {
      //   return true;
      // } else {
      //   this.myRoute.navigate(['home']);
      //   return false;
      // }
    }
    return true;
  }
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(route, state);
  }
}
