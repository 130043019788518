import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserReportComponent } from './user-report/user-report.component';
import { UserAvailabilityReportComponent } from './user-availability-report/user-availability-report.component';
import { DailyReportComponent } from './daily-report/daily-report.component';
import { LeaveTakenReportComponent } from './leave-taken-report/leave-taken-report.component';
import { LeaveBalanceReportComponent } from './leave-balance-report/leave-balance-report.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BoxModule } from 'angular-admin-lte';
import { SelectModule } from 'ng2-select';
import { CoreModule } from 'src/app/core/core.module';
import { TabsModule as MkTabsModule } from 'angular-admin-lte';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { TimepickerModule, BsDatepickerModule } from 'ngx-bootstrap';
import { HighchartsChartModule } from 'highcharts-angular';
import { GridAllModule, PagerModule } from '@syncfusion/ej2-angular-grids';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    NgbModule,DropDownListModule,
    BoxModule, SelectModule,
    MkTabsModule, DatePickerModule,
    BoxModule,
    CoreModule,
    SelectModule,
    GridAllModule,
    PagerModule,
    HighchartsChartModule,
    ReactiveFormsModule,
    NgbModule,
    AngularMultiSelectModule,
    TimepickerModule.forRoot(),
    BsDatepickerModule.forRoot(),

  ],
  declarations: [UserReportComponent, UserAvailabilityReportComponent, DailyReportComponent, LeaveTakenReportComponent, LeaveBalanceReportComponent]
})
export class LeaveReportModule { }

