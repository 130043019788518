import { Injectable, PipeTransform } from "@angular/core";
import { environment } from "src/app/environment";
import { map, pluck } from "rxjs/operators";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, BehaviorSubject, Subject, of } from "rxjs";
import { LeaveTypeModel } from "./leave-model/leave-type";
import { AuthService } from "src/app/services/auth.service";
import { param } from "jquery";

@Injectable({
  providedIn: "root"
})
export class LeaveService {
  constructor(private http: HttpClient, private authService: AuthService) {}

  getAllLeavetype(CmpCode): Observable<any> {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/Leave/GetAllLeaveTypesForSelect?&CmpCode="+CmpCode;
    return this.http.get(URL,{headers:headers})
    // .pipe(map((response: Response) => response));
  }

  GetEmployeeLocation(CmpCode, UserId): Observable<any> {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "Admin/GetEmployeeLocation?cmpcode="+CmpCode;
    return this.http.get(URL,{headers:headers})
    // .pipe(map((response: Response) => response));
  }

  getAvailableLeaveBalance(UserId, finanicialYearId,CmpCode){
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/Leave/GetLeaveBalance?UserId=" + UserId + "&FinanicialYearId=" + finanicialYearId +`&CmpCode=`+CmpCode;
    return this.http.get(URL,{headers:headers})
    .pipe(map((response ) => response))
  }
  
  getAllHolidayList(parms): Observable<any> {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/Holiday/GetAllHoliday";
    return this.http
      .post(URL, parms,{headers:headers})
      // .pipe(map((response: Response) => response));
  }
  getAllHolidayListLeaveDashboard(parms): Observable<any> {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "Holiday/GetAllHolidaydeshboard";
    return this.http
      .post(URL, parms,{headers:headers})
      // .pipe(map((response: Response) => response));
  }

  getAllLeave(parms) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/Leave/GetAllMyAppliedLeaves";
    return this.http
      .post(URL, parms,{headers:headers})
      // .pipe(map((response: Response) => response));
  }

  getcmpLeaveTpeCode(cmpCode) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "Admin/Getpreleavecustomcode?Cmpcode="+cmpCode;
    return this.http.get(URL,{headers:headers})
      // .pipe(map((response: Response) => response));
  }
  
  getAllCompLeave(parms) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/CompensatoryOff/GetAllCompOff";
    return this.http
      .post(URL, parms,{headers:headers})
      // .pipe(map((response: Response) => response));
  }

  approveLeave(parms) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/CompensatoryOff/CompOffApproval";
    return this.http
      .post(URL, parms,{headers:headers})
      // .pipe(map((response: Response) => response));
  }
  getAllRequestedCompOff(parms) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/Leave/GetAllRequestedCompOff";
    return this.http
      .post(URL, parms,{headers:headers})
      // .pipe(map((response: Response) => response));
  }

  getEmployeeDetails(UserId,CmpCode){
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/Admin/GetEmployeeDetailsByUserId?UserId=" + UserId +`&CmpCode=`+CmpCode;
    return this.http.get(URL,{headers:headers})
    // .pipe(map((response: Response) => response));
  }
  getcompDate(date,CmpCode){
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/CompensatoryOff/GetCompOffExpiredDate?cmpcode=" + CmpCode +`&selectdate=`+date;
    return this.http.get(URL,{headers:headers})
    // .pipe(map((response: Response) => response));
  }

  getEmployeesListForSelect(UserId,CmpCode) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + `/Admin/GetEmployeesListForSelect?CmpCode=`+CmpCode;
    return this.http.get(URL,{headers:headers})
    // .pipe(map((response: Response) => response));
  }

  getProjectsListForSelect(EmpId,CmpCode) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/CompensatoryOff/GetCompOffprojects?UserId=" + EmpId+`&CmpCode=`+CmpCode;
    return this.http.get(URL,{headers:headers})
    // .pipe(map((response: Response) => response));
  }

  getFinancialYear() {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/Admin/GetFinancialYearList";
    return this.http
      .get(URL,{headers:headers})
      // .pipe(map((response: Response) => response));
  }

  getCurrentFinancialYear(cmpCode) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/Admin/GetCurrentFinancialYear?cmpCode="+ cmpCode;
    return this.http
      .get(URL,{headers:headers})
      // .pipe(map((response: Response) => response));
  }

  getFinancialYearDetails(financialYearId, CmpCode) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/Admin/GetFinancialYearDetails?FinancialYearId=" + financialYearId+"&cmpCode="+CmpCode;
    return this.http
      .get(URL,{headers:headers})
      // .pipe(map((response: Response) => response));
  }

  applyLeave(parms): Observable<any> {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/Leave/ApplyLeave";
    return this.http
      .post(URL, parms,{headers:headers})
      // .pipe(map((response: Response) => response));
  }
  updateAppliedLeave(parms): Observable<any> { 
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');

    const URL = environment.apiUrl + "/Leave/UpdateAppliedLeave";
    return this.http
      .post(URL, parms,{headers:headers})
      // .pipe(map((response: Response) => response));
  }
  saveUploadedFiles(formData, leaveId, userId) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    try {
      const URL = environment.apiUrl + "/Leave/UploadLeaveApplyFiles?LeaveId=" + leaveId + "&UserId=" + userId;
      return this.http
        .post(URL, formData,{headers:headers})
        // .pipe(map((response: Response) => response));
    } catch (error) {
      throw error;
    }
  }
  getLeaveDates(parms): Observable<any> {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/Leave/GetLeaveDates";
    return this.http
      .post(URL, parms,{headers:headers})
      // .pipe(map((response: Response) => response));
  }
  getLeaveDetailsForEdit(LeaveId, cmpCode): Observable<any> {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/Leave/GetLeaveDetailsForEdit?LeaveId=" + LeaveId+ "&cmpCode="+ cmpCode ;
    return this.http.get(URL,{headers:headers})
    // .pipe(map((response: Response) => response));
  }

  getviewtimebalance(user, LeaveId,type, cmpCode, rqsdate): Observable<any> {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "Leave/getviewtimebalance?employeeid="+user+"&leavetypeid="+LeaveId+"&takenleave="+type+"&cmpcode="+cmpCode+"&requestdate="+rqsdate;
    return this.http.get(URL,{headers:headers})
    // .pipe(map((response: Response) => response));
  }
  cancelLeaveRequest(parms): Observable<any> {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/Leave/CancelLeaveRequest";
    return this.http
      .post(URL, parms,{headers:headers})
      // .pipe(map((response: Response) => response));
  }

  // End of Leaves

  applyCompLeave(parms): Observable<any> {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/CompensatoryOff/ApplyCompOff";
    return this.http
      .post(URL, parms,{headers:headers})
      // .pipe(map((response: Response) => response));
  }

  //Aquib --Leave Approval Services
  getAllRequestedLeaves(parms) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/Leave/GetAllRequestedLeaves";
    return this.http
      .post(URL, parms,{headers:headers})
      // .pipe(map((response: Response) => response));
  }
  //delete Leave
  DeleteLeave(formdata: any): Observable<any>{
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/Leave/DeleteLeave"; 
    return this.http
      .post(URL, formdata,{headers:headers})
 
  }
  getAllRequestedCompLeaves(parms) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/CompensatoryOff/GetAllRequestedCompOff";
    return this.http
      .post(URL, parms,{headers:headers})
      // .pipe(map((response: Response) => response));
  }
  getCompOffDetailsForEdit(CompOffId) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/CompensatoryOff/GetCompOffDetailsForEdit?CompOffId=" + CompOffId;
    return this.http.get(URL,{headers:headers})
    // .pipe(map((response: Response) => response));
  }
  cancelCompOffRequest(parms): Observable<any> {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/CompensatoryOff/CancelCompOffRequest";
    return this.http
      .post(URL, parms,{headers:headers})
      // .pipe(map((response: Response) => response));
  }

  getLeaveDetails(LeaveId,CmpCode) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/Leave/GetLeaveDetails?LeaveId=" + LeaveId+`&CmpCode=`+CmpCode;
    return this.http.get(URL,{headers:headers})
    // .pipe(map((response: Response) => response));
  }
  getLeaveApprovalDetails(LeaveId,CmpCode) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/Leave/GetLeaveApprovalDetails?LeaveId=" + LeaveId +`&CmpCode=`+CmpCode;
    return this.http.get(URL,{headers:headers})
    // .pipe(map((response: Response) => response));
  }
  getProjectDetails(type,typeid,approvalid,CmpCode,approvalgroup) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "Leave/Getapprovalprojects?type=" + type   +`&typeid=`+typeid +`&approvalid=`+approvalid+`&cmpcode=`+CmpCode+`&ApproverGroup=`+approvalgroup;
    return this.http.get(URL,{headers:headers})
    // .pipe(map((response: Response) => response));
  }
  getCompOffDetails(CompOffId,CmpCode) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/CompensatoryOff/GetCompOffDetails?CompOffId=" + CompOffId +`&CmpCode=`+CmpCode;
    return this.http.get(URL,{headers:headers})
    // .pipe(map((response: Response) => response));
  }
  getCompOffApprovalDetails(CompOffId,CmpCode) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/CompensatoryOff/GetCompOffApprovalDetails?CompOffId=" + CompOffId +`&CmpCode=`+CmpCode;
    return this.http.get(URL,{headers:headers})
    // .pipe(map((response: Response) => response));
  }
  
  leaveApproval(parms): Observable<any> {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/Leave/LeaveApproval";
    return this.http
      .post(URL, parms,{headers:headers})
      // .pipe(map((response: Response) => response));
  }
  leaveApprovalAll(parms): Observable<any> {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "Leave/AllLevelApproval";
    return this.http
      .post(URL, parms,{headers:headers})
      // .pipe(map((response: Response) => response));
  }
  leaveBulkApproval(parms): Observable<any> {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/Leave/LeaveBulkApproval";
    return this.http
      .post(URL, parms,{headers:headers})
      // .pipe(map((response: Response) => response));
  }
  getLeavesReport_Excel(parms) {
    //const URL = environment.apiUrl + "/Leave/GetAllRequestedLeaveExcelExport";
    //return this.http.get(URL).pipe(map((response: Response) => response));
    try {
      const headers = new HttpHeaders();
      let token = this.authService.getToken();
      headers.append('Authorization', `bearer ${token}`)
      headers.append('Content-Type', 'application/json');
      const URL = environment.apiUrl + "/Leave/GetAllLeaves_Export";
      return this.http
        .post(URL, parms,{headers:headers})
        // .pipe(map((response: Response) => response));
    } catch (error) {
      throw error;
    }
  }
  getCompOffReport_Excel(parms) {
    // const URL = environment.apiUrl + "/CompensatoryOff/GetAllRequestedCompOffExcelExport";
    // return this.http.get(URL).pipe(map((response: Response) => response));
    try {
      const headers = new HttpHeaders();
      let token = this.authService.getToken();
      headers.append('Authorization', `bearer ${token}`)
      headers.append('Content-Type', 'application/json');
      const URL = environment.apiUrl + "/Leave/GetAllCompOff_Export";
      return this.http
        .post(URL, parms,{headers:headers})
        // .pipe(map((response: Response) => response));
    } catch (error) {
      throw error;
    }
  }

  importLeaves(formData, isAutoApprove) {
    try {
      const headers = new HttpHeaders();
      let token = this.authService.getToken();
      headers.append('Authorization', `bearer ${token}`)
      headers.append('Content-Type', 'application/json');
      const URL = environment.apiUrl + "/Leave/ImportLeaves?IsAutoApprove=" + isAutoApprove;
      return this.http
        .post(URL, formData,{headers:headers})
        // .pipe(map((response: Response) => response));
    } catch (error) {
      throw error;
    }
  }
  importCompOffs(formData, isAutoApprove) {
    try {
      const headers = new HttpHeaders();
      let token = this.authService.getToken();
      headers.append('Authorization', `bearer ${token}`)
      headers.append('Content-Type', 'application/json');
      const URL = environment.apiUrl + "/Leave/ImportCompOffs?IsAutoApprove=" + isAutoApprove;
      return this.http
        .post(URL, formData,{headers:headers})
        // .pipe(map((response: Response) => response));
    } catch (error) {
      throw error;
    }
  }

  compOffApproval(parms): Observable<any> {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/CompensatoryOff/CompOffApproval";
    return this.http
      .post(URL, parms,{headers:headers})
      // .pipe(map((response: Response) => response));
  }
  compOffApprovalAll(parms): Observable<any> {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/CompensatoryOff/AlllevelCompOffApproval";
    return this.http
      .post(URL, parms,{headers:headers})
      // .pipe(map((response: Response) => response));
  }
  compOffBulkApproval(parms): Observable<any> {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/CompensatoryOff/CompOffBulkApproval";
    return this.http
      .post(URL, parms,{headers:headers})
      // .pipe(map((response: Response) => response));
  }

  // Leave and Compensatory Off related Services
  getAllMyLeavesCompOffRequests(parms) {
    try {
      const headers = new HttpHeaders();
      let token = this.authService.getToken();
      headers.append('Authorization', `bearer ${token}`)
      headers.append('Content-Type', 'application/json');
      const URL = environment.apiUrl + "/Leave/GetAllMyLeavesCompOffRequests";
      return this.http
        .post(URL, parms,{headers:headers})
        // .pipe(map((response: Response) => response));
    } catch (error) {
      throw error;
    }
  }
  getAllMyLeavesCompOffApprovals(parms) {
    try {
      const headers = new HttpHeaders();
      let token = this.authService.getToken();
      headers.append('Authorization', `bearer ${token}`)
      headers.append('Content-Type', 'application/json');
      const URL = environment.apiUrl + "/Leave/GetAllMyLeavesCompOffApprovals";
      return this.http
        .post(URL, parms,{headers:headers})
        // .pipe(map((response: Response) => response));
    } catch (error) {
      throw error;
    }
  }
  leaveCompOffBulkApproval(parms): Observable<any> {
    try {
      const headers = new HttpHeaders();
      let token = this.authService.getToken();
      headers.append('Authorization', `bearer ${token}`)
      headers.append('Content-Type', 'application/json');
      const URL = environment.apiUrl + "/Leave/LeaveCompOffBulkApproval";
      return this.http
      .post(URL, parms,{headers:headers})
      // .pipe(map((response: Response) => response));
    } catch (error) {
      throw error;
    }
  }

  // Comp Off end

  //-------------------------------------------------------------------------------------------------------------------
  //-------------------------------------------------------------------------------------------------------------------

  // Leave Type Services Start
  getAllLeaveTypeForSelect(CmpCode) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/Leave/GetAllLeaveTypesForSelect?CmpCode="+CmpCode+"";
    return this.http
      .get(URL,{headers:headers})
      // .pipe(map((response: Response) => response));
  }

  getAllLeaveTypeData(parms) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/Leave/GetAllLeaveTypes";
    return this.http
      .post(URL, parms,{headers:headers})
      // .pipe(map((response: Response) => response));
  }

  saveLeaveType(parms: LeaveTypeModel): Observable<any> {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/Leave/SaveLeaveType";
    return this.http
      .post(URL, parms,{headers:headers})
      // .pipe(map((response: Response) => response));
  }
  // postLeaveTypeImage(dataParms): Observable<any> {
  //   const URL = environment.apiUrl + "/Leave/PostLeaveTypeImage";
  //   return this.http
  //     .post(URL, dataParms)
      // .pipe(map((response: Response) => response));
  // }

  getLeaveTypeById(LeaveTypeId, cmpCode) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/Leave/GetLeaveTypeById?LeaveTypeId=" + LeaveTypeId +"&cmpCode="+ cmpCode;
    return this.http.get(URL,{headers:headers})
    // .pipe(map((response: Response) => response));
  }

  deleteLeaveType(parms, LeaveTypeId) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/Leave/DeleteLeaveType/?Id=" + LeaveTypeId;
    return this.http.post(URL, parms,{headers:headers})
    // .pipe(map((response: Response) => response));
  }

  getLeaveTypeProrateAccural(LeaveTypeId){
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/Leave/GetLeaveTypePolicyProrateAccural?LeaveTypeId=" + LeaveTypeId;
    return this.http.get(URL,{headers:headers})
    // .pipe(map((response: Response) => response));
  }
  SaveProrateAccural(parms): Observable<any> {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/Leave/SaveProrateAccural";
    return this.http
      .post(URL, parms,{headers:headers})
      // .pipe(map((response: Response) => response));
  }

  updateLeaveTypeStatus(LeaveTypeId, Status, cmpCode) {
    const headers = new HttpHeaders(); 
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/Leave/UpdateLeaveTypeStatus/?leaveTypeId=" + LeaveTypeId + "&status=" + Status+ "&CmpCode=" +cmpCode;
    return this.http.post(URL,{headers:headers})
    // .pipe(map((response: Response) => response));
  }

  // Leave Type services call End

  //-------------------------------------------------------------------------------------------------------------------
  //-------------------------------------------------------------------------------------------------------------------

  // customize leave/policy start
  getEmployeeLeavePolicy(EmployeeId, LeaveTypeId, data, CmpCode) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/EmployeeLeavePolicy/GetEmployeeLeavePolicy?EmployeeId=" + EmployeeId + "&LeaveTypeId="+ LeaveTypeId + "&CmpCode="+ CmpCode;
    return this.http.post(URL, data,{headers:headers})
    // .pipe(map((response: Response) => response));
  }

  saveLeavePolicy(parms) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/EmployeeLeavePolicy/SaveEmployeeLeavePolicy";
    return this.http.post(URL, parms,{headers:headers})
    // .pipe(map((response: Response) => response));
  }

  getEmployeeLeaveHistory(parms) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/EmployeeLeavePolicy/GetEmployeeLeavesHistory";
    return this.http.post(URL, parms,{headers:headers})
    // .pipe(map((response: Response) => response));
  }

  getAllEpmloyeeLeavePolicy(parms) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/EmployeeLeavePolicy/GetAllEmployeeLeavePolicy";
    return this.http.post(URL, parms,{headers:headers})
    // .pipe(map((response: Response) => response));
  }

  exportEmployeeLeaveHistory(parms) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/EmployeeLeavePolicy/ExportEmployeeLeavesHistory";
    return this.http.post(URL, parms,{headers:headers})
    // .pipe(map((response: Response) => response));
  }

  // customize leave/policy end

  //-------------------------------------------------------------------------------------------------------------------
  //-------------------------------------------------------------------------------------------------------------------

  // Customize leave balance  start

  getAllLeaveBalance(parms) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/LeaveBalance/GetAllLeaveBalance";
    return this.http.post(URL, parms,{headers:headers})
    // .pipe(map((response: Response) => response));
  }

  GetLeaveBalanceByEmployee(userId, parms,cmpcode) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/LeaveBalance/GetLeaveBalanceByEmployee?EmployeeId=" + userId+'&CmpCode='+cmpcode;
    return this.http.post(URL, parms,{headers:headers})
    // .pipe(map((response: Response) => response));
  }

  updateLeaveBalance(parms) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/LeaveBalance/UpdateLeaveBalance";
    return this.http.post(URL, parms,{headers:headers})
    // .pipe(map((response: Response) => response));
  }

  getAllRolesList(parms) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/Admin/GetAllRoles";
    return this.http.post(URL, parms,{headers:headers})
    // .pipe(map((response: Response) => response));
  }

  saveLeaveTypeImage(parms) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/Leave/PostLeaveTypeImage";
    return this.http.post(URL, parms,{headers:headers})
    // .pipe(map((response: Response) => response));
  }

  // Customize leave balance  End

  //-------------------------------------------------------------------------------------------------------------------
  //-------------------------------------------------------------------------------------------------------------------

  // General Settings
  getGeneralSettings(Cmpcode) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/GeneralSettings/GetGeneralSettings?Cmpcode="+Cmpcode;
    return this.http.get(URL,{headers:headers})
    // .pipe(map((response: Response) => response));
  }
  saveGeneralSettings(dataParams) {
    try {
      const headers = new HttpHeaders();
      let token = this.authService.getToken();
      headers.append('Authorization', `bearer ${token}`)
      headers.append('Content-Type', 'application/json');
      const URL = environment.apiUrl + "/GeneralSettings/SaveGeneralSettings";
      return this.http
      .post(URL, dataParams,{headers:headers})
      // .pipe(map((response: Response) => response));
    } catch (error) {
      throw error;
    }
  }
  // End of General Settings


  // User Report
  getUserReport(employeeId, filterUnit,CmpCode) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/LeaveReports/GetUserReport?EmployeeId=" + employeeId + "&filterUnit=" + filterUnit+"&CmpCode="+CmpCode;
    return this.http.post(URL,{headers:headers})
    // .pipe(map((response: Response) => response));
  }
  // End of User Report

  // User Availability Report
  getUserAvailabilityReport(params) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/LeaveReports/GetUserAvailabilityReport";
    return this.http.post(URL, params,{headers:headers})
    // .pipe(map((response: Response) => response));
  }
  getUserAvailabilityReport_Excel(params) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/LeaveReports/GetUserAvailabilityReport_Excel";
    return this.http.post(URL, params,{headers:headers})
    // .pipe(map((response: Response) => response));
  }
  // End of User Availability Report

  // Daily Report
  getDailyReport(params) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/LeaveReports/GetDailyReport";
    return this.http.post(URL, params,{headers:headers})
    // .pipe(map((response: Response) => response));
  }
  getDailyReport_Excel(params) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/LeaveReports/GetDailyReport_Excel";
    return this.http.post(URL, params,{headers:headers})
    // .pipe(map((response: Response) => response));
  }
  // End of Daily

  // Leave Balance Report
  getLeaveBalanceReport(params) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/LeaveReports/GetLeaveBalanceReport";
    return this.http.post(URL, params,{headers:headers})
    // .pipe(map((response: Response) => response));
  }
  getLeaveBalanceReport_Excel(params) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/LeaveReports/GetLeaveBalanceReport_Excel";
    return this.http.post(URL, params,{headers:headers})
    // .pipe(map((response: Response) => response));
  }
  // End of Leave Balance Report

  // Leave Taken Report
  getLeaveTakenReport(params) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/LeaveReports/GetLeaveTakenReport";
    return this.http.post(URL, params,{headers:headers})
    // .pipe(map((response: Response) => response));
  }
  getLeaveTakenReport_Excel(params) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/LeaveReports/GetLeaveTakenReport_Excel";
    return this.http.post(URL, params,{headers:headers})
    // .pipe(map((response: Response) => response));
  }
  // End of Leave Taken Report


  // Approval Settings
  getAllApprovalSettings(dataParams) {
    try {
      const headers = new HttpHeaders();
      let token = this.authService.getToken();
      headers.append('Authorization', `bearer ${token}`)
      headers.append('Content-Type', 'application/json');
      const URL = environment.apiUrl + "/ApprovalSettings/GetAllApprovalSettings";
      return this.http
      .post(URL, dataParams,{headers:headers})
      // .pipe(map((response: Response) => response));
    } catch (error) {
      throw error;
    }
  }
  saveAttachmentFiles(formData) {
    try {
      const headers = new HttpHeaders();
      let token = this.authService.getToken();
      headers.append('Authorization', `bearer ${token}`)
      headers.append('Content-Type', 'application/json');
      const URL = environment.apiUrl + "/ApprovalSettings/UploadAttachmentFiles";
      return this.http
        .post(URL, formData,{headers:headers})
        // .pipe(map((response: Response) => response));
    } catch (error) {
      throw error;
    }
  }
  saveApprovalSettings(dataParams) {
    try {
      const headers = new HttpHeaders();
      let token = this.authService.getToken();
      headers.append('Authorization', `bearer ${token}`)
      headers.append('Content-Type', 'application/json');
      const URL = environment.apiUrl + "/ApprovalSettings/SaveApprovalSettings";
      return this.http
      .post(URL, dataParams,{headers:headers})
      // .pipe(map((response: Response) => response));
    } catch (error) {
      throw error;
    }
  }
  getApprovalSettingsDetails(approvalId,CmpCode) {
    try {
      const headers = new HttpHeaders();
      let token = this.authService.getToken();
      headers.append('Authorization', `bearer ${token}`)
      headers.append('Content-Type', 'application/json');
      const URL = environment.apiUrl + "/ApprovalSettings/GetApprovalSettingsDetails?Id=" + approvalId +"&CmpCode="+CmpCode;
      return this.http
      .post(URL,{headers:headers})
      // .pipe(map((response: Response) => response));
    } catch (error) {
      throw error;
    }
  }
  updateApprovalSettingsStatus(approvalId, status,CmpCode) {
    try {
      const headers = new HttpHeaders();
      let token = this.authService.getToken();
      headers.append('Authorization', `bearer ${token}`)
      headers.append('Content-Type', 'application/json');
      const URL = environment.apiUrl + "/ApprovalSettings/UpdateApprovalSettingStatus?approvalId=" + approvalId + "&status=" + status +"&CmpCode="+CmpCode;
      return this.http
      .post(URL,{headers:headers})
      // .pipe(map((response: Response) => response));
    } catch (error) {
      throw error;
    }
  }
  deleteApprovalSettings(parms, approvalId) {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/ApprovalSettings/DeleteApprovalSettings/?Id=" + approvalId;
    return this.http.post(URL, parms,{headers:headers})
    // .pipe(map((response: Response) => response));
  }
  // End of Approval Settings

  // Get PDF from base64
  // getReportPDFFromBase64(params) {
  //   const URL = environment.apiUrl + "/LeaveReports/GetReportPDFFromBase64";
  //   return this.http.post(URL, params).pipe(map((response: Response) => response));
  // }
  getReportPDFFromBase64(params) {
    let token = this.authService.getToken();
    const URL = environment.apiUrl + "/LeaveReports/GetReportPDFFromBase64";
    const httpOptions = {
      responseType: 'blob' as 'json',
      Authorization:`bearer ${token}`
    };
    return this.http.post(URL, params, httpOptions);
      // .pipe(map((response: Response) => response));
  }
  // End of PDF from base64

  // For Drop Down List
  getJobBandsForSelect() {
    try {
      const headers = new HttpHeaders();
      let token = this.authService.getToken();
      headers.append('Authorization', `bearer ${token}`)
      headers.append('Content-Type', 'application/json');
      const URL = environment.apiUrl + '/Admin/GetAllJobBandsForSelect';
      return this.http.get(URL,{headers:headers})
      // .pipe(map((response: Response) => response));
    } catch (error) {
      throw error;
    }
  }
  getRolesForSelect(cmpcode) {
    try {
      const headers = new HttpHeaders();
      let token = this.authService.getToken();
      headers.append('Authorization', `bearer ${token}`)
      headers.append('Content-Type', 'application/json');
      const URL = environment.apiUrl + '/Admin/GetAllRolesForSelect?Cmpcode='+cmpcode;
      return this.http.get(URL,{headers:headers})
      // .pipe(map((response: Response) => response));
    } catch (error) {
      throw error;
    }
  }
  getGroupListForSelect(parms) {
    try {
      const headers = new HttpHeaders();
      let token = this.authService.getToken();
      headers.append('Authorization', `bearer ${token}`)
      headers.append('Content-Type', 'application/json');
      const URL = environment.apiUrl + "/Admin/GetAllGroups";
      return this.http.post(URL, parms,{headers:headers})
      // .pipe(map((response: Response) => response));
    } catch (error) {
      throw error;
    }
  }
  getEmailTemplatesForSelect(parms) {
    try {
      const headers = new HttpHeaders();
      let token = this.authService.getToken();
      headers.append('Authorization', `bearer ${token}`)
      headers.append('Content-Type', 'application/json');
      const URL = environment.apiUrl + '/EmailTemplate/GetEmailTemplatesForSelect';
      return this.http.post(URL, parms,{headers:headers})
      // .pipe(map((response: Response) => response));
    } catch (error) {
      throw error;
    }
  }
  // End of Drop Down List


  //#region Calendar Sync

  getUserCalendarSyncAuthorized(_userId) {
    try {
      const headers = new HttpHeaders();
      let token = this.authService.getToken();
      headers.append('Authorization', `bearer ${token}`)
      headers.append('Content-Type', 'application/json');
      const URL = environment.apiUrl + "/CalendarSync/GetUserCalendarSyncAuthorized?UserId=" + _userId;
      return this.http
      .post(URL,{headers:headers})
      // .pipe(map((response: Response) => response));
    } catch (error) {
      throw error;
    }
  }
  saveGoogleAuthorized(dataParams) {
    try {
      const headers = new HttpHeaders();
      let token = this.authService.getToken();
      headers.append('Authorization', `bearer ${token}`)
      headers.append('Content-Type', 'application/json');
      const URL = environment.apiUrl + "/CalendarSync/SaveGoogleAuthorized";
      return this.http
        .post(URL, dataParams,{headers:headers})
        // .pipe(map((response: Response) => response));
    } catch (error) {
      throw error;
    }
  }
  revokeGoogleAuthorized(_userId) {
    try {
      const headers = new HttpHeaders();
      let token = this.authService.getToken();
      headers.append('Authorization', `bearer ${token}`)
      headers.append('Content-Type', 'application/json');
      const URL = environment.apiUrl + "/CalendarSync/RevokeGoogleAuthorized?UserId=" + _userId;
      return this.http
      .post(URL,{headers:headers})
      // .pipe(map((response: Response) => response));
    } catch (error) {
      throw error;
    }
  }
  syncGoogleCalendar(dataParams) {
    try {
      const headers = new HttpHeaders();
      let token = this.authService.getToken();
      headers.append('Authorization', `bearer ${token}`)
      headers.append('Content-Type', 'application/json');
      const URL = environment.apiUrl + "/CalendarSync/SyncWithGoogleCalendar";
      return this.http
        .post(URL, dataParams,{headers:headers})
        // .pipe(map((response: Response) => response));
    } catch (error) {
      throw error;
    }
  }

  //#endregion
}
