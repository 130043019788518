import { Component, OnInit, ViewChildren,ViewChild, QueryList, } from '@angular/core';
import { LeaveService } from "../../leave.service";
import { CommonService } from "src/app/common.service";
import { AuthService } from "src/app/services/auth.service";
import { BsDatepickerConfig } from "ngx-bootstrap";
import { DepartmentService } from "src/app/+layout/department/department.service";
import { DesignationService } from "src/app/+layout/designation/designation.service";
import { LocationService } from "src/app/+layout/location/location.service";
import { SelectComponent } from "ng2-select/ng2-select";
import { GridComponent,ExcelExportProperties, Column } from "@syncfusion/ej2-angular-grids";
import { FilterSettingsModel, IFilter } from '@syncfusion/ej2-angular-grids';
import { environment } from 'src/app/environment';


@Component({
  selector: 'app-leave-taken-report',
  templateUrl: './leave-taken-report.component.html',
  styleUrls: ['./leave-taken-report.component.css']
})
export class LeaveTakenReportComponent implements OnInit {
  datePickerConfiq: Partial<BsDatepickerConfig>;
  @ViewChild('overviewgrid') public Grid: GridComponent;
  CmpCode:any;
  userLoader: boolean;
  currentUserId: any;

  filterDayHour: any;
  filterCurrentView: any;

  flterShowData = false;

  filterPeriod: number;

  filterStartDate: Date;
  filterEndDate: Date;
  minDate: any;
  maxDate: any;

  filterType: number;

  // For  Dropdownlist
  filterEmployeeId: any;
  EmployeeList: any[];
  ddlEmployeeList: any[];
  ActiveEmployees = [];

  filterLeaveTypeId: any;
  LeaveTypesList: any[];
  ddlLeaveTypeList: any[];
  ActiveLeaveTypes = [];

  filterDepartmentId: any;
  DepartmentList: any[];
  ddlDepartmentList: any[];
  ActiveDepartments = [];

  filterDesignationId: any;
  DesignationList: any[];
  ddlDesignationList: any[];
  ActiveDesignations = [];

  filterLocationId: any;
  LocationList: any[];
  ddlLocationList: any[];
  ActiveLocations = [];

  filterUserStatus: number = null;

  reportList: any[];

  @ViewChildren(SelectComponent) selectElements: QueryList<any>;

  constructor(private _leaveService: LeaveService,
    private _commonSer: CommonService,
    private _authSer: AuthService,
    private _depratmentSer: DepartmentService,
    private _desigation: DesignationService,
    private _locationSer: LocationService) { this.CmpCode = this._authSer.getcompanyCode();}
    // public filterOptions: FilterSettingsModel;
public filter: IFilter;
  ngOnInit() {
   
   this.filterSettings = { type: "CheckBox" };
  //  this.filter = { type: "CheckBox" };
//   this.filterOptions = {
//     type: 'Menu'
// };
// this.filter = {
//     type: 'CheckBox'
// };
    this.CmpCode = this._authSer.getcompanyCode();
    this.reportList = [];

    this._authSer.currentUser.subscribe((res) => {
      this.currentUserId = res.userId;
    });

    this.filterDayHour = 1;
    this.filterCurrentView = 1;

    this.filterPeriod = 6;
    let currentDate = new Date();
    this.filterStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    this.filterEndDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

    this.minDate = this.filterStartDate;
    this.minDate.setDate(this.minDate.getDate());

    this.maxDate = this.filterEndDate;
    this.maxDate.setDate(this.maxDate.getDate());

    this.filterType = -1;
    this.checkFilter=1
    this.getLeaveTakenReport();
  }
  
  public pageSettings = { pageCount: 3 };

  
  filterSettings:object
  getCSharpFormattedDate(val: any) {
    if (val === null || val === "") {
      return null;
    }
    const date = new Date(val);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return month + "-" + day + "-" + year;
  }
    checkFilter:any
  setFilterDayHour(value) {
    this.filterDayHour = value;
    if(value===1)
    {
      this.checkFilter=1
      this.getLeaveTakenReport();

    }
    else
    {
      this.checkFilter=2

      this.getLeaveTakenReportFor();

    }
  }
  

  load() {
    const rowHeight: number = this.Grid.getRowHeight();  // height of the each row
    const gridHeight: any = this.Grid.height;  // grid height
    const pageSize: number = this.Grid.pageSettings.pageSize;   // initial page size
    const pageResize: any = (gridHeight - (pageSize * rowHeight)) / rowHeight; // new page size is obtained here
    this.Grid.pageSettings.pageSize = pageSize + Math.round(pageResize);
  }

  filterShow() {
    this.getLeaveTypeList();
    this.getEmployeeList();
    this.getDepartmentList();
    this.getAllDesigationList();
    this.getAllLocationList();
    this.flterShowData = !this.flterShowData;
  }
  closeFilter() {
    this.flterShowData = false;
  }

  ddlSelection(value: any, placeHolder: any) {
    try {
      switch (placeHolder) {
        case 'Employee':
          this.ActiveEmployees = [];
          this.ActiveEmployees.push(value);
          this.filterEmployeeId = value.id;
          break;
        case 'LeaveType':
          this.ActiveLeaveTypes = [];
          this.ActiveLeaveTypes.push(value);
          this.filterLeaveTypeId = value.id;
          break;
        case "Department":
          this.ActiveDepartments = [];
          this.ActiveDepartments.push(value);
          this.filterDepartmentId = value.id;
          break;
        case "Desigation":
          this.ActiveDesignations = [];
          this.ActiveDesignations.push(value);
          this.filterDesignationId = value.id;
          break;
        case "Location":
          this.ActiveLocations = [];
          this.ActiveLocations.push(value);
          this.filterLocationId = value.id;
          break;
      }
    } catch (ex) {

    }
  }
  removeFilterDropdownValue(value, _type) {
    try {
      switch (_type) {
        case "Employee":
          this.ActiveEmployees = [];
          this.filterEmployeeId = null;
          break;
        case 'LeaveType':
          this.ActiveLeaveTypes = [];
          this.filterLeaveTypeId = null;
          break;
        case "Department":
          this.ActiveDepartments = [];
          this.filterDepartmentId = null;
          break;
        case "Desigation":
          this.ActiveDesignations = [];
          this.filterDesignationId = null;
          break;
        case "Location":
          this.ActiveLocations = [];
          this.filterLocationId = null;
          break;
      }
    } catch (ex) {
      console.log(ex);
    }
  }

  getEmployeeList() {
    this._leaveService.getEmployeesListForSelect(this.currentUserId,this.CmpCode).subscribe((res) => {
      this.EmployeeList = res['data'].table;
      this.ddlEmployeeList = this._commonSer.SetDDlDropdown(res["data"].table, 'employeeId', 'employeeCodeName');
    }, error => {
      this.userLoader = false;
      console.log(error);
    });
  }

  getLeaveTypeList() {
    this._leaveService.getAllLeaveTypeForSelect(this.CmpCode).subscribe((res) => {
      this.LeaveTypesList = res["data"].table;
      this.ddlLeaveTypeList = this._commonSer.SetDDlDropdown(res["data"].table, "leaveTypeId", "leaveTypeName");
    }, error => {
      this.userLoader = false;
      console.log(error);
    });
  }

  getDepartmentList() {
    let parms = {
      Condition: "",
      FieldName: "CreatedDate",
      NoOfRecords: 10000,
      PageNo: 1,
      SortType: "desc",
      CmpCode:this.CmpCode
    };
    this._depratmentSer.getDepartmentList(parms).subscribe((res) => {
      this.DepartmentList = res["data"].table;
      this.ddlDepartmentList = this._commonSer.SetDDlDropdown(res["data"].table, "departmentId", "departmentName");
    }, error => {
      this.userLoader = false;
      console.log(error);
    });
  }

  getAllDesigationList() {
    let parms = {
      Condition: "",
      FieldName: "CreatedDate",
      NoOfRecords: 10000,
      PageNo: 1,
      SortType: "desc",
      CmpCode:this.CmpCode
    };
    this._desigation.getDesignationList(parms).subscribe((res) => {
      this.DesignationList = res["data"].table;
      this.ddlDesignationList = this._commonSer.SetDDlDropdown(res["data"].table, "designationId", "designationName");
    }, error => {
      this.userLoader = false;
      console.log(error);
    });
  }

  getAllLocationList() {
    let parms = {
      Condition: "",
      FieldName: "CreatedDate",
      NoOfRecords: 10000,
      PageNo: 1,
      SortType: "desc",
      CmpCode:this.CmpCode
    };
    this._locationSer.getlocationlist(parms).subscribe((res) => {
      this.LocationList = res["data"].table;
      this.ddlLocationList = this._commonSer.SetDDlDropdown(res["data"].table, "locationId", "locationName");
    }, error => {
      this.userLoader = false;
      console.log(error);
    });
  }

  processFilters() {
    this.flterShowData = false;
    this.getLeaveTakenReport();
  }

  resetFilters() {
    this.ActiveEmployees = [];
    this.filterEmployeeId = null;
    this.ActiveLeaveTypes = [];
    this.filterLeaveTypeId = null;
    this.ActiveDepartments = [];
    this.filterDepartmentId = null;
    this.ActiveDesignations = [];
    this.filterDesignationId = null;
    this.ActiveLocations = [];
    this.filterLocationId = null;

    this.filterPeriod = 6;
    let currentDate = new Date();
    this.filterStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    this.filterEndDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    this.filterType = -1;
  }

  onChangeFilterPeriod(e) {
    let _period = e.srcElement.value;
    let currentDate = new Date();

    if (_period == 1) { // Yesterday
      currentDate.setDate(currentDate.getDate() - 1);
      this.filterStartDate = currentDate;
      this.filterEndDate = currentDate;
    } else if (_period == 2) { // Today
      this.filterStartDate = currentDate;
      this.filterEndDate = currentDate;
    } else if (_period == 3) { // Last Week
      let first = (currentDate.getDate() - currentDate.getDay()) - 7;
      let last = first + 6;

      this.filterStartDate = new Date(currentDate.setDate(first));
      this.filterEndDate = new Date(currentDate.setDate(last));
    } else if (_period == 4) { // This Week
      let first = currentDate.getDate() - currentDate.getDay();
      let last = first + 6;

      this.filterStartDate = new Date(currentDate.setDate(first));
      this.filterEndDate = new Date(currentDate.setDate(last));
    } else if (_period == 5) { // Last Month
      currentDate.setMonth(currentDate.getMonth() - 1);
      this.filterStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      this.filterEndDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    } else if (_period == 6) { // This Month
      this.filterStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      this.filterEndDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    }
  }

  onChangeFilterType(e) {
    console.clear();
    console.log(e);
    console.log(this.filterType);
  }

  // Close Opened Dropdowns when clicking on new dropdown
  closeDropdownList(element) {
    if (element.optionsOpened == true) {
      let elementsToclose = this.selectElements.filter(function (el: any) {
        return (el != element && el.optionsOpened == true);
      });
      elementsToclose.forEach(function (e: SelectComponent) {
        e.clickedOutside();
      });
    }
  }

  onDPStartDateChange(e) {
    this.filterStartDate = e;
    this.minDate = new Date(this.filterStartDate);
    this.minDate.setDate(this.minDate.getDate());
  }
  onDPEndDateChange(e) {
    this.filterEndDate = e;
    this.maxDate = new Date(this.filterEndDate);
    this.maxDate.setDate(this.maxDate.getDate());
  }
  Load()
  {

  }

  // Get Report
  employeeName:any
  getLeaveAllBalanceData:any=[]
  myRequestsListLength:any
  columnArray:any=[]
  paidArray:any=[]
  unPaidArray:any=[]
  paidListCount:any=[]
  unpaidListCount:any=[]
  onDutyListCount:any=[]
  onDutyList:any=[]
  newDutyList:any=[]
  newTotalArray:any=[]
  ejsGridData:any=[]
  newArrayList:any=[]
  userId:any
  inActive:any
  getLeaveTakenReport() {
    this.userLoader = true;
    let parms = {
      Period: this.filterPeriod,
      StartDate: this.getCSharpFormattedDate(this.filterStartDate),
      EndDate: this.getCSharpFormattedDate(this.filterEndDate),
      filterUnit: this.filterDayHour,
      FilterLeaveType: this.filterLeaveTypeId !== undefined ? this.filterLeaveTypeId : null,
      FilterEmployee: this.filterEmployeeId !== undefined ? this.filterEmployeeId : null,
      FilterDepartment: this.filterDepartmentId !== undefined ? this.filterDepartmentId : null,
      FilterDesignation: this.filterDesignationId !== undefined ? this.filterDesignationId : null,
      FilterLocation: this.filterLocationId !== undefined ? this.filterLocationId : null,
      FilterUserStatus: this.filterUserStatus !== undefined ? this.filterUserStatus : null,
      UserId: this.currentUserId,
      CmpCode:this.CmpCode
    };
    this._leaveService.getLeaveTakenReport(parms).subscribe((res) => {
               document.getElementById('saveClick').click()
      this.columnArray=[]
      this.ejsGridData=[]
      this.userId='userid'
      this.reportList = res["data"];
      this.employeeName='Employee Name'
      this.inActive="inActive"
      
    this.getLeaveAllBalanceData=[]
    this.getLeaveAllBalanceData = res['data'];
    this.myRequestsListLength = this.getLeaveAllBalanceData.length;
    this.columnArray.push(this.employeeName)
    this.paidArray=this.getLeaveAllBalanceData[0].paidLeavesList
    this.unPaidArray=this.getLeaveAllBalanceData[0].unpaidLeavesList
    this.paidListCount = this.getLeaveAllBalanceData[0].paidLeavesList.length;
    this.unpaidListCount = this.getLeaveAllBalanceData[0].unpaidLeavesList.length;
    this.onDutyListCount = this.getLeaveAllBalanceData[0].onDutyLeavesList.length;
    this.onDutyList=this.getLeaveAllBalanceData[0].onDutyLeavesList
    this.newDutyList=this.onDutyList.concat(this.unPaidArray)
    this.newTotalArray=this.paidArray.concat(this.newDutyList)
    var totalCount= this.paidListCount+this.unpaidListCount
      for(var i=0;i< this.newTotalArray.length;i++)
      {
        this.columnArray.push(this.newTotalArray[i].leaveTypeName)
      }
         this.columnArray.push(this.inActive)
         this.columnArray.push(this.userId)
   /// this.columnArray.push(this.inActive)

       this.unpaidListCount = this.getLeaveAllBalanceData[0].unpaidLeavesList.length;

  
      for(var i=0;i< this.getLeaveAllBalanceData.length;i++)
      {
        for(var j=0;j<this.columnArray.length;j++)
      {
        var key=this.columnArray[j]
        if(j==0)
        {
          var data=this.getLeaveAllBalanceData[i].employeeName
          if(data=="Katy Watson ")
          {
            debugger
          }
        }
      //  if(j==1)
      //  {
      //   var data=this.getLeaveAllBalanceData[i].userId
      //  }
        else if(j>0 && j<=this.paidListCount){
          var data=this.getLeaveAllBalanceData[i].paidLeavesList[j-1].leaveCount
        }
        else if(j>this.paidListCount&&j<=totalCount) {
          var count=this.paidListCount+1
          var data=this.getLeaveAllBalanceData[i].unpaidLeavesList[j-count].leaveCount
        }
       
        else {
          // var count=totalCount+1
          // if(j==this.columnArray.length)
          // {
          //   var data=this.getLeaveAllBalanceData[i].userId

          // }
          // else if(this.getLeaveAllBalanceData[i].onDutyLeavesList>0)
          // {
          //   var data=this.getLeaveAllBalanceData[i].onDutyLeavesList[j-count].leaveCount

          // }
          var count=totalCount+1
          if(j==this.columnArray.length-2)
          {
            if(this.getLeaveAllBalanceData[i].inActive==true)
            {
              data='InActive'
            }
            else
            {
              data='Active'
            }
          }
          else if(j==this.columnArray.length-1)
          {
            if(this.getLeaveAllBalanceData[i].userId)
            {
              data=this.getLeaveAllBalanceData[i].userId
            }

          }
          else 
          {
            if(this.getLeaveAllBalanceData[i].onDutyLeavesList>0)
            {
              var data=this.getLeaveAllBalanceData[i].onDutyLeavesList[j-count].leaveCount

            }
  
          }
      
          // var count=totalCount+1
          // if(j==this.columnArray.length-1)
          // {
          

          //   if(this.getLeaveAllBalanceData[i].inActive==true)
          //   {
          //     data='InActive'
          //   }
          //   else
          //   {
          //     data='Active'
          //   }
          //   if(this.getLeaveAllBalanceData[i].userId)
          //   {
          //     data=this.getLeaveAllBalanceData[i].userId
          //   }
  
          // }
          // else if(this.getLeaveAllBalanceData[i].onDutyLeavesList>0)
          // {
          //   var data=this.getLeaveAllBalanceData[i].onDutyLeavesList[j-count].leaveCount
  
          // }
        }
         this.ejsGridData.push({[key]:data})
        }
      }
    

   
      var obj = {};
      this.newArrayList=[]

          for(var j=0;j<this.ejsGridData.length;j++)
      { 
         obj={}
        for(var k=0;k<=this.newTotalArray.length+2;k++)
        {
          obj[k]=this.ejsGridData[j+k]
      
        }
        var d=this.collect(obj)

          this.newArrayList.push(d)
          j=j+this.newTotalArray.length+2
      }
      console.log(this.newArrayList,'this.newArray')
     // this.Grid.refresh()
      
      
      this.userLoader = false;
    }, error => {
      this.userLoader = false;
      console.log(error);
    });
  }
  actionComplete(args: any) {
   

    // for (const cols of this.Grid.columns) {
    //   var data=this.columnArray.length-1
          
    //           if ((cols as Column).field === data+'.inActive') {
    //   debugger;
  
    //               (cols as Column).visible = false;
    //       this.Grid.refresh()

    //           } 
    //           else{
    //             (cols as Column).visible = false;
    //           }
    //       }
  
}
  newArray:any=[]
 userid:any
  getLeaveTakenReportFor() {
    debugger;
    this.userLoader = true;
    let parms = {
      Period: this.filterPeriod,
      StartDate: this.getCSharpFormattedDate(this.filterStartDate),
      EndDate: this.getCSharpFormattedDate(this.filterEndDate),
      filterUnit: this.filterDayHour,
      FilterLeaveType: this.filterLeaveTypeId !== undefined ? this.filterLeaveTypeId : null,
      FilterEmployee: this.filterEmployeeId !== undefined ? this.filterEmployeeId : null,
      FilterDepartment: this.filterDepartmentId !== undefined ? this.filterDepartmentId : null,
      FilterDesignation: this.filterDesignationId !== undefined ? this.filterDesignationId : null,
      FilterLocation: this.filterLocationId !== undefined ? this.filterLocationId : null,
      FilterUserStatus: this.filterUserStatus !== undefined ? this.filterUserStatus : null,
      UserId: this.currentUserId,
      CmpCode:this.CmpCode
    };
    this._leaveService.getLeaveTakenReport(parms).subscribe((res) => {

      this.columnArray=[]
      this.ejsGridData=[]
      this.reportList = res["data"];
      this.employeeName='Employee Name'
      this.userId='userid'
      this.inActive="inActive"



    this.getLeaveAllBalanceData=[]
    this.getLeaveAllBalanceData = res['data'];
    this.myRequestsListLength = this.getLeaveAllBalanceData.length;
    this.columnArray.push(this.employeeName)
    this.paidArray=this.getLeaveAllBalanceData[0].paidLeavesList
    this.unPaidArray=this.getLeaveAllBalanceData[0].unpaidLeavesList
    this.paidListCount = this.getLeaveAllBalanceData[0].paidLeavesList.length;
    this.unpaidListCount = this.getLeaveAllBalanceData[0].unpaidLeavesList.length;
    this.onDutyListCount = this.getLeaveAllBalanceData[0].onDutyLeavesList.length;
    this.onDutyList=this.getLeaveAllBalanceData[0].onDutyLeavesList
    this.newDutyList=this.onDutyList.concat(this.unPaidArray)
    this.newTotalArray=this.paidArray.concat(this.newDutyList)
    var totalCount= this.paidListCount+this.unpaidListCount
      for(var i=0;i< this.newTotalArray.length;i++)
      {
        this.columnArray.push(this.newTotalArray[i].leaveTypeName)
      }
      this.columnArray.push(this.inActive)
      this.columnArray.push(this.userId)

     
     
      this.unpaidListCount = this.getLeaveAllBalanceData[0].unpaidLeavesList.length; 

  
      for(var i=0;i< this.getLeaveAllBalanceData.length;i++)
      {
        for(var j=0;j<this.columnArray.length;j++)
      {
        var key=this.columnArray[j]
        if(j==0)
        {
          var data=this.getLeaveAllBalanceData[i].employeeName
          if(data=="Katy Watson ")
          {
          }
        }
        else if(j>0 && j<=this.paidListCount){
          var data=this.getLeaveAllBalanceData[i].paidLeavesList[j-1].leaveCount
        }
        else if(j>this.paidListCount&&j<=totalCount) {
          var count=this.paidListCount+1
          var data=this.getLeaveAllBalanceData[i].unpaidLeavesList[j-count].leaveCount
        }
        else
        {
          var count=totalCount+1
          if(j==this.columnArray.length-2)
          {
            if(this.getLeaveAllBalanceData[i].inActive==true)
            {
              data='InActive'
            }
            else
            {
              data='Active'
            }
          }
          else if(j==this.columnArray.length-1)
          {
            if(this.getLeaveAllBalanceData[i].userId)
            {
              data=this.getLeaveAllBalanceData[i].userId
            }

          }
          else 
          {
            if(this.getLeaveAllBalanceData[i].onDutyLeavesList>0)
            {
              var data=this.getLeaveAllBalanceData[i].onDutyLeavesList[j-count].leaveCount

            }
  
          }
        }
         this.ejsGridData.push({[key]:data})
        }
      }
      

   
      var obj = {};
      this.newArray=[]

          for(var j=0;j<this.ejsGridData.length;j++)
      { 
         obj={}
        for(var k=0;k<=this.newTotalArray.length+2;k++)
        {
          obj[k]=this.ejsGridData[j+k]
      
        }
        var d=this.collect(obj)

          this.newArray.push(d)
          j=j+this.newTotalArray.length+2
      }
      console.log(this.newArray,'this.newArray')
     // this.Grid.refresh()
      
      
      this.userLoader = false;
    }, error => {
      this.userLoader = false;
      console.log(error);
    });
  }
  recieptIMg:any
  colCell:any=0
  customiseCell(args: any) {
     var col=args.column.field
     var  clName=col.split('.')
    const column = this.Grid.getColumnByField(args.column.field); 
      column.headerText = clName[1]; 
      if (args.data[this.columnArray.length-2]['inActive']=='InActive') { 
        debugger;
        args.cell.classList.add('background-yellow');
      //args.cell.classList.add('below-30'); 

    }
    
   if (args.column.field =="0.Employee Name") {
        args.cell.classList.add('below-0');
        for(var i=0;i<this.getLeaveAllBalanceData.length;i++)
        {
          if(args.data[this.columnArray.length-1]["userid"]==this.getLeaveAllBalanceData[i].userId)
          {
          // for(var k=0;k<this.getLeaveAllBalanceData.length;k++)
          // {
          //  this.getLeaveAllBalanceData[k].userid
          // }
       //  if(this.colCell==i){
            if (this.getLeaveAllBalanceData[i].userImage == '' || this.getLeaveAllBalanceData[i].userImage == undefined || this.getLeaveAllBalanceData[i].userImage == null) {
              if (this.getLeaveAllBalanceData[i].genders == "Female") {
                this.recieptIMg =  "/assets/img/Logo/female.png"
              }
              else {
                this.recieptIMg =  "/assets/img/Logo/male.png"
              }
            }
            else {
             this.recieptIMg = `${environment.siteUrl}` + this.getLeaveAllBalanceData[i].userImage;
           }
           var imageUrl='http://localhost:4200/assets/img/Logo/contactclipart.png'
           args.cell.innerHTML = " <img style='height:20px ;width:20px; border-radius:50%' src="+this.recieptIMg+'>'+' '+args.data[0]["Employee Name"]
         //  args.cell.innerHTML = " <img style='height:20px ;width:20px' src="+imageUrl+'>'+' '+args.data[0]["Employee Name"]
         }
       
        }
      

    // this.colCell=this.colCell+1
  

    } 
  this.Grid.refreshHeader();
      
}
  collect(...a) {
   var ret = {};
   var len = arguments.length;
   for (var i = 0; i < len; i++) {
       for (var p in arguments[i]) {
         if (arguments[i].hasOwnProperty(p)) {
           ret[p] = arguments[i][p];
       }
     }
    
   }
   return ret
 }
  export(): void {
  //   debugger
  //   const excelExportProperties: ExcelExportProperties = {
  //     fileName: 'daily-report.xlsx'
  // };
  //   this.Grid.excelExport(excelExportProperties);
  }

  printList(printData) {
    debugger
    let printContents = document.getElementById(printData).innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    location.reload();
  }

  exportTableToCSV() {
    debugger
  //   const excelExportProperties: ExcelExportProperties = {
  //     fileName: 'daily-report.csv'
  // };
  // this.Grid.csvExport(excelExportProperties);

}

  // Export
  ExportExcel() {
    this.userLoader = true;
    let parms = {
      Period: this.filterPeriod,
      StartDate: this.getCSharpFormattedDate(this.filterStartDate),
      EndDate: this.getCSharpFormattedDate(this.filterEndDate),
      filterUnit: this.filterDayHour,
      filterType: this.filterType,
      FilterLeaveType: this.filterLeaveTypeId !== undefined ? this.filterLeaveTypeId : null,
      FilterEmployee: this.filterEmployeeId !== undefined ? this.filterEmployeeId : null,
      FilterDepartment: this.filterDepartmentId !== undefined ? this.filterDepartmentId : null,
      FilterDesignation: this.filterDesignationId !== undefined ? this.filterDesignationId : null,
      FilterLocation: this.filterLocationId !== undefined ? this.filterLocationId : null,
      FilterUserStatus: this.filterUserStatus !== undefined ? this.filterUserStatus : null,
      UserId: this.currentUserId,
      CmpCode:this.CmpCode
    };
    this._leaveService.getLeaveTakenReport_Excel(parms).subscribe((res) => {
      let exportData = res["data"];
      this.userLoader = false;
      this._commonSer.exportAsExcelFile(exportData, 'Lave Taken Report');
    }, error => {
      this.userLoader = false;
      console.log(error);
    });
  }
}
