import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs";
import { environment } from "src/app/environment";
import { map } from "rxjs/operators";
import { AuthService } from "src/app/services/auth.service";

@Injectable({
  providedIn: "root",
})
export class HolidaysService {
  constructor(private http: HttpClient, private authService: AuthService) {}


saveHoliday(parms): Observable<any> {
  const headers = new HttpHeaders();
  let token = this.authService.getToken();
  headers.append('Authorization', `bearer ${token}`)
  headers.append('Content-Type', 'application/json');
  const URL = environment.apiUrl + "/Holiday/PostHoliday";
  return this.http
    .post(URL, parms,{headers:headers})
    // .pipe(map((response: Response) => response));
}
getlocationlist(parm): Observable<any> {
  const headers = new HttpHeaders();
  let token = this.authService.getToken();
  headers.append('Authorization', `bearer ${token}`)
  headers.append('Content-Type', 'application/json');
  const URL = environment.apiUrl + "/Admin/GetAlllocations";
  return this.http
    .post(URL,parm,{headers:headers})
    // .pipe(map((response: Response) => response));
}


  deleteHoliday(HolidayId): Observable<any>{
    const headers = new HttpHeaders();
  let token = this.authService.getToken();
  headers.append('Authorization', `bearer ${token}`)
  headers.append('Content-Type', 'application/json');
    let parms = {};
    const URL = environment.apiUrl + "/Holiday/DeleteHoliday?holidayId="+HolidayId;
    return this.http.post(URL, parms,{headers:headers})
    // .pipe(map((response: Response) => response));
  }

  getAllHoliday(parms): Observable<any> {
    const headers = new HttpHeaders();
  let token = this.authService.getToken();
  headers.append('Authorization', `bearer ${token}`)
  headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "Holiday/GetAllHoliday";
    return this.http
      .post(URL, parms,{headers:headers})
      // .pipe(map((response: Response) => response));
  }
}































