import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectModule } from 'ng2-select';
import { LeaveRoutingModule } from './leave-routing.module';
import { LeaveComponent, NgbdSortableHeaders } from './leave.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BoxModule } from 'angular-admin-lte';
import { DynamicFormModule } from 'src/app/dynamic-form/dynamic-form.module';
import { CoreModule } from 'src/app/core/core.module';
import { TabsModule as MkTabsModule } from 'angular-admin-lte';
import { InputTextRoutingModule } from 'src/app/+form/input-text/input-text-routing.module';
import { InputGroupModule, InputTextModule as mkInputTextModule } from 'angular-admin-lte';
import { InputTextModule } from "./../../+form/input-text/input-text.module";
import { BsDatepickerModule } from 'ngx-bootstrap';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { NgbdSortableHeader } from './sortable.directive';

import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { ApprovalModule } from "./approval/approval.module";
import { SettingModule } from "./setting/setting.module";
import { CompoffModule } from "./comp-off/comp-off.module";
import { HolidaysModule } from './holidays/holidays.module';
import { LeaveReportModule } from './leave-report/leave-report.module';
import { GridAllModule, PagerModule } from '@syncfusion/ej2-angular-grids';


@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    LeaveRoutingModule,
    BoxModule, SelectModule,
    DynamicFormModule,
    MkTabsModule,
    BoxModule,
    CoreModule,
    SelectModule,
    ReactiveFormsModule,
    NgbModule,
    ApprovalModule,
    SettingModule,
    LeaveReportModule,
    CompoffModule,
    HolidaysModule,
    GridAllModule,
    PagerModule,
    DropDownListModule,
    DatePickerModule,
    
    TimepickerModule.forRoot(),
    BsDatepickerModule.forRoot(),
  ],
  declarations: [LeaveComponent, NgbdSortableHeader, NgbdSortableHeaders],
  exports:[NgbdSortableHeader]
})
export class LeaveModule {
}
