import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class UdfService {
  updateMultipleUdfData(params: any): any {
    const URL = environment.apiUrl + "/Admin/UpdateAdHocAttributeValues";
    return this.http.post(URL, params).toPromise();
  }

  constructor(private http: HttpClient) {}

  getUdfData(params) {
    const URL = environment.apiUrl + "/Admin/GetAllAdHocAttributes";
    return this.http
      .post(URL, params).toPromise();
      // .pipe(map((response: Response) => response));
  }
  
  getUdfDataExport(params) {
    const URL = environment.apiUrl + "/Admin/GetAllAdHocAttributesForExport";
    return this.http
      .post(URL, params).toPromise();
      // .pipe(map((response: Response) => response));
  }
  
  getUdfDataAsync(params): Promise<any> {
    const URL = environment.apiUrl + "/Admin/GetAllAdHocAttributes";
    return this.http.post(URL, params).toPromise();
  }
  getUdfDataValuesAsync(params): Promise<any> {
    const URL = environment.apiUrl + "/Admin/GetAllAdHocAttributeValues";
    return this.http.post(URL, params).toPromise();
  }

  getAllCount() {
    const URL = environment.apiUrl + "/Admin/GetAdHocAttributesCount";
    return this.http.get(URL).toPromise();
    // .pipe(map((response: Response) => response));
  }

  updateUdfData(udfData) {
    const URL = environment.apiUrl + "/Admin/UpdateAdHocAttribute";
    return this.http
      .post(URL, udfData).toPromise();
      // .pipe(map((response: Response) => response));
  }

  deleteUdf(params) {
    const URL = environment.apiUrl + "/Admin/DeleteAdHocAttribute/" + params;
    return this.http
      .post(URL, null).toPromise();
      // .pipe(map((response: Response) => response));
  }

  getAllUdfForExport() {
    const URL = environment.apiUrl + "/Admin/GetAllAdHocAttributesForExport";
    return this.http.get(URL).toPromise();
    // .pipe(map((response: Response) => response));
  }

  saveUdfData(udfData) {
    const URL = environment.apiUrl + "/Admin/CreateAdHocAttribute";
    return this.http
      .post(URL, udfData).toPromise();
      // .pipe(map((response: Response) => response));
  }
}
