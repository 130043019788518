import { Component, OnInit, ViewChildren,ViewChild, QueryList } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { LeaveService } from '../../leave.service';
import { CommonService } from 'src/app/common.service';
import { FormBuilder } from '@angular/forms';
import { Permission } from 'src/app/permission-keyword';
import { BsDatepickerConfig } from "ngx-bootstrap";
import { DepartmentService } from "src/app/+layout/department/department.service";
import { DesignationService } from "src/app/+layout/designation/designation.service";
import { LocationService } from "src/app/+layout/location/location.service";
import { SelectComponent } from "ng2-select/ng2-select";
import { environment  } from 'src/app/environment';
import { GridComponent ,Column, ExcelExportProperties} from '@syncfusion/ej2-angular-grids';
import { ColumnModel, ResizeService,QueryCellInfoEventArgs } from '@syncfusion/ej2-angular-grids';



@Component({
  selector: 'app-customize-leave-balance',
  templateUrl: './customize-leave-balance.component.html',
  styleUrls: ['./customize-leave-balance.component.css']
})
export class CustomizeLeaveBalanceComponent implements OnInit {
  datePickerConfiq: Partial<BsDatepickerConfig>;
  @ViewChild('overviewgrid') public Grid: GridComponent;
  globalindex: number;
  

  load() {
    const rowHeight: number = this.Grid.getRowHeight();  // height of the each row
    const gridHeight: any = this.Grid.height;  // grid height
    const pageSize: number = this.Grid.pageSettings.pageSize;   // initial page size
    const pageResize: any = (gridHeight - (pageSize * rowHeight)) / rowHeight; // new page size is obtained here
    this.Grid.pageSettings.pageSize = pageSize + Math.round(pageResize);
  }
 
  userLoader: boolean;
  currentUserId: any;

  getLeaveAllBalanceData: any;

  paidListCount: any;
  unpaidListCount: any;
  onDutyListCount: any;

  myRequestsListLength: any;
  myRequestsPage = 1;
  myRequestsPageSize = 10;

  CustomizePermission: boolean;

  flterShowData = false;

  filterDate: Date;
  filterType: number;

  // For  Dropdownlist
  filterEmployeeId: any;
  EmployeeList: any[];
  ddlEmployeeList: any[];
  ActiveEmployees = [];

  filterLeaveTypeId: any;
  LeaveTypesList: any[];
  ddlLeaveTypeList: any[];
  ActiveLeaveTypes = [];

  filterDepartmentId: any;
  DepartmentList: any[];
  ddlDepartmentList: any[];
  ActiveDepartments = [];

  filterDesignationId: any;
  DesignationList: any[];
  ddlDesignationList: any[];
  ActiveDesignations = [];

  filterLocationId: any;
  LocationList: any[];
  ddlLocationList: any[];
  ActiveLocations = [];
  ViewPermission:any
  filterUserStatus: number = null;
  CmpCode:any;
  AddPermission:any
  @ViewChildren(SelectComponent) selectElements: QueryList<any>;
  LocalDataPermission:any
  DeletePermission:any
  EditPermission:any
  constructor(
    private _authSer: AuthService,
    private _leaveSer: LeaveService,
    private _commonSer: CommonService,
    private _fb: FormBuilder,
    private _depratmentSer: DepartmentService,
    private _desigation: DesignationService,
    private _locationSer: LocationService,
    
  ) { 
    this.CmpCode = this._authSer.getcompanyCode();

    this._authSer.IsPermissions('Customize Leave Balance');
    this.LocalDataPermission = this._authSer.LocalDataPermission;
    if (this.LocalDataPermission === '' || this.LocalDataPermission === null || this.LocalDataPermission === undefined) {
      this.LocalDataPermission[0].addPermission = 0;
      this.LocalDataPermission[0].editPermission = 0;
      this.LocalDataPermission[0].viewpermission = 0;
      this.LocalDataPermission[0].deletePermission = 0;
    }
    this.ViewPermission = this.LocalDataPermission[0].viewpermission //this.CurrentUser.permission.includes(Permission.ProjectPhaseView);
    this.AddPermission = this.LocalDataPermission[0].addPermission; //this.CurrentUser.permission.includes(Permission.ProjectPhaseAdd);
    this.EditPermission = this.LocalDataPermission[0].editPermission //this.CurrentUser.permission.includes(Permission.ProjectPhaseEdit);
    this.DeletePermission = this.LocalDataPermission[0].deletePermission; //this.CurrentUser.permission.includes(Permission.ProjectPhaseDelete);
    // this.ArchivePermission = this.LocalDataPermission[0].addPermission; //this.CurrentUser.permission.includes(Permission.ProjectArchive); 
  }
  filterSettings:object
  filter:object
  ngOnInit() {// Angular lifecycle hook
    this.filterSettings = { type: "CheckBox" };
    this.filter = { type: "CheckBox" };
    this.CmpCode = this._authSer.getcompanyCode();
    this.getLeaveAllBalanceData = [];
    this.paidListCount = 0;
    this.unpaidListCount = 0;
    this.onDutyListCount = 0;

    this._authSer.currentUser.subscribe((res) => {
      this.currentUserId = res.userId;
   //   this.CustomizePermission = res.permission.includes(Permission.CustomizeLeaveBal);
    });

    let currentDate = new Date();
    this.filterDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());

    this.filterType = -1;

    this.getAllLeaveBalance();
    
  }

 columnArray:any=[]
 employeeName:any
 ejsGridData:any=[]
 newArray:any=[]
 public columns: ColumnModel[];
 columnArrayfor:any=[]
 unPaidArray:any=[]
 paidArray:any=[]
 newTotalArray:any=[]
 paidUnpaidLeave:any=[]
 paidLeave:any=[]
 onDutyList:any=[]
 newDutyList:any=[]
 

  getAllLeaveBalance() {// Created By A Getting all leave balance list 
    this.userLoader = true;
    let parms = {
      StartDate: this.getCSharpFormattedDate(this.filterDate),
      FilterLeaveType: this.filterLeaveTypeId !== undefined ? this.filterLeaveTypeId : null,
      FilterEmployee: this.filterEmployeeId !== undefined ? this.filterEmployeeId : null,
      FilterDepartment: this.filterDepartmentId !== undefined ? this.filterDepartmentId : null,
      FilterDesignation: this.filterDesignationId !== undefined ? this.filterDesignationId : null,
      FilterLocation: this.filterLocationId !== undefined ? this.filterLocationId : null,
      FilterUserStatus: this.filterUserStatus !== undefined ? this.filterUserStatus : null,
      CmpCode:this.CmpCode,
    };

    this._leaveSer.getAllLeaveBalance(parms).subscribe(res => {
      this.userLoader = false;
      debugger
      // empCode: ""
      // employeeId: 20580
      // employeeName: "Lovish Kalra "
      // inActive: false
      // isdeleted: false
      // userId: 20580
    this.employeeName='Employee Name'
    this.getLeaveAllBalanceData = res['data'];
    this.myRequestsListLength = this.getLeaveAllBalanceData.length;
    this.columnArray.push(this.employeeName)
    this.paidArray=this.getLeaveAllBalanceData[0].paidLeavesList
    this.unPaidArray=this.getLeaveAllBalanceData[0].unpaidLeavesList
    this.paidListCount = this.getLeaveAllBalanceData[0].paidLeavesList.length;
    this.unpaidListCount = this.getLeaveAllBalanceData[0].unpaidLeavesList.length;
    this.onDutyListCount = this.getLeaveAllBalanceData[0].onDutyLeavesList.length;
    this.onDutyList=this.getLeaveAllBalanceData[0].onDutyLeavesList
    this.newDutyList=this.onDutyList.concat(this.unPaidArray)
    this.newTotalArray=this.paidArray.concat(this.newDutyList)
    var totalCount= this.paidListCount+this.unpaidListCount
      for(var i=0;i< this.newTotalArray.length;i++)
      {
        this.columnArray.push(this.newTotalArray[i].leaveTypeName)
      }
 
      // for(var i=0;i< this.getLeaveAllBalanceData[0].paidLeavesList.length;i++)
      // {
      //   this.columnArray.push(this.getLeaveAllBalanceData[0].paidLeavesList[i].leaveTypeName)
      // }
       this.unpaidListCount = this.getLeaveAllBalanceData[0].unpaidLeavesList.length;

      let arra = []
      for(var i=0;i< this.getLeaveAllBalanceData.length;i++)
      {
        for(var j=0;j<this.columnArray.length;j++)
      {
        var key=this.columnArray[j]
        if(j==0)
        {
          var data=this.getLeaveAllBalanceData[i].employeeName
          if(data=="Katy Watson ")
          {
            // debugger
          }
        }
        else if(j>0 && j<=this.paidListCount){
          var data=this.getLeaveAllBalanceData[i].paidLeavesList[j-1].balance
          // var user=this.getLeaveAllBalanceData[i].userId
        }
        else if(j>this.paidListCount&&j<=totalCount) {
          var count=this.paidListCount+1
          var data=this.getLeaveAllBalanceData[i].unpaidLeavesList[j-count].balance
     
         
          
         
        }
        else{
          var count=totalCount+1
          var data=this.getLeaveAllBalanceData[i].onDutyLeavesList[j-count].balance
          // var user=this.getLeaveAllBalanceData[i].userId
        }
        
         this.ejsGridData.push({[key]:data})
         arra.push(this.getLeaveAllBalanceData[i].userId)
        }
      }
    

      //       for(var j=0;j<this.ejsGridData.length;j++)
      // { 
      //   var d=this.collect(bb
      //     )
      //     this.newArray.push(d)
      //     j=j+this.newTotalArray.length
      // }
      var obj = [];

          for(var j=0;j<this.ejsGridData.length;j++)
      { 
         obj=[]
        for(var k=0;k<=this.newTotalArray.length;k++)
        {
          obj[k]=this.ejsGridData[j+k]
       //  ss.push(this.ejsGridData[j+k])
        }
        this.globalindex = (j+k)-1
        
       
        obj.push({userId: arra[(j+k)-1]})
        
        var d=this.collect(obj)
        

          this.newArray.push(d)
          this.newArray.splice()
          j=j+this.newTotalArray.length
      }
      
      
      // for(var i=0;i< this.getLeaveAllBalanceData.length;i++)
      // {
      //   for(var j=0;j<this.columnArray.length;j++)
      // {
      //   var key=this.columnArray[j]
      //   if(j==0)
      //   {
      //     var data=this.getLeaveAllBalanceData[i].employeeName
      //   }
      //   else{
      //     var data=this.getLeaveAllBalanceData[0].paidLeavesList[j-1].balance
      //   }
      //    this.ejsGridData.push({[key]:data})
      //   }
      // }
     
      // for(var j=0;j<this.ejsGridData.length;j++)
      // { 
      //   var d=this.collect(this.ejsGridData[j+0],this.ejsGridData[j+1],this.ejsGridData[j+2],this.ejsGridData[j+3],this.ejsGridData[j+4],
      //     this.ejsGridData[j+5],this.ejsGridData[j+6],this.ejsGridData[j+7],this.ejsGridData[j+8]
      //     )
      //     this.newArray.push(d)
      //     j=j+8
      // }
      
      
    }, error => {
      this.userLoader = false;
      console.log(error);
    });
  }
  



  export(): void {// Created By K Export Records as Excel
    debugger
    const excelExportProperties: ExcelExportProperties = {
      fileName: 'leave.xlsx'
  };
   // this.UpdateForExport();
  //this.getLocationList();
    this.Grid.excelExport(excelExportProperties);
  }

  printList(printData) {// Created By G Print Current View
    debugger
    let printContents = document.getElementById(printData).innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    location.reload();
  }

  exportTableToCSV() {// Created By K Export Records to CSV
    debugger
    const excelExportProperties: ExcelExportProperties = {
      fileName: 'leave.csv'
  };
  this.Grid.csvExport(excelExportProperties);

}
  collect(...a) {// Created By S
    var ret = {};
    var len = arguments.length;
    for (var i = 0; i < len; i++) {
        for (var p in arguments[i]) {
          if (arguments[i].hasOwnProperty(p)) {
            ret[p] = arguments[i][p];
        }
      }
     
    }
    return ret;
  }
  get countries() {
    return this.getLeaveAllBalanceData.map((country, i) => ({
      id: i + 1,
      ...country,
    })).slice(
      (this.myRequestsPage - 1) * this.myRequestsPageSize,
      (this.myRequestsPage - 1) * this.myRequestsPageSize + this.myRequestsPageSize
    );
  }
  recieptIMg:any
  colCell:any=0
  customiseCell(args: QueryCellInfoEventArgs) {// Created By S grid cells customization
    debugger
    var col=args.column.field
    var  clName=col.split('.')
  //  args.column.field=clName[1]
   const column = this.Grid.getColumnByField(args.column.field); // get the JSON object of the column corresponding to the field name
     column.headerText = clName[1]; // assign a new header text to the column

    //  if (args.data[this.columnArray.length-2]['inActive']=='InActive'||args.data['inActive']==0) { 
    //      args.cell.classList.add('background-yellow');
    //    //args.cell.classList.add('below-30'); 

    //  }
   
     
     if (args.column.field == "0.Employee Name") {
      // debugger
      args.cell.classList.add('below-0');
      for (var i = 0; i < this.getLeaveAllBalanceData.length; i++) {
        if (args.data[this.columnArray.length]["userId"] == this.getLeaveAllBalanceData[i].userId) {
          // debugger;
          // for(var k=0;k<this.getLeaveAllBalanceData.length;k++)
          // {
          //  this.getLeaveAllBalanceData[k].userid
          // }
          //  if(this.colCell==i){
          if (this.getLeaveAllBalanceData[i].userImage == '' || this.getLeaveAllBalanceData[i].userImage == undefined || this.getLeaveAllBalanceData[i].userImage == null) {
            if (this.getLeaveAllBalanceData[i].genders == "Female") {
              this.recieptIMg = "/assets/img/Logo/female.png"
            }
            else {
              this.recieptIMg = "/assets/img/Logo/male.png"
            }
          }
          else {
            this.recieptIMg = `${environment.siteUrl}` + this.getLeaveAllBalanceData[i].userImage;
          }
          var imageUrl = 'http://localhost:4200/assets/img/Logo/contactclipart.png'
          args.cell.innerHTML = " <img style='width: 28px;margin-right: 5px; border-radius: 50%;' src=" + this.recieptIMg + '>' + ' ' + args.data[0]["Employee Name"]
          //  args.cell.innerHTML = " <img style='height:20px ;width:20px' src="+imageUrl+'>'+' '+args.data[0]["Employee Name"]
        }

      }


      // this.colCell=this.colCell+1


    }
    debugger
    let num = this.Grid.getColumns(true);
    (this.Grid.columns[num.length-1] as Column).visible = false;
        this.Grid.refreshHeader(); 
    
}


  getCSharpFormattedDate(val: any) {// Created By A Get backend date format
    if (val === null || val === "") {
      return null;
    }
    const date = new Date(val);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return month + "-" + day + "-" + year;
  }

  filterShow() {// Created By A Initialize filters
    this.getLeaveTypeList();
    this.getEmployeeList();
    this.getDepartmentList();
    this.getAllDesigationList();
    this.getAllLocationList();
    this.flterShowData = !this.flterShowData;
  }
  closeFilter() {// Created By A ste value
    this.flterShowData = false;
  }

  ddlSelection(value: any, placeHolder: any) {// Created By A Global Controller For fields update
    try {
      switch (placeHolder) {
        case 'Employee':
          this.ActiveEmployees = [];
          this.ActiveEmployees.push(value);
          this.filterEmployeeId = value.id;
          break;
        case 'LeaveType':
          this.ActiveLeaveTypes = [];
          this.ActiveLeaveTypes.push(value);
          this.filterLeaveTypeId = value.id;
          break;
        case "Department":
          this.ActiveDepartments = [];
          this.ActiveDepartments.push(value);
          this.filterDepartmentId = value.id;
          break;
        case "Desigation":
          this.ActiveDesignations = [];
          this.ActiveDesignations.push(value);
          this.filterDesignationId = value.id;
          break;
        case "Location":
          this.ActiveLocations = [];
          this.ActiveLocations.push(value);
          this.filterLocationId = value.id;
          break;
      }
    } catch (ex) {

    }
  }
  removeFilterDropdownValue(value, _type) {// Created By A Global Controller for fields update
    try {
      switch (_type) {
        case "Employee":
          this.ActiveEmployees = [];
          this.filterEmployeeId = null;
          break;
        case 'LeaveType':
          this.ActiveLeaveTypes = [];
          this.filterLeaveTypeId = null;
          break;
        case "Department":
          this.ActiveDepartments = [];
          this.filterDepartmentId = null;
          break;
        case "Desigation":
          this.ActiveDesignations = [];
          this.filterDesignationId = null;
          break;
        case "Location":
          this.ActiveLocations = [];
          this.filterLocationId = null;
          break;
      }
    } catch (ex) {
      console.log(ex);
    }
  }

  getEmployeeList() {// Created By A Getting all employees list
    this._leaveSer.getEmployeesListForSelect(this.currentUserId,this.CmpCode).subscribe((res) => {
      this.EmployeeList = res['data'].table;
      this.ddlEmployeeList = this._commonSer.SetDDlDropdown(res["data"].table, 'employeeId', 'employeeCodeName');
    }, error => {
      this.userLoader = false;
      console.log(error);
    });
  }

  getLeaveTypeList() {// Created By A Getting leave type list
    this._leaveSer.getAllLeaveTypeForSelect(this.CmpCode).subscribe((res) => {
      this.LeaveTypesList = res["data"].table;
      this.ddlLeaveTypeList = this._commonSer.SetDDlDropdown(res["data"].table, "leaveTypeId", "leaveTypeName");
    }, error => {
      this.userLoader = false;
      console.log(error);
    });
  }

  getDepartmentList() {// Created By A Getting all departments
    let parms = {
      Condition: "",
      FieldName: "CreatedDate",
      NoOfRecords: 10000,
      PageNo: 1,
      SortType: "desc",
      CmpCode:this.CmpCode
    };
    this._depratmentSer.getDepartmentList(parms).subscribe((res) => {
      this.DepartmentList = res["data"].table;
      this.ddlDepartmentList = this._commonSer.SetDDlDropdown(res["data"].table, "departmentId", "departmentName");
    }, error => {
      this.userLoader = false;
      console.log(error);
    });
  }

  getAllDesigationList() {// Created By A Getting all designation
    let parms = {
      Condition: "",
      FieldName: "CreatedDate",
      NoOfRecords: 10000,
      PageNo: 1,
      SortType: "desc",
      CmpCode:this.CmpCode
    };
    this._desigation.getDesignationList(parms).subscribe((res) => {
      this.DesignationList = res["data"].table;
      this.ddlDesignationList = this._commonSer.SetDDlDropdown(res["data"].table, "designationId", "designationName");
    }, error => {
      this.userLoader = false;
      console.log(error);
    });
  }

  getAllLocationList() {// Created By A Getting all locations
    let parms = {
      Condition: "",
      FieldName: "CreatedDate",
      NoOfRecords: 10000,
      PageNo: 1,
      SortType: "desc",
      CmpCode:this.CmpCode
    };
    this._locationSer.getlocationlist(parms).subscribe((res) => {
      this.LocationList = res["data"].table;
      this.ddlLocationList = this._commonSer.SetDDlDropdown(res["data"].table, "locationId", "locationName");
    }, error => {
      this.userLoader = false;
      console.log(error);
    });
  }

  processFilters() {// Created By A Get leave balance list for filters
    this.flterShowData = false;
    this.getAllLeaveBalance();
  }

  resetFilters() {// Created By A Reset Filters
    this.ActiveEmployees = [];
    this.filterEmployeeId = null;
    this.ActiveLeaveTypes = [];
    this.filterLeaveTypeId = null;
    this.ActiveDepartments = [];
    this.filterDepartmentId = null;
    this.ActiveDesignations = [];
    this.filterDesignationId = null;
    this.ActiveLocations = [];
    this.filterLocationId = null;

    let currentDate = new Date();
    this.filterDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
    this.filterType = -1;
  }

  onChangeFilterType(e) {// Created By A 
    // console.clear();
    // console.log(e);
    // console.log(this.filterType);
  }


  closeDropdownList(element) {// Created By A Close Opened Dropdowns when clicking on new dropdown
    if (element.optionsOpened == true) {
      let elementsToclose = this.selectElements.filter(function (el: any) {
        return (el != element && el.optionsOpened == true);
      });
      elementsToclose.forEach(function (e: SelectComponent) {
        e.clickedOutside();
      });
    }
  }

  onDPDateChange(e) {// Created By A Set value
    this.filterDate = e;
  }
  
 
 userImage(item) {// Created By S Convert Users Image URL into standard format
   // <img src="/assets/img/Logo/male.png" style="width: 190px;">
   if (item.userImage == '' || item.userImage == undefined || item.userImage == null) {
     // this.recieptIMg = `${environment.siteUrl}` + "Uploads/Icon/UploadIcons.png";
     if (item.genders == "Female") {
       this.recieptIMg = "/assets/img/Logo/female.png"

     }
     else {
       this.recieptIMg = "/assets/img/Logo/male.png"

     }
   }
   else {
     this.recieptIMg = `${environment.siteUrl}` + item.userImage;
   }
   return this.recieptIMg

 }
}
