import { DynamicControlBase } from "./dynamic-control-base";

export class DynamicControlCheckbox extends DynamicControlBase<string> {
  controlType = "checkbox";
  type: string;
  options: string[];
  constructor(options: {} = {}) {
    super(options);
    this.type = options["type"] || "";
    this.options = options["options"] || [];
  }
}
