import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../environment';
import { NgxZendeskWebwidgetService } from 'ngx-zendesk-webwidget';
import { DatePipe, DecimalPipe } from '@angular/common';
import { param } from 'jquery';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  IsPermissionData: any = [];
  LocalDataPermission: any;

  constructor(private myRoute: Router, private httpClient: HttpClient,
    // private _NgxZendeskWebwidgetService: NgxZendeskWebwidgetService,
    private datePipe: DatePipe,private decimalpipe: DecimalPipe) { 
      this.DateFormat= this.getdateFormat();
      this.Decimalformat = this.getdecimalFormat();
    }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  DateFormat:any
  Dateformat(datas:string){
    this.DateFormat= this.getdateFormat();
    if (this.DateFormat=='1')
    {
      return this.datePipe.transform(datas, 'MM/dd/yyyy')   
    }
    if(this.DateFormat=='2')
    {
      return this.datePipe.transform(datas, 'dd/MM/yyyy')
    }
    if(this.DateFormat== '3')
    {
      return this.datePipe.transform(datas, 'yyyy/MM/dd')
    } 
  }

  Decimalformat:any;
  DecimalFormat(datas:string) {
    this.Decimalformat = this.getdecimalFormat();
    if (this.Decimalformat=='0')
    {return this.decimalpipe.transform(datas, '1.0-0')}
    if (this.Decimalformat=='1')
    {return this.decimalpipe.transform(datas, '.1-1')}
    if (this.Decimalformat=='2')
    {return this.decimalpipe.transform(datas, '.2-2')}
  }

  CurrencySymbols(data: any){
    switch (data) {
      case 'USD':
        return "assets/img/CURRENCY-SYMBOLS/USD.png";
        break;
      case 'AUD':
        return "assets/img/CURRENCY-SYMBOLS/AUD.png";
        break;
      case 'BGN':
        return "assets/img/CURRENCY-SYMBOLS/BGN.png";
        break;
      case 'BRL':
        return "assets/img/CURRENCY-SYMBOLS/BRL.png";
        break;
      case 'CAD':
        return "assets/img/CURRENCY-SYMBOLS/CAD.png";
        break;
      case 'CHF':
        return "assets/img/CURRENCY-SYMBOLS/CHF.png";
        break;
      case 'CNY':
        return "assets/img/CURRENCY-SYMBOLS/CNY.png";
        break;
      case 'CZK':
        return "assets/img/CURRENCY-SYMBOLS/CZK.png";
        break;
      case 'DKK':
        return "assets/img/CURRENCY-SYMBOLS/DKK.png";
        break;
      case 'GBP':
        return "assets/img/CURRENCY-SYMBOLS/GBP.png";
        break;
      case 'HKD':
        return "assets/img/CURRENCY-SYMBOLS/HKD.png";
        break;
      case 'HRK':
        return "assets/img/CURRENCY-SYMBOLS/HRK.png";
        break;
      case 'HUF':
        return "assets/img/CURRENCY-SYMBOLS/HUF.png";
        break;
      case 'IDR':
        return "assets/img/CURRENCY-SYMBOLS/IDR.png";
        break;
      case 'ILS':
        return "assets/img/CURRENCY-SYMBOLS/ILS.png";
        break;
      case 'INR':
        return "assets/img/CURRENCY-SYMBOLS/INR.png";
        break;
      case 'ISK':
        return "assets/img/CURRENCY-SYMBOLS/ISK.png";
        break;
      case 'JPY':
        return "assets/img/CURRENCY-SYMBOLS/JPY.png";
        break;
      case 'KRW':
        return "assets/img/CURRENCY-SYMBOLS/KRW.png";
        break;
      case 'MXN':
        return "assets/img/CURRENCY-SYMBOLS/MXN.png";
        break;
      case 'MYR':
        return "assets/img/CURRENCY-SYMBOLS/MYR.png";
        break;
      case 'NOK':
        return "assets/img/CURRENCY-SYMBOLS/NOK.png";
        break;
      case 'NZD':
        return "assets/img/CURRENCY-SYMBOLS/NZD.png";
        break;
      case 'PHP':
        return "assets/img/CURRENCY-SYMBOLS/PHP.png";
        break;
      case 'PLN':
        return "assets/img/CURRENCY-SYMBOLS/PLN.png";
        break;
      case 'RON':
        return "assets/img/CURRENCY-SYMBOLS/RON.png";
        break;
      case 'RUB':
        return "assets/img/CURRENCY-SYMBOLS/RUB.png";
        break;
      case 'SEK':
        return "assets/img/CURRENCY-SYMBOLS/SEK.png";
        break;
      case 'SGD':
        return "assets/img/CURRENCY-SYMBOLS/SGD.png";
        break;
      case 'THB':
        return "assets/img/CURRENCY-SYMBOLS/THB.png";
        break;
      case 'TRY':
        return "assets/img/CURRENCY-SYMBOLS/TRY.png";
        break;
      case 'ZAR':
        return "assets/img/CURRENCY-SYMBOLS/ZAR.png";
        break;
      default:
        break;
    }
  }

  IsPermissions(value){
    let localData = localStorage.getItem('IsPermissions');
    this.IsPermissionData = JSON.parse(localData);
    if(this.IsPermissionData){
    this.LocalDataPermission = this.IsPermissionData.filter(user => user.formName === value);
    }
  }

  setToken(token: string, expiry: string) {
    let up = localStorage.getItem(btoa('Permission'));
    up = up === null ? up : atob(up);
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(up));
    this.currentUser = this.currentUserSubject.asObservable();
    localStorage.setItem(btoa("LoggedInUser"), btoa(token));
    localStorage.setItem(btoa("tokenExpiry"), btoa(expiry));
  }
  isTokenAlive() {
    let expiry = localStorage.getItem(btoa("tokenExpiry"));
    expiry = atob(expiry);
    let expiryTimes = new Date(expiry);
    if (Date.now() <= expiryTimes.getTime()) {
      return true;
    } else {
      return false;
    }
  }
  getToken() {
    let lu = localStorage.getItem(btoa("LoggedInUser"));
    lu = lu === null ? lu : atob(lu);

    if (lu) {
      if (this.isTokenAlive()) {
        // this._NgxZendeskWebwidgetService.identify({
        //   name: this.getUserFullName(),
        //   email: this.getUseremail()
        // });
        // this._NgxZendeskWebwidgetService.show();
      }
    }

    return this.isTokenAlive() ? lu : null;
  }

  isLoggednIn() {
    return this.getToken() !== null;
  }
  logout() {
    this.httpClient.post(`${environment.apiUrl}/accounts/logout`, null).subscribe(response => {
      localStorage.removeItem(btoa("LoggedInUser"));
      localStorage.removeItem(btoa('tokenExpiry'));
      localStorage.removeItem(btoa("userName"));
      localStorage.removeItem(btoa('userEmail'));
      localStorage.removeItem(btoa('userFullName'));
      localStorage.removeItem(btoa('Permission'));
      localStorage.removeItem(btoa('roleId'));
      // localStorage.removeItem(btoa('themeId'));
      localStorage.removeItem(btoa('currency'));
      localStorage.removeItem(btoa('logo'));
      localStorage.removeItem(btoa('noofCustomer'));
      localStorage.removeItem(btoa('noofUsers'));
      localStorage.removeItem(btoa('userImage'));
      localStorage.removeItem(btoa('companyCode'));
      localStorage.removeItem(btoa('projectcoderequired'));
      localStorage.removeItem(btoa('projectprefix'));
      localStorage.removeItem(btoa('projectstart'));
      localStorage.removeItem(btoa('customerrequired'));
      localStorage.removeItem(btoa('customerprefix'));
      localStorage.removeItem(btoa('customerstart'));
      localStorage.removeItem(btoa('employeerequired'));
      localStorage.removeItem(btoa('employeeprefix'));
      localStorage.removeItem(btoa('employeestart'));
      localStorage.removeItem(btoa('addressrequired'));
      localStorage.removeItem(btoa('addressprefix'));
      localStorage.removeItem(btoa('addressstart'));
      localStorage.removeItem(btoa('decimalFormat'));
      localStorage.removeItem(btoa('dateFormat'));
      localStorage.removeItem(btoa('starTime'));
      localStorage.removeItem(btoa('endTime'));
      localStorage.removeItem(btoa('checkintype'));
      localStorage.removeItem(btoa('checkintime'));
      localStorage.removeItem(btoa('locationId'));
      localStorage.removeItem(btoa('locationId'));

      localStorage.removeItem('istoggle');
      localStorage.removeItem('IsPermissions');
      

      
      // this._NgxZendeskWebwidgetService.hide();

      this.myRoute.navigate(["Login"]);
      // window.location.href = `${environment.PMSLoginUri}`;
      this.currentUserSubject.next(null);
    });
  }
  loginUser(username: string, password: string , cmp): Observable<any> {
    const formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);
    formData.append('ClientId', cmp);
    
    formData.append('grant_type', 'password');

    const userData = "ClientId=" + cmp + "&password=" + password + "&grant_type=password"+"&username="+username;
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.httpClient.post(`${environment.authUrl}`, userData, { headers: reqHeader });
  }
  GetOTP(mobile, code): Observable<any> {
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.httpClient.get(`${environment.apiUrl}` + 'Message/getjointop?mobileno=' + mobile+ "&countrycode="+code, { headers: reqHeader });
  }

  getUserComponies(email,token): Observable<any> {
    const reqHeader = new HttpHeaders();
    reqHeader.append('Authorization', `bearer ${token}`)
    return this.httpClient.get(`${environment.apiUrl}` + 'Accounts/Getusercompanyains?email=' + email, { headers: reqHeader });
  }
  LoginWarn(email): Observable<any> {
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.httpClient.get(`${environment.apiUrl}` + "Accounts/wrongattempt?email="+email , { headers: reqHeader });
  }
  // GetMobileNoStatus(mobile): Observable<any> {
  //   const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
  //   return this.httpClient.get(`${environment.apiUrl}` + 'Message/getjointop?mobileno=' + mobile, { headers: reqHeader });
  // }
  getcheckinstatus(params): Observable<any> {
//debugger
    const formData = new FormData();
    

    const userData = "Accounts/checkinstatus?userid=" + params.userid + "&CmpCode=" + params.cmpcode + "&dateon=" + params.date;
  
    return this.httpClient.get(`${environment.apiUrl}`+ userData);
  }

  setcheckstatus(params): Observable<any> {
 
   debugger;
        const userData = "Accounts/checkinuser?userid=" + params.userid + "&CmpCode=" + params.cmpcode + "&dateon=" + params.dateon+ '&lati='+ params.lati+'&longi='+ params.longi+'&status='+ params.status+'&header='+ params.browser+'&ipaddress='+params.ipaddress;
        return this.httpClient.get(`${environment.apiUrl}`+ userData);
      }
    

  settimeformat(timeformat: string) {
        localStorage.setItem(btoa("timeformat"), btoa(timeformat));
      }

  setCheckin(checkintype: string) {
    localStorage.setItem(btoa("checkintype"), btoa(checkintype));
  }

  setLastcheckinmints(checkintype: string) {
    localStorage.setItem(btoa("lastcheckinmints"), btoa(checkintype));
  }
  setCheckininTime(checkintime: any) {
    localStorage.setItem(btoa("checkintime"), btoa(checkintime));
  }
  setLocationId(locationId: any) {
    localStorage.setItem(btoa("locationId"), btoa(locationId));
  }


  setNoOfAttepmts(number: any) {
    localStorage.setItem(btoa("setNoOfAttepmts"), btoa(number));
  }
  
  setUsername(userName: string) {
    localStorage.setItem(btoa("userName"), btoa(userName));
  }
  setUseremail(userEmail: string) {
    localStorage.setItem(btoa("userEmail"), btoa(userEmail));
  }
  setUserFullName(setUserFullName: string) {
    localStorage.setItem(btoa("userFullName"), btoa(setUserFullName));
  }
  setUserType(userType: string) {
    localStorage.setItem(btoa("userType"), btoa(userType));
  }
  setUserId(userId: string) {
    localStorage.setItem(btoa("userId"), btoa(userId));
  }
  setRoleId(roleId: any){
    localStorage.setItem(btoa("roleId"), btoa(roleId));
  }
  setSubRoleId(SubroleId: any){
    localStorage.setItem(btoa("SubroleId"), btoa(SubroleId));
  }
  setThemeId(themeId: any){
    localStorage.setItem(btoa("themeId"), btoa(themeId));
  }
  setCurrency(currency: any){
    localStorage.setItem(btoa("currency"), btoa(currency));
  }
  setFinancialYearStart(start: any){
    localStorage.setItem(btoa("FinancialYearStart"), btoa(start));
  }
  setFinancialYearEnd(end: any){
    localStorage.setItem(btoa("FinancialYearEnd"), btoa(end));
  }
  setLogo(logo: any){
    localStorage.setItem(btoa("logo"), btoa(`${environment.siteUrl}` + logo));
  }
  setNoOfCustomer(noofCustomer: any){
    localStorage.setItem(btoa("noofCustomer"), btoa(noofCustomer));
  }
  setNoOfUsers(noofUsers: any){
    localStorage.setItem(btoa("noofUsers"), btoa(noofUsers));
  }
  setcmpName(cmpname: any){
    localStorage.setItem(btoa("cmpName"), btoa(cmpname));
  }

  setUserImage(userImage: any){
    localStorage.setItem(btoa("userImage"), btoa(`${environment.siteUrl}` + userImage));
  }
  setcompanyCode(companyCode: any){
    localStorage.setItem(btoa("companyCode"), btoa(companyCode));
  }
  setprojectcoderequired(projectcoderequired: any){
    localStorage.setItem(btoa("projectcoderequired"), btoa(projectcoderequired));
  }  
  setprojectprefix(projectprefix: any){
    localStorage.setItem(btoa("projectprefix"), btoa(projectprefix));
  }
  setprojectstart(projectstart: any){
    localStorage.setItem(btoa("projectstart"), btoa(projectstart));
  }
  setcustomerrequired(customerrequired: any){
    localStorage.setItem(btoa("customerrequired"), btoa(customerrequired));
  }
  setcustomerprefix(customerprefix: any){
    localStorage.setItem(btoa("customerprefix"), btoa(customerprefix));
  }
  setcustomerstart(customerstart: any){
    localStorage.setItem(btoa("customerstart"), btoa(customerstart));
  }
  setemployeerequired(employeerequired: any){
    localStorage.setItem(btoa("employeerequired"), btoa(employeerequired));
  }
  setemployeeprefix(employeeprefix: any){
    localStorage.setItem(btoa("employeeprefix"), btoa(employeeprefix));
  }
  setemployeestart(employeestart: any){
    localStorage.setItem(btoa("employeestart"), btoa(employeestart));
  }
  setaddressrequired(addressrequired: any){
    localStorage.setItem(btoa("addressrequired"), btoa(addressrequired));
  }
  setaddressprefix(addressprefix: any){
    localStorage.setItem(btoa("addressprefix"), btoa(addressprefix));
  }
  setaddressstart(addressstart: any){
    localStorage.setItem(btoa("addressstart"), btoa(addressstart));
  }
  setdecimalFormat(decimalFormat: any){
    localStorage.setItem(btoa("decimalFormat"), btoa(decimalFormat));
  }
  setdateFormat(dateFormat: any){
    localStorage.setItem(btoa("dateFormat"), btoa(dateFormat));
  }
  setStartTime(startTime: any){
    localStorage.setItem(btoa("startTime"), btoa(startTime));
  }
  setEndTime(endTime: any){
    localStorage.setItem(btoa("endTime"), btoa(endTime));
  }
  setSalePersonId(SalePersonId: any){
    localStorage.setItem(btoa("SalePersonId"), btoa(SalePersonId));
  }
  settoggle(toggle: any){
    localStorage.setItem("istoggle", toggle);
  }
  ImageRecord: any;
  setImageData(data) {
    this.ImageRecord = data
  }

  getImageData() {
    return this.ImageRecord
  }
  

  gettimeformat() {  
    let un = localStorage.getItem(btoa("timeformat"));
    un = un === null ? un : atob(un);
    return un;
  }


  getSalePersonId() {  
    let un = localStorage.getItem(btoa("SalePersonId"));
    un = un === null ? un : atob(un);
    return un;
  }

  getNoOfAttepmts() {  
    let un = localStorage.getItem(btoa("setNoOfAttepmts"));
    un = un === null ? un : atob(un);
    return un;
  }

  getLastcheckinmints() {  
    let un = localStorage.getItem(btoa("lastcheckinmints"));
    un = un === null ? un : atob(un);
    return un;
  }
  getCheckintype() {  
    let un = localStorage.getItem(btoa("checkintype"));
    un = un === null ? un : atob(un);
    return un;
  }

  getCheckinTime() {  
    let un = localStorage.getItem(btoa("checkintime"));
    un = un === null ? un : atob(un);
    return un;
  }
  getLocationId() {  
    let un = localStorage.getItem(btoa("locationId"));
    un = un === null ? un : atob(un);
    return un;
  }

  getFinancialYearStart(){
    let un = localStorage.getItem(btoa("FinancialYearStart"));
    un = un === null ? un : atob(un);
    return un;
   
  }
  getFinancialYearEnd(){
    let un = localStorage.getItem(btoa("FinancialYearEnd"));
    un = un === null ? un : atob(un);
    return un;
   
  }
  getLogo() {  
    let un = localStorage.getItem(btoa("logo"));
    un = un === null ? un : atob(un);
    return un;
  }
  getNoOfCustomer() {
    let un = localStorage.getItem(btoa("noofCustomer"));
    un = un === null ? un : atob(un);
    return un;
  }
  getNoOfUsers() {
    let un = localStorage.getItem(btoa("noofUsers"));
    un = un === null ? un : atob(un);
    return un;
  }
  getUserImage() {
    let un = localStorage.getItem(btoa("userImage"));
    un = un === null ? un : atob(un);
    return un;
  }
  getCurrency() {
    let un = localStorage.getItem(btoa("currency"));
    un = un === null ? un : atob(un);
    return un;
  }
  getCmpName() {
    let un = localStorage.getItem(btoa("cmpName"));
    un = un === null ? un : atob(un);
    return un;
  }
  getThemeId() {
    let un = localStorage.getItem(btoa("themeId"));
    un = un === null ? un : atob(un);
    return un;
  }
  getRoleId() {
    let un = localStorage.getItem(btoa("roleId"));
    un = un === null ? un : atob(un);
    return un;
  }
  getSubRoleId() {
    let un = localStorage.getItem(btoa("SubroleId"));
    un = un === null ? un : atob(un);
    return un;
  }
  getUserId() {
    let un = localStorage.getItem(btoa("userId"));
    un = un === null ? un : atob(un);
    return un;
  }
  getExpenseDashboard() {
    let un = localStorage.getItem(btoa("ExpenseDashboard"));
    un = un === null ? un : atob(un);
    return un;
  }
  getUsername() {
    let un = localStorage.getItem(btoa("userName"));
    un = un === null ? un : atob(un);
    return un;
  }
  getUName() {
    let ut = localStorage.getItem(btoa("uName"));
    ut = ut === null ? ut : atob(ut);
    return ut;
  }
  setUName(uName: string) {
    localStorage.setItem(btoa("uName"), btoa(uName));
  }
  getUseremail() {
    let ue = localStorage.getItem(btoa("userEmail"));
    ue = ue === null ? ue : atob(ue);
    return ue;
  }
  getUserFullName() {
    let ufn = localStorage.getItem(btoa("userFullName"));
    ufn = ufn === null ? ufn : atob(ufn);
    return ufn;
  }
  getUserType() {
    let ut = localStorage.getItem(btoa("userType"));
    ut = ut === null ? ut : atob(ut);
    return ut;
  }
  getcompanyCode() {
    let ut = localStorage.getItem(btoa("companyCode"));
    ut = ut === null ? ut : atob(ut);
    return ut;
  }
  getprojectcoderequired() {
    let ut = localStorage.getItem(btoa("projectcoderequired"));
    ut = ut === null ? ut : atob(ut);
    return ut;
  }
  getprojectprefix() {
    let ut = localStorage.getItem(btoa("projectprefix"));
    ut = ut === null ? ut : atob(ut);
    return ut;
  }
  getprojectstart() {
    let ut = localStorage.getItem(btoa("projectstart"));
    ut = ut === null ? ut : atob(ut);
    return ut;
  }
  getcustomerrequired() {
    let ut = localStorage.getItem(btoa("customerrequired"));
    ut = ut === null ? ut : atob(ut);
    return ut;
  }
  getcustomerprefix() {
    let ut = localStorage.getItem(btoa("customerprefix"));
    ut = ut === null ? ut : atob(ut);
    return ut;
  }
  getcustomerstart() {
    let ut = localStorage.getItem(btoa("customerstart"));
    ut = ut === null ? ut : atob(ut);
    return ut;
  }
  getemployeerequired() {
    let ut = localStorage.getItem(btoa("employeerequired"));
    ut = ut === null ? ut : atob(ut);
    return ut;
  }
  getemployeeprefix() {
    let ut = localStorage.getItem(btoa("employeeprefix"));
    ut = ut === null ? ut : atob(ut);
    return ut;
  }
  getemployeestart() {
    let ut = localStorage.getItem(btoa("employeestart"));
    ut = ut === null ? ut : atob(ut);
    return ut;
  }
  getaddressrequired() {
    let ut = localStorage.getItem(btoa("addressrequired"));
    ut = ut === null ? ut : atob(ut);
    return ut;
  }
  getaddressprefix() {
    let ut = localStorage.getItem(btoa("addressprefix"));
    ut = ut === null ? ut : atob(ut);
    return ut;
  }
  getdecimalFormat() {
    let ut = localStorage.getItem(btoa("decimalFormat"));
    ut = ut === null ? ut : atob(ut);
    return ut;
  }
 
  getaddressstart() {
    let ut = localStorage.getItem(btoa("addressstart"));
    ut = ut === null ? ut : atob(ut);
    return ut;
  }
  getdateFormat() {
    let ut = localStorage.getItem(btoa("dateFormat"));
    ut = ut === null ? ut : atob(ut);
    return ut;
  }
  getStartTime() {
    let ut = localStorage.getItem(btoa("startTime"));
    ut = ut === null ? ut : atob(ut);
    return ut;
  }
  getEndTime() {
    let ut = localStorage.getItem(btoa("endTime"));
    ut = ut === null ? ut : atob(ut);
    return ut;
  }
  getPermission() {
    let up = localStorage.getItem(btoa('Permission'));
    up = up === null ? up : atob(up);
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(up));
    this.currentUser = this.currentUserSubject.asObservable();
  }
  setPermission(permissionObj: any) {
    localStorage.setItem(btoa('Permission'), btoa(permissionObj));
  }
  getForgotPasswordToken(email: string): Observable<any> {
    var userData = "email=" + email;
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.httpClient.post(`${environment.apiUrl}/accounts/ForgotPassword`, userData, { headers: reqHeader });
  }
  getUserPermission(id: number, userType: string,CmpCode): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let userData = { 'userId': id, 'userType': (userType === 'Client' ? 3 : 2),'CmpCode':CmpCode};
    return this.httpClient.post(`${environment.apiUrl}/admin/GetUserPermission`, userData, { headers: headers });
  }
}
