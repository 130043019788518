import { Injectable, PipeTransform } from "@angular/core";
import { environment } from "src/app/environment";
import { map } from "rxjs/operators";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, BehaviorSubject, Subject, of } from "rxjs";
import { AuthService } from "src/app/services/auth.service";

@Injectable({
  providedIn: "root",
})
export class JobbandService {
  constructor(private http: HttpClient, private authService: AuthService) {}

  getJobBandList(parms): Observable<any> {
    debugger;
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/Admin/GetAllJobBands";
    return this.http
      .post(URL, parms,{headers:headers})
      // .pipe(map((response: Response) => response));
  }

  saveJobBand(parms): Observable<any> {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
   
    const URL = environment.apiUrl + "/Admin/SaveJobBand";
    return this.http
      .post(URL, parms,{headers:headers})
      // .pipe(map((response: Response) => response));
  }

  getJobBand(depId): Observable<any> {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');

    const URL = environment.apiUrl + "/Admin/GetJobBandById?Id=" + depId;
    return this.http.get(URL,{headers:headers})
    // .pipe(map((response: Response) => response));
  }

  deleteJobBand(bandId): Observable<any>{
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/Admin/DeleteJobBand?Id="+bandId;
    return this.http
      .post(URL, {headers:headers})
      // .pipe(map((response: Response) => response));
  }
}
