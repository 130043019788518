export const Permission = {
    CountryView: 'COUNTRY_VIEW',
    CountryAdd: 'COUNTRY_ADD',
    CountryEdit: 'COUNTRY_EDIT',
    CountryDelete: 'COUNTRY_DELETE',
    CountryChangeStatus: 'COUNTRY_CHANGE_STATUS',
    StateView: 'STATE_VIEW',
    StateAdd: 'STATE_ADD',
    StateEdit: 'STATE_EDIT',
    StateDelete: 'STATE_DELETE',
    StateChangeStatus: 'STATE_CHANGE_STATUS',
    CityView: 'CITY_VIEW',
    CityAdd: 'CITY_ADD',
    CityEdit: 'CITY_EDIT',
    CityDelete: 'CITY_DELETE',
    CityChangeStatus: 'CITY_CHANGE_STATUS',
    ZipView: 'ZIPCODE_VIEW',
    ZipAdd: 'ZIPCODE_ADD',
    ZipEdit: 'ZIPCODE_EDIT',
    ZipDelete: 'ZIPCODE_DELETE',
    ZipChangeStatus: 'ZIPCODE_CHANGE_STATUS',
    CURRENCYView: 'CURRENCY_VIEW',
    CURRENCYAdd: 'CURRENCY_ADD',
    CURRENCYEdit: 'CURRENCY_EDIT',
    CURRENCYDelete: 'CURRENCY_DELETE',
    CURRENCYChangeStatus: 'CURRENCY_CHANGE_STATUS',
    BusinessView: 'BUSINESS_VIEW',
    BusinessAdd: 'BUSINESS_ADD',
    BusinessEdit: 'BUSINESS_EDIT',
    BusinessDelete: 'BUSINESS_DELETE',
    BusinessChangeStatus: 'BUSINESS_CHANGE_STATUS',
    languageView: 'LANGUAGE_VIEW',
    LanguageAdd: 'LANGUAGE_ADD',
    LanguageEdit: 'LANGUAGE_EDIT',
    LanguageDelete: 'LANGUAGE_DELETE',
    LanguageChangeStatus: 'LANGUAGE_CHANGE_STATUS',
    KeywordView: 'KEYWORD_VIEW',
    KeywordAdd: 'KEYWORD_ADD',
    KeywordEdit: 'KEYWORD_EDIT',
    KeywordDelete: 'KEYWORD_DELETE',
    LevelView: 'LEVEL_VIEW',
    LevelAdd: 'LEVEL_ADD',
    LevelEdit: 'LEVEL_EDIT',
    LevelDelete: 'LEVEL_DELETE',
    LevelChangeStatus: 'LEVEL_CHANGE_STATUS',
    LevelElementView: 'LEVELELEMENT_VIEW',
    LevelElementAdd: 'LEVELELEMENT_ADD',
    LevelElementEdit: 'LEVELELEMENT_EDIT',
    LevelElementDelete: 'LEVELELEMENT_DELETE',
    LevelElementChangeStatus: 'LEVELELEMENT_CHANGE_STATUS',
    GroupView: 'GROUP_VIEW',
    GroupAdd: 'GROUP_ADD',
    GroupEdit: 'GROUP_EDIT',
    GroupDelete: 'GROUP_DELETE',
    GroupChangeStatus: 'GROUP_CHANGE_STATUS',
    RoleView: 'ROLE_VIEW',
    RoleAdd: 'ROLE_ADD',
    RoleEdit: 'ROLE_EDIT',
    RoleDelete: 'ROLE_DELETE',
    RoleChangeStatus: 'ROLE_CHANGE_STATUS',
    UdfView: 'UDF_VIEW',
    UdfAdd: 'UDF_ADD',
    UdfEdit: 'UDF_EDIT',
    UdfDelete: 'UDF_DELETE',
    UdfChangeStatus: 'UDF_CHANGE_STATUS',
    UserView: 'USER_VIEW',
    UserAdd: 'USER_ADD',
    UserEdit: 'USER_EDIT',
    UserDelete: 'USER_DELETE',
    UserChangeStatus: 'USER_CHANGE_STATUS',
    CustomerView: 'CUSTOMER_VIEW',
    CustomerAdd: 'CUSTOMER_ADD',
    CustomerEdit: 'CUSTOMER_EDIT',
    CustomerDelete: 'CUSTOMER_DELETE',
    CustomerChangeStatus: 'CUSTOMER_CHANGE_STATUS',
    CustomerViewAddress: 'CUSTOMER_VIEW_ADDRESS',
    CustomerAddAddress: 'CUSTOMER_ADD_ADDRESS',
    CustomerEditAddress: 'CUSTOMER_EDIT_ADDRESS',
    CustomerDeleteAddress: 'CUSTOMER_DELETE_ADDRESS',
    CustomerAssignContact: 'CUSTOMER_ASSIGN_CONTACTPERSON',
    CustomerViewContact: 'CUSTOMER_VIEW_CONTACTPERSON',
    ContactPersonView: 'CONTACTPERSON_VIEW',
    ContactPersonAdd: 'CONTACTPERSON_ADD',
    ContactPersonEdit: 'CONTACTPERSON_EDIT',
    ContactPersonDelete: 'CONTACTPERSON_DELETE',
    ContactPersonChangeStatus: 'CONTACTPERSON_CHANGE_STATUS',
    SalesView: 'SALES_VIEW',
    SalesAdd: 'SALES_ADD',
    SalesEdit: 'SALES_EDIT',
    SalesDelete: 'SALES_DELETE',
    SalesChangeStatus: 'SALES_CHANGE_STATUS',
    SalesAssignCustomer: 'SALES_ASSIGN_CUSTOMER',
    SalesViewCustomer: 'SALES_VIEW_CUSTOMER',
    ProjectView: 'PROJECT_VIEW',
    ProjectAdd: 'PROJECT_ADD',
    ProjectEdit: 'PROJECT_EDIT',
    ProjectDelete: 'PROJECT_DELETE',
    ProjectLock: 'PROJECT_LOCK',
    ProjectArchive: 'PROJECT_ARCHIVE',
    ProjectReOpen: 'PROJECT_REOPEN',
    ProjectClose: 'PROJECT_CLOSE',
    ProjectPhaseView: 'PROJECTPHASE_VIEW',
    ProjectPhaseAdd: 'PROJECTPHASE_ADD',
    ProjectPhaseEdit: 'PROJECTPHASE_EDIT',
    ProjectPhaseDelete: 'PROJECTPHASE_DELETE',
    ProjectPhaseLock: 'PROJECTPHASE_LOCK',
    ProjectSubPhaseAdd: 'PROJECTSUBPHASE_ADD',
    ProjectPhaseUpload: 'PROJECTPHASE_UPLOAD_CONTRACT',
    ProjectPhaseCopy: 'PROJECTPHASE_COPY_PROJECT',
    ProjectAssignView: 'PROJECTASSIGN_VIEW',
    ProjectAssignAdd: 'PROJECTASSIGN_ADD',
    ProjectAssignEdit: 'PROJECTASSIGN_EDIT',
    ProjectAssignDelete: 'PROJECTASSIGN_DELETE',
    ProjectAssignChangeStatus: 'PROJECTASSIGN_CHANGE_STATUS',
    ProjectAssignAssigne: 'PROJECTASSIGN_ASSIGNE',
    TimesheetAdd: 'TIMESHEET_ADD',
    TimesheetEdit: 'TIMESHEET_EDIT',
    TimesheetDelete: 'TIMESHEET_DELETE',
    TimesheetCopy: 'TIMESHEET_COPY_LASTWEEK',
    TimesheetDetailAdd: 'TIMESHEET_DETAIL_ADD',
    TimesheetDetailEdit: 'TIMESHEET_DETAIL_EDIT',
    TimesheetDetailDelete: 'TIMESHEET_DETAIL_DELETE',
    TimesheetApproveEdit: 'TIMESHEET_APPROVE_EDIT',
    EditPrimaryManager: 'EDIT_PRIMARY_MANAGER',
	PostApprovalRequest: 'POST_APPROVAL_REQUEST',
	RoadblockAdd: 'ROADBLOCK_ADD',
    RoadblockEdit: 'ROADBLOCK_EDIT',
    RoadblockDelete: 'ROADBLOCK_DELETE',
    RoadblockEditAllAction: 'ROADBLOCK_FULL',
    MoMAdd: 'MEETINGMINUTES_ADD',
    MoMEdit: 'MEETINGMINUTES_EDIT',
    MoMDelete: 'MEETINGMINUTES_DELETE',
    MoMView: 'MEETINGMINUTES_VIEW',
    MoMDownload: 'MEETINGMINUTES_DOWNLOAD',
    MoMEditAllAction: 'MEETINGMINUTES_EDIT_ALL_ACTION',
	MoMEmailSend: 'MEETINGMINUTES_SEND_EMAIL',
	MoMActionReminder: 'MEETINGMINUTES_ACTION_REMINDER',
    AnnAdd: 'ANNOUNCEMENTS_ADD',
    AnnUpdate: 'ANNOUNCEMENTS_UPDATE',
    AnnDelete: 'ANNOUNCEMENTS_DELETE',
    CatManage: 'CATEGORY_MANAGE',
    QlAdd: 'QUICKLINK_ADD',
    QlUpdate: 'QUICKLINK_UPDATE',
    QlDelete: 'QUICKLINK_DELETE',
    LeaveTypeAdd: 'LEAVETYPE_ADD',
    LeaveTypeEdit: 'LEAVETYPE_EDIT',
    LeaveTypeDelete: 'LEAVETYPE_DELETE',
    LeaveTypeView: 'LEAVETYPE_VIEW',
    LeaveTypeStatus: 'LEAVETYPE_STATUS_UPDATE',
    CustomizePolicy: 'CUSTOMIZE_POLICY',
    ViewHistory: 'CUSTOMIZE_POLICY_VIEW_HISTORY',
    LeaveHistoryExport: 'CUSTOMIZE_POLICY_VIEW_HISTORY_EXPORT',
    CustomizeLeaveBal: 'CUSTOMIZE_LEAVE_BALANCE',
    EmailTemplateAdd: 'EMAILTEMPLATE_ADD',
    EmailTemplateEdit: 'EMAILTEMPLATE_EDIT',
    EmailTemplateView: 'EMAILTEMPLATE_VIEW',
    EmailTemplateDelete: 'EMAILTEMPLATE_DELETE',
    EmailTemplateStatus: 'EMAILTEMPLATE_STATUS',
    ApprovalSettingsAdd: 'APPROVALSETTINGS_ADD',
    ApprovalSettingsEdit: 'APPROVALSETTINGS_EDIT',
    ApprovalSettingsView: 'APPROVALSETTINGS_VIEW',
    ApprovalSettingsDelete: 'APPROVALSETTINGS_DELETE',
    ApprovalSettingsStatus: 'APPROVALSETTINGS_STATUS'
};
