import { Component, OnInit, ViewChild } from '@angular/core';
import { LeaveService } from "../../leave.service";
import { CommonService } from "src/app/common.service";
import { AuthService } from "src/app/services/auth.service";
import { UserService } from "src/app/user/user.service";
import { BsDatepickerConfig } from "ngx-bootstrap";
import * as Highcharts from 'highcharts';
declare let html2canvas: any;
import { GridComponent ,Column, ExcelExportProperties} from '@syncfusion/ej2-angular-grids';
import { ColumnModel, ResizeService, QueryCellInfoEventArgs } from '@syncfusion/ej2-angular-grids';


@Component({
  selector: 'app-user-report',
  templateUrl: './user-report.component.html',
  styleUrls: ['./user-report.component.css']
})

export class UserReportComponent implements OnInit {

  reportList: any[];
  emplyeeFields: any;
  userreport: any;
  getVal(): any[] {
    return this.catogries;
  }
  getMap(): any[] {
    return this.datagraph;
  }

  title = 'myHighchart';
  data = [{
    name: 'Balance',
    data: []
  }];

highcharts = Highcharts;
chartOptions = {   
chart: {
 type: "column"
},

title: {
 text: "User Report"
},
xAxis:{
 categories:[]
},
yAxis: {          
 title:{
    text:"Leave Type"
 } 
},
series: this.data
};




  datePickerConfiq: Partial<BsDatepickerConfig>;

  userLoader: boolean;
  currentUserId: any;

  // For User Dropdownlist
  ddlEmployeeId: any;
  EmployeeList: any[];
  ddlEmployeeList: any[];
  ActiveEmployee = [];
  catogries=[];
  datagraph=[];

  filterDayHour: any;
  filterCurrentView: any;

  chartshow=false;
  listshow=true;
  

  selectedLeaveTypeId: any;
  leaveHistoryList: any[];
  leaveHistoryLength: any;
  leaveHistoryPage = 1;
  leaveHistoryPageSize = 10;

  vh_StartDate: Date;
  vh_EndDate: Date;
  minDate: any;
  maxDate: any;
  CmpCode:any;
  public filter: Object;
  public filterSettings: Object;
  public selectionSettings: Object;
  public height: string = '240px';
  public fields: Object = { text: 'text', value: 'value' };
  public item: number[] = [1, 2, 3, 4, 5];
  constructor(
    private _leaveService: LeaveService,
    private _commonSer: CommonService,
    private _authSer: AuthService,
    private userService: UserService,
  ) {
    this.CmpCode = this._authSer.getcompanyCode();
   }

  ngOnInit() {
    this.filterSettings = { type: "Menu" };
    this.filter = { type: "CheckBox" };
    this.emplyeeFields = { value: 'id', text: "text" };
    // this.stTime = performance.now();
    this.selectionSettings = { persistSelection: true, type: "Multiple", checkboxOnly: true };

    this.CmpCode = this._authSer.getcompanyCode();
    this.leaveHistoryList = [];
    this.filterDayHour = 1;
    this.filterCurrentView = 1;
    this._authSer.currentUser.subscribe((res) => {
      this.currentUserId = res.userId;
    });

    this.getAllEmployeList();

    let currentDate = new Date();
    this.vh_StartDate = new Date(currentDate.getFullYear() + "-01-01");
    this.vh_EndDate = currentDate;
  }


  
  public pageSettings = { pageCount: 3 };
  @ViewChild('overviewgrid') public Grid: GridComponent;
  load() {
    const rowHeight: number = this.Grid.getRowHeight();  // height of the each row
    const gridHeight: any = this.Grid.height;  // grid height
    const pageSize: number = this.Grid.pageSettings.pageSize;   // initial page size
    const pageResize: any = (gridHeight - (pageSize * rowHeight)) / rowHeight; // new page size is obtained here
    this.Grid.pageSettings.pageSize = pageSize + Math.round(pageResize);
  }

  
  getCSharpFormattedDate(val: any) {
    if (val === null || val === "") {
      return null;
    }
    const date = new Date(val);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return month + "-" + day + "-" + year;
  }

  setFilterDayHour(value) {
    this.filterDayHour = value;
    this.getUserReport();
  }

  getAllEmployeList() {
    this._leaveService.getEmployeesListForSelect(this.currentUserId,this.CmpCode).subscribe((res) => {
      this.EmployeeList = res['data'].table;
      this.ddlEmployeeList = this._commonSer.SetDDlDropdown(res["data"].table, 'employeeId', 'employeeCodeName');

      const currentEmp = this.EmployeeList.find(x => x.userId == this.currentUserId);
      if (currentEmp !== undefined && currentEmp != null) {
        const empValue = { 'id': currentEmp.employeeId, 'text': currentEmp.employeeCodeName };
        setTimeout(() => {
          this.ddlSelection(empValue, 'Employee');
          this.ddlEmployeeId = currentEmp.employeeId;
          this.getUserReport();
        }, 300);
      }
    }, error => {
      this.userLoader = false;
      console.log(error);
    });
  }

  export(): void {
    debugger
    const excelExportProperties: ExcelExportProperties = {
      fileName: 'user-report.xlsx'
  };
  // this.UpdateForExport();
  //this.getLocationList();
    this.Grid.excelExport(excelExportProperties);
  }

  printList(printData) {
    debugger
    let printContents = document.getElementById(printData).innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    location.reload();
  }

  exportTableToCSV() {
    debugger
    const excelExportProperties: ExcelExportProperties = {
      fileName: 'user-report.csv'
  };
  this.Grid.csvExport(excelExportProperties);

}


  getChart() {
    this.chartshow = true;
    this.listshow = false;
    let data1 = [{
      name: 'Balance',
      data: this.datagraph
    }];
    this.chartOptions = {
      chart: {
        type: "column"
      },
      title: {
        text: "User Report"
      },
      xAxis: {
        categories: this.catogries
      },
      yAxis: {
        title: {
            text: "Leave Type"
        }
      },
      series: data1
    };
  }

  getUserRepo() {
    this.listshow = true;
    this.chartshow = false;
    this.getUserReport();
  }

  getUserReport() {
    debugger
    this.userLoader = true;
    this._leaveService.getUserReport(this.ddlEmployeeId, this.filterDayHour,this.CmpCode).subscribe((res) => {
      this.reportList = res["data"];
      this.userLoader = false;

      this.datagraph = [];
      this.catogries = [];
      for (let value of this.reportList) {
        this.catogries.push(value.leaveTypeName);
        this.datagraph.push(value.balance);
      }
      if (this.chartshow == true) {
        this.listshow = false;
        this.chartshow =true;
        this.getChart();
      }
    }, error => {
      this.userLoader = false;
      console.log(error);
    });
  }


  ddlSelection(value: any, placeHolder: any) {
    debugger
    try {
      switch (placeHolder) {
        case 'Employee':
          this.ActiveEmployee = [];
          this.ActiveEmployee.push(value);
          if(value.id == undefined){
            this.ddlEmployeeId = value.value;
          }
          else{
            this.ddlEmployeeId = value.id;
          }
        

          this.getUserReport();
          break;
      }
    } catch (ex) {

    }
    this.userreport = this.ActiveEmployee[0].id
  }
  dateformat(datas:string){
    return this._authSer.Dateformat(datas);
    // if (this.DateFormat=='1')
    // {
    //   return this.datePipe.transform(datas, 'MM/dd/yyyy')
   
    // }
    // if(this.DateFormat=='2')
    // {
    //   return this.datePipe.transform(datas, 'dd/MM/yyyy')
    // }
    // if(this.DateFormat== '3')
    // {
    //   return this.datePipe.transform(datas, 'yyyy/MM/dd')
    // }
  }

  datepickerStartDate($event1) {
    debugger
    this.vh_StartDate = $event1.value;
    this.minDate = this.vh_StartDate;
    this.minDate.setDate(this.minDate.getDate());
    this.getLeaveHistory(this.selectedLeaveTypeId);
  }

  datepickerEndDate($event1) {
    this.vh_EndDate = $event1.value;
    this.maxDate = this.vh_EndDate;
    this.maxDate.setDate(this.maxDate.getDate());

    this.getLeaveHistory(this.selectedLeaveTypeId);
  }

  getLeaveHistory(leaveTypeId) {
    this.selectedLeaveTypeId = leaveTypeId;
    if (this.vh_StartDate != null && this.vh_EndDate != null &&
      this.ddlEmployeeId > 0 && this.selectedLeaveTypeId > 0) {

      this.userLoader = true;
      let startDate = this.getCSharpFormattedDate(this.vh_StartDate);
      let endDate = this.getCSharpFormattedDate(this.vh_EndDate);
      let parms = {
        EmployeeId: this.ddlEmployeeId,
        LeaveTypeId: leaveTypeId,
        StartDate: startDate,
        EndDate: endDate,
        CmpCode:this.CmpCode
      };

      this._leaveService.getEmployeeLeaveHistory(parms).subscribe(res => {
        this.userLoader = false;
        this.leaveHistoryList = res['data'];
        this.leaveHistoryLength = res['data'].length;
        this.leaveHistoryPage = 1;
        this.leaveHistoryPageSize = 10;
        this._commonSer.OpenModalHandler('historyModal');
      });
    }
  }

  // Export
  ExportSelectedEmployeeHistory() {
    if (this.vh_StartDate != null && this.vh_EndDate != null) {
      this.userLoader = true;
      let startDate = this.getCSharpFormattedDate(this.vh_StartDate);
      let endDate = this.getCSharpFormattedDate(this.vh_EndDate);
      let parms = {
        EmployeeId: this.ddlEmployeeId,
        LeaveTypeId: this.selectedLeaveTypeId,
        StartDate: startDate,
        EndDate: endDate,
        CmpCode:this.CmpCode
      };

      let empNameCode = "";
      const currentEmp = this.EmployeeList.find(x => x.employeeId == this.ddlEmployeeId);
      if (currentEmp !== undefined && currentEmp != null) {
        empNameCode = currentEmp.employeeCodeName;
      }

      this._leaveService.exportEmployeeLeaveHistory(parms).subscribe(res => {
        let exportData = res['data'];

        //const headers = [response.headerstring];//['Name', 'ShortDescription', 'Description', 'CustomerName', 'ProjectType', 'ProjectSource', 'Office', 'PlannedStartDate', 'ActualStartDate', 'PlannedEndDate', 'ActualEndDate', 'PaymentMode', 'ProjectManagerFirstName', 'ProjectManagerMiddleName', 'ProjectManagerLastName', 'SalesPersonFirstName', 'SalesPersonMiddleName', 'SalesPersonLastName', 'ContactPersonFirstName', 'ContactPersonMiddleName', 'ContactPersonLastName', 'WorkingDays', 'InActive', 'IsDeleted'];
        this._commonSer.exportAsExcelFile(exportData, 'LeaveTransaction_' + empNameCode);
        this.userLoader = false;
      }, error => {
        this.userLoader = false;
        console.log(error);
      });
    }
  }

  downloadChart(_type) {
    let vm = this;
    html2canvas(document.querySelector("#dvChart")).then(canvas => {
      let contentType = "image/png";
      let fileName = "User_Report";
      if (_type == 1) {
        contentType = "image/png";
      } else if (_type == 2) {
        contentType = "image/jpeg";
      } else if (_type == 3) {
        contentType = "application/pdf";
      }
      //this.capturedImage = canvas.toDataURL("image/jpeg", 1.0);

      canvas.toBlob(function (blobData) {
        if (_type == 3) {
          vm.userLoader = true;

          let reader = new FileReader();
          reader.readAsDataURL(blobData);
          reader.onloadend = function () {
            let base64data = reader.result;
            let params = {
              base64Data: base64data,
              fileName: 'User Report',
              
          
            };

            try {
              vm._leaveService.getReportPDFFromBase64(params)
                .subscribe((res) => {
                  vm.userLoader = false;
                  let url = URL.createObjectURL(res);
                  let a = document.createElement("a");
                  a.href = url;
                  a.download = "User Report.pdf";
                  a.click();
                });
            } catch (ex) {
              vm.userLoader = false;
              console.log(ex);
            }
          };
        } else {
          if (window.navigator && window.navigator.msSaveOrOpenBlob) { //IE
            window.navigator.msSaveOrOpenBlob(blobData, fileName);
          } else { // chrome
            const blob = new Blob([blobData], { type: contentType });
            const url = window.URL.createObjectURL(blob);
            // window.open(url);
            const link = document.createElement('a');
            link.href = url;
            link.download = fileName;
            link.click();
          }
        }
      });
    });
  }
}
