import { Component, OnInit,ElementRef,ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToasterService } from 'src/app/+layout/expense/toaster/toaster.service';
import { AuthService } from 'src/app/services/auth.service';
import { ExpenseHttpService } from 'src/app/services/expense/expense-http.service';
import { CommonService } from '../../common.service';
import { Language, LanguageTranslation } from '../../models/Language';
import { NgModule } from '@angular/core';
import { DynamicControlService } from 'src/app/dynamic-form/dynamic-control.service';
import { DatePipe } from '@angular/common';
import { environment } from '../../environment';
import { StarRatingColor } from '../star-rating/star-rating.component';
import {
  IPayPalConfig,
  ICreateOrderRequest
} from 'ngx-paypal';

@Component({
  selector: 'app-header-inner',
  templateUrl: './header-inner.component.html',
  styleUrls: ['./header-inner.component.css']
})
export class HeaderInnerComponent implements OnInit {
  UpdateUserDataSubmitted = false;
  UpdateUserDataForm: FormGroup;
  noOfUnits: '';
  rating:number = 3;
  starCount:number = 5;
  starColor:StarRatingColor = StarRatingColor.accent;
  starColorP:StarRatingColor = StarRatingColor.primary;
  starColorW:StarRatingColor = StarRatingColor.warn;
  item: '';
  fields = {text:'val', value:'id'}
  NoOfCustomers: '';
  isItem: boolean = false;
  isNoOfUnits: boolean = false
  SkillLists = [{ 'id': 1, 'text': 'Angular' }, { 'id': 2, 'text': 'React Native' }, { 'id': 3, 'text': 'React' }, { 'id': 4, 'text': 'Django' }, { 'id': 5, 'text': 'Laravel' }];
  activeSkill = []
  public UserName: string = '';
  public UserName1: string = '';
  payPalConfig = {
    currency: 'USD',
    clientId: 'AauZ07HgQ4HxWH3ljJz0duYH03TTTNxZc9-nkVw42-8F4rvMhEpEcA6J0CpW4SiOfX0nOLBoQSAZ8Baf',
    createOrderOnClient: (data) => < ICreateOrderRequest > {
        intent: 'CAPTURE',
        application_context:{payment_method:{payer_selected:'PAYPAL'}},
        purchase_units: [{
            amount: {
                currency_code: 'USD',
                value: this.CompanyDetail[0]?.subcriptionType == '1' ?(((+this.CompanyDetail[0]?.totalMonth)*(this.userAmount?.taxRate/100)+(+this.CompanyDetail[0]?.totalMonth))+''):(((+this.CompanyDetail[0]?.totalYear)*0.18+(+this.CompanyDetail[0]?.totalYear))+''),
                breakdown: {
                    item_total: {
                        currency_code: 'USD',
                        value: this.CompanyDetail[0]?.subcriptionType == '1' ?(((+this.CompanyDetail[0]?.totalMonth)*(this.userAmount?.taxRate/100)+(+this.CompanyDetail[0]?.totalMonth))+''):(((+this.CompanyDetail[0]?.totalYear)*0.18+(+this.CompanyDetail[0]?.totalYear))+'')
                    }
                }
            },
            description:'1',
            items: [{
                name: this.CompanyDetail[0]?.itemPlan,
                quantity: '1',
                category: 'DIGITAL_GOODS',
                unit_amount: {
                    currency_code: 'USD',
                    value: this.CompanyDetail[0]?.subcriptionType == '1' ?(((+this.CompanyDetail[0]?.totalMonth)*(this.userAmount?.taxRate/100)+(+this.CompanyDetail[0]?.totalMonth))+''):(((+this.CompanyDetail[0]?.totalYear)*0.18+(+this.CompanyDetail[0]?.totalYear))+''),
                },
            }]
        }]
    },
    advanced: {
        commit: 'true'
    },
    style: {
        label: 'paypal',
        layout: 'horizontal'
    },
    onApprove: (data, actions) => {
      debugger
        console.log('onApprove - transaction was approved, but not authorized', data, actions);
        actions.order.get().then(details => {
            console.log('onApprove - you can get full order details inside onApprove: ', details);
            this.OnSubscription(details)
        });

    },
    onClientAuthorization: (data) => {
      debugger
      console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);

    },
    onCancel: (data, actions) => {
      debugger
      this.toaster.show('error', 'Transaction Cancelled')
      actions.order.get().then(details => {
        console.log('OnCancel', data, actions);
        this.OnSubscription(details)
    });

    },
    onError: err => {
      debugger
      this.toaster.show('error', 'Something Went Wrong')
      console.log('OnError', err);

    },
    onClick: (data, actions) => {
      console.log('onClick', data, actions);
    }
};
  userAmount = {perUserAmount:1,taxRate:0,perClientUserAmount:1}
  originalUsers = 0
  originalcustomers = 0
  originalMonth = 0
  originalYear = 0
  AddedUsersChange(value){
    debugger
    value = value =='' || value == null?0:value
    this.CompanyDetail[0].noofUsers = (+value)+(+this.originalUsers)
    this.CompanyDetail[0].addedMonth = (+this.userAmount.perUserAmount)*(+value) // added
    this.CompanyDetail[0].addedYear = ((+this.userAmount.perUserAmount)*(+value)) // added
    this.CompanyDetail[0].totalMonth = ((+this.userAmount.perUserAmount)*(+value)+(+this.originalMonth))+((+this.userAmount.perClientUserAmount)*(this.CompanyDetail[0]?.addedCustomer == null?0:+this.CompanyDetail[0]?.addedCustomer))
    this.CompanyDetail[0].totalYear = ((+this.userAmount.perUserAmount*10)*(+value)+(+this.originalYear))+((+this.userAmount.perClientUserAmount*10)*(this.CompanyDetail[0]?.addedCustomer == null?0:+this.CompanyDetail[0]?.addedCustomer))

  }
  AddedUsersCustChange(value){
    debugger
    value = value =='' || value == null?0:value
    this.CompanyDetail[0].noofCustomer = (+value)+(+this.originalcustomers)
    this.CompanyDetail[0].addedMonth = (+this.userAmount.perUserAmount)*(+value) // added
    this.CompanyDetail[0].addedYear = ((+this.userAmount.perUserAmount)*(+value)+this.CompanyDetail[0].addedYear) // added
    this.CompanyDetail[0].totalMonth = ((+this.userAmount.perUserAmount)*(this.CompanyDetail[0].addedUsers == null?0:+this.CompanyDetail[0].addedUsers)+(+this.originalMonth))+((+this.userAmount.perClientUserAmount)*(+value))
    this.CompanyDetail[0].totalYear = ((+this.userAmount.perUserAmount*10)*(this.CompanyDetail[0].addedUsers == null?0:+this.CompanyDetail[0].addedUsers)+(+this.originalYear))+((+this.userAmount.perClientUserAmount*10)*(+value))
  }
  updateCustomers(val){
    debugger
    this.newserice.getUserCost(this.CompanyDetail[0].itemPlan,this.CmpCode).then((res) => {
      this.isNoOfUnits = false
      this.userAmount = res.data.table[0];
      switch (val.target.value) {
        case "Basic Plan":
          this.CompanyDetail[0].noofCustomer = 50
          this.CompanyDetail[0].addedCustomer = 50
          this.CompanyDetail[0].itemPlan = 'Basic Plan'
          this.CompanyDetail[0].noofUsers = 5
          this.CompanyDetail[0].addedUsers = 5
          this.CompanyDetail[0].totalMonth = '3'
          this.CompanyDetail[0].totalYear = '150'
        break;
        case "Business Plan":
          this.CompanyDetail[0].noofCustomer = 75
          this.CompanyDetail[0].addedCustomer = 75
          this.CompanyDetail[0].itemPlan = 'Business Plan'
          this.CompanyDetail[0].noofUsers = 10
          this.CompanyDetail[0].addedUsers = 10
          this.CompanyDetail[0].totalMonth = '5'
          this.CompanyDetail[0].totalYear = '500'
        break;
        case "Enterprise Plan":
          this.CompanyDetail[0].noofCustomer = 100
          this.CompanyDetail[0].addedCustomer = 100
          this.CompanyDetail[0].itemPlan = 'Enterprise Plan'
          this.CompanyDetail[0].noofUsers = 20
          this.CompanyDetail[0].addedUsers = 20
          this.CompanyDetail[0].totalMonth = '7'
          this.CompanyDetail[0].totalYear = '1400'
        break;

        case "Customize Plan":
          this.CompanyDetail[0].noofCustomer = 500
          this.CompanyDetail[0].itemPlan = 'Customize Plan'
          this.CompanyDetail[0].totalMonth = '20'
          this.CompanyDetail[0].noofUsers = 50
          this.CompanyDetail[0].totalYear = '2500'
        break;

        default:
          break;
      }
    }, error => {
      console.log(error);
    })

  }

  public UserEmail: string = '';
  public UserFullName: string = '';
  public Experaince: any = [
    {id:"0", val:"0"},
    {id:"1", val:"1"},
    {id:"2", val:"2"},
    {id:"3", val:"3"},
    {id:"4", val:"4"},
    {id:"5", val:"5"},
    {id:"6", val:"6"},
    {id:"7", val:"7"},
    {id:"8", val:"8"},
    {id:"9", val:"9"},
    {id:"10", val:"10"},
    {id:"11", val:"11"},
    {id:"12", val:"12"},
    {id:"13", val:"13"},
    {id:"14", val:"14"},
    {id:"15", val:"15"},
    {id:"16", val:"16"},
    {id:"17", val:"17"},
    {id:"18", val:"18"},
    {id:"19", val:"19"},
    {id:"20", val:"20"},
    {id:"21", val:"21"},
    {id:"22", val:"22"},
    {id:"23", val:"23"},
    {id:"24", val:"24"},
    {id:"25", val:"25"},
    {id:"26", val:"26"},
    {id:"27", val:"27"},
    {id:"28", val:"28"},
    {id:"29", val:"29"},
    {id:"30", val:"30"},
    {id:"30+", val:"30+"},
  ];
  public ExperainceMonth:any = [
  {id:"0", val:"0"},
  {id:"1", val:"1"},
  {id:"2", val:"2"},
  {id:"3", val:"3"},
  {id:"4", val:"4"},
  {id:"5", val:"5"},
  {id:"6", val:"6"},
  {id:"7", val:"7"},
  {id:"8", val:"8"},
  {id:"9", val:"9"},
  {id:"10", val:"10"},
  {id:"11", val:"11"},
  {id:"12", val:"12"},];
  public ExperainceType:any = []
  public UserId: any;
  roleId: any;
  public language: Language = new Language();
  public languageArray: Array<any> = [];
  public defaultLanguageName: string;
  CompanyDetail: any = [];
  secretKey = ''
  clientId = ''
  tenentId = ''
  scopes = ''
  secretKey1 = ''
  clientId1 = ''
  tenentId1 = ''
  scopes1 = ''
  view = false
  currentURLToken = ''
  googleIntegrated = false;
  microsoftIntegrated = false;
  googleIntegratedAdmin = false;
  microsoftIntegratedAdmin = false;
  Notifications: any = [];
  NotificationLength: any = 0
  Notify: any = [];
  myDate = new Date();
  currentDate: any;
  interval:any;
  Logo:any = '/Uploads/companyLogo/10098_f1b427a9-ecc4-41c7-b649-3982c066eec6_04-07-2022-11-34-16.jpeg';
  userImage:any = '/Uploads/ProfilePicture/10098_0e29f48e-944d-4867-b39d-b474dfc68b3f.jpeg';
  CmpCode:any;
  cmplogo:any;
  constructor(private dynamicCtrlSvc: DynamicControlService,
    private common: CommonService,
    private toaster: ToasterService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    public newserice: CommonService,
    private Http: ExpenseHttpService,
    private datePipe: DatePipe) {


      this.timeformat= this.authService.gettimeformat();

      setInterval(() => {
            if(this.timeformat==1)
            {
              this.now = new Date();
              let date=  this.datePipe.transform(this.now,'EEE, MMM d, y HH:mm');
              this.now= date;
            }else{
              this.now = new Date();
              let date=  this.datePipe.transform(this.now,'EEE, MMM d, y hh:mm a');
              this.now= date;
            }
      }, 10);

      this.CmpCode = this.authService.getcompanyCode();
      this.UserName = this.authService.getUsername();
      this.UserFullName = this.authService.getUserFullName();
      this.UserEmail = this.authService.getUseremail();
      this.UserName1 = this.UserName.substr(0, 1).toUpperCase();
      this.UserId = this.authService.getUserId();
      this.roleId = this.authService.getRoleId();
      this.Logo = this.authService.getLogo();
      this.currentDate = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
      this.cmplogo= `${environment.siteUrl}`+"Uploads/companyLogo/CRG_logo_14-12-2020-14-29-17.png";

  }
  @ViewChild("videoPlayer", { static: false }) videoplayer: ElementRef;
  isPlay: boolean = false;
  toggleVideo() {
    this.videoplayer.nativeElement.play();
  }

  public path: Object = {
    saveUrl: 'https://ej2.syncfusion.com/services/api/uploadbox/Save',
    removeUrl: 'https://ej2.syncfusion.com/services/api/uploadbox/Remove'
  };
  now:any = new Date();
  timeformat:any;
  onRatingChanged(rating){
    console.log(rating);
    this.rating = rating;
  }
  clear(){
    this.clientId1 = ''
    this.secretKey1 = ''
    this.tenentId1 = ''
    this.scopes1 = ''
  }

  startGoogleSync(){
    let obj = {
      IsActive:true,
      Cmpcode:this.authService.getcompanyCode(),
      UserId:this.authService.getUserId(),
    }
    this.Http.getGoogleTokenHeader(obj).then(res =>{
      if(res.success){
        this.currentURLToken = res.data
        setTimeout(() => {
          window.open(res.data,"_blank");
        }, 500);
      }else{
        this.toaster.show('error','Uh Oh, Something Went Wrong')
      }

    }).catch(error =>{
      this.toaster.show('error','Uh Oh, Something Went Wrong')
    })
  }

  startMicrosoftSyc(){
    let obj = {
      IsActive:true,
      Cmpcode:this.authService.getcompanyCode(),
      UserId:this.authService.getUserId(),
    }
    this.Http.getMicrosoftTokenSync(obj).then(res =>{
      if(res.success){
        this.currentURLToken = res.data
        setTimeout(() => {
          window.open(res.data,"_blank");
        }, 500);
        // this.common.OpenModalHandler('exampleModal123')
      }else{
        this.toaster.show('error','Uh Oh, Something Went Wrong')
      }
    }).catch(error =>{
      this.toaster.show('error','Uh Oh, Something Went Wrong')
    })
  }

  saveGOOGLEIntegration(status){
    debugger
    let data = {
      IsActive:status==1?true:false,
      Cmpcode:this.authService.getcompanyCode(),
      userid:this.authService.getUserId(),
      isAdmin:false,
      clientId:this.clientId, // gClient_Id
      secretKey:this.secretKey, // gSecret_Id
      apiKey:this.tenentId, // gApiKeydata.scopes == ''
      scopes:this.scopes // gScopes
    }
    if(data.clientId == '' || data.clientId == null){
      this.toaster.show('warning','Please Enter Client Id')
      return
    }
    if( data.secretKey == '' || data.secretKey == null){
      this.toaster.show('warning','Please Enter Secret Key')
      return
    }
    if(data.apiKey == '' || data.apiKey == null){
      this.toaster.show('warning','Please Enter API Key')
      return
    }
    if( data.scopes == '' || data.scopes == null){
      this.toaster.show('warning','Please Enter Scopes')
      return
    }
    this.Http.ConfiguringGoogle(data).then(res =>{
      if(res.success){
        this.toaster.show('success',res.data)
        this.closeModel('exampleModal1222')
        this.clear();
        let obj = {
          IsActive:status==1?true:false,
          Cmpcode:this.authService.getcompanyCode(),
          UserId:this.authService.getUserId(),
        }
        // this.getMicrosoftKeys();
        // this.getGoogleKeys();
        this.Http.getGoogleTokenHeader(obj).then(res =>{
          if(res.success){
            this.currentURLToken = res.data
            setTimeout(() => {
              window.open(res.data,"_blank");
            }, 500);
            this.common.OpenModalHandler('exampleModal122')
          }else{
            this.toaster.show('error','Uh Oh, Something Went Wrong')
          }

        }).catch(error =>{
          this.toaster.show('error','Uh Oh, Something Went Wrong')
        })
      }else{
        this.toaster.show('error',res.data)
      }

    }).catch(error =>{
      this.toaster.show('error','Uh Oh, Something Went Wrong')
    })
  }

  pauseSync(type){
    let data = {
      IsActive:false,
      Cmpcode:this.authService.getcompanyCode(),
      UserId:this.authService.getUserId(),
      serviceType:type
    }
    this.Http.PauseSync(data).then(res => {
      if(res.success){
        this.toaster.show('success',type+' Syncing paused.');
        // this.getMicrosoftKeys();
        // this.getGoogleKeys();
        window.location.reload();
      }else{
        this.toaster.show('error','Failed to pause '+type+' Sync.');
      }
    }).catch(error =>{
      this.toaster.show('error','Failed to pause '+type+' Sync.');
    })
  }

  saveMICROSOFTIntegration(status){
    let data = {
      IsActive:status==1?true:false,
      Cmpcode:this.authService.getcompanyCode(),
      userid:this.authService.getUserId(),
      isAdmin:false,
      clientId:this.clientId1, //mClient_Id
      secretKey:this.secretKey1, //mSecret_Id
      tenantId:this.tenentId1, //mTenant_Id
      scopes:this.scopes1 //mScopes
    }
    if(data.clientId == '' || data.clientId == null){
      this.toaster.show('warning','Please Enter Client Id')
      return
    }
    if( data.secretKey == '' || data.secretKey == null){
      this.toaster.show('warning','Please Enter Secret Key')
      return
    }
    if(data.tenantId == ''){
      this.toaster.show('warning','Please Enter Tenant Id')
      return
    }
    if( data.scopes == '' || data.scopes == null){
      this.toaster.show('warning','Please Enter Scopes')
      return
    }
    this.Http.ConfiguringMicroSoft(data).then(res =>{
      if(res.success){
        this.toaster.show('success',res.data)
        this.closeModel('exampleModal1232')
        this.clear();
        let obj = {
          IsActive:status==1?true:false,
          Cmpcode:this.authService.getcompanyCode(),
          UserId:this.authService.getUserId(),
        }
        this.getMicrosoftKeys();
        this.getGoogleKeys();
        this.Http.getMicrosoftTokenHeader(obj).then(res =>{
          if(res.success){
            this.currentURLToken = res.data
            setTimeout(() => {
              window.open(res.data,"_blank");
            }, 500);
            this.common.OpenModalHandler('exampleModal123')
          }else{
            this.toaster.show('error','Uh Oh, Something Went Wrong')
          }
        }).catch(error =>{
          this.toaster.show('error','Uh Oh, Something Went Wrong')
        })
      }else{
        this.toaster.show('error',res.data)
      }
    }).catch(error =>{
      this.toaster.show('error','Uh Oh, Something Went Wrong')
    })
  }

  getGoogleKeys(){
    this.clear();
    this.Http.getConfiguringGoogleHeader(this.authService.getcompanyCode(), this.UserId ).then(res => {
      if(res.success){
        this.clientId = res.data.table[0]?.gClient_Id;
        this.secretKey = res.data.table[0]?.gSecret_Id;
        this.tenentId = res.data.table[0]?.gApiKey;
        this.scopes = res.data.table[0]?.gScopes;
        // this.googleIntegrated = res.data.table[0]?.gClient_Id?true:false;
      }
    }).catch(error =>{
      this.toaster.show('error','Failed to load Google integration');
    })



  }

  getMicrosoftKeys(){
    this.clear();
    this.Http.getConfiguringMicroSoftHeader(this.authService.getcompanyCode(), this.UserId).then(res => {
      if(res.success){
        this.clientId1 = res.data.table[0]?.mClient_Id;
        this.secretKey1 = res.data.table[0]?.mSecret_Id;
        this.tenentId1 = res.data.table[0]?.mTenant_Id;
        this.scopes1 = res.data.table[0]?.mScopes;
        // this.microsoftIntegrated = res.data.table[0]?.mClient_Id?true:false;
      }
    }).catch(error =>{
      this.toaster.show('error','Failed to load Microsoft integration');
    })
  }

  ngOnInit() {

    this.getuserImage();
    this.getloginnoti();
    this.getAllLanguages();
    this.GetcompanyDetail();
    // this.getMicrosoftKeys();
    // this.getGoogleKeys();
    this.Http.IsConfigurationsExist(this.authService.getcompanyCode()).then(res => {
      if(res.success){
        this.googleIntegratedAdmin = res.data.table[0].isGoogleConfigured
        this.microsoftIntegratedAdmin = res.data.table1[0].isMicrosoftConfigured
      }else{
        this.toaster.show('error','Uh Oh, Something Went Wrong')
      }
    }).catch(error =>{
      this.toaster.show('error','Uh Oh, Something Went Wrong')
    })

    this.Http.IsConfigurationsEmployeeExist(this.authService.getcompanyCode(), this.UserId).then(res => {
      if(res.success){
        this.googleIntegrated = res.data.table[0].isGoogleConfigured
        this.microsoftIntegrated = res.data.table1[0].isMicrosoftConfigured
      }else{
        this.toaster.show('error','Uh Oh, Something Went Wrong')
      }
    }).catch(error =>{
      this.toaster.show('error','Uh Oh, Something Went Wrong')
    })

    if (this.interval) {
      clearInterval(this.interval);
   }

    this.interval=setInterval(() => {
      this.GetNotifications();
    }, 1200000);

  }
  setRedNotification(notificationId){

    this.Http.setRedNotification(notificationId,this.CmpCode).then((res: any) => {
      if (res.success === true) {
        let data = res.data.table
      }
    })
  }
  setRedAllNotification(){
    this.Http.setRedAllNotification(this.UserId,this.CmpCode).then((res: any) => {
      if (res.success === true) {
        this.Notify = res.data.table;
       // this.toaster.show("warning", "Notification Read Successfully");
        this.Notify = [];
        this.Notifications = [];
        this.NotificationLength = '';

      }
    })
  }
  IsNotification(val, data) {
    this.Notify = data;
    this.Notify.sort(function (a, b) {
      return b.eventDate.localeCompare(a.eventDate);
    });
  }
  ddlSelection(value){
  debugger
   if(!this.ExperainceType.includes(value.text)){
    this.ExperainceType.push(value.text)
   }else{
    let index = this.ExperainceType.indexOf(value.text)
    this.ExperainceType.splice(index,0)
   }

  }
  getuserImage() {
    var userid = this.UserId
    this.Http.GetUserData(userid,this.CmpCode).then((res) => {
      this.userdatalist = res.data.table;
      if(this.userdatalist.length>0){
        this.userImage= res.data.table[0].userImage;
        if(this.userImage!=null || this.userImage!=undefined){
         this.userImage=`${environment.siteUrl}`+this.userImage;
        }
        else{
         this.userImage=`${environment.siteUrl}`+"Uploads/ProfilePicture/DummyProfilePhoto.png";
        }
      }
      else{
        this.userImage=`${environment.siteUrl}`+"Uploads/ProfilePicture/DummyProfilePhoto.png";
       }

    });
  }
  IsStatus(val: any,notificationId) {
    this.setRedNotification(notificationId);
    val.eventstatus = 1;
    let notificationLen = this.Notifications.filter((data) => data.eventstatus === 0)
    this.NotificationLength = notificationLen.length;
    this.Notifications = notificationLen;
  }

  GetNotifications() {

    if(this.roleId === null){
      this.roleId = 1;
    }
    let params = {
      UserId: this.UserId,
      date: this.currentDate,
      roleId: this.roleId,
      CmpCode:this.CmpCode
    }
    this.Http.GetNotifications(params).then((res: any) => {
      if (res.success === true) {
        let data = res.data.table
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          this.Notifications.push(element);
          this.toaster.show("success", element.discription);

        }
        let notificationLen = this.Notifications.filter((data) => data.eventstatus === 0)
        this.NotificationLength = notificationLen.length;
      }
    })
  }
getloginnoti(){

  if(this.roleId === null){
    this.roleId = 1;
  }
  let params = {
    UserId: this.UserId,
    date: this.currentDate,
    roleId: this.roleId,
    CmpCode:this.CmpCode
  }
  this.Http.GetNotificationLogin(params).then((res: any) => {
    if (res.success === true) {
      let data = res.data.table
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        this.Notifications.push(element);
        // setTimeout(() => {this.toaster.show("success", element.discription);},4000)

      }
      let notificationLen = this.Notifications.filter((data) => data.eventstatus === 0)
      this.NotificationLength = notificationLen.length;
    }
  });
}

  urlExpense: any;
  UpdateUserData: any;
  file: any;
  onSelectFile(event) {
    if(event.file==undefined)
    {
      let IsCheckSize = event.target.files[0].size;
      if(IsCheckSize > 2097152){
        this.toaster.show("success", 'Please Select a file smaller than 2MB');
      }else {
        if (event.target.files && event.target.files[0]) {
          this.file = event.target.files[0];
          this.UpdateUserData = this.file;
          //this.UpdateUserDataForm.get("reciptImage").setValue(file);
          // const fileReader: FileReader = new FileReader();
          var reader = new FileReader();
          reader.readAsDataURL(event.target.files[0]); // read file as data url
          reader.onload = (event) => {
            debugger
            this.userImage = event.currentTarget;
            this.userImage = this.userImage.result;
            //console.log(this.userImage);
          };
        }
      };
    }

  }
  subscriptionPlan:any
  GetcompanyDetail() {
    this.Http.GetcompanyDetail(this.CmpCode).then((res) => {
    this.CompanyDetail = res.data.table;
    this.subscriptionPlan=this.CompanyDetail[0].itemPlan
    this.originalUsers = this.CompanyDetail[0].noofUsers
    this.originalcustomers = this.CompanyDetail[0].noofCustomer
    this.originalMonth = this.CompanyDetail[0].totalMonth
    this.originalYear = this.CompanyDetail[0].totalYear
    this.CompanyDetail[0].addedUsers = 0
    this.CompanyDetail[0].addedCustomer = 0
    this.newserice.getUserCost(this.CompanyDetail[0].itemPlan,this.CmpCode).then((res) => {
      this.userAmount = res.data.table[0];
    })
    }, error => {
      console.log(error);
    })

  }

  UpgradeUser() {
    this.GetcompanyDetail();
    this.isItem = !this.isItem;
    this.isNoOfUnits = false
  }

  ChangePlan() {
    this.GetcompanyDetail();
    this.isNoOfUnits = !this.isNoOfUnits;
  }

  OnSubscription(value) {
    let params = {
      companyId: this.CompanyDetail[0].companyId,
      itemPlan: this.CompanyDetail[0].itemPlan,
      noofUsers: this.CompanyDetail[0].noofUsers,
      noofCustomer: this.CompanyDetail[0].noofCustomer,
      totalMonth: this.CompanyDetail[0].totalMonth,
      subcriptionType: this.CompanyDetail[0].subcriptionType,
      totalYear: this.CompanyDetail[0].totalYear,
      CmpCode:this.CmpCode
    }
    this.Http.OnSubscription(params).then((res) => {
      if (res.success === true) {
        // this.toaster.show("success", res.data);
        let obj = {
          Cmpcode: this.CmpCode,
          PaypalResponseJson:value
        }
        this.Http.OnPayDone(obj).then((res) => {
          if (res.success === true) {
            this.toaster.show("success", res.data);
            this.resetForm();
          } else {
            this.toaster.show("warning", res.data);
          }
        })
        this.resetForm();
      } else {
        this.toaster.show("warning", res.data);
      }
    })
  }

  resetForm() {
    const target = "#exampleModal51";
    $(target).hide("slow");
  }

  InitializeForm() {

    this.UpdateUserDataForm = this.formBuilder.group({
      salesPersonId: [""],
      firstName: [''],
      themeId: [''],
      lastName: [''],
      homePhoneNo: [''],
      mobileNo: [''],
      reciptImage: [""]


    });
  }
  signout() {
    this.authService.logout();
  }
  addNewLanguage() {
    if (this.language.displayName.toLowerCase() === 'english') {
      this.language.isDefault = true;
    } else {
      this.language.isDefault = false;
    }
    this.newserice.addNewLanguage(this.language,this.CmpCode).then((res) => {
      this.closeModel('AdminNewLanguage');
    }, error => {
      console.log(error);
    })
  }
  getAllLanguages() {
    this.newserice.getAllLanguages(this.CmpCode).then((res) => {
      this.languageArray = res.data.table;
      for (let i = 0; i < res.data.table.length; i++) {
        if (res.data.table[i].displayName.toLowerCase().trim() === 'english') {
          this.defaultLanguageName = res.data.table[i].displayName;
        }
      }
    }, error => {
      console.log(error);
    })
  }


  async SetDefaultImg(){
    debugger
    // let path =  `${environment.siteUrl}`+"Uploads/ProfilePicture/DummyProfilePhoto.png";
    let path =  "../assets/img/Logo/dummy.png";
    let response = await fetch(path);
    let data = await response.blob();
    let metadata = {
      type: 'image/png'
    };
    this.file = data;
    this.UpdateUserData = this.file;
    let file = new File([data], "test.png", metadata);
    var reader = new FileReader();
    reader.readAsDataURL(file); // read file as data url
    reader.onload = (event) => {
      this.userImage = event.currentTarget;
      this.userImage = this.userImage.result;

    };


  }

  public Sid: any;
  userdatalist: any = [];
  getuserdata() {

    // this.Cid = obj.customerId;
    var userid = this.UserId
    // this.Sid = obj.customerCode;
    // let DocumentJSON = {
    // 'Customerid':0,
    // 'userid':0
    // }
    this.Http.GetUserData(userid,this.CmpCode).then((res) => {
      this.userdatalist = res.data.table;
      if(this.userdatalist.length>0){
        this.userImage= res.data.table[0].userImage;
        if(this.userImage!=null || this.userImage!=undefined){
         this.userImage=`${environment.siteUrl}`+ this.userImage;

        }
        else{
         this.userImage = '';
        //  this.SetDefaultImg()
        }
      }
      else{
        // this.userImage=`${environment.siteUrl}`+"Uploads/ProfilePicture/DummyProfilePhoto.png";
        this.SetDefaultImg()
       }

    });
  }
  //ThemeIdolde:any
  userDatalistArray: any;
  UpdateUserDataSubmit() {
    this.UpdateUserDataSubmitted = true;
    // if (this.UpdateUserDataForm.invalid) {
    //   return;
    // }
    let ThemeIdolde = this.authService.getThemeId();
    this.userDatalistArray = [];
    this.userdatalist.userid = this.UserId;
    let thmid=this.userdatalist[0].themeId;
    const params = {
      firstName: this.userdatalist[0].firstName,
      themeId: this.userdatalist[0].themeId,
      lastName: this.userdatalist[0].lastName,
      officePhoneNo: this.userdatalist[0].workPhone,
      homePhoneNo: this.userdatalist[0].homePhoneNo,
      mobileNo: this.userdatalist[0].mobileNo,
      language: this.userdatalist[0].language,
      userid: this.UserId,
      CmpCode:this.CmpCode
    }
    this.Http.UpdateUserData(params).then((res) => {
      if (res.success) {
        this.authService.setThemeId(thmid);
        if(thmid==ThemeIdolde){
          this.toaster.show("success", "User Profile Updated", res.message);
          setInterval(() => {window.location.reload()}, 2000)
        }
        else{
          this.toaster.show("success", "Theme Updated", res.message);
          setInterval(() => {window.location.reload()}, 2000)
        }
        const formData = new FormData();
        const fd = new FormData();
        //for (var i = 0; i < this.urlExpense.length; i++) {
        fd.append('userImage', this.file);
        //}
        fd.append('userId', this.UserId);
        fd.append('cmpCode', this.CmpCode);
        debugger
        this.Http.uploadempImage(fd).then((res) => {
          if (res.success) {
          setInterval(() => {window.location.reload()}, 3000)

           // this.toaster.show("success", "User image Updated.", res.message);
          } error => {

          }

        });


      } error => {
        console.log(error);
      }
    });
    // }


    this.getuserdata();
    $("#uploadimage").val('');
    var input = $("#uploadimage");
    input.replaceWith(input.val('').clone(true));
    this.closeModel('exampleModal1');
    this.userImage = "";


  }

  closeModel(ModalId) {
    this.newserice.CloseModalHandler(ModalId);
  }
}
