import { CommonModule, DatePipe } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { HttpModule } from "@angular/http";
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LoadingPageModule, MaterialBarModule } from 'angular-loading-page';
import { LayoutModule } from 'angular-admin-lte';
import { NgxZendeskWebwidgetModule, NgxZendeskWebwidgetConfig } from 'ngx-zendesk-webwidget';
import { adminLteConf } from './admin-lte.conf';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { AppComponent } from './app.component';
import { CommonService } from './common.service';
import { JwtInterceptorService } from './services/jwt-interceptor.service';
import { StarRatingComponent } from './core/star-rating/star-rating.component';
import { AuthGuard } from './auth.guard';
import { AuthService } from './services/auth.service';
import { LeaveModule } from "./+layout/leave/leave.module";
import { ToasterContainerComponent } from './+layout/expense/toaster/toaster-container.component';
import { ToasterComponent } from './+layout/expense/toaster/toaster.component';
import {DecimalPipe} from '@angular/common';
import { DropDownListAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { BarRatingModule } from 'ngx-bar-rating';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


// export class ZendeskConfig extends NgxZendeskWebwidgetConfig {
//   timeOut = 2000;
//   lazyLoad = false;
//   accountUrl = 'crgroup.zendesk.com';
//   beforePageLoad(zE) {
//     zE.setLocale('en');
//     zE.hide();
//   }
// }

@NgModule({
  imports: [
    FormsModule, CommonModule, BrowserModule,
    HttpModule, HttpClientModule,
    CoreModule, LoadingPageModule, MaterialBarModule,
    AppRoutingModule,
	  LeaveModule,
    NgxSkeletonLoaderModule,
    LayoutModule.forRoot(adminLteConf),
    // NgxZendeskWebwidgetModule.forRoot(ZendeskConfig),
    DropDownListAllModule,
    BrowserAnimationsModule,
  

  ],
  declarations: [
    AppComponent , ToasterContainerComponent, ToasterComponent,StarRatingComponent
  ],
  providers: [
    DatePipe,
    CommonService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptorService, multi: true },
    AuthService,
    AuthGuard,
    DecimalPipe
  ],
  bootstrap: [AppComponent],

})
export class AppModule {}
