import { FilterPipe } from './search.filter';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [
    FilterPipe 
  ],
  exports: [
    FilterPipe
  ]
})   
export class CommonUtilModule {}