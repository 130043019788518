<div class="bg-overlady" *ngIf="userLoader">
  <div class="loader-position">
    <div class="loader"></div>
    <h2>Loading....</h2>
  </div>
</div> 

<mk-box header="" [isCollapsable]="false" [isRemovable]="false" contentStyleClasses="table-responsive">
  <div class="row" style="margin: 0; border-bottom: 1px solid #ddd; margin-bottom: 10px;">
    <div class="col-sm-4 no-padding">
      <h4 class="title-new">Leave Balance Report</h4>
    </div>

    <div class="col-sm-8 text-right btnset" style="padding-top: 50px;">
      
      <!-- <button class="mx-auto" type="button" id="navbarDropdown" (click)="filterShow()"  role="button" data-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false"><i class="fa fa-filter" aria-hidden="true"></i>
      </button> -->

      <div class="btn-group">
        <button type="button" class="AddLeaveType mx-auto btn btn-primary btn-brand" data-toggle="modal" data-target="#exampleModalfilter2" title="Filters" (click)="filterShow()">
          <img src="../assets/img/Logo/23.png">
        </button>
      </div>

      <!-- Filter and Export -->
      <div class="btn-group">
        <!-- <div class="container datashow rt-filters" *ngIf="flterShowData">
          <div class="card">
            <div class="card card-body">
              <div>
                <div class="row row-bor-bot">                   
                  <div class="col-xs-9">
                    <h4>Filter</h4>
                  </div>
                  <div class="col-xs-3 text-right">
                    <h4 class="icon-close" (click)="closeFilter();">x</h4>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="depart">Period</label>
                      <select class="form-control" name="filterPeriod" [(ngModel)]="filterPeriod"
                        (change)="onChangeFilterPeriod($event);">
                        <option value=1>Yesterday</option>
                        <option value=2>Today</option>
                        <option value=3>Last Week</option>
                        <option value=4>This Week</option>
                        <option value=5>Last Month</option>
                        <option value=6>This Month</option>
                        <option value=7>Custom</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="depart">From</label>
                      <div class="input-group date">
                        <div class="input-group-addon">
                          <i class="fa fa-calendar"></i>
                        </div>
                        <input type="text" class="form-control" name="filterStartDate" [(ngModel)]="filterStartDate"
                          [attr.disabled]="filterPeriod != 7 ? 'true' : null" 
                          placeholder="From" autocomplete="off" bsDatepicker style="margin-top: 0; margin-bottom: 0;"
                          [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', containerClass: 'theme-dark-blue', customTodayClass: 'custom-today-class', showWeekNumbers: false }"
                          autocomplete="off" (bsValueChange)="onDPStartDateChange($event)"
                          bsDatepicker id="dpStartDate" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="depart">To</label>
                      <div class="input-group date">
                        <div class="input-group-addon">
                          <i class="fa fa-calendar"></i>
                        </div>
                        <input type="text" class="form-control" name="filterEndDate" [(ngModel)]="filterEndDate"
                          [attr.disabled]="filterPeriod != 7 ? 'true' : null" 
                          placeholder="From" autocomplete="off" bsDatepicker style="margin-top: 0; margin-bottom: 0;"
                          [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', containerClass: 'theme-dark-blue', customTodayClass: 'custom-today-class', showWeekNumbers: false }"
                          autocomplete="off" (bsValueChange)="onDPEndDateChange($event)"
                          bsDatepicker id="dpEndDate" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="depart">Type</label>
                      <select class="form-control" name="filterType" [(ngModel)]="filterType"
                        (change)="onChangeFilterType($event);">
                        <option value=-1>All Types</option>
                        <option value=1>Paid</option>
                        <option value=2>Unpaid</option>
                        <option value=3>On Duty</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="depart">Leave Type</label>
                      <ng-select [allowClear]="true" [(active)]="ActiveLeaveTypes" [(items)]="ddlLeaveTypeList"
                        (selected)="ddlSelection($event, 'LeaveType')" placeholder="All Leave Types"
                        #ddlFilterLeaveType (opened)="closeDropdownList(ddlFilterLeaveType)"
                        (removed)="removeFilterDropdownValue($event, 'LeaveType')">
                      </ng-select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="depart">Employee</label>
                      <ng-select [allowClear]="true" [(active)]="ActiveEmployees" [(items)]="ddlEmployeeList"
                        (selected)="ddlSelection($event, 'Employee')" placeholder="All Employees"
                        #ddlFilterEmployee (opened)="closeDropdownList(ddlFilterEmployee)"
                        (removed)="removeFilterDropdownValue($event, 'Employee')">
                      </ng-select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="depart">Department</label>
                      <ng-select [allowClear]="true" [(active)]="ActiveDepartments" [(items)]="ddlDepartmentList"
                        (selected)="ddlSelection($event, 'Department')" placeholder="All Department"
                        #ddlFilterDepartments (opened)="closeDropdownList(ddlFilterDepartments)"
                        (removed)="removeFilterDropdownValue($event, 'Department')">
                      </ng-select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="location">Location</label>
                      <ng-select [allowClear]="true" [(active)]="ActiveLocations" [(items)]="ddlLocationList"
                        (selected)="ddlSelection($event, 'Location')" placeholder="All Location"
                        #ddlFilterLocations (opened)="closeDropdownList(ddlFilterLocations)"
                        (removed)="removeFilterDropdownValue($event, 'Location')">
                      </ng-select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="Designation">Designation</label>
                      <ng-select [allowClear]="true" [(active)]="ActiveDesignations" [(items)]="ddlDesignationList"
                        (selected)="ddlSelection($event, 'Designation')" placeholder="All Designation"
                        #ddlFilterDesignations (opened)="closeDropdownList(ddlFilterDesignations)"
                        (removed)="removeFilterDropdownValue($event, 'Designation')">
                      </ng-select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="filterUserStatus">User Status</label>
                      <select class="form-control" name="filterUserStatus" [(ngModel)]="filterUserStatus">
                        <option value=null>All</option>
                        <option value="0">Active</option>
                        <option value="1">Inactive</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">               
                  <div class="col-sm-12">
                    <button type="button" (click)="processFilters()" class="btn btn-primary btn-brand">Search</button>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <button type="button" (click)="resetFilters()" class="btn btn-default">Reset</button>
                  </div>                    
                </div>
              </div>
            </div>
          </div>
        </div> -->

        <div class="dropdown rt-dropdown">
          <label class="col-form-label btn" data-toggle="dropdown"><img src="../assets/img/Logo/22.png" class="fa fa-ellipsis-h" aria-hidden="true"> </label>
          <ul class="dropdown-menu pull-right">
            <!-- <li (click)="ExportExcel()"> Export</li> -->
            <li>
              <a (click)="ExportExcel()">Excel</a>
            </li>
            <li>
              <a (click)="printList('tableData')">Print</a>
            </li>
           
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="row leavebal">
    <div class="col-md-12 no-padding">
      <span (click)="setFilterDayHour(1)" class="filter-links days" [ngClass]="{'active': (filterDayHour == 1)}">Day</span>
      <span (click)="setFilterDayHour(2)" class="filter-links hours" [ngClass]="{'active': (filterDayHour == 2)}">Hour</span>
    </div>
    <div class="col-sm-12 no-padding" *ngIf="checkValue==1">
      <div class="rt-table">
        <ejs-grid #overviewgrid id='overviewgrid'  [dataSource]='newArray' rowHeight='38'  (rowDataBound)='rowDB($event)'  (actionComplete)="actionComplete($event)" [columns]="columns" 
        [allowSorting]='true' height='600' [enableHover]='false' [selectionSettings]='selectionSettings' (queryCellInfo)="customiseCell($event)"
        [filterSettings]='filterSettings' [allowSorting]='true' [allowFiltering]='true' [enableVirtualization]='false' 
        (load)='load()' [allowPaging]='true'   [pageSettings]='pageSettings' [allowExcelExport]='true' [allowPdfExport]="true">
        <e-columns>
            <ng-template #template let-data>
              <e-column field='data.value' headerText='data.key'  clipMode='EllipsisWithTooltip'>
              </e-column>
            </ng-template>
        </e-columns>
      </ejs-grid>
    
      </div>
    </div>
    <div class="col-sm-12 no-padding" *ngIf="checkValue==2">
      <div class="rt-table leaveTakenValue">
        <ejs-grid #overviewgrid id='overviewgrid'  [dataSource]='newArrayList' rowHeight='38' (rowDataBound)='rowDB($event)' (actionComplete)="actionComplete($event)" [allowSelection]='true' [columns]="columns" 
        [allowSorting]='true' height='600' [enableHover]='false' [selectionSettings]='selectionSettings' (queryCellInfo)="customiseCell($event)"
        [filterSettings]='filterSettings' [allowSorting]='true' [allowFiltering]='true' [enableVirtualization]='false' 
        (load)='load()' [allowPaging]='true'   [pageSettings]='pageSettings' [allowExcelExport]='true' [allowPdfExport]="true">
        <e-columns>
            <ng-template #template let-data>
              <e-column field='data.value' headerText='data.key'  clipMode='EllipsisWithTooltip'>
              </e-column>
            </ng-template>
        </e-columns>
      </ejs-grid>
     
      </div>
    </div>
  </div>
</mk-box>





<!-- Modal -->
<div class="modal fade small-filter" id="exampleModalfilter2" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Filters</h5>
        <button type="button" id="saveClick" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="width: 100%; display: inline-block;height: 350px;
      overflow-y: scroll;">
         <div class="container datashow rt-filters no-padding" *ngIf="flterShowData">
          <div class="card">
            <div class="card card-body">
              <div>
                
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="depart">Period</label>
                      <select class="form-control" name="filterPeriod" [(ngModel)]="filterPeriod"
                        (change)="onChangeFilterPeriod($event);">
                        <option value=1>Yesterday</option>
                        <option value=2>Today</option>
                        <option value=3>Last Week</option>
                        <option value=4>This Week</option>
                        <option value=5>Last Month</option>
                        <option value=6>This Month</option>
                        <option value=7>Custom</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="depart">From</label>
                      <div class="input-group date">
                        <div class="input-group-addon">
                          <i class="fa fa-calendar"></i>
                        </div>
                        <input type="text" class="e-input" name="filterStartDate" [(ngModel)]="filterStartDate"
                          [attr.disabled]="filterPeriod != 7 ? 'true' : null" 
                          placeholder="From" autocomplete="off" bsDatepicker style="margin-top: 0; margin-bottom: 0;"
                          [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', containerClass: 'theme-dark-blue', customTodayClass: 'custom-today-class', showWeekNumbers: false }"
                          autocomplete="off" (bsValueChange)="onDPStartDateChange($event)"
                          bsDatepicker id="dpStartDate" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="depart">To</label>
                      <div class="input-group date">
                        <div class="input-group-addon">
                          <i class="fa fa-calendar"></i>
                        </div>
                        <input type="text" class="e-input" name="filterEndDate" [(ngModel)]="filterEndDate"
                          [attr.disabled]="filterPeriod != 7 ? 'true' : null" 
                          placeholder="From" autocomplete="off" bsDatepicker style="margin-top: 0; margin-bottom: 0;"
                          [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', containerClass: 'theme-dark-blue', customTodayClass: 'custom-today-class', showWeekNumbers: false }"
                          autocomplete="off" (bsValueChange)="onDPEndDateChange($event)"
                          bsDatepicker id="dpEndDate" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="depart">Type</label>
                      <select class="form-control" name="filterType" [(ngModel)]="filterType"
                        (change)="onChangeFilterType($event);">
                        <option value=-1>All Types</option>
                        <option value=1>Paid</option>
                        <option value=2>Unpaid</option>
                        <option value=3>On Duty</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="depart">Leave Type</label>
                      <ng-select [allowClear]="true" [(active)]="ActiveLeaveTypes" [(items)]="ddlLeaveTypeList"
                        (selected)="ddlSelection($event, 'LeaveType')" placeholder="All Leave Types"
                        #ddlFilterLeaveType (opened)="closeDropdownList(ddlFilterLeaveType)"
                        (removed)="removeFilterDropdownValue($event, 'LeaveType')">
                      </ng-select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="depart">Employee</label>
                      <ng-select [allowClear]="true" [(active)]="ActiveEmployees" [(items)]="ddlEmployeeList"
                        (selected)="ddlSelection($event, 'Employee')" placeholder="All Employees"
                        #ddlFilterEmployee (opened)="closeDropdownList(ddlFilterEmployee)"
                        (removed)="removeFilterDropdownValue($event, 'Employee')">
                      </ng-select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="depart">Department</label>
                      <ng-select [allowClear]="true" [(active)]="ActiveDepartments" [(items)]="ddlDepartmentList"
                        (selected)="ddlSelection($event, 'Department')" placeholder="All Department"
                        #ddlFilterDepartments (opened)="closeDropdownList(ddlFilterDepartments)"
                        (removed)="removeFilterDropdownValue($event, 'Department')">
                      </ng-select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="location">Location</label>
                      <ng-select [allowClear]="true" [(active)]="ActiveLocations" [(items)]="ddlLocationList"
                        (selected)="ddlSelection($event, 'Location')" placeholder="All Location"
                        #ddlFilterLocations (opened)="closeDropdownList(ddlFilterLocations)"
                        (removed)="removeFilterDropdownValue($event, 'Location')">
                      </ng-select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="Designation">Designation</label>
                      <ng-select [allowClear]="true" [(active)]="ActiveDesignations" [(items)]="ddlDesignationList"
                        (selected)="ddlSelection($event, 'Designation')" placeholder="All Designation"
                        #ddlFilterDesignations (opened)="closeDropdownList(ddlFilterDesignations)"
                        (removed)="removeFilterDropdownValue($event, 'Designation')">
                      </ng-select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="filterUserStatus">User Status</label>
                      <select class="form-control" name="filterUserStatus" [(ngModel)]="filterUserStatus">
                        <option value=null>All</option>
                        <option value="0">Active</option>
                        <option value="1">Inactive</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">               
                  <div class="col-sm-12">
                    <button type="button" (click)="processFilters()" class="btn btn-primary btn-brand">Search</button>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <button type="button" (click)="resetFilters()" class="btn btn-default">Reset</button>
                  </div>                    
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</div>