import {
  Component,
  OnInit,
  Input,
  HostListener,
  ElementRef,
  ViewChildren,
  QueryList,
  ViewChild
} from "@angular/core";

import { LeaveService } from "../../leave.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BsDatepickerConfig } from "ngx-bootstrap";
import { LeaveTypeModel } from "../../leave-model/leave-type";
import { CommonService } from "src/app/common.service";
import { UserService } from "src/app/user/user.service";
import { DepartmentService } from "src/app/+layout/department/department.service";
import { LocationService } from "src/app/+layout/location/location.service";
import { DesignationService } from "src/app/+layout/designation/designation.service";
import { AuthService } from "src/app/services/auth.service";
import { environment } from "src/app/environment";
import { Permission } from 'src/app/permission-keyword';
import { connectableObservableDescriptor } from "rxjs/internal/observable/ConnectableObservable";
import { SelectComponent } from "ng2-select/ng2-select";
import { GridComponent } from "@syncfusion/ej2-angular-grids";
import { ToasterService } from "src/app/+layout/expense/toaster/toaster.service";

class LeaveTypeData {
  LeaveTypeId: number;
  LType: number;
  Unit: number;
  ImagePath: string;
  IsAccural: Boolean;
  AccuralPeriod: number;
  AccuralDay: number;
  AccuralMonth: number;
  AccuralNoOfDays: number;
  AccuralDaysInPeriod: number;
  IsReset: Boolean;
  ResetPeriod: number;
  ResetDay: number;
  ResetMonth: number;
  CarryForwardType: number;
  CarryForwardPercentageUnit: number;
  EncashmentPercentageUnit: number;
  CanCountAsLeaveInWeekendBetweenLeave: Boolean;
  CanCountAsHolidayInWeekendBetweenLeave: Boolean;
  EntitlementCalendarList: any;
}

@Component({
  selector: "app-leave-type",
  templateUrl: "./leave-type.component.html",
  styleUrls: ["./leave-type.component.css"],
})
export class LeaveTypeComponent implements OnInit {
  public text: String;

  private wasInside = false;
  fieldEmpArray = []

  @Input() makeDifferent;

  // @HostListener('click')
  // clickInside() {
  //   this.text = "clicked inside";
  //   this.wasInside = true;
  //   this.flterShowData = true
  // }

  // @HostListener('document:click')
  // clickout() {
  //   if (!this.wasInside) {
  //     this.text = "clicked outside";
  //     this.flterShowData = false
  //   }
  //   this.wasInside = false;
  // }
  datePickerConfiq: Partial<BsDatepickerConfig>;
  allLeaveTypeList = [];
  userLoader: boolean;
  leaveTypeForm: FormGroup;
  showSuccess: any;
  showMessage: any;
  editMode = false;
  viewMode = false;
  NeweditMode = true;
  NewviewMode = true;
  flterShowData = false;
  // DropDown List Data of Leave Type For selection start
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};

  effictiveYears = [
    { name: "Day(s)", value: 3 },
    { name: "Month(s)", value: 2 },
    { name: "Year(s)", value: 1 },
  
  ];
  effictiveYearsfield = {value:"value", text: "name" }

  ProrateAccuralTypedata = [
    { name: "Start of Policy", value: 1 },
    { name: "Start and End of Policy", value: 2 },
    { name: "Do not Prorate", value: 3 },
  ];
  ProrateAccuralTypefield = {value:"value", text: "name" }

  DeductibleHolidaysdata = [
    { name: "All Holidays", value: 1 },
    { name: "Holidays on Workdays", value: 2 },
  ];
  DeductibleHolidaysfield = {value:"value", text: "name" }

  EncashmentPercentageUnitdata = [
    { name: "Percentage", value: 1 },
    { name: "Unit(s)", value: 2 },
  ];
  EncashmentPercentageUnitfield = {value:"value", text: "name" }

  CarryForwardType = [
    { name: "Carry Forward", value: 1 },
    { name: "Carry Forward With Expiry", value: 2 },
    { name: "Carry Forward With Overall Limit", value: 3 }
  ];
  CarryForwardTypefield = {value:"value", text: "name" }

  CarryForwardPercentage = [
    { name: "Percentage", value: 1 },
    { name: "Unit(s)", value: 2 },
  ];
  CarryForwardPercentagefield = {value:"value", text: "name" }

  CarryForwardExpiresin = [
    { name: "Month(s)", value: 1 },
    { name: "Day(s)", value: 2 },
  ];
  CarryForwardExpiresinfield = {value:"value", text: "name" }

  AccuralDaysInPeriod = [
    { name: "Current Accrual", value: 1 },
    { name: "Next Accrual", value: 2 },
  ];
  AccuralDaysInPeriodfield = {value:"value", text: "name" }

  effictiveAfterfrom = [
    { name: "Date of Joining", value: 1 },
    { name: "Date of Confirmation", value: 2 }
    
  ];
  effictiveAfterfromfield = {value:"value", text: "name" }

  AccuralPeriod = [
    { name: "One Time", value: 1 },
    { name: "Yearly", value: 2 },
    { name: "Monthly", value: 3 },
    { name: "Half Yearly", value: 4 },
    { name: "Triannually", value: 5 },
    { name: "Quarterly", value: 6 },
    { name: "Bi-Monthly", value: 7 },
    { name: "Semi Monthly", value: 8 },
    { name: "Bi-Weekly", value: 9 },
    { name: "Weekly", value: 10 },
  ];
  AccuralPeriodfield = {value:"value", text: "name" }
 

  ResetPeriod = [
    { name: "One Time", value: 1 },
    { name: "Yearly", value: 2 },
    { name: "Monthly", value: 3 },
    { name: "Half Yearly", value: 4 },
    { name: "Triannually", value: 5 },
    { name: "Quarterly", value: 6 },
    { name: "Bi-Monthly", value: 7 },
    { name: "Semi Monthly", value: 8 },
    { name: "Bi-Weekly", value: 9 },
    { name: "Weekly", value: 10 },
  ];
  ResetPeriodfield = {value:"value", text: "name" }

  AllowUserstToView = [
    { name: "Complete Leave Summary", value: 1 },
    { name: "Leave Taken Alone", value: 2 },
    { name: "Simple Leave Summary", value: 3 },
  ];

  AllowUserstToViewfield = { value: "value", text: "name" }

  BalanceToBedisplayed  = [
    { name: "Current Balance", value: 1 },
    { name: "Accural Period Balance", value: 2 },
  ];
  BalanceToBedisplayedfield = { value: "value", text: "name" }

  Week  = [
    { name: "Week", value: 1 },
    { name: "Month", value: 2 },
    { name: "Year", value: 3 },
    { name: "Accrual Period", value: 4 },
    { name: "Job Tenure", value: 5 },
  ];
  Weekfield = { value: "value", text: "name" }

  minDate: any;
  startDate: any;
  endDate: any;
  public RoleItemList: any;
  AccuralDayList = [];
  itemList = [];
  // selectedItems = [];
  settings = {};
  MonthList = [
    { value: 1, name: "Jan" },
    { value: 2, name: "Feb" },
    { value: 3, name: "Mar" },
    { value: 4, name: "April" },
    { value: 5, name: "May" },
    { value: 6, name: "Jun" },
    { value: 7, name: "Jul" },
    { value: 8, name: "Aug" },
    { value: 9, name: "Sep" },
    { value: 10, name: "Oct" },
    { value: 11, name: "Nov" },
    { value: 12, name: "Dec" },
    // Extra values
    { value: 20, name: "Policy Month" },
    { value: 21, name: "Joining Month" },
    { value: 22, name: "Birth Month" }
  ];
  RoleCollection: any[];
  DepartmentCollection: any[];

  public RoleDeleteCollection: Array<any> = [];

  public activeRoleList: Array<object> = [];
  dayHrFilterValue: any;
  allEmployeeList: any;
  allDepartmentList: any;
  allLocationList: any;
  allDesigationList: any;
  departmentSelectValue = null;
  statusSelectValue = true;
  employeeSelectedValue = null;
  locationSelectedValue = null;
  desigationSelectedValue = null;
  roleSelectedValue = null;
  // DropDown List Data of Leave Type For selection end
  myRequestsListLength: any;
  myRequestsPage = 1;
  myRequestsPageSize = 10;
  submitted: Boolean = false;
  allRolesList: any;

  //radio buttons values
  canExceedLeaveBalance = false;

  // check Box var
  IsAccuralChange = false;
  isResetChange = false;
  isProPrateChange = false;
  isRequestAllowFor = false;
  isFuturesDate = false;
  isMinimumApplied = false;

  isMAximumConsectiveHoursAllow = false;
  isMinimumGapInDay = false;
  isMaximumAppliedLeaved = false;
  isEditMode = false;
  IsExceedLeaveChange = false;
  dontExceedLeaveChange = false;
  enableFileUploadOption = false;

  LeaveTypeUnit = 1;
  selectedType = null;
  selectedEfficetiveHours = null;
  selectEfficetiveFrom = null;
  selectedExceedLeaveAllow = true;
  todatlEmployeeList: any;
  employeName: any;
  employeCode: any;
  ActiveEmployee: any[];
  ddlEmployeeUserId: any;
  EmployeeList: any[];
  currentUserId: any;
  DepartmentList: any[];
  ActiveDepartment: any[];
  LocationList: any[];
  todatlLocationList: any;
  ActiveLocation: any[];
  ActiveDesigation: any[];
  DesigationList: any[];
  ActiveRoles: any[];
  RolesList: any[];
  ddlRolesId: any;
  ddlDepartmentId: any;
  ddlLocationId: any;
  ddlDesigationId: any;
  DesigationCollection: any[];
  LocationCollection: any[];
  EmployeeCollection: any[];
  appliedLeaveList: any;
  AppliedList: any[];
  AppliedLeaveCollection: any[];
  IsDelete: boolean;
  deleteId: any;

  // Applicable Add Field related Data
  EmployeeTypesList: any[];
  SourceOfHireList: any[];
  JobBandsList: any[];
  OnboardingStatusList: any[];

  IsEmployeeTypes: boolean = false;
  IsSourceOfHire: boolean = false;
  IsJobBands: boolean = false;
  IsOnboardingStatus: boolean = false;

  HeaderActionMsg =  environment.AddButton_text;
  LeaveTypeCollection: any[];
  LeaveTypeList: any[];
  ActiveLeaveType: any[];
  getdepratmentValue: any[];

  // For Filters
  filterStatus: any;
  filterDepartmentId: any;
  filterDesignationId: any;
  filterLocationId: any;
  filterRoleId: any;
  filterEmployeeId: any;

  private isClickOutside = false;

  // Applicable DDL Lists
  public applicableDDLDepartmentList = [];
  public applicableDDLDesigationList = [];
  public applicableDDLLocationList = [];
  public applicableDDLRoleList = [];

  // Exception DDL Lists
  public exceptionDDLDepartmentList = [];
  public exceptionDDLDesigationList = [];
  public exceptionDDLLocationList = [];
  public exceptionDDLRoleList = [];

  // Multi select dropdowns temp data
  public applicableDepartmentList = [];
  public applicableDesigationList = [];
  public applicableLocationList = [];
  public applicableRoleList = [];

  public applicableEmployeeTypesList = [];
  public applicableSourceOfHireList = [];
  public applicableJobBandsList = [];
  public applicableOnboardingStatusList = [];

  public applicableEmployeeList = [];

  public exceptionDepartmentList = [];
  public exceptionDesigationList = [];
  public exceptionLocationList = [];
  public exceptionRoleList = [];

  public canAppliedOnlyOnList = [];
  public cannotTakenTogetherWithList = [];
  selectedFile: any;

  uploadedFile: File;
  uploadedFileByteData: any;

  // New DropdownLists
  ddlAccuralDaysList = [];
  ddlAccuralMonthsList = [];

  ddlResetDaysList = [];
  ddlResetMonthsList = [];


  // Entitlements More Options Related Data
  IsProrateAccuralEnable: false;
  IsOpeningBalanceEnable: false;
  IsMaximumBalanceEnable: false;
  IsDeductibleHolidaysEnable: false;

  // Prorate Accural model
  prorateAccuralType: string;
  prorateAccural: any;
  public filter: Object;
  public filterSettings: Object;
  public selectionSettings: Object;
  public height: string = '240px';
  public fields: Object = { text: 'text', value: 'value' };
  leavtypefields: Object = {text: 'text', value: 'val' };
  exceptioUserfields: Object = {text: 'text', value: 'id' }
  
  public item: number[] = [1, 2, 3, 4, 5];
  roundOffValueSelectList = [
    { value: 1, name: "Nearest" },
    { value: 2, name: "Minimum" },
    { value: 3, name: "Maximum" }
  ];
  roundOffTypeSelectList = [
    { value: 1, name: "Day" },
    { value: 2, name: "Half Day" },
    { value: 3, name: "Quarter Day" }
  ];
  prorateAccuralDayList = [];

  tempLeaveType: LeaveTypeData = new LeaveTypeData();

  isCompOffExists: Boolean = false;

  @ViewChildren(SelectComponent) selectElements: QueryList<any>;
  //Permissions

  AddPermission: boolean;
  EditPermission: boolean;
  DeletePermission: boolean;
  ViewPermission: boolean;
  StatusPermission: boolean;
//
InActiveLength:any=[];
ActiveLength:any=[];
CmpCode:any;
public data = [ { val: 1, text: 'Paid' },
{ val: 2, text: 'Unpaid' }, { val: 3, text: 'On Duty' }, { val: 4, text: 'Restricted Holiday' },
{ val: 5, text: 'Compensatory Off' }
];
// maps the appropriate column to fields property
public gamefields: Object = { text: 'Game', value: 'Id' };
  Typeleave: any;
  LocalDataPermission: any;
  CustomLeaveCode: any;
  customerno: any;
  leaveTyperequestCode: any;
  LeaveTypeenable: boolean = true;
  ddlAccuralDaysListfield: { value: string; text: string; };
  ddlAccuralMonthsListfield: { value: string; text: string; };
  ddlResetDaysListfield: {};
  ddlResetMonthsListfield: { value: string; text: string; };
  ValidityFrom: any;
  ValidityTo: any;
  canapplyon: any;
  CannotTakenTogether: any;

  constructor(
    private _LeaveServices: LeaveService,
    private _fb: FormBuilder,
    private toaster: ToasterService,
    private _commonSer: CommonService,
    private userService: UserService,
    private _depratmentSer: DepartmentService,
    private _locationSer: LocationService,
    private _desigation: DesignationService,
    private _authSer: AuthService
  ) {
    this.CmpCode = this._authSer.getcompanyCode();
    this.datePickerConfiq = Object.assign(
      {},
      {
        showWeekNumbers: true,
      }
    );
    this.RoleItemList = [];
    this.text = "no clicks yet";
  }

  ngOnInit() {// Angular lifecycle hook
    this.filterSettings = { type: "Menu" };
    this.filter = { type: "CheckBox" };
    // this.stTime = performance.now();
    this.selectionSettings = { persistSelection: true, type: "Multiple", checkboxOnly: true };

    this.CmpCode = this._authSer.getcompanyCode();
    this._authSer.IsPermissions('Custome Leave Type');
    this.LocalDataPermission = this._authSer.LocalDataPermission;
    if (this.LocalDataPermission === '' || this.LocalDataPermission === null || this.LocalDataPermission === undefined) {
      this.LocalDataPermission[0].addPermission = 0;
      this.LocalDataPermission[0].editPermission = 0;
      this.LocalDataPermission[0].viewpermission = 0;
      this.LocalDataPermission[0].deletePermission = 0;
    }
    try {
      this.ViewPermission = this.LocalDataPermission[0].viewpermission;//this.CurrentUser.permission.includes(Permission.ProjectView);
      this.AddPermission = this.LocalDataPermission[0].addPermission;//this.CurrentUser.permission.includes(Permission.ProjectAdd);
      this.EditPermission = this.LocalDataPermission[0].editPermission;// this.CurrentUser.permission.includes(Permission.ProjectEdit);
      this.DeletePermission = this.LocalDataPermission[0].deletePermission;// this.CurrentUser.permission.includes(Permission.ProjectDelete);
      this.StatusPermission = this.LocalDataPermission[0].addPermission;
    } catch (ex) {

    }
    // this._authSer.currentUser.subscribe((res) => {
    //   this.currentUserId = res.userId;
    //   this.AddPermission = res.permission.includes(Permission.LeaveTypeAdd);
    //   this.EditPermission = res.permission.includes(Permission.LeaveTypeEdit);
    //   this.DeletePermission = res.permission.includes(Permission.LeaveTypeDelete);
    //   this.ViewPermission = res.permission.includes(Permission.LeaveTypeView);
    //   this.StatusPermission = res.permission.includes(Permission.LeaveTypeStatus);
    //   // this.applyLeaveSelectedUserId = res.userId;
    //   this.ddlEmployeeUserId = res.userId;
    // });
    this.dayHrFilterValue = 1;
    this.ValidityFrom = new Date();
    this.ValidityTo = new Date();
    // this.getAllLeaveTypeForSelect()
    this.leaveTypeForm = this._fb.group({
      LeaveTypeId: [0],
      LeaveTypeName: ["", Validators.required],
      LeaveTypeCode: ["", Validators.required],
      ColorCode: [""],
      ImagePath: ["assets/LeaveIcons/Icon_1.png"],
      Type: [1, Validators.required],
      Unit: [1, Validators.required],
      Description: [""],
      ValidityFrom: ["", Validators.required],
      ValidityTo: [""],
      IsActive: [true],
      CreatedBy: [""],
      CreatedDate: [""],
      UpdatedBy: [""],
      UpdatedDate: [""],
      EffectiveAfter: [0],
      EffectiveAfterPeriod: [1], 
      EffectiveAfterFrom: [1],
      IsAccural: [true],
      AccuralPeriod: [2],
      AccuralDay: [1],
      AccuralMonth: [1],
      AccuralNoOfDays: 0,
      AccuralDaysInPeriod: [1],
      IsReset: [true],
      ResetPeriod: [2],
      ResetDay: [71],
      ResetMonth: [12],
      CarryForwardType: [1],
      CarryForwardValue: [0],
      CarryForwardPercentageUnit: [1],
      CarryForwardMaxLimit: [],
      CarryForwardExpiresIn: [],
      CarryForwardExpiresInType: [],
      CarryForwardOverallLimit: [],
      EncashmentType: [],
      EncashmentValue: [0],
      EncashmentPercentageUnit: [1],
      EncashmentMaxLimit: [],

      IsProrateAccuralEnable: [false],
      ProrateAccuralType: [],
      IsOpeningBalanceEnable: [false],
      OpeningBalance: [],
      IsMaximumBalanceEnable: [false],
      MaximumBalance: [],
      IsDeductibleHolidaysEnable: [false],
      DeductibleHolidays: [],

      ApplicableGenderMale: [false],
      ApplicableGenderFemale: [false],
      ApplicableGenderOthers: [false],
      ApplicableMaritalStatusSingle: [false],
      ApplicableMaritalStatusMarried: [false],
      ApplicableDepartments: [[]],
      ApplicableDesignations: [[]],
      ApplicableLocations: [[]],
      ApplicableRoles: [[]],
      ApplicableEmployeeTypes: [[]],
      ApplicableSourceOfHire: [[]],
      ApplicableJobBands: [[]],
      ApplicableOnboardingStatus: [[]],
      ApplicableEmployees: [[]],
      ExceptionDepartments: [[]],
      ExceptionDesignations: [[]],
      ExceptionLocations: [[]],
      ExceptionRoles: [[]],
      customeleavesrno: "0",
      CmpCode: "",
      CanCountAsLeaveInWeekendBetweenLeave: [false],
      WeekendBetweenLeaveCountAfter: [],
      CanCountAsHolidayInWeekendBetweenLeave: [false],
      WeekendBetweenHolidayCountAfter: [],
      CanExceedLeaveBalance: [false],
      ExceedLeaveBalanceType: ["1"],
      IsDurationFullDay: [true],
      IsDurationHalfDay: [false],
      IsDurationQuarterDay: [false],
      ReportConfigAllowView: [],
      ReportConfigBalanceDisplay: [],
      AllowRequestsForPastDates: [false],
      AllowRequestsForPastDays: [],
      AllowRequestsForFutureDates: [false],
      AllowRequestsForNextDays: [],
      AllowRequestsDaysinAdvance: [],
      IsMinimumLimitPerApplication: [false],
      MinimumLimitPerApplication: [],
      IsMaximumLimitPerApplication: [false],
      MaximumLimitPerApplication: [],
      IsMaximumConsecutiveHoursAllowed: [false],
      MaximumConsecutiveHoursAllowed: [],
      IsMinimumGapBetweenTwoApplications: [false],
      MinimumGapBetweenTwoApplications: [],
      EnableFileUploadOption: [false],
      OnAppliedLeavePeriodExceeds: [],
      MaximumApplicationsAllowed: [],
      MaximumApplicationsAllowedType: [1],
      CanAppliedOnlyOn: [[]],
      CannotTakenTogetherWith: [[]],
      EntitlementCalendarList: [[]],
      LeaveTypeProrateAccural: []
    });

    this.setTempLeaveTypeData();

    this.initAccuralDDL();
    this.getAllLeaveTypeData();
    this.getDropDownListData();
  }


  
  public pageSettings = { pageCount: 3 };
  @ViewChild('overviewgrid') public Grid: GridComponent;
  
  load() {// Created By A Initialize grid properties
    const rowHeight: number = this.Grid.getRowHeight();  // height of the each row
    const gridHeight: any = this.Grid.height;  // grid height
    const pageSize: number = this.Grid.pageSettings.pageSize;   // initial page size
    const pageResize: any = (gridHeight - (pageSize * rowHeight)) / rowHeight; // new page size is obtained here
    this.Grid.pageSettings.pageSize = pageSize + Math.round(pageResize);
  }
 
  
  @HostListener("click")
  clickInside() {// Created By A Set values
    this.isClickOutside = true;
  }

  resetLeaveTyeForm() {// Created By A Forms Initialization
    this.leaveTypeForm = this._fb.group({
      LeaveTypeId: [0],
      LeaveTypeName: ["", Validators.required],
      LeaveTypeCode: ["", Validators.required],
      ColorCode: [""],
      ImagePath: ["assets/LeaveIcons/Icon_1.png"],
      Type: [1, Validators.required],
      Unit: [1, Validators.required],
      Description: [""],
      ValidityFrom: ["", Validators.required],
      ValidityTo: [""],
      IsActive: [true],
      CreatedBy: [""],
      CmpCode: "",
      CreatedDate: [""],
      UpdatedBy: [""],
      UpdatedDate: [""],
      EffectiveAfter: [0],
      EffectiveAfterPeriod: [1],
      EffectiveAfterFrom: [1],
      IsAccural: [true],
      AccuralPeriod: [2],
      AccuralDay: [1],
      AccuralMonth: [1],
      AccuralNoOfDays: [0],
      AccuralDaysInPeriod: [1],
      IsReset: [true],
      ResetPeriod: [2],
      ResetDay: [71],
      ResetMonth: [12],
      customeleavesrno: "0",
      CarryForwardType: [1],
      CarryForwardValue: [0],
      CarryForwardPercentageUnit: [1],
      CarryForwardMaxLimit: [],
      CarryForwardExpiresIn: [],
      CarryForwardExpiresInType: [],
      CarryForwardOverallLimit: [],
      EncashmentType: [],
      EncashmentValue: [0],
      EncashmentPercentageUnit: [1],
      EncashmentMaxLimit: [],

      IsProrateAccuralEnable: [false],
      ProrateAccuralType: [],
      IsOpeningBalanceEnable: [false],
      OpeningBalance: [],
      IsMaximumBalanceEnable: [false],
      MaximumBalance: [],
      IsDeductibleHolidaysEnable: [false],
      DeductibleHolidays: [],

      ApplicableGenderMale: [false],
      ApplicableGenderFemale: [false],
      ApplicableGenderOthers: [false],
      ApplicableMaritalStatusSingle: [false],
      ApplicableMaritalStatusMarried: [false],
      ApplicableDepartments: [[]],
      ApplicableDesignations: [[]],
      ApplicableLocations: [[]],
      ApplicableRoles: [[]],
      ApplicableEmployeeTypes: [[]],
      ApplicableSourceOfHire: [[]],
      ApplicableJobBands: [[]],
      ApplicableOnboardingStatus: [[]],
      ApplicableEmployees: [[]],
      ExceptionDepartments: [[]],
      ExceptionDesignations: [[]],
      ExceptionLocations: [[]],
      ExceptionRoles: [[]],

      CanCountAsLeaveInWeekendBetweenLeave: [false],
      WeekendBetweenLeaveCountAfter: [],
      CanCountAsHolidayInWeekendBetweenLeave: [false],
      WeekendBetweenHolidayCountAfter: [],
      CanExceedLeaveBalance: [false],
      ExceedLeaveBalanceType: ["1"],
      IsDurationFullDay: [true],
      IsDurationHalfDay: [false],
      IsDurationQuarterDay: [false],
      ReportConfigAllowView: [],
      ReportConfigBalanceDisplay: [],
      AllowRequestsForPastDates: [false],
      AllowRequestsForPastDays: [],
      AllowRequestsForFutureDates: [false],
      AllowRequestsForNextDays: [],
      AllowRequestsDaysinAdvance: [],
      IsMinimumLimitPerApplication: [false],
      MinimumLimitPerApplication: [],
      IsMaximumLimitPerApplication: [false],
      MaximumLimitPerApplication: [],
      IsMaximumConsecutiveHoursAllowed: [false],
      MaximumConsecutiveHoursAllowed: [],
      IsMinimumGapBetweenTwoApplications: [false],
      MinimumGapBetweenTwoApplications: [],
      EnableFileUploadOption: [false],
      OnAppliedLeavePeriodExceeds: [],
      MaximumApplicationsAllowed: [],
      MaximumApplicationsAllowedType: [1],
      CanAppliedOnlyOn: [[]],
      CannotTakenTogetherWith: [[]],
      EntitlementCalendarList: [[]],
      LeaveTypeProrateAccural: []
    });
    this.setTempLeaveTypeData();

    // Applicable DDL Lists
    this.applicableDDLDepartmentList = this.DepartmentList;
    this.applicableDDLDesigationList = this.DesigationList;
    this.applicableDDLLocationList = this.LocationList;
    this.applicableDDLRoleList = this.RoleItemList;

    // Exception DDL Lists
    this.exceptionDDLDepartmentList = this.DepartmentList;
    this.exceptionDDLDesigationList = this.DesigationList;
    this.exceptionDDLLocationList = this.LocationList;
    this.exceptionDDLRoleList = this.RoleItemList;

    console.log("Department", this.applicableDDLDepartmentList);
    console.log("Desigation", this.applicableDDLDesigationList);
    console.log("Location", this.applicableDDLLocationList);
    console.log("Role", this.applicableDDLRoleList);
  }

  setTempLeaveTypeData() {// Created By A Set values
    this.tempLeaveType = new LeaveTypeData();

    this.tempLeaveType.LeaveTypeId = 0;
    this.tempLeaveType.LType = 1;
    this.tempLeaveType.Unit = 1;
    this.tempLeaveType.ImagePath = "assets/LeaveIcons/Icon_1.png";
    this.tempLeaveType.IsAccural = true;
    this.tempLeaveType.AccuralPeriod = 2;
    this.tempLeaveType.AccuralDay = 1;
    this.tempLeaveType.AccuralMonth = 1;
    this.tempLeaveType.AccuralNoOfDays = 0;
    this.tempLeaveType.AccuralDaysInPeriod = 1;
    this.tempLeaveType.IsReset = true;
    this.tempLeaveType.ResetPeriod = 2;
    this.tempLeaveType.ResetDay = 71;
    this.tempLeaveType.ResetMonth = 12;
    this.tempLeaveType.CarryForwardType = 1;
    this.tempLeaveType.CarryForwardPercentageUnit = 1;
    this.tempLeaveType.EncashmentPercentageUnit = 1;
    this.tempLeaveType.EntitlementCalendarList = [];

    this.IsProrateAccuralEnable = false;
    this.IsOpeningBalanceEnable = false;
    this.IsMaximumBalanceEnable = false;
    this.IsDeductibleHolidaysEnable = false;

    this.prorateAccuralType = "";

    this.IsEmployeeTypes = false;
    this.IsSourceOfHire = false;
    this.IsJobBands = false;
    this.IsOnboardingStatus = false;

    this.applicableDepartmentList = [];
    this.applicableDesigationList = [];
    this.applicableLocationList = [];
    this.applicableRoleList = [];
    this.applicableEmployeeTypesList = [];
    this.applicableSourceOfHireList = [];
    this.applicableJobBandsList = [];
    this.applicableOnboardingStatusList = [];
    this.applicableEmployeeList = [];
    this.exceptionDepartmentList = [];
    this.exceptionDesigationList = [];
    this.exceptionLocationList = [];
    this.exceptionRoleList = [];
    this.canAppliedOnlyOnList = [];
    this.cannotTakenTogetherWithList = [];

    this.setDDLSelectLists();
    this.createEntitlementCalendarData();
  }

  setDDLSelectLists() {// Created By A Set values
    this.setAccuralDaySelectList(this.tempLeaveType.AccuralPeriod.toString());
    this.setAccuralMonthSelectList(this.tempLeaveType.AccuralPeriod.toString());
    this.setResetDaySelectList(this.tempLeaveType.ResetPeriod.toString());
    this.setResetMonthSelectList(this.tempLeaveType.ResetPeriod.toString());
  }

  getFormattedDate(val: any) {// Created By A Date format
    if (val === null || val === "") {
      return null;
    }
    const date = new Date(val);
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    return new Date(year, month, day);
  }
  getCSharpFormattedDate(val: any) {// Created By A Backend date format
    if (val === null || val === "") {
      return null;
    }
    const date = new Date(val);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return month + "-" + day + "-" + year;
  }

  @HostListener("document:click")
  clickout() {// Created By A Set value
    if (!this.isClickOutside) {
      this.flterShowData = false;
    }
    this.isClickOutside = false;
  }

  initAccuralDDL() {// Created By A Initialize Accural dropdown values
    for (let i = 1; i <= 31; i++) {
      this.AccuralDayList.push(this.getNumberWithOrdinal(i));
      this.prorateAccuralDayList.push(this.getNumberWithOrdinal(i));
    }

    this.AccuralDayList.push("Last Day");
    this.AccuralDayList.push("Policy Date");
    this.AccuralDayList.push("Joining Date");
    this.AccuralDayList.push("Birth Date");
  }
  getNumberWithOrdinal(n) {// Created By K Get Numbers with Ordinals
    let s = ["th", "st", "nd", "rd"],
        v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  }

  openUserModal() {// Created By A Open modal
    // this.leaveTypeForm.reset();
    this.viewMode = false;
    this.editMode = false;
    this.NeweditMode = true;

    this.getDropDownListData();
    this.setTempLeaveTypeData();
  }
  OpenAddLeaveTypeForm() {// Created By A Initialize leave-type form
    this.leaveTypeForm.reset()
    this.resetLeaveTyeForm();
    this.submitted = false
    debugger
    this.editMode = false;
    this.viewMode = false;
    this.NeweditMode = true;
    this.IsAccuralChange = true;
    this.isResetChange = true;
    this.HeaderActionMsg = environment.AddModel_msg;
    this.uploadedFileByteData = null;
    this.LeaveTypeenable = true;
    this.getCmpLeaveTypecode();
    this.getDropDownListData();
    this.leaveTypeForm.controls["ValidityFrom"].patchValue(this.ValidityFrom);
    this.leaveTypeForm.controls["ValidityTo"].patchValue(this.ValidityTo);
    this.setAppliacble();
    // this.setTempLeaveTypeData();
  
  }

  setAppliacble(){// Created By A Set values
    debugger
    //applicable
    this.exceptionDDLDepartmentList = this.DepartmentList;
    this.exceptionDDLDesigationList = this.DesigationList;
    this.exceptionDDLLocationList = this.LocationList;
    this.exceptionDDLRoleList = this.RoleItemList;

    //exceptions
    this.applicableDDLDepartmentList = this.DepartmentList;
    this.applicableDDLDesigationList = this.DesigationList;
    this.applicableDDLLocationList = this.LocationList;
    this.applicableDDLRoleList = this.RoleItemList;
  }

  getDropDownListData() {// Created By A Get Dropdowns lists values
    this.getDepartment();
    this.getAllDesigation();
    this.getAllLocation();
    this.getAllRolesForSelect();
    this.getAllEmployeList();
    this.leaveAppliedOn();
    this.getAllLeaveTypeForSelect();

    this.getEmployeeTypesList();
    this.getSourceOfHireList();
    this.getOnboardingStatusList();
    this.getJobBandsList();
  }
totalMyRequestList:any=[]
  getAllLeaveTypeData() {// Created By A Get All Leave-types list
    debugger;
    this.userLoader = true;
    let parms = {
      PageNo: 1,
      NoOfRecords: 100000,
      FilterStatus: this.filterStatus !== undefined ? this.filterStatus : null,
      FilterEmployees: this.filterEmployeeId !== undefined ? this.filterEmployeeId : null,
      FilterDepartments: this.filterDepartmentId !== undefined ? this.filterDepartmentId : null,
      FilterLocations: this.filterLocationId !== undefined ? this.filterLocationId : null,
      FilterDesignations: this.filterDesignationId !== undefined ? this.filterDesignationId : null,
      FilterRoles: this.filterRoleId !== undefined ? this.filterRoleId : null,
      FilterUnit: this.dayHrFilterValue !== undefined ? this.dayHrFilterValue : null,
      CmpCode:this.CmpCode,
    };
    this.isCompOffExists = false;
    this._LeaveServices.getAllLeaveTypeData(parms).subscribe((res) => {
      this.allLeaveTypeList = res["data"].table;
      this.totalMyRequestList=this.allLeaveTypeList
      this.userLoader = false;
      this.myRequestsListLength = this.allLeaveTypeList.length;
      this.InActiveLength = this.allLeaveTypeList.filter(user => user.isActive === false ).length;
      this.ActiveLength = this.allLeaveTypeList.filter(user => user.isActive === true).length;

      for (let i = 0; i < this.allLeaveTypeList.length; i++) {
        if (this.allLeaveTypeList[i].type == 5) {
          this.isCompOffExists = true;
        }
      }
    });
  }
  getAllLeaveTypeForSelect() {// Created By A Get Leave-type list for dropdown
    this.userLoader = true;

    let parms = {
      PageNo: 1,
      NoOfRecords: 100000,
      FilterStatus: null,
      FilterEmployees: null,
      FilterDepartments: null,
      FilterLocations: null,
      FilterDesignations: null,
      FilterRoles: null,
      FilterUnit: null,
      CmpCode:this.CmpCode,
    };
    this._LeaveServices.getAllLeaveTypeData(parms).subscribe((res) => {
      this.userLoader = false;

      this.LeaveTypeList = this._commonSer.SetDDlDropdown(
        res["data"].table,
        "leaveTypeId",
        "leaveTypeName"
      );
    });
  }

  numberOnly(event): boolean {// Created By S Numbers validation
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  numberOnlyWithDecimal(event): boolean {// Created By S Numbers with decimal validation
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 
      && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  filterShow() { // Created By A get filters values
    this.getAllEmployeList();
    this.getDepartment();
    this.getAllDesigation();
    this.getAllLocation();
    this.getAllRole();
    this.flterShowData = !this.flterShowData;
  }
  closeFilter() {// Created By A Set value
    this.flterShowData = false;
  }

  getAllEmployeList() {// Created By A Getting all employees list
    debugger
    let parms = {
      PageNo: 1,
      NoOfRecords: 100000,
      SortType: "desc",
      FieldName: "UpdatedDate",
      Condition: "",
      CmpCode:this.CmpCode
    };
    this.userService.getUserList(parms).subscribe((res) => {
      // this.allEmployeeList =res['data'].table
      this.todatlEmployeeList = res["data"].table;
      if (res["data"].table != null && res["data"].table.length > 0) {
        this.employeName = res["data"].table[0].employeeName;
        this.employeCode = res["data"].table[0].empCode;
      }
      this.EmployeeList = this._commonSer.SetDDlDropdown(
        res["data"].table,
        "employeeId",
        "fullName"
      );
      // const selectedEmp = this.todatlEmployeeList.find(
      //   (x) => x.userId.toString() === this.currentUserId.toString()
      // );
      // if (selectedEmp !== undefined) {
      //   const empValue = {
      //     id: selectedEmp.employeeId,
      //     text: selectedEmp.fullName,
      //   };
      //   setTimeout(() => {
      //     this.ddlSelection(empValue, "Employee", undefined, undefined);
      //   }, 300);
      // }
    });
  }

  ddlSelection(value: any, placeHolder: any, index: any, index2: any) {// Created By A Global Controller to update fields 
    try {
      switch (placeHolder) {
        case "Employee":
          this.ActiveEmployee = [];
          this.ActiveEmployee.push(value);
          this.filterEmployeeId = value.id;
          break;
        case "Department":
          this.ActiveDepartment = [];
          this.ActiveDepartment.push(value);
          this.filterDepartmentId = value.id;
          break;
        case "Desigation":
          this.ActiveDesigation = [];
          this.ActiveDesigation.push(value);
          this.filterDesignationId = value.id;
          break;
        case "Location":
          this.ActiveLocation = [];
          this.ActiveLocation.push(value);
          this.filterLocationId = value.id;
          break;
        case "Roles":
          this.ActiveRoles = [];
          this.ActiveRoles.push(value);
          this.filterRoleId = value.id;
          break;
      }
    } catch (ex) {
      console.log(ex);
    }
  }
  removeFilterDropdownValue(_type, value) {// Created By A Global Controller to remove fields values 
    try {
      switch (_type) {
        case "Employee":
          this.ActiveEmployee = [];
          this.filterEmployeeId = null;
          break;
        case "Department":
          this.ActiveDepartment = [];
          this.filterDepartmentId = null;
          break;
        case "Desigation":
          this.ActiveDesigation = [];
          this.filterDesignationId = null;
          break;
        case "Location":
          this.ActiveLocation = [];
          this.filterLocationId = null;
          break;
        case "Roles":
          this.ActiveRoles = [];
          this.filterRoleId = null;
          break;
      }
    } catch (ex) {
      console.log(ex);
    }
  }

  public refreshEmployeeValue(value: any): void {// Created By A Employee dropdown data binding
    this.EmployeeCollection = [];
    value.forEach((element) => {
      this.EmployeeCollection.push({
        employeeId: element.id,
      });
      let  empMap = this.EmployeeCollection.reduce((a, o) => (a.push(o.employeeId), a), []);
      let data = empMap.map(String);
      this.leaveTypeForm.patchValue({
        ApplicableEmployees: data
      });
    });
    // console.log(this.RoleCollection);
  }

  getDepartment() {// Created By A Getting all departments
    let parms = {
      Condition: "",
      FieldName: "CreatedDate",
      NoOfRecords: 10000,
      PageNo: 1,
      SortType: "desc",
      CmpCode:this.CmpCode
    };
    this._depratmentSer.getDepartmentList(parms).subscribe((res) => {
      // this.allDepartmentList = res['data'].table
      this.allDepartmentList = res["data"].table;
      // if (res["data"].table != null && res["data"].table.lenght > 0) {
      //   this.employeName = res["data"].table[0].employeeName;
      //   this.employeCode = res["data"].table[0].empCode;
      // }

      this.DepartmentList = this._commonSer.SetDDlDropdown(
        res["data"].table,
        "departmentId",
        "departmentName"
      );
      // const selectedEmp = this.allDepartmentList.find(
      //   (x) => x.userId.toString() === this.currentUserId.toString()
      // );
      // if (selectedEmp !== undefined) {
      //   const empValue = {
      //     id: selectedEmp.departmentId,
      //     text: selectedEmp.departmentName,
      //   };
      //   setTimeout(() => {
      //     this.departmentSelection(empValue, "Department", undefined, undefined);
      //   }, 300);
      // }
      // this.DepartmentMultiList = [];
      //   for (let i = 0; i < res.data.table.length; i++) {
      //     this.DepartmentMultiList.push({
      //       id: res.data.table.departmentId,
      //       text: res.data.table.departmentName,
      //     });
      //   }
    });
  }
  departmentSelection(value: any, placeHolder: any, index: any, index2: any) {// Created By A On select Department
    console.log(this.ActiveDepartment)
    try {
      switch (placeHolder) {
        case "Department":

          this.ActiveDepartment = [];
          this.ActiveDepartment.push(value);

          this.ddlDepartmentId = value.id;

          console.log(this.ActiveEmployee);

          // this.bindAllDashboardData();
          break;
      }
    } catch (ex) {}
  }

  exceedChange(event) {// Created By A Set value
    this.selectedExceedLeaveAllow = event.target.value;
    console.log(event);
  }

  leaveAppliedOn() {// Created By A Set values
    // this._depratmentSer.getDepartmentList(parms).subscribe(() => {
      // this.allDepartmentList = res['data'].table
      let res = { table: [{ appliedid: 1, appliedName: 'Restricted Holiday' }, { appliedid: 2, appliedName: 'Work Anniversary' }, { appliedid: 3, appliedName: 'BirthDay' }, { appliedid: 4, appliedName: 'Date of Exit' }] };
      this.appliedLeaveList = res.table;
      // if (res.table != null && res.table.lenght > 0) {
      //   this.employeName = res.table[0].employeeName;
      //   this.employeCode = res.table[0].empCode;
      // }
      this.AppliedList = this._commonSer.SetDDlDropdown(
        res.table,
        "appliedid",
        "appliedName"
      );
    // });
  }
  selectedAppliedLeave(evt: any) {}

  public refreshAppliedLeaveValue(value: any): void {// Created By A
    this.AppliedLeaveCollection = [];
    value.forEach((element) => {
      this.AppliedLeaveCollection.push({
        appliedid: element.id,
      });
    });
    // console.log(this.RoleCollection);
  }

  exceedChangeNotAllow(event) {// Created By A Set value
    this.selectedExceedLeaveAllow = event.target.value;
  }

  getAllLocation() {// Created By A Getting all locations
    let parms = {
      Condition: "",
      FieldName: "CreatedDate",
      NoOfRecords: 10000,
      PageNo: 1,
      SortType: "desc",
      CmpCode:this.CmpCode,
    };
    this._locationSer.getlocationlist(parms).subscribe((res) => {
      // this.allLocationList = res['data'].table
      this.allLocationList = res["data"].table;
      if (res["data"].table != null && res["data"].table.lenght > 0) {
        this.employeName = res["data"].table[0].employeeName;
        this.employeCode = res["data"].table[0].empCode;
      }
      this.LocationList = this._commonSer.SetDDlDropdown(
        res["data"].table,
        "locationId",
        "locationName"
      );
    });
  }

  locationSelection(value: any, placeHolder: any, index: any, index2: any) {// Created By A On select location
    try {
      switch (placeHolder) {
        case "Location":
          this.ActiveLocation = [];
          this.ActiveLocation.push(value);
          this.ddlLocationId = value.id;
          console.log(this.ActiveEmployee);

          // this.bindAllDashboardData();
          break;
      }
    } catch (ex) {}
  }
  selectedLocation(evt: any) {}

  public refreshLocationValue(value: any): void {// Created By A Location data binding
    this.LocationCollection = [];
    value.forEach((element) => {
      this.LocationCollection.push({
        locationId: element.id,
      });
      let  locationMap = this.LocationCollection.reduce((a, o) => (a.push(o.locationId), a), []);
      let data = locationMap.map(String);
      this.leaveTypeForm.patchValue({
        ApplicableLocations: data
      });
    });
    // console.log(this.RoleCollection);
  }

  getAllLeaveType() {// Created By A Set All Leave types
    let parms = {
      PageNo: 1,
      NoOfRecords: 100000,
      FilterStatus: this.statusSelectValue,
      FilterEmployees: this.employeeSelectedValue,
      FilterDepartments: this.departmentSelectValue,
      FilterLocations: this.locationSelectedValue,
      FilterDesignations: this.desigationSelectedValue,
      FilterRoles: null,
      FilterUnit: this.dayHrFilterValue,
      CmpCode:this.CmpCode,
    };
    this._LeaveServices.getAllLeaveTypeData(parms).subscribe((res) => {
      this.allLeaveTypeList = res["data"].table;
      // this.allLocationList = res["data"].table;
    
      this.LeaveTypeList = this._commonSer.SetDDlDropdown(
        res["data"].table,
        "leaveTypeId",
        "leaveTypeName"
      );
    });
  }

  leaveTypeSelection(value: any, placeHolder: any, index: any, index2: any) {// Created By A On Select Leave Type
    try {
      switch (placeHolder) {
        case "LeaveType":
          this.ActiveLeaveType = [];
          this.ActiveLeaveType.push(value);
          this.ddlLocationId = value.id;
          // console.log(this.ActiveEmployee);

          // this.bindAllDashboardData();
          break;
      }
    } catch (ex) {}
  }
  selectedLeaveType(evt: any) {}

  public refreshLeaveType(value: any): void {// Created By A Binding leave type data
    this.LeaveTypeCollection = [];
    value.forEach((element) => {
      this.LeaveTypeCollection.push({
        locationId: element.id,
      });
    });
    // console.log(this.RoleCollection);
  }

  AddUserInExcept(ExceptionUser,fromDateExcept,toDateExcept){// Created By A Set value
    debugger
    let UserName = this.EmployeeList.filter( x => x.id ==ExceptionUser)
    this.fieldEmpArray.push({employeeId:ExceptionUser,employeeName:UserName[0].text, fromDate:fromDateExcept, toDate:toDateExcept, index:Math.random()})
  }
  RemoveUserInExcept(index){// Created By A Removing users
    debugger
    this.fieldEmpArray.splice(index,1)
  }
  exceptionUser
  onChangeExceptionUser(val){// Created By A Set value
    this.exceptionUser = val
  }
  

  getAllRole() {// Created By A Getting all Roles
    let parms = {
      PageNo: 1,
      NoOfRecords: 10,
      SortType: "asc",
      FieldName: "name",
      Condition: "",
      CmpCode:this.CmpCode,
    };
    this._LeaveServices.getAllRolesList(parms).subscribe((res) => {
      // this.allRolesList = res['data'].table
      this.allRolesList = res["data"].table;
      // if (res['data'].table != null && res['data'].table.lenght > 0) {
      //   this.employeName = res["data"].table[0].employeeName;
      //   this.employeCode = res["data"].table[0].empCode;
      // }
      this.RolesList = this._commonSer.SetDDlDropdown(
        res["data"].table,
        "roleId",
        "name"
      );
    });
  }

  rolesSelection(value: any, placeHolder: any, index: any, index2: any) {// Created By A On select Role
    try {
      switch (placeHolder) {
        case "Roles":
          this.ActiveRoles = [];
          this.ActiveRoles.push(value);
          this.ddlRolesId = value.id;
          console.log(this.ActiveEmployee);

          // this.bindAllDashboardData();
          break;
      }
    } catch (ex) {}
  }

  getAllDesigation() {// Created By A Getting all Desiganations
    let parms = {
      Condition: "",
      FieldName: "CreatedDate",
      NoOfRecords: 10000,
      PageNo: 1,
      SortType: "desc",
      CmpCode:this.CmpCode,
    };
    this._desigation.getDesignationList(parms).subscribe((res) => {
      // this.allDesigationList = res['data'].table
      this.allDesigationList = res["data"].table;
      if (res["data"].table != null && res["data"].table.lenght > 0) {
        this.employeName = res["data"].table[0].employeeName;
        this.employeCode = res["data"].table[0].empCode;
      }
      this.DesigationList = this._commonSer.SetDDlDropdown(
        res["data"].table,
        "designationId",
        "designationName"
      );
    });
  }

  desigationSelection(value: any, placeHolder: any, index: any, index2: any) {// Created By A On select designation
    try {
      switch (placeHolder) {
        case "Desigation":
          this.ActiveDesigation = [];
          this.ActiveDesigation.push(value);
          this.ddlDesigationId = value.id;
          console.log(this.ActiveEmployee);

          // this.bindAllDashboardData();
          break;
      }
    } catch (ex) {}
  }
  selectedDesigation(evt: any) {}

  public refreshDesigationValue(value: any): void {// Created By A Binding Designations
    this.DesigationCollection = [];
    value.forEach((element) => {
      this.DesigationCollection.push({
        designationId: element.id,
      });
      let  designationMap = this.DesigationCollection.reduce((a, o) => (a.push(o.designationId), a), []);
      let data = designationMap.map(String);
      this.leaveTypeForm.patchValue({
        ApplicableDesignations: data
      });
    });
    // console.log(this.DesigationCollection);
  }

  daysHoursFilter(value) {// Created By A Set values
    this.dayHrFilterValue = value;
    this.getAllLeaveTypeData();
  }

  SideFilterFunc() {// Created By A Set values
    this.flterShowData = false;
    this.getAllLeaveTypeData();
    //console.log(this.departmentSelectValue);
  }

  ResetFilter() {// Created By A Reset values
    this.statusSelectValue = true;
    this.ActiveEmployee = [];
    this.filterEmployeeId = null;
    this.ActiveDepartment = [];
    this.filterDepartmentId = null;
    this.ActiveDesigation = [];
    this.filterDesignationId = null;
    this.ActiveLocation = [];
    this.filterLocationId = null;
    this.ActiveRoles = [];
    this.filterRoleId = null;
  }

  get leaveTypeControl() {
    return this.leaveTypeForm.controls;
  }

  getEmployeeTypesList() {// Created By A Set Employees type dropdown values
    let _list = [{ text: 'Employee', id: 1 }, { text: 'Vendor', id: 2 }, { text: 'Contact Person', id: 3 }, { text: 'Sales Person', id: 4 }];
    this.EmployeeTypesList = this._commonSer.SetDDlDropdown(_list, "id", "text");
  }
  getSourceOfHireList() {// Created By A Set Engagmentmodel type dropdown values
    let _list = [    { id: '1', text: 'Billable' },
    { id: '2', text: 'Non billable' },
    { id: '3', text: 'Shared ' },
    { id: '4', text: 'Management' },
    { id: '5', text: 'Administration' }];
    this.SourceOfHireList = this._commonSer.SetDDlDropdown(_list, "id", "text");
  }
  getOnboardingStatusList() {// Created By A Set bording status dropdown values
    let _list = [{ 'id': 1, 'text': 'No Triggered' }, { 'id': 2, 'text': 'Triggered' }, { 'id': 3, 'text': 'In Progress' }, { 'id': 4, 'text': 'Completed' }];
    this.OnboardingStatusList = this._commonSer.SetDDlDropdown(_list, "id", "text");
  }
  getJobBandsList() {// Created By K Get All Job bands
    this.userService.getAllJobBandsForSelect(this.CmpCode).subscribe((response: any) => {
      if (response.success) {
        this.JobBandsList = this._commonSer.SetDDlDropdown(
          response["data"].table,
          "jobBandId",
          "bandName"
        );
      }
    });
  }

 
  onFileChanged(event) {// Created By A Uploaded File
    this.uploadedFile = event.target.files[0];
    let reader = new FileReader();
    let vm = this;
    reader.onload = function() {
      let arrayBuffer = this.result;
      //console.log(arrayBuffer);
      vm.uploadedFileByteData = arrayBuffer;
      //vm.postLeaveTypeImage(21);
    };
    reader.readAsArrayBuffer(event.target.files[0]);
  }
  getCmpLeaveTypecode(){// Created By A Getting compony leave-type codes
    debugger
    this._LeaveServices.getcmpLeaveTpeCode(this.CmpCode).subscribe((res) => {
      this.leaveTypeForm.controls["LeaveTypeCode"].patchValue(res['data'].table[0].leaveTypeCode);
      this.leaveTypeForm.controls["customeleavesrno"].patchValue(res['data'].table[0].customeleavesrno);
      this.CustomLeaveCode = res['data'].table[0].leaveTypeCode;
      this.leaveTyperequestCode = res['data'].table[0].customLeaveCode;
      
    })
  }

  saveLeaveType() {// Created By A On Save Lave Type
    debugger
    this.submitted = true;
    if(this.leaveTypeForm.controls["IsMaximumBalanceEnable"].value){
    if(this.leaveTypeForm.controls.MaximumBalance.value < this.leaveTypeForm.controls.OpeningBalance.value){
      this.toaster.show("warning", "Opening balance should be less then or equal to maximum balance")
      return
    }
  }else {
    if(this.leaveTypeForm.controls["EncashmentValue"].value && this.leaveTypeForm.controls["CarryForwardPercentageUnit"].value == 1 ){
      let myval = 100 - this.leaveTypeForm.controls["CarryForwardValue"].value; 
      if(this.leaveTypeForm.controls["EncashmentValue"].value > myval){
        this.toaster.show("warning", "Encashment percantage needs to be less then "+myval)
        return
      } 
    }
  }

    if (this.leaveTypeForm.controls.LeaveTypeName.valid === false ||
      this.leaveTypeForm.controls.LeaveTypeCode.valid === false ||
      this.leaveTypeForm.controls.Type.valid === false ||
      this.leaveTypeForm.controls.Unit.valid === false ||
      this.leaveTypeForm.controls.ValidityFrom.valid === false) {
      return;
    }
    let validityFromDate = this.getFormattedDate(this.leaveTypeForm.value.ValidityFrom);
    let validityToDate = this.getFormattedDate(this.leaveTypeForm.value.ValidityTo);

    if (validityFromDate != null) {
      this.leaveTypeForm.patchValue({
        ValidityFrom: this.getCSharpFormattedDate(validityFromDate)
      });
    } else {
      this.leaveTypeForm.patchValue({
        ValidityFrom: null
      });
    }
    if (validityToDate != null) {
      this.leaveTypeForm.patchValue({
        ValidityTo: this.getCSharpFormattedDate(validityToDate)
      });
    } else {
      this.leaveTypeForm.patchValue({
        ValidityTo: null
      });
    }
    // Check Validity Start Date Exists
    if (this.leaveTypeForm.controls.ValidityFrom == null) {
      return;
    }
  
    this.leaveTypeForm.patchValue({
      CmpCode: this._authSer.getcompanyCode(),
      CreatedBy: this._authSer.getUserId(),
    });
   
 
    this.leaveTypeForm.patchValue({
      EntitlementCalendarList: this.tempLeaveType.EntitlementCalendarList
    });

    // Get All Multi Select Dropdown values in arry format
    // Applicable Department
    let dept = []
    this.leaveTypeForm.value?.ApplicableDepartments.forEach(element => {
      dept.push(''+element.id)
    });
    let departmentMap = dept.reduce((a, o) => (a.push(o), a), []);
    let _departmentData = departmentMap.map(String);
    this.leaveTypeForm.patchValue({
      ApplicableDepartments: _departmentData
    });

    // Applicable Designation
    let deg = []
    this.leaveTypeForm.value?.ApplicableDesignations.forEach(element => {
      deg.push(''+element.id)
    });
    let designationMap = deg.reduce((a, o) => (a.push(o), a), []);
    let _designationData = designationMap.map(String);
    this.leaveTypeForm.patchValue({
      ApplicableDesignations: _designationData
    });

    // Applicable Location
    let loc = []
    this.leaveTypeForm.value?.ApplicableLocations.forEach(element => {
      loc.push(''+element.id)
    });
    let locationMap = loc.reduce((a, o) => (a.push(o), a), []);
    let _locationData = locationMap.map(String);
    this.leaveTypeForm.patchValue({
      ApplicableLocations: _locationData
    });

    // Applicable Roles
    
    let role = []
    this.leaveTypeForm.value?.ApplicableRoles.forEach(element => {
      role.push(''+element.id)
    });
    let roleMap = role.reduce((a, o) => (a.push(o), a), []);
    let _roleData = roleMap.map(String);
    this.leaveTypeForm.patchValue({
      ApplicableRoles: _roleData
    });

    // Applicable Employee Types
    debugger
    let emps = []
    this.leaveTypeForm.value?.ApplicableEmployees.forEach(element => {
      emps.push(''+element.id)
    });
    let employeeTyesMap = emps.reduce((a, o) => (a.push(o), a), []);
    let _employeeTypesData = employeeTyesMap.map(String);
    this.leaveTypeForm.patchValue({
      ApplicableEmployeeTypes: _employeeTypesData
    });

    // Applicable Source Of Hire
    let sohMap = this.applicableSourceOfHireList.reduce((a, o) => (a.push(o), a), []);
    let _sohData = sohMap.map(String);
    this.leaveTypeForm.patchValue({
      ApplicableSourceOfHire: _sohData
    });

    // Applicable Job Bands
    let jbMap = this.applicableJobBandsList.reduce((a, o) => (a.push(o), a), []);
    let _jbData = jbMap.map(String);
    this.leaveTypeForm.patchValue({
      ApplicableJobBands: _jbData
    });

    // Applicable Onboarding Status
    let osMap = this.applicableOnboardingStatusList.reduce((a, o) => (a.push(o), a), []);
    let _osData = osMap.map(String);
    this.leaveTypeForm.patchValue({
      ApplicableOnboardingStatus: _osData
    });

    // Applicable Employees
    let empMap = emps.reduce((a, o) => (a.push(o), a), []);
    let _empData = empMap.map(String);
    this.leaveTypeForm.patchValue({
      ApplicableEmployees: _empData
    });

    // Exception Department
    let excepDepartmentMap = this.exceptionDepartmentList.reduce((a, o) => (a.push(o), a), []);
    let _excepDepartmentData = excepDepartmentMap.map(String);
    this.leaveTypeForm.patchValue({
      ExceptionDepartments: _excepDepartmentData
    });

    // Exception Designation
    let excepDesignationMap = this.exceptionDesigationList.reduce((a, o) => (a.push(o), a), []);
    let _excepDesignationData = excepDesignationMap.map(String);
    this.leaveTypeForm.patchValue({
      ExceptionDesignations: _excepDesignationData
    });

    // Exception Location
    let excepLocationMap = this.exceptionLocationList.reduce((a, o) => (a.push(o), a), []);
    let _excepLocationData = excepLocationMap.map(String);
    this.leaveTypeForm.patchValue({
      ExceptionLocations: _excepLocationData
    });

    // Exception Roles
    let excepRoleMap = this.exceptionRoleList.reduce((a, o) => (a.push(o), a), []);
    let _excepRoleData = excepRoleMap.map(String);
    this.leaveTypeForm.patchValue({
      ExceptionRoles: _excepRoleData
    });


    // Can Applied Only On
    debugger;
    let canAppliedMap = this.canAppliedOnlyOnList.reduce((a, o) => (a.push(o), a), []);
    let _canAppliedData = canAppliedMap.map(String);
    this.leaveTypeForm.patchValue({
      CanAppliedOnlyOn: _canAppliedData
    });
    if(_canAppliedData == ""){
      this.leaveTypeForm.patchValue({
        CanAppliedOnlyOn: this.canapplyon
      });
    }

    // Cannot Taken Together With
    debugger
    let cannotTakenMap = this.cannotTakenTogetherWithList.reduce((a, o) => (a.push(o), a), []);
    let _cannotTakenData = cannotTakenMap.map(String);
    this.leaveTypeForm.patchValue({
      CannotTakenTogetherWith: _cannotTakenData
    });
    if(_cannotTakenData == ""){    
      this.leaveTypeForm.patchValue({
        CannotTakenTogetherWith: this.CannotTakenTogether 
      });
    }

    console.clear();
    console.log(this.leaveTypeForm.value);
    // if (this.leaveTypeForm.invalid) {
    //   return;
    // }
    // this.submitted = false;
    //return;
    this.userLoader = true;
    // if(this.leaveTypeForm.value.IsAccural==null && this.leaveTypeForm.value.CanExceedLeaveBalance==null && this.leaveTypeForm.value.AllowRequestsForPastDates==null && this.leaveTypeForm.value.AllowRequestsForFutureDates==null && this.leaveTypeForm.value.IsMinimumLimitPerApplication==null && this.leaveTypeForm.value.IsMaximumLimitPerApplication==null && this.leaveTypeForm.value.IsMaximumConsecutiveHoursAllowed==null && this.leaveTypeForm.value.IsMinimumGapBetweenTwoApplications==null &&  this.leaveTypeForm.value.MaximumApplicationsAllowed==null){
    this.leaveTypeForm.patchValue({
      IsActive: this.leaveTypeForm.value.IsActive != null ? this.leaveTypeForm.value.IsActive : true,
      IsAccural: this.leaveTypeForm.value.IsAccural != null ? this.leaveTypeForm.value.IsAccural : false,
      IsReset: this.leaveTypeForm.value.IsReset != null ? this.leaveTypeForm.value.IsReset : false,
      CanExceedLeaveBalance: this.leaveTypeForm.value.CanExceedLeaveBalance != null ? this.leaveTypeForm.value.CanExceedLeaveBalance : false,
      ApplicableGenderMale: this.leaveTypeForm.value.ApplicableGenderMale != null ? this.leaveTypeForm.value.ApplicableGenderMale : false,
      ApplicableGenderFemale: this.leaveTypeForm.value.ApplicableGenderFemale != null ? this.leaveTypeForm.value.ApplicableGenderFemale : false,
      ApplicableGenderOthers: this.leaveTypeForm.value.ApplicableGenderOthers != null ? this.leaveTypeForm.value.ApplicableGenderOthers : false,
      ApplicableMaritalStatusSingle: this.leaveTypeForm.value.ApplicableMaritalStatusSingle != null ? this.leaveTypeForm.value.ApplicableMaritalStatusSingle : false,
      ApplicableMaritalStatusMarried: this.leaveTypeForm.value.ApplicableMaritalStatusMarried != null ? this.leaveTypeForm.value.ApplicableMaritalStatusMarried : false,
      AllowRequestsForPastDates: this.leaveTypeForm.value.AllowRequestsForPastDates != null ? this.leaveTypeForm.value.AllowRequestsForPastDates : false,
      AllowRequestsForFutureDates: this.leaveTypeForm.value.AllowRequestsForFutureDates != null ? this.leaveTypeForm.value.AllowRequestsForFutureDates : false,
      IsMinimumLimitPerApplication: this.leaveTypeForm.value.IsMinimumLimitPerApplication != null ? this.leaveTypeForm.value.IsMinimumLimitPerApplication : false,
      IsMaximumLimitPerApplication: this.leaveTypeForm.value.IsMaximumLimitPerApplication != null ? this.leaveTypeForm.value.IsMaximumLimitPerApplication : false,
      IsMaximumConsecutiveHoursAllowed: this.leaveTypeForm.value.IsMaximumConsecutiveHoursAllowed != null ? this.leaveTypeForm.value.IsMaximumConsecutiveHoursAllowed : false,
      IsMinimumGapBetweenTwoApplications: this.leaveTypeForm.value.IsMinimumGapBetweenTwoApplications != null ? this.leaveTypeForm.value.IsMinimumGapBetweenTwoApplications : false,
      //MaximumApplicationsAllowed: this.leaveTypeForm.value.MaximumApplicationsAllowed != null ? this.leaveTypeForm.value.MaximumApplicationsAllowed : false,
    });
    // }
    //let departmentData = this.leaveTypeForm.value.ApplicableDepartments
    // let convertedArraytoString = departmentData.map(item=>item.id).join(',')
    // // let deparJoin = convertedArraytoString.toString().join('')
    // this.leaveTypeForm.patchValue({
    //   ApplicableDepartments:convertedArraytoString
    // })
    if (this.leaveTypeForm.value.LeaveTypeId === 0) {
      this._LeaveServices.saveLeaveType(this.leaveTypeForm.value).subscribe(
        (res) => {
          if (res.success === true) {
            this.userLoader = false;
            this.showSuccess = true;
            this.submitted=false
            this.showMessage = res.data;
      
            this.resetLeaveTyeForm();
            // this.getAllLeaveTypeForSelect()
            this.getAllLeaveTypeData();
            this.toaster.show("success",this.showMessage);
            // this._commonSer.OpenModalHandler("leaveTypeMessageModel");
            this._commonSer.CloseModalHandler("applyLeaveMode");
          } else {
            this.userLoader = false;
            this.showSuccess = false;
            this.showMessage = res.message;
            this.toaster.show("success",this.showMessage);
            // this._commonSer.OpenModalHandler("leaveTypeMessageModel");
          }
          console.log(this.leaveTypeForm.value);
        },
        (error) => {
          this.userLoader = false;
          this.showSuccess = false;
          this.showMessage = "Uh oh, Something went wrong !";
          this.toaster.show("success",this.showMessage);
          // this._commonSer.OpenModalHandler("leaveTypeMessageModel");
        }
      );
    } else {
      this.userLoader = true;
      // this.leaveTypeForm.patchValue({
      //   IsMaximumLimitPerApplication: false
      // });
      this._LeaveServices.saveLeaveType(this.leaveTypeForm.value).subscribe(
        (res) => {
          if (res.success === true) {
            this.userLoader = false;
            this.submitted=false
            this.showSuccess = true;
            this.showMessage = res.data;
            // this.leaveTypeForm.reset()
            // this.getAllLeaveTypeForSelect()
            this.getAllLeaveTypeData();
            this.toaster.show("success",this.showMessage);
            this.leaveTypeForm.reset();
            // this._commonSer.OpenModalHandler("leaveTypeMessageModel");
            this._commonSer.CloseModalHandler("applyLeaveMode");

            this.resetLeaveTyeForm();
          } else {
            this.userLoader = false;
            this.showSuccess = false;
            this.showMessage = res.message;
            this.toaster.show("success",this.showMessage);
            // this._commonSer.OpenModalHandler("leaveTypeMessageModel");
          }
          console.log(this.leaveTypeForm.value);
        },
        (error) => {
          this.userLoader = false;
          this.showSuccess = false;
          this.showMessage = "Uh oh, Something went wrong !";
          this._commonSer.OpenModalHandler("leaveTypeMessageModel");
        }
      );
    }
  }

  
  getAllRolesForSelect() {// Created By A Slelect Role
    this.userService.getAllRolesForSelect(this.CmpCode).subscribe(
      (res: any) => {
        this.RoleItemList = [];
        for (let i = 0; i < res.data.table.length; i++) {
          this.RoleItemList.push({
            id: res.data.table[i].roleId,
            text: res.data.table[i].name,
          });
        }
      },
      (err) => {}
    );
  }

  removedRole(evt: any) {// Created By A Remove Role
    if (this.editMode) {
      this.RoleDeleteCollection.push({
        roleId: evt.id,
      });
      console.log(this.RoleDeleteCollection);
    }
  }
  removedDepartmentException(evt: any) {// Created By A Remove department on exception
    if (this.editMode) {
      this.DepartmentCollection.push({
        departmentId: evt.id,
      });
      console.log(this.DepartmentCollection);
    }
  }
  selectedRoles(evt: any) {}
  selectedDepartment(evt: any) {// Created By A

  }

  selectedEmployee(evt: any) {}

  public refreshValue(value: any): void {// Created By A Binding Roles data
    this.RoleCollection = [];
    value.forEach((element) => {
      this.RoleCollection.push({
        roleId: element.id,
      });
      let  roleMap = this.RoleCollection.reduce((a, o) => (a.push(o.roleId), a), []);
      let data = roleMap.map(String);
      this.leaveTypeForm.patchValue({
        ApplicableRoles: data
      });
    });
    // console.log(this.RoleCollection);
  }

  public refreshDepartmentValue(value: any): void {// Created By A Binding Department data
    this.DepartmentCollection = [];
    value.forEach((element) => {
      this.DepartmentCollection.push({
        departmentId: element.id,
      });

      let  departmentMap = this.DepartmentCollection.reduce((a, o) => (a.push(o.departmentId), a), []);
      let data = departmentMap.map(String);
      this.leaveTypeForm.patchValue({
        ApplicableDepartments: data
      });
    });
    console.log(this.DepartmentCollection);
  }
  public refreshDepartmentValueException(value: any): void {// Created By A
    // this.DepartmentCollection = [];
    // value.forEach((element) => {
    //   debugger;
    //   this.DepartmentCollection.push({
    //     departmentId: element.id,
    //   });

    //   let  departmentMap = this.DepartmentCollection.reduce((a, o) => (a.push(o.departmentId), a), []);
    //   let data = departmentMap.map(String);
    //   this.leaveTypeForm.patchValue({
    //     ExceptionDepartments: data
    //   });
    // });
    // console.log(this.DepartmentCollection);
  }

 
  deleteLeaveType(leaveId) {// Created By A Initialize Delete Leave type
    try {
      if (leaveId.leaveTypeId != '') {
        this.IsDelete = true;
        this.showSuccess = false;

        this.deleteId = leaveId.leaveTypeId;
        this.openModel('userMessageModel', 'Are you sure, you want to delete this record?');
      }
    }
    catch (ex) {

    }
  }
  openModel(ModalId, Message) {// Created By S Open Modals From Code
    this._commonSer.OpenModalHandler(ModalId);
    this.showMessage = Message;
  }
  ConfdeleteDepartment(leveId) {// Created By A On Delete Leave-Type
    let parms ={}
    try {
      this.userLoader = true;
      this._LeaveServices
        .deleteLeaveType(parms,leveId)
        .subscribe((res) => {
          this.userLoader = false;
          if(res['success']==true){
            this.showSuccess = true;
            this.showMessage = res['data'];
            this.toaster.show("success", this.showMessage);
            // this._commonSer.OpenModalHandler('leaveTypeMessageModel')
            this.getAllLeaveTypeData();
          } else{
            this.showSuccess = false;
            this.showMessage = res['message'];
            this.toaster.show("success", this.showMessage);
            // this._commonSer.OpenModalHandler('leaveTypeMessageModel')           
          }
        
        });
    }
    catch (ex) {
      this.userLoader = false;
      this.getAllLeaveTypeData();
    }
  }


  cloaseEditMode() {// Created By A Reset Modal Values
    this.leaveTypeForm.reset();
    this.resetLeaveTyeForm();

    // if(this.isEditMode==true){
    // this.leaveTypeForm.reset()
    // }
  }

 
  editLeaveType(leaveTypeId) {// Created By A On Edit Leave-Type
    debugger
    this.userLoader = true;
    this.uploadedFileByteData = null;
    this.HeaderActionMsg = environment.EdtiModel_msg;
    this.IsAccuralChange = false;
    this.getAllLeaveTypeForSelect();
    this._LeaveServices.getLeaveTypeById(leaveTypeId, this.CmpCode)
      .subscribe((resLeaveType) => {
        debugger;
        this.userLoader = false;
        this.editMode = true;
        this.NeweditMode = true;
        this.LeaveTypeenable = false;
        this.viewMode = false;
        this.IsAccuralChange = resLeaveType["data"].entitlements[0].isAccural;
        this.isResetChange = resLeaveType["data"].entitlements[0].isReset;
        this.Typeleave = resLeaveType["data"].leaveType[0].type;
        this.isProPrateChange = true;
        this.RoleDeleteCollection = [];
        this.activeRoleList = [];
        this.setLeaveTypeFormData(resLeaveType);
      });
  }

 
  setLeaveTypeFormData(resLeaveType) {// Created By A Set Form Data from DB
    this.resetLeaveTyeForm();
    this.setTempLeaveTypeData();

    if (resLeaveType["data"].leaveType[0].imagePath == null || resLeaveType["data"].leaveType[0].imagePath == "") {
      resLeaveType["data"].leaveType[0].imagePath = "assets/LeaveIcons/Icon_1.png";
    }

    //this.getdepratmentValue = [];
    //this.getdepratmentValue.push(resLeaveType["data"].applicableExceptions[0].applicableDepartments);
  //   var canapplyon = resLeaveType["data"].restrictions[0].canAppliedOnlyOn.split(",");
  //   let CannotTakenTogether = resLeaveType["data"].restrictions[0].cannotTakenTogetherWith.split(",");
  //   this.canapplyon = resLeaveType["data"].restrictions[0].canAppliedOnlyOn.split(",");
  //   this.CannotTakenTogether = resLeaveType["data"].restrictions[0].cannotTakenTogetherWith.split(",");

  //   let canapplyonList =[]
  //   let CannotTakenTogetherList = []
  //   this.AppliedList
  //   this.LeaveTypeList
  //   if(canapplyon != ""){
  //     for(let i=0; i<canapplyon.length; i++){   
  //       let value = this.AppliedList.filter(x => x.id == canapplyon[i])
  //       canapplyonList.push(value[0].text);
      
  //     }
  //   }
  // if(CannotTakenTogether != ""){
  //   for(let i=0; i<CannotTakenTogether.length; i++){   
  //     let value = this.LeaveTypeList.filter(x => x.id == CannotTakenTogether[i])
  //     CannotTakenTogetherList.push(value[0].text);
    
  //   }
  // }
    debugger
    this.leaveTypeForm.patchValue({
      LeaveTypeId: resLeaveType["data"].leaveType[0].leaveTypeId,
      LeaveTypeName: resLeaveType["data"].leaveType[0].leaveTypeName,
      LeaveTypeCode: resLeaveType["data"].leaveType[0].leaveTypeCode,
      ColorCode: resLeaveType["data"].leaveType[0].colorCode,
      ImagePath: resLeaveType["data"].leaveType[0].imagePath,
      Type: resLeaveType["data"].leaveType[0].type,
      Unit: resLeaveType["data"].leaveType[0].unit,
      Description: resLeaveType["data"].leaveType[0].description,
      //ValidityFrom: resLeaveType["data"].leaveType[0].validityFrom,
      //ValidityTo: resLeaveType["data"].leaveType[0].validityTo,
      ValidityFrom: this.getFormattedDate(resLeaveType["data"].leaveType[0].validityFrom),
      ValidityTo: this.getFormattedDate(resLeaveType["data"].leaveType[0].validityTo),
      IsActive: resLeaveType["data"].leaveType[0].isActive,

      EffectiveAfter: resLeaveType["data"].entitlements[0].effectiveAfter,
      EffectiveAfterPeriod: resLeaveType["data"].entitlements[0].effectiveAfterPeriod,
      EffectiveAfterFrom: resLeaveType["data"].entitlements[0].effectiveAfterFrom,

      IsAccural: resLeaveType["data"].entitlements[0].isAccural,
      AccuralPeriod: resLeaveType["data"].entitlements[0].accuralPeriod,
      AccuralDay: resLeaveType["data"].entitlements[0].accuralDay,
      AccuralMonth: resLeaveType["data"].entitlements[0].accuralMonth,
      AccuralNoOfDays: resLeaveType["data"].entitlements[0].accuralNoOfDays,
      AccuralDaysInPeriod: resLeaveType["data"].entitlements[0].accuralDaysInPeriod,

      IsReset: resLeaveType["data"].entitlements[0].isReset,
      ResetPeriod: resLeaveType["data"].entitlements[0].resetPeriod,
      ResetDay: resLeaveType["data"].entitlements[0].resetDay,
      ResetMonth: resLeaveType["data"].entitlements[0].resetMonth,

      CarryForwardType: resLeaveType["data"].entitlements[0].carryForwardType,
      CarryForwardValue: resLeaveType["data"].entitlements[0].carryForwardValue,
      CarryForwardPercentageUnit: resLeaveType["data"].entitlements[0].carryForwardPercentageUnit,
      CarryForwardMaxLimit: resLeaveType["data"].entitlements[0].carryForwardMaxLimit,
      CarryForwardExpiresIn: resLeaveType["data"].entitlements[0].carryForwardExpiresIn,
      CarryForwardExpiresInType: resLeaveType["data"].entitlements[0].carryForwardExpiresInType,
      CarryForwardOverallLimit: resLeaveType["data"].entitlements[0].carryForwardOverallLimit,

      EncashmentType: resLeaveType["data"].entitlements[0].encashmentType,
      EncashmentValue: resLeaveType["data"].entitlements[0].encashmentValue,
      EncashmentPercentageUnit: resLeaveType["data"].entitlements[0].encashmentPercentageUnit,
      EncashmentMaxLimit: resLeaveType["data"].entitlements[0].encashmentMaxLimit,

      IsProrateAccuralEnable: resLeaveType["data"].entitlements[0].isProrateAccuralEnable,
      ProrateAccuralType: resLeaveType["data"].entitlements[0].prorateAccuralType,

      IsOpeningBalanceEnable: resLeaveType["data"].entitlements[0].isOpeningBalanceEnable,
      OpeningBalance: resLeaveType["data"].entitlements[0].openingBalance,

      IsMaximumBalanceEnable: resLeaveType["data"].entitlements[0].isMaximumBalanceEnable,
      MaximumBalance: resLeaveType["data"].entitlements[0].maximumBalance,

      IsDeductibleHolidaysEnable: resLeaveType["data"].entitlements[0].isDeductibleHolidaysEnable,
      DeductibleHolidays: resLeaveType["data"].entitlements[0].deductibleHolidays,

      ApplicableGenderMale: resLeaveType["data"].applicableExceptions[0].applicableGenderMale,
      ApplicableGenderFemale: resLeaveType["data"].applicableExceptions[0].applicableGenderFemale,
      ApplicableGenderOthers: resLeaveType["data"].applicableExceptions[0].applicableGenderOthers,
      ApplicableMaritalStatusSingle: resLeaveType["data"].applicableExceptions[0].applicableMaritalStatusSingle,
      ApplicableMaritalStatusMarried: resLeaveType["data"].applicableExceptions[0].applicableMaritalStatusMarried,
      ApplicableDepartments: [],
      ApplicableDesignations: [],
      ApplicableLocations: [],
      ApplicableRoles: [],
      ApplicableEmployeeTypes: [],
      ApplicableSourceOfHire: [],
      ApplicableJobBands: [],
      ApplicableOnboardingStatus: [],
      ApplicableEmployees: [],
      ExceptionDepartments: [],
      ExceptionDesignations: [],
      ExceptionLocations: [],
      ExceptionRoles: [],

      CanCountAsLeaveInWeekendBetweenLeave: resLeaveType["data"].restrictions[0].canCountAsLeaveInWeekendBetweenLeave,
      WeekendBetweenLeaveCountAfter: resLeaveType["data"].restrictions[0].weekendBetweenLeaveCountAfter,
      CanCountAsHolidayInWeekendBetweenLeave: resLeaveType["data"].restrictions[0].canCountAsHolidayInWeekendBetweenLeave,
      WeekendBetweenHolidayCountAfter: resLeaveType["data"].restrictions[0].weekendBetweenHolidayCountAfter,

      CanExceedLeaveBalance: resLeaveType["data"].restrictions[0].canExceedLeaveBalance,
      ExceedLeaveBalanceType: resLeaveType['data'].restrictions[0].exceedLeaveBalanceType+"",
      IsDurationFullDay: resLeaveType["data"].restrictions[0].isDurationFullDay,
      IsDurationHalfDay: resLeaveType["data"].restrictions[0].isDurationHalfDay,
      IsDurationQuarterDay: resLeaveType["data"].restrictions[0].isDurationQuarterDay,
      ReportConfigAllowView: resLeaveType['data'].restrictions[0].reportConfigAllowView,
      ReportConfigBalanceDisplay: resLeaveType['data'].restrictions[0].reportConfigBalanceDisplay,
      AllowRequestsForPastDates: resLeaveType["data"].restrictions[0].allowRequestsForPastDates,
      AllowRequestsForPastDays: resLeaveType['data'].restrictions[0].allowRequestsForPastDays,
      AllowRequestsForFutureDates: resLeaveType["data"].restrictions[0].allowRequestsForFutureDates,
      AllowRequestsForNextDays: resLeaveType["data"].restrictions[0].allowRequestsForNextDays,
      AllowRequestsDaysinAdvance: resLeaveType['data'].restrictions[0].allowRequestsDaysinAdvance,
      IsMinimumLimitPerApplication: resLeaveType["data"].restrictions[0].isMinimumLimitPerApplication,
      MinimumLimitPerApplication: resLeaveType["data"].restrictions[0].minimumLimitPerApplication,
      IsMaximumLimitPerApplication: resLeaveType["data"].restrictions[0].minimumLimitPerApplication,
      MaximumLimitPerApplication: resLeaveType["data"].restrictions[0].maximumLimitPerApplication,
      IsMaximumConsecutiveHoursAllowed: resLeaveType["data"].restrictions[0].isMaximumConsecutiveHoursAllowed,
      MaximumConsecutiveHoursAllowed: resLeaveType["data"].restrictions[0].maximumConsecutiveHoursAllowed,
      IsMinimumGapBetweenTwoApplications: resLeaveType["data"].restrictions[0].isMinimumGapBetweenTwoApplications,
      MinimumGapBetweenTwoApplications: resLeaveType["data"].restrictions[0].minimumGapBetweenTwoApplications,
      EnableFileUploadOption: resLeaveType["data"].restrictions[0].enableFileUploadOption,
      OnAppliedLeavePeriodExceeds: resLeaveType["data"].restrictions[0].onAppliedLeavePeriodExceeds,
      MaximumApplicationsAllowed: resLeaveType["data"].restrictions[0].maximumApplicationsAllowed,
      MaximumApplicationsAllowedType: resLeaveType["data"].restrictions[0].maximumApplicationsAllowedType,
      CanAppliedOnlyOn: resLeaveType["data"].restrictions[0].canAppliedOnlyOn,
      CannotTakenTogetherWith: resLeaveType["data"].restrictions[0].cannotTakenTogetherWith,
      EntitlementCalendarList: resLeaveType["data"].entitlementCalendar,
    });

    this.tempLeaveType = new LeaveTypeData();
    this.tempLeaveType.LeaveTypeId = resLeaveType["data"].leaveType[0].leaveTypeId;
    this.tempLeaveType.LType = resLeaveType["data"].leaveType[0].type;
    this.tempLeaveType.Unit = resLeaveType["data"].leaveType[0].unit;
    this.tempLeaveType.ImagePath = resLeaveType["data"].leaveType[0].imagePath;
    this.tempLeaveType.IsAccural = resLeaveType["data"].entitlements[0].isAccural;
    this.tempLeaveType.AccuralPeriod = resLeaveType["data"].entitlements[0].accuralPeriod;
    this.tempLeaveType.AccuralDay = resLeaveType["data"].entitlements[0].accuralDay;
    this.tempLeaveType.AccuralMonth = resLeaveType["data"].entitlements[0].accuralMonth;
    this.tempLeaveType.AccuralNoOfDays = resLeaveType["data"].entitlements[0].accuralNoOfDays;
    this.tempLeaveType.AccuralDaysInPeriod = resLeaveType["data"].entitlements[0].accuralDaysInPeriod;
    this.tempLeaveType.IsReset = resLeaveType["data"].entitlements[0].isReset;
    this.tempLeaveType.ResetPeriod = resLeaveType["data"].entitlements[0].resetPeriod;
    this.tempLeaveType.ResetDay = resLeaveType["data"].entitlements[0].resetDay;
    this.tempLeaveType.ResetMonth = resLeaveType["data"].entitlements[0].resetMonth;
    this.tempLeaveType.CarryForwardType = resLeaveType["data"].entitlements[0].carryForwardType;
    this.tempLeaveType.CarryForwardPercentageUnit = resLeaveType["data"].entitlements[0].carryForwardPercentageUnit;
    this.tempLeaveType.EncashmentPercentageUnit = resLeaveType["data"].entitlements[0].encashmentPercentageUnit;
    this.tempLeaveType.EntitlementCalendarList = [];

    this.IsProrateAccuralEnable = resLeaveType["data"].entitlements[0].isProrateAccuralEnable;
    this.IsOpeningBalanceEnable = resLeaveType["data"].entitlements[0].isOpeningBalanceEnable;
    this.IsMaximumBalanceEnable = resLeaveType["data"].entitlements[0].isMaximumBalanceEnable;
    this.IsDeductibleHolidaysEnable = resLeaveType["data"].entitlements[0].isDeductibleHolidaysEnable;

    this.prorateAccuralType = resLeaveType["data"].entitlements[0].prorateAccuralType;

    if (resLeaveType["data"].entitlementCalendar == null ||
      resLeaveType["data"].entitlementCalendar == undefined ||
      resLeaveType["data"].entitlementCalendar.length < 1) {
      this.createEntitlementCalendarData();
    } else {
      this.setEntitlementCalendarData(resLeaveType["data"].entitlementCalendar);
    }

    this.canExceedLeaveBalance = resLeaveType["data"].restrictions[0].canExceedLeaveBalance != null ? resLeaveType["data"].restrictions[0].canExceedLeaveBalance : false;

    this.bindNGSelectList('applicableDepartments', resLeaveType["data"].applicableExceptions[0].applicableDepartments);
    this.bindNGSelectList('applicableDesignations', resLeaveType["data"].applicableExceptions[0].applicableDesignations);
    debugger
    this.bindNGSelectList('applicableLocations', resLeaveType["data"].applicableExceptions[0].applicableLocations);
    this.bindNGSelectList('applicableRoles', resLeaveType["data"].applicableExceptions[0].applicableRoles);

    this.bindNGSelectList('applicableEmployeeTypes', resLeaveType["data"].applicableExceptions[0].applicableEmployeeTypes);
    this.bindNGSelectList('applicableSourceOfHire', resLeaveType["data"].applicableExceptions[0].applicableSourceOfHire);
    this.bindNGSelectList('applicableJobBands', resLeaveType["data"].applicableExceptions[0].applicableJobBands);
    this.bindNGSelectList('applicableOnboardingStatus', resLeaveType["data"].applicableExceptions[0].applicableOnboardingStatus);

    this.bindNGSelectList('applicableEmployees', resLeaveType["data"].applicableExceptions[0].applicableEmployees);

    this.bindNGSelectList('exceptionDepartments', resLeaveType["data"].applicableExceptions[0].exceptionDepartments);
    this.bindNGSelectList('exceptionDesignations', resLeaveType["data"].applicableExceptions[0].exceptionDesignations);
    this.bindNGSelectList('exceptionLocations', resLeaveType["data"].applicableExceptions[0].exceptionLocations);
    this.bindNGSelectList('exceptionRoles', resLeaveType["data"].applicableExceptions[0].exceptionRoles);
    debugger
    this.bindNGSelectList('canAppliedOnlyOn', resLeaveType["data"].restrictions[0].canAppliedOnlyOn);
    this.bindNGSelectList('cannotTakenTogetherWith', resLeaveType["data"].restrictions[0].cannotTakenTogetherWith);

    this.setAccuralDaySelectList(this.tempLeaveType.AccuralPeriod.toString());
    this.setAccuralMonthSelectList(this.tempLeaveType.AccuralPeriod.toString());

    this.setResetDaySelectList(this.tempLeaveType.ResetPeriod.toString());
    this.setResetMonthSelectList(this.tempLeaveType.ResetPeriod.toString());

    // bind temp check box values
    this.isMinimumApplied = resLeaveType["data"].restrictions[0].isMinimumLimitPerApplication;
    this.isMaximumAppliedLeaved = resLeaveType["data"].restrictions[0].isMaximumLimitPerApplication;
    this.isMAximumConsectiveHoursAllow = resLeaveType["data"].restrictions[0].isMaximumConsecutiveHoursAllowed;
    this.isMinimumGapInDay = resLeaveType["data"].restrictions[0].isMinimumGapBetweenTwoApplications;
    this.enableFileUploadOption = resLeaveType["data"].restrictions[0].enableFileUploadOption;
  }


  bindNGSelectList(_type, _list) { // Created By A Global Controller To Bind All Select List
    debugger;
    switch (_type) {
      case 'applicableDepartments' :
        if (_list !== null && _list !== undefined) {
          let list = _list.split(",");
          let tempSelectList = this.DepartmentList;
          let selectedData = [];
          list.forEach(function (id) {
            let item = tempSelectList.find(i => i.id == id);
            if (item !== undefined && item !== null) {
              selectedData.push(item);
            }
          });
          this.leaveTypeForm.patchValue({
            ApplicableDepartments: selectedData
          });
          for (let j = 0; j < list; j++) {
            if (list[j] !== "") {
              this.applicableDepartmentList.push(list[j]);
              this.removeDDLItemOnLoad('applicableDepartment', parseInt(list[j]));
            }
          }
        }
        break;
      case 'applicableDesignations' :
        if (_list !== null && _list !== undefined) {
          let list = _list.split(",");
          let tempSelectList = this.DesigationList;
          let selectedData = [];
          list.forEach(function (id) {
            let item = tempSelectList.find(i => i.id == id);
            if (item !== undefined && item !== null) {
              selectedData.push(item);
            }
          });
          this.leaveTypeForm.patchValue({
            ApplicableDesignations: selectedData
          });
          //this.applicableDesigationList = list;
          for (let j = 0; j < list; j++) {
            if (list[j] !== "") {
              this.applicableDesigationList.push(list[j]);
              this.removeDDLItemOnLoad('applicableDesignation', parseInt(list[j]));
            }
          }
        }
        break;
      case 'applicableLocations' :
        if (_list !== null && _list !== undefined) {
          let list = _list.split(",");
          let tempSelectList = this.LocationList;
          let selectedData = [];
          list.forEach(function (id) {
            let item = tempSelectList.find(i => i.id == id);
            if (item !== undefined && item !== null) {
              selectedData.push(item);
            }
          });
          this.leaveTypeForm.patchValue({
            ApplicableLocations: selectedData
          });
          //this.applicableLocationList = list;
          for (let j = 0; j < list; j++) {
            if (list[j] !== "") {
              this.applicableLocationList.push(list[j]);
              this.removeDDLItemOnLoad('applicableLocation', parseInt(list[j]));
            }
          }
        }
        break;
      case 'applicableRoles' :
        if (_list !== null && _list !== undefined) {
          let list = _list.split(",");
          let tempSelectList = this.RoleItemList;
          let selectedData = [];
          list.forEach(function (id) {
            let item = tempSelectList.find(i => i.id == id);
            if (item !== undefined && item !== null) {
              selectedData.push(item);
            }
          });
          this.leaveTypeForm.patchValue({
            ApplicableRoles: selectedData
          });
          //this.applicableRoleList = list;
          for (let j = 0; j < list; j++) {
            if (list[j] !== "") {
              this.applicableRoleList.push(list[j]);
              this.removeDDLItemOnLoad('applicableRole', parseInt(list[j]));
            }
          }
        }
        break;

      case 'applicableEmployeeTypes' :
        if (_list !== null && _list !== undefined) {
          let list = _list.split(",");
          let tempSelectList = this.EmployeeTypesList;
          let selectedData = [];
          list.forEach(function (id) {
            let item = tempSelectList.find(i => i.id == id);
            if (item !== undefined && item !== null) {
              selectedData.push(item);
            }
          });
          this.leaveTypeForm.patchValue({
            ApplicableEmployeeTypes: selectedData
          });
          //this.applicableEmployeeTypesList = list;
          for (let j = 0; j < list; j++) {
            if (list[j] !== "") {
              this.applicableEmployeeTypesList.push(list[j]);
            }
          }
          if (this.applicableEmployeeTypesList.length > 0) {
            this.IsEmployeeTypes = true;
          }
        }
        break;
      case 'applicableSourceOfHire' :
        if (_list !== null && _list !== undefined) {
          let list = _list.split(",");
          let tempSelectList = this.SourceOfHireList;
          let selectedData = [];
          list.forEach(function (id) {
            let item = tempSelectList.find(i => i.id == id);
            if (item !== undefined && item !== null) {
              selectedData.push(item);
            }
          });
          this.leaveTypeForm.patchValue({
            ApplicableSourceOfHire: selectedData
          });
          //this.applicableSourceOfHireList = list;
          for (let j = 0; j < list; j++) {
            if (list[j] !== "") {
              this.applicableSourceOfHireList.push(list[j]);
            }
          }
          if (this.applicableSourceOfHireList.length > 0) {
            this.IsSourceOfHire = true;
          }
        }
        break;
      case 'applicableJobBands' :
        if (_list !== null && _list !== undefined) {
          let list = _list.split(",");
          let tempSelectList = this.JobBandsList;
          let selectedData = [];
          list.forEach(function (id) {
            let item = tempSelectList.find(i => i.id == id);
            if (item !== undefined && item !== null) {
              selectedData.push(item);
            }
          });
          this.leaveTypeForm.patchValue({
            ApplicableJobBands: selectedData
          });
          //this.applicableJobBandsList = list;
          for (let j = 0; j < list; j++) {
            if (list[j] !== "") {
              this.applicableJobBandsList.push(list[j]);
            }
          }
          if (this.applicableJobBandsList.length > 0) {
            this.IsJobBands = true;
          }
        }
        break;
      case 'applicableOnboardingStatus' :
        if (_list !== null && _list !== undefined) {
          let list = _list.split(",");
          let tempSelectList = this.OnboardingStatusList;
          let selectedData = [];
          list.forEach(function (id) {
            let item = tempSelectList.find(i => i.id == id);
            if (item !== undefined && item !== null) {
              selectedData.push(item);
            }
          });
          this.leaveTypeForm.patchValue({
            ApplicableOnboardingStatus: selectedData
          });
          //this.applicableOnboardingStatusList = list;
          for (let j = 0; j < list; j++) {
            if (list[j] !== "") {
              this.applicableOnboardingStatusList.push(list[j]);
            }
          }
          if (this.applicableOnboardingStatusList.length > 0) {
            this.IsOnboardingStatus = true;
          }
        }
        break;

      case 'applicableEmployees' :
        if (_list !== null && _list !== undefined) {
          let list = _list.split(",");
          let tempSelectList = this.EmployeeList;
          let selectedData = [];
          list.forEach(function (id) {
            let item = tempSelectList.find(i => i.id == id);
            if (item !== undefined && item !== null) {
              selectedData.push(item);
            }
          });
          this.leaveTypeForm.patchValue({
            ApplicableEmployees: selectedData
          });
          //this.applicableEmployeeList = list;
          for (let j = 0; j < list; j++) {
            if (list[j] !== "") {
              this.applicableEmployeeList.push(list[j]);
            }
          }
        }
        break;

      case 'exceptionDepartments' :
        if (_list !== null && _list !== undefined) {
          let list = _list.split(",");
          let tempSelectList = this.DepartmentList;
          let selectedData = [];
          list.forEach(function (id) {
            let item = tempSelectList.find(i => i.id == id);
            if (item !== undefined && item !== null) {
              selectedData.push(item);
            }
          });
          this.leaveTypeForm.patchValue({
            ExceptionDepartments: selectedData
          });
          //this.exceptionDepartmentList = list;
          for (let j = 0; j < list; j++) {
            if (list[j] !== "") {
              this.exceptionDepartmentList.push(list[j]);
              this.removeDDLItemOnLoad('exceptionDepartment', parseInt(list[j]));
            }
          }
        }
        break;
      case 'exceptionDesignations' :
        if (_list !== null && _list !== undefined) {
          let list = _list.split(",");
          let tempSelectList = this.DesigationList;
          let selectedData = [];
          list.forEach(function (id) {
            let item = tempSelectList.find(i => i.id == id);
            if (item !== undefined && item !== null) {
              selectedData.push(item);
            }
          });
          this.leaveTypeForm.patchValue({
            ExceptionDesignations: selectedData
          });
          //this.exceptionDesigationList = list;
          for (let j = 0; j < list; j++) {
            if (list[j] !== "") {
              this.exceptionDesigationList.push(list[j]);
              this.removeDDLItemOnLoad('exceptionDesignation', parseInt(list[j]));
            }
          }
        }
        break;
      case 'exceptionLocations' :
        if (_list !== null && _list !== undefined) {
          let list = _list.split(",");
          let tempSelectList = this.LocationList;
          let selectedData = [];
          list.forEach(function (id) {
            let item = tempSelectList.find(i => i.id == id);
            if (item !== undefined && item !== null) {
              selectedData.push(item);
            }
          });
          this.leaveTypeForm.patchValue({
            ExceptionLocations: selectedData
          });
          //this.exceptionLocationList = list;
          for (let j = 0; j < list; j++) {
            if (list[j] !== "") {
              this.exceptionLocationList.push(list[j]);
              this.removeDDLItemOnLoad('exceptionLocation', parseInt(list[j]));
            }
          }
        }
        break;
      case 'exceptionRoles' :
        if (_list !== null && _list !== undefined) {
          let list = _list.split(",");
          let tempSelectList = this.RoleItemList;
          let selectedData = [];
          list.forEach(function (id) {
            let item = tempSelectList.find(i => i.id == id);
            if (item !== undefined && item !== null) {
              selectedData.push(item);
            }
          });
          this.leaveTypeForm.patchValue({
            ExceptionRoles: selectedData
          });
          //this.exceptionRoleList = list;
          for (let j = 0; j < list; j++) {
            if (list[j] !== "") {
              this.exceptionRoleList.push(list[j]);
              this.removeDDLItemOnLoad('exceptionRole', parseInt(list[j]));
            }
          }
        }
        break;

      case 'canAppliedOnlyOn' :
        if (_list !== null && _list !== undefined) {
          let list = _list.split(",");
          let tempSelectList = this.AppliedList;
          let selectedData = [];
          list.forEach(function (id) {
            let item = tempSelectList.find(i => i.id == id);
            if (item !== undefined && item !== null) {
              selectedData.push(item);
            }
          });
          this.leaveTypeForm.patchValue({
            CanAppliedOnlyOn: selectedData
          });
          //this.canAppliedOnlyOnList = list;
          for (let j = 0; j < list; j++) {
            if (list[j] !== "") {
              this.canAppliedOnlyOnList.push(list[j]);
            }
          }
        }
        break;
      case 'cannotTakenTogetherWith' :
        if (_list !== null && _list !== undefined) {
          let list = _list.split(",");
          let tempSelectList = this.LeaveTypeList;
          let selectedData = [];
          list.forEach(function (id) {
            let item = tempSelectList.find(i => i.id == id);
            if (item !== undefined && item !== null) {
              selectedData.push(item);
            }
          });
          this.leaveTypeForm.patchValue({
            CannotTakenTogetherWith: selectedData
          });
          //this.cannotTakenTogetherWithList = list;
          for (let j = 0; j < list; j++) {
            if (list[j] !== "") {
              this.cannotTakenTogetherWithList.push(list[j]);
            }
          }
        }
        break;
    }
  }

  
  viewLeaveType(leaveTypeId) {// Created By A On View Leave-Type
  
   
    this.userLoader = true;
    this.uploadedFileByteData = null;
    this.HeaderActionMsg = environment.ViewModel_msg;
    this.IsAccuralChange = false;
    this.isResetChange = false;
    this.viewMode = true;
    this.editMode = false;
    this.NeweditMode = false;
    this.LeaveTypeenable = false;

    this._LeaveServices.getLeaveTypeById(leaveTypeId, this.CmpCode)
      .subscribe((resLeaveType) => {
        debugger;
        this.userLoader = false;
        this.IsAccuralChange = resLeaveType["data"].entitlements[0].isAccural;
        this.isResetChange = resLeaveType["data"].entitlements[0].isReset;
        this.isProPrateChange = true;
        this.RoleDeleteCollection = [];
        this.activeRoleList = [];
        this._commonSer.OpenModalHandler("newUserModal");
        
        this.setLeaveTypeFormData(resLeaveType);
      });

    // this.editLeaveType(data)
  }

  datepickerStartDate($event1) {// Created By A Set values
    debugger
    this.startDate = $event1;
    this.minDate = this.startDate;
    this.minDate.setDate(this.minDate.getDate());
  }
  datepickerEndDate($event1) {// Created By A Set values
    this.endDate = $event1;
    this.minDate = this.startDate;
  }

  onItemSelect(item: any) {// Created By A
    console.log(item);
    console.log(this.selectedItems);
  }
  OnItemDeSelect(item: any) {// Created By A
    console.log(item);
    console.log(this.selectedItems);
  }
  onSelectAll(items: any) {// Created By A
    console.log(items);
  }
  onDeSelectAll(items: any) {// Created By A
    console.log(items);
  }


  selectDropdownValue(_type, value) {// Created By A New Multi Selection Dropdown List Data
    if (value.id != null && value.id != undefined) {
      switch (_type) {
        case 'applicableDepartment':
          this.applicableDepartmentList.push(value.id.toString());
          break;
        case 'applicableDesignation':
          this.applicableDesigationList.push(value.id.toString());
          break;
        case 'applicableLocation':
          this.applicableLocationList.push(value.id.toString());
          break;
        case 'applicableRole':
          this.applicableRoleList.push(value.id.toString());
          break;
        case 'applicableEmployeeTypes':
          this.applicableEmployeeTypesList.push(value.id.toString());
          break;
        case 'applicableSourceOfHire':
          this.applicableSourceOfHireList.push(value.id.toString());
          break;
        case 'applicableJobBands':
          this.applicableJobBandsList.push(value.id.toString());
          break;
        case 'applicableOnboardingStatus':
          this.applicableOnboardingStatusList.push(value.id.toString());
          break;
        case 'applicableEmployee':
          this.applicableEmployeeList.push(value.id.toString());
          break;
        case 'exceptionDepartment':
          this.exceptionDepartmentList.push(value.id.toString());
          break;
        case 'exceptionDesignation':
          this.exceptionDesigationList.push(value.id.toString());
          break;
        case 'exceptionLocation':
          this.exceptionLocationList.push(value.id.toString());
          break;
        case 'exceptionRole':
          this.exceptionRoleList.push(value.id.toString());
          break;
        case 'canAppliedOnlyOn':
          this.canAppliedOnlyOnList.push(value.id.toString());
          break;
        case 'leaveCannotbeTakenWith':
          this.cannotTakenTogetherWithList.push(value.id.toString());
          break;
      }

      this.removeDDLItem(_type, value);
    }
  }

  removedDropdownValue(_type, value) {// Created By A On Remove selected fields
    let _index = -1;
    switch (_type) {
      case 'applicableDepartment':
        _index = this.applicableDepartmentList.indexOf(value.id.toString());
        if (_index !== -1) {
          this.applicableDepartmentList.splice(_index, 1);
        }
        break;
      case 'applicableDesignation':
        _index = this.applicableDesigationList.indexOf(value.id.toString());
        if (_index !== -1) {
          this.applicableDesigationList.splice(_index, 1);
        }
        break;
      case 'applicableLocation':
        _index = this.applicableLocationList.indexOf(value.id.toString());
        if (_index !== -1) {
          this.applicableLocationList.splice(_index, 1);
        }
        break;
      case 'applicableRole':
        _index = this.applicableRoleList.indexOf(value.id.toString());
        if (_index !== -1) {
          this.applicableRoleList.splice(_index, 1);
        }
        break;
      case 'applicableEmployeeTypes':
        _index = this.applicableEmployeeTypesList.indexOf(value.id.toString());
        if (_index !== -1) {
          this.applicableEmployeeTypesList.splice(_index, 1);
        }
        break;
      case 'applicableSourceOfHire':
        _index = this.applicableSourceOfHireList.indexOf(value.id.toString());
        if (_index !== -1) {
          this.applicableSourceOfHireList.splice(_index, 1);
        }
        break;
      case 'applicableJobBands':
        _index = this.applicableJobBandsList.indexOf(value.id.toString());
        if (_index !== -1) {
          this.applicableJobBandsList.splice(_index, 1);
        }
        break;
      case 'applicableOnboardingStatus':
        _index = this.applicableOnboardingStatusList.indexOf(value.id.toString());
        if (_index !== -1) {
          this.applicableOnboardingStatusList.splice(_index, 1);
        }
        break;
      case 'applicableEmployee':
        _index = this.applicableEmployeeList.indexOf(value.id.toString());
        if (_index !== -1) {
          this.applicableEmployeeList.splice(_index, 1);
        }
        break;
      case 'exceptionDepartment':
        _index = this.exceptionDepartmentList.indexOf(value.id.toString());
        if (_index !== -1) {
          this.exceptionDepartmentList.splice(_index, 1);
        }
        break;
      case 'exceptionDesignation':
        _index = this.exceptionDesigationList.indexOf(value.id.toString());
        if (_index !== -1) {
          this.exceptionDesigationList.splice(_index, 1);
        }
        break;
      case 'exceptionLocation':
        _index = this.exceptionLocationList.indexOf(value.id.toString());
        if (_index !== -1) {
          this.exceptionLocationList.splice(_index, 1);
        }
        break;
      case 'exceptionRole':
        _index = this.exceptionRoleList.indexOf(value.id.toString());
        if (_index !== -1) {
          this.exceptionRoleList.splice(_index, 1);
        }
        break;
      case 'canAppliedOnlyOn':
        _index = this.canAppliedOnlyOnList.indexOf(value.id.toString());
        if (_index !== -1) {
          this.canAppliedOnlyOnList.splice(_index, 1);
        }
        break;
      case 'leaveCannotbeTakenWith':
        _index = this.cannotTakenTogetherWithList.indexOf(value.id.toString());
        if (_index !== -1) {
          this.cannotTakenTogetherWithList.splice(_index, 1);
        }
        break;
    }
    this.addDDLItem(_type, value);
  }

  removeDDLItem(_type, value) {// Created By A Set Dropdown values
    debugger;
    if (value.id != null && value.id != undefined) {
      switch (_type) {
        case 'applicableDepartment':
          this.exceptionDDLDepartmentList = this.exceptionDDLDepartmentList.filter(s => s.id != value.id);
          break;
        case 'applicableDesignation':
          this.exceptionDDLDesigationList = this.exceptionDDLDesigationList.filter(s => s.id != value.id);
          break;
        case 'applicableLocation':
          this.exceptionDDLLocationList = this.exceptionDDLLocationList.filter(s => s.id != value.id);
          break;
        case 'applicableRole':
          this.exceptionDDLRoleList = this.exceptionDDLRoleList.filter(s => s.id != value.id);
          break;
        case 'exceptionDepartment':
          this.applicableDDLDepartmentList = this.applicableDDLDepartmentList.filter(s => s.id != value.id);
          break;
        case 'exceptionDesignation':
          this.applicableDDLDesigationList = this.applicableDDLDesigationList.filter(s => s.id != value.id);
          break;
        case 'exceptionLocation':
          this.applicableDDLLocationList = this.applicableDDLLocationList.filter(s => s.id != value.id);
          break;
        case 'exceptionRole':
          this.applicableDDLRoleList = this.applicableDDLRoleList.filter(s => s.id != value.id);
          break;
      }
    }
  }
  removeDDLItemOnLoad(_type, value) {// Created By A Set dropdown values
    debugger;
    if (value != null && value != undefined) {
      switch (_type) {
        case 'applicableDepartment':
          this.exceptionDDLDepartmentList = this.exceptionDDLDepartmentList.filter(s => s.id != value);
          break;
        case 'applicableDesignation':
          this.exceptionDDLDesigationList = this.exceptionDDLDesigationList.filter(s => s.id != value);
          break;
        case 'applicableLocation':
          this.exceptionDDLLocationList = this.exceptionDDLLocationList.filter(s => s.id != value);
          break;
        case 'applicableRole':
          this.exceptionDDLRoleList = this.exceptionDDLRoleList.filter(s => s.id != value);
          break;
        case 'exceptionDepartment':
          this.applicableDDLDepartmentList = this.applicableDDLDepartmentList.filter(s => s.id != value);
          break;
        case 'exceptionDesignation':
          this.applicableDDLDesigationList = this.applicableDDLDesigationList.filter(s => s.id != value);
          break;
        case 'exceptionLocation':
          this.applicableDDLLocationList = this.applicableDDLLocationList.filter(s => s.id != value);
          break;
        case 'exceptionRole':
          this.applicableDDLRoleList = this.applicableDDLRoleList.filter(s => s.id != value);
          break;
      }
    }
  }

  addDDLItem(_type, value) {// Created By A Bind Dropdown values
    debugger;
    let vm = this;
    if (value.id != null && value.id != undefined) {
      let _filteredItems = null;
      switch (_type) {
        case 'applicableDepartment':
          _filteredItems = vm.DepartmentList.filter(s => s.id == value.id);
          if (_filteredItems != null && _filteredItems.length > 0) {
            vm.exceptionDDLDepartmentList.push(_filteredItems[0]);
            vm.exceptionDDLDepartmentList = vm.exceptionDDLDepartmentList.slice(0);
          }
          break;
        case 'applicableDesignation':
          _filteredItems = vm.DesigationList.filter(s => s.id == value.id);
          if (_filteredItems != null && _filteredItems.length > 0) {
            vm.exceptionDDLDesigationList.push(_filteredItems[0]);
            vm.exceptionDDLDesigationList = vm.exceptionDDLDesigationList.slice(0);
          }
          break;
        case 'applicableLocation':
          _filteredItems = vm.LocationList.filter(s => s.id == value.id);
          if (_filteredItems != null && _filteredItems.length > 0) {
            vm.exceptionDDLLocationList.push(_filteredItems[0]);
            vm.exceptionDDLLocationList = vm.exceptionDDLLocationList.slice(0);
          }
          break;
        case 'applicableRole':
          _filteredItems = vm.RoleItemList.filter(s => s.id == value.id);
          if (_filteredItems != null && _filteredItems.length > 0) {
            vm.exceptionDDLRoleList.push(_filteredItems[0]);
            vm.exceptionDDLRoleList = vm.exceptionDDLRoleList.slice(0);
          }
          break;
        case 'exceptionDepartment':
          _filteredItems = vm.DepartmentList.filter(s => s.id == value.id);
          if (_filteredItems != null && _filteredItems.length > 0) {
            vm.applicableDDLDepartmentList.push(_filteredItems[0]);
            vm.applicableDDLDepartmentList = vm.applicableDDLDepartmentList.slice(0);
          }
          break;
        case 'exceptionDesignation':
          _filteredItems = vm.DesigationList.filter(s => s.id == value.id);
          if (_filteredItems != null && _filteredItems.length > 0) {
            vm.applicableDDLDesigationList.push(_filteredItems[0]);
            vm.applicableDDLDesigationList = vm.applicableDDLDesigationList.slice(0);
          }
          break;
        case 'exceptionLocation':
          _filteredItems = vm.LocationList.filter(s => s.id == value.id);
          if (_filteredItems != null && _filteredItems.length > 0) {
            vm.applicableDDLLocationList.push(_filteredItems[0]);
            vm.applicableDDLLocationList = vm.applicableDDLLocationList.slice(0);
          }
          break;
        case 'exceptionRole':
          _filteredItems = vm.RoleItemList.filter(s => s.id == value.id);
          if (_filteredItems != null && _filteredItems.length > 0) {
            vm.applicableDDLRoleList.push(_filteredItems[0]);
            vm.applicableDDLRoleList = vm.applicableDDLRoleList.slice(0);
          }
          break;
      }
    }
  }

 
  setprorateAccuralTypeValue(e) {// Created By A Prorate Accural Popup Related
    let _value = e.value;
    if (_value.toString() == '0: 1') {
      this.prorateAccuralType = "1";
    } else if (_value.toString() == '1: 2') {
      this.prorateAccuralType = "2";
    } else if (_value.toString() == '2: 3') {
      this.prorateAccuralType = "3";
    }
  }

  setProrateAccuralDaySelectList() {// Created By A Bind Prorate Accrual
    this.prorateAccuralDayList = [];
    for (let i = 1; i <= 31; i++) {
      let _text = this.getNumberWithOrdinal(i);
      let newOption = {
        id: i,
        text: _text
      };
      this.prorateAccuralDayList.push(newOption);
    }
  }
  openProrateAccuralModal() {// Created By A Initialize Prorate Accrual Modal
    let vm = this;
    let leaveTypeId = this.leaveTypeForm.controls.LeaveTypeId.value;
    vm.setProrateAccuralDaySelectList();

    if (vm.leaveTypeForm.value.LeaveTypeProrateAccural != null) {
      vm.prorateAccural = vm.leaveTypeForm.value.LeaveTypeProrateAccural;

      for (let i = 0; i < vm.prorateAccural.prorateAccuralFirstMonthList.length; i++) {
        vm.duplicateFirstMonthRuleFrom.push("true");
        vm.duplicateFirstMonthRuleTo.push("true");
      }
      for (let i = 0; i < vm.prorateAccural.prorateAccuralLastMonthList.length; i++) {
        vm.duplicateLastMonthRuleFrom.push("true");
        vm.duplicateLastMonthRuleTo.push("true");
      }
      vm._commonSer.OpenModalHandler("manageProrateAccuralModal");
    } else {
      vm.userLoader = true;
      vm._LeaveServices.getLeaveTypeProrateAccural(leaveTypeId)
        .subscribe((resLeaveType) => {
          vm.userLoader = false;
          vm.prorateAccural = resLeaveType["data"];
          for (let i = 0; i < vm.prorateAccural.prorateAccuralFirstMonthList.length; i++) {
            vm.duplicateFirstMonthRuleFrom.push("true");
            vm.duplicateFirstMonthRuleTo.push("true");
          }
          for (let i = 0; i < vm.prorateAccural.prorateAccuralLastMonthList.length; i++) {
            vm.duplicateLastMonthRuleFrom.push("true");
            vm.duplicateLastMonthRuleTo.push("true");
          }
          vm._commonSer.OpenModalHandler("manageProrateAccuralModal");
        });
    }
  }

  addFirstMonthRule() {// Created By A On add month rule
    let newRule = {
      leavePolicyPAFMId: 0,
      leaveTypeId: this.prorateAccural.leaveTypeId,
      from: null,
      to: null,
      count: null
    };
    this.duplicateFirstMonthRuleFrom.push("true");
    this.duplicateFirstMonthRuleTo.push("true");
    this.prorateAccural.prorateAccuralFirstMonthList.push(newRule);
  }
  addLastMonthRule() {// Created By A On add month rule
    let newRule = {
      leavePolicyPALMId: 0,
      leaveTypeId: this.prorateAccural.leaveTypeId,
      from: null,
      to: null,
      count: null
    };
    this.duplicateLastMonthRuleFrom.push("true");
    this.duplicateLastMonthRuleTo.push("true");
    this.prorateAccural.prorateAccuralLastMonthList.push(newRule);
  }

  duplicateFirstMonthRuleFrom = [];
  duplicateFirstMonthRuleTo = [];
  onFirstRuleFromSelected(e, i, _value) {// Created By A On Select From --First Rule
    let count1 = this.prorateAccural.prorateAccuralFirstMonthList.filter(item => item.from == _value).length;
    let count2 = this.prorateAccural.prorateAccuralFirstMonthList.filter(item => item.to == _value).length;
    if (count1 > 1 || count2 > 0) {
      this.duplicateFirstMonthRuleFrom[i] = "false";
    } else {
      this.duplicateFirstMonthRuleFrom[i] = "true";
    }
  }
  onFirstRuleToSelected(e, i, _value) {// Created By A On Select To -- First Rule
    let count1 = this.prorateAccural.prorateAccuralFirstMonthList.filter(item => item.from == _value).length;
    let count2 = this.prorateAccural.prorateAccuralFirstMonthList.filter(item => item.to == _value).length;
    if (count1 > 0 || count2 > 1) {
      this.duplicateFirstMonthRuleTo[i] = "false";
    } else {
      this.duplicateFirstMonthRuleTo[i] = "true";
    }
  }

  duplicateLastMonthRuleFrom = [];
  duplicateLastMonthRuleTo = [];
  onLastRuleFromSelected(e, i, _value) {// Created By A  On Select From --Last Rule
    let count1 = this.prorateAccural.prorateAccuralLastMonthList.filter(item => item.from == e.target.value).length;
    let count2 = this.prorateAccural.prorateAccuralLastMonthList.filter(item => item.to == e.target.value).length;
    if (count1 > 1 || count2 > 0) {
      this.duplicateLastMonthRuleFrom[i] = "false";
    } else {
      this.duplicateLastMonthRuleFrom[i] = "true";
    }
  }
  onLastRuleToSelected(e, i, _value) {// Created By A  On Select To --Last Rule
    let count1 = this.prorateAccural.prorateAccuralLastMonthList.filter(item => item.from == _value).length;
    let count2 = this.prorateAccural.prorateAccuralLastMonthList.filter(item => item.to == _value).length;
    if (count1 > 0 || count2 > 1) {
      this.duplicateLastMonthRuleTo[i] = "false";
    } else {
      this.duplicateLastMonthRuleTo[i] = "true";
    }
  }

  saveProrateAccural() {// Created By A On save Prorate Accrual
    const vm = this;
    if (vm.duplicateFirstMonthRuleFrom.includes("false") ||
      vm.duplicateFirstMonthRuleTo.includes("false") ||
      vm.duplicateLastMonthRuleFrom.includes("false") ||
      vm.duplicateLastMonthRuleTo.includes("false")) {
        return;
    }

    this.leaveTypeForm.patchValue({
      LeaveTypeProrateAccural: vm.prorateAccural
    });
    vm._commonSer.CloseModalHandler("manageProrateAccuralModal");
    // vm.userLoader = true;
    // debugger;
    // vm._LeaveServices.SaveProrateAccural(vm.prorateAccural).subscribe((data: any) => {
    //   vm.userLoader = false;
    //   if (data && data.success === true) {
    //     vm.showMessage = data.data;
    //     vm.showSuccess = true;

    //     vm._commonSer.OpenModalHandler('userMessageModel');
    //     vm._commonSer.CloseModalHandler('manageProrateAccuralModal');
    //   } else {
    //     console.log(data);
    //   }
    // }, error => {
    //   vm.userLoader = false;
    // });
  }
  // End of Prorate Accural Popup Related

  onChangeLeaveType(e) {// Created By A Set Value
    this.tempLeaveType.LType = e.srcElement.value;
    this.leaveTypeForm.patchValue({
      Unit: 1
    });
  }
  onChangeUnit(e, _value) {// Created By A Set Value
    this.tempLeaveType.Unit = _value;
    this.leaveTypeForm.patchValue({
      CanCountAsLeaveInWeekendBetweenLeave: false,
      WeekendBetweenLeaveCountAfter: 0
    });
  }
  onImageSelect(_path) {// Created By A Set Value
    this.tempLeaveType.ImagePath = _path;
    this.leaveTypeForm.patchValue({
      ImagePath: _path
    });
  }

  // Entitlement (Accural, Reset) Related


  onChangeIsAccural(e) {// Created By A On Accural
    this.tempLeaveType.IsAccural = !this.tempLeaveType.IsAccural;

    // Reset Values
    this.leaveTypeForm.patchValue({
      AccuralPeriod: 2,
      AccuralDay: 1,
      AccuralMonth: 1,
      AccuralNoOfDays: 0,
      AccuralDaysInPeriod: 1
    });

    this.tempLeaveType.AccuralPeriod = 2;
    this.tempLeaveType.AccuralDay = 1;
    this.tempLeaveType.AccuralMonth = 1;
    this.tempLeaveType.AccuralNoOfDays = 0;
    this.tempLeaveType.AccuralDaysInPeriod = 1;

    this.setAccuralFieldsData(this.tempLeaveType.AccuralPeriod.toString());
  }

  setAccuralFieldsData(_type) {// Created By A Bind accural day
    this.setAccuralDaySelectList(_type);
    this.setAccuralMonthSelectList(_type);

    let _accuralDay = 1, _accuralMonth = 1;
    switch (_type) {
      case "1" :
        _accuralDay = 72;
        _accuralMonth = 61;
      break;
      case "2" :
      case "3" :
        _accuralDay = 1;
        _accuralMonth = 1;
      break;
      case "4" :
        _accuralDay = 1;
        _accuralMonth = 21;
      break;
      case "5" :
        _accuralDay = 1;
        _accuralMonth = 31;
      break;
      case "6" :
        _accuralDay = 1;
        _accuralMonth = 41;
      break;
      case "7" :
        _accuralDay = 1;
        _accuralMonth = 51;
      break;
      case "8" :
        _accuralDay = 41;
        _accuralMonth = 1;
      break;
      case "9" :
        _accuralDay = 61;
        _accuralMonth = 1;
      break;
      case "10" :
        _accuralDay = 61;
        _accuralMonth = 1;
      break;
    }

    this.leaveTypeForm.patchValue({
      AccuralDay: _accuralDay,
      AccuralMonth: _accuralMonth
    });
  }

  setAccuralDaySelectList(_type) {// Created By A Set accural day list
    this.ddlAccuralDaysList = [];
    this.ddlAccuralDaysListfield = {value:"id", text: "text" }
    switch (_type) {
      case "1" :
      case "2" :
      case "3" :
      case "4" :
      case "5" :
      case "6" :
      case "7" :
        for (let i = 1; i <= 31; i++) {
          let _text = this.getNumberWithOrdinal(i);
          let newOption = {
            id: i,
            text: _text
          };
          this.ddlAccuralDaysList.push(newOption);
        }
      break;
      case "8" :
        for (let i = 1; i <= 15; i++) {
          let _text = "" + i + " & " + (i + 15) + "";
          if (i == 15) {
            _text = "" + i + " & Last Day";
          }
          let newOption = {
            id: i + 40,
            text: _text
          };
          this.ddlAccuralDaysList.push(newOption);
        }
      break;
      case "9" :
      case "10" :
        this.ddlAccuralDaysList.push({ id: 61, text: "Sun" });
        this.ddlAccuralDaysList.push({ id: 62, text: "Mon" });
        this.ddlAccuralDaysList.push({ id: 63, text: "Tue" });
        this.ddlAccuralDaysList.push({ id: 64, text: "Wed" });
        this.ddlAccuralDaysList.push({ id: 65, text: "Thu" });
        this.ddlAccuralDaysList.push({ id: 66, text: "Fri" });
        this.ddlAccuralDaysList.push({ id: 67, text: "Sat" });
      break;
    }
    if (_type != "8" && _type != "9" && _type != "10") {
      this.ddlAccuralDaysList.push({ id: 71, text: "Last Day" });
      this.ddlAccuralDaysList.push({ id: 72, text: "Policy Date" });
      this.ddlAccuralDaysList.push({ id: 73, text: "Joining Date" });
      this.ddlAccuralDaysList.push({ id: 74, text: "Birth Date" });
    }
  }

  setAccuralMonthSelectList(_type) {// Created By A On Change accular month list
    this.ddlAccuralMonthsList = [];
    this.ddlAccuralMonthsListfield = {value:"value", text: "name" }
    switch (_type) {
      case "1" :
      case "2" :
        this.ddlAccuralMonthsList = [
          { value: 1, name: "Jan" },
          { value: 2, name: "Feb" },
          { value: 3, name: "Mar" },
          { value: 4, name: "Apr" },
          { value: 5, name: "May" },
          { value: 6, name: "Jun" },
          { value: 7, name: "Jul" },
          { value: 8, name: "Aug" },
          { value: 9, name: "Sep" },
          { value: 10, name: "Oct" },
          { value: 11, name: "Nov" },
          { value: 12, name: "Dec" },
          // Extra values
          { value: 61, name: "Policy Month" },
          { value: 62, name: "Joining Month" },
          { value: 63, name: "Birth Month" }
        ];
        break;
      case "3" :
      case "8" :
      case "9" :
      case "10" :
        this.ddlAccuralMonthsList = [];
        break;
      case "4" :
        this.ddlAccuralMonthsList = [
          { value: 21, name: "Jan & Jul" },
          { value: 22, name: "Feb & Aug" },
          { value: 23, name: "Mar & Sep" },
          { value: 24, name: "Apr & Oct" },
          { value: 25, name: "May & Nov" },
          { value: 26, name: "Jun & Dec" },
          // Extra values
          { value: 61, name: "Policy Month" },
          { value: 62, name: "Joining Month" },
          { value: 63, name: "Birth Month" }
        ];
        break;
      case "5" :
        this.ddlAccuralMonthsList = [
          { value: 31, name: "Jan & May & Sep" },
          { value: 32, name: "Feb & Jun & Oct" },
          { value: 33, name: "Mar & Jul & Nov" },
          { value: 34, name: "Apr & Aug & Dec" },
          // Extra values
          { value: 61, name: "Policy Month" },
          { value: 62, name: "Joining Month" },
          { value: 63, name: "Birth Month" }
        ];
        break;
      case "6" :
        this.ddlAccuralMonthsList = [
          { value: 41, name: "Jan & Apr & Jul & Oct" },
          { value: 42, name: "Feb & May & Aug & Nov" },
          { value: 43, name: "Mar & Jun & Sep & Dec" },
          // Extra values
          { value: 61, name: "Policy Month" },
          { value: 62, name: "Joining Month" },
          { value: 63, name: "Birth Month" }
        ];
        break;
      case "7" :
        this.ddlAccuralMonthsList = [
          { value: 51, name: "Jan & Mar & May & Jul & Sep & Nov" },
          { value: 52, name: "Feb & Apr & Jun & Aug & Oct & Dec" },
          // Extra values
          { value: 61, name: "Policy Month" },
          { value: 62, name: "Joining Month" },
          { value: 63, name: "Birth Month" }
        ];
        break;
    }
  }
  isCheck:boolean=true

  onChangeAccuralPeriod(e) {// Created By A On change accural period
    debugger
    if(e.value==1)
    {
      this.isCheck=false

    }
    else
    {
      this.isCheck=true
    }
    this.tempLeaveType.AccuralPeriod = e.value;
    let _type = this.tempLeaveType.AccuralPeriod.toString();

    this.setAccuralFieldsData(_type);
  }


 
  onChangeIsReset(e) {// Created By A on select reset 
    debugger;
    this.tempLeaveType.IsReset = !this.tempLeaveType.IsReset;

    // Reset Values
    this.leaveTypeForm.patchValue({
      ResetPeriod: 2,
      ResetDay: 71,
      ResetMonth: 12,
      CarryForwardType: 1,
      CarryForwardValue: 0,
      CarryForwardPercentageUnit: 1,
      CarryForwardMaxLimit: null,
      EncashmentValue: 0,
      EncashmentPercentageUnit: 1,
      EncashmentMaxLimit: null
    });

    this.tempLeaveType.ResetPeriod = 2;
    this.tempLeaveType.ResetDay = 71;
    this.tempLeaveType.ResetMonth = 12;
    this.tempLeaveType.CarryForwardPercentageUnit = 1;
    this.tempLeaveType.EncashmentPercentageUnit = 1;

    this.setResetFieldsData(this.tempLeaveType.ResetPeriod.toString());
  }

  setResetFieldsData(_type) {// Created By A
    this.setResetDaySelectList(_type);
    this.setResetMonthSelectList(_type);

    let _resetDay = 71, _resetMonth = 12;
    switch (_type) {
      case "1" :
        _resetDay = 72;
        _resetMonth = 61;
      break;
      case "2" :
      case "3" :
        _resetDay = 71;
        _resetMonth = 12;
      break;
      case "4" :
        _resetDay = 71;
        _resetMonth = 26;
      break;
      case "5" :
        _resetDay = 71;
        _resetMonth = 34;
      break;
      case "6" :
        _resetDay = 71;
        _resetMonth = 43;
      break;
      case "7" :
        _resetDay = 71;
        _resetMonth = 52;
      break;
      case "8" :
        _resetDay = 55;
        _resetMonth = 12;
      break;
      case "9" :
        _resetDay = 61;
        _resetMonth = 12;
      break;
      case "10" :
        _resetDay = 61;
        _resetMonth = 12;
      break;
    }

    this.leaveTypeForm.patchValue({
      ResetDay: _resetDay,
      ResetMonth: _resetMonth
    });
  }

  setResetDaySelectList(_type) {// Created By A Set Reset Days List Values
    this.ddlResetDaysList = [];
    this.ddlResetDaysListfield = {value: "id", text: "text"};
    switch (_type) {
      case "1" :
      case "2" :
      case "3" :
      case "4" :
      case "5" :
      case "6" :
      case "7" :
        for (let i = 1; i <= 31; i++) {
          let _text = this.getNumberWithOrdinal(i);
          let newOption = {
            id: i,
            text: _text
          };
          this.ddlResetDaysList.push(newOption);
        }
      break;
      case "8" :
        for (let i = 1; i <= 15; i++) {
          let _text = "" + i + " & " + (i + 15) + "";
          if (i == 15) {
            _text = "" + i + " & Last Day";
          }
          let newOption = {
            id: i + 40,
            text: _text
          };
          this.ddlResetDaysList.push(newOption);
        }
      break;
      case "9" :
      case "10" :
        this.ddlResetDaysList.push({ id: 61, text: "Sun" });
        this.ddlResetDaysList.push({ id: 62, text: "Mon" });
        this.ddlResetDaysList.push({ id: 63, text: "Tue" });
        this.ddlResetDaysList.push({ id: 64, text: "Wed" });
        this.ddlResetDaysList.push({ id: 65, text: "Thu" });
        this.ddlResetDaysList.push({ id: 66, text: "Fri" });
        this.ddlResetDaysList.push({ id: 67, text: "Sat" });
      break;
    }
    if (_type != "8" && _type != "9" && _type != "10") {
      this.ddlResetDaysList.push({ id: 71, text: "Last Day" });
      this.ddlResetDaysList.push({ id: 72, text: "Policy Date" });
      this.ddlResetDaysList.push({ id: 73, text: "Joining Date" });
      this.ddlResetDaysList.push({ id: 74, text: "Birth Date" });
    }
  }

  setResetMonthSelectList(_type) {// Created By A Set Reset Months List Values
    this.ddlResetMonthsList = [];
    this.ddlResetMonthsListfield = {value:"value", text: "name"};
    switch (_type) {
      case "1" :
      case "2" :
        this.ddlResetMonthsList = [
          { value: 1, name: "Jan" },
          { value: 2, name: "Feb" },
          { value: 3, name: "Mar" },
          { value: 4, name: "Apr" },
          { value: 5, name: "May" },
          { value: 6, name: "Jun" },
          { value: 7, name: "Jul" },
          { value: 8, name: "Aug" },
          { value: 9, name: "Sep" },
          { value: 10, name: "Oct" },
          { value: 11, name: "Nov" },
          { value: 12, name: "Dec" },
          // Extra values
          { value: 61, name: "Policy Month" },
          { value: 62, name: "Joining Month" },
          { value: 63, name: "Birth Month" }
        ];
        break;
      case "3" :
      case "8" :
      case "9" :
      case "10" :
        this.ddlResetMonthsList = [];
        break;
      case "4" :
        this.ddlResetMonthsList = [
          { value: 21, name: "Jan & Jul" },
          { value: 22, name: "Feb & Aug" },
          { value: 23, name: "Mar & Sep" },
          { value: 24, name: "Apr & Oct" },
          { value: 25, name: "May & Nov" },
          { value: 26, name: "Jun & Dec" },
          // Extra values
          { value: 61, name: "Policy Month" },
          { value: 62, name: "Joining Month" },
          { value: 63, name: "Birth Month" }
        ];
        break;
      case "5" :
        this.ddlResetMonthsList = [
          { value: 31, name: "Jan & May & Sep" },
          { value: 32, name: "Feb & Jun & Oct" },
          { value: 33, name: "Mar & Jul & Nov" },
          { value: 34, name: "Apr & Aug & Dec" },
          // Extra values
          { value: 61, name: "Policy Month" },
          { value: 62, name: "Joining Month" },
          { value: 63, name: "Birth Month" }
        ];
        break;
      case "6" :
        this.ddlResetMonthsList = [
          { value: 41, name: "Jan & Apr & Jul & Oct" },
          { value: 42, name: "Feb & May & Aug & Nov" },
          { value: 43, name: "Mar & Jun & Sep & Dec" },
          // Extra values
          { value: 61, name: "Policy Month" },
          { value: 62, name: "Joining Month" },
          { value: 63, name: "Birth Month" }
        ];
        break;
      case "7" :
        this.ddlResetMonthsList = [
          { value: 51, name: "Jan & Mar & May & Jul & Sep & Nov" },
          { value: 52, name: "Feb & Apr & Jun & Aug & Oct & Dec" },
          // Extra values
          { value: 61, name: "Policy Month" },
          { value: 62, name: "Joining Month" },
          { value: 63, name: "Birth Month" }
        ];
        break;
    }
  }

  onChangeResetPeriod(e) {// Created By K Set Reset Period
    this.tempLeaveType.ResetPeriod = e.value;
    let _type = this.tempLeaveType.ResetPeriod.toString();

    this.setResetFieldsData(_type);
  }
  onChangeCarryForwardType(e) {// Created By K On Select Carry Forward
    this.tempLeaveType.CarryForwardType = e.value;

    this.leaveTypeForm.patchValue({
      CarryForwardExpiresIn: null,
      CarryForwardExpiresInType: null,
      CarryForwardOverallLimit: null
    });

    if (this.tempLeaveType.CarryForwardType == 2) {
      this.leaveTypeForm.patchValue({
        CarryForwardExpiresIn: null,
        CarryForwardExpiresInType: 1,
        CarryForwardOverallLimit: null
      });
    }
  }
  onChangeCarryForwardPercentageUnit(e) {// Created By K Set Value
    debugger
    this.tempLeaveType.CarryForwardPercentageUnit = e.value;
  }
  onChangeEncashmentPercentageUnit(e) {// Created By K Set value
    debugger
    this.tempLeaveType.EncashmentPercentageUnit = e.value;
  }
  // End of Entitlement (Accural, Reset) Related


  
  openEntitlementCalendarPopup() {// Created By A Entitlement Popup Related
    this._commonSer.OpenModalHandler('manageEntitlementCalendar');
  }
  createEntitlementCalendarData() {// Created By A Intialize Entitlement Calendar
    let entitlementsList = []; 
    let _count = 0;
    let _leaveTypeId = 0;

    if (this.tempLeaveType.AccuralNoOfDays != null && this.tempLeaveType.AccuralNoOfDays > 0) {
      _count = this.tempLeaveType.AccuralNoOfDays;
    }
    if (this.tempLeaveType.LeaveTypeId != null && this.tempLeaveType.LeaveTypeId > 0) {
      _leaveTypeId = this.tempLeaveType.LeaveTypeId;
    }

    for (let i = 1; i <= 12; i++) {
      let _month = "";
      switch (i) {
        case 1:
          _month = "Jan";
          break;
        case 2:
          _month = "Feb";
          break;
        case 3:
          _month = "Mar";
          break;
        case 4:
          _month = "Apr";
          break;
        case 5:
          _month = "May";
          break;
        case 6:
          _month = "Jun";
          break;
        case 7:
          _month = "Jul";
          break;
        case 8:
          _month = "Aug";
          break;
        case 9:
          _month = "Sep";
          break;
        case 10:
          _month = "Oct";
          break;
        case 11:
          _month = "Nov";
          break;
        case 12:
          _month = "Dec";
          break;
      }
      entitlementsList.push({ LeavePolicyEntitlementCalendarId: 0, LeaveTypeId: _leaveTypeId, Month: i, MonthText: _month, Count: _count });
    }

    // this.leaveTypeForm.patchValue({
    //   EntitlementCalendarList: entitlementsList
    // });
    this.tempLeaveType.EntitlementCalendarList = entitlementsList;
  }
  setEntitlementCalendarData(list) {// Created By A On Set Entitlement
    let entitlementsList = [];
    for (let i = 0; i < list.length; i++) {
      let _newItem = {
        LeavePolicyEntitlementCalendarId: list[i].leavePolicyEntitlementCalendarId,
        LeaveTypeId: list[i].leaveTypeId,
        Month: list[i].month,
        MonthText: list[i].monthText,
        Count: list[i].count
      };
      entitlementsList.push(_newItem);
    }
    this.tempLeaveType.EntitlementCalendarList = entitlementsList;
  }
  onchangeAccuralNoOfDays(e) {// Created By A On Chage Accural
    let _value = e.target.value;
    for (let i = 0; i < this.tempLeaveType.EntitlementCalendarList.length; i++) {
      this.tempLeaveType.EntitlementCalendarList[i].Count = _value;
    }
  }
  // End of Entitlement Popup Related



  setMoreOptionData(_type, isEnable) {// Created By A Entitlement More Options
    switch (_type) {
      case "OpeningBalance" :
        this.IsOpeningBalanceEnable = isEnable;
        this.leaveTypeForm.patchValue({
          IsOpeningBalanceEnable: isEnable,
          OpeningBalance: 0
        });
        break;
      case "MaximumBalance" :
        this.IsMaximumBalanceEnable = isEnable;
        this.leaveTypeForm.patchValue({
          IsMaximumBalanceEnable: isEnable,
          MaximumBalance: 0
        });
        break;
      case "ProrateAccural" :
        this.IsProrateAccuralEnable = isEnable;
        this.leaveTypeForm.patchValue({
          IsProrateAccuralEnable: isEnable,
          ProrateAccuralType: 1
        });
        this.prorateAccuralType = "1";
        break;
      case "DeductibleHolidays" :
        this.IsDeductibleHolidaysEnable = isEnable;
        this.leaveTypeForm.patchValue({
          IsDeductibleHolidaysEnable: isEnable,
          DeductibleHolidays: 1
        });
        break;
    }
  }
  // End of Entitlement More Options


  
  addApplicableField(_type, isEnable) {// Created By A Add Field in Applicable
    switch (_type) {
      case "ApplicableEmployeeTypes" :
        this.IsEmployeeTypes = isEnable;
        this.applicableEmployeeTypesList = [];
        this.leaveTypeForm.patchValue({
          ApplicableEmployeeTypes: []
        });
        break;
      case "ApplicableSourceOfHire" :
        this.IsSourceOfHire = isEnable;
        this.applicableSourceOfHireList = [];
        this.leaveTypeForm.patchValue({
          ApplicableSourceOfHire: []
        });
        break;
      case "ApplicableJobBands" :
        this.IsJobBands = isEnable;
        this.applicableJobBandsList = [];
        this.leaveTypeForm.patchValue({
          ApplicableJobBands: []
        });
        break;
      case "ApplicableOnboardingStatus" :
        this.IsOnboardingStatus = isEnable;
        this.applicableOnboardingStatusList = [];
        this.leaveTypeForm.patchValue({
          ApplicableOnboardingStatus: []
        });
        break;
    }
  }
  // End of Entitlement More Options

  ChangeStatus(data) {// Created By A On Change Status
    debugger;
    this.userLoader = true;
    data.isActive = !data.isActive;
    this._LeaveServices.updateLeaveTypeStatus(data.leaveTypeId, data.isActive, this.CmpCode).subscribe((res: any) => {
    
      if(res["success"]){
        this.userLoader = false;
        this.toaster.show("success", res["data"])
      }
      else{
        this.userLoader = false;
        this.toaster.show("error" , res.message)
      }
      
      this.getAllLeaveTypeData();
    });
  }

  
  closeDropdownList(element) { // Created By A Close Opened Dropdowns when clicking on new dropdown
    if (element.optionsOpened == true) {
      let elementsToclose = this.selectElements.filter(function (el: any) {
        return (el != element && el.optionsOpened == true);
      });
      elementsToclose.forEach(function (e: SelectComponent) {
        e.clickedOutside();
      });
    }
  }
  active:any=0
  getTotalLeave(val){// Created By A Filter Leave-Type List
    debugger;
    if(val==1)
    {
      this.active=1
      this.allLeaveTypeList=this.totalMyRequestList

    }
    else
    if(val==2)
    {
      this.active=2

      this.allLeaveTypeList=[]
      this.allLeaveTypeList= this.totalMyRequestList.filter(user => user.isActive === true );
    }
    else if(val==3)
    {
      this.active=3

      this.allLeaveTypeList=[]
      this.allLeaveTypeList= this.totalMyRequestList.filter(user => user.isActive === false );
    }
 

  }
}
