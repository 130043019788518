import { DynamicControlBase } from "./dynamic-control-base";

export class DynamicControlDropdown extends DynamicControlBase<string> {
  controlType = 'dropdown';
  type: string;
  options: {id: string, text: string}[] = [];

  constructor(options: {} = {}) {
    super(options);
    this.type = options['type'] || '';
    this.options = options['options'] || [];
  }
}
