<div class="bg-overlady" *ngIf="userLoader">
  <div class="loader-position">
    <div class="loader"></div>
    <h2>Loading....</h2>
  </div>
</div>

<div class="row" style="margin:0;">

  <div class="col-sm-4 no-padding">
    <h4 class="title-new">Schedule Services</h4>
  </div>
  <div class="col-md-4 no-padding text-center">
    <ul class="new-version">
      <li  [ngClass]="{'activelegend': active === 'total'}" (click)="Total()"  style="cursor:pointer">Total : <span class="value-get get-b">{{TotalListLength}}</span></li>
      <li  [ngClass]="{'activelegend': active === 'active'}" (click)="Active()" style="cursor:pointer">Active : <span class="value-get get-g">{{ActiveLength}}</span></li>
      <li  [ngClass]="{'activelegend': active === 'inactive'}" (click)="Inactive()" style="cursor:pointer">In- Active : <span class="value-get get-o">{{InActiveLength}}</span></li>
    </ul>
  </div>
  <div class="col-sm-4 no-padding">
    <div class="col-md-2"></div>
    <div class="col-md-5 col-sm-3">
      <!-- <ejs-dropdownlist id='ddllocation' [(ngModel)]="filterServiceId"  (change)="onChangeFilterServiceId($event);" name="location"  [fields]='EmployeeFields' [dataSource]='ddlServiceList'   placeholder = 'Select Service'></ejs-dropdownlist>  -->
      <!-- <select class="form-control" [(ngModel)]="filterServiceId" (change)="onChangeFilterServiceId($event);" style="margin-top: -4px;">
        <option value=null>All Request Types</option>
        <option *ngFor="let item of ddlServiceList" value="{{item.id}}">{{ item.text }}</option>
      </select> -->
    </div>
    <div class="col-md-5 col-sm-7 text-right btnset" style="padding-top: 46px;">
      <button type="button" *ngIf="AddPermission" data-toggle="modal" data-backdrop="static" data-keyboard="false"
         data-target="#addApprovalpopup" class="btn btn-primary btn-brand sm mx-auto" (click)="resetForm()"
        title="Add Approval">
        <img src="../assets/img/Logo/20.png">
      </button>

    </div>
  </div>

  <div class="col-md-12 col-xs-12 no-padding">


      <div class="row">
        <div class="col-sm-12 no-padding">
          <ejs-grid #overviewgrid id='overviewgrid' [dataSource]='ServiceList' rowHeight='38' [allowSelection]='true'
            [allowSorting]='true' height='600' [enableHover]='false' [selectionSettings]='selectionSettings'
            [filterSettings]='filterSettings'  [allowFiltering]='true'
            [enableVirtualization]='false'  [allowPaging]='true' [pageSettings]='pageSettings'>
            <e-columns>
              <e-column field='serviceName' headerText='Service Name' [filter]='filter' clipMode='EllipsisWithTooltip'>

              </e-column>

              <e-column field='scheduletime' headerText='Schedule Time (hh:mm)' [filter]='filter' clipMode='EllipsisWithTooltip'>
                <!-- <ng-template #template let-data>
                  <i class="fa fa-calendar"></i>
                  {{dateformat(data.scheduletime)}}
                </ng-template> -->
              </e-column>

              <e-column field='isactive' headerText='Status' [filter]='filter'   [allowSorting]='false' clipMode='EllipsisWithTooltip'>
                <ng-template #template let-data>
                <label class="switch1" >
                  <input type="checkbox" [value]="data.isactive" [checked]="data.isactive" #active (change)="SaveEditservice( data ,'update', active.value)"  name="Status">
                  <div class="slider1 round"></div>
                </label>
              </ng-template>
              </e-column>

              <e-column field='isactive' headerText='Action'  [allowFiltering]='false' width="110"  clipMode='EllipsisWithTooltip'>
                <ng-template #template  let-data>
                  <div class="btn-group btngg">
                    <button type="button" class="btn action-button" data-toggle="dropdown" aria-haspopup="true"
                      aria-expanded="false" title="Action">
                      <span style="margin-top: -16px;">...</span>
                    </button>
                    <ul class="dropdown-menu actions-dropdown">
                       <li >
                        <a data-toggle="modal" data-target="#addApprovalpopup"
                        (click)="viewService(data)">View
                        </a>
                      </li>

                      <li >
                        <a data-toggle="modal" data-target="#addApprovalpopup"
                        (click)="editservice(data)" >Edit

                        </a>
                      </li>



                    </ul>
                  </div>
                </ng-template>
              </e-column>
            </e-columns>
          </ejs-grid>
        </div>
      </div>


  </div>
</div>

<div id="addApprovalpopup" class="modal fade" role="dialog">
  <div class="modal-dialog modal-md">
    <div class="modal-content" >
      <div class="modal-header">
        <button _ngcontent-c11="" class="close" data-dismiss="modal" type="button">×</button>
        <h4 class="modal-title">{{add}} Schedule Service Time</h4>
      </div>
      <form [formGroup]="scheduleServiceForm" name="emailTemplates"  autocomplete="off" (submit)="SaveSchedule()" >
        <div class="modal-body model-bg user-modal-height col-xs-12 col-sm-12 less" style="background-color: #fff;padding-top: 20px;">
          <div class="col-xs-12 col-sm-12">
            <div class="m-portlet__body">
              <input type="hidden" id="emailTemplateId"
                name="emailTemplateId"  />
              <input type="hidden" id="isActive"
                name="isActive" />

              <div class="form-group row">
                <div class="col-sm-6">
                  <label class="control-label">Service Name</label>&nbsp;<span class="text-danger"></span>
                  <ejs-dropdownlist [allowFiltering]="true" [enabled]="saveEdit" formControlName="scheduleid"  [dataSource]="seriveNames" [fields]='serviceFields' id='userAvalLeave'> </ejs-dropdownlist>
                    <!-- <div *ngIf="(u.submitted)" class="invalid-feedback error">
                      <div *ngIf="( selectedService != null ? false : true)">Service Name is required</div>
                    </div> -->
                </div>
                <div class="col-sm-6">
                  <label class="control-label">Running Time</label>&nbsp;<span class="text-danger"></span>
                  <ejs-dropdownlist [allowFiltering]="true" [enabled]="saveEdit" formControlName="scheduletime" *ngIf="timeFormat == 0"  [dataSource]="Timeformat" [fields]='timeFields' id='time'> </ejs-dropdownlist>

                  <ejs-dropdownlist [allowFiltering]="true" [enabled]="saveEdit" formControlName="scheduletime" *ngIf="timeFormat == 1"   [dataSource]="Timeformat24" [fields]='timeFields' id='time'> </ejs-dropdownlist>
                  <!-- <div *ngIf="(u.submitted && templateName.invalid)" class="invalid-feedback error">
                    <div *ngIf="(templateName.errors != null ? templateName.errors.required : false)">Name is required</div>
                  </div> -->
                </div>

              </div>



              <div class="form-group row">
                <div class="col-sm-6" *ngIf="scheduleServiceForm.value.scheduleid == 2">
                  <label class="control-label">Running Day</label>&nbsp;<span class="text-danger"></span>
                  <ejs-dropdownlist [allowFiltering]="true" [enabled]="saveEdit"  formControlName="scheduleDay" [dataSource]="DaysList" [fields]='daysField' id='userAvalLeave'> </ejs-dropdownlist>
                    <!-- <div *ngIf="(u.submitted)" class="invalid-feedback error">
                      <div *ngIf="( selectedService != null ? false : true)">Service Name is required</div>
                    </div> -->
                </div>
                <div class="col-sm-6">
                  <label class="control-label">Status</label>&nbsp;<span class="text-danger"></span>
                  <label class="switch1" >
                    <input type="checkbox" formControlName="isactive"  name="Status">
                    <div class="slider1 round"></div>
                  </label>
                  <!-- <div *ngIf="(u.submitted && subject.invalid)" class="invalid-feedback error">
                    <div *ngIf="(subject.errors != null ? subject.errors.required : false)">Subject is required</div>
                  </div> -->
                </div>
              </div>

              <div class="form-group row">
        
                <div class="col-sm-6" *ngIf="scheduleServiceForm.value.scheduleid == 2">
                  <label class="control-label">Report Data</label>&nbsp;<span class="text-danger"></span>
                  <ejs-dropdownlist [allowFiltering]="true" [enabled]="saveEdit"  formControlName="scheduleWeek" [dataSource]="weekList" [fields]='daysField' > </ejs-dropdownlist>                  
                </div>
                <div class="col-sm-6">
               
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="row" >
            <div class="col-sm-12 text-left" *ngIf="save">
              <button id="btnSave" style="margin:10px 0; padding: 7px 30px !IMPORTANT;" type="submit" class="btn btn-primary btn-brand"> Save </button>
              <span>&nbsp;&nbsp;&nbsp;</span>
            </div>
            <div class="col-sm-12 text-left" *ngIf="update">
              <button id="btnUpdate" style="margin:10px 0; padding: 7px 30px !IMPORTANT;" type="button" class="btn btn-primary btn-brand" (click)="SaveEditservice(null,'edit', null)"> Update </button>
              <span>&nbsp;&nbsp;&nbsp;</span>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>





