import { Component, OnInit, ViewChildren, QueryList, ViewChild } from '@angular/core';
import { CommonService } from "src/app/common.service";
import { AuthService } from "src/app/services/auth.service";
import { DepartmentService } from "src/app/+layout/department/department.service";
import { DesignationService } from "src/app/+layout/designation/designation.service";
import { LocationService } from "src/app/+layout/location/location.service";
import { SelectComponent } from "ng2-select/ng2-select";
import { LeaveService } from "../../leave.service";
import { Permission } from 'src/app/permission-keyword';
import { ExpenseHttpService } from 'src/app/services/expense/expense-http.service';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { ToasterService } from 'src/app/+layout/expense/toaster/toaster.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-scheduleServices',
  templateUrl: './scheduleServices.component.html',
  styleUrls: ['./scheduleServices.component.css']
})
export class ScheduleServicesComponent implements OnInit {
  seriveNames: any;
  cmpCode;
  UserId;
  ServiceList: any;
  add = "Add"
  EditPermission: boolean;
  DeletePermission: boolean;
  ViewPermission: boolean;
  StatusPermission: boolean;
  update: boolean;
  userLoader: boolean = false;
  TotalListLength = 0
  reportTimimgs
  ActiveLength = 0
  InActiveLength = 0
  save = true
  public form = FormGroup;
  active = "total"
  AddPermission = true
  formatString = "HH:mm"
  filterSettings = { type: "Menu" };
  public pageSettings = { pageCount: 3 };
  filter = { type: "CheckBox" };
  selectionSettings = { persistSelection: true, type: "Multiple", checkboxOnly: true };
  public serviceFields: Object = { text: 'serviceName', value: 'scheduleid' };
  timeFields = {text:"text", value: "id"}
  @ViewChild('overviewgrid')
  public gridInstance: GridComponent;
  daysField = {text:"text", value: "id"}
  DaysList = [
    {id:"Sunday", text: "Sunday"},
    {id:"Monday", text: "Monday"},
    {id:"Tuesday", text: "Tuesday"},
    {id:"Wednesday", text: "Wednesday"},
    {id:"Thursday", text: "Thursday"},
    {id:"Friday", text: "Friday"},
    {id:"Saturday", text: "Saturday"},
  
]
weekList = [
  {id:"Current", text: "Current Week"},
  {id:"Previous", text: "Previous Week"},

]
  Timeformat = [
        {id:"12:00 AM", text: "12:00 AM"},{id:"12:30 AM", text: "12:30 AM"},
        {id:"01:00 AM", text: "01:00 AM"},{id:"01:30 AM", text: "01:30 AM"},
        {id:"02:00 AM", text: "02:00 AM"},{id:"02:30 AM", text: "02:30 AM"},
        {id:"03:00 AM", text: "03:00 AM"},{id:"03:30 AM", text: "03:30 AM"},
        {id:"04:00 AM", text: "04:00 AM"},{id:"04:30 AM", text: "04:30 AM"},
        {id:"05:00 AM", text: "05:00 AM"},{id:"05:30 AM", text: "05:30 AM"},
        {id:"06:00 AM", text: "06:00 AM"},{id:"06:30 AM", text: "06:30 AM"},
        {id:"07:00 AM", text: "07:00 AM"},{id:"07:30 AM", text: "07:30 AM"},
        {id:"08:00 AM", text: "08:00 AM"},{id:"08:30 AM", text: "08:30 AM"},
        {id:"09:00 AM", text: "09:00 AM"},{id:"09:30 AM", text: "09:30 AM"},
        {id:"10:00 AM", text: "10:00 AM"},{id:"10:30 AM", text: "10:30 AM"},
        {id:"11:00 AM", text: "11:00 AM"},{id:"11:30 AM", text: "11:30 AM"},
        {id:"12:00 PM", text: "12:00 PM"},{id:"12:30 PM", text: "12:30 PM"},
        {id:"01:00 PM", text: "01:00 PM"},{id:"01:30 PM", text: "01:30 PM"},
        {id:"02:00 PM", text: "02:00 PM"},{id:"02:30 PM", text: "02:30 PM"},
        {id:"03:00 PM", text: "03:00 PM"},{id:"03:30 PM", text: "03:30 PM"},
        {id:"04:00 PM", text: "04:00 PM"},{id:"04:30 PM", text: "04:30 PM"},
        {id:"05:00 PM", text: "05:00 PM"},{id:"05:30 PM", text: "05:30 PM"},
        {id:"06:00 PM", text: "06:00 PM"},{id:"06:30 PM", text: "06:30 PM"},
        {id:"07:00 PM", text: "07:00 PM"},{id:"07:30 PM", text: "07:30 PM"},
        {id:"08:00 PM", text: "08:00 PM"},{id:"08:30 PM", text: "08:30 PM"},
        {id:"09:00 PM", text: "09:00 PM"},{id:"09:30 PM", text: "09:30 PM"},
        {id:"10:00 PM", text: "10:00 PM"},{id:"10:30 PM", text: "10:30 PM"},
        {id:"11:00 PM", text: "11:00 PM"},{id:"11:30 PM", text: "11:30 PM"},

 
  ]

  Timeformat24 = [
        {id:"00:00", text: "00:00"},{id:"00:30", text: "00:30"},
        {id:"01:00", text: "01:00"},{id:"01:30", text: "01:30"},
        {id:"02:00", text: "02:00"},{id:"02:30", text: "02:30"},
        {id:"03:00", text: "03:00"},{id:"03:30", text: "03:30"},
        {id:"04:00", text: "04:00"},{id:"04:30", text: "04:30"},
        {id:"05:00", text: "05:00"},{id:"05:30", text: "05:30"},
        {id:"06:00", text: "06:00"},{id:"06:30", text: "06:30"},
        {id:"07:00", text: "07:00"},{id:"07:30", text: "07:30"},
        {id:"08:00", text: "08:00"},{id:"08:30", text: "08:30"},
        {id:"09:00", text: "09:00"},{id:"09:30", text: "09:30"},
        {id:"10:00", text: "10:00"},{id:"10:30", text: "10:30"},
        {id:"11:00", text: "11:00"},{id:"11:30", text: "11:30"},
        {id:"12:00", text: "12:00"},{id:"12:30", text: "12:30"},
        {id:"13:00", text: "13:00"},{id:"13:30", text: "13:30"},
        {id:"14:00", text: "14:00"},{id:"14:30", text: "14:30"},
        {id:"15:00", text: "15:00"},{id:"15:30", text: "15:30"},
        {id:"16:00", text: "16:00"},{id:"16:30", text: "16:30"},
        {id:"17:00", text: "17:00"},{id:"17:30", text: "17:30"},
        {id:"18:00", text: "18:00"},{id:"18:30", text: "18:30"},
        {id:"19:00", text: "19:00"},{id:"19:30", text: "19:30"},
        {id:"20:00", text: "20:00"},{id:"20:30", text: "20:30"},
        {id:"21:00", text: "21:00"},{id:"21:30", text: "21:30"},
        {id:"22:00", text: "22:00"},{id:"22:30", text: "22:30"},
        {id:"23:00", text: "23:00"},{id:"23:30", text: "23:30"},

  ]
  scheduleServiceForm:FormGroup
  saveEdit: boolean = false;
  timeFormat: any;
  totalServiceList: any;

  constructor(private _leaveService: LeaveService,
    private _commonSer: CommonService,
    private _authSer: AuthService,
    private _depratmentSer: DepartmentService,
    private toaster: ToasterService,
    private Formbild: FormBuilder,

    private Http: ExpenseHttpService){

      

  }
 

ngOnInit(){// Angular Lifecycle hook
    debugger
    this.userLoader = false;
    this.cmpCode = this._authSer.getcompanyCode()
    this.UserId = this._authSer.getUserId();
    this.getAllSeviceList();
    this.timeFormat = this._authSer.gettimeformat()
    this.scheduleServiceForm = this.Formbild.group({
      id : [0],
      scheduleid: [1, Validators.required],
      scheduletime: ["" , Validators.required],
      isactive : [1, Validators.required],
      scheduleDay : ["Monday"],
      scheduleWeek : ['Current'],
      cmpcode :[0],
      type : [1],
      userid: [0]
    })

    this._authSer.currentUser.subscribe((res) => {
      this.AddPermission = true; //res.permission.includes(Permission.ApprovalSettingsAdd);
      this.EditPermission = true; // res.permission.includes(Permission.ApprovalSettingsEdit);
      this.DeletePermission = true; // res.permission.includes(Permission.ApprovalSettingsDelete);
      this.ViewPermission = true; // res.permission.includes(Permission.ApprovalSettingsView);
      this.StatusPermission = true; // res.permission.includes(Permission.ApprovalSettingsStatus);
    });
    try {
      this.Http.getScheduleServices().then( res =>{
        if(res.success){
          this.seriveNames = res.data.table;
        }else{
          console.log(res.success)
        }
        
      });
    } catch (error) {
      this.toaster.show("error","Uh Oh, Something went wrong !")
    }
   
  }
  resetForm(){// Created By K reset Forms
    this.scheduleServiceForm.reset()
    this.save = true
    this.update = false
    this.saveEdit = true
    this.add = "Add"
  }

  Total(){// Created By K Getting all schedule services
    this.active = "total"
    this.ServiceList = this.totalServiceList
  }

  Active(){// Created By K Filter Schedule services
    this.active = "active"
    this.ServiceList = this.totalServiceList
    let data = this.ServiceList.filter( x => x.isactive == true || x.isactive == 1)
    this.ServiceList = data
  }

  Inactive(){// Created By K Filter Schedule services
    this.active = "inactive"
    this.ServiceList = this.totalServiceList
    let data =  this.ServiceList.filter( x => x.isactive == false || x.isactive == 0)
    this.ServiceList = data
  }

  dateformat(datas:string){// Created By K Global Date Format
    return this._authSer.Dateformat(datas);
  }
  getAllSeviceList(){  // Created By K Getting all Schedule services list 
    debugger
    try {
      this.Http.getScheduleServiceList( 0, this.cmpCode).then((res) => {
        if(res.success){
          this.ServiceList = res.data.table
          this.totalServiceList = res.data.table
          this.TotalListLength = this.ServiceList.length
          this.ActiveLength = this.ServiceList.filter( x => x.isactive == true || x.isactive == 1).length
          this.InActiveLength = this.ServiceList.filter( x => x.isactive == false || x.isactive == 0).length
        }else{
          console.log(res.success)
        }
      }) 
    } catch (error) {
      this.toaster.show("error","Uh Oh, Something went wrong !")
    }
  }
  

  viewService(data){// Created By K Initialize View Schedule Service
    debugger
    this.update = false
    this.save = false
    this.add = "View"
    this.saveEdit = false
    this.scheduleServiceForm.patchValue({scheduletime : data.scheduletime})
    this.scheduleServiceForm.patchValue({id : data.id})
    this.scheduleServiceForm.patchValue({scheduleid : data.scheduleid})
    this.scheduleServiceForm.patchValue({isactive: data.isactive})
    this.scheduleServiceForm.patchValue({scheduleDay: data.scheduleDay})
    this.scheduleServiceForm.patchValue({type: 2})

  }
  deleteservice(data){// Created By K Delete Schedule Service
    let formdata = {
      id : data.id,
      cmpcode :this.cmpCode,
      type : 3,
      userid: this.UserId
    }
    try {
      this.Http.SaveScheduleService(formdata).then( res => {
        if(res.success){
          this.toaster.show("success",res.data)
          this.getAllSeviceList();
          this.gridInstance.refresh()
        }else{
          this.toaster.show("error",res.data)
        }
  
      })
    } catch (error) {
      this.toaster.show("error","Uh Oh, Something went wrong !")
    }
  }
  editservice(data){// Created By K Intialize Edit Schedule Service
    debugger
    this.update = true
    this.save = false
    this.add = "Edit"
    this.saveEdit = true
    this.scheduleServiceForm.patchValue({scheduletime : data.scheduletime})
    this.scheduleServiceForm.patchValue({id : data.id})
    this.scheduleServiceForm.patchValue({scheduleid : data.scheduleid})
    this.scheduleServiceForm.patchValue({isactive: data.isactive})
    this.scheduleServiceForm.patchValue({scheduleDay: data.scheduleDay})
    this.scheduleServiceForm.patchValue({scheduleWeek: data.scheduleweek})
    this.scheduleServiceForm.patchValue({type: 2})
  }
  SaveEditservice(data ,txt, val){// Created By K On Update Schedule Service
    debugger
    let week = this.scheduleServiceForm?.controls?.scheduleWeek?.value
    if(txt == "update"){
      if(val == 0){
        val = true
      }else{
        val = false
      }
      let formdata = {
        id : data.id,
        scheduleid: data.scheduleid,
        scheduletime: data.scheduletime,
        scheduleDay: data.scheduleDay,
        isactive : val,
        cmpcode :this.cmpCode,
        type : 4,
        scheduleweek:week,
        userid: this.UserId
      }
      try {
        this.Http.SaveScheduleService(formdata).then( res => {
          if(res.success){
            this.toaster.show("success",res.data)
            this.getAllSeviceList();
            this._commonSer.CloseModalHandler("addApprovalpopup")
            this.gridInstance.refresh()
          }else{
            this.toaster.show("error",res.data)
          }
    
        })
      } catch (error) {
        this.toaster.show("error","Uh Oh, Something went wrong !")
      }
    }else{
      let formdata = {
        id : this.scheduleServiceForm.value.id,
        scheduleid: this.scheduleServiceForm.value.scheduleid,
        scheduletime: this.scheduleServiceForm.value.scheduletime,
        scheduleDay: this.scheduleServiceForm.value.scheduleDay,
        isactive : this.scheduleServiceForm.value.isactive,
        cmpcode :this.cmpCode,
        scheduleweek:week,
        type : this.scheduleServiceForm.value.type,
        userid: this.UserId
      }
      try {
        this.Http.SaveScheduleService(formdata).then( res => {
          if(res.success){
            this.toaster.show("success",res.data)
            this.getAllSeviceList();
            this.gridInstance.refresh()
            this._commonSer.CloseModalHandler("addApprovalpopup")
          }else{
            this.toaster.show("error",res.data)
          }
    
        })
      } catch (error) {
        this.toaster.show("error","Uh Oh, Something went wrong !")
      }
    }
    
  }

  SaveSchedule(){// Created By K On Saving Schedule Service
    debugger
    this.update = false
    this.scheduleServiceForm
    if(this.scheduleServiceForm.value.isactive == null){
      this.scheduleServiceForm.patchValue({isactive: false})
    }
    let formdata = {
      id : 0,
      scheduleid: this.scheduleServiceForm.value.scheduleid,
      scheduletime: this.scheduleServiceForm.value.scheduletime,
      isactive : this.scheduleServiceForm.value.isactive,
      scheduleDay: this.scheduleServiceForm.value.scheduleDay,
      cmpcode :this.cmpCode,
      scheduleweek:this.scheduleServiceForm.value.scheduleWeek,
      type : 1,
      userid: this.UserId
    }
    try {
      this.Http.SaveScheduleService(formdata).then( res => {
        if(res.success){
          this.toaster.show("success",res.data)
          this.getAllSeviceList();
          this._commonSer.CloseModalHandler("addApprovalpopup")
        }else{
          this.toaster.show("error",res.data)
        }
  
      })
    } catch (error) {
      this.toaster.show("error","Uh Oh, Something went wrong !")
    }
  }
  
}