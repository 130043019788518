<div class="bg-overlady" *ngIf="userLoader">
  <div class="loader-position">
    <div class="loader"></div>
    <h2>Loading....</h2>
  </div>
</div>

<mk-box header="" [isCollapsable]="false" [isRemovable]="false" contentStyleClasses="table-responsive">
  <div class="row" style="margin:0; border-bottom: 1px solid #ddd; margin-bottom: 10px;">
    <div class="col-sm-6 no-padding">
      <h4 class="title-new">User Report</h4>
    </div>



    <div class="col-sm-3" style="margin-top: -4px;">
      <!-- <ng-select [allowClear]="true" [(active)]="ActiveEmployee" [(items)]="ddlEmployeeList"
        (selected)="" placeholder="Select Employee">
      </ng-select> -->
     <!-- {{ActiveEmployee | json}}  -->

      <ejs-dropdownlist  [value]="userreport" [dataSource]='ddlEmployeeList'  [allowFiltering]='true'
       (change)="ddlSelection($event, 'Employee')"  [fields]='emplyeeFields' ></ejs-dropdownlist>
    </div>
    <div class="col-sm-3 text-right btnset" style="padding-top: 48px !important;">
      <div class="btn-group">
        <button type="button" class="btn btn-primary btn-brand" data-toggle="dropdown" aria-haspopup="true"
          aria-expanded="false" title="Export">
          <img src="../assets/img/Logo/22.png">
        </button>
        <ul class="dropdown-menu">
          <li>
            <a (click)="export()">Excel</a>
          </li>
          <li>
            <a (click)="printList('tableData')">Print</a>
          </li>
          <li>
            <a (click)="exportTableToCSV()">CSV</a>
          </li>

        </ul>
      </div>


      <button class="btn btn-primary btn-brand sm mx-auto" (click)="getChart()">
        <i class="fa fa-bar-chart" aria-hidden="true"></i>

      </button>


      <button class="btn btn-primary btn-brand sm mx-auto" (click)="getUserRepo()">
        <i class="fa fa-list-ul" aria-hidden="true"></i>
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 no-padding">
      <span (click)="setFilterDayHour(1)" class="filter-links days" [ngClass]="{'active': (filterDayHour == 1)}">Day</span>
      <span (click)="setFilterDayHour(2)" class="filter-links hours" [ngClass]="{'active': (filterDayHour == 2)}">Hour</span>
    </div>
    <div class="col-sm-12 no-padding" id="tableData" *ngIf="listshow">
      <!-- <table class="table table-hover">
        <thead>
          <tr class="table-primary">
            <th scope="col" style="text-align: left; width: 50%;">Leave Type</th>
            <th scope="col" style="text-align: left; width: 30%;">Balance</th>
            <th scope="col" style="text-align: left; width: 20%;">Action</th>
          </tr>
        </thead>
        <tbody style="text-align: left;">
          <tr *ngFor="let item of reportList">
            <td>
              <span><i class="fa fa-circle" [ngStyle]="{ color:item.colorCode }"></i></span>
              <span>{{ item.leaveTypeName }}</span>
            </td>
            <td>
              <span>{{ item.balance }}</span>
            </td>
            <td>
              <a href="javascript:void(0)" class="view" title="View History" (click)="getLeaveHistory(item.leaveTypeId)"
                data-keyboard="false" data-target="#historyModal">
                <i class="fa fa-history "></i>
              </a>
            </td>
          </tr>
        </tbody>
      </table> -->

      <ejs-grid #overviewgrid id='overviewgrid' [dataSource]='reportList' rowHeight='38' [allowSelection]='true'
      [allowSorting]='true' height='600' [enableHover]='false' [selectionSettings]='selectionSettings'
      [filterSettings]='filterSettings' [allowSorting]='true' [allowFiltering]='true' [enableVirtualization]='false'
      (load)='load()' [allowPaging]='true' [pageSettings]='pageSettings ' [allowExcelExport]='true' [allowPdfExport]="true">
      <e-columns>
        <e-column field='leaveTypeName' headerText='Leave Type' [filter]='filter' clipMode='EllipsisWithTooltip'>
          <ng-template #template let-data>
            <span><i class="fa fa-circle" [ngStyle]="{ color:data.colorCode }"></i></span>
            <span>{{ data.leaveTypeName }}</span>
          </ng-template>

        </e-column>
        <e-column field='balance' headerText='Balance' [filter]='filter' clipMode='EllipsisWithTooltip'>

        </e-column>

        <e-column field='action' headerText='Action'  [allowFiltering]='false' textAlign='Center' width='100'>
          <ng-template #template let-data>


          <div class="btn-group btngg">
            <button type="button" class="btn action-button" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false" title="Action">
              <span style="margin-top: -16px;">... </span>
            </button>
            <ul class="dropdown-menu actions-dropdown">
              <li ><a href="javascript:void(0)"  title="View History" (click)="getLeaveHistory(data.leaveTypeId)"
                data-keyboard="false" data-target="#historyModal">View
              </a></li>



            </ul>
          </div>

          </ng-template>

        </e-column>




      </e-columns>
    </ejs-grid>

    </div>





    <div class="col-sm-12 no-padding" *ngIf="chartshow">

      <div class="card-box">
        <div class="card-box-body">
          <div class="row">
            <div id="dvChart" class="col-sm-10" style="padding-bottom: 25px;">
              <highcharts-chart [Highcharts] = "highcharts"  [options] = "chartOptions"
                style = "width: 97%; height: 400px; display: block;">
              </highcharts-chart>
            </div>
            <div class="col-sm-2">
              <div class="form-group row">
                <div class="dropdown rt-dropdown">
                  <label class="col-form-label" data-toggle="dropdown" style="cursor: pointer;"><i class="fa fa-align-justify" aria-hidden="true"></i></label>
                  <ul class="dropdown-menu">
                    <li (click)="downloadChart(1)">Download PNG image</li>
                    <li (click)="downloadChart(2)">Download JPEG image</li>
                    <li (click)="downloadChart(3)">Download PDF document</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

</mk-box>

<div id="historyModal" class="modal fade" role="dialog">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <div class="row">
          <div class="col-sm-3">
            <h4 class="modal-title">Leave History</h4>
          </div>
          <div class="col-sm-2">
            <div class="form-group" style="margin-bottom: 0;">
              <div class="input-group date">
                <!-- <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div> -->
                <!-- <input type="text" class="e-input" [(ngModel)]="vh_StartDate"
                  placeholder="From" [bsConfig]="{ containerClass: 'theme-dark-blue' }" autocomplete="off"
                  bsDatepicker [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', customTodayClass:'custom-today-class', showWeekNumbers: false }"
                  id="startDateHistory" [maxDate]="maxDate" (bsValueChange)="datepickerStartDate($event)"
                  required /> -->
                  <ejs-datepicker [(ngModel)]="vh_StartDate"  [allowEdit]="false" id="startDateHistory"
                   (change) = "datepickerStartDate($event)" ></ejs-datepicker>
              </div>
            </div>
          </div>
          <div class="col-sm-2">
            <div class="form-group" style="margin-bottom: 0;">
              <div class="input-group date">
                <!-- <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div> -->
                <!-- <input type="text"  class="e-input" [(ngModel)]="vh_EndDate"
                  placeholder="To" [bsConfig]="{ containerClass: 'theme-dark-blue' }" autocomplete="off"
                  bsDatepicker [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', customTodayClass:'custom-today-class', showWeekNumbers: false }"
                  id="endDateHistory" [minDate]="minDate" (bsValueChange)="datepickerEndDate($event)"
                  required /> -->
                  <ejs-datepicker [(ngModel)]="vh_EndDate" [allowEdit]="false" id="endDateHistory"
                   [min]='vh_StartDate' (change) = "datepickerEndDate($event)" ></ejs-datepicker>
              </div>
            </div>
          </div>
          <div class="col-sm-5 text-right btnset2">
            <button type="button" (click)="ExportSelectedEmployeeHistory()" title="Export" class="btn btn-primary btn-brand" style="    border-radius: 50%;
            margin-top: -10px; position: relative; top: 6px;">
              <img src="../assets/img/Logo/22.png">
            </button>


          </div>
          <button _ngcontent-c11="" class="close" data-dismiss="modal" type="button">×</button>
        </div>
      </div>
      <div class="modal-body user-modal-height" style="background-color: #fff;border-radius: 10px;">
        <div class="row no-padding">
          <div class="col-sm-12 no-padding">
            <!-- <table class="table table-hover">
              <thead>
                <tr class="table-primary" >
                  <th scope="col" style="text-align: left;">Date</th>
                  <th scope="col" style="text-align: left;">Type</th>
                  <th scope="col" style="text-align: left;">Added</th>
                  <th scope="col" style="text-align: left;">Used</th>
                  <th scope="col" style="text-align: left;">Balance</th>
                </tr>
              </thead>
              <tbody style="text-align: left;">
                <ng-container *ngIf="leaveHistoryList.length > 0">
                  <tr *ngFor="let historyData of leaveHistoryList">
                    <tr *ngFor="let historyData of leaveHistoryList | slice: (leaveHistoryPage-1) * leaveHistoryPageSize : (leaveHistoryPage-1) * leaveHistoryPageSize + leaveHistoryPageSize">
                    <td>{{ historyData.createdDate | date }} </td>
                    <td>
                      {{ historyData.type }}
                      <span *ngIf="historyData.createdBy != null">
                        <span title="Added By : {{ historyData.createdBy }} | Added Date : {{ historyData.createdDate | date }}">
                          <i class="fa fa-info-circle"></i>
                        </span>
                      </span>
                    </td>
                    <td>{{ historyData.added }}</td>
                    <td>{{ historyData.used }}</td>
                    <td>{{ historyData.balance }}</td>
                  </tr>
                </ng-container>
                <ng-container *ngIf="leaveHistoryList.length == 0">
                  <tr>
                    <td colspan="5" class="text-center">
                      <span class="noData mx-auto">No Data Available</span>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table> -->
            <!-- {{leaveHistoryList | json}} -->
            <ejs-grid #overviewgrid2 id='overviewgrid2' [dataSource]='leaveHistoryList' rowHeight='30' [allowSelection]='true'
            [allowSorting]='true' height='600' [enableHover]='false' [selectionSettings]='selectionSettings'
            [filterSettings]='filterSettings' [allowSorting]='true' [allowFiltering]='true' [enableVirtualization]='false'
            [allowPaging]='true' [pageSettings]='pageSettings'>
            <e-columns>
              <e-column field='createdDate' headerText='Date' [filter]='filter' clipMode='EllipsisWithTooltip'>
                <ng-template #template let-data>
                  <!-- {{ data.createdDate | date }} -->
                  {{dateformat( data.createdDate)}}
                </ng-template>
              </e-column>
              <e-column field='type' headerText='Type' [filter]='filter' clipMode='EllipsisWithTooltip' width= "300">

              </e-column>
              <e-column field='added' headerText='Added' [filter]='filter' clipMode='EllipsisWithTooltip'>

              </e-column>
              <e-column field='used' headerText='Used' [filter]='filter' clipMode='EllipsisWithTooltip'>

              </e-column>
              <e-column field='encashment' headerText='Encashment' [filter]='filter' clipMode='EllipsisWithTooltip'>

              </e-column>
              <e-column field='lapsed' headerText='Lapsed' [filter]='filter' clipMode='EllipsisWithTooltip'>

              </e-column>
              <e-column field='balance' headerText='Balance' [filter]='filter' clipMode='EllipsisWithTooltip'>

              </e-column>

            </e-columns>
          </ejs-grid>
            <!-- Pagination -->
            <!-- <div class="row">
              <div class="col-sm-12">
                <div class="d-flex justify-content-between p-2" class="col-sm-12 text-right">
                  <ul class="paggingUl">
                    <ngb-pagination [collectionSize]="leaveHistoryLength" [(page)]="leaveHistoryPage" [pageSize]="leaveHistoryPageSize"></ngb-pagination>
                    <li>
                      <span class="recordsMsg">No. of Records</span>
                      <select class="NoOfpage" style="width: auto;" [(ngModel)]="leaveHistoryPageSize">
                        <option [ngValue]="10">10</option>
                        <option [ngValue]="20">20</option>
                        <option [ngValue]="50">50</option>
                      </select>
                    </li>
                  </ul>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
