export const colorsAliases = [
  'default',
  'primary',
  'danger',
  'warning',
  'info',
  'success'
];

export const colors = {
  'aqua': '#00c0ef',
  'aqua-active': '#00a7d0',
  'black': '#111111',
  'black-active': '#000000',
  'blue': '#0073b7',
  'blue-active': '#005384',
  'fuchsia': '#f012be',
  'fuchsia-active': '#db0ead',
  'green': '#00a65a',
  'green-active': '#008d4c',
  'gray': '#d2d6de',
  'gray-active': '#b5bbc8',
  'gray-light': '#f7f7f7',
  'light-blue': '#3c8dbc',
  'light-blue-active': '#357ca5',
  'lime': '#01ff70',
  'lime-active': '#00e765',
  'maroon': '#d81b60',
  'maroon-active': '#ca195a',
  'navy': '#001f3f',
  'navy-active': '#001a35',
  'olive': '#3d9970',
  'olive-active': '#368763',
  'orange': '#ff851b',
  'orange-active': '#ff7701',
  'purple': '#605ca8',
  'purple-active': '#555299',
  'red': '#dd4b39',
  'red-active': '#d33724',
  'teal': '#39cccc',
  'teal-active': '#30bbbb',
  'yellow': '#f39c12',
  'yellow-active': '#db8b0b',
};
