import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { environment } from '../environments/environment';
import { map } from 'rxjs/operators';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import { Router } from '@angular/router';
import 'rxjs';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { AuthService } from './services/auth.service';

declare var $: any;

const API_Url = environment.apiUrl;
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
const EXCEL_CSV_EXTENSION = '.csv';
//Delete message 

@Injectable()
export class CommonService {
    TitleSelect2_ddl = [{ id: 'Mr', text: 'Mr.' }, { id: 'Ms', text: 'Ms' }, { id: 'Mrs', text: 'Mrs' }];
    GenderSelect2_ddl = [{ id: 'Male', text: 'Male' }, { id: 'Female', text: 'Female' }];

    constructor(private _http: Http, private _route: Router, private authService: AuthService) {
    }

    CRG_GetAPI(RoutesParms) {
        try {
            let URL = environment.apiUrl + '/' + RoutesParms;
            let opt: RequestOptions
            let myHeaders: Headers = new Headers
            let token = this.authService.getToken();
            myHeaders.set('Authorization', `bearer ${token}`)
            opt = new RequestOptions({
                headers: myHeaders
            });
            return this._http.get(URL, opt).pipe(map((response: Response) => response.json()));

        }
        catch (ex) {
            let message = ex.message;
        }
    }

    CRG_PostAPI(RoutesParms, data?: any) {
        try {
            if (!data) {
                data = "";
            }
            let opt: RequestOptions
            let myHeaders: Headers = new Headers
            myHeaders.set('Content-type', 'application/json')
            let token = this.authService.getToken();
            myHeaders.set('Authorization', `bearer ${token}`)
            opt = new RequestOptions({
                headers: myHeaders
            });
            let URL = environment.apiUrl + '/' + RoutesParms;
            return this._http.post(URL, data, opt)
                .pipe(map((response: Response) => response))
        }
        catch (ex) {
            let message = ex.message;
        }
    }

    CRG_PostFileAPI(RoutesParms, data) {
        try {
            let myHeaders: Headers = new Headers
            let token = this.authService.getToken();
            myHeaders.set('Authorization', `bearer ${token}`);
            let URL = environment.apiUrl + '/' + RoutesParms;
            return this._http.post(URL, data, { headers: myHeaders })
                .pipe(map((response: Response) => response));
        } catch (ex) {
            let message = ex.message;
        }
    }

    CloseModalHandler = function (ModalId) {
        document.getElementsByTagName("BODY")[0].removeAttribute('style');
        document.getElementsByTagName("BODY")[0].removeAttribute('class');
        (<any>$("#" + ModalId)).modal('hide');
    };

    OpenModalHandler = function (ModalId) {
        (<any>$("#" + ModalId)).modal({ backdrop: 'static', keyboard: false });
        document.getElementsByTagName("BODY")[0].removeAttribute('style');
        document.getElementsByTagName("BODY")[0].removeAttribute('class');
    }

    //Excel expot function
    public exportAsExcelFile(json: any[], excelFileName: string): void {
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
        const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, excelFileName);
    }
    private saveAsExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    }

    public exportAsExcelAsCsvFile(json: any[], excelFileName: string): void {
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
        const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'csv', type: 'array' });
        this.saveAsExcelAsCsvFile(excelBuffer, excelFileName);
    }
    private saveAsExcelAsCsvFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], { type: "text/csv" });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_CSV_EXTENSION);
    }


    requiredValidation = function (txtclass, e, parentElement = '') {
        var preventCheck = false;
        var Isvalid = true;
        var txtCount = 0;
        var ddlcount = 0;
        var validNumber = /^[0-9]*$/;
        var emailRegx = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
        var ISDCode = /^(\+)([0-9]{1})|([0-9]{2})|([0-9]{2})$/;
        var Mobile = /^([0-9]*[\.\s\-\(\)]|[0-9]+){5,15}$/;
        var Telephone = /^[+]?([0-9]*[\.\s\-\(\)]|[0-9]+){5,15}$/;
        var website = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/

        $(".error").each(function () {
            $(this).remove();
        });

        $("." + txtclass, parentElement).each(function () {
            var o = $(this);
            var Type = o["0"].localName;

            if (Isvalid) {
                if (Type == "ng-select") {
                    if (o.attr("req")) {
                        if (o[0].outerHTML.includes('Billing Type')) {
                            if (o[0].outerText === 'Billing Type') {
                                o.after("<span class='error'> " + environment.requiredMsg + " </span>").show("slow");
                                ddlcount++;
                            }
                        } else if (o[0].outerText.length === 0) {
                            o.after("<span class='error'> " + environment.requiredMsg + " </span>").show("slow");
                            ddlcount++;
                        }
                    }
                    else {
                        if (ddlcount > 0) {
                            ddlcount--;
                        }
                    }
                } else if (Type === "select") {
                    if (o.attr("req") && $("option:selected", o).index() <= 0 && (o[0].options.length > 1 && o[0].options[0].value === "null")) {
                        o.after("<span class='error'> " + environment.requiredMsg + " </span>").show("slow");
                        ddlcount++;
                    }
                    // else {
                    //     if (ddlcount > 0) {
                    //         ddlcount--;
                    //     }
                    //}
                }
                else if (Type == "input") {

                    //required for texbox
                    if ((o.attr("req")) == '1' && $.trim(o.val().toString()) == '') {
                        o.after("<span class='error' > " + environment.requiredMsg + "</span>").show("slow");
                        txtCount++;
                    }
                    else if ((o.attr("countrycode")) == 1 && $.trim(o.val().toString() != '')) {
                        if ((!(ISDCode.test(o.val().toString())))) {
                            o.after("<span class='error' > " + environment.countryCode + "</span>").show("slow");
                            txtCount++;
                        }
                    }
                    else if (o.attr("Num") && (!(validNumber.test(o.val().toString())))) {
                        //  var message = o.attr("Number-allowed");
                        o.after("<span class='error'>" + environment.NumberMsg + " </span>").show("slow");
                        txtCount++;
                    }
                    else if (o.attr("Mobile") == 1 && $.trim(o.val()) != '') {
                        if ((!(Mobile.test(o.val().toString())))) {
                            o.after("<span class='error' >" + environment.MobileMsg + "</span>").show("slow");
                            txtCount++;
                            //e.preventDefault();
                        }
                    }
                    else if ((o.attr("Telephone")) == 1 && $.trim(o.val()) != '') {
                        if ((!(Telephone.test(o.val().toString())))) {
                            o.after("<span class='error' >" + environment.TelephoneMsg + "</span>").show("slow");
                            txtCount++;
                            //e.preventDefault();
                        }
                    }
                    else if (((o.attr("Email")) == '1' && $.trim(o.val().toString()) === '') && ((o.attr("Email")) == '1' && !(emailRegx.test(o.val().toString())))) {
                        o.after("<span class='error'>" + environment.EmailMsg + "</span>").show("slow");
                        txtCount++;
                        //  return Isvalid;
                    }

                    else if (o.attr("website") == '1' && (!(website.test(o.val().toString()))) &&
                        $.trim(o.val()) != ''
                    ) {
                        o.after("<span class='error'>" + environment.websiteMsg + "</span>").show("slow");
                        txtCount++;
                        //  return Isvalid;
                    }

                    else {
                        if (txtCount > 0) {
                            // txtCount--;
                        }
                    }
                }
                //validation on textarea
                else if (Type == 'textarea') {
                    if ((o.attr("req")) == '1' && $.trim(o.val().toString()) == '') {
                        o.after("<span class='error' > " + environment.requiredMsg + "</span>").show("slow");
                        txtCount++;
                    }
                    else {
                        // txtCount--
                    }
                }
                else {
                    o.after().remove();
                }

            }
        });
        if (txtCount > 0 || ddlcount > 0) {
            Isvalid = false;
        }
        else {
            Isvalid = true;
        }


        return Isvalid;

    }
    getCountrycodes(){
        let codes = [
          //   {
          //     "code": "+93",
          //     "name": "Afghanistan",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+355",
          //     "name": "Albania",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+213",
          //     "name": "Algeria",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+1 684",
          //     "name": "American Samoa",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+376",
          //     "name": "Andorra",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+244",
          //     "name": "Angola",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+1 264",
          //     "name": "Anguilla",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+1 268",
          //     "name": "Antigua and Barbuda",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+54",
          //     "name": "Argentina",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+374",
          //     "name": "Armenia",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+297",
          //     "name": "Aruba",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+247",
          //     "name": "Ascension",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+61",
          //     "name": "Australia",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+43",
          //     "name": "Austria",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+994",
          //     "name": "Azerbaijan",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+1 242",
          //     "name": "Bahamas",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+973",
          //     "name": "Bahrain",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+880",
          //     "name": "Bangladesh",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+1 246",
          //     "name": "Barbados",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+1 268",
          //     "name": "Barbuda",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+375",
          //     "name": "Belarus",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+32",
          //     "name": "Belgium",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+501",
          //     "name": "Belize",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+229",
          //     "name": "Benin",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+1 441",
          //     "name": "Bermuda",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+975",
          //     "name": "Bhutan",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+591",
          //     "name": "Bolivia",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+387",
          //     "name": "Bosnia",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+267",
          //     "name": "Botswana",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+55",
          //     "name": "Brazil",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+246",
          //     "name": "British Indian Ocean Territory",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+1 284",
          //     "name": "British Virgin Islands",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+673",
          //     "name": "Brunei",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+359",
          //     "name": "Bulgaria",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+226",
          //     "name": "Burkina Faso",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+257",
          //     "name": "Burundi",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+855",
          //     "name": "Cambodia",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+237",
          //     "name": "Cameroon",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
            {
              "code": "+1 ",
              "name": "Canada",
              "flag": "../../assets/img/Flags/ca.png"
            },
          //   {
          //     "code": "+238",
          //     "name": "Cape Verde",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+ 345",
          //     "name": "Cayman Islands",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+236",
          //     "name": "Central African Republic",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+235",
          //     "name": "Chad",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+56",
          //     "name": "Chile",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+86",
          //     "name": "China",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+61",
          //     "name": "Christmas Island",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+61",
          //     "name": "Cocos-Keeling Islands",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+57",
          //     "name": "Colombia",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+269",
          //     "name": "Comoros",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+242",
          //     "name": "Congo",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+243",
          //     "name": "Congo",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+682",
          //     "name": "Cook Islands",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+506",
          //     "name": "Costa Rica",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+385",
          //     "name": "Croatia",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+53",
          //     "name": "Cuba",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+599",
          //     "name": "Curacao",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+537",
          //     "name": "Cyprus",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+420",
          //     "name": "Czech Republic",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+45",
          //     "name": "Denmark",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+246",
          //     "name": "Diego Garcia",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+253",
          //     "name": "Djibouti",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+1 767",
          //     "name": "Dominica",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+1 809",
          //     "name": "Dominican Republic",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+670",
          //     "name": "East Timor",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+56",
          //     "name": "Easter Island",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+593",
          //     "name": "Ecuador",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+20",
          //     "name": "Egypt",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+503",
          //     "name": "El Salvador",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+240",
          //     "name": "Equatorial Guinea",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+291",
          //     "name": "Eritrea",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+372",
          //     "name": "Estonia",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+251",
          //     "name": "Ethiopia",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+500",
          //     "name": "Falkland Islands",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+298",
          //     "name": "Faroe Islands",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+679",
          //     "name": "Fiji",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+358",
          //     "name": "Finland",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+33",
          //     "name": "France",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+596",
          //     "name": "French Antilles",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+594",
          //     "name": "French Guiana",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+689",
          //     "name": "French Polynesia",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+241",
          //     "name": "Gabon",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+220",
          //     "name": "Gambia",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+995",
          //     "name": "Georgia",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+49",
          //     "name": "Germany",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+233",
          //     "name": "Ghana",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+350",
          //     "name": "Gibraltar",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+30",
          //     "name": "Greece",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+299",
          //     "name": "Greenland",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+1 473",
          //     "name": "Grenada",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+590",
          //     "name": "Guadeloupe",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+1 671",
          //     "name": "Guam",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+502",
          //     "name": "Guatemala",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+224",
          //     "name": "Guinea",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+245",
          //     "name": "Guinea-Bissau",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+595",
          //     "name": "Guyana",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+509",
          //     "name": "Haiti",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+504",
          //     "name": "Honduras",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+852",
          //     "name": "Hong Kong SAR China",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+36",
          //     "name": "Hungary",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+354",
          //     "name": "Iceland",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
            {
              "code": "+91",
              "name": "India",
              "flag": "../../assets/img/Flags/in.png"
            },
          //   {
          //     "code": "+62",
          //     "name": "Indonesia",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+98",
          //     "name": "Iran",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+964",
          //     "name": "Iraq",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+353",
          //     "name": "Ireland",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+972",
          //     "name": "Israel",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+39",
          //     "name": "Italy",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+225",
          //     "name": "Ivory Coast",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+1 876",
          //     "name": "Jamaica",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+81",
          //     "name": "Japan",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+962",
          //     "name": "Jordan",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+7 7",
          //     "name": "Kazakhstan",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+254",
          //     "name": "Kenya",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+686",
          //     "name": "Kiribati",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+965",
          //     "name": "Kuwait",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+996",
          //     "name": "Kyrgyzstan",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+856",
          //     "name": "Laos",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+371",
          //     "name": "Latvia",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+961",
          //     "name": "Lebanon",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+266",
          //     "name": "Lesotho",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+231",
          //     "name": "Liberia",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+218",
          //     "name": "Libya",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+423",
          //     "name": "Liechtenstein",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+370",
          //     "name": "Lithuania",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+352",
          //     "name": "Luxembourg",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+853",
          //     "name": "Macau SAR China",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+389",
          //     "name": "Macedonia",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+261",
          //     "name": "Madagascar",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+265",
          //     "name": "Malawi",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+60",
          //     "name": "Malaysia",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+960",
          //     "name": "Maldives",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+223",
          //     "name": "Mali",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+356",
          //     "name": "Malta",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+692",
          //     "name": "Marshall Islands",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+596",
          //     "name": "Martinique",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+222",
          //     "name": "Mauritania",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+230",
          //     "name": "Mauritius",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+262",
          //     "name": "Mayotte",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+52",
          //     "name": "Mexico",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+691",
          //     "name": "Micronesia",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+1 808",
          //     "name": "Midway Island",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+373",
          //     "name": "Moldova",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+377",
          //     "name": "Monaco",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+976",
          //     "name": "Mongolia",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+382",
          //     "name": "Montenegro",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+1664",
          //     "name": "Montserrat",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+212",
          //     "name": "Morocco",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+95",
          //     "name": "Myanmar",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+264",
          //     "name": "Namibia",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+674",
          //     "name": "Nauru",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+977",
          //     "name": "Nepal",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+31",
          //     "name": "Netherlands",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+599",
          //     "name": "Netherlands Antilles",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+1 869",
          //     "name": "Nevis",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+687",
          //     "name": "New Caledonia",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+64",
          //     "name": "New Zealand",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+505",
          //     "name": "Nicaragua",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+227",
          //     "name": "Niger",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+234",
          //     "name": "Nigeria",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+683",
          //     "name": "Niue",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+672",
          //     "name": "Norfolk Island",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+850",
          //     "name": "North Korea",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+1 670",
          //     "name": "Northern Mariana Islands",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+47",
          //     "name": "Norway",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+968",
          //     "name": "Oman",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+92",
          //     "name": "Pakistan",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+680",
          //     "name": "Palau",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+970",
          //     "name": "Palestinian Territory",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+507",
          //     "name": "Panama",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+675",
          //     "name": "Papua New Guinea",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+595",
          //     "name": "Paraguay",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+51",
          //     "name": "Peru",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+63",
          //     "name": "Philippines",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+48",
          //     "name": "Poland",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+351",
          //     "name": "Portugal",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+1 787",
          //     "name": "Puerto Rico",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+974",
          //     "name": "Qatar",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+262",
          //     "name": "Reunion",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+40",
          //     "name": "Romania",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+7",
          //     "name": "Russia",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+250",
          //     "name": "Rwanda",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+685",
          //     "name": "Samoa",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+378",
          //     "name": "San Marino",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+966",
          //     "name": "Saudi Arabia",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+221",
          //     "name": "Senegal",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+381",
          //     "name": "Serbia",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+248",
          //     "name": "Seychelles",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+232",
          //     "name": "Sierra Leone",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
            {
              "code": "+65",
              "name": "Singapore",
              "flag": "../../assets/img/Flags/sg.png"
            },
          //   {
          //     "code": "+421",
          //     "name": "Slovakia",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+386",
          //     "name": "Slovenia",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+677",
          //     "name": "Solomon Islands",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+27",
          //     "name": "South Africa",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+500",
          //     "name": "South Georgia",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+82",
          //     "name": "South Korea",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+34",
          //     "name": "Spain",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+94",
          //     "name": "Sri Lanka",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+249",
          //     "name": "Sudan",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+597",
          //     "name": "Suriname",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+268",
          //     "name": "Swaziland",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+46",
          //     "name": "Sweden",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+41",
          //     "name": "Switzerland",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+963",
          //     "name": "Syria",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+886",
          //     "name": "Taiwan",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+992",
          //     "name": "Tajikistan",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+255",
          //     "name": "Tanzania",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+66",
          //     "name": "Thailand",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+670",
          //     "name": "Timor Leste",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+228",
          //     "name": "Togo",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+690",
          //     "name": "Tokelau",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+676",
          //     "name": "Tonga",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+1 868",
          //     "name": "Trinidad and Tobago",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+216",
          //     "name": "Tunisia",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+90",
          //     "name": "Turkey",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+993",
          //     "name": "Turkmenistan",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+1 649",
          //     "name": "Turks and Caicos Islands",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+688",
          //     "name": "Tuvalu",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+1 340",
          //     "name": "U.S. Virgin Islands",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+256",
          //     "name": "Uganda",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+380",
          //     "name": "Ukraine",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+971",
          //     "name": "United Arab Emirates",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+44",
          //     "name": "United Kingdom",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
            {
              "code": "+1",
              "name": "United States",
              "flag": "../../assets/img/Flags/us.png"
            },
          //   {
          //     "code": "+598",
          //     "name": "Uruguay",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+998",
          //     "name": "Uzbekistan",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+678",
          //     "name": "Vanuatu",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+58",
          //     "name": "Venezuela",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+84",
          //     "name": "Vietnam",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+1 808",
          //     "name": "Wake Island",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+681",
          //     "name": "Wallis and Futuna",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+967",
          //     "name": "Yemen",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+260",
          //     "name": "Zambia",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+255",
          //     "name": "Zanzibar",
          //     "flag": "../../assets/img/Flags/af.png"
          //   },
          //   {
          //     "code": "+263",
          //     "name": "Zimbabwe",
          //     "flag": "../../assets/img/Flags/af.png"
          //   }
          ];
          return codes
    }
    removeErrorMessage = function () {
        $(".error").each(function () {
            $(this).remove();
        });
    };

    //Create dropdown selection JSON 
    public CreateDDLSelectionJSON(id, value) {
        try {
            let selectionJSON = [];
            selectionJSON.push({ id: id, text: value });
            return selectionJSON;
        }
        catch (ex) {

        }
    }

    //Create object in Id and textFormat
    public SetDDlDropdown(arraylist, IdKey, textkey) {
        let ddlobject = [];
     
        for (let i = 0; i < arraylist.length; i++) {
            ddlobject.push({ id: arraylist[i][IdKey], text: arraylist[i][textkey] });
        }
        return ddlobject;
    }

    public SetDDlDropdownProjectResource(arraylist, IdKey, textkey) {
        let ddlobject = [];
     
        for (let i = 0; i < arraylist.length; i++) {
            ddlobject.push({ id: arraylist[i][IdKey], text: arraylist[i].inActive?arraylist[i][textkey]+` (In-Active) `:arraylist[i][textkey] });
        }
        return ddlobject;
    }
    public SetDDlDropdownActive(arraylist, IdKey, textkey) {
        debugger
        let ddlobject = [];
     
        for (let i = 0; i < arraylist.length; i++) {
            ddlobject.push({ id: arraylist[i][IdKey], 
            text: arraylist[i].inActive? `<span style='color: black;background-color: #babebe9e;width: 150%;height: 100%; padding:3px; display: inline-block;margin-left: -11px;padding-left:12px;pointer-events: none;cursor: not-allowed;'> `+arraylist[i][textkey]+`</span>`:arraylist[i][textkey] });
        }
        return ddlobject;
    }
    public SetDDlDropdownfor(arraylist, IdKey, textkey,roleid) {
        debugger;
        let ddlobject = [];
       if(roleid==0)
       {
           if(arraylist.length==1)
           {
            for (let i = 0; i < arraylist.length; i++) {
                ddlobject.push({ id: arraylist[i][IdKey], text: arraylist[i][textkey] });
               }
           }
           else
           {
           ddlobject.push({id:0,text:"My Team"})

            for (let i = 0; i < arraylist.length; i++) {
                ddlobject.push({ id: arraylist[i][IdKey], text: arraylist[i][textkey] });
               }
           }
           
        }
        else
        {
            for (let i = 0; i < arraylist.length; i++) {
                ddlobject.push({ id: arraylist[i][IdKey], text: arraylist[i][textkey] });
               }

        }
       
      
        return ddlobject;
    }
    public SetDDlDropdown3values(arraylist, IdKey, textkey, valID) {
        let ddlobject = [];
        for (let i = 0; i < arraylist.length; i++) {
            ddlobject.push({ id: arraylist[i][IdKey], text: arraylist[i][textkey],  valId: arraylist[i][valID] });
        }
        return ddlobject;
    }

    public SetDDlDropdownProjectAssignValues(arraylist, IdKey, textkey, valID) {
        let ddlobject = [];
        debugger;
        for (let i = 0; i < arraylist.length; i++) {
            ddlobject.push({ id: arraylist[i][IdKey], text: arraylist[i].inActive?arraylist[i][textkey]+` (In-Active) `:arraylist[i][textkey] ,  valId: arraylist[i][valID], userInactive:arraylist[i].inActive});
        }
        return ddlobject;
    }

    public Temp_SetDDlDropdown(arraylist, IdKey, textkey) {
        let ddlobject = [];
        ddlobject.push({ id: '', text: 'Select' });
        for (let i = 0; i < arraylist.length; i++) {
            ddlobject.push({ id: arraylist[i][IdKey], text: arraylist[i][textkey] });
        }
        return ddlobject;
    }
    getOrganizationStructure(obj: object) {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        let token = this.authService.getToken();
        headers.append('Authorization', `bearer ${token}`)
        const PostData = JSON.stringify(obj);
        return this._http.post(`${environment.apiUrl}/Admin/GetAllOrgStructure`, PostData, { headers: headers })
            .map((response: Response) => response.json()).toPromise()
    }
    getOrganizationStructureNewExport(obj: object) {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        let token = this.authService.getToken();
        headers.append('Authorization', `bearer ${token}`)
        const PostData = JSON.stringify(obj);
        return this._http.post(`${environment.apiUrl}/Admin/GetAllOrgStructureNewExport`, PostData, { headers: headers })
            .map((response: Response) => response.json()).toPromise()
    }

    getBusinessTypeExport(cmpCode: any) {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        let token = this.authService.getToken();
        headers.append('Authorization', `bearer ${token}`)
        return this._http.get(`${environment.apiUrl}Admin/GetBusinessTypeForExport?CmpCode=`+cmpCode, { headers: headers })
            .map((response: Response) => response.json()).toPromise()
    }
    getDesignationListExport(cmpCode: any) {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        let token = this.authService.getToken();
        headers.append('Authorization', `bearer ${token}`)
        return this._http.get(`${environment.apiUrl}Admin/GetDesignationsForExport?cmpcode=`+cmpCode, { headers: headers })
            .map((response: Response) => response.json()).toPromise()
    }

    getVendorList(cmpCode: any) {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        let token = this.authService.getToken();
        headers.append('Authorization', `bearer ${token}`)
        return this._http.get(`${environment.apiUrl}Admin/Getvendor?cmpcode=`+cmpCode, { headers: headers })
            .map((response: Response) => response.json()).toPromise()
    }

    getDepartmentsListExport(cmpCode: any) {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        let token = this.authService.getToken();
        headers.append('Authorization', `bearer ${token}`)
        return this._http.get(`${environment.apiUrl}Admin/GetDepartmentsForExport?CmpCode=`+cmpCode, { headers: headers })
            .map((response: Response) => response.json()).toPromise()
    }

    getSalesDetailexportexcel(cmpCode: any) {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        let token = this.authService.getToken();
        headers.append('Authorization', `bearer ${token}`)
        return this._http.get(`${environment.apiUrl}Admin/GetSalesDetailexportexcel?CmpCode=`+cmpCode, { headers: headers })
            .map((response: Response) => response.json()).toPromise()
    }
    getContactPersonDetailexportexcel(cmpCode: any) {
            const headers = new Headers();
            headers.append('Content-Type', 'application/json');
            let token = this.authService.getToken();
            headers.append('Authorization', `bearer ${token}`)
            return this._http.get(`${environment.apiUrl}Admin/GetContactPersonDetailexportexcel?CmpCode=`+cmpCode, { headers: headers })
                .map((response: Response) => response.json()).toPromise()
        }
    getCustomerexportdetail(cmpCode: any) {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        let token = this.authService.getToken();
        headers.append('Authorization', `bearer ${token}`)
        return this._http.get(`${environment.apiUrl}Admin/Customerexportdetail?CmpCode=`+cmpCode, { headers: headers })
            .map((response: Response) => response.json()).toPromise()
    }
    

    
    getJobBandListExport(cmpCode: object) {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        let token = this.authService.getToken();
        headers.append('Authorization', `bearer ${token}`)
        return this._http.get(`${environment.apiUrl}Admin/GetJobBandForExport?CmpCode=`+cmpCode, { headers: headers })
            .map((response: Response) => response.json()).toPromise()
    }
    
    organizationStructure_Post(obj: object) {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        let token = this.authService.getToken();
        headers.append('Authorization', `bearer ${token}`)
        const PostData = JSON.stringify(obj);
        return this._http.post(`${environment.apiUrl}/Admin/CreateOrgStructure`, PostData, { headers: headers })
            .map((response: Response) => response.json()).toPromise()
    }
    organizationStructure_Delete(id: number) {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        let token = this.authService.getToken();
        headers.append('Authorization', `bearer ${token}`);
        return this._http.post(`${environment.apiUrl}/Admin/DeleteOrgStructure/` + id, '', { headers: headers })
            .map((response: Response) => response.json()).toPromise()
    }
    organizationStructure_Update(obj: object) {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        let token = this.authService.getToken();
        headers.append('Authorization', `bearer ${token}`)
        const PostData = JSON.stringify(obj);
        return this._http.post(`${environment.apiUrl}/Admin/UpdateOrgStructure`, PostData, { headers: headers })
            .map((response: Response) => response.json()).toPromise()
    }
    organizationStructureCount() {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        let token = this.authService.getToken();
        headers.append('Authorization', `bearer ${token}`)
        
        return this._http.get(`${environment.apiUrl}/Admin/GetOrgStructureCount?cmpcode=`, { headers: headers })
            .map((response: Response) => response.json()).toPromise()
    }
    getOrgStructureElements(obj: object) {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        let token = this.authService.getToken();
        headers.append('Authorization', `bearer ${token}`)
        const PostData = JSON.stringify(obj);
        return this._http.post(`${environment.apiUrl}/Admin/GetAllOrgStructureLevel`, PostData, { headers: headers })
            .map((response: Response) => response.json()).toPromise()
    }
    getOrgStructureElementsNewExport(obj: object) {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        let token = this.authService.getToken();
        headers.append('Authorization', `bearer ${token}`)
        const PostData = JSON.stringify(obj);
        return this._http.post(`${environment.apiUrl}/Admin/GetAllOrgStructureLevelNewExport`, PostData, { headers: headers })
            .map((response: Response) => response.json()).toPromise()
    }
    orgStructureElement_Post(obj: object) {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        let token = this.authService.getToken();
        headers.append('Authorization', `bearer ${token}`)
        const PostData = JSON.stringify(obj);
        return this._http.post(`${environment.apiUrl}/Admin/CreateOrgStructureLevel`, PostData, { headers: headers })
            .map((response: Response) => response.json()).toPromise()
    }
    orgStructureElement_Update(obj: object) {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        let token = this.authService.getToken();
        headers.append('Authorization', `bearer ${token}`)
        const PostData = JSON.stringify(obj);
        return this._http.post(`${environment.apiUrl}/Admin/UpdateOrgStructureLevel`, PostData, { headers: headers })
            .map((response: Response) => response.json()).toPromise()
    }
    orgStructureElement_Delete(id: number) {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        let token = this.authService.getToken();
        headers.append('Authorization', `bearer ${token}`)
        return this._http.post(`${environment.apiUrl}/Admin/DeleteOrgStructureLevel/` + id, '', { headers: headers })
            .map((response: Response) => response.json()).toPromise()
    }
    orgStructureElementsCount() {
        const headers = new Headers();
        let token = this.authService.getToken();
        headers.append('Authorization', `bearer ${token}`)
        headers.append('Content-Type', 'application/json');
        return this._http.get(`${environment.apiUrl}/Admin/GetOrgStructureLevelCount`, { headers: headers })
            .map((response: Response) => response.json()).toPromise()
    }
    addNewLanguage(obj: object,CmpCode) {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        let token = this.authService.getToken();
        headers.append('Authorization', `bearer ${token}`)
        const PostData = JSON.stringify(obj);
        return this._http.post(`${environment.apiUrl}/Admin/AddNewLanguage/`+CmpCode, PostData, { headers: headers })
            .map((response: Response) => response.json()).toPromise()
    }
    getAllLanguages(CmpCode) {
        const headers = new Headers();
        let token = this.authService.getToken();
        headers.append('Authorization', `bearer ${token}`)
        headers.append('Content-Type', 'application/json');
        return this._http.get(`${environment.apiUrl}/Admin/GetAllLanguages/`+CmpCode, { headers: headers })
            .map((response: Response) => response.json()).toPromise()
    }
    getUserCost(type,CmpCode) {
        const headers = new Headers();
        let token = this.authService.getToken();
        headers.append('Authorization', `bearer ${token}`)
        headers.append('Content-Type', 'application/json');
        return this._http.get(`${environment.apiUrl}/Admin/SubscriptionAmount?planType=`+type+`&cmpcode=`+CmpCode, { headers: headers })
            .map((response: Response) => response.json()).toPromise()
    }
    addLanguageKeyword(obj: object,CmpCode) {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        let token = this.authService.getToken();
        headers.append('Authorization', `bearer ${token}`)
        const PostData = JSON.stringify(obj);
        return this._http.post(`${environment.apiUrl}/Admin/LanguageTranslation/`+CmpCode, PostData, { headers: headers })
            .map((response: Response) => response.json()).toPromise()
    }
    getAllAdminLanguagesList(obj: object) {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        let token = this.authService.getToken();
        headers.append('Authorization', `bearer ${token}`)
        const PostData = JSON.stringify(obj);
        return this._http.post(`${environment.apiUrl}/Admin/GetAllAdminLanguagesList`, PostData, { headers: headers })
            .map((response: Response) => response.json()).toPromise()
    }
    GetAllAdminLanguagesListNewExport(obj: object) {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        let token = this.authService.getToken();
        headers.append('Authorization', `bearer ${token}`)
        const PostData = JSON.stringify(obj);
        return this._http.post(`${environment.apiUrl}/Admin/GetAllAdminLanguagesListNewExport`, PostData, { headers: headers })
            .map((response: Response) => response.json()).toPromise()
    }
    getLanguagesCount() {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        let token = this.authService.getToken();
        headers.append('Authorization', `bearer ${token}`)
        return this._http.get(`${environment.apiUrl}/Admin/GetLanguagesCount`, { headers: headers })
            .map((response: Response) => response.json()).toPromise()
    }
    updateLanguage(obj: object) {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        let token = this.authService.getToken();
        headers.append('Authorization', `bearer ${token}`)
        const PostData = JSON.stringify(obj);
        return this._http.post(`${environment.apiUrl}/Admin/UpdateLanguage`, PostData, { headers: headers })
            .map((response: Response) => response.json()).toPromise()
    }
    deleteLanguage(id: number) {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        let token = this.authService.getToken();
        headers.append('Authorization', `bearer ${token}`)
        return this._http.post(`${environment.apiUrl}/Admin/DeleteLanguage/` + id, { headers: headers })
            .map((response: Response) => response.json()).toPromise()
    }
    getAllKeywordList(obj: object) {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        let token = this.authService.getToken();
        headers.append('Authorization', `bearer ${token}`)
        const PostData = JSON.stringify(obj);
        return this._http.post(`${environment.apiUrl}/Admin/GetAllKeywordList`, PostData, { headers: headers })
            .map((response: Response) => response.json()).toPromise()
    }
    getAllKeywordNewExport(obj: object) {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        let token = this.authService.getToken();
        headers.append('Authorization', `bearer ${token}`)
        const PostData = JSON.stringify(obj);
        return this._http.post(`${environment.apiUrl}/Admin/GetAllKeywordNewExport`, PostData, { headers: headers })
            .map((response: Response) => response.json()).toPromise()
    }
    getKeywordsCount(CmpCode) {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        let token = this.authService.getToken();
        headers.append('Authorization', `bearer ${token}`)
        return this._http.get(`${environment.apiUrl}/Admin/GetKeywordsCount/`+CmpCode, { headers: headers })
            .map((response: Response) => response.json()).toPromise()
    }
    updateKeywrod(obj: object,CmpCode) {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        let token = this.authService.getToken();
        headers.append('Authorization', `bearer ${token}`)
        const PostData = JSON.stringify(obj);
        return this._http.post(`${environment.apiUrl}/Admin/UpdateKeyword/`+CmpCode, PostData, { headers: headers })
            .map((response: Response) => response.json()).toPromise()
    }
    deleteKeyword(id: number) {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        let token = this.authService.getToken();
        headers.append('Authorization', `bearer ${token}`)
        return this._http.post(`${environment.apiUrl}/Admin/DeleteKeyword/` + id, { headers: headers })
            .map((response: Response) => response.json()).toPromise()
    }

    setGanttProject(id: number, name: string, workingDays: string = '') {
        localStorage.setItem("GanttProject", String(id) + "_" + name + "_" + workingDays);
    }

    getGanttProject() {
        return localStorage.getItem("GanttProject");
    }

}
