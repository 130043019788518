import { Injectable } from "@angular/core";
import { DynamicControlBase } from "./controls/dynamic-control-base";
import {
  FormControl,
  Validators,
  FormGroup,
  FormBuilder
} from "@angular/forms";
import { DynamicControlTextbox } from "./controls/dynamic-control-textbox";
import { UdfService } from "../udf/udf.service";
import { DynamicControlDropdown } from "./controls/dynamic-control-dropdown";
import { SelectItem } from "ng2-select";
import { DynamicControlTextArea } from "./controls/dynamic-control-textarea";
import { DynamicControlCheckbox } from "./controls/dynamic-control-checkbox";
import { CustomValidators } from "../core/validators/custom-validators";
import { DynamicControlRadioButton } from "./controls/dynamic-control-radio-button";

@Injectable({
  providedIn: "root"
})
export class DynamicControlService {
  getValues(moduleName: string, tablePKIds: any[]): any {
    const ids = tablePKIds.join(",");
    const params = {
      PageNo: 1,
      NoOfRecords: 100,
      SortType: "asc",
      FieldName: "tableName",
      Condition: `where tableName='${moduleName}' and tablePKId in (${ids ? ids : -1})`
    };
    return this.udfService.getUdfDataValuesAsync(params);
  }

  constructor(
    private formBuilder: FormBuilder,
    private udfService: UdfService
  ) { }

  toFormGroup(controls: DynamicControlBase<any>[], dynamicValues: any) {
    const group: any = {};

    controls.forEach(control => {
      const validations = [];
      if (control.required) { // || control.minLength
        validations.push(Validators.required);
      }
      if (control.minLength > 0) {
        validations.push(Validators.minLength(control.minLength));
      }
      if (control.maxLength > 0) {
        validations.push(Validators.maxLength(control.maxLength));
      }
      if (control.type == "Integer") {
        validations.push(CustomValidators.integerValidator);
      } else if (control.type == "Decimal") {
        validations.push(CustomValidators.decimalValidator);
      }
      const value = dynamicValues
        .filter(v => v.attributeLabel === control.label)
        .map(v => v.fieldValue);

      if (control.controlType === "dropdown") {
        if (value && value.length) {
          control.value = value[0].split(",").map(item => {
            const selectItem = new SelectItem(item);
            return selectItem;
          });
        } else {
          control.value = [];
        }
      } else if (value && value.length) {
        control.value = value[0];
      }
      group[control.key] = new FormControl(
        control.value || "",
        Validators.compose(validations)
      );
      if (control.controlType === "checkbox") {
        const checkbox = control as DynamicControlCheckbox;
        let values = [];
        if (value && value.length) {
          values = value[0].split(",");
        }

        const checkboxOptions = checkbox.options.map((op, index) => {
          const checked = values && values.length && values.filter(v => v == op).length;
          const fc = new FormControl(checked);
          return fc;
        });
        control.value = values;
        if (control.required) {
          group[control.key] = this.formBuilder.array(
            checkboxOptions,
            CustomValidators.minSelectedCheckboxes(1)
          );
        } else {
          group[control.key] = this.formBuilder.array(checkboxOptions, []);
        }
      }
      if (control.controlType === "RadioButton") {
        if (value && value.length) {
          control.value = value[0];
        } else {
          control.value = "";
        }
        if (control.required) {
          group[control.key] = new FormControl(
            control.value,
            Validators.required
          );
        } else {
          group[control.key] = new FormControl(control.value, []);
        }
      }
    });
    return new FormGroup(group);
  }
  async getControls(moduleName: string) {
    const params = {
      PageNo: 1,
      NoOfRecords: 100,
      SortType: "asc",
      FieldName: "tableName",
      Condition: `where tableName='${moduleName}' and inactive=0`
    };
    let data = await this.udfService.getUdfDataAsync(params);
    let controls: DynamicControlBase<any>[] = data.data.table.map(item => {
      if (item.controlType === "Textbox") {
        return new DynamicControlTextbox({
          key: item.attributeId,
          label: item.attributeLabel,
          required: item.isRequired,
          order: item.attributeOrder,
          minLength: item.minLength,
          maxLength: item.maxLength,
          type: item.dataType,
          listDisplay: item.listDisplay
        });
      } else if (item.controlType === "Dropdown") {
        let options = [];
        let dropdownOptions = [];
        if (item.options) {
          options = item.options.split(",");
          dropdownOptions = options.map(option => {
            const dropdownItem = { id: option, text: option };
            return dropdownItem;
          });
        }
        return new DynamicControlDropdown({
          key: item.attributeId,
          label: item.attributeLabel,
          options: dropdownOptions,
          required: item.isRequired,
          order: item.attributeOrder,
          listDisplay: item.listDisplay
        });
      } else if (item.controlType === "Textarea") {
        return new DynamicControlTextArea({
          key: item.attributeId,
          label: item.attributeLabel,
          required: item.isRequired,
          order: item.attributeOrder,
          minLength: item.minLength,
          maxLength: item.maxLength,
          type: item.dataType,
          listDisplay: item.listDisplay
        });
      } else if (item.controlType === "Checkbox") {
        let options = [];
        if (item.options) {
          options = item.options.split(",");
        }
        return new DynamicControlCheckbox({
          key: item.attributeId,
          label: item.attributeLabel,
          required: item.isRequired,
          order: item.attributeOrder,
          minLength: item.minLength,
          maxLength: item.maxLength,
          options: options,
          listDisplay: item.listDisplay
        });
      } else if (item.controlType === "RadioButton") {
        let options = [];
        if (item.options) {
          options = item.options.split(",");
        }
        return new DynamicControlRadioButton({
          key: item.attributeId,
          label: item.attributeLabel,
          required: item.isRequired,
          order: item.attributeOrder,
          minLength: item.minLength,
          maxLength: item.maxLength,
          options: options,
          listDisplay: item.listDisplay
        });
      }
    });
    return controls.sort((a, b) => a.order - b.order);
  }

  update(udfData: any): any {
    return this.udfService.updateMultipleUdfData(udfData);
  }
}
