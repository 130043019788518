<aside class="main-sidebar">
  <section class="sidebar" #sidebarElement [style.height.px]="sidebarHeight" [style.overflow]="sidebarOverflow">
    <ng-content></ng-content>
  	<ul class="sidebar-menu">
      <ng-container *ngFor="let item of menu">
        <ng-container *ngTemplateOutlet="sidebarInner; context: {item: item}"></ng-container>
      </ng-container>
  	</ul>
  </section>

<!-- <section>
<div class="checkin">
  <p style="color: #353333;
  font-weight: 600;
  margin-bottom: 0;">You have not Check-in</p>
  <span style="    clear: both;
  display: block;
  background: #27cda5;
  color: #fff;
  margin: 10px auto 5px;
  width: 100px;
  padding: 5px;
  border-radius: 20px;">Check-in <i class="fa fa-clock-o" aria-hidden="true"></i></span>
  <label style="    font-weight: 500;">00:00:00</label>
</div>
</section> -->

</aside>

<ng-template #sidebarInner let-item="item">
    <!-- [class.header]="item.separator" -->
  <li *ngIf="showMenuItem(item)" [class.active]="item.isActive" [class.menu-open]="!item.isCollapsed">
    <span *ngIf="item.separator">{{item.label}}</span>
    <a *ngIf="!item.separator && item.route" [routerLink]="item.route" (click)="checktoggle(item)">
    	<i [class]="getIconClasses(item)"></i><span>{{item.label}}</span>
    	<span *ngIf="item.children || item.pullRights" class="pull-right-container">
    		<span *ngFor="let rightItem of item.pullRights" class="pull-right {{rightItem.classes}}">{{rightItem.text}}</span>
    	  <i *ngIf="!item.pullRights" class="fa fa-angle-left pull-right"></i>
    	</span>
    </a>
    <a *ngIf="!item.separator && !item.route" href="#" [mkMenuToggle]="item">
    	<i [class]="getIconClasses(item)"></i><span>{{item.label}}</span>
    	<span *ngIf="item.children || item.pullRights" class="pull-right-container">
    		<span *ngFor="let rightItem of item.pullRights" class="pull-right {{rightItem.classes}}">{{rightItem.text}}</span>
    	  <i *ngIf="!item.pullRights" class="fa fa-angle-left pull-right"></i>
    	</span>
    </a>
    <ul *ngIf="item.children" [collapseAnimation]="item.isCollapsed" (collapseAnimation.start)="visibilityStateStart($event)" class="treeview-menu">
      <ng-container *ngFor="let item of item.children">
            <ng-container *ngTemplateOutlet="sidebarInner; context: {item: item}"></ng-container>
      </ng-container>
    </ul>
  </li>
</ng-template>

