import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BoxModule } from 'angular-admin-lte';
import { SelectModule } from 'ng2-select';
import { CoreModule } from 'src/app/core/core.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TabsModule as MkTabsModule } from 'angular-admin-lte';
import { TimepickerModule, BsDatepickerModule } from 'ngx-bootstrap';
import { CompOffComponent, NgbdSortableHeader } from './comp-off.component';
import { GridAllModule, PagerModule } from '@syncfusion/ej2-angular-grids';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    BoxModule, SelectModule,
    MkTabsModule,
    BoxModule,
    CoreModule,
    SelectModule,
    GridAllModule,
    PagerModule,
    ReactiveFormsModule,
    NgbModule,
    DropDownListModule,
    DatePickerModule,
    TimepickerModule.forRoot(),
    BsDatepickerModule.forRoot()
  ],
  declarations: [CompOffComponent, NgbdSortableHeader]
})
export class CompoffModule { }
