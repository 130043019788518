import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { LeaveService } from '../../../leave.service';
import { CommonService } from 'src/app/common.service';
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { ToasterService } from "src/app/+layout/expense/toaster/toaster.service";

@Component({
  selector: 'app-update-leave-balance',
  templateUrl: './update-leave-balance.component.html',
  styleUrls: ['./update-leave-balance.component.css']
})
export class UpdateLeaveBalanceComponent implements OnInit {
  currentUserId: any;
  bsValue = new Date();
  ddlEmployeeUserId: any;
  getLeaveBalcanceByEmplyeList: any;
  public LeaveTypeList: FormArray;
  EmployeeList = [];
  ActiveEmployee = [];
  todatlEmployeeList: any;
  employeName: any;
  employeCode: any;
  userLoader: boolean;
  updateLeaveBalanceForm: FormGroup;
  showSuccess: boolean;
  showMessage: any;
  myRequestsListLength: any;
  myRequestsPage = 1;
  myRequestsPageSize = 10;
  getFormDataBalnce = [];
  showLeaveTypeName: any;
  CmpCode:any;
  leaveNewList: any;
  constructor(
    private _authSer: AuthService,
    private _leaveSer: LeaveService,
    private _commonSer: CommonService,
    private _fb: FormBuilder,
    private toasterService:ToasterService
  ) { this.CmpCode = this._authSer.getcompanyCode();}

  ngOnInit() {
    this.CmpCode = this._authSer.getcompanyCode();
    this._authSer.currentUser.subscribe((res) => {
      this.currentUserId = res.userId;
      // this.applyLeaveSelectedUserId = res.userId;
      
      this.ddlEmployeeUserId = res.userId;
    });

    this.updateLeaveBalanceForm = this._fb.group({
      EmployeeId: [],
      LeaveTypeList: new FormArray([]),
      CmpCode:this.CmpCode

      // LeaveTypeList:this._fb.group({
      //   LeaveTypeId:[],
      //   ExistingBalance:[],
      //   NewBalance:[],
      //   Reason:[],
      //   Date:[]
      // })
    });

    this.getEmployeesListForSelect();

    setTimeout(() => {
      this.GetLeaveBalanceByEmployee();
      this.GetBalanceByForFOrmPop();
    }, 300);
  }

  addLeaveBalanceFormFun(): FormGroup {
    return this._fb.group({
      LeaveTypeId: [],
      ExistingBalance: [],
      NewBalance: [],
      Reason: [''],
      Date: [new Date()]
    });
  }

  addAddress(): void {
    this.LeaveTypeList = this.updateLeaveBalanceForm.get('LeaveTypeList') as FormArray;
    this.LeaveTypeList.push(this.addLeaveBalanceFormFun());
  }

  getEmployeesListForSelect () {
    this.userLoader = true;
    this._leaveSer.getEmployeesListForSelect(this.currentUserId,this.CmpCode).subscribe((res) => {
    
      this.todatlEmployeeList = res['data'].table;
      if (res['data'].table != null && res['data'].table.lenght > 0) {
        this.employeName = res["data"].table[0].employeeName;
        this.employeCode = res["data"].table[0].empCode;
      }
      // For Dashboard Emplyee Select List
      this.EmployeeList = this._commonSer.SetDDlDropdown(res["data"].table, 'employeeId', 'employeeCodeName');
      const selectedEmp = this.todatlEmployeeList.find(x => x.userId === this.currentUserId);
      if (selectedEmp !== undefined) {
        const empValue = { 'id': selectedEmp.employeeId, 'text': selectedEmp.employeeCodeName };
        setTimeout(() => {
          this.ddlSelection(empValue, 'Employee');
          this.GetLeaveBalanceByEmployee();
          this.GetBalanceByForFOrmPop();
        }, 300);
      }
    });
  }

  ddlSelection(value: any, placeHolder: any, type?) {
    try {
      switch (placeHolder) {
        case 'Employee':
          this.ActiveEmployee = [];
          this.ActiveEmployee.push(value);
          this.ddlEmployeeUserId = value.id;
          // console.log(this.ActiveEmployee)

          // this.bindAllDashboardData();
          if(type != undefined){
            if(type == 'manualy'){
              this.GetLeaveBalanceByEmployee();
             }
          }
         
          break;
      }
    } catch (ex) {

    }
  }

  GetLeaveBalanceByEmployee() {
    this.userLoader = true;
    let currentUserId = this.ddlEmployeeUserId; //this.currentUserId;

    let parms = {CmpCode:this.CmpCode};
    this._leaveSer.GetLeaveBalanceByEmployee(currentUserId, parms,this.CmpCode).subscribe(res => {
      this.userLoader = false;
      debugger;
      this.getLeaveBalcanceByEmplyeList = res['data'];

      this.myRequestsListLength = res['data'].length;
      this.updateLeaveBalanceForm.patchValue({
        EmployeeId: this.ddlEmployeeUserId,
      });

      setTimeout(() => {
        console.clear();
        console.log('s');
        this.getLeaveBalcanceByEmplyeList.filter((res, i) => {
          this.showLeaveTypeName = res.leaveTypeName;
          console.log("mydata",this.updateLeaveBalanceForm);
         var date= new Date().toISOString().split('T')[0];
          this.updateLeaveBalanceForm['controls']['LeaveTypeList']['controls'][i]['controls'].LeaveTypeId.patchValue(res.leaveTypeId);
          this.updateLeaveBalanceForm['controls']['LeaveTypeList']['controls'][i]['controls'].ExistingBalance.patchValue(res.balance);
          //this.updateLeaveBalanceForm['controls']['LeaveTypeList']['controls'][i]['controls'].Date.patchValue(new Date());
          this.updateLeaveBalanceForm['controls']['LeaveTypeList']['controls'][i]['controls'].Date.patchValue(new Date());

        });
        console.log('e');
      }, 300);
    });
  }

  GetBalanceByForFOrmPop() {
    debugger
    this.userLoader = true;
    let currentUserId = this.ddlEmployeeUserId;
    let _currentFinancialYearId = 1;
let cmpcode=this.CmpCode
    let parms = {CmpCode:this.CmpCode};
    this._leaveSer.GetLeaveBalanceByEmployee(currentUserId, parms,cmpcode).subscribe(res => {
      this.userLoader = false;
      this.myRequestsListLength = res['data'].length;
      this.getLeaveBalcanceByEmplyeList.forEach(text => {
        const control = new FormControl(this.updateLeaveBalanceForm.value);
        (<FormArray>this.updateLeaveBalanceForm.get("LeaveTypeList")).push(this.addLeaveBalanceFormFun());
      });
    });
  }

  get addressControls() {
    return this.updateLeaveBalanceForm.get('LeaveTypeList')['controls'];
  }
leave:any=[]
leavetype:any=[]
  updateLeaveBalance() {
    debugger;
    console.clear();
    //console.log(this.updateLeaveBalanceForm);
    console.log(this.updateLeaveBalanceForm.value);
    this.userLoader = true;
    this.leave=[]
       this.leave= this.updateLeaveBalanceForm.controls.LeaveTypeList

      for(var i=0;i<this.leave.length;i++)
      {
        if(this.leave.value[i].LeaveTypeId !=null)
        {
          this.leavetype.push(this.leave.value[i])
        }
      }
    //console.log(this.updateLeaveBalanceForm);
    this.showMessage = "";
    this.showSuccess = true;
    this.updateLeaveBalanceForm.value.CmpCode = this.CmpCode;
    this.updateLeaveBalanceForm.value.LeaveTypeList = this.leavetype;
    this.leavetype=[]

    this._leaveSer.updateLeaveBalance(this.updateLeaveBalanceForm.value).subscribe(res => {
      this.userLoader = false;
      debugger;
      if (res['success'] === true) {
        this.userLoader = false;
        this.showSuccess = true;
        this.showMessage = res['data'];
        this.updateLeaveBalanceForm.reset();
        this.GetLeaveBalanceByEmployee();
            this.toasterService.show('success',this.showMessage)
       // this._commonSer.OpenModalHandler("leaveTypeMessageModel");
      } else {
        this.userLoader = false;
        this.showSuccess = false;
        this.showMessage = res['message'];
        this.toasterService.show('success',this.showMessage)

       // this._commonSer.OpenModalHandler("leaveTypeMessageModel");
      }
    }, (error) => {
      debugger;
      this.userLoader = false;
      this.showSuccess = false;
      this.showMessage = "Uh oh, Something went wrong !";
      this.toasterService.show('success',this.showMessage)

      //this._commonSer.OpenModalHandler("leaveTypeMessageModel");
    });
  }

  bindAllDashboardData() {
    // this.getAllHolidayList();
    // this.getAllLeave();
    // this.GetLeaveBalanceByEmployee();
  }

}
