import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LeaveComponent } from './leave.component';
import { ApprovalComponent } from './approval/approval.component';
import { CompOffComponent } from './comp-off/comp-off.component';
import { HolidaysComponent } from './holidays/holidays.component';
import { LeaveTypeComponent } from './setting/leave-type/leave-type.component';
import { ClientholidayComponent } from './clientholiday/clientholiday.component';
import { GeneralsettingsComponent } from './setting/generalsettings/generalsettings.component';
import { AuthGuard } from '../../auth.guard';
import { CustomizePolicyComponent } from './setting/customize-policy/customize-policy.component';
import { CustomizeLeaveBalanceComponent } from './setting/customize-leave-balance/customize-leave-balance.component';
import { UpdateLeaveBalanceComponent } from './setting/customize-leave-balance/update-leave-balance/update-leave-balance.component';
import { ApprovalSettingComponent } from './setting/approval-setting/approval-setting.component';
import { ScheduleServicesComponent } from './setting/scheduleServices/scheduleServices.component';
import { UserReportComponent } from './leave-report/user-report/user-report.component';
import { UserAvailabilityReportComponent } from './leave-report/user-availability-report/user-availability-report.component';
import { DailyReportComponent } from './leave-report/daily-report/daily-report.component';
import { LeaveTakenReportComponent } from './leave-report/leave-taken-report/leave-taken-report.component';
import { LeaveBalanceReportComponent } from './leave-report/leave-balance-report/leave-balance-report.component';


const routes: Routes = [{
  path: '',
  component: LeaveComponent
},
{
  path: 'leave/approval',
  component: ApprovalComponent,
  data: {
    //title: 'Requests/Approvals',
    module: 'APPROVAL'
  }, //canActivate: [AuthGuard]
},
{
  path: 'leave/approval/:userId',
  component: ApprovalComponent,
  data: {
    //title: 'Requests/Approvals',
    module: 'APPROVAL'
  }, //canActivate: [AuthGuard]
},
{
  path: 'leave/comp-off',
  component: CompOffComponent,
  data: {
    //title: 'Compensatory Off Requests',
    module: 'COMPOFF'
  }, //canActivate: [AuthGuard]
},
{
  path: 'leave/holidays',
  component: HolidaysComponent,
  data: {
    //title: 'Holidays',
    module: 'HOLIDAYS'
  }, //canActivate: [AuthGuard]
},
{
  path: 'leave/clientholiday',
  component: ClientholidayComponent,
  data: {
    //title: 'Clientholiday',
    module: 'CLIENTHOLIDAY' 
  }, //canActivate: [AuthGuard] 
},

// {
//   path: 'leave/comp-off/approve',
//   component: CompOffApproveComponent,
//   data: {
//     title: 'Compensatory Off Requests Approval',
//     module: 'COMP-OFF-APPROVE'
//   }
// },
// {
//   path: 'leave/leave-approval',
//   component: LeaveApprovalComponent,
//   data: {
//     title: 'Leave Approval',
//     module: 'LEAVEAPPROVAL'
//   }
// },
{
  path: 'leave/setting/leave-type',
  component: LeaveTypeComponent,
  data: {
    //title: 'Custom Leave Type ',
    module: 'SETTING'
  }, //canActivate: [AuthGuard]
},
  {
    path: 'leave/setting/generalsettings',
    component: GeneralsettingsComponent,
    data: {
      //title: 'General Settings',
      module: 'SETTING'
    }, //canActivate: [AuthGuard]
}
,
  {
    path: 'leave/setting/customize-privacy',
    component: CustomizePolicyComponent,
    data: {
      //title: 'Customize Leave Policy',
      module: 'SETTING'
    }, //canActivate: [AuthGuard]
},
{
  path: 'leave/setting/customize-leave-balance',
  component: CustomizeLeaveBalanceComponent,
  data: {
    //title: 'Customize Leave Balance',
    module: 'SETTING'
  }, //canActivate: [AuthGuard]
},
{
  path: 'leave/setting/customize-leave-balance/update-balance',
  component: UpdateLeaveBalanceComponent,
  data: {
    //title: 'Customize Leave Balance',
    module: 'SETTING'
  }, //canActivate: [AuthGuard]
},
{
  path: 'leave/setting/approval-setting',
  component: ApprovalSettingComponent,
  data: {
    //title: 'Approval Settings',
    module: 'SETTING'
  }, //canActivate: [AuthGuard]
},

{
  path: 'leave/setting/scheduleServices',
  component: ScheduleServicesComponent,
  data: {
    //title: 'Approval Settings',
    module: 'SETTING'
  }, //canActivate: [AuthGuard]
},

{
  path: 'leave/report/user-report',
  component: UserReportComponent,
  data: {
    //title: 'User Report',
    module: 'LEAVEREPORT'
  }, canActivate: [AuthGuard]
},
{
  path: 'leave/report/user-availability-report',
  component: UserAvailabilityReportComponent,
  data: {
    //title: 'User Availability Report',
    module: 'LEAVEREPORT'
  }, canActivate: [AuthGuard]
},
{
  path: 'leave/report/daily-report',
  component: DailyReportComponent,
  data: {
    //title: 'Daily Report',
    module: 'LEAVEREPORT'
  }, canActivate: [AuthGuard]
},
{
  path: 'leave/report/leave-taken-report',
  component: LeaveTakenReportComponent,
  data: {
    //title: 'Leave Taken Report',
    module: 'LEAVEREPORT'
  }, canActivate: [AuthGuard]
},
{
  path: 'leave/report/Leave-balance-report',
  component: LeaveBalanceReportComponent,
  data: {
    //title: 'Leave Balance Report',
    module: 'LEAVEREPORT'
  }, canActivate: [AuthGuard]
},

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LeaveRoutingModule { }
