import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeaveTypeComponent } from './leave-type/leave-type.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { TimepickerModule, BsDatepickerModule } from 'ngx-bootstrap';
import { GeneralsettingsComponent } from './generalsettings/generalsettings.component';
import { CustomizePolicyComponent } from './customize-policy/customize-policy.component';
import { BoxModule } from 'angular-admin-lte';
import { SelectModule } from 'ng2-select';
import { CoreModule } from 'src/app/core/core.module';
import { TabsModule as MkTabsModule } from 'angular-admin-lte';
import { CustomizeLeaveBalanceComponent } from './customize-leave-balance/customize-leave-balance.component';
import { UpdateLeaveBalanceComponent } from './customize-leave-balance/update-leave-balance/update-leave-balance.component';
import { RouterModule } from '@angular/router';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { ApprovalSettingComponent } from './approval-setting/approval-setting.component';
import { NgxEditorModule } from 'ngx-editor';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { ScheduleServicesComponent } from './scheduleServices/scheduleServices.component';
import { TimePickerModule } from '@syncfusion/ej2-angular-calendars';

import { GridAllModule, PagerModule } from '@syncfusion/ej2-angular-grids';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    NgbModule,
    BoxModule, SelectModule,
    MkTabsModule,
    BoxModule,
    CoreModule,
    TimePickerModule,
    SelectModule,
    ReactiveFormsModule,
    NgbModule,
    GridAllModule,
    DatePickerModule,
    PagerModule,
    AngularMultiSelectModule,
    TimepickerModule.forRoot(),
    BsDatepickerModule.forRoot(),
    NgxEditorModule,
    DropDownListModule
  ],
  declarations: [LeaveTypeComponent,ScheduleServicesComponent ,GeneralsettingsComponent, CustomizePolicyComponent, CustomizeLeaveBalanceComponent, UpdateLeaveBalanceComponent, ApprovalSettingComponent]
})
export class SettingModule { }
