import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs";
import { environment } from "src/app/environment";
import { map } from "rxjs/operators";
import { AuthService } from "src/app/services/auth.service";


@Injectable({
  providedIn: 'root'
})
export class LocationService {

constructor(private http: HttpClient,private authService: AuthService) {}


  getlocationlist(parm): Observable<any> {
    const headers = new HttpHeaders();
  let token = this.authService.getToken();
  headers.append('Authorization', `bearer ${token}`)
  headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/Admin/GetAlllocations";
    return this.http
      .post(URL,parm,{headers:headers})
      // .pipe(map((response: Response) => response));
  }

  Createlocation(parms): Observable<any> {
    const headers = new HttpHeaders();
  let token = this.authService.getToken();
  headers.append('Authorization', `bearer ${token}`)
  headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/Admin/SaveLocation";
    return this.http
      .post(URL, parms,{headers:headers})
      // .pipe(map((response: Response) => response));
  }



  getAllCountry(parms){
    const headers = new HttpHeaders();
  let token = this.authService.getToken();
  headers.append('Authorization', `bearer ${token}`)
  headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/Admin/GetAllCountry";
    return this.http
      .post(URL, parms,{headers:headers})
      // .pipe(map((response: Response) => response));
  }

  getAllState(parms){
    const headers = new HttpHeaders();
  let token = this.authService.getToken();
  headers.append('Authorization', `bearer ${token}`)
  headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/Admin/GetAllStates";
    return this.http
      .post(URL, parms,{headers:headers})
      // .pipe(map((response: Response) => response));
  }

  getLocationDetails(LocationId,CmpCode): Observable<any> {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/Admin/GetLocationById?Id=" + LocationId+`&CmpCode=`+CmpCode;
    return this.http.get(URL,  { headers: headers })
    // .pipe(map((response: Response) => response));
  }

  getLocationByCountryId(CountryId,cmpcode): Observable<any> {
    const headers = new HttpHeaders();
    let token = this.authService.getToken();
    headers.append('Authorization', `bearer ${token}`)
    headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/Admin/GetStateByCountry?id=" + CountryId+"&Cmpcode="+cmpcode;
    return this.http.get(URL,  { headers: headers })
    // .pipe(map((response: Response) => response));
  }
  deleteLocation(locId, cmp): Observable<any>{
    const headers = new HttpHeaders();
  let token = this.authService.getToken();
  headers.append('Authorization', `bearer ${token}`)
  headers.append('Content-Type', 'application/json');
    const URL = environment.apiUrl + "/Admin/DeleteLocation?Id="+locId+"&cmpcode="+cmp;
    return this.http
      .post(URL,{headers:headers})
      // .pipe(map((response: Response) => response));
  }
  // updatelocation(id: number) {
  //     const URL = environment.apiUrl + "/Admin/Updatelocation";
  //   // return this.http.put( + '/' + location.id, location);
  // }
}
