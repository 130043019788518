import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { LeaveService } from '../../leave.service';
import { CommonService } from 'src/app/common.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SSF } from 'xlsx/types';
import { BsDatepickerConfig } from 'ngx-bootstrap';
import { UserService } from 'src/app/user/user.service';
import { DepartmentService } from 'src/app/+layout/department/department.service';
import { LocationService } from 'src/app/+layout/location/location.service';
import { DesignationService } from 'src/app/+layout/designation/designation.service';
import { Permission } from 'src/app/permission-keyword';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { DatePipe } from '@angular/common';
import { ToasterService } from 'src/app/+layout/expense/toaster/toaster.service';


class LeaveTypeData {
  IsAccural: Boolean;
  AccuralPeriod: number;
  AccuralDay: number;
  AccuralMonth: number;
  AccuralNoOfDays: number;
  AccuralDaysInPeriod: number;
  IsReset: Boolean;
  ResetPeriod: number;
  ResetDay: number;
  ResetMonth: number;
  CarryForwardType: number;
  CarryForwardPercentageUnit: number;
  EncashmentPercentageUnit: number;
  EntitlementCalendarList: any;
  ProrateAccuralFMList: any;
  ProrateAccuralLMList: any;
}

@Component({
  selector: 'app-customize-policy',
  templateUrl: './customize-policy.component.html',
  styleUrls: ['./customize-policy.component.css']
})
export class CustomizePolicyComponent implements OnInit {
  datePickerConfiq: Partial<BsDatepickerConfig>;
  showHistory: Boolean = false;
  ddlEmployeeUserId: any;
  ddlEmployeeId: any;
  EmployeeList = [];
  ActiveEmployee = [];
  currentUserId: any;
  totalEmployeeList: any;
  employeName: any;
  employeCode: any;
  customizePolicyForm: FormGroup;
  userLoader: boolean;
  showMessage: any;
  showSuccess: boolean;
  avialableLIstBalance: any;
  userAvalLeave: any;
  applyLeaveSelectedUserLeaveBalance: any;
  minDate: any;
  startDate: any;
  endDate: any;
  selectLeaveHistory: any;
  selectedLeaveTypeID: any;
  myRequestsListLength: any;
  myRequestsListHistoryLength: any;
  myRequestsPage = 1;
  myRequestsPageSize = 10;
  myHistoryRequestsPage = 1;
  myHistoryRequestsPageSize = 10;
  flterShowData = false;
  statusSelectValue = true;
  // filter VAr
  DepartmentList: any[];
  ActiveDepartment: any[];
  LocationList: any[];
  todatlLocationList: any;
  ActiveLocation: any[];
  ActiveDesigation: any[];
  DesigationList: any[];
  ActiveRoles: any[];
  RolesList: any[];
  ddlRolesId: any;
  ddlDepartmentId: any;
  ddlLocationId: any;
  ddlDesigationId: any;
  DesigationCollection: any[];

  private isClickOutside = false;

  MonthList = [{ value: 1, name: "Jan" }, {value: 1, name: "Feb" }, { value: 2, name: "Mar"}, { value: 3, name: "April" }, { value:4, name: "May"}, { value: 5, name: "Jun" }, { value: 6, name: "Jul" }, { value: 7, name: "Aug" }, { value: 8, name: "Sep" }, { value: 9, name: "Oct" }, { value: 10, name: "Nov" }, { value: 11, name: "Dec" }];
  AccuralDayList = [];
  allDepartmentList: any;
  allLocationList: any;
  allDesigationList: any;
  allRolesList: any;


  // New DropdownLists
  ddlAccuralDaysList = [];
  ddlAccuralMonthsList = [];

  ddlResetDaysList = [];
  ddlResetMonthsList = [];


  // Entitlements More Options Related Data
  IsProrateAccuralEnable: false;
  IsOpeningBalanceEnable: false;
  IsMaximumBalanceEnable: false;
  IsDeductibleHolidaysEnable: false;

  // Prorate Accural model
  prorateAccuralType: string;
  prorateAccural: any;
  roundOffValueSelectList = [
    { value: 1, name: "Nearest" },
    { value: 2, name: "Minimum" },
    { value: 3, name: "Maximum" }
  ];
  roundOffTypeSelectList = [
    { value: 1, name: "Day" },
    { value: 2, name: "Half Day" },
    { value: 3, name: "Quarter Day" }
  ];
  prorateAccuralDayList = [];

  tempLeaveType: LeaveTypeData = new LeaveTypeData();

  vh_StartDate: Date;
  vh_EndDate: Date;

  CustomizePermission: boolean;
  ViewHistoryPermission: boolean;
  LeaveExport: boolean;
  CmpCode:any;
  ActiveEmployeefields: any;
  EmpValue: any;
  AccuralPerioddata: any;
  AccuralPeriodfield: any;
  ddlAccuralDaysListfield: any;
  ddlAccuralMonthsListfield: any;
  AccuralDaysInPeriod: any;
  AccuralDaysInPeriodfield: any;
  ResetPeriodfield: any;
  ResetPeriod: any;
  ddlResetDaysListfield: any;
  ddlResetMonthsListfield: any;
  CarryForwardTypefield: any;
  CarryForwardType: any;
  CarryForwardPercentagefield: any;
  CarryForwardPercentage: any;
  CarryForwardExpiresinfield: any;
  CarryForwardExpiresin: any;
  EncashmentPercentageUnitfield: any;
  EncashmentPercentageUnitdata: any;
  ProrateAccuralTypefield: any;
  ProrateAccuralTypedata: any;
  DeductibleHolidaysfield: any;
  DeductibleHolidaysdata: any;
  LocalDataPermission: any;
  constructor(
    private _authSer: AuthService,
    private _leaveSer: LeaveService,
    private _commonSer: CommonService,
    private toaster: ToasterService,
    private _fb: FormBuilder,private datePipe: DatePipe,
    private userService: UserService,
    private _depratmentSer: DepartmentService,
    private _locationSer: LocationService,
    private _desigation: DesignationService

    
  ) {
    this.CmpCode = this._authSer.getcompanyCode();
    this._authSer.IsPermissions('Customize Leave Policy');
    this.LocalDataPermission = this._authSer.LocalDataPermission;
    if (this.LocalDataPermission === '' || this.LocalDataPermission === null || this.LocalDataPermission === undefined) {
      this.LocalDataPermission[0].addPermission = 0;
      this.LocalDataPermission[0].editPermission = 0;
      this.LocalDataPermission[0].viewpermission = 0;
      this.LocalDataPermission[0].deletePermission = 0;
    }
    try {
      this.ViewHistoryPermission = this.LocalDataPermission[0].viewpermission;//this.CurrentUser.permission.includes(Permission.ProjectView);
      this.LeaveExport = this.LocalDataPermission[0].viewpermission;//this.CurrentUser.permission.includes(Permission.ProjectAdd);
      this.CustomizePermission = this.LocalDataPermission[0].editPermission;// this.CurrentUser.permission.includes(Permission.ProjectEdit);
      this.ddlEmployeeUserId = this._authSer.getUserId();
      this.currentUserId = this._authSer.getUserId();
      this.ddlEmployeeId = 0;
    } catch (ex) {

    }
    this.datePickerConfiq = Object.assign(
      { },
      {
        showWeekNumbers: true,
      }
    );
   }

   public filter: Object;
   public filterSettings: Object;
   public selectionSettings: Object;
   public height: string = '240px';
   public fields: Object = { text: 'text', value: 'value' };
   public item: number[] = [1, 2, 3, 4, 5];
   public pageSettings = { pageCount: 3 };
   DateFormat: any;

  @ViewChild('overviewgrid') public Grid: GridComponent;
  load() {// Created By S Initialize grid properties
    const rowHeight: number = this.Grid.getRowHeight();  // height of the each row
    const gridHeight: any = this.Grid.height;  // grid height
    const pageSize: number = this.Grid.pageSettings.pageSize;   // initial page size
    const pageResize: any = (gridHeight - (pageSize * rowHeight)) / rowHeight; // new page size is obtained here
    this.Grid.pageSettings.pageSize = pageSize + Math.round(pageResize);
  }
  EmPplaceholder
  ngOnInit() {// Angular Lifecycle hook
    this.EmPplaceholder="Select Employee"
    this.ActiveEmployeefields = {text: 'text', value: 'id' }
    this.AccuralPerioddata = [
      { name: "One Time", value: 1 },
      { name: "Yearly", value: 2 },
      { name: "Monthly", value: 3 },
      { name: "Half Yearly", value: 4 },
      { name: "Triannually", value: 5 },
      { name: "Quarterly", value: 6 },
      { name: "Bi-Monthly", value: 7 },
      { name: "Semi Monthly", value: 8 },
      { name: "Bi-Weekly", value: 9 },
      { name: "Weekly", value: 10 },
    ]; 
    this.AccuralPeriodfield =  {value:"value", text: "name" }

    this.AccuralDaysInPeriod = [
      { name: "Current Accrual", value: 1 },
      { name: "Next Accrual", value: 2 },
    ];
    this.AccuralDaysInPeriodfield = {value:"value", text: "name" }

    this.ResetPeriod = [
      { name: "One Time", value: 1 },
      { name: "Yearly", value: 2 },
      { name: "Monthly", value: 3 },
      { name: "Half Yearly", value: 4 },
      { name: "Triannually", value: 5 },
      { name: "Quarterly", value: 6 },
      { name: "Bi-Monthly", value: 7 },
      { name: "Semi Monthly", value: 8 },
      { name: "Bi-Weekly", value: 9 },
      { name: "Weekly", value: 10 },
    ];
    this.ResetPeriodfield = {value:"value", text: "name" }

    this.CarryForwardType =[
      { name: "Carry Forward", value: 1 },
      { name: "Carry Forward With Expiry", value: 2 },
      { name: "Carry Forward With Overall Limit", value: 3 }
    ];
    this.CarryForwardTypefield = {value:"value", text: "name" }

    this.CarryForwardPercentage = [
      { name: "Percentage", value: 1 },
      { name: "Unit(s)", value: 2 },
    ];
    this.CarryForwardPercentagefield = {value:"value", text: "name" }

    this.CarryForwardExpiresin = [
      { name: "Month(s)", value: 1 },
      { name: "Day(s)", value: 2 },
    ];
    this.CarryForwardExpiresinfield = {value:"value", text: "name" }

    this.EncashmentPercentageUnitdata = [
      { name: "Percentage", value: 1 },
      { name: "Unit(s)", value: 2 },
    ];
    this.EncashmentPercentageUnitfield = {value:"value", text: "name" }

    this.ProrateAccuralTypedata = [
      { name: "Start of Policy", value: 1 },
      { name: "Start and End of Policy", value: 2 },
      { name: "Do not Prorate", value: 3 },
    ];
    this.ProrateAccuralTypefield = {value:"value", text: "name" }

    this.DeductibleHolidaysdata = [
      { name: "All Holidays", value: 1 },
      { name: "Holidays on Workdays", value: 2 },
    ];
    this.DeductibleHolidaysfield = {value:"value", text: "name" }

    this.CmpCode = this._authSer.getcompanyCode();
    this.DateFormat= this._authSer.getdateFormat();
  
    // this._authSer.currentUser.subscribe((res) => {
    //   this.currentUserId = res.userId;
    //   this.CustomizePermission = res.permission.includes(Permission.CustomizePolicy);
    //   this.ViewHistoryPermission = res.permission.includes(Permission.ViewHistory);
    //   this.LeaveExport = res.permission.includes(Permission.LeaveHistoryExport);
    //   this.ddlEmployeeUserId = res.userId;
    //   this.ddlEmployeeId = 0;
    // });
    this.getEmployeesListForSelect();
    this.filterSettings = { type: "Menu" };
    this.filter = { type: "CheckBox" };
    this.selectionSettings = { persistSelection: true, type: "Multiple", checkboxOnly: true };

    this.customizePolicyForm = this._fb.group({
      EmployeeId: [],
      CmpCode: this.CmpCode,
      LeaveTypeId: [],
      EffectiveFrom: [ new Date()],
      IsAccural: [false],
      AccuralPeriod: [],
      AccuralDay: [],
      AccuralMonth: [],
      AccuralNoOfDays: [],
      AccuralDaysInPeriod: [],
      IsReset: [false],
      ResetPeriod: [],
      ResetDay: [],
      ResetMonth: [],
      CarryForwardType: [],
      CarryForwardValue: [],
      CarryForwardPercentageUnit: [],
      CarryForwardMaxLimit: [],
      CarryForwardExpiresIn: [],
      CarryForwardExpiresInType: [],
      CarryForwardOverallLimit: [],
      EncashmentValue: [],
      EncashmentPercentageUnit: [],
      EncashmentMaxLimit: [],
      IsProrateAccuralEnable: [false],
      ProrateAccuralType: [],
      IsOpeningBalanceEnable: [false],
      OpeningBalance: [],
      IsMaximumBalanceEnable: [false],
      MaximumBalance: [],
      IsDeductibleHolidaysEnable: [false],
      DeductibleHolidays: [],
      ProrateBy: [false],
      IsRoundOff: [false],
      RoundOffToValue: [],
      RoundOffToType: [],
      CreatedBy: [],
      CreatedDate: [],
      UpdatedBy: [],
      UpdatedDate: [],
      EntitlementCalendarList: [[]],
      ProrateAccuralFMList: [[]],
      ProrateAccuralLMList: [[]]
    });

    // this.getAvilabeLIstBalance()
    //this.bindAllDashboardData();

    this.setTempLeaveTypeData();
    this.initAccuralDDL();
    this.setDDLSelectLists();
  }

  dateformat(datas:string){// Created By K Global Date format
    return this._authSer.Dateformat(datas);

}


  getFormattedDate(val: any) {// Created By A Date format
    if (val === null) {
      return null;
    }
    const date = new Date(val);
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    return new Date(year, month, day);
  }
  getCSharpFormattedDate(val: any) {// Created By A Backend date format
    if (val === null || val === "") {
      return null;
    }
    const date = new Date(val);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return month + "-" + day + "-" + year;
  }

  // hide side menu
  @HostListener("click")
  clickInside() {// Created By A Set value
    this.isClickOutside = true;
  }

  @HostListener("document:click")
  clickout() {// Created By A Set values
    if (!this.isClickOutside) {
      this.flterShowData = false;
    }
    this.isClickOutside = false;
  }
  // end hide menu

  numberOnly(event): boolean {// Created By A Numbers validations
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  numberOnlyWithDecimal(event): boolean {// Created By A Numbers and decimals validations
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 
      && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  bindAllDashboardData() {// Created By A Bind data on dashboard
    // this.getAllHolidayList();
    // this.getAllLeave();
    this.userAvaliableLeave();
  }

  getNumberWithOrdinal(n) {// Created By K Set Ordinals for numbers
    let s = ["th", "st", "nd", "rd"], v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  }

  onShowHistory() {// Created By A On View history
    let currentDate = new Date();
    this.vh_StartDate = new Date(currentDate.getFullYear() + "-01-01");
    this.vh_EndDate = currentDate;

    this.startDate = this.vh_StartDate;
    this.endDate = this.vh_EndDate;

    this.showHistory = true;
    this.GetSelectEmployeeHistory();
  }

  backHistory() {// Created By A set value
    this.showHistory = false;
  }

  getAvilabeLIstBalance() {// Created By A Getting available balance list 
    this.getEmployeesListForSelect();
    this._leaveSer.getAvailableLeaveBalance(this.ActiveEmployee[0].id, 1,this.CmpCode).subscribe(res => {
      this.avialableLIstBalance = res['data'].table;
    });
  }


  getDepartment() {// Created By A Getting all departments
    let parms = {
      Condition: "",
      FieldName: "CreatedDate",
      NoOfRecords: 10000,
      PageNo: 1,
      SortType: "desc",
      CmpCode:this.CmpCode,
    };
    this._depratmentSer.getDepartmentList(parms).subscribe((res) => {
      // this.allDepartmentList = res['data'].table
      this.allDepartmentList = res["data"].table;
      // if (res["data"].table != null && res["data"].table.lenght > 0) {
      //   this.employeName = res["data"].table[0].employeeName;
      //   this.employeCode = res["data"].table[0].empCode;
      // }
      this.DepartmentList = this._commonSer.SetDDlDropdown(
        res["data"].table,
        "departmentId",
        "departmentName"
      );
      // const selectedEmp = this.allDepartmentList.find(
      //   (x) => x.userId.toString() === this.currentUserId.toString()
      // );
      // if (selectedEmp !== undefined) {
      //   const empValue = {
      //     id: selectedEmp.departmentId,
      //     text: selectedEmp.departmentName,
      //   };
      //   setTimeout(() => {
      //     this.departmentSelection(empValue, "Department", undefined, undefined);
      //   }, 300);
      // }
      // this.DepartmentMultiList = [];
      //   for (let i = 0; i < res.data.table.length; i++) {
      //     this.DepartmentMultiList.push({
      //       id: res.data.table.departmentId,
      //       text: res.data.table.departmentName,
      //     });
      //   }
    });
  }
  departmentSelection(value: any, placeHolder: any, index: any, index2: any) {// Created By A On select department
    console.log(this.ActiveDepartment)
    try {
      switch (placeHolder) {
        case "Department":

          this.ActiveDepartment = [];
          this.ActiveDepartment.push(value);

          this.ddlDepartmentId = value.id;

          console.log(this.ActiveEmployee);

          // this.bindAllDashboardData();
          break;
      }
    } catch (ex) {}
  }


  getAllLocation() {// Created By A Getting all locations
    let parms = {
      Condition: "",
      FieldName: "CreatedDate",
      NoOfRecords: 10000,
      PageNo: 1,
      SortType: "desc",

      CmpCode:this.CmpCode,
    };
    this._locationSer.getlocationlist(parms).subscribe((res) => {
      // this.allLocationList = res['data'].table
      this.allLocationList = res["data"].table;
      if (res["data"].table != null && res["data"].table.lenght > 0) {
        this.employeName = res["data"].table[0].employeeName;
        this.employeCode = res["data"].table[0].empCode;
      }
      this.LocationList = this._commonSer.SetDDlDropdown(
        res["data"].table,
        "locationId",
        "locationName"
      );
      const selectedEmp = this.allLocationList.find(
        (x) => x.userId.toString('utf8') === this.currentUserId.toString('utf8')
      );
      if (selectedEmp !== undefined) {
        const empValue = {
          id: selectedEmp.locationId,
          text: selectedEmp.locationName,
        };
        setTimeout(() => {
          this.ddlSelection(empValue, "Location", undefined, undefined);
        }, 300);
      }
    });
  }

  locationSelection(value: any, placeHolder: any, index: any, index2: any) {// Created By A On Select location
    try {
      switch (placeHolder) {
        case "Location":
          this.ActiveLocation = [];
          this.ActiveLocation.push(value);
          this.ddlLocationId = value.id;
          console.log(this.ActiveEmployee);

          // this.bindAllDashboardData();
          break;
      }
    } catch (ex) {}
  }

  getAllDesigation() {// Created By A Getting all designations
    let parms = {
      Condition: "",
      FieldName: "CreatedDate",
      NoOfRecords: 10000,
      PageNo: 1,
      SortType: "desc",
      CmpCode:this.CmpCode
    };
    this._desigation.getDesignationList(parms).subscribe((res) => {
      // this.allDesigationList = res['data'].table
      this.allDesigationList = res["data"].table;
      if (res["data"].table != null && res["data"].table.lenght > 0) {
        this.employeName = res["data"].table[0].employeeName;
        this.employeCode = res["data"].table[0].empCode;
      }
      this.DesigationList = this._commonSer.SetDDlDropdown(
        res["data"].table,
        "designationId",
        "designationName"
      );
      const selectedEmp = this.allDesigationList.find(
        (x) => x.userId.toString() === this.currentUserId.toString('utf8')
      );
      if (selectedEmp !== undefined) {
        const empValue = {
          id: selectedEmp.designationId,
          text: selectedEmp.designationName,
        };
        setTimeout(() => {
          this.ddlSelection(empValue, "Desigation", undefined, undefined);
        }, 300);
      }
    });
  }

  desigationSelection(value: any, placeHolder: any, index: any, index2: any) {// Created By A On select designation
    try {
      switch (placeHolder) {
        case "Desigation":
          this.ActiveDesigation = [];
          this.ActiveDesigation.push(value);
          this.ddlDesigationId = value.id;
          console.log(this.ActiveEmployee);

          // this.bindAllDashboardData();
          break;
      }
    } catch (ex) {}
  }


  getAllRole() {// Created By A Getting all roles
    let parms = {
      PageNo: 1,
      NoOfRecords: 10,
      SortType: "asc",
      FieldName: "name",
      Condition: "",
      CmpCode:this.CmpCode
    };
    this._leaveSer.getAllRolesList(parms).subscribe((res) => {
      // this.allRolesList = res['data'].table
      this.allRolesList = res["data"].table;
      // if (res['data'].table != null && res['data'].table.lenght > 0) {
      //   this.employeName = res["data"].table[0].employeeName;
      //   this.employeCode = res["data"].table[0].empCode;
      // }
      this.RolesList = this._commonSer.SetDDlDropdown(
        res["data"].table,
        "roleId",
        "name"
      );
      const selectedEmp = this.allRolesList.find(
        (x) => x.userId.toString() === this.currentUserId.toString('utf8')
      );
      if (selectedEmp !== undefined) {
        const empValue = { id: selectedEmp.roleId, text: selectedEmp.name };
        setTimeout(() => {
          this.ddlSelection(empValue, "Roles", undefined, undefined);
        }, 300);
      }
    });
  }

  rolesSelection(value: any, placeHolder: any, index: any, index2: any) {// Created By A On select role
    try {
      switch (placeHolder) {
        case "Roles":
          this.ActiveRoles = [];
          this.ActiveRoles.push(value);
          this.ddlRolesId = value.id;
          console.log(this.ActiveEmployee);

          // this.bindAllDashboardData();
          break;
      }
    } catch (ex) {}
  }
  filterShow() {// Created By A Initialize filters modal
    // this.getAllEmployeList();
    this.getDepartment();
    this.getAllDesigation();
    this.getAllLocation();
    this.getAllRole();
    this.flterShowData = !this.flterShowData;
  }
  closeFilter() {// Created By A
    this.flterShowData = false;
  }

  SideFilterFunc() {// Created By A On Search click
    this.flterShowData = false;
    this.userAvaliableLeave();
  }

  ResetFilter() { // Created By A Reset values
    this.statusSelectValue = true;
    this.ActiveDesigation = [];
    this.ddlDepartmentId = null;
    this.ddlDesigationId = null;
    this.ddlLocationId = null;
    this.ddlRolesId = null;
    // this.ddlEmployeeUserId = null
    this.ActiveDepartment = [];
    // this.ActiveEmployee =[]
    this.ActiveLocation = [];
    this.ActiveRoles = [];
  }

  // Side filter end

  userAvaliableLeave() {// Created By A Getting Users avalible leave details
    this.userLoader = true;
    if (this.ddlEmployeeId != 0) {
      let parms = {
        "EmployeeId": this.ddlEmployeeId,
        "FilterStatus": this.statusSelectValue,
        "FilterEmployees": null,
        "FilterDepartments": this.ddlDepartmentId || null,
        "FilterLocations": this.ddlLocationId || null,
        "FilterDesignations": this.ddlDesigationId || null,
        "FilterRoles": this.ddlRolesId || null,
        "CmpCode":this.CmpCode
      };

      this.userAvalLeave = [];
      this.myRequestsListLength = 0;
      this._leaveSer.getAllEpmloyeeLeavePolicy(parms).subscribe((res: any) => {
        this.userLoader = false;
        this.userAvalLeave = res["data"];
        this.myRequestsListLength = this.userAvalLeave.length;
      }, error => {
        this.userLoader = false;
        console.log(error);
      });
    }
  }

  
  getEmployeesListForSelect () {// Created By A Getting all employees list
    debugger
    this.ddlEmployeeId = 0;
    this.totalEmployeeList = [];
    this.EmployeeList = [];
    this._leaveSer.getEmployeesListForSelect(this.currentUserId,this.CmpCode).subscribe((res) => {
      this.totalEmployeeList = res['data'].table;
      this.EmployeeList = this._commonSer.SetDDlDropdown(res["data"].table, 'employeeId', 'employeeCodeName');
      this.EmpValue = this.currentUserId
      const currentEmp = this.totalEmployeeList.find(x => x.userId == this.currentUserId);
      if (currentEmp !== undefined && currentEmp != null) {
        this.ddlEmployeeId = currentEmp.employeeId;
        const empValue = { 'id': currentEmp.employeeId, 'text': currentEmp.employeeCodeName };
        setTimeout(() => {
          this.ddlSelection(empValue, 'Employee', undefined, undefined);
        }, 300);
      }
    });
  }

  ddlSelection(value: any, placeHolder: any, index: any, index2: any) {// Created By A On select employees
    debugger
    try {
      switch (placeHolder) {
        case 'Employee':
          this.ActiveEmployee = [];
          this.ActiveEmployee.push(value);
          if(value.id == undefined){
            this.ddlEmployeeId = value.value;
          }else{
            this.ddlEmployeeId = value.id;
          }
      
        
          this.bindAllDashboardData();
          break;
      }
    } catch (ex) {

    }
  }

  
  saveCustomizeLeave() {// Created By A On Save Customize Leave Policy
    this.userLoader = true;
    this.customizePolicyForm.patchValue({
      EmployeeId: this.ddlEmployeeId,
      LeaveTypeId: this.selectedLeaveTypeID,
      EntitlementCalendarList: this.tempLeaveType.EntitlementCalendarList
    });

    let effectiveFromDate = this.getCSharpFormattedDate(this.customizePolicyForm.value.EffectiveFrom);
    if (effectiveFromDate != null) {
      this.customizePolicyForm.patchValue({
        EffectiveFrom: effectiveFromDate
      });
    } else {
      this.customizePolicyForm.patchValue({
        EffectiveFrom: null
      });
    }

    this._leaveSer.saveLeavePolicy(this.customizePolicyForm.value).subscribe(res => {
      if (res['success'] === true) {
        this.userLoader = false;
        this.showSuccess = true;
        this.showMessage = res['data'];
        this.toaster.show("success",this.showMessage);
        // this._commonSer.OpenModalHandler("leaveTypeMessageModel");
      } else {
        this.userLoader = false;
        this.showSuccess = false;
        this.showMessage = res['message'];
        this.toaster.show("success",this.showMessage);
        // this._commonSer.OpenModalHandler("leaveTypeMessageModel");
      }
    }, (error) => {
      this.userLoader = false;
      this.showSuccess = false;
      this.showMessage = "Uh oh, Something went wrong !";
      this.toaster.show("success",this.showMessage);
      // this._commonSer.OpenModalHandler("leaveTypeMessageModel");
      
    });
  }

  selectLeaveType(LeaveTypeId) {// Created By A Get Employe Leave policy details
    debugger;
    this.selectedLeaveTypeID = LeaveTypeId;
    let parms = {};
    this.userLoader = true;
    this.flterShowData = false;
    this._leaveSer.getEmployeeLeavePolicy(this.ddlEmployeeId, LeaveTypeId, parms, this.CmpCode).subscribe(res => {
      this.userLoader = false;
      console.clear();
      console.log(res['data'][0]);
      this.customizePolicyForm.patchValue({
        EmployeeId: res['data'][0].employeeId,
        LeaveTypeId: res['data'][0].leaveTypeId,
        EffectiveFrom: this.getFormattedDate(res['data'][0].effectiveFrom),
        IsAccural: res['data'][0].isAccural,
        AccuralPeriod: res['data'][0].accuralPeriod,
        AccuralDay: res['data'][0].accuralDay,
        AccuralMonth: res['data'][0].accuralMonth,
        AccuralNoOfDays: res['data'][0].accuralNoOfDays,
        AccuralDaysInPeriod: res['data'][0].accuralDaysInPeriod,
        IsReset: res['data'][0].isReset,
        ResetPeriod: res['data'][0].resetPeriod,
        ResetDay: res['data'][0].resetDay,
        ResetMonth: res['data'][0].resetMonth,
        CarryForwardType: res['data'][0].carryForwardType,
        CarryForwardValue: res['data'][0].carryForwardValue,
        CarryForwardPercentageUnit: res['data'][0].carryForwardPercentageUnit,
        CarryForwardMaxLimit: res['data'][0].carryForwardMaxLimit,
        CarryForwardExpiresIn: res['data'][0].carryForwardExpiresIn,
        CarryForwardExpiresInType: res['data'][0].carryForwardExpiresInType,
        CarryForwardOverallLimit: res['data'][0].carryForwardOverallLimit,
        EncashmentValue: res['data'][0].encashmentValue,
        EncashmentPercentageUnit: res['data'][0].encashmentPercentageUnit,
        EncashmentMaxLimit: res['data'][0].encashmentMaxLimit,
        IsProrateAccuralEnable: res['data'][0].isProrateAccuralEnable,
        ProrateAccuralType: res['data'][0].prorateAccuralType,
        IsOpeningBalanceEnable: res['data'][0].isOpeningBalanceEnable,
        OpeningBalance: res['data'][0].openingBalance,
        IsMaximumBalanceEnable: res['data'][0].isMaximumBalanceEnable,
        MaximumBalance: res['data'][0].maximumBalance,
        IsDeductibleHolidaysEnable: res['data'][0].isDeductibleHolidaysEnable,
        DeductibleHolidays: res['data'][0].deductibleHolidays,
        ProrateBy: res['data'][0].prorateBy,
        IsRoundOff: res['data'][0].isRoundOff,
        RoundOffToValue: res['data'][0].roundOffToValue,
        RoundOffToType: res['data'][0].roundOffToType,
        // CreatedBy:[""],
        // CreatedDate:res['data'][0].leaveTypeId,
        // UpdatedBy:"",
        // UpdatedDate:res['data'][0].leaveTypeId
      });

      this.tempLeaveType = new LeaveTypeData();
      this.tempLeaveType.IsAccural = res["data"][0].isAccural;
      this.tempLeaveType.AccuralPeriod = res["data"][0].accuralPeriod;
      this.tempLeaveType.AccuralDay = res["data"][0].accuralDay;
      this.tempLeaveType.AccuralMonth = res["data"][0].accuralMonth;
      this.tempLeaveType.AccuralNoOfDays = res["data"][0].accuralNoOfDays;
      this.tempLeaveType.AccuralDaysInPeriod = res["data"][0].accuralDaysInPeriod;
      this.tempLeaveType.IsReset = res["data"][0].isReset;
      this.tempLeaveType.ResetPeriod = res["data"][0].resetPeriod;
      this.tempLeaveType.ResetDay = res["data"][0].resetDay;
      this.tempLeaveType.ResetMonth = res["data"][0].resetMonth;
      this.tempLeaveType.CarryForwardType = res["data"][0].carryForwardType;
      this.tempLeaveType.CarryForwardPercentageUnit = res["data"][0].carryForwardPercentageUnit;
      this.tempLeaveType.EncashmentPercentageUnit = res["data"][0].encashmentPercentageUnit;
      this.tempLeaveType.EntitlementCalendarList = [];
      this.tempLeaveType.ProrateAccuralFMList = [];
      this.tempLeaveType.ProrateAccuralLMList = [];

      this.IsProrateAccuralEnable = res["data"][0].isProrateAccuralEnable;
      this.IsOpeningBalanceEnable = res["data"][0].isOpeningBalanceEnable;
      this.IsMaximumBalanceEnable = res["data"][0].isMaximumBalanceEnable;
      this.IsDeductibleHolidaysEnable = res["data"][0].isDeductibleHolidaysEnable;
      if (res["data"][0].entitlementCalendarList == null ||
        res["data"][0].entitlementCalendarList == undefined ||
        res["data"][0].entitlementCalendarList.length < 1) {
        this.createEntitlementCalendarData();
      } else {
        this.setEntitlementCalendarData(res["data"][0].entitlementCalendarList);
      }

      this.prorateAccural = {};
      this.prorateAccural.leaveTypeId = res['data'][0].leaveTypeId;
      this.prorateAccural.employeeId = res['data'][0].employeeId;
      this.prorateAccural.prorateBy = res['data'][0].prorateBy;
      this.prorateAccural.isRoundOff = res['data'][0].isRoundOff;
      this.prorateAccural.roundOffToType = res['data'][0].roundOffToType;
      this.prorateAccural.roundOffToValue = res['data'][0].roundOffToValue;

      this.prorateAccuralType = res['data'][0].prorateAccuralType;

      this.prorateAccural.prorateAccuralFirstMonthList = res['data'][0].prorateAccuralFMList;
      this.duplicateFirstMonthRuleFrom = [];
      this.duplicateFirstMonthRuleTo = [];
      for (let i = 0; i < this.prorateAccural.prorateAccuralFirstMonthList.length; i++) {
        this.duplicateFirstMonthRuleFrom.push("true");
        this.duplicateFirstMonthRuleTo.push("true");
      }

      this.prorateAccural.prorateAccuralLastMonthList = res['data'][0].prorateAccuralLMList;
      this.duplicateLastMonthRuleFrom = [];
      this.duplicateLastMonthRuleTo = [];
      for (let i = 0; i < this.prorateAccural.prorateAccuralLastMonthList.length; i++) {
        this.duplicateLastMonthRuleFrom.push("true");
        this.duplicateLastMonthRuleTo.push("true");
      }
    }, error => {
      this.userLoader = false;
      console.log(error);
    });
    // this._commonSer.OpenModalHandler("customizeleave");
    this.showHistory = false;
  }

  selectLeaveTypeViewHistory(LeaveTypeId) {// Created By A On Leave Type View History
    debugger;
    this.selectedLeaveTypeID = LeaveTypeId;
    let parms = {};
    this.userLoader = true;
    this.flterShowData = false;
    this._leaveSer.getEmployeeLeavePolicy(this.ddlEmployeeId, LeaveTypeId, parms, this.CmpCode).subscribe(res => {
      this.userLoader = false;
      console.clear();
      console.log(res['data'][0]);
      this.customizePolicyForm.patchValue({
        EmployeeId: res['data'][0].employeeId,
        LeaveTypeId: res['data'][0].leaveTypeId,
        EffectiveFrom: this.getFormattedDate(res['data'][0].effectiveFrom),
        IsAccural: res['data'][0].isAccural,
        AccuralPeriod: res['data'][0].accuralPeriod,
        AccuralDay: res['data'][0].accuralDay,
        AccuralMonth: res['data'][0].accuralMonth,
        AccuralNoOfDays: res['data'][0].accuralNoOfDays,
        AccuralDaysInPeriod: res['data'][0].accuralDaysInPeriod,
        IsReset: res['data'][0].isReset,
        ResetPeriod: res['data'][0].resetPeriod,
        ResetDay: res['data'][0].resetDay,
        ResetMonth: res['data'][0].resetMonth,
        CarryForwardType: res['data'][0].carryForwardType,
        CarryForwardValue: res['data'][0].carryForwardValue,
        CarryForwardPercentageUnit: res['data'][0].carryForwardPercentageUnit,
        CarryForwardMaxLimit: res['data'][0].carryForwardMaxLimit,
        CarryForwardExpiresIn: res['data'][0].carryForwardExpiresIn,
        CarryForwardExpiresInType: res['data'][0].carryForwardExpiresInType,
        CarryForwardOverallLimit: res['data'][0].carryForwardOverallLimit,
        EncashmentValue: res['data'][0].encashmentValue,
        EncashmentPercentageUnit: res['data'][0].encashmentPercentageUnit,
        EncashmentMaxLimit: res['data'][0].encashmentMaxLimit,
        IsProrateAccuralEnable: res['data'][0].isProrateAccuralEnable,
        ProrateAccuralType: res['data'][0].prorateAccuralType,
        IsOpeningBalanceEnable: res['data'][0].isOpeningBalanceEnable,
        OpeningBalance: res['data'][0].openingBalance,
        IsMaximumBalanceEnable: res['data'][0].isMaximumBalanceEnable,
        MaximumBalance: res['data'][0].maximumBalance,
        IsDeductibleHolidaysEnable: res['data'][0].isDeductibleHolidaysEnable,
        DeductibleHolidays: res['data'][0].deductibleHolidays,
        ProrateBy: res['data'][0].prorateBy,
        IsRoundOff: res['data'][0].isRoundOff,
        RoundOffToValue: res['data'][0].roundOffToValue,
        RoundOffToType: res['data'][0].roundOffToType,
        // CreatedBy:[""],
        // CreatedDate:res['data'][0].leaveTypeId,
        // UpdatedBy:"",
        // UpdatedDate:res['data'][0].leaveTypeId
      });

      this.tempLeaveType = new LeaveTypeData();
      this.tempLeaveType.IsAccural = res["data"][0].isAccural;
      this.tempLeaveType.AccuralPeriod = res["data"][0].accuralPeriod;
      this.tempLeaveType.AccuralDay = res["data"][0].accuralDay;
      this.tempLeaveType.AccuralMonth = res["data"][0].accuralMonth;
      this.tempLeaveType.AccuralNoOfDays = res["data"][0].accuralNoOfDays;
      this.tempLeaveType.AccuralDaysInPeriod = res["data"][0].accuralDaysInPeriod;
      this.tempLeaveType.IsReset = res["data"][0].isReset;
      this.tempLeaveType.ResetPeriod = res["data"][0].resetPeriod;
      this.tempLeaveType.ResetDay = res["data"][0].resetDay;
      this.tempLeaveType.ResetMonth = res["data"][0].resetMonth;
      this.tempLeaveType.CarryForwardType = res["data"][0].carryForwardType;
      this.tempLeaveType.CarryForwardPercentageUnit = res["data"][0].carryForwardPercentageUnit;
      this.tempLeaveType.EncashmentPercentageUnit = res["data"][0].encashmentPercentageUnit;
      this.tempLeaveType.EntitlementCalendarList = [];
      this.tempLeaveType.ProrateAccuralFMList = [];
      this.tempLeaveType.ProrateAccuralLMList = [];

      this.IsProrateAccuralEnable = res["data"][0].isProrateAccuralEnable;
      this.IsOpeningBalanceEnable = res["data"][0].isOpeningBalanceEnable;
      this.IsMaximumBalanceEnable = res["data"][0].isMaximumBalanceEnable;
      this.IsDeductibleHolidaysEnable = res["data"][0].isDeductibleHolidaysEnable;

      if (res["data"][0].entitlementCalendarList == null ||
        res["data"][0].entitlementCalendarList == undefined ||
        res["data"][0].entitlementCalendarList.length < 1) {
        this.createEntitlementCalendarData();
      } else {
        this.setEntitlementCalendarData(res["data"][0].entitlementCalendarList);
      }

      this.prorateAccural = {};
      this.prorateAccural.leaveTypeId = res['data'][0].leaveTypeId;
      this.prorateAccural.employeeId = res['data'][0].employeeId;
      this.prorateAccural.prorateBy = res['data'][0].prorateBy;
      this.prorateAccural.isRoundOff = res['data'][0].isRoundOff;
      this.prorateAccural.roundOffToType = res['data'][0].roundOffToType;
      this.prorateAccural.roundOffToValue = res['data'][0].roundOffToValue;

      this.prorateAccuralType = res['data'][0].prorateAccuralType;

      this.prorateAccural.prorateAccuralFirstMonthList = res['data'][0].prorateAccuralFMList;
      this.duplicateFirstMonthRuleFrom = [];
      this.duplicateFirstMonthRuleTo = [];
      for (let i = 0; i < this.prorateAccural.prorateAccuralFirstMonthList.length; i++) {
        this.duplicateFirstMonthRuleFrom.push("true");
        this.duplicateFirstMonthRuleTo.push("true");
      }

      this.prorateAccural.prorateAccuralLastMonthList = res['data'][0].prorateAccuralLMList;
      this.duplicateLastMonthRuleFrom = [];
      this.duplicateLastMonthRuleTo = [];
      for (let i = 0; i < this.prorateAccural.prorateAccuralLastMonthList.length; i++) {
        this.duplicateLastMonthRuleFrom.push("true");
        this.duplicateLastMonthRuleTo.push("true");
      }
    }, error => {
      this.userLoader = false;
      console.log(error);
    });
    this.showHistory = false;
    this.onShowHistory();
  }

  datepickerStartDate($event1) {// Created By K Set Start Date
    this.startDate = $event1;
    this.minDate = this.startDate;
    this.minDate.setDate(this.minDate.getDate());

    this.GetSelectEmployeeHistory();
  }

  datepickerEndDate($event1) {// Created By K Set End Date
    this.endDate = $event1;
    this.minDate = this.startDate;

    this.GetSelectEmployeeHistory();
  }

  GetSelectEmployeeHistory() {// Created By A Get Employee history details
    if (this.startDate != null && this.endDate != null) {
      this.userLoader = true;
      let startDate = this.getCSharpFormattedDate(this.startDate);
      let endDate = this.getCSharpFormattedDate(this.endDate);
      let parms = {
        EmployeeId: this.ddlEmployeeId,
        LeaveTypeId: this.selectedLeaveTypeID,
        StartDate: startDate,
        EndDate: endDate,
        CmpCode:this.CmpCode
      };

      this._leaveSer.getEmployeeLeaveHistory(parms).subscribe(res => {
        this.userLoader = false;
        this.selectLeaveHistory = res['data'];
        this.myRequestsListHistoryLength = res['data'].length;
      });
    }
  }

 
  setTempLeaveTypeData() {// Created By A Set values
    this.tempLeaveType = new LeaveTypeData();

    this.tempLeaveType.IsAccural = true;
    this.tempLeaveType.AccuralPeriod = 2;
    this.tempLeaveType.AccuralDay = 1;
    this.tempLeaveType.AccuralMonth = 1;
    this.tempLeaveType.AccuralNoOfDays = 0;
    this.tempLeaveType.AccuralDaysInPeriod = 1;
    this.tempLeaveType.IsReset = true;
    this.tempLeaveType.ResetPeriod = 2;
    this.tempLeaveType.ResetDay = 71;
    this.tempLeaveType.ResetMonth = 12;
    this.tempLeaveType.CarryForwardType = 1;
    this.tempLeaveType.CarryForwardPercentageUnit = 1;
    this.tempLeaveType.EncashmentPercentageUnit = 1;
    this.tempLeaveType.EntitlementCalendarList = [];
    this.tempLeaveType.ProrateAccuralFMList = [];
    this.tempLeaveType.ProrateAccuralLMList = [];

    this.setDDLSelectLists();
    this.createEntitlementCalendarData();
  }

  initAccuralDDL() {// Created By A Set accural dropdown list
    for (let i = 1; i <= 31; i++) {
      this.AccuralDayList.push(this.getNumberWithOrdinal(i));
      this.prorateAccuralDayList.push(this.getNumberWithOrdinal(i));
    }

    this.AccuralDayList.push("Last Day");
    this.AccuralDayList.push("Policy Date");
    this.AccuralDayList.push("Joining Date");
    this.AccuralDayList.push("Birth Date");
  }

  setDDLSelectLists() {// Created By A Set Values
    this.setAccuralDaySelectList(this.tempLeaveType.AccuralPeriod.toString());
    this.setAccuralMonthSelectList(this.tempLeaveType.AccuralPeriod.toString());
    this.setResetDaySelectList(this.tempLeaveType.ResetPeriod.toString());
    this.setResetMonthSelectList(this.tempLeaveType.ResetPeriod.toString());
  }

  // Accural
  onChangeIsAccural(e) {// Created By A Reset Values
    this.tempLeaveType.IsAccural = !this.tempLeaveType.IsAccural;
    this.customizePolicyForm.patchValue({
      AccuralPeriod: 2,
      AccuralDay: 1,
      AccuralMonth: 1,
      AccuralNoOfDays: 0,
      AccuralDaysInPeriod: 1
    });

    this.tempLeaveType.AccuralPeriod = 2;
    this.tempLeaveType.AccuralDay = 1;
    this.tempLeaveType.AccuralMonth = 1;
    this.tempLeaveType.AccuralNoOfDays = 0;
    this.tempLeaveType.AccuralDaysInPeriod = 1;

    this.setAccuralFieldsData(this.tempLeaveType.AccuralPeriod.toString());
  }

  setAccuralFieldsData(_type) {// Created By A Set values
    this.setAccuralDaySelectList(_type);
    this.setAccuralMonthSelectList(_type);

    let _accuralDay = 1, _accuralMonth = 1;
    switch (_type) {
      case "1" :
        _accuralDay = 72;
        _accuralMonth = 61;
      break;
      case "2" :
      case "3" :
        _accuralDay = 1;
        _accuralMonth = 1;
      break;
      case "4" :
        _accuralDay = 1;
        _accuralMonth = 21;
      break;
      case "5" :
        _accuralDay = 1;
        _accuralMonth = 31;
      break;
      case "6" :
        _accuralDay = 1;
        _accuralMonth = 41;
      break;
      case "7" :
        _accuralDay = 1;
        _accuralMonth = 51;
      break;
      case "8" :
        _accuralDay = 41;
        _accuralMonth = 1;
      break;
      case "9" :
        _accuralDay = 61;
        _accuralMonth = 1;
      break;
      case "10" :
        _accuralDay = 61;
        _accuralMonth = 1;
      break;
    }

    this.customizePolicyForm.patchValue({
      AccuralDay: _accuralDay,
      AccuralMonth: _accuralMonth
    });
  }

  setAccuralDaySelectList(_type) {// Created By A Set values
    this.ddlAccuralDaysList = [];
    this.ddlAccuralDaysListfield =  {value:"id", text: "text" };
    switch (_type) {
      case "1" :
      case "2" :
      case "3" :
      case "4" :
      case "5" :
      case "6" :
      case "7" :
        for (let i = 1; i <= 31; i++) {
          let _text = this.getNumberWithOrdinal(i);
          let newOption = {
            id: i,
            text: _text
          };
          this.ddlAccuralDaysList.push(newOption);
        }
      break;
      case "8" :
        for (let i = 1; i <= 15; i++) {
          let _text = "" + i + " & " + (i + 15) + "";
          if (i == 15) {
            _text = "" + i + " & Last Day";
          }
          let newOption = {
            id: i + 40,
            text: _text
          };
          this.ddlAccuralDaysList.push(newOption);
        }
      break;
      case "9" :
      case "10" :
        this.ddlAccuralDaysList.push({ id: 61, text: "Sun" });
        this.ddlAccuralDaysList.push({ id: 62, text: "Mon" });
        this.ddlAccuralDaysList.push({ id: 63, text: "Tue" });
        this.ddlAccuralDaysList.push({ id: 64, text: "Wed" });
        this.ddlAccuralDaysList.push({ id: 65, text: "Thu" });
        this.ddlAccuralDaysList.push({ id: 66, text: "Fri" });
        this.ddlAccuralDaysList.push({ id: 67, text: "Sat" });
      break;
    }
    if (_type != "8" && _type != "9" && _type != "10") {
      this.ddlAccuralDaysList.push({ id: 71, text: "Last Day" });
      this.ddlAccuralDaysList.push({ id: 72, text: "Policy Date" });
      this.ddlAccuralDaysList.push({ id: 73, text: "Joining Date" });
      this.ddlAccuralDaysList.push({ id: 74, text: "Birth Date" });
    }
  }

  setAccuralMonthSelectList(_type) {// Created By A Set Values
    this.ddlAccuralMonthsList = [];
    this.ddlAccuralMonthsListfield = {value:"value", text: "name" };
    switch (_type) {
      case "1" :
      case "2" :
        this.ddlAccuralMonthsList = [
          { value: 1, name: "Jan" },
          { value: 2, name: "Feb" },
          { value: 3, name: "Mar" },
          { value: 4, name: "Apr" },
          { value: 5, name: "May" },
          { value: 6, name: "Jun" },
          { value: 7, name: "Jul" },
          { value: 8, name: "Aug" },
          { value: 9, name: "Sep" },
          { value: 10, name: "Oct" },
          { value: 11, name: "Nov" },
          { value: 12, name: "Dec" },
          // Extra values
          { value: 61, name: "Policy Month" },
          { value: 62, name: "Joining Month" },
          { value: 63, name: "Birth Month" }
        ];
        break;
      case "3" :
      case "8" :
      case "9" :
      case "10" :
        this.ddlAccuralMonthsList = [];
        break;
      case "4" :
        this.ddlAccuralMonthsList = [
          { value: 21, name: "Jan & Jul" },
          { value: 22, name: "Feb & Aug" },
          { value: 23, name: "Mar & Sep" },
          { value: 24, name: "Apr & Oct" },
          { value: 25, name: "May & Nov" },
          { value: 26, name: "Jun & Dec" },
          // Extra values
          { value: 61, name: "Policy Month" },
          { value: 62, name: "Joining Month" },
          { value: 63, name: "Birth Month" }
        ];
        break;
      case "5" :
        this.ddlAccuralMonthsList = [
          { value: 31, name: "Jan & May & Sep" },
          { value: 32, name: "Feb & Jun & Oct" },
          { value: 33, name: "Mar & Jul & Nov" },
          { value: 34, name: "Apr & Aug & Dec" },
          // Extra values
          { value: 61, name: "Policy Month" },
          { value: 62, name: "Joining Month" },
          { value: 63, name: "Birth Month" }
        ];
        break;
      case "6" :
        this.ddlAccuralMonthsList = [
          { value: 41, name: "Jan & Apr & Jul & Oct" },
          { value: 42, name: "Feb & May & Aug & Nov" },
          { value: 43, name: "Mar & Jun & Sep & Dec" },
          // Extra values
          { value: 61, name: "Policy Month" },
          { value: 62, name: "Joining Month" },
          { value: 63, name: "Birth Month" }
        ];
        break;
      case "7" :
        this.ddlAccuralMonthsList = [
          { value: 51, name: "Jan & Mar & May & Jul & Sep & Nov" },
          { value: 52, name: "Feb & Apr & Jun & Aug & Oct & Dec" },
          // Extra values
          { value: 61, name: "Policy Month" },
          { value: 62, name: "Joining Month" },
          { value: 63, name: "Birth Month" }
        ];
        break;
    }
  }

  onChangeAccuralPeriod(e) {// Created By A On change Accural Period
    this.tempLeaveType.AccuralPeriod = e.value;
    let _type = this.tempLeaveType.AccuralPeriod.toString();

    this.setAccuralFieldsData(_type);
  }


  // Reset
  onChangeIsReset(e) {// Created By A Reset values
    this.tempLeaveType.IsReset = !this.tempLeaveType.IsReset;
    this.customizePolicyForm.patchValue({
      ResetPeriod: 2,
      ResetDay: 71,
      ResetMonth: 12,
      CarryForwardType: 1,
      CarryForwardValue: 0,
      CarryForwardPercentageUnit: 1,
      CarryForwardMaxLimit: null,
      EncashmentValue: 0,
      EncashmentPercentageUnit: 1,
      EncashmentMaxLimit: null
    });

    this.tempLeaveType.ResetPeriod = 2;
    this.tempLeaveType.ResetDay = 71;
    this.tempLeaveType.ResetMonth = 12;
    this.tempLeaveType.CarryForwardType = 1;
    this.tempLeaveType.CarryForwardPercentageUnit = 1;
    this.tempLeaveType.EncashmentPercentageUnit = 1;

    this.setResetFieldsData(this.tempLeaveType.ResetPeriod.toString());
  }

  setResetFieldsData(_type) {// Created By A Reset Values
    this.setResetDaySelectList(_type);
    this.setResetMonthSelectList(_type);

    let _resetDay = 71, _resetMonth = 12;
    switch (_type) {
      case "1" :
        _resetDay = 72;
        _resetMonth = 61;
      break;
      case "2" :
      case "3" :
        _resetDay = 71;
        _resetMonth = 12;
      break;
      case "4" :
        _resetDay = 71;
        _resetMonth = 26;
      break;
      case "5" :
        _resetDay = 71;
        _resetMonth = 34;
      break;
      case "6" :
        _resetDay = 71;
        _resetMonth = 43;
      break;
      case "7" :
        _resetDay = 71;
        _resetMonth = 52;
      break;
      case "8" :
        _resetDay = 55;
        _resetMonth = 12;
      break;
      case "9" :
        _resetDay = 61;
        _resetMonth = 12;
      break;
      case "10" :
        _resetDay = 61;
        _resetMonth = 12;
      break;
    }

    this.customizePolicyForm.patchValue({
      ResetDay: _resetDay,
      ResetMonth: _resetMonth
    });
  }

  setResetDaySelectList(_type) {// Created By A Reset values
    this.ddlResetDaysList = [];
    this.ddlResetDaysListfield = {value: "id", text: "text"};
    switch (_type) {
      case "1" :
      case "2" :
      case "3" :
      case "4" :
      case "5" :
      case "6" :
      case "7" :
        for (let i = 1; i <= 31; i++) {
          let _text = this.getNumberWithOrdinal(i);
          let newOption = {
            id: i,
            text: _text
          };
          this.ddlResetDaysList.push(newOption);
        }
      break;
      case "8" :
        for (let i = 1; i <= 15; i++) {
          let _text = "" + i + " & " + (i + 15) + "";
          if (i == 15) {
            _text = "" + i + " & Last Day";
          }
          let newOption = {
            id: i + 40,
            text: _text
          };
          this.ddlResetDaysList.push(newOption);
        }
      break;
      case "9" :
      case "10" :
        this.ddlResetDaysList.push({ id: 61, text: "Sun" });
        this.ddlResetDaysList.push({ id: 62, text: "Mon" });
        this.ddlResetDaysList.push({ id: 63, text: "Tue" });
        this.ddlResetDaysList.push({ id: 64, text: "Wed" });
        this.ddlResetDaysList.push({ id: 65, text: "Thu" });
        this.ddlResetDaysList.push({ id: 66, text: "Fri" });
        this.ddlResetDaysList.push({ id: 67, text: "Sat" });
      break;
    }
    if (_type != "8" && _type != "9" && _type != "10") {
      this.ddlResetDaysList.push({ id: 71, text: "Last Day" });
      this.ddlResetDaysList.push({ id: 72, text: "Policy Date" });
      this.ddlResetDaysList.push({ id: 73, text: "Joining Date" });
      this.ddlResetDaysList.push({ id: 74, text: "Birth Date" });
    }
  }

  setResetMonthSelectList(_type) {// Created By A Reset values
    this.ddlResetMonthsList = [];
    this.ddlResetMonthsListfield = {value:"value", text: "name"};
    switch (_type) {
      case "1" :
      case "2" :
        this.ddlResetMonthsList = [
          { value: 1, name: "Jan" },
          { value: 2, name: "Feb" },
          { value: 3, name: "Mar" },
          { value: 4, name: "Apr" },
          { value: 5, name: "May" },
          { value: 6, name: "Jun" },
          { value: 7, name: "Jul" },
          { value: 8, name: "Aug" },
          { value: 9, name: "Sep" },
          { value: 10, name: "Oct" },
          { value: 11, name: "Nov" },
          { value: 12, name: "Dec" },
          // Extra values
          { value: 61, name: "Policy Month" },
          { value: 62, name: "Joining Month" },
          { value: 63, name: "Birth Month" }
        ];
        break;
      case "3" :
      case "8" :
      case "9" :
      case "10" :
        this.ddlResetMonthsList = [];
        break;
      case "4" :
        this.ddlResetMonthsList = [
          { value: 21, name: "Jan & Jul" },
          { value: 22, name: "Feb & Aug" },
          { value: 23, name: "Mar & Sep" },
          { value: 24, name: "Apr & Oct" },
          { value: 25, name: "May & Nov" },
          { value: 26, name: "Jun & Dec" },
          // Extra values
          { value: 61, name: "Policy Month" },
          { value: 62, name: "Joining Month" },
          { value: 63, name: "Birth Month" }
        ];
        break;
      case "5" :
        this.ddlResetMonthsList = [
          { value: 31, name: "Jan & May & Sep" },
          { value: 32, name: "Feb & Jun & Oct" },
          { value: 33, name: "Mar & Jul & Nov" },
          { value: 34, name: "Apr & Aug & Dec" },
          // Extra values
          { value: 61, name: "Policy Month" },
          { value: 62, name: "Joining Month" },
          { value: 63, name: "Birth Month" }
        ];
        break;
      case "6" :
        this.ddlResetMonthsList = [
          { value: 41, name: "Jan & Apr & Jul & Oct" },
          { value: 42, name: "Feb & May & Aug & Nov" },
          { value: 43, name: "Mar & Jun & Sep & Dec" },
          // Extra values
          { value: 61, name: "Policy Month" },
          { value: 62, name: "Joining Month" },
          { value: 63, name: "Birth Month" }
        ];
        break;
      case "7" :
        this.ddlResetMonthsList = [
          { value: 51, name: "Jan & Mar & May & Jul & Sep & Nov" },
          { value: 52, name: "Feb & Apr & Jun & Aug & Oct & Dec" },
          // Extra values
          { value: 61, name: "Policy Month" },
          { value: 62, name: "Joining Month" },
          { value: 63, name: "Birth Month" }
        ];
        break;
    }
  }

  onChangeCarryForwardType(e) {// Created By A On change carry forward type
    this.tempLeaveType.CarryForwardType = e.value;

    this.customizePolicyForm.patchValue({
      CarryForwardExpiresIn: null,
      CarryForwardExpiresInType: null,
      CarryForwardOverallLimit: null
    });

    if (this.tempLeaveType.CarryForwardType == 2) {
      this.customizePolicyForm.patchValue({
        CarryForwardExpiresIn: null,
        CarryForwardExpiresInType: 1,
        CarryForwardOverallLimit: null
      });
    }
  }
  onChangeResetPeriod(e) {// Created By A Set value
    this.tempLeaveType.ResetPeriod = e.value;
    let _type = this.tempLeaveType.ResetPeriod.toString();

    this.setResetFieldsData(_type);
  }
  onChangeCarryForwardPercentageUnit(e) {// Created By A Set value
    this.tempLeaveType.CarryForwardPercentageUnit = e.value;
  }
  onChangeEncashmentPercentageUnit(e) {// Created By A Set value
    this.tempLeaveType.EncashmentPercentageUnit = e.value;
  }
  // End of Entitlement (Accural, Reset) Related

  // Entitlement Popup Related
  openEntitlementCalendarPopup() {// Created By A Open modal
    this._commonSer.OpenModalHandler('manageEntitlementCalendar');
  }
  createEntitlementCalendarData() {// Created By A Initialize entitlement calendar months
    let entitlementsList = [];
    for (let i = 1; i <= 12; i++) {
      let _month = "";
      switch (i) {
        case 1:
          _month = "Jan";
          break;
        case 2:
          _month = "Feb";
          break;
        case 3:
          _month = "Mar";
          break;
        case 4:
          _month = "Apr";
          break;
        case 5:
          _month = "May";
          break;
        case 6:
          _month = "Jun";
          break;
        case 7:
          _month = "Jul";
          break;
        case 8:
          _month = "Aug";
          break;
        case 9:
          _month = "Sep";
          break;
        case 10:
          _month = "Oct";
          break;
        case 11:
          _month = "Nov";
          break;
        case 12:
          _month = "Dec";
          break;
      }
      entitlementsList.push({ LeavePolicyEntitlementCalendarId: 0, LeaveTypeId: 0, Month: i, MonthText: _month, Count: 0 });
    }

    // this.leaveTypeForm.patchValue({
    //   EntitlementCalendarList: entitlementsList
    // });
    this.tempLeaveType.EntitlementCalendarList = entitlementsList;
  }
  setEntitlementCalendarData(list) {// Created By A Initialize entitlement calendar data
    let entitlementsList = [];
    for (let i = 0; i < list.length; i++) {
      let _newItem = {
        EmployeeLeavePolicyEntitlementCalendarId: list[i].employeeLeavePolicyEntitlementCalendarId,
        EmployeeId: list[i].employeeId,
        LeaveTypeId: list[i].leaveTypeId,
        Month: list[i].month,
        MonthText: list[i].monthText,
        Count: list[i].count
      };
      entitlementsList.push(_newItem);
    }
    this.tempLeaveType.EntitlementCalendarList = entitlementsList;
  }

  onchangeAccuralNoOfDays(e) {// Created By A On change accural no of days
    let _value = e.target.value;
    for (let i = 0; i < this.tempLeaveType.EntitlementCalendarList.length; i++) {
      this.tempLeaveType.EntitlementCalendarList[i].Count = _value;
    }
  }
  // End of Entitlement Popup Related

  // Entitlement More Options

  setMoreOptionData(_type, isEnable) {// Created By A Global Controller for more options fields update
    switch (_type) {
      case "OpeningBalance" :
        this.IsOpeningBalanceEnable = isEnable;
        this.customizePolicyForm.patchValue({
          IsOpeningBalanceEnable: isEnable,
          OpeningBalance: 0
        });
        break;
      case "MaximumBalance" :
        this.IsMaximumBalanceEnable = isEnable;
        this.customizePolicyForm.patchValue({
          IsMaximumBalanceEnable: isEnable,
          MaximumBalance: 0
        });
        break;
      case "ProrateAccural" :
        this.IsProrateAccuralEnable = isEnable;
        this.customizePolicyForm.patchValue({
          IsProrateAccuralEnable: isEnable,
          ProrateAccuralType: 1
        });
        break;
      case "DeductibleHolidays" :
        this.IsDeductibleHolidaysEnable = isEnable;
        this.customizePolicyForm.patchValue({
          IsDeductibleHolidaysEnable: isEnable,
          DeductibleHolidays: 1
        });
        break;
    }
  }

  // End of Entitlement More Options

  // Prorate Accural Popup Related
  setprorateAccuralTypeValue(e) {// Created By A set type list
    let _value = e.value;
    if (_value.toString() == '0: 1') {
      this.prorateAccuralType = "1";
    } else if (_value.toString() == '1: 2') {
      this.prorateAccuralType = "2";
    } else if (_value.toString() == '2: 3') {
      this.prorateAccuralType = "3";
    }
  }

  setProrateAccuralDaySelectList() {// Created By A Set prorate accural days list
    this.prorateAccuralDayList = [];
    for (let i = 1; i <= 31; i++) {
      let _text = this.getNumberWithOrdinal(i);
      let newOption = {
        id: i,
        text: _text
      };
      this.prorateAccuralDayList.push(newOption);
    }
  }
  openProrateAccuralModal() {// Created By A Open Modal
    let vm = this;
    let leaveTypeId = this.customizePolicyForm.controls.LeaveTypeId.value;
    let employeeId = this.customizePolicyForm.controls.EmployeeId.value;

    vm.setProrateAccuralDaySelectList();
    vm._commonSer.OpenModalHandler("manageProrateAccuralModal");
  }

  addFirstMonthRule() {// Created By A add first month rule
    let newRule = {
      employeeLeavePolicyPAFMId: 0,
      employeeId: this.prorateAccural.employeeId,
      leaveTypeId: this.prorateAccural.leaveTypeId,
      from: null,
      to: null,
      count: null
    };
    this.duplicateFirstMonthRuleFrom.push("true");
    this.duplicateFirstMonthRuleTo.push("true");
    this.prorateAccural.prorateAccuralFirstMonthList.push(newRule);
  }
  addLastMonthRule() {// Created By A Add Last month rule
    let newRule = {
      employeeLeavePolicyPALMId: 0,
      employeeId: this.prorateAccural.employeeId,
      leaveTypeId: this.prorateAccural.leaveTypeId,
      from: null,
      to: null,
      count: null
    };
    this.duplicateLastMonthRuleFrom.push("true");
    this.duplicateLastMonthRuleTo.push("true");
    this.prorateAccural.prorateAccuralLastMonthList.push(newRule);
  }

  duplicateFirstMonthRuleFrom = [];
  duplicateFirstMonthRuleTo = [];
  onFirstRuleFromSelected(e, i, _value) {// Created By A On select from --from rule
    let count1 = this.prorateAccural.prorateAccuralFirstMonthList.filter(item => item.from == _value).length;
    let count2 = this.prorateAccural.prorateAccuralFirstMonthList.filter(item => item.to == _value).length;
    if (count1 > 1 || count2 > 0) {
      this.duplicateFirstMonthRuleFrom[i] = "false";
    } else {
      this.duplicateFirstMonthRuleFrom[i] = "true";
    }
  }

  onFirstRuleToSelected(e, i, _value) {// Created By A On select To --first rule
    let count1 = this.prorateAccural.prorateAccuralFirstMonthList.filter(item => item.from == _value).length;
    let count2 = this.prorateAccural.prorateAccuralFirstMonthList.filter(item => item.to == _value).length;
    if (count1 > 0 || count2 > 1) {
      this.duplicateFirstMonthRuleTo[i] = "false";
    } else {
      this.duplicateFirstMonthRuleTo[i] = "true";
    }
  }

  duplicateLastMonthRuleFrom = [];
  duplicateLastMonthRuleTo = [];
  onLastRuleFromSelected(e, i, _value) {// Created By A Onselect from --last rule
    let count1 = this.prorateAccural.prorateAccuralLastMonthList.filter(item => item.from == e.target.value).length;
    let count2 = this.prorateAccural.prorateAccuralLastMonthList.filter(item => item.to == e.target.value).length;
    if (count1 > 1 || count2 > 0) {
      this.duplicateLastMonthRuleFrom[i] = "false";
    } else {
      this.duplicateLastMonthRuleFrom[i] = "true";
    }
  }
  onLastRuleToSelected(e, i, _value) {// Created By A On select to --last rule
    let count1 = this.prorateAccural.prorateAccuralLastMonthList.filter(item => item.from == _value).length;
    let count2 = this.prorateAccural.prorateAccuralLastMonthList.filter(item => item.to == _value).length;
    if (count1 > 0 || count2 > 1) {
      this.duplicateLastMonthRuleTo[i] = "false";
    } else {
      this.duplicateLastMonthRuleTo[i] = "true";
    }
  }

  saveProrateAccural() {// Created By A Initialize Save Prorate Accural
    const vm = this;
    if (vm.duplicateFirstMonthRuleFrom.includes("false") ||
      vm.duplicateFirstMonthRuleTo.includes("false") ||
      vm.duplicateLastMonthRuleFrom.includes("false") ||
      vm.duplicateLastMonthRuleTo.includes("false")) {
        return;
    }

    this.customizePolicyForm.patchValue({
      ProrateBy: vm.prorateAccural.prorateBy,
      IsRoundOff: vm.prorateAccural.isRoundOff,
      RoundOffToValue: vm.prorateAccural.roundOffToValue,
      RoundOffToType: vm.prorateAccural.roundOffToType,
      ProrateAccuralFMList: vm.prorateAccural.prorateAccuralFirstMonthList,
      ProrateAccuralLMList: vm.prorateAccural.prorateAccuralLastMonthList
    });

    console.log(this.customizePolicyForm.value);
    vm._commonSer.CloseModalHandler('manageProrateAccuralModal');
  }
  // End of Prorate Accural Popup Related

  // Export
  ExportSelectedEmployeeHistory() { // Created By A Export Employee leaves history
    if (this.startDate != null && this.endDate != null) {
      this.userLoader = true;
      let startDate = this.getCSharpFormattedDate(this.startDate);
      let endDate = this.getCSharpFormattedDate(this.endDate);
      let parms = {
        EmployeeId: this.ddlEmployeeId,
        LeaveTypeId: this.selectedLeaveTypeID,
        StartDate: startDate,
        EndDate: endDate,
        CmpCode:this.CmpCode,
      };

      let empNameCode = "";
      const currentEmp = this.totalEmployeeList.find(x => x.employeeId == this.ddlEmployeeId);
      if (currentEmp !== undefined && currentEmp != null) {
        empNameCode = currentEmp.employeeCodeName;
      }

      this._leaveSer.exportEmployeeLeaveHistory(parms).subscribe(res => {
        let exportData = res['data'];

        //const headers = [response.headerstring];//['Name', 'ShortDescription', 'Description', 'CustomerName', 'ProjectType', 'ProjectSource', 'Office', 'PlannedStartDate', 'ActualStartDate', 'PlannedEndDate', 'ActualEndDate', 'PaymentMode', 'ProjectManagerFirstName', 'ProjectManagerMiddleName', 'ProjectManagerLastName', 'SalesPersonFirstName', 'SalesPersonMiddleName', 'SalesPersonLastName', 'ContactPersonFirstName', 'ContactPersonMiddleName', 'ContactPersonLastName', 'WorkingDays', 'InActive', 'IsDeleted'];
        this._commonSer.exportAsExcelFile(exportData, 'LeaveTransaction_' + empNameCode);
        this.userLoader = false;
      });
    }
  }
}
